import { useLocation } from 'react-router-dom'
import { useState, useEffect } from 'react'

// Get Params
const getParams = (search: any) =>
	Object.fromEntries(new URLSearchParams(search))

/**
 * Use Query Params Component
 */

const useQueryParams = () => {
	const { search } = useLocation()
	const getSearch = () => getParams(search)
	const [params, setParams] = useState(getSearch())
	useEffect(() => {
		setParams(getSearch())
	}, [search])
	return params
}

export default useQueryParams
