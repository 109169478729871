import {FC, useState, useEffect} from 'react'

import Grid from '@mui/material/Grid'
import Box from '@mui/material/Box'

import RSInputAdornment from '../../components/input/RSInputAdornment'
import RSButton from '../../components/buttons/RSButton'
import { AccountProfile } from '../../api/v2/account/Account.types'
import { getHeight, getWeight } from '../../modules/miscUtils'

export type HeightWeightProps = {
	data: AccountProfile,
	handleUpdateProfile: Function,
}

const HeightWeight: FC<HeightWeightProps> = ({data, handleUpdateProfile}) => {
	const {measurement_system, weight_lbs, height_cm, user_id} = data
	const isMetric = measurement_system === 'metric' ? true : false
	const [feet, setFeet] = useState<any>('');
	const [inch, setInch] = useState<any>('');
	const [cm, setCm] = useState<any>(height_cm ? height_cm : '');
	const [lbs, setLbs] = useState<any>(weight_lbs ? weight_lbs : '')
	const [kg, setKg] = useState<any>('')

	const handleSave = async () => {
		if(user_id && cm && lbs) {
			const l = lbs !== weight_lbs ? { weight_lbs: lbs} : {}
			const c = cm !== height_cm ? { height_cm: cm} : {}
			handleUpdateProfile({ ...l, ...c})
		}
	}

	const setAllHeight = (data: any) => {
		const {feet: fD, inch: iD, cm: cD} = data
		setCm(cD ? cD : '')
		setFeet(fD ? fD : '')
		setInch(iD ? iD : '')
	}

	const setAllWeight = (data: any) => {
		const {kg: kD, lbs: lD} = data
		setLbs(lD ? lD : '')
		setKg(kD ? kD : '')
	}

	const handleSetCm = (val: any) => setAllHeight(getHeight(true, {feet, inch, cm: val}))

	const handleSetFeet = (val: any,) => setAllHeight(getHeight(false, {feet: val, inch, cm}))

	const handleSetInch = (val: any) => setAllHeight(getHeight(false, {feet, inch: val, cm}))

	const handleSetLBS = (val: any) => setAllWeight(getWeight(false, {kg, lbs: val}))

	const handleSetKG = (val: any) => setAllWeight(getWeight(true, {kg: val, lbs}))

	useEffect(()=>{
		if (height_cm) {
			const {feet: fD, inch: iD} = getHeight(true, {feet, inch, cm})
			setFeet(fD ? fD : '')
			setInch(iD ? iD : '')
		}
	},[height_cm])

	useEffect(()=>{
		if (weight_lbs) {
			const {kg: kD} = getWeight(false, {kg, lbs})
			setKg(kD ? kD : '')
		}
	},[height_cm])

	const renderWithLabel = (val: any, setVal: any, label: string, arial: string) => {
		return <RSInputAdornment value={val} setValue={setVal} label={label} arial={arial} />
	}

	return (
		<Box>
			<Box sx={{fontSize: '16px', fontFamily: 'Poppins-Medium'}}>Height</Box>
			<Grid container xs={12} justifyContent='space-between'>
				{isMetric ?
					<Grid container xs={12}>
						{renderWithLabel(cm, handleSetCm, 'CM', 'centimeters')}
					</Grid>
					: <>
					<Grid container xs={5.9}>
						{renderWithLabel(feet, handleSetFeet, 'FT', 'feet')}
					</Grid>
					<Grid container xs={5.9}>
						{renderWithLabel(inch, handleSetInch, 'IN', 'inch')}
					</Grid>
				</>}
			</Grid>
			<Box sx={{pt: 2, fontSize: '16px', fontFamily: 'Poppins-Medium'}}>Weight</Box>
			<RSInputAdornment value={isMetric ? kg : lbs} setValue={isMetric ? handleSetKG : handleSetLBS} label={isMetric ? 'KG' : 'LBS'} arial='weight'/>
			<RSButton onClick={handleSave} disabled={!cm || !lbs || (height_cm == cm && weight_lbs == lbs)} sx={{mt: 3}}>Save</RSButton>
		</Box>
	)
}

export default HeightWeight;
