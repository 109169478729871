import { AuthPageProps } from '../modules/types'
import { useState, useEffect } from 'react'
import allVideos, { Video } from '../modules/videos/allVideos'

// Video ID's By Type
const VIDEO_IDS_BY_TYPE = {
	strength: 701850418,
	form: 701850461,
	injury: 701850500,
	race: 701850549,
	training: 761151027
}

// Videos by Type
const VIDEOS_BY_TYPE = {
	strength: allVideos[VIDEO_IDS_BY_TYPE.strength],
	form: allVideos[VIDEO_IDS_BY_TYPE.form],
	injury: allVideos[VIDEO_IDS_BY_TYPE.injury],
	race: allVideos[VIDEO_IDS_BY_TYPE.race],
	training: allVideos[VIDEO_IDS_BY_TYPE.training],
}

/**
 * useJoinVideo Hook
 */

const useJoinVideoHook = ({
	userInfo, isGetStarted
}: {
	userInfo: AuthPageProps['userInfo'],
	isGetStarted?: boolean
}) => {
	// State
	const [video, setVideo] = useState<Video | undefined>(undefined)

	// Init
	const init = () => {
		const joinReason = (!isGetStarted ? userInfo?.join_reason : 'training' ||
			'strength') as keyof typeof VIDEOS_BY_TYPE
		const curVideo = VIDEOS_BY_TYPE[joinReason]
		setVideo(curVideo)
	}

	// User Info Change Effect
	useEffect(() => {
		init()
	}, [userInfo])

	return { video }
}

export default useJoinVideoHook
