import { date, strtotime } from 'locutus/php/datetime';
import React, { useEffect, useState } from 'react'
import { Button, Dropdown, ToastContainer, Toast } from 'react-bootstrap'
import Modal from 'react-bootstrap/Modal'
// import CustomPopOver from '../../components/custom-popover'
import useTrainingPlanUtils from '../../hooks/useTrainingPlanUtils';
import { UpdateUserLocation } from '../../modules/trainingPlanActions';
import GoalRaces from '../../pages/training-plan/components/GoalRaces';
import { IComponentStore } from '../../store/createComponentStore';
import { ITrainingPlanStore } from '../../store/createTrainingPlanStore';
import useStore from '../../store/useStore';
import AdjustRaceGoalModal from './AdjustRaceGoalModal';
import ChangeLevelModal from './ChangeLevelModal';
import EditLocationModal from './EditLocationModal';
import EditScheduleModal from './EditScheduleModal';
import TrainingCalendarModal from './TrainingCalendarModal';

function YourTrainingPlanSidebar(props: any) {

	const { ConvertFullTo5k, ConvertFullToHalf, ConvertM2H2, ConvertFullto10k, convertDateToUTC } = useTrainingPlanUtils()
	const { showWeatherFormModal, currentLocation, setShowWeatherFormModal, setCurrentLocation, userVdotAdjustments, weatherData } = useStore((state: ITrainingPlanStore) => state)

	const { userInfo, selectedWeek, raceDetails, selectedRace, loadingValues, marathonDailyValues, getDailyActivityDisplay, getUserOverrides, getUserRestComments, selectedDay, getUserVdotChanges, currentWeek, handleClose: handleCloseMain, allUserNotes, setReload } = props;

	// const { getCurrentWeek } = useTrainingPlanUtils()

	const [show, setShow] = useState(false)
	const [locationInput, setLocationInput] = useState("")
	const [showViewCalandar, setShowViewCalandar] = useState(false)
	const [showPlanOverview, setShowPlanOverview] = useState(false)
	const [showEditLocations, setShowEditLocations] = useState(false)
	const [showAdjustGoal, setShowAdjustGoal] = useState(false)
	const [showChangeLevel, setShowChangeLevel] = useState(false)
	const [targetTime, setTargetTime] = useState("")
	// const [week, setWeek] = useState<any>(null)

	const [isLoading, setIsLoading] = useState(false)
	const [hasErrorSaving, setHasErrorSaving] = useState(false)
	const [isSuccessSave, setIsSuccessSave] = useState(false)
	const { setToast, toast } = useStore((state: IComponentStore) => state)

	const handleClose = () => setShow(false)
	const handleShow = () => setShow(true)

	const handleCloseViewCalandar = () => setShowViewCalandar(false)
	const handleShowViewCalandar = () => setShowViewCalandar(true)

	const handleClosePlanOverview = () => setShowPlanOverview(false)
	const handleShowPlanOverview = () => setShowPlanOverview(true)

	const handleCloseEditLocations = () => {
		setShowEditLocations(false)
		setShowWeatherFormModal(false)
	}
	const handleShowEditLocations = () => setShowEditLocations(true)

	const handleCloseAdjustGoal = () => setShowAdjustGoal(false)
	const handleShowAdjustGoal = () => setShowAdjustGoal(true)

	const handleCloseChangeLevel = () => setShowChangeLevel(false)
	const handleShowChangeLevel = () => setShowChangeLevel(true)

	const handleSubmitLocation = async () => {
		if (currentLocation !== locationInput && !isLoading) {
			setIsLoading(true)
			const request = await UpdateUserLocation(userInfo?.uid, locationInput);
			if (request.data.messages === "success") {
				setCurrentLocation(locationInput)
				setIsLoading(false)
				setIsSuccessSave(true)
				handleCloseEditLocations()
			}
			else {
				setIsLoading(false)
				setIsSuccessSave(false)
			}
		}
		else {
			handleCloseEditLocations()
		}
	}

	useEffect(() => {

		let targetTime;

		if (userVdotAdjustments?.length > 0)
			targetTime = userVdotAdjustments[userVdotAdjustments?.length - 1]?.targetm

		else
			targetTime = userInfo?.targetm;

		const type = userInfo?.type;

		switch (type) {
			case 0:
				setTargetTime(ConvertFullToHalf(targetTime));
				break;
			case 1:
				setTargetTime(ConvertM2H2(targetTime));
				break;
			case 2:
				setTargetTime(ConvertFullTo5k(targetTime));
				break;
			case 3:
				setTargetTime(ConvertFullto10k(targetTime));
				break;
			case 4:
				setTargetTime(ConvertM2H2(targetTime));
				break;
			default:
				break;
		}
	}, [userInfo, userVdotAdjustments])

	useEffect(() => {
		setToast({
			show: hasErrorSaving,
			title: "Error Message",
			message: "An error occurred while saving location data",
			variant: "danger",
			onClose: () => setHasErrorSaving(false),
			duration: 5000
		})
	}, [hasErrorSaving])

	useEffect(() => {
		// setSelectedRaceDate(new Date())
		setToast({
			show: isSuccessSave,
			title: "Save location",
			message: "Location has been successfully updated",
			variant: "success",
			onClose: () => {
				setIsSuccessSave(false)
			},
			duration: 3000,
		})
	}, [isSuccessSave])

	useEffect(() => {
		showWeatherFormModal ? setShowEditLocations(true) : setShowEditLocations(false)
		currentLocation ? setLocationInput(currentLocation) : setLocationInput("")
	}, [showWeatherFormModal, currentLocation])

	return (
		<div className="build-plan bg-md-white radius-5 py-md-5 py-2 px-md-4 mt-md-2 text-center remove-devices">
			{selectedWeek?.key > 0 ? (
				<h4 className="text-uppercase font-20 font-md-16 font-weight-semibold mb-3 mb-md-4">
					WEEK {selectedWeek?.key} SUMMARY
				</h4>
			) : (
				<h4 className="text-uppercase font-13 font-md-16 font-weight-semibold mb-3 mb-md-4">
					Your Plan Begins in {selectedWeek?.key * (-1) + 1} {(selectedWeek?.key * (-1) + 1) > 1 ? 'weeks' : 'week'}
				</h4>
			)}

			<h5 className="text-uppercase mb-md-4 mb-2 week-sum-list">
				WEEKS REMAINING:{' '}
				<span className="text-primary h3">
					<i className="marathon-value--text">{userInfo?.weeks ? (userInfo?.weeks - selectedWeek?.key) <= 0 ? 0 : userInfo?.weeks - selectedWeek?.key : 0}</i>
				</span>
			</h5>
			<h5 className="text-uppercase mb-md-4 mb-2 week-sum-list">
				TOTAL DISTANCE:{' '}
				<span className="text-primary h3 text-lowercase">
					<i className="marathon-value--text">{loadingValues ? 0 : raceDetails?.totalDistance}</i>
				</span>
			</h5>
			<h5 className="text-uppercase mb-md-4 mb-2 week-sum-list">
				{' '}
				<span className="text-primary h3 text-lowercase">
					<i className="marathon-value--text">{raceDetails?.daysUntilRace}</i>
				</span>
				&nbsp;&nbsp;DAYS UNTIL RACE DAY
			</h5>
			<h5 className="text-uppercase mb-md-4 mb-2 week-sum-list">
				{' '}
				<span className="text-primary h3 text-lowercase">
					<i className="marathon-value--text">{raceDetails?.percentCompleted}%</i>
				</span>
				&nbsp;&nbsp;PLAN COMPLETED
			</h5>
			{/* {Plan Overview} */}
			<div className="d-md-none">
				<h3 className="text-uppercase font-md-16 letter-space my-4">
					PLAN OVERVIEW
				</h3>
				<ul className="list-unstyled bg-white radius-5 py-3 px-4 text-start">
					<li className="mb-1">
						<strong>{selectedRace?.value == 4 ? "Training Ends:" : "Race Day:" || ""}</strong> {userInfo && date('m/d/Y', strtotime(userInfo?.race_date))}
					</li>
					<li className="mb-1">
						<strong>Event:</strong> {selectedRace?.value == 0 || selectedRace?.value == 1 ? `${selectedRace?.title} ${selectedRace?.subTitle}` : selectedRace?.title}
					</li>
					<li className="mb-1">
						<strong>Goal Time:</strong> {targetTime}
					</li>
					<li className="mb-1">
						<strong>Training Plan Level:</strong> {userInfo?.training_level == 0 ? 3 : userInfo?.training_level}
					</li>
					<li className="mb-1">
						<strong>Weeks Training:</strong> {userInfo?.weeks} weeks
					</li>
					<li className="mb-1">
						<strong>Max Weekly Mileage: </strong> {userInfo?.km === "Y" ? `${(userInfo?.distance * 1.60934).toPrecision(3)} kilometers` : `${userInfo?.distance} miles`}
					</li>
					<li className="mb-1">
						<strong>Weather Tracking:</strong> {weatherData?.data?.name}, {weatherData?.data?.sys?.country}
					</li>
				</ul>
			</div>

			{/*Goal races slider  */}
			{
				userInfo?.type != 2 || userInfo?.type != 4 ? (<GoalRaces userInfo={userInfo} mainClass="d-md-none goal-races-device" />) : null
			}

			<div className="bottom-btn-wrap">
				<Button
					size="lg"
					className="d-none d-md-block mx-auto rounded-pill font-weight-medium color-black-bg save-btn mt-4"
					variant="dark"
					onClick={handleShowViewCalandar}
				>
					View Calender
				</Button>
				<Button
					disabled={selectedWeek?.key < 0}
					size="lg"
					className="d-none d-md-block mx-auto rounded-pill font-weight-medium color-black-bg save-btn mt-4"
					variant="dark"
					onClick={handleShow}
				>
					Edit Schedule
				</Button>
				<div className="traning-plan-dropdown">
					<Dropdown>
						<Dropdown.Toggle variant="outline-primary" id="traning-plan">
							Training Plan Options
						</Dropdown.Toggle>
						<Dropdown.Menu>
							<Dropdown.Item
								className="d-md-block d-none"
								onClick={handleShowPlanOverview}
							>
								Plan Overview
							</Dropdown.Item>
							<Dropdown.Item
								className="d-md-none"
								onClick={handleShowViewCalandar}
							>
								View Calendar
							</Dropdown.Item>
							<Dropdown.Item disabled={selectedWeek?.key < 0} className="d-md-none" onClick={handleShow}>
								Edit Schedule
							</Dropdown.Item>
							<Dropdown.Item onClick={handleShowEditLocations}>
								Edit Location
							</Dropdown.Item>
							<Dropdown.Item disabled={selectedWeek?.key < 0} onClick={handleShowAdjustGoal}>
								Adjust Race Goal
							</Dropdown.Item>

							<Dropdown.Item disabled={selectedWeek?.key < 0} onClick={handleShowChangeLevel}>
								Change Level
							</Dropdown.Item>
						</Dropdown.Menu>
					</Dropdown>
				</div>
				{/* EDIT SCHEDULE Modal */}

				<EditScheduleModal
					show={show}
					handleClose={handleClose}
					userInfo={userInfo}
					selectedWeek={selectedWeek}
					raceDetails={raceDetails}
					selectedRace={selectedRace}
					loadingValues={loadingValues}
					marathonDailyValues={marathonDailyValues}
					getDailyActivityDisplay={getDailyActivityDisplay}
					getUserOverrides={getUserOverrides}
					getUserRestComments={getUserRestComments}
				/>
				{/* View Calendar Modal */}
				<TrainingCalendarModal
					showViewCalandar={showViewCalandar}
					handleCloseViewCalandar={handleCloseViewCalandar}
					handleClose={handleCloseMain}
					userInfo={userInfo}
					getDailyActivityDisplay={getDailyActivityDisplay}
					selectedRace={selectedRace}
					currentWeek={currentWeek}
					allUserNotes={allUserNotes}
				/>

				{/* Plan Overview : Modal */}
				<Modal
					className="common-modal plan-overivew-modal"
					show={showPlanOverview}
					onHide={handleClosePlanOverview}
				>
					<Modal.Header></Modal.Header>
					<Modal.Body className="mx-5 px-md-2">
						<ul className="list-unstyled">
							<li>
								<h2 className="traning-popup--title mb-3">
									Training Plan Overview
								</h2>
							</li>
							<li className="mb-1">
								<strong>{selectedRace?.value == 4 ? "Training Ends:" : "Race Day:" || ""}</strong> {userInfo && date('m/d/Y', strtotime(convertDateToUTC(userInfo?.race_date)))}
							</li>
							<li className="mb-1">
								<strong>Event:</strong> {selectedRace?.value == 0 || selectedRace?.value == 1 ? `${selectedRace?.title} ${selectedRace?.subTitle}` : selectedRace?.title}
							</li>
							<li className="mb-1">
								<strong>Goal Time:</strong> {targetTime}
							</li>
							<li className="mb-1">
								<strong>Training Plan Level:</strong> {userInfo?.training_level == 0 ? 3 : userInfo?.training_level}
							</li>
							<li className="mb-1">
								<strong>Weeks Training:</strong> {userInfo?.weeks} weeks
							</li>
							<li className="mb-1">
								<strong>Max Weekly Mileage: </strong> {userInfo?.km === "Y" ? `${(userInfo?.distance * 1.60934).toPrecision(3)} kilometers` : `${userInfo?.distance} miles`}
							</li>
							<li className="mb-1">
								<strong>Weather Tracking:</strong> {weatherData?.data?.name}, {weatherData?.data?.sys?.country}
								{/* <img
									src="./images/icons/edit-black.png"
									alt="Download Plan"
								></img> */}
							</li>
						</ul>
						<div className="center-align">
							<Button
								variant="primary"
								onClick={handleClosePlanOverview}
								className="rounded-pill color-primary-bg mt-5 save-btn center-align text-uppercase letter-space btn-dark"
							>
								Back
							</Button>
						</div>
					</Modal.Body>
				</Modal>

				{/* Edit Locations : Modal */}
				<EditLocationModal
					showEditLocations={showEditLocations}
					handleCloseEditLocations={handleCloseEditLocations}
					locationInput={locationInput}
					setLocationInput={setLocationInput}
					isLoading={isLoading}
					handleSubmitLocation={handleSubmitLocation}
					currentLocation={currentLocation}
				/>

				{/* Adjust Race Goal : Modal */}
				<AdjustRaceGoalModal
					showAdjustGoal={showAdjustGoal}
					handleCloseAdjustGoal={handleCloseAdjustGoal}
					userInfo={userInfo}
					selectedWeek={selectedWeek}
					selectedDay={selectedDay}
					getUserVdotChanges={getUserVdotChanges}
				/>

				<ChangeLevelModal
					showChangeLevel={showChangeLevel}
					handleCloseChangeLevel={handleCloseChangeLevel}
					userInfo={userInfo}
					selectedWeek={selectedWeek}
					selectedDay={selectedDay}
					getUserVdotChanges={getUserVdotChanges}
					setReload={setReload}
				/>

			</div>

			<ToastContainer position='top-center' style={{ marginTop: 5 }}>
				<Toast bg={toast?.variant} show={toast?.show} delay={toast?.duration} autohide onClose={toast?.onClose}>
					<Toast.Header style={{ background: '#F6F5F5' }}>
						<strong className="me-auto">{toast?.title}</strong>
						<small></small>
					</Toast.Header>
					<Toast.Body className={`${toast.variant === "success" ? 'toast--success' : ''}`} style={{ color: '#FFF' }}>{toast?.message}</Toast.Body>
				</Toast>
			</ToastContainer>
		</div>
	)
}

export default YourTrainingPlanSidebar
