import { FC } from 'react'

/**
 * Not Found Page
 */

const NotFound: FC = () => {
	// Render
	return <div>Not Found</div>
}

export default NotFound
