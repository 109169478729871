// import { useContext } from 'react'
import { getReq } from '../modules/apiConsume';
import { IGoogleCastStore } from '../store/createGoogleCastStore';
import useStore from '../store/useStore';
import useRNBridge from './useRNBridge';
// import { WebSocketContext } from '../WebsocketProvider';

const usePlayCastVideo = (requestKey: string | undefined) => {

    // const { sendJsonMessage } = useContext(WebSocketContext);

    const { setActiveVideo } = useStore((state: IGoogleCastStore) => state)
    const { sendDataToReactNative } = useRNBridge()


    const playVideo = (videoId: string, progress?: number, openCastOnly?: boolean) => {
        if (requestKey) {
            if (openCastOnly) {
                sendDataToReactNative({ requestType: 'google-cast-toggle-dialog', requestKey: requestKey, payload: {}, timestamp: new Date().getTime() })
            }
            else {
                // sendDataToReactNative({ requestType: 'google-cast-toggle-dialog', requestKey: requestKey, payload: {}, timestamp: new Date().getTime() })
                getReq('/v2/exercises/raw-link?video_id=' + videoId)
                    .then((res) => {
                        const media = res.data.result;
                        sendDataToReactNative({ requestType: 'google-cast-toggle-dialog', requestKey: requestKey, payload: { ...media, progress: progress || 0 }, timestamp: new Date().getTime() })
                        // setCastStatus('playing')
                        setActiveVideo(videoId)
                    })
                    .catch(err => {
                        console.log("ERR: VIDEO LINK", err);
                    })
            }
        }
    }

    return { playVideo }
}

export default usePlayCastVideo