/* eslint-disable @typescript-eslint/no-unused-vars */
import moment from "moment"
import { useState } from "react"
import { getReq, postReq, putReq } from "../../modules/apiConsume"


const midfootActions = () => {
    const [loading, setIsLoading] = useState(false)

    const getActiveMidfoot = async (user_id?: number, signal?: AbortSignal) => {
        setIsLoading(true)
        const result = await getReq(`/v2/midfoot/user?user_id=${user_id}`, { signal: signal })
        setIsLoading(false)
        return result?.data?.result
    }

    const getDaysCompleted = async (user_id?: number, signal?: AbortSignal) => {
        setIsLoading(true)
        const result = await getReq(`/v2/midfoot/user?user_id=${user_id}`, { signal: signal })
        setIsLoading(false)
        return result?.data?.result[0].completed_weeks
    }

    const createMidfootData = async (user_id?: number, week?: number) => {
        const data = { user_id: user_id, date_started: moment(new Date()).format("YYYY-MM-DD"), current_week: week ? week : 1 }
        setIsLoading(true)
        const result = await postReq(`/v2/midfoot`, data)
        setIsLoading(false)
        return result?.data?.result
    }

    const updateMidfootData = async (user_id: number, midfoot_id: number, week: number) => {
				const data = { user_id: user_id, date_started: moment(new Date()).format("YYYY-MM-DD"), current_week: week }
				setIsLoading(true)
				await postReq(`/v2/midfoot/restart/${midfoot_id}`, data)
				const result = await postReq(`/v2/midfoot`, data)
				setIsLoading(false)
				return result?.data?.result
		}

    const restartMidfoot = async (user_id?: number, midfoot_id?: number) => {
        const data = { user_id: user_id, date_started: moment(new Date()).format("YYYY-MM-DD") }
        setIsLoading(true)
        await postReq(`/v2/midfoot/restart/${midfoot_id}`, data)
        const result = await postReq(`/v2/midfoot`, data)
        setIsLoading(false)
        return result?.data?.result
    }

    const getDailyMidfoot = async (midfootId: number, signal?: AbortSignal) => {
        setIsLoading(true)
        const result = await getReq(`/v2/midfoot/weekly?midfoot_id=${midfootId}`, { signal: signal })
        setIsLoading(false)
        return result?.data?.result
    }

    const createMidfootDaily = async (midfoot_id: number, midfoot_date: string, week: number) => {
        const data = { midfoot_id: midfoot_id, midfoot_date: moment(midfoot_date).format("YYYY-MM-DD"), week: week }
        setIsLoading(true)
        const result = await postReq(`/v2/midfoot/weekly?type=create`, data)
        setIsLoading(false)
        return result?.data?.result
    }

    const updateMidfootDaily = async (midfoot_id: number, completed: number, week: number) => {
        const data = { midfoot_id: midfoot_id, completed: completed, week: week }
        setIsLoading(true)
        const result = await postReq(`/v2/midfoot/weekly?type=update`, data)
        setIsLoading(false)
        return result?.data?.result
    }

    return { getActiveMidfoot, loading, createMidfootData, restartMidfoot, getDailyMidfoot, createMidfootDaily, updateMidfootDaily, getDaysCompleted, updateMidfootData }
}

export default midfootActions
