/* eslint-disable @typescript-eslint/no-unused-vars */
import AccountSubmitButtons from '../../buttons/AuthSubmitButtons'
import MessageCard from '../../cards/MessageCard'
import SubscriptionForm from '../../forms/SubscriptionForm'
import { DEFAULT_COSTS } from '../../../modules/values'
import { planCodes } from '../../../api/values'
import {
	getSubscriptionInfo,
	changeSubscription,
	createSubscription,
	getAllAccountInfo,
	getPlanCosts,
	createFutureSubscription,
} from '../../../modules/accountActions'
import { navReq, postReq } from '../../../modules/apiConsume'
import { FC, useState, useEffect, useContext } from 'react'
import { titleCase } from '../../../modules/miscUtils'
// import useStore from '../../../store/useStore'
// import { ICurrentUser } from '../../../store/createAccountStore'
// import useQueryParams from '../../../hooks/useQueryParams'
import { Elements, RecurlyProvider } from '@recurly/react-recurly'
// import ApplePayButton from '../../buttons/ApplePayButton'
import { HAS_SALE, RECURLY_PUBLIC_KEY, WS_URL } from '../../../modules/cliEnvValues'
import useQueryParams from '../../../hooks/useQueryParams'
import { useLocation } from 'react-router-dom'
import GooglePayButton from '../../buttons/GooglePayButton'
import ReactPixel from 'react-facebook-pixel';
import useSale from '../../../hooks/useSale'
import useStoreWSMessages from '../../../hooks/useStoreWSMessages'
import useWebSocket from 'react-use-websocket'
import WithAuth from '../../WithAuth'
import { AuthPageProps } from '../../../modules/types'
import { WebSocketContext } from '../../../WebsocketProvider'
import moment from 'moment'
import useIsMobileScreen from '../../../hooks/useIsMobileScreen'
import { ArrowForwardIos } from '@mui/icons-material'
import CouponModal from '../../../pages/account/checkout/CouponModal'
import useDeviceDetect from '../../../hooks/useDeviceDetect'
import useRNBridge from '../../../hooks/useRNBridge'
import { IComponentStore } from '../../../store/createComponentStore'
import useStore from '../../../store/useStore'


// Subscription Props
export type SubscriptionProps = {
	isRenew?: boolean
	isExpiring?: boolean
	isCoupon?: boolean
	billingIsValid?: boolean
	onSubmit?: () => any
	onCancel?: () => any
	setPlanId?: (x?: string) => any
	onAlertOpen: () => any
	setAlertMessage: (x: string) => any
	setAlertVariant: (x: string) => any,
	side?: boolean
}

// Get Submit Text
const getSubmitText = (isActive: boolean, isRenew = false) =>
	isRenew ? 'Renew' : isActive ? 'Change Plan' : 'Reactivate Plan'

/**
 * Subscription Component
 */

// Default Billing Info
const DEFAULT_BILLING_INFO = {
	lastFour: '',
	billingType: '',
	planType: '',
	nextBillDate: '',
}

const Subscription: FC<SubscriptionProps & AuthPageProps> = ({
	onSubmit,
	onCancel,
	setPlanId,
	// onAlertOpen,
	setAlertMessage,
	setAlertVariant,
	isRenew = false,
	isExpiring = false,
	isCoupon = false,
	billingIsValid = true,
	userInfo,
	side
}) => {

	// State

	const { activeSale, isLoadingSale } = useSale()

	const [loadingGooglePay, setLoadingGooglePay] = useState(false)

	const isMobileScreen = useIsMobileScreen()
	const { mobileType } = useDeviceDetect()

	const [messageData, setMessageData] = useState<{ requestKey: string, payload: any, requestType: string, timestamp: number } | undefined>();

	const [monthly, setMonthly] = useState(true)
	const [initMonthly, setInitMonthly] = useState<boolean | undefined>(undefined)
	const [optionChanged, setOptionChanged] = useState(false)
	const [currentPlanId, setCurrentPlanId] = useState<string | undefined>(
		undefined
	)
	const [hasPendingId, setHasPendingId] = useState(false)
	const [costs, setCosts] = useState({ ...DEFAULT_COSTS })
	const [isActive, setIsActive] = useState(true)
	const [formKey, setFormKey] = useState(0)
	const [submitText, setSubmitText] = useState(getSubmitText(isActive, isRenew))
	const [cardText, setCardText] = useState('')
	const [cardIsShown, setCardIsShown] = useState(false)
	const [info, setInfo] = useState({} as any)
	const [changeCost, setChangeCost] = useState(0)
	const [disableButton, setDisableButton] = useState(false)
	const [currentPlanCode, setCurrentPlanCode] = useState("")
	const [nextBillingDate, setNextBillingDate] = useState<Date>()
	const [subscriptionType, setSubscriptionType] = useState()

	const [showCouponModal, setShowCouponModal] = useState(false)
	const [isNativeMobile, setIsNativeMobile] = useState(false)

	const { storeWebSocketMessages, webSocketMessages, removeMessageByType } = useStoreWSMessages()

	const deviceRequestKey = localStorage.getItem('deviceRequestKey') || undefined

	const { sendJsonMessage, lastJsonMessage } = useContext(WebSocketContext);

	const { sendDataToReactNative } = useRNBridge()

	const { setIsLoading, setLastRNBridgeMessage, lastRNBridgeMessage } = useStore((state: IComponentStore) => state)

	// const { sendJsonMessage, lastMessage, lastJsonMessage } = useWebSocket(WS_URL,
	// 	{
	// 		shouldReconnect: (closeEvent) => {
	// 			return true;
	// 		},
	// 		reconnectAttempts: 3,
	// 		reconnectInterval: 30000,
	// 	}, deviceRequestKey ? true : false);

	const hasSale = HAS_SALE || activeSale && !deviceRequestKey ? true : false

	const isInactive = userInfo?.is_inactive

	// const { development } = useQueryParams()

	const [billingInfo, setBillingInfo] = useState({ ...DEFAULT_BILLING_INFO })
	const [isInAppPayment, setIsInAppPayment] = useState(false)

	// const [planCosts, setPlanCosts] = useState({ ...DEFAULT_COSTS })

	// const { hasOffer } = useStore((state: ICurrentUser) => state)
	const { offer, requestKey } = useQueryParams()

	const url = useLocation();


	const hasOffer = Number(offer) == 2 ? true : false


	// Reset After Changes
	const resetAfterChanges = () => {
		setInfo({})
		setInitMonthly(undefined)
		setOptionChanged(false)
	}

	const resetModalData = () => {
		setCardText("")
		setDisableButton(false)
	}

	// Get Billing Info
	const getBillingInfo = async () => {
		const deviceRequestKey = localStorage.getItem('deviceRequestKey')

		if (deviceRequestKey)
			setIsInAppPayment(true)
		else
			setIsInAppPayment(false)

		const newBillingInfo = { ...DEFAULT_BILLING_INFO }
		const responses = await getAllAccountInfo({ account: false })
		setNextBillingDate(responses?.subscription?.data?.active?.next_billing)
		setSubscriptionType(responses?.subscription?.data?.active?.subscription_type)
		const billingResponse = responses.billing
		const billingDetails = billingResponse?.data
		if (billingDetails) {
			newBillingInfo.lastFour = billingDetails.cardLastFour || ''
			newBillingInfo.billingType = billingDetails.paymentType
				? titleCase(billingDetails.paymentType)
				: ''
		}
		setBillingInfo(newBillingInfo)
	}

	// On Initiate Plan Change Event
	const onInitiatePlanChangeEvent = async () => {
		const nextBillDate = info.nextDate
		const newCost = monthly ? costs.monthly : costs.annual
		let newCardText;
		if (!isRenew && billingIsValid || !isRenew && billingIsValid && subscriptionType && subscriptionType == "coupon") {
			newCardText = `Your next bill date will be for $${newCost} on ${nextBillDate}`
			setCardText(newCardText)
			setCardIsShown(true)
		}

		else if (isRenew && billingIsValid) {
			newCardText = `Your card ${billingInfo?.lastFour !== "" ? `ending with ${billingInfo?.lastFour}` : ''}  will be charged $${newCost}.`
			setCardText(newCardText)
			setCardIsShown(true)
		}
		else {
			redirectToUpdateBilling()
		}

		setChangeCost(newCost)

	}

	const gtagTrack = () => {
		window.gtag('event', 'conversion', {
			'send_to': 'AW-10847885916/DW1UCO6PkssZENy01rQo',
			'value': monthly ? 67 : 147,
			'currency': 'USD'
		});
	}

	// Set Plan Costs
	const setPlanCosts = async () => {
		const newCosts = await getPlanCosts({
			hasOffer: hasOffer,
			isRenew: false,
			isYearlyRenewOnly: false,
			hasSale: hasSale,
		})
		setCosts(newCosts)
	}

	// useEffect(() => {
	// 	if (hasSale && costs.annual == DEFAULT_COSTS.annual) {
	// 		setPlanCosts()
	// 	}
	// }, [costs, hasSale])

	// After Billing Change
	const afterBillingChange = async () => {
		let planCode = "";
		// ReactPixel.trackCustom('AddPaymentInfo', { k: new Date().getTime() });
		if (hasOffer || hasSale) {
			planCode = monthly ? planCodes.monthlyRenew : costs.annualCode
		}
		else
			planCode = monthly ? planCodes.monthlyRenew : planCodes.annualRenew
		await createSubscription(planCode)
	}

	const redirectToUpdateBilling = () => {
		const planIsValid = currentPlanId && isActive
		let planCode = "";
		if (hasOffer || hasSale) {
			planCode = monthly ? planCodes.monthlyRenew : costs.annualCode
		}
		else
			planCode = monthly ? planCodes.monthlyRenew : planCodes.annualRenew

		const updateParams: any = { renew: isRenew, valid: planIsValid ? 1 : 0 }
		if (planCode) updateParams.plan_code = planCode
		const paramStr = new URLSearchParams(updateParams).toString()

		return navReq(`/#/update-billing?${paramStr}`)
	}

	// On Confirm Plan Change Event
	const onConfirmPlanChangeEvent = async () => {
		let success = false
		let subscription: any = undefined
		try {
			let planCode = "";
			if (hasOffer || hasSale) {
				planCode = monthly ? planCodes.monthlyRenew : costs.annualCode
			}
			else
				planCode = monthly ? planCodes.monthlyRenew : planCodes.annualRenew

			const planIsValid = currentPlanId && isActive

			// Update Plan
			if (planIsValid && billingIsValid && subscriptionType != "coupon") {
				success = await changeSubscription(
					currentPlanId,
					planCode,
					hasPendingId
				)
				subscription = (await getSubscriptionInfo()).subscription
			}
			else if (billingIsValid && subscriptionType && subscriptionType == "coupon" && nextBillingDate) {
				success = await createFutureSubscription(planCode, nextBillingDate)
				subscription = (await getSubscriptionInfo()).subscription
			}
			// Create Plan
			else if (!planIsValid && billingIsValid) {
				success = await createSubscription(planCode)
				subscription = (await getSubscriptionInfo()).subscription
			}

			gtagTrack()

			// Update Billing Info
			// else {
			// 	redirectToUpdateBilling()
			// }
			if (onSubmit) onSubmit()
		} catch (err) {
			success = false
		}
		if (success && !isRenew) {
			const amount = `$${changeCost || 0}`
			const subscriptionDate = subscription?.next_billing
				? new Date(subscription.next_billing).toLocaleDateString()
				: undefined
			const nextDate =
				subscriptionDate || info?.nextDate || new Date().toLocaleDateString()
			// setAlertVariant('success')
			// setAlertMessage(
			// 	`Your subscription has been ${isActive ? 'updated' : 'reactivated'
			// 	}. You will be charged ${amount} on ${nextDate}.`
			// )
			setCardText(`Your subscription has been ${isActive ? 'updated' : 'reactivated'
				}. You will be charged ${amount} on ${nextDate}.`)
			setDisableButton(true)
			resetAfterChanges()
		} else {
			setAlertVariant('danger')
			setAlertMessage(
				`Could not ${isActive ? 'update' : 'reactivate'} subscription`
			)
		}
		// setCardIsShown(false)
		// onAlertOpen()
		setFormKey(formKey + 1)
	}

	const handleChangeSubsriptionIos = (payload: any) => {
		removeMessageByType('apple-change-sub-mobileapp')
		// alert(JSON.stringify(payload))
	}

	const handleSaveExternalSubscription = async (data: any) => {
		const localStorageRequestKey = localStorage.getItem('deviceRequestKey')
		// setIsSavingExternalSubscription(true)
		if ((messageData?.requestKey === localStorageRequestKey) && messageData?.payload) {
			// await axios.post('http://192.168.1.3:4000/api/v2/external-subscription', data).catch(err => alert(JSON.stringify(err)))
			await postReq('/v2/external-subscription', data).then(() => {
				removeMessageByType('google-success-sub-mobileapp')
				removeMessageByType('apple-success-sub-mobileapp')
				navReq('/')
			})
		}
	}

	const handleInitPurchaseSubscription = () => {
		const localStorageRequestKey = localStorage.getItem('deviceRequestKey')
		if (localStorageRequestKey && userInfo?.account_id) {
			sendDataToReactNative && sendDataToReactNative(
				{
					requestType: 'google-choose-plan-webapp',
					requestKey: localStorageRequestKey,
					payload: monthly ?
						{ amount: costs.monthly, planCode: costs.monthlyCode, type: isRenew ? 'renew' : 'change', account_id: userInfo.account_id }
						: { amount: costs.annual, planCode: costs.annualCode, type: isRenew ? 'renew' : 'change', account_id: userInfo.account_id },
					timestamp: new Date().getTime()
				}
			)
			// sendJsonMessage && sendJsonMessage({
			// 	requestType: 'google-choose-plan-webapp',
			// 	requestKey: localStorageRequestKey,
			// 	payload: monthly ?
			// 		{ amount: costs.monthly, planCode: costs.monthlyCode, type: isRenew ? 'renew' : 'change', account_id: userInfo.account_id }
			// 		: { amount: costs.annual, planCode: costs.annualCode, type: isRenew ? 'renew' : 'change', account_id: userInfo.account_id },
			// 	timestamp: new Date().getTime()
			// })
		}
	}

	// useEffect(() => {
	// 	// alert(JSON.stringify(messageData))
	// 	const localStorageRequestKey = localStorage.getItem('deviceRequestKey')

	// 	const changeSubApple = webSocketMessages.find(x => (x?.requestKey === localStorageRequestKey) && (x?.requestType === "apple-change-sub-mobileapp"))

	// 	const handleSucessSub = webSocketMessages.find(x => (x?.requestKey === localStorageRequestKey) && (x?.requestType === "google-success-sub-mobileapp"))

	// 	const handleSucessSubIos = webSocketMessages.find(x => (x?.requestKey === localStorageRequestKey) && (x?.requestType === "apple-success-sub-mobileapp"))

	// 	if (handleSucessSub) {
	// 		const externalData = { user_id: userInfo?.account_id, type: 'google', purchase_token: handleSucessSub.payload, is_expired: 0, app_account_token: localStorageRequestKey }
	// 		handleSaveExternalSubscription(externalData)
	// 	}
	// 	else if (userInfo?.account_id && handleSucessSubIos) {
	// 		const externalData = { user_id: userInfo?.account_id, type: 'apple', purchase_token: handleSucessSubIos.payload, is_expired: 0, app_account_token: localStorageRequestKey }
	// 		handleSaveExternalSubscription(externalData)
	// 	}

	// 	else if (changeSubApple)
	// 		handleChangeSubsriptionIos(changeSubApple.payload)

	// 	// alert(isSavingExternalSubscription)

	// }, [webSocketMessages]);


	useEffect(() => {
		// alert(JSON.stringify(messageData))
		if (messageData) {

			const localStorageRequestKey = localStorage.getItem('deviceRequestKey') || requestKey

			const handleSucessSub = (messageData.requestKey === requestKey || messageData.requestKey === localStorageRequestKey) && (messageData.requestType === "google-success-sub-mobileapp") ? true : false

			const handleSucessSubIos = (messageData.requestKey === requestKey || messageData.requestKey === localStorageRequestKey) && (messageData.requestType === "apple-success-sub-mobileapp") ? true : false

			if (handleSucessSub) {
				const externalData = { user_id: userInfo?.account_id, type: 'google', purchase_token: messageData.payload, is_expired: 0, app_account_token: localStorageRequestKey }
				handleSaveExternalSubscription(externalData)
			}
			else if (handleSucessSubIos) {
				const externalData = { user_id: userInfo?.account_id, type: 'apple', purchase_token: messageData.payload, is_expired: 0, app_account_token: localStorageRequestKey }
				handleSaveExternalSubscription(externalData)
			}
		}

	}, [messageData]);


	useEffect(() => {
		// handleGetExternalAccount()
		const localStorageRequestKey = localStorage.getItem('deviceRequestKey')
		if (lastRNBridgeMessage && userInfo) {
			const RNBridgeData = JSON.parse(lastRNBridgeMessage)

			if (RNBridgeData.requestKey === requestKey || RNBridgeData.requestKey === localStorageRequestKey) {
				setMessageData(RNBridgeData)
				setLastRNBridgeMessage(undefined)
			}
		}

	}, [lastRNBridgeMessage, userInfo]);

	useEffect(() => {
		if (!userInfo?.account_id) {
			setIsLoading(true)
		}
		else
			setIsLoading(false)
	}, [userInfo])

	// Plan ID Change Effect
	useEffect(() => {
		if (setPlanId) setPlanId(currentPlanId)
	}, [currentPlanId])

	// Selection Changed Effect
	useEffect(() => {
		if (
			initMonthly == null &&
			Object.keys(info).length &&
			initMonthly !== monthly
		) {
			setInitMonthly(monthly)
		} else if (initMonthly != null) {
			setOptionChanged(initMonthly != monthly)
		}
	}, [info, monthly])

	// Is Active Change Effect
	useEffect(() => {
		getBillingInfo()
		// setPlanCosts()
		setSubmitText(getSubmitText(isActive, isRenew))
	}, [isActive, isRenew, activeSale])

	// useEffect(() => {
	// 	setPlanCosts()
	// }, [offer, url, activeSale, isLoadingSale])

	useEffect(() => {
		let planCode;
		if (hasOffer || hasSale) {
			planCode = monthly ? planCodes.monthlyRenew : costs.annualCode
		}
		else
			planCode = monthly ? planCodes.monthlyRenew : planCodes.annualRenew

		setCurrentPlanCode(planCode)
	}, [monthly])

	// Render
	return (
		<div className='overflow-scroll-view'>
			<CouponModal
				email={userInfo?.email}
				signupMethod={(isNativeMobile && mobileType === "Ios") ? 'Ios' : (isNativeMobile && mobileType === "Android") ? 'Android' : 'Web'} showModal={showCouponModal}
				handleCloseModal={setShowCouponModal}
				afterBillingProcess={() => navReq('/#/')}
			/>
			<SubscriptionForm
				key={formKey}
				isExpiring={isExpiring}
				showInvalidBillingMessage={!isRenew && !billingIsValid}
				setMonthly={setMonthly}
				setParentCosts={setCosts}
				setHasPendingId={setHasPendingId}
				setCurrentPlanId={setCurrentPlanId}
				setIsActive={setIsActive}
				setInfo={setInfo}
				isAccount={isRenew ? false : true}
				side={side}
			/>
			{currentPlanCode && isRenew ? (
				<>
					<p onClick={() => { setShowCouponModal(true) }} className={`${!isMobileScreen ? 'pe-large' : ''} my-n2 coupon-btn`}>Enter Race Code<ArrowForwardIos /></p>
					<RecurlyProvider publicKey={RECURLY_PUBLIC_KEY}>
						<Elements>
							{
								!isInAppPayment ? (
									<div className={`${isRenew ? 'mt-3 mt-md-4' : ''} mb-4 other-btn`}>
										{/* <ApplePayButton planCode={currentPlanCode} afterBillingChange={afterBillingChange} tranxType="renew" /> */}
										{/* <GooglePayButton planCode={currentPlanCode} loadingGooglePay={loadingGooglePay} setLoadingGooglePay={setLoadingGooglePay} afterBillingChange={afterBillingChange} tranxType="renew" /> */}
									</div>
								) : undefined
							}
						</Elements>
					</RecurlyProvider>
				</>
			) : undefined}
			<div className={`${isRenew ? 'mt-3 mt-md-5' : 'mt-0 mt-md-3'}`}>
				<AccountSubmitButtons
					primaryText={isInAppPayment && subscriptionType && subscriptionType === "coupon" ? 'Subscribe' : isRenew || (isActive && subscriptionType && subscriptionType !== "coupon") ? submitText : (subscriptionType && subscriptionType === "coupon" && !billingIsValid) ? "Subscribe with Credit Card" : 'Change Plan'}
					primaryAction={!isInAppPayment ? onInitiatePlanChangeEvent : handleInitPurchaseSubscription}
					primaryDisabled={subscriptionType && subscriptionType == "coupon" && (billingIsValid || !billingIsValid) ? false : subscriptionType && subscriptionType == "coupon" && deviceRequestKey && !isRenew ? true : !isRenew && (!optionChanged || !billingIsValid)}
					secondaryText={isRenew || isExpiring ? undefined : 'Cancel Subscription'}
					secondaryAction={isRenew || isExpiring || isCoupon || isInactive ? undefined : onCancel}
					secondaryDisabled={isRenew || isExpiring || isCoupon || isInactive ? undefined : !isActive}
					isAccount
					addClassName={isRenew ? 'renew-btn' : undefined}
					newButton={true}
					isRenew={isRenew}
					nextBillingDate={nextBillingDate}
				/>
			</div>

			<MessageCard
				title={isRenew ? 'Renew Plan' : (subscriptionType === "coupon" && !billingIsValid) ? 'Subscribe' : 'Change Plan'}
				text={cardText}
				isShown={cardIsShown}
				setIsShown={setCardIsShown}
				onConfirm={onConfirmPlanChangeEvent}
				resetModalData={resetModalData}
				disableButton={disableButton}
				buttonText={isRenew ? 'Confirm Renew' : (subscriptionType === "coupon" && !billingIsValid) ? 'Confirm Subscription' : 'Confirm Change'}
				secondaryAction={isRenew ? redirectToUpdateBilling : undefined}
				secondaryButtonText={isRenew ? "Change Billing" : undefined}
			/>

		</div>
	)
}

export default WithAuth(Subscription, true)
