/* eslint-disable @typescript-eslint/no-unused-vars */
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import { CHANGE_ACCOUNT } from '../../modules/cliEnvValues'
import { AuthPageProps } from '../../modules/types'
import { DetailLine } from './SubscriptionForm'
import { isRequired } from '../../modules/validation'
import { InputInfo } from './SignInForm'
import useFormInfo, { FormInfo } from '../../hooks/useFormInfo'
// import GenericForm, { GenericFormProps } from './GenericForm'
import { FC, useEffect, useState } from 'react'
import { Form } from 'react-bootstrap'
import BasicInput from '../input/BasicInput'
import { GenericFormProps } from './GenericForm'
// import ApplePayButton from '../buttons/ApplePayButton'
import GooglePayButton from '../buttons/GooglePayButton'
export type { FormInfo }

// Billing Form Props
export type BillingFormProps = {
	setIsValid: (x: boolean) => any
	setInfo?: (x: FormInfo) => any
	userInfo: AuthPageProps['userInfo']
	hideDetails?: boolean
	hideDue?: boolean
	lastFour?: string
	billingType?: string
	planType?: string
	nextBillDate?: string
	totalDue?: string
	planCode?: string
	isAccount?: boolean
	isPastDue?: boolean
	isUpdate?: boolean
}

// Input Props
export const inputProps: GenericFormProps['inputs'] = [
	{
		id: 'first-input',
		placeholder: 'First Name',
		label: 'First Name',
		formKey: 'firstName',
		recurlyData: 'first_name',
		validations: [isRequired],
	},
	{
		id: 'last-input',
		placeholder: 'Last Name',
		label: 'Last Name',
		formKey: 'lastName',
		recurlyData: 'last_name',
		validations: [isRequired],
	},
	{
		id: 'zipcode-input',
		placeholder: 'Postal Code',
		label: 'Postal Code',
		formKey: 'zipCode',
		recurlyData: 'postal_code',
		validations: [isRequired],
	},
	{
		id: 'cc-input',
		placeholder: 'Credit Card',
		label: 'Credit Card',
		formKey: 'creditCard',
		isCreditCard: true,
	},
]

// Default Form Info
export const defaultFormInfo = {
	firstName: { value: '', isValid: false } as InputInfo,
	lastName: { value: '', isValid: false } as InputInfo,
	zipCode: { value: '', isValid: false } as InputInfo,
	creditCard: { value: '', isValid: true } as InputInfo,
}

// Account Form Info
export type AccountFormInfo = typeof defaultFormInfo

// Default Details
const DEFAULT_DETAILS = {
	'Current Billing': 'XXXX-XXXX-XXXX-XXXX',
	'Billing Type': 'N/A',
	'Billing Cycle': 'N/A',
	'Next Billing Cycle': 'N/A',
}

/**
 * Billing Form Component
 */

const BillingForm: FC<BillingFormProps> = ({
	setInfo,
	setIsValid,
	userInfo,
	hideDetails,
	hideDue,
	lastFour,
	billingType,
	planType,
	nextBillDate,
	totalDue,
	isPastDue,
	planCode,
	isAccount
}) => {
	// State
	const [details, setDetails] = useState(DEFAULT_DETAILS)
	const [cost, setCost] = useState('')
	const [showUpdate, setShowUpdate] = useState(false)

	// Hooks
	useFormInfo({ inputProps, defaultFormInfo, setInfo, setIsValid })

	// Details Change Effect
	useEffect(() => {
		; (async () => {
			const newDetails = { ...details }
			if (lastFour) newDetails['Current Billing'] = `XXXX-XXXX-XXXX-${lastFour}`
			if (billingType) newDetails['Billing Type'] = billingType
			if (nextBillDate) newDetails['Next Billing Cycle'] = billingType === "Coupon" ? 'N/A' : nextBillDate
			if (userInfo?.amount != null) {
				const newCost = userInfo?.amount
				let newCostText = ''
				if (newCost) {
					newCostText = `$${Math.round(newCost)}`
					setCost(newCostText)
				}
				if (planType && newCostText)
					newDetails['Billing Cycle'] = `${newCostText} ${planType}`
			}
			setDetails(newDetails)
		})()
	}, [lastFour, billingType, planType, userInfo, nextBillDate])

	// User Info Change Effect
	useEffect(() => {
		if (CHANGE_ACCOUNT) {
			// const invalidRedirectStatus = userInfo?.import_redirect_status === 0
			// if (invalidRedirectStatus) {
			// 	setShowUpdate(false)
			// } else {
			setShowUpdate(false)
			// }
		}
	}, [userInfo])

	// Render
	return (
		<div className='mt-n4'>
			{/* Update Billing Row */}
			{showUpdate && (
				<Row>
					<Col>
						<p className="mb-1 font-italic">
							<strong className="mb-1 mb-md-2 d-block">Billing Update Required</strong>
							{/* <br />
							<br /> */}
							Please update your billing information to avoid an increase in
							fees. Your subscription updates on {nextBillDate} for {cost}
						</p>
					</Col>
				</Row>
			)}

			{/* Main Row */}
			<Row className='align-left-justified'>
				{/* Details */}
				{!hideDetails && (
					<Col xs={12} md={12}>
						{/* {!hideDetails && <p className="billing--title mb-4">Billing</p>} */}
						{Object.keys(details).map((key, index) => (
							<Row key={index} className='billing-details'>
								<Col>
									<DetailLine
										title={key}
										details={details[key as keyof typeof details]}
									/>
								</Col>
							</Row>
						))}
					</Col>
				)}

				{/* Form */}
				<Col xs={12} md={hideDetails ? undefined : 12}>
					{!hideDetails && <p className="billing--title mb-0">Update Billing Details</p>}
					{isAccount && !hideDetails ? (
						<div className="mb-4 mt-4 other-btn">
							{/* <ApplePayButton planCode={planCode as string} tranxType="update" /> */}
							{/* <GooglePayButton planCode={planCode as string} tranxType="update" /> */}
						</div>
					) : undefined}

					<Form id={'billing-form'} className='mt-4'>
						<Row className='mb-2' xs={12}>
							<Col xs={6}>
								<BasicInput floatingLabel {...inputProps[0]} />
							</Col>
							<Col xs={6}>
								<BasicInput floatingLabel {...inputProps[1]} />
							</Col>
						</Row>

						<Row className='mb-2' xs={12}>
							<Col xs={12}>
								<BasicInput floatingLabel {...inputProps[3]} />
							</Col>
						</Row>

						<Row className='mb-2' xs={12}>
							<Col xs={12}>
								<BasicInput floatingLabel {...inputProps[2]} />
							</Col>
						</Row>

					</Form>

				</Col>

				{!hideDue && <Col>
					<p className='append-info'>Total Due: ${totalDue}</p>
				</Col>}

			</Row>

		</div>
	)
}

export default BillingForm
