import { FC} from 'react'
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';

import RSButton from './RSButton';

export type RSCircledSymbolProps = {
	symbol: string,
	title: string,
	subtitle: any,
	children?: any
	onClick?: Function,
	disabled?: boolean,
	className?: string,
	sx?: object,
	secondary?: boolean,
	styleTwo?: boolean
}


const RSCircledSymbol: FC<RSCircledSymbolProps> = ({symbol, children, sx = {}, title, subtitle, onClick, disabled, className, secondary = true, styleTwo}) => {

	const style = {
		pl: '15px',
		py: '15px',
		height: 'fit-content',
		width: '100%',
		maxWidth: '500px',
		textAlign: 'left',
		lineHeight: '1em !important',
		...sx
	}

	const handleClick = (e: any) => {
		onClick?.(e)
	}

	return <RSButton style2={styleTwo} customized secondary={secondary} card className={className ? className : ''} onClick={handleClick} sx={style} disabled={disabled ? disabled : false}>
		{!styleTwo && <Box sx={{pr: '20px', pl: '10px'}}>
			<Grid container sx={{height: '65px', width: '65px', textAlign: 'center', fontFamily: 'Poppins-SemiBold !important', fontSize: '25px !important', color: '#742F4E', border: '2px solid #742F4E', borderRadius: '50%'}} justifyContent='center' alignItems='center'>
				{symbol}
			</Grid>
		</Box>}
		<Box sx={{display: 'flex', flexDirection: 'column', width: '100%' }}>
			<Grid container xs={12} sx={{ color: '#010101', fontSize: '21px !important', fontFamily: `Poppins-${styleTwo ? 'SemiBold' : 'Medium'} !important`, lineHeight: '1.2em !important', letterSpacing: '-0.3px !important'}} alignItems='center'>
				{title}
			</Grid>
			<Grid container xs={12} sx={{ pt: '5px', pr: styleTwo ? 0 : '15px', color: '#010101', fontSize: `${styleTwo ? '16px' : '1em'} !important`, fontFamily: 'Poppins-Light !important', lineHeight: '1.2em !important'}}>
				{subtitle}
			</Grid>
			{children}
		</Box>
	</RSButton>
}

export default RSCircledSymbol;
