/* eslint-disable @typescript-eslint/no-unused-vars */

import Card from 'react-bootstrap/Card'
import PlayButton from '../buttons/PlayButton'
import Heart from '../logos/Heart'
import BodyComponent from './BodyComponent'
import classNames from 'classnames'
import ChromeCastButtons from '../buttons/ChromeCastButtons'
import { FC, useState, useEffect, useContext } from 'react'
// import Heart from '../logos/Heart'
import useQueryParams from '../../hooks/useQueryParams'
// import useWebSocket from 'react-use-websocket'
// import { WS_URL } from '../../modules/cliEnvValues'
import { getReq } from '../../modules/apiConsume'
import { WebSocketContext } from '../../WebsocketProvider'
import { IGoogleCastStore } from '../../store/createGoogleCastStore'
import useStore from '../../store/useStore'
import useRNBridge from '../../hooks/useRNBridge'
declare global {
    interface Window {
        initChromecast: () => any
    }
}

// Props
export type ImageCardProps = {
    videoId?: string
    type?: keyof typeof classes
    bgImage?: string
    logoImage?: string
    logoIsLg?: boolean
    logoIsMd?: boolean
    playButtonIsCentered?: boolean
    vimeoId?: number | string
    hasPlayButton?: boolean
    hasLikeButton?: boolean
    progress?: number
    isLiked?: boolean
    cardKey?: any
    favorites?: any
    onPlay?: () => any
    onLike?: () => any
    onClick?: () => any
    isComplete?: boolean
    isProgram?: boolean
    isSelected?: boolean
}
// Classes
const classes = {
    small: 'small-card',
    medium: 'home-detail-card',
    large: 'large-card',
    video: 'video-card',
    videoLg: 'video-card-lg',
    videoLgStretch: 'video-card-lg-stretch',
    responsiveCard: 'responsive-card',
    responsiveCardLg: 'responsive-card-lg',
    responsiveCardXl: 'responsive-card-xl',
    responsiveCardFill: 'responsive-card-fill',
    responsiveCardFull: 'responsive-card-full',
}

/**
 * ImageCard Component
 */

const ImageCard: FC<ImageCardProps> = ({
    bgImage,
    logoImage,
    logoIsLg,
    logoIsMd,
    type,
    playButtonIsCentered,
    hasPlayButton,
    hasLikeButton,
    vimeoId,
    progress,
    onPlay,
    onLike,
    favorites,
    videoId
    // onClick
}) => {
    // Properties
    const cardType = type || 'small'
    const className = classNames(
        classes[cardType],
        bgImage ? 'card-clear mx-n2' : undefined
    )
    const progressClassName =
        cardType.includes('lg') || cardType.includes('xl')
            ? 'rso-header-progress'
            : 'rso-video-progress'
    const hasLogo = logoImage ? true : false
    const { requestKey } = useQueryParams()
    const localStorageRequestKey = localStorage.getItem('deviceRequestKey')
    // State
    const [showVimeoPlayer, setShowVimeoPlayer] = useState(false)
    const [isPlaying, setIsPlaying] = useState(true)

    const { sendJsonMessage, lastJsonMessage } = useContext(WebSocketContext);
    const { setSelectedVideo, selectedVideo, setActiveVideo, activeVideo, setCastStatus, castStatus } = useStore((state: IGoogleCastStore) => state)

    const { sendDataToReactNative } = useRNBridge()

    // On Play Click
    const onPlayClick = (curPlaying = true) => {
        if (castStatus == 'active' || castStatus == 'playing') {
            // alert(vimeoId)
            if (selectedVideo != activeVideo || (!selectedVideo && vimeoId)) {
                // sendDataToReactNative({ requestType: 'google-cast-toggle-dialog', requestKey: requestKey || localStorageRequestKey, payload: {}, timestamp: new Date().getTime() })
                getReq(`/v2/exercises/raw-link?video_id=${vimeoId}`)
                    .then((res) => {
                        const media = res.data.result;

                        sendDataToReactNative({ requestType: 'google-cast-toggle-dialog', requestKey: requestKey || localStorageRequestKey, payload: media, timestamp: new Date().getTime() })

                        // setCastStatus('playing')
                        setActiveVideo(String(vimeoId))

                    })
                    .catch(err => {
                        console.log("ERR: VIDEO LINK", err);
                    })
            }
            else {
                sendDataToReactNative({ requestType: 'google-cast-toggle-dialog', requestKey: requestKey || localStorageRequestKey, payload: {}, timestamp: new Date().getTime() })
            }
        } else {
            if (vimeoId && !showVimeoPlayer) setShowVimeoPlayer(true)
            if (!isPlaying == curPlaying) setIsPlaying(curPlaying)
            if (onPlay) onPlay()
        }

    }

    const isLiked = favorites && favorites.find((x: any) => x.id === videoId) ? true : false;


    // Vimeo ID Change Effect
    useEffect(() => {
        setShowVimeoPlayer(false)
        window.initChromecast()
        setIsPlaying(false)

    }, [vimeoId])

    // Render
    return (
        <Card text="light" className={className} bg="black">
            <BodyComponent
                bgImage={bgImage}
                vimeoId={vimeoId}
                showVimeoPlayer={showVimeoPlayer}
                isPlaying={isPlaying}
            >
                <div
                    className="flex-content-center justify-content-center text-center video-wrapper-details"
                    style={{ height: '100%' }}
                // onClick={onClick}
                >
                    {hasLogo && (
                        <img
                            src={logoImage}
                            style={{
                                maxWidth: logoIsLg ? '85%' : logoIsMd ? '75%' : '50%',
                                maxHeight: logoIsMd ? '75%' : undefined,
                            }}
                        // onClick={onClick}
                        />
                    )}

                    {/* Play Button */}
                    {!localStorageRequestKey && ((hasPlayButton || vimeoId) && !showVimeoPlayer) && (
                        <PlayButton
                            isLower
                            isCentered={playButtonIsCentered}
                            onPlay={() => onPlayClick(true)}
                        />
                    )}

                    {(hasPlayButton || vimeoId) && localStorageRequestKey &&
                        ((activeVideo != selectedVideo && castStatus == 'playing') || castStatus != 'playing')
                        && !showVimeoPlayer && (
                            <PlayButton
                                isLower
                                isCentered={playButtonIsCentered}
                                onPlay={onPlayClick}
                            />
                        )}

                    {activeVideo == selectedVideo && castStatus == 'playing' && (
                        <h2 onClick={() => onPlayClick()} style={{ fontSize: 20 }}>CASTING</h2>
                    )}

                    {/* Like Button */}
                    {onLike && hasLikeButton && (
                        <Heart onLike={onLike} isLiked={isLiked} />
                    )}
                    {/* {(onLike && hasLikeButton && type !== "responsiveCardLg" ? true : false) && (
						<div className="fav-icon heart-image" onClick={() => setLiked(!liked)}>
							<input type="checkbox" checked={liked} id={bgImage} hidden />
							<label htmlFor={bgImage}>
								<div className="animation">
									<span className="dot-1"></span>
									<span className="dot-2"></span>
									<span className="dot-3"></span>
									<span className="dot-4"></span>
									<span className="dot-5"></span>
									<span className="dot-6"></span>
									<span className="dot-7"></span>
									<span className="dot-8"></span>
								</div>
								<img
									className="fill pointer"
									src="./images/icons/heart-red.svg"
									alt="Like"
								/>
								<img
									className="without-fill pointer"
									src="./images/icons/heart-white-big.png"
									alt="Like"
								/>
							</label>
						</div>
					)} */}
                    {(vimeoId ? true : false) && (
                        <ChromeCastButtons
                            vimeoId={vimeoId}
                            showVimeoPlayer={showVimeoPlayer}
                            onClick={onPlayClick.bind(null, false)}
                            isPlaying={isPlaying}
                        />
                    )}
                    {hasPlayButton && !showVimeoPlayer && (
                        <div className={progressClassName}>
                            {(progress ? true : false) && (
                                <div style={{ width: `${progress} % ` }} />
                            )}
                        </div>
                    )}
                </div>
            </BodyComponent>
        </Card>
    )
}

export default ImageCard