import WithAuth from '../../components/WithAuth'
import AppMenus from '../../components/layout/AppMenus'
import Button from 'react-bootstrap/Button'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import MainCard from '../../components/cards/MainCard'
import useJoinVideo from '../../hooks/useJoinVideo'
import AboutCard from '../../components/page-specific/video/AboutCard'
import { GET_STARTED_IMG } from '../../modules/values'
import { guideLinks, programLinks, webinarLinks } from '../../modules/linkInfo'
import { AuthPageProps } from '../../modules/types'
import { FC } from 'react'

// Button Text
const BUTTON_TEXT = {
	strength: 'Go to Strength Guide',
	form: 'Go to Mechanix',
	injury: 'Go to Injury Guide',
	race: 'Go to Training Guide',
}

// Links
const LINKS = {
	strength: guideLinks.Strength,
	form: programLinks.Mechanix,
	injury: webinarLinks['RunSmart Protocol'],
	race: guideLinks.Training,
}

/**
 * JoinVideo Component
 */

const JoinVideo: FC<AuthPageProps> = ({ userInfo }) => {
	// Hooks
	const { video } = useJoinVideo({ userInfo })

	// TO-DO: On Like
	const onLike = () => 0

	// Render
	return (
		<AppMenus hideBackButton>
			{/* Video Card Row */}
			<Row className='justify-content-center mb-md-2 mb-3'>
				<Col className="px-0 px-sm-3">
					<div className="video-wrapper blue-bg">
						<MainCard
							playButtonIsCentered
							type="responsiveCardLg"
							bgImage={GET_STARTED_IMG}
							vimeoId={video?.id}
							onLike={onLike}
						/>
					</div>
				</Col>
			</Row>

			{/* Title Desktop */}
			<Row className="d-none d-md-block text-center mb-5">
				<Col>
					<h1>Getting Started</h1>
				</Col>
			</Row>

			{/* Download Row */}
			{(userInfo?.join_reason ? true : false) && (
				<Row className="justify-content-center mb-5">
					<Col className="text-center">
						<span className="ps-3 ps-md-0 d-inline-block">
							<Button
								as="a"
								size="lg"
								variant="dark"
								className="rounded-pill color-primary-bg"
								href={
									userInfo
										? LINKS[userInfo.join_reason as keyof typeof LINKS]
										: '#'
								}
							>
								{BUTTON_TEXT[userInfo?.join_reason as keyof typeof BUTTON_TEXT]}
							</Button>
						</span>
					</Col>
				</Row>
			)}

			{/* About Card */}
			{(video?.id ? true : false) && (
				<Row className="mb-5 text-center justify-content-center">
					<Col xl={8} lg={10} xs={12}>
						<AboutCard
							aboutHeadlines={video?.headlines || []}
							aboutDescriptions={video?.descriptions || []}
							className="text-center"
						/>
					</Col>
				</Row>
			)}
		</AppMenus>
	)
}

export default WithAuth(JoinVideo)
