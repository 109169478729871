/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/no-empty-function */
import { FC, useState, useEffect, useRef } from 'react'
import { Elements, RecurlyProvider } from '@recurly/react-recurly'
import Box from '@mui/material/Box';
import Checkout from '../../../account/checkout/CheckoutV2';
import { RECURLY_PUBLIC_KEY } from '../../../../modules/cliEnvValues'

export type ChooseAPlanProps = {
    done?: any,
		bodyData?: any,
		setBodyData?: any,
		nextAvailability?: any
}

const ChooseAPlan: FC<ChooseAPlanProps> = ({done, nextAvailability}) => {

    return <Box sx={{display: 'flex', flexDirection: 'column', width: '100%'}}>
			<RecurlyProvider publicKey={RECURLY_PUBLIC_KEY}>
				<Elements>
					<Checkout nextAvailability={nextAvailability} done={done}/>
				</Elements>
			</RecurlyProvider>
		</Box>
}

export default ChooseAPlan
