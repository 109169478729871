/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useCallback, useEffect, useState } from 'react'
import { Button, Modal } from 'react-bootstrap'
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';
import { DAYS, DAYS_ALT, DAYS_OF_WEEK, DAYS_OF_WEEK_ALT } from '../../pages/training-plan/build/DaysOptions';
import { UserOverrideRecord } from '../../api/v2/types';
import { CreateUserOverride } from '../../modules/trainingPlanActions';
import useStore from '../../store/useStore';
import { ITrainingPlanStore } from '../../store/createTrainingPlanStore';
import { IComponentStore } from '../../store/createComponentStore';

const EditScheduleModal = (props: any) => {
    const {
        loadingValues,
        setSelectedDay,
        getDailyActivityDisplay,
        marathonDailyValues,
        userInfo,
        selectedRace,
        selectedDay,
        selectedWeek,
        dayData,
        show,
        handleClose,
        getUserOverrides,
        getUserRestComments
    } = props

    const { userOverrides } = useStore((state: ITrainingPlanStore) => state)

    const [daysOfWeek, setDaysOfWeek] = useState<Array<any>>([])
    const [newSetOrder, setNewSetOrder] = useState<Array<UserOverrideRecord>>([])
    const [currentOverrides, setCurrentOverrides] = useState<Array<UserOverrideRecord>>([])

    const [isLoading, setIsLoading] = useState(false)
    const [hasErrorSaving, setHasErrorSaving] = useState(false)
    const [isSuccessSave, setIsSuccessSave] = useState(false)
    const { setToast, toast } = useStore((state: IComponentStore) => state)

    const onDragEnd = (result: any) => {

        if (!result.destination) {
            return;
        }

        if (currentOverrides && currentOverrides.length > 0) {

            const items = reorder(
                currentOverrides,
                result.source.index,
                result.destination.index
            );

            setCurrentOverrides(items)
        }
        else if (currentOverrides && currentOverrides.length == 0) {
            const items = reorder(
                daysOfWeek,
                result.source.index,
                result.destination.index
            );

            setDaysOfWeek(items)
        }
    }

    const handleSave = async () => {
        setIsLoading(true)
        const saveOverrideData = await CreateUserOverride(newSetOrder)
        if (saveOverrideData && saveOverrideData.data.messages === "success") {
            setIsLoading(false)
            getUserOverrides()
            getUserRestComments()
            setIsSuccessSave(true)
            handleClose()
        }
        else {
            setIsLoading(false)
            setHasErrorSaving(true)
            handleClose()
        }
    }

    const reorder = (list: Array<any>, startIndex: number, endIndex: number) => {
        const result = Array.from(list);
        const [removed] = result.splice(startIndex, 1);
        result.splice(endIndex, 0, removed);

        const newSet: Array<UserOverrideRecord> = []

        if (currentOverrides && currentOverrides.length > 0) {
            result.forEach((data, idx) => {
                const newData = marathonDailyValues.find((x: any) => x?.marathon?.id == data.mid)
                if (newData)
                    newSet.push({ user_id: userInfo?.uid, day: idx, mid: newData.marathon.id, week: newData.marathon.week, rest_day: 'N', old_day: data.day })
                else
                    newSet.push({ user_id: userInfo?.uid, day: idx, mid: 0, week: selectedWeek.key, rest_day: 'Y', old_day: data.day })
            });

            setNewSetOrder(newSet)
        }

        else if (currentOverrides && currentOverrides.length == 0) {
            result.forEach((data, idx) => {
                const newData = marathonDailyValues.find((x: any) => x?.marathon?.day == data.id + 1)
                if (newData)
                    newSet.push({ user_id: userInfo?.uid, day: idx, mid: newData.marathon.id, week: newData.marathon.week, rest_day: 'N', old_day: data.id })
                else
                    newSet.push({ user_id: userInfo?.uid, day: idx, mid: 0, week: selectedWeek.key, rest_day: 'Y', old_day: data.id })
            });
            setNewSetOrder(newSet)
        }

        return result;
    };

    useEffect(() => {
        setToast({
            show: hasErrorSaving,
            title: "Error Message",
            message: "An error occurred while saving new schedule ",
            variant: "danger",
            onClose: () => setHasErrorSaving(false),
            duration: 5000
        })
    }, [hasErrorSaving])

    useEffect(() => {
        // setSelectedRaceDate(new Date())
        setToast({
            show: isSuccessSave,
            title: "Save Plan Schedule",
            message: "Plan schedule has been successfully updated",
            variant: "success",
            onClose: () => {
                setIsSuccessSave(false)
            },
            duration: 3000,
        })
    }, [isSuccessSave])

    useEffect(() => {
        setDaysOfWeek(userInfo?.monday_start ? DAYS_ALT : DAYS)

        const overrides = userOverrides?.filter((x: any) => x.week == selectedWeek.key) as Array<UserOverrideRecord>
        if (overrides && overrides.length > 0)
            setCurrentOverrides(overrides)
        else {
            setCurrentOverrides([])
            setNewSetOrder([])
        }
    }, [show])

    // useEffect(() => {
    //     console.log(newSetOrder)
    // }, [newSetOrder])


    return (
        <>
            <DragDropContext onDragEnd={onDragEnd} >
                <Modal
                    className="common-modal build-plan change-plan"
                    show={show}
                    onHide={handleClose}
                >
                    <Modal.Header closeButton>
                        <h2 className="text-capitalize mb-0">
                            <span className="traning-popup--title text-capitalize d-none d-md-block">
                                Edit Schedule
                            </span>{' '}
                            {/* <span className="text-uppercase h1 d-md-none">EDIT SCHEDULE</span> */}
                            <span className="traning-popup--title mb-0 mb-md-3 d-md-none">
                                Edit Schedule
                            </span>
                        </h2>
                        <p className="mt-0 mt-md-3 mb-0 font-weight-400">
                            Drag days to reorder them.
                            <br /> Save when finished.
                        </p>
                    </Modal.Header>
                    <Modal.Body>
                        <Droppable droppableId="droppable">
                            {(provided, snapshot) => (
                                <div
                                    {...provided.droppableProps}
                                    ref={provided.innerRef}
                                >
                                    <ul className="list-unstyled mb-0 mx-md-5 mx-4">
                                        {
                                            currentOverrides && currentOverrides?.length > 0 ? (
                                                currentOverrides.map((value, idx) => (
                                                    <Draggable key={value.day} draggableId={value.day.toString()} index={idx}>
                                                        {(provided, snapshot) => (
                                                            <div ref={provided.innerRef} {...provided.draggableProps} {...provided.dragHandleProps}>
                                                                <li className="d-flex justify-content-between align-items-center radius-10 p-3 mb-2">
                                                                    <h5 className="text-uppercase m-0 font-weight-semibold">
                                                                        {daysOfWeek.find(x => x.id == value.day)?.day}
                                                                    </h5>
                                                                    <p className="text-primary m-0">
                                                                        {getDailyActivityDisplay(
                                                                            marathonDailyValues.find((x: any) => x?.marathon?.id == value.mid),
                                                                            userInfo,
                                                                            marathonDailyValues.find((x: any) => x?.marathon?.id == value.mid)?.marathonPaces,
                                                                            selectedRace?.value as number,
                                                                            marathonDailyValues.find((x: any) => x?.marathon?.id == value.mid)?.marathon?.race_day
                                                                        )?.toUpperCase()}
                                                                    </p>
                                                                </li>
                                                            </div>
                                                        )}
                                                    </Draggable>
                                                ))
                                            ) : null
                                        }
                                        {currentOverrides && currentOverrides?.length == 0 ?
                                            daysOfWeek.map((day, idx) => (
                                                <Draggable key={day.day} draggableId={day.day} index={idx}>
                                                    {(provided, snapshot) => (
                                                        <div ref={provided.innerRef} {...provided.draggableProps} {...provided.dragHandleProps}>
                                                            <li className="d-flex justify-content-between align-items-center radius-10 p-3 mb-2">
                                                                <h5 className="text-uppercase m-0 font-weight-semibold">
                                                                    {day.day}
                                                                </h5>
                                                                <p className="text-primary m-0">
                                                                    {getDailyActivityDisplay(
                                                                        marathonDailyValues.find((x: any) => x?.marathon?.day == day.id + 1),
                                                                        userInfo,
                                                                        marathonDailyValues.find((x: any) => x?.marathon?.day == day.id + 1)?.marathonPaces,
                                                                        selectedRace?.value as number,
                                                                        marathonDailyValues.find((x: any) => x?.marathon?.day == day.id + 1)?.marathon?.race_day
                                                                    )?.toUpperCase()}
                                                                </p>
                                                            </li>
                                                        </div>
                                                    )}
                                                </Draggable>
                                            )) : null}
                                        {provided.placeholder}
                                    </ul>
                                </div>)}
                        </Droppable>
                        <div className="center-align">
                            <Button
                                disabled={isLoading}
                                variant="primary"
                                onClick={handleSave}
                                className="rounded-pill mt-3 color-primary-bg save-btn center-align text-capitalize letter-space"
                            >
                                {isLoading ? "Saving Changes..." : "Save"}
                            </Button>
                        </div>
                    </Modal.Body>
                </Modal>
            </DragDropContext >

            {/* <ToastContainer position='top-center' style={{ marginTop: 5 }}>
                <Toast bg={toast?.variant} show={toast?.show} delay={toast?.duration} autohide onClose={toast?.onClose}>
                    <Toast.Header>
                        <strong className="me-auto">{toast?.title}</strong>
                        <small></small>
                    </Toast.Header>
                    <Toast.Body style={{ color: '#FFF' }}>{toast?.message}</Toast.Body>
                </Toast>
            </ToastContainer> */}
        </>
    )
}

export default EditScheduleModal