/* eslint-disable @typescript-eslint/no-unused-vars */
import WithAuth from '../../components/WithAuth'
import RSNavCont from '../../components/layout/RSNavCont'
import AppMenus from '../../components/layout/AppMenus'
import MainCard from '../../components/cards/MainCard'
import Button from 'react-bootstrap/Button'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import PageTitle from '../../components/text/PageTitle'
import Download from '@mui/icons-material/Download'
import TextCard from '../../components/cards/TextCard'
import useWebinarPage from '../../hooks/useWebinarPage'
import useAlert from '../../hooks/useAlert'
import useSlideDirection from '../../hooks/useSlideDirection'
import useIsMobileScreen from '../../hooks/useIsMobileScreen'
import GenericAlert from '../../components/misc/GenericAlert'
import Motion from '../../components/animation/Motion'
import { WEBINAR_DOWNLOADS } from '../../modules/values'
import { UserInfoProps } from '../../modules/types'
import { DownloadModal } from '../guides/Guide'
// import { backNav } from '../../modules/apiConsume'
import { AllRSCategories } from '../../modules/types'
import { FC, useState, useEffect } from 'react'
import featuredPrograms from '../../modules/programs/featured-programs.json'
import useProgramFavorites from '../favorites/useProgramFavorites'
import { Card, Spinner } from 'react-bootstrap'
import categories from '../../modules/programs/program-categories.json'
import allPrograms from '../../modules/programs/featured-programs.json'
import { ArrowBack } from '@mui/icons-material'
import { backNav } from '../../modules/apiConsume'

import { slideInRight, slideOutRight, slideInLeft, slideOutLeft } from 'react-animations'
import styled, { keyframes } from 'styled-components';

const AslideInLeft = keyframes`${slideInLeft}`;
const AslideInRight = keyframes`${slideInRight}`;

const AslideOutLeft = keyframes`${slideOutLeft}`;
const AslideOutRight = keyframes`${slideOutRight}`;

const DivInLeft = styled.div` animation: 0.3s ${AslideInLeft};`;
const DivInRight = styled.div` animation: 0.3s ${AslideInRight};`;

const DivOutLeft = styled.div` animation: 0.3s ${AslideOutLeft};`;
const DivOutRight = styled.div` animation: 0.3s ${AslideOutRight};`;

// Props
export type WebinarProps = UserInfoProps & {
	webinarType: keyof AllRSCategories['webinars']
	noDownloads?: boolean
}

// Get Modal Title
const getModalTitle = (webinarType: string) => `${webinarType} Guide`

/**
 * Webinar Component
 */

const Webinar: FC<WebinarProps> = ({ webinarType, noDownloads, userInfo }) => {
	// State
	const [modalOpen, setModalOpen] = useState(false)
	const [modalTitle, setModalTitle] = useState(getModalTitle(webinarType))
	const [isPlaying, setIsPlaying] = useState(false)
	const [isFavorite, setIsFavorite] = useState(false)

	const [isLoadingMark, setIsLoadingMark] = useState(false)

	const slideDirection = useSlideDirection();
	const isMobile = useIsMobileScreen()

	// Hooks
	const { headline, description, image, video, progressInfo } = useWebinarPage({
		webinarType,
	})
	const {
		showAlert,
		onAlertOpen,
		onAlertClose,
		alertTitle,
		alertMessage,
		setAlertMessage,
		alertVariant,
	} = useAlert()

	// TO-DO: On Like
	const onLike = () => {
		null
	}

	const { toggleProgramFavorite, programFavorites } = useProgramFavorites(userInfo?.account_id as number, isFavorite)

	const handleLikeProgram = async (checked: boolean) => {
		const program = getProgram();
		setIsLoadingMark(true)
		if (userInfo?.account_id && video) {
			const current = programFavorites.find(x => x.program_id == program?.Program_ID)
			await toggleProgramFavorite(program?.Program_ID as number, userInfo.account_id, current);
			setIsFavorite(!checked)
		}
		setIsLoadingMark(false)
	}

	const getProgram = () => {
		const program = featuredPrograms.find((x => x.Title === webinarType))
		return program
	}

	// Download Click
	const onDownloadClick = () => setModalOpen(true)

	// Get Email ID
	const getEmailId = () => {
		const lowerTitle = modalTitle.toLowerCase()
		return lowerTitle.includes('40')
			? 84
			: lowerTitle.includes('60')
				? 82
				: lowerTitle.includes('protocol')
					? 77
					: 83
	}

	// Get Guide Type
	const getGuideType = () => ''

	// Get File
	const getFile = () => WEBINAR_DOWNLOADS[webinarType]

	// Webinar Type Change Effect
	useEffect(() => {
		setModalTitle(getModalTitle(webinarType))
	}, [webinarType])

	useEffect(() => {
		const program = getProgram();
		if (programFavorites) {
			const current = programFavorites?.find(x => x.program_id == program?.Program_ID)
			if (current) {
				if (current.favorited == 1)
					setIsFavorite(true)
				else
					setIsFavorite(false)
			}
		}

	}, [programFavorites])

	// Render
	return (
		<Motion
			isVisible={true}
			isMobile={isMobile}
			direction={slideDirection.current}
		>
			<RSNavCont>
			  {/* Alert */}
			  <GenericAlert
				  show={showAlert}
				  title={alertTitle}
				  message={alertMessage}
				  variant={alertVariant}
				  onClose={onAlertClose}
			  />

			  {/* Page */}
				{/* Video Card Row */}
				<Row className="get-started-training-plan mb-md-2 mb-3 mt-md-0">
					<Row className='d-none d-md-block mx-lg-n4 mt-4'>
						<Col md={1}>
							<span className="has-pointer back-btn w-auto pt-1" onClick={backNav}>
								<ArrowBack />
							</span>
						</Col>
						<Col md={11}>
							<h3 style={{ cursor: 'pointer', marginTop: '0px' }} onClick={backNav} className='mx-5'>{webinarType}</h3>
						</Col>
					</Row>

					<Col sm={12} >
						<div className="video-wrapper mx-n3 mx-md-0 w-auto blue-bg">
							{!isPlaying ? (
								<h5 className="heading-meta-data text-white fw-regular">
									{categories.find(x => x.program_ids.includes(allPrograms.find(x => x.Program === webinarType)?.Program_ID as number || allPrograms.find(x => x.Title === webinarType)?.Program_ID as number))?.category || ''}
								</h5>
							) : null}
							<MainCard
								hasPlayButton
								playButtonIsCentered
								type="responsiveCardLg"
								bgImage={video.image}
								vimeoId={video.id}
								onLike={onLike}
								progress={progressInfo?.progress}
								progressPercent={progressInfo?.progress}
								onPlay={() => setIsPlaying(true)}
								hasCheck
							/>
							{!isPlaying && !isLoadingMark ? (
								<div className="fav-icon heart-image">
									<input type="checkbox" checked={isFavorite} onChange={e => handleLikeProgram(e.target.checked)} id="heart" hidden />
									<label htmlFor="heart">
										<div className="animation">
											<span className="dot-1"></span>
											<span className="dot-2"></span>
											<span className="dot-3"></span>
											<span className="dot-4"></span>
											<span className="dot-5"></span>
											<span className="dot-6"></span>
											<span className="dot-7"></span>
											<span className="dot-8"></span>
										</div>
										<img
											className="fill pointer"
											src="./images/icons/heart-red.svg"
											alt="Like"
										/>
										<img
											className="without-fill pointer"
											src="./images/icons/heart-white-big.png"
											alt="Like"
										/>
									</label>
								</div>
							) :
								!isPlaying && isLoadingMark ? (<div className="fav-icon heart-image mb-n2 mx-n1">
									<Spinner animation='border' variant='danger' />
								</div>) : undefined
							}

							{!isPlaying ? (
								<div className="banner-info">
									<h3 className="text-white fw-normal text-capitalize">
										{/* {selectedVideoType === "Intro" ? (
													<>
														{exerciseData && exerciseData.length > 0 ? "Intro : " + exerciseData[0].exercise_title : ''}
													</>
												) : ( */}
										<>
											{video.headingTitle || ''}
										</>
										{/* )
												} */}
									</h3>
									{/* <h4 className="text-white text-capitalize">
											{currentWorkoutVideo?.headingTitle}
										</h4> */}

								</div>
							) : null}

							{!isPlaying ? (
								<div className="tick-mark">
									<img
										src={`${progressInfo?.progress >= 80 ? './images/icons/checkmark-done.svg' : './images/icons/checkmark.svg'}`}
										alt="Like"
									/>
								</div>
							) : undefined}

						</div>
					</Col>
					<Col sm={12} >
						{/* Download Row */}
						{!noDownloads ? (
							<>
								<Row className="mb-2 text-center mt-md-n5 mb-4">
									<Col>
										<Button
											size="lg"
											variant="dark"
											className="rounded-pill color-primary-bg"
											onClick={onDownloadClick}
										>
											<Download className="me-1" />
											Download Guide
										</Button>
									</Col>
								</Row>
								{/* Info Row */}
								<Row>
									<Col xs={12} className="mb-4">
										{/* <TextCard title={headline} text={description} /> */}
										<Card className='border-0 p-4 p-md-5'  >
											<p>Download the PDF above for more specifics regarding this program, including how to incorporate them into practice. <br />Adding spinal stability through this program will make you a faster and more durable runner.</p>
										</Card>
									</Col>
								</Row>
							</>
						) : <Row>
							<Col xs={12} className="mb-4 px-4">
								{/* <TextCard title={headline} text={description} /> */}
								<Card className='border-0 p-4 p-md-5'  >
									<p>{video.descriptions}</p>
								</Card>
							</Col>
						</Row>}
					</Col>
				</Row>



				{/* Title Row */}
				{/* <Row className="justify-content-center">
				<Col className="text-center">
					<PageTitle className="page-title-sm" text={webinarType} />
				</Col>
			</Row> */}

				{/* Back Row */}
				{/* <Row className="justify-content-center mb-5">
				<Col className="text-center">
					<Button
						size="lg"
						variant="dark"
						className="rounded-pill"
						onClick={backNav}
					>
						Back to Webinars
					</Button>
				</Col>
			</Row> */}


				{/* Download Modal */}
				<DownloadModal
					title={modalTitle}
					show={modalOpen}
					setShow={setModalOpen}
					userInfo={userInfo}
					getEmailId={getEmailId}
					getGuideType={getGuideType}
					onAlertOpen={onAlertOpen}
					setAlertMessage={setAlertMessage}
					getFile={getFile}
				/>
			</RSNavCont>
		</Motion>
	)
}

export default WithAuth(Webinar)
