import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { FC } from 'react';
import useTrainingPlanUtils from '../../../hooks/useTrainingPlanUtils';
import { AvTimerRounded } from '@mui/icons-material';


const GarminLaps: FC<{ laps: any[], userInfo: any }> = ({ laps, userInfo }) => {

    const { getGarminPace, ConvertMetersToMiles, ConvertS2M } = useTrainingPlanUtils();

    return (
        <div className="">
            {laps.length > 0 ? (
                <TableContainer>
                    <div className='m-2'><AvTimerRounded className='mt-n1' /> Laps</div>
                    <Table size="small">
                        <TableHead>
                            <TableRow>
                                <TableCell width={50} className=''>Lap</TableCell>
                                <TableCell width={120} className=''>Distance</TableCell>
                                <TableCell width={140} className=''>Time/Interval</TableCell>
                                <TableCell width={300} className=''>Avg. Pace</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {laps.map((row: any, idx: number) => (
                                <TableRow
                                    key={idx}
                                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                >
                                    <TableCell>{idx + 1}</TableCell>
                                    <TableCell>{
                                        userInfo?.km == 'Y' ? String((idx > 0 ? (row.totalDistanceInMeters - laps[idx - 1].totalDistanceInMeters) / 1000 : row.totalDistanceInMeters / 1000))?.substring(0, 4) + `${Number(String((idx > 0 ? (row.totalDistanceInMeters - laps[idx - 1].totalDistanceInMeters) / 1000 : row.totalDistanceInMeters / 1000))) <= 1 ? ' km' : ' kms'}`
                                            : ConvertMetersToMiles((idx > 0 ? (row.totalDistanceInMeters - laps[idx - 1].totalDistanceInMeters) : row.totalDistanceInMeters))?.toFixed(2) + `${Number(ConvertMetersToMiles((idx > 0 ? Number(row.totalDistanceInMeters - laps[idx - 1].totalDistanceInMeters) : row.totalDistanceInMeters))?.toFixed(1)) <= 1 ? ' mile' : ' miles'}`}</TableCell>
                                    <TableCell>
                                        {((idx > 0 ? ConvertS2M(row.timerDurationInSeconds - laps[idx - 1].timerDurationInSeconds) : ConvertS2M(row.timerDurationInSeconds)))}
                                    </TableCell>
                                    {/* <TableCell>{getGarminPace(userInfo?.km == 'Y' ? true : false, row.speedMetersPerSecond)}</TableCell> */}
                                    <TableCell>
                                        {
                                            idx == laps.length - 1 || (userInfo?.km == 'Y' && Number(String((idx > 0 ? (row.totalDistanceInMeters - laps[idx - 1].totalDistanceInMeters) / 1000 : row.totalDistanceInMeters / 1000))) < 1 || userInfo?.km != 'Y' && Number(ConvertMetersToMiles((idx > 0 ? Number(row.totalDistanceInMeters - laps[idx - 1].totalDistanceInMeters) : row.totalDistanceInMeters))?.toFixed(1)) < 1)
                                                ? getGarminPace(userInfo?.km == 'Y' ? true : false, row.speedMetersPerSecond) :
                                                ((idx > 0 ? ConvertS2M(row.timerDurationInSeconds - laps[idx - 1].timerDurationInSeconds) + (idx !== laps.length - 1 ? userInfo?.km == 'Y' ? ' / km' : ' / mile' : '') : ConvertS2M(row.timerDurationInSeconds) + (idx !== laps.length - 1 ? userInfo?.km == 'Y' ? ' / km' : ' / mile' : '')))

                                        }
                                        {/* {idx !== laps.length - 1 ? userInfo?.km == 'Y' ? ' / km' : ' / mile' : ''} */}
                                    </TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer >

            ) : undefined}

        </div >
        // <TableContainer component={Paper}>
        //     <Table size="small" aria-label="a dense table">
        //         <TableHead>
        //             <TableRow>
        //                 <TableCell>Time</TableCell>
        //                 <TableCell>Pace</TableCell>
        //                 <TableCell>Distance</TableCell>
        //             </TableRow>
        //         </TableHead>
        //         <TableBody>
        //             {laps.map((row: any) => (
        //                 <TableRow
        //                     key={row.name}
        //                     sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
        //                 >
        //                     <TableCell>
        //                         {(row.timerDurationInSeconds / 60).toFixed(2)} mins
        //                     </TableCell>
        //                     <TableCell>{getGarminPace(userInfo?.km == 'Y' ? true : false, row.speedMetersPerSecond)}</TableCell>
        //                     <TableCell>{userInfo?.km == 'Y' ? (row.totalDistanceInMeters / 1000).toFixed(2) + ' kms' : ConvertMetersToMiles((row.totalDistanceInMeters)).toFixed() + ' miles'}</TableCell>
        //                 </TableRow>
        //             ))}
        //         </TableBody>
        //     </Table>
        // </TableContainer >
    );
}

export default GarminLaps