import classNames from 'classnames'
import { FC } from 'react'

// Props
export type PageTitleProps = {
	text: string
	isMobileTitle?: boolean
	className?: string
}

/**
 * PageTitle Component
 */

const PageTitle: FC<PageTitleProps> = ({ text, isMobileTitle, className }) => {
	// Properties
	const curClassName = classNames(
		isMobileTitle ? 'page-title-div' : 'default-title',
		className
	)

	// Render
	return (
		<div className={curClassName}>
			<h2 className="page-title">{text}</h2>
		</div>
	)
}

export default PageTitle
