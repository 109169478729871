/* eslint-disable @typescript-eslint/no-unused-vars */
import Box from '@mui/material/Box';
import InstabotHide from '../../components/styles/InstabotHide'
import AppMenus from '../../components/layout/AppMenus'
import RSButton from '../../components/buttons/RSButton'
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import GenericAlert from '../../components/misc/GenericAlert'
import useAlert from '../../hooks/useAlert'
import AccountSubmitButtons, { PrimaryButton } from '../../components/buttons/AuthSubmitButtons'
import useQueryParams from '../../hooks/useQueryParams'
import useAppStorage from '../../hooks/useAppStorage'
import useLocalStorage from '../../hooks/useLocalStorage'
import { sha1 } from 'locutus/php/strings'

// import Badge from 'react-bootstrap/Badge'
import {
    APPLE_CLIENT_ID, APPLE_REDIRECT_URI, AUTH_COOKIE,
    GOOGLE_CLIENT_AUTH,
    GOOGLE_CLIENT_ID,
    HAS_SALE,
    IMPACT_CAMPAIGN_ID,
    // IMPACT_TRIAL_ID,
    // WS_URL
} from '../../modules/cliEnvValues'
// import { aboutLinks } from '../../modules/values'
import SignInForm, {
    SignInFormInfo,
    defaultFormInfo,
} from '../../components/forms/SignInForm'
import { navReq } from '../../modules/apiConsume'
import { createImpactClickId, logIn, signUp } from '../../modules/accountActions'
import { FC, useState, useEffect, useContext } from 'react'
import { useLocation } from 'react-router-dom'
import useStore from '../../store/useStore'
import { ICurrentUser } from '../../store/createAccountStore'
import classNames from 'classnames'
import AppleIcon from '@mui/icons-material/Apple';
import GoogleIcon from '@mui/icons-material/Google';
import MailIcon from '@mui/icons-material/Mail';
import { appleAuthHelpers } from 'react-apple-signin-auth';
import { useGoogleLogin } from '@react-oauth/google';
import axios from 'axios'
import { IAppleTokenDetails, IAppleUser, IGoogleUser } from '../../store/types'
import jwt_decode from "jwt-decode";
import { updateAccountInfo } from '../../modules/accountActions'
import startCase from 'lodash.startcase'
import secureLocalStorage from "react-secure-storage";
// import { Spinner } from 'react-bootstrap'
import ReactPixel from 'react-facebook-pixel';
import useScript from '../../hooks/useScript'
import useDeviceDetect from '../../hooks/useDeviceDetect'
import useSale from '../../hooks/useSale'
import { IComponentStore } from '../../store/createComponentStore'
// import useWebSocket from 'react-use-websocket'
import useStoreWSMessages from '../../hooks/useStoreWSMessages'
import { ArrowForwardIos } from '@mui/icons-material'
import { WebSocketContext } from '../../WebsocketProvider'



// Props
export type SignInProps = {
    isSignUp?: boolean,
    pageType?: string
}

// URL With Offer
export const urlWithOffer = (url: string, offerType?: number) =>
    offerType ? `${url}?offer=${offerType}` : url

// Sign In Header
const SIGNIN_HEADER = `
Get everything you need for fast & injury-free running - all in one place!
`
const SIGNIN_HEADER_STR = `Create an account & choose a free plan to access your free workouts!`

const SUB_HEADER = `Click below and create your account now.`

const ChooseSignUp: FC<SignInProps> = ({ isSignUp, pageType }) => {
    const { setHasOffer, setGoogleUser } = useStore((state: ICurrentUser) => state)

    const { mobileType } = useDeviceDetect()

    const { activeSale, isLoadingSale } = useSale()
    const [hasSale, setHasSale] = useState(false)
    const { setIsLoading: setIsLoadingMain, setLoadingMsg, setIsSuccess } = useStore((state: IComponentStore) => state)

    const [messageData, setMessageData] = useState<{ requestKey: string, payload: any, requestType: string, timestamp: number } | undefined>();

    // Params
    const { offer, development, irclickid, requestKey, newVersion } = useQueryParams()

    const NEW_VERSION = newVersion || localStorage.getItem('appVersion') || undefined

    const url = useLocation();
    // useScript('impact.js', url)
    useScript('identify.js', url)
    useScript('conversion.js', url)

    const mainTitle = isLoadingSale ? 'Join RunSmart' : hasSale ? 'Join RunSmart' : 'Join RunSmart & start your 14-day free trial.'
    // Properties
    const title = isSignUp && pageType !== "strength" ? mainTitle : isSignUp && pageType === "strength" ? "Strength Workouts for Runners" : 'Log In'

    const linkHref = isSignUp
        ? undefined
        : `/#/signup${HAS_SALE ? '?offer=2' : ''}`
    const linkName = isSignUp ? undefined : 'Sign Up'
    const linkDetails = isSignUp
        ? undefined
        : "Don't have an account?"
    const [info, setInfo] = useState<SignInFormInfo>(defaultFormInfo)
    const [isValid, setIsValid] = useState(false)
    const [isLoading, setIsLoading] = useState(false)
    const [redirect, setRedirect] = useState('')

    const offerType = Number(offer) || undefined

    const localStorageRequestKey = localStorage.getItem('deviceRequestKey')

    // Hooks
    useAppStorage()
    const { storedValue: authCookie } = useLocalStorage({ key: AUTH_COOKIE })
    const {
        showAlert,
        onAlertOpen,
        onAlertClose,
        alertTitle,
        alertMessage,
        setAlertMessage,
        alertVariant,
        setAlertVariant,
    } = useAlert()

    // const { sendJsonMessage, lastJsonMessage } = useWebSocket(WS_URL,
    //     {
    //         shouldReconnect: (closeEvent) => {
    //             return true;
    //         },
    //         reconnectAttempts: 3,
    //         reconnectInterval: 30000,
    //     }, localStorageRequestKey || requestKey ? true : false);

    const { storeWebSocketMessages, webSocketMessages, removeMessageByType } = useStoreWSMessages()
    const { sendJsonMessage, lastJsonMessage } = useContext(WebSocketContext);

    const handleGoogleSignUp = () => {
        const localStorageRequestKey = localStorage.getItem('deviceRequestKey')
        if (requestKey || localStorageRequestKey) {
            sendJsonMessage && sendJsonMessage({ requestType: 'google-signup-webapp', requestKey: requestKey || localStorageRequestKey, payload: {}, timestamp: new Date().getTime() })
        }
        else
            return googleLogin()
    }

    const CLICK_ID = localStorage.getItem("irclickid") || irclickid || undefined

    // On Submit
    const onSubmit = async (type?: string, googleUser?: IGoogleUser, appleUser?: IAppleUser) => {
        removeMessageByType('google-signin-mobileapp')
        setIsLoading(true)
        // Sign Up Google
        if (googleUser && type === "google") {
            const response = await signUp({
                username: googleUser?.email as string,
                email: googleUser?.email as string,
                password: googleUser?.sub as string,
            })
            // Login and get token
            if (response?.data?.account_id != null) {
                const response = await logIn({
                    username: googleUser?.email as string,
                    password: googleUser?.sub as string,
                })
                // Go to User Info Page
                if (response?.data?.account_id != null) {

                    await initImpactConversion(sha1(response?.data?.account_id), response?.data?.account_id, sha1(googleUser?.email as string))

                    const result = await updateAccountInfo({
                        firstName: startCase(googleUser?.given_name.toLowerCase()),
                        lastName: startCase(googleUser?.family_name.toLowerCase()),
                        gender: "",
                        // favorite: ""
                    })
                    if (result?.data?.id) {
                        // setTimeout(() => {
                        localStorage.setItem("surveyType", "skipped")
                        setIsLoading(false)
                        setRedirect(urlWithOffer(`/#/start`, offerType))
                        // }, 2000)
                    }
                }
            }

            // User or email exists
            else if (response?.error?.message?.includes('exist')) {
                setIsLoading(false)
                setAlertVariant('danger')
                setAlertMessage(response.error.message)
                onAlertOpen()
            }

            else if (JSON.stringify(response)?.toLowerCase()?.includes('many')) {
                setIsLoading(false)
                setAlertVariant('danger')
                setAlertMessage(response)
                onAlertOpen()
            }

            // Uncaught error
            else {
                setIsLoading(false)
                setAlertVariant('danger')
                setAlertMessage('Signup failed')
                onAlertOpen()
            }
        }

        // Sign Up Apple
        if (appleUser && type === "apple") {
            await signUp({
                username: appleUser?.email as string,
                email: appleUser?.email as string,
                password: appleUser?.password as string,
            }).then(async (response) => {
                if (response?.data?.account_id != null) {
                    await logIn({
                        username: appleUser?.username as string,
                        password: appleUser?.password as string,
                    }).then(async (response) => {
                        if (response?.data?.account_id != null) {
                            const data = {
                                username: appleUser?.username as string,
                                password: appleUser?.password as string,
                            }
                            secureLocalStorage.setItem("workerKeys", data)
                            secureLocalStorage.setItem("isAppleSignup", true)

                            await initImpactConversion(sha1(response?.data?.account_id), response?.data?.account_id, sha1(appleUser?.email as string))

                            await updateAccountInfo({
                                firstName: startCase(appleUser?.firstName?.toLowerCase()) || '',
                                lastName: startCase(appleUser?.lastName?.toLowerCase()) || '',
                                gender: "",
                                // favorite: ""
                            }).then((result) => {
                                if (result?.data?.id) {
                                    // setTimeout(() => {
                                    localStorage.setItem("surveyType", "skipped")
                                    setIsLoading(false)
                                    setRedirect(urlWithOffer(`/#/start` + url.search, offerType))
                                    // }, 2000)
                                }
                                else {
                                    setIsLoading(false)
                                    setAlertVariant('danger')
                                    setAlertMessage("Error updating account info")
                                    onAlertOpen()
                                }
                            })
                        }
                    })
                    // Go to User Info Page
                }
                // User or email exists
                else if (response?.error?.message?.includes('exist')) {
                    setIsLoading(false)
                    setAlertVariant('danger')
                    setAlertMessage(response.error.message)
                    onAlertOpen()
                }

                else if (JSON.stringify(response)?.toLowerCase()?.includes('many')) {
                    setIsLoading(false)
                    setAlertVariant('danger')
                    setAlertMessage(response)
                    onAlertOpen()
                }

                // Uncaught error
                else {
                    setIsLoading(false)
                    setAlertVariant('danger')
                    setAlertMessage('Signup failed')
                    onAlertOpen()
                }
            })
            // Login and get token
        }
    }

    const googleLogin = useGoogleLogin({
        onSuccess: async (tokenResponse) => {
            const userInfo = await axios.get(GOOGLE_CLIENT_AUTH,
                { headers: { Authorization: `Bearer ${tokenResponse.access_token}` } },
            );

            if (userInfo) {
                onSubmit("google", userInfo?.data)
                setGoogleUser(userInfo?.data as IGoogleUser)
            }
            else {
                setAlertVariant('danger')
                setAlertMessage('Signup failed')
                onAlertOpen()
            }
        },
        onError: () => {
            setAlertVariant('danger')
            setAlertMessage('Signup failed')
            onAlertOpen()
        }
    });

    const initImpactConversion = async (orderId: string, account_id: number, email: string) => {
        if (CLICK_ID) {
            // window?.callConversion?.(IMPACT_TRIAL_ID, orderId, String(account_id), sha1(email))
            await createImpactClickId(account_id as number, irclickid, orderId, 'created')
        }
    }

    //Apple Response Signup



    const signupApple = async () => await appleAuthHelpers.signIn({
        authOptions: {
            clientId: APPLE_CLIENT_ID,
            scope: 'email name',
            redirectURI: APPLE_REDIRECT_URI,
            state: 'state',
            nonce: 'nonce',
            usePopup: true
        },

        onSuccess: async (response: any) => {
            const tokenDetails: IAppleTokenDetails = await jwt_decode(response?.authorization?.id_token)
            if (response && tokenDetails) {
                const data: IAppleUser = {
                    username: tokenDetails?.email,
                    password: tokenDetails?.sub,
                    email: tokenDetails?.email,
                    firstName: response?.user?.name?.firstName,
                    lastName: response?.user?.name?.lastName,
                }
                onSubmit("apple", undefined, data)
            }
            else {
                setAlertVariant('danger')
                setAlertMessage('Signup failed')
                onAlertOpen()
            }
        },
        onError: (error: any) => {
            if (error.error !== "popup_closed_by_user") {
                setAlertVariant('danger')
                setAlertMessage(startCase(error.error))
                onAlertOpen()
            }
        },
    });

    const handleAppleSignup = () => {
        const localStorageRequestKey = localStorage.getItem('deviceRequestKey')
        if ((requestKey || localStorageRequestKey) && NEW_VERSION) {
            sendJsonMessage && sendJsonMessage({ requestType: 'apple-signup-webapp', requestKey: requestKey || localStorageRequestKey, payload: {}, timestamp: new Date().getTime() })
        }
        else
            return signupApple()
    }

    const getCookie = (name: string) => {
        let cookieValue;
        if (document.cookie && document.cookie !== '') {
            var cookies = document.cookie.split(';');
            for (var i = 0; i < cookies.length; i++) {
                var cookie = cookies[i].trim();
                if (cookie.substring(0, name.length + 1) === (name + '=')) {
                    cookieValue = decodeURIComponent(cookie.substring(name.length + 1));
                    break;
                }
            }
        }
        return cookieValue;
    }

    useEffect(() => {
        const cookie = getCookie('IR_' + IMPACT_CAMPAIGN_ID)
        if (cookie) {
            const value = cookie.split('|')[3];
            if (value) {
                const append = `?irclickid=${value}${offer && Number(offer) == 2 ? '&offer=2' : ''}`
                const nextURL = `/#/signup${append}`
                window.location.assign(nextURL)
            }
        }
    }, [])

    // useEffect(() => {
    //     // alert("test")
    //     ReactPixel.trackCustom('ViewContent', { k: new Date().getTime() });
    // }, [])

    useEffect(() => {
        if (irclickid)
            localStorage.setItem("irclickid", irclickid)
    }, [irclickid])

    useEffect(() => {
        if (requestKey)
            localStorage.setItem("deviceRequestKey", requestKey)
    }, [requestKey])

    // Redirect / Cookie Effect
    useEffect(() => {
        if (authCookie && redirect) navReq(redirect)
    }, [authCookie, redirect])

    useEffect(() => {
        // console.log(activeSale)
        if (activeSale) {
            if (activeSale.active == 1)
                setHasSale(true)

            else if (activeSale.active == 0)
                setHasSale(false)
        }
        else if (Number(offer) == 2 || Number(offer) == 3)
            setHasSale(true)
    }, [activeSale, offer])

    useEffect(() => {
        if (isLoadingSale) {
            setIsLoadingMain(true)
        }
        else {
            setIsLoadingMain(false)
        }
    }, [isLoadingSale])


    useEffect(() => {
        const localStorageRequestKey = localStorage.getItem('deviceRequestKey')
        if (lastJsonMessage && (lastJsonMessage.requestKey === requestKey || lastJsonMessage.requestKey === localStorageRequestKey)) {
            setMessageData(lastJsonMessage)
            storeWebSocketMessages(lastJsonMessage)
        }
    }, [lastJsonMessage]);

    useEffect(() => {
        const localStorageRequestKey = localStorage.getItem('deviceRequestKey')
        const googleSignData = webSocketMessages.find(x => (x?.requestKey === requestKey || x?.requestKey === localStorageRequestKey) && (x?.requestType === "google-signin-mobileapp"))
        if (googleSignData) {
            onSubmit("google", googleSignData.payload)
        }
    }, [webSocketMessages]);

    useEffect(() => {
        const localStorageRequestKey = localStorage.getItem('deviceRequestKey')

        if ((messageData?.requestKey === requestKey || messageData?.requestKey === localStorageRequestKey) && messageData?.requestType === 'apple-success-signin' && messageData.payload) {
            const tokenDetails: IAppleTokenDetails = jwt_decode(messageData?.payload?.identityToken)
            const fullName = messageData?.payload?.fullName
            const appleUser: IAppleUser = {
                username: tokenDetails.email,
                password: tokenDetails.sub,
                email: tokenDetails.email,
                firstName: fullName?.givenName || '',
                lastName: fullName?.familyName || ''
            }
            removeMessageByType('apple-success-signin')

            sendJsonMessage && sendJsonMessage({ requestType: 'success-signin', requestKey: requestKey || localStorageRequestKey, payload: {}, timestamp: new Date().getTime() })

            onSubmit("apple", undefined, appleUser)
        }
    }, [messageData]);

    const renderBTN = (icon: any, text: string, action: any) => {
        return <RSButton sx={{ mb: '10px' }} onClick={action}>
            {icon}
            <Box sx={{ pl: '10px' }}>{text}</Box>
        </RSButton>
    }

    // Render
    return (
        <>
            {/* Styles */}
            <InstabotHide />

            {/* Alert */}
            <GenericAlert
                show={showAlert}
                title={alertTitle}
                message={alertMessage}
                variant={alertVariant}
                onClose={onAlertClose}
            />

            {/* Page */}
            <AppMenus hideOptions hideBackButton>
                <Container fluid="md" className={classNames("px-form-5", isSignUp ? 'signup-container' : 'signup-container-sm')}>
                    <div className="user-info-page--wrapper container-center">
                        {/* Title */}
                        <Row className="mb-2 user-login--title">
                            <Col>
                                {offer === "3" ? (
                                    <>
                                        <div className='limited-offer pt-2'>
                                            <p>LIMITED TIME - SAVE 45%</p>
                                        </div>
                                        <div className='d-block d-md-none'>
                                            <br />

                                        </div>
                                    </>
                                ) : undefined}
                                {/* {localStorageRequestKey ? (
                                    <div className='mobile-link' onClick={() => {
                                        window.location.assign('https://runsmartonline.com/app-splash/?requestKey=' + localStorageRequestKey)
                                    }}>
                                        <h4>EXPLORE THE APP <ArrowForwardIos htmlColor='#742F4E' /></h4>
                                    </div>
                                ) : undefined} */}
                                <h3>{title}</h3>
                            </Col>
                        </Row>


                        {isSignUp && (
                            <>
                                {/* Signup Header */}
                                {!isLoadingSale && hasSale ? (
                                    <>
                                        <Row className="mb-2 text-center m-auto user--text subtitle-2">
                                            <Col className="text-center m-auto d-flex justify-content-center">
                                                <p style={{ maxWidth: '500px' }}>{pageType === "strength" ? SIGNIN_HEADER_STR : SIGNIN_HEADER}</p>
                                            </Col>
                                        </Row>
                                    </>
                                ) : undefined}
                                {!isLoadingSale && !hasSale && !localStorageRequestKey ? (<Row className="mb-0 text-center m-auto user--text subtitle-2">
                                    <Col className="text-center m-auto d-flex justify-content-center">
                                        <p style={{ maxWidth: '500px' }}>{SUB_HEADER}</p>
                                    </Col>
                                </Row>) : undefined}

                            </>
                        )}
                    </div>

                    {/* Form */}
                    <div className="account-form m-auto">
                        {isSignUp && (
                            <Row xs={12}>
                                <div className='other-login'>
                                    <Box sx={{ width: '220px' }}>
                                        {mobileType !== "Android" && renderBTN(<AppleIcon />, 'Sign up with Apple', () => handleAppleSignup())}
                                        {renderBTN(<GoogleIcon />, 'Sign up with Google', (mobileType === "Android" || mobileType === "Ios") ? handleGoogleSignUp : googleLogin)}
                                        {renderBTN(<MailIcon />, 'Sign up with Email', () => { setRedirect(`/#/signup-email${url.search}`) })}
                                    </Box>
                                    <Row className="mt-5">
                                        <Col className="text-center footer-info">
                                            <span className="text-black me-2">Already have an account?</span>
                                            <span>
                                                <a href="/#/login"><u>Log in here.</u></a>
                                            </span>
                                        </Col>
                                    </Row>
                                </div>
                            </Row>)}
                        <br />
                        {isSignUp && (
                            <div className='mt-1 mb-3 text-center footer-info'>
                                <p>
                                    By Signing up, you agree to our{' '}
                                    <a href={localStorageRequestKey ? undefined : 'https://runsmartonline.com/terms-of-use/'} target={localStorageRequestKey ? '' : 'blank'} onClick={() => {
                                        localStorageRequestKey ? navReq('/#/terms-of-use') : undefined
                                    }} className='pointer'>
                                        <u>Terms of Service</u>
                                    </a>
                                    ,{' '}
                                    <a href={localStorageRequestKey ? undefined : 'https://runsmartonline.com/privacy-policy/'} target={localStorageRequestKey ? '' : 'blank'} onClick={() => {
                                        localStorageRequestKey ? navReq('/#/privacy-policy') : undefined
                                    }} className='pointer'>
                                        <u>Privacy Policy, </u>
                                    </a>{' '}
                                    <a href={localStorageRequestKey ? undefined : 'https://runsmartonline.com/waiver-of-liability/'} target={localStorageRequestKey ? '' : 'blank'} onClick={() => {
                                        localStorageRequestKey ? navReq('/#/waiver-of-liability') : undefined
                                    }} className='pointer'>
                                        <u>Waiver of Liability, </u>
                                    </a>{' '}
                                    and receive our email updates.
                                </p>
                            </div>
                        )}
                    </div>

                </Container>
            </AppMenus>
        </>
    )
}

export default ChooseSignUp
