import { RefreshOutlined, TaskAltOutlined } from '@mui/icons-material'
import { Checkbox, FormControlLabel, Grid, InputLabel, MenuItem, Select } from '@mui/material'
import moment from 'moment'
import { FC, useEffect, useState } from 'react'
import { Table } from 'react-bootstrap'
import { FaBoxArchive } from 'react-icons/fa6'
import { OpenAIPrompt } from '../../api/v2/open-ai/OpenAIPrompt.types'
import RSButton from '../../components/buttons/RSButton'

interface Props {
    data: OpenAIPrompt[]
    setSelectedPrompt: (prompt: OpenAIPrompt | undefined) => void
    deletePromptVersion: (id: number) => Promise<void>
    activatePromptVersion: (prompt: OpenAIPrompt) => Promise<void>
    restorePromptVersion: (id: number) => Promise<void>
    isLoading?: boolean
    selectedPrompt?: OpenAIPrompt
    setLastVersion: (version: number) => void
}

const PromptVersion: FC<Props> = ({ data, setSelectedPrompt, deletePromptVersion, activatePromptVersion, restorePromptVersion, isLoading, selectedPrompt, setLastVersion }) => {

    const raceTypeSelection = ['All', 'full', 'half', '10k', '8k', '5k', 'maintenance']
    const [raceType, setRaceType] = useState('All')
    const [filteredStatus, setFilteredStatus] = useState<any>(1)
    const [prompts, setPrompts] = useState<OpenAIPrompt[]>([])
    const [stepFilter, setStepFilter] = useState<string[]>([])
    const [selectedStep, setSelectedStep] = useState('All')
    const [showArchived, setShowArchived] = useState(false)
    const [archivePrompts, setArchivedPrompts] = useState<OpenAIPrompt[]>([])

    const statusSelection = ['All', 'Inactive', 'Active', showArchived ? 'Archived' : undefined]

    const handleChange = (event: any) => {
        setRaceType(event.target.value)
    }

    const getAvailableSteps = (data: OpenAIPrompt[], raceType: string, isAll?: boolean) => {
        if (data && data.length) {
            const filteredData = isAll ? data : data.filter((prompt) => prompt.race_type === raceType)
            const steps = filteredData.map((prompt) => prompt.step)
            //return unique steps with no duplicates es2015 way
            return Array.from(new Set(steps)).sort((a: any, b: any) => a.localeCompare(b)) as string[]
        }

        return []
    }

    const getLastVersion = (data: OpenAIPrompt[], raceType: string, step: string) => {
        const filteredData = data.filter((prompt) => prompt.race_type === raceType && prompt.step == step).sort((a, b) => a.version - b.version)
        if (filteredData.length > 0) {
            return filteredData[filteredData.length - 1].version
        }

        return 0
    }

    useEffect(() => {
        setArchivedPrompts(data.filter((prompt) => prompt.status === 2))
    }, [data])

    useEffect(() => {

        const handleFilter = async (prompts: OpenAIPrompt[]) => {
            let filteredPrompts = showArchived ? prompts : prompts.filter((prompt) => prompt.status !== 2)

            if (selectedStep !== "All")
                filteredPrompts = filteredPrompts.filter((prompt) => prompt.step === selectedStep)

            if (filteredStatus != -1) {
                filteredPrompts = filteredPrompts.filter((prompt) => prompt.status === filteredStatus)
            }
            setPrompts(filteredPrompts)
        }

        if (raceType && raceType === 'All') {
            setStepFilter(['All', ...getAvailableSteps(data, raceType, true)])
            const filteredPrompts = showArchived ? data : data.filter((prompt) => prompt.status !== 2)
            handleFilter(filteredPrompts)
        }
        else {
            setStepFilter(['All', ...getAvailableSteps(data, raceType, false)])
            const filteredPrompts = showArchived ? data.filter((prompt) => prompt.race_type === raceType.toLowerCase()) : data.filter((prompt) => prompt.race_type === raceType.toLowerCase() && prompt.status !== 2)
            handleFilter(filteredPrompts)
        }
    }, [raceType, data, filteredStatus, showArchived, selectedStep])

    useEffect(() => {
        if (selectedPrompt) {
            setLastVersion(getLastVersion(data, selectedPrompt.race_type, selectedPrompt.step as string) as number)
        }
    }, [selectedPrompt, data])

    return (
        <div>
            <Grid className='mb-1' container spacing={1}>
                <Grid item xs={2}>
                    <InputLabel className='mt-2' id="demo-multiple-name-label">- Race Type -</InputLabel>
                    <Select className='w-100' placeholder='Select Program' value={raceType} onChange={(e) => handleChange(e)} >
                        {raceTypeSelection.map((rt, index) => (<MenuItem key={index} value={rt}>{rt}</MenuItem>))}
                    </Select>
                </Grid>

                <Grid item xs={3}>
                    <InputLabel className='mt-2' id="demo-multiple-name-label">- Status -</InputLabel>
                    <Select className='w-100' placeholder='Select Status' value={filteredStatus} onChange={(e) => setFilteredStatus(e.target.value)} >
                        {statusSelection.map((st, index) => (<MenuItem key={index} value={index - 1}>{st}</MenuItem>))}
                    </Select>
                </Grid>

                {
                    stepFilter.length > 0 && <Grid item xs={2}>
                        <InputLabel className='mt-2' id="demo-multiple-name-label">- Step -</InputLabel>
                        <Select className='w-100' placeholder='Select Step' value={selectedStep} onChange={(e) => setSelectedStep(e.target.value as string)} >
                            {stepFilter.map((st, index) => (<MenuItem key={index} value={st}>{st}</MenuItem>))}
                        </Select>
                    </Grid>
                }
                {
                    archivePrompts.length > 0 && <Grid item xs={stepFilter.length ? 5 : 7}>
                        <FormControlLabel style={{ marginTop: '40px', float: "right" }} control={<Checkbox checked={showArchived} onChange={(e, checked) => setShowArchived(checked)} />} label="Show Archived" />
                    </Grid>
                }

            </Grid>

            <Table responsive striped bordered hover size="sm">
                <thead>
                    <tr>
                        <th>#</th>
                        <th style={{ width: '100px' }}>Race</th>
                        <th style={{ width: '80px' }} >Ver.</th>
                        <th style={{ width: '80px' }} >Step</th>
                        {/* <th style={{ width: '500px' }}>Prompt</th> */}
                        <th>Notes</th>
                        <th>Status</th>
                        <th>Created</th>
                        <th>Modified</th>
                        <th></th>
                    </tr>
                </thead >
                <tbody>
                    {prompts?.map((prompt, index: number) => (
                        <tr onClick={() => setSelectedPrompt(prompt)} style={{ cursor: 'pointer' }} key={index}>
                            <td>{index + 1}</td>
                            <td>{prompt.race_type}</td>
                            <td>{prompt.version}</td>
                            <td>{prompt.step}</td>
                            {/* <td>{prompt.plan_prompts}</td> */}
                            <td>{prompt.notes}</td>
                            <td style={{ color: prompt.status === 1 ? 'green' : prompt.status === 2 ? 'gray' : 'red' }}><b>{prompt.status === 1 ? 'Active' : prompt.status === 2 ? 'Archived' : 'Inactive'}</b></td>
                            <td>{moment(prompt.date_created).format('MM/DD/YY h:mm A')}</td>
                            <td>{moment(prompt.last_modified).format('MM/DD/YY h:mm A')}</td>
                            <td>
                                <tr>
                                    <td><RSButton tooltip={`${prompt.status === 0 ? 'Set As Active' : 'Restore'}`} disabled={prompt.status == 1 || isLoading} onClick={async (e: any) => {
                                        e.stopPropagation();
                                        if (prompt.status === 0) await activatePromptVersion(prompt);
                                        else await restorePromptVersion(prompt?.id as number);
                                        setSelectedPrompt(undefined)
                                    }}>{
                                            prompt.status === 0 || prompt.status === 1 ? <TaskAltOutlined /> : <RefreshOutlined />
                                        }</RSButton></td>
                                    <td><RSButton danger disabled={prompt.status === 2 || isLoading} tooltip='Archive Prompt' onClick={async (e: any) => {
                                        e.stopPropagation();
                                        await deletePromptVersion(prompt?.id as number);
                                        setSelectedPrompt(undefined)
                                    }} ><FaBoxArchive /></RSButton></td>
                                </tr>
                            </td>
                        </tr>
                    ))}
                </tbody>
            </Table >
        </div>
    )
}

export default PromptVersion