import { useState, useEffect } from 'react'
import { InputInfo } from '../components/forms/SignInForm'
import { GenericFormProps } from '../components/forms/GenericForm'

// Form Info Type
export type FormInfo = { [index: string]: InputInfo }

// Set Is Valid
type SetIsValid = (x: boolean) => any

// Set Info
type SetInfo = (x: FormInfo) => any

/**
 * useFormInfo Hook
 */

const useFormInfoHook = ({
	inputProps,
	defaultFormInfo,
	setIsValid,
	setInfo,
}: {
	inputProps: GenericFormProps['inputs']
	defaultFormInfo: FormInfo
	setIsValid: SetIsValid
	setInfo?: SetInfo
}) => {
	// State
	const [formInfo, setFormInfo] = useState(defaultFormInfo)
	const [valid, setValid] = useState(false)

	// Update Input Props
	const updateInputProps = () => {
		for (const fieldProps of inputProps) {
			const fieldInfoKey = fieldProps.formKey || ''
			fieldProps.text = defaultFormInfo[fieldInfoKey]?.value
			fieldProps.setText = setFieldValue.bind(null, fieldInfoKey as any)
			fieldProps.setIsValid = setFieldIsValid.bind(null, fieldInfoKey as any)
		}
	}

	// Set All Valid
	const setAllValid = (newFormInfo: typeof defaultFormInfo) => {
		let allValid = true
		for (const key in newFormInfo) {
			const formInfoKey = key as keyof typeof newFormInfo
			const isValid = newFormInfo[formInfoKey].isValid
			if (!isValid) {
				allValid = false
				break
			}
		}
		if (valid != allValid) {
			setValid(allValid)
			if (setIsValid) setIsValid(allValid)
		}
	}

	// Set Field Value
	const setFieldValue = (
		field: keyof typeof defaultFormInfo,
		value: string
	) => {
		const newFormInfo = { ...formInfo }
		newFormInfo[field].value = value
		setFormInfo(newFormInfo)
		setAllValid(newFormInfo)
		if (setInfo) setInfo(newFormInfo)
	}

	// Set Field Is Valid
	const setFieldIsValid = (
		field: keyof typeof defaultFormInfo,
		isValid: boolean
	) => {
		const newFormInfo = { ...formInfo }
		newFormInfo[field].isValid = isValid
		setAllValid(newFormInfo)
		setFormInfo(newFormInfo)
		if (setInfo) setInfo(newFormInfo)
	}

	// Properties
	updateInputProps()

	// Form Info Change Effect
	useEffect(() => {
		updateInputProps()
	}, [defaultFormInfo, inputProps])

	// Return
	return { formInfo, valid }
}

export default useFormInfoHook
