import allCategories from '../modules/static-images/allCategories'

/**
 * useGuidesPage Hook
 */

const useGuidesPage = () => {
	return { guides: allCategories['guides'] }
}

export default useGuidesPage
