import Alert from 'react-bootstrap/Alert'
import Button from 'react-bootstrap/Button'
import { FC, useState, useEffect } from 'react'

// Props
export type GenericAlertProps = {
	show: boolean
	title?: string
	message: string | JSX.Element
	variant: string
	mustClose?: boolean
	closeText?: string
	onClose: () => any
}

// Toast Delay
const TOAST_DELAY = 5000

/**
 * GenericAlert Component
 */

const GenericAlert: FC<GenericAlertProps> = ({
	show,
	title,
	message,
	variant,
	mustClose,
	closeText = 'Ok',
	onClose,
}) => {
	// State
	const [isOpen, setIsOpen] = useState(show)

	// Properties
	let closeTimeout: any = undefined

	// On Close Click
	const onCloseClick = () => {
		onClose()
		clearTimeout(closeTimeout)
		setIsOpen(false)
	}

	// Show Change Effect
	useEffect(() => {
		if (show && !mustClose) {
			if (closeTimeout) clearTimeout(closeTimeout)
			closeTimeout = setTimeout(() => onClose(), TOAST_DELAY)
		}
		setIsOpen(show)
	}, [show])

	// Must Close Change Effect
	useEffect(() => {
		if (mustClose && closeTimeout) clearTimeout(closeTimeout)
	}, [mustClose])

	// Render
	return (
		<>
			{isOpen && (
				<div className="alert-container">
					<Alert
						className="mx-auto"
						variant={variant}
						onClose={onCloseClick}
						dismissible
					>
						{title && <Alert.Heading>{title}</Alert.Heading>}
						<p>{message}</p>
						{mustClose && (
							<>
								<hr />
								<div className="d-flex justify-content-end">
									<Button onClick={onCloseClick} variant={`outline-${variant}`}>
										{closeText}
									</Button>
								</div>
							</>
						)}
					</Alert>
				</div>
			)}
		</>
	)
}

export default GenericAlert
