import { FC } from 'react'

import Box from '@mui/material/Box';

export type RSCloseBtnProps = {
	size?: number,
	onClick?: Function,
	className?: string
}


const RSCloseBtn: FC<RSCloseBtnProps> = ({ size, onClick, className = '' }) => {

	const finalSize = size || 20;

	return <Box className={`pointer ${className}`} onClick={()=>onClick?.()}
		sx={{width: `${finalSize}px`, height: `${finalSize}px`, display: 'flex', justifyContent: 'center', alignItems: 'center', borderRadius: '50%'}}
	>
		<i className="fa-duotone fa-circle-xmark duo-tone-BW" style={{color: 'black', fontSize: `${finalSize}px`}} />
	</Box>
}

export default RSCloseBtn;
