import { useState, useRef, useEffect } from 'react'
import { motion, AnimatePresence } from "framer-motion";

import Grid from '@mui/material/Grid'
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'

import useIsMobileScreen from './useIsMobileScreen'
import { RSTabsDataProps } from '../modules/types'

const variants = {
	enter: (direction: number) => {
		return {
			opacity: 0,
			x: direction > 0 ? "100%" : "-100%"
		};
	},
	center: {
		opacity: 1,
		x: 0
	},
	exit: (direction: number) => {
		return {
			opacity: 0,
			x: direction > 0 ? "-100%" : "100%"
		};
	}
};

export type RSTabsProps = {
	data: RSTabsDataProps,
	btnContSX?: object,
	btnSX?: object,
	btnTitleSX?: object,
	bodySX?: object,
	initialTab?: number,
	animateDesktop?: boolean
	initialBody?: any
}

const useRSTabs = ({ data, btnContSX = {}, btnSX = {}, btnTitleSX = {}, bodySX = {}, initialBody, initialTab = 0, animateDesktop }: RSTabsProps) => {
	const [initTab, setInitTab] = useState(initialTab)
	const [activeTab, setActiveTab] = useState(initialTab)

	const prevActiveTab = useRef(initialTab)

	const isMobile = useIsMobileScreen()

	const handleChangeActive = (val: number) => {
		prevActiveTab['current'] = activeTab
		setInitTab(val);
	};

//	useEffect(() => {
//		document?.getElementsByClassName('rs-tab-content')?.[0].scroll?.({
//                                                           			top: 0,
//                                                           			behavior: "smooth"
//                                                           		})
//	},[activeTab])

	useEffect(() => {
		setActiveTab(initTab)
	}, [initTab])

	const renderTabsUI = () => (
		<Box sx={{width: '100%', height: '100%'}}>
			{data?.find((d: any)=>d.title) ? <AnimatePresence mode='wait' initial={false}>
				<Grid container sx={{ pl: '9px', ...btnContSX }}>
					{data?.map((t: any, i: number) => {
						return t ? <Button sx={{ position: 'relative', textTransform: 'none', px: '15px', pb: '10px', color: 'black !important', ...btnSX }}
							key={'default-tabs-' + i}
							className={i === activeTab ? "selected" : ""}
							onClick={() => handleChangeActive(i)}
						>
							<Box sx={{ position: 'relative', fontFamily: 'Poppins-Bold !important', fontSize: '14px', letterSpacing: 'normal', ...btnTitleSX }}>
								{t.title}
								{i === activeTab ? (
									<motion.div className="underlineTab default-tabs" layoutId="underline" />
								) : null}
							</Box>
						</Button> : ''
					})}
				</Grid>
			</AnimatePresence> : <></>}
			<Box>
				<AnimatePresence mode='wait' initial={false}>
					<motion.div
						key={activeTab}
						custom={initTab - prevActiveTab?.current}
						variants={isMobile || animateDesktop ? variants : undefined}
						initial="enter"
						animate="center"
						exit='exit'
						transition={{ duration: 0.2 }}
						style={{ width: '100%'}}
						className='rs-tab-content'
					>
						<Box sx={bodySX}>
							{initialTab === activeTab && initialBody ? initialBody : data?.[activeTab]?.body}
						</Box>
					</motion.div>
				</AnimatePresence>
			</Box>
		</Box>
	)

	return { tabsUI: renderTabsUI(), setTab: setInitTab, tab: activeTab, handleChangeActive}
}

export default useRSTabs;
