import React from 'react'
import { Form } from 'react-bootstrap'
import CustomPopOver from '../../../components/custom-popover'

const UpdateDistance = (props: any) => {
    const { isSuccessSave, isLoadingDistance, handleSaveUserNotes, userInfo, distanceValue, setDistanceValueInput } = props
    return (
        <CustomPopOver
            autoClose={isSuccessSave}
            icon={
                <img
                    src="./images/icons/plus-icon.svg"
                    alt="Edit Distance"
                    style={{ width: '21px', height: '21px' }}
                />
            }
        >
            <div className="popover-custom">
                <h6 className="m-0 text-center box-title">
                    {isLoadingDistance ? 'Saving Distance...' : 'ENTER DISTANCE'}
                </h6>
                <Form>
                    <Form.Group>
                        <Form.Control type="number" min={0} defaultValue={userInfo?.km == 'Y' ? distanceValue : Number(distanceValue)?.toFixed()} onChange={(e) => setDistanceValueInput(e.target.value)} />
                    </Form.Group>
                </Form>
                <div onClick={() => handleSaveUserNotes("distance")} className="thickmark-icon pointer">
                    <img
                        src="./images/icons/tick-mark.png"
                        alt="Tick Mark"
                    />
                </div>
            </div>
        </CustomPopOver>
    )
}

export default UpdateDistance