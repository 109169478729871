/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/no-empty-function */
import { FC, useState, useEffect, useRef } from 'react'
import { useNavigate } from 'react-router-dom'
import Slider from 'react-slick'
import moment from 'moment'

import Drawer from '@mui/material/Drawer';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';

import useStore from '../../../store/useStore'
import { ICurrentUser } from '../../../store/createAccountStore'
import { IComponentStore } from "../../../store/createComponentStore"
import {
	updateAccountInfo,
	GetOnBoarding,
	CreateOnBoarding,
	UpdateOnBoarding,
	signOut
} from '../../../modules/accountActions'
import { AuthPageProps } from '../../../modules/types'
import Loader from '../../../components/animation/Loader'
import WithAuth from '../../../components/WithAuth'
import RSButton from '../../../components/buttons/RSButton'
import RSDrawer from '../../../components/layout/RSDrawer'
import useSuggestedPrograms from '../useSuggestedPrograms'
import useProgressIndicator from './useProgressIndicator'
import CreateAccount from './create-account/CreateAccount';
import PrivateInfo from './complete-profile/PrivateInfo';
import NutritionInfo from './complete-profile/NutritionInfo';
import PhysicalActivityInfo from './complete-profile/PhysicalActivityInfo';
import AverageRunningInfo from './complete-profile/AverageRunningInfo';
import RunningStyleInfo from './complete-profile/RunningStyleInfo';
import ProblemAreasInfo from './complete-profile/ProblemAreasInfo';
import SetYourGoals from './build-plan/SetYourGoals';
import ChooseAPlan from './build-plan/ChooseAPlan';
import useSale from '../../../hooks/useSale';
import useQueryParams from '../../../hooks/useQueryParams';
import SuggestedPrograms from '../../programs/SuggestedPrograms';


const LogInPage: FC<AuthPageProps> = ({ userInfo: currentUser }) => {
	const { activeSale } = useSale()
	const [start, setStart] = useState(false)
	const [skipCreate, setSkipCreate] = useState(currentUser?.account_id ? true : false)
	const [id, setId] = useState<any>('')
	const [goTo, setGoTo] = useState<any>('')
	const [obData, setObData] = useState<any>('')
	const [resumeOnboarding, setResumeOnboarding] = useState(false)
	const navigate = useNavigate()
	const inactive = currentUser?.is_inactive
	const cId = currentUser?.account_id
	const { requestKey, ref } = useQueryParams()
	const localStorageRequestKey = localStorage.getItem('deviceRequestKey') || requestKey
	const { p_id } = useQueryParams()

	const data = [
		{
			icon: <i className="fa-regular fa-arrow-right-to-bracket"></i>,
			title: 'Create an Account',
			desc: 'Access your training from any device.',
			progressData: [CreateAccount],
			noBack: [1],
			noNext: [1],
			skip: skipCreate
		},
		{
			icon: <i className="fa-regular fa-user" />,
			title: 'Complete Profile',
			desc: 'Let’s start by learning about you and your running habits. ',
			fixedTitle: true,
			progressData: [PrivateInfo, NutritionInfo, PhysicalActivityInfo, AverageRunningInfo, RunningStyleInfo, ProblemAreasInfo],
			noBack: [0],
		}, {
			icon: <i className="fa-regular fa-screwdriver-wrench"></i>,
			title: 'Build a Plan',
			desc: 'You’re on the right track!  Now let’s figure out your next steps.',
			progressData: [SetYourGoals, SuggestedPrograms, ChooseAPlan],
			complete: 2,
			noNext: [3],
			navStatic: [2, 3]
		}, {
			icon: <i className="fa-duotone fa-flag-checkered flag-duo" />,
			title: 'Start Training',
			desc: 'We have what we need to get started!',
			end: (d: any) => {
				const gIndex = d?.goals?.[0]?.index
				navigate('/', { replace: true })
			}
		}]

	const submitOnBoarding = async (res: any, bodyData: any) => {
		if (bodyData?.id) {
			const body = { user_id: bodyData.id, user_progress: JSON.stringify(bodyData) }
			const abortController = new AbortController();
			if (res?.data?.result?.length)
				await UpdateOnBoarding(body)
			else
				await CreateOnBoarding(body)

			await updateAccountInfo({
				firstName: bodyData.firstName,
				lastName: bodyData.lastName,
				gender: bodyData.gender === 'nb' ? 'O' : bodyData?.gender?.toUpperCase(),
				dob: bodyData.birthDate?.replaceAll('/', '-'),
				distanceUnit: bodyData.isKm ? 'km' : 'mi',
				dayStart: bodyData.isLongRunSat ? 'sunday' : 'monday',
				joinReason: bodyData?.goals[0]?.title?.join_reason || '',
				height: bodyData?.height?.cm || 0,
				weight: bodyData?.weight?.lbs || 0
			}, "profile_update")

			await GetOnBoarding(bodyData.id, abortController.signal).then(
				(r: any) => {
					setObData(JSON.parse(r?.data?.result?.[0]?.user_progress))
				}
			)
		}
	}

	const complete = async (bodyData: any) => {
		if (bodyData?.id) {
			const abortController = new AbortController();
			await GetOnBoarding(bodyData.id, abortController.signal).then(
				(res: any) => {
					submitOnBoarding(res, bodyData)
				}
			)
		}
	}

	const doOnNext = () => {
		document?.getElementsByClassName('MuiDrawer-paperAnchorRight')?.[0]?.scroll?.({
			top: 0,
			behavior: "smooth"
		})
	}

	const { isLoading } = useStore((state: IComponentStore) => state)
	const { progressUI, increaseProgress, bodyData: allData, setBodyData } = useProgressIndicator({ data, complete, doOnNext, goTo })
	// const { getSuggestedPrograms } = useSuggestedPrograms(allData?.id as number, false)

	const landingPageData = [{
		img: '/images/icons/mobile-header.jpg',
		logo: 'img-new/homepage/Logos/RunSmart - Horizontal - Normal.png',
		title: 'Your Next PR Awaits.',
		subTitle: !localStorageRequestKey && activeSale ? `WEB SALE - SAVE 45%` : `Run Fast & Injury-Free in ${moment().format('YYYY')}.`,
		desc: `Physical Therapist-designed training plans, strength workouts, yoga, and more – from any device. ${!localStorageRequestKey && activeSale ? '' : 'Try free for 14 days. Continue training for $9.92/month.'}`
	},
	{
		img: '/images/icons/personalized_training_2.png',
		logo: 'img-new/homepage/Logos/RunSmart - Horizontal - Normal.png',
		title: 'Personalized Training',
		subTitle: 'Upgrade Your Spreadsheet',
		desc: 'Get every run, workout, and pace customized to your goal. It’s all at your fingertips.'
	},
	{
		img: '/images/icons/enjoy_flexibility.png',
		logo: 'img-new/homepage/Logos/RunSmart - Horizontal - Normal.png',
		title: 'Flexible & Adaptable',
		subTitle: 'Perfect for Busy Runners',
		desc: 'Life happens – edit and adjust your plan on the fly to keep up with your changing schedule.'
	},
	{
		img: '/images/icons/injury_prevention.png',
		logo: 'img-new/homepage/Logos/RunSmart - Horizontal - Normal.png',
		title: 'Strength & Yoga',
		subTitle: 'For Injury-Free Running',
		desc: 'Find the perfect stretch or stream strength & yoga workouts. It’s all specifically designed to keep you running your best.'
	},
	{
		img: '/images/icons/steve_gonser_login.png',
		logo: 'img-new/homepage/Logos/RunSmart - Horizontal - Normal.png',
		title: 'Physical Therapist Designed',
		subTitle: 'Unmatched Expertise',
		desc: 'Stay a step ahead with plans, exercises, and drills supported by Physical Therapists who love running as much as you.'
	}]

	const settings = {
		dots: true,
		infinite: true,
		speed: 500,
		slidesToShow: 1,
		slidesToScroll: 1,
		arrows: false
	};

	const setDefaultData = async (id: number) => {
		const abortController = new AbortController();
		await GetOnBoarding(id, abortController.signal).then(
			(r: any) => {
				if (r?.data?.result?.[0]?.user_progress) {
					const bd = JSON.parse(r?.data?.result?.[0]?.user_progress)
					if (bd?.id)
						setBodyData(bd)
				}
			}
		)
	}

	// const gSuggestedPrograms = async (s: string) => {
	// 	await getSuggestedPrograms(s, 200, true)
	// }

	useEffect(() => {
		if (inactive === false)
			navigate('/', { replace: true })
	}, [inactive])

	useEffect(() => {
		if (ref) {
			localStorage.setItem('referral_code', ref)
		}
	}, [ref])

	useEffect(() => {

		if (currentUser?.account_id) {
			if (currentUser?.onBoardingData?.id) {
				setBodyData(currentUser.onBoardingData)
				setGoTo({ step: 2, subStep: 2 })
			} else {
				const flName = {
					firstName: currentUser?.first_name || '',
					lastName: currentUser?.last_name || ''
				}
				const newData = allData ? { ...allData, ...flName } : flName
				setBodyData({ ...newData, id: currentUser.account_id })
				setSkipCreate(true)
			}
		}

		if (currentUser?.account_id && currentUser?.onBoardingData?.id && !currentUser?.plan_code) setStart(true)

	}, [currentUser?.account_id])

	useEffect(() => {
		if (p_id) setResumeOnboarding(true);
		else setResumeOnboarding(false);
	}, [p_id])


	useEffect(() => {
		if (obData) {
			const d = { ...obData }
			let suggestedString = ''
			const rA = d?.runningActivity?.titleMI
			suggestedString += rA?.indexOf('No running') > -1 ? suggestedString : `running ${rA?.replaceAll(' ', '').replaceAll('to', '-').replaceAll('mi', '').replaceAll('mi', '').replaceAll('Morethan', '> ')} miles/km`
			d?.goals?.forEach((ag: any) => {
				if (ag?.title?.details)
					suggestedString += `${suggestedString ? ', ' : ''}${ag?.title?.details}`
			})

			d?.problemAreas?.forEach((ag: any, agi: number) => {
				if (ag?.title !== 'None') {
					const isLast = agi > 0 && agi === d?.problemAreas?.length - 1
					suggestedString += !agi ? `${suggestedString ? ', ' : ''}struggle with ` : ''
					suggestedString += `${suggestedString && agi ? ', ' : ''}${isLast && ag?.title?.indexOf('/') === -1 ? 'and ' : ''}${ag?.title?.replaceAll('(s)', '').replaceAll(' /', `,${isLast ? ' and' : ''}`)}`
				}
			})
			// gSuggestedPrograms(suggestedString)
		}
	}, [obData])

	useEffect(() => {
		if (id)
			setDefaultData(id)
	}, [id])

	useEffect(() => {
		if (allData?.id && id !== allData.id)
			setId(allData.id)
	}, [allData])

	return <>
		<Loader active={isLoading} />
		<Box className='login-v2' sx={{ minHeight: window.innerHeight, maxWidth: '600px', margin: 'auto' }}>

			{!resumeOnboarding ? <>
				<Slider {...settings}>
					{landingPageData.map((ld: any, ldi: number) => {
						return <Box key={'ld' + ldi} sx={{ height: window.innerHeight - 184, width: '100%', maxHeight: '710px' }} >
							<Box sx={{ display: 'flex', width: '100%', overflow: 'hidden', height: `calc( 100% - ${window.innerWidth < 385 ? 200 : 180}px)`, maxHeight: '550px', justifyContent: 'center', alignItems: 'center' }}>
								<img src={ld.img} style={{ height: '100%', width: 'auto' }} />
							</Box>
							<Box sx={{ width: '100%' }}>
								<Grid container sx={{ p: 2, flexDirection: 'column' }} >
									<Box>
										<img src={ld.logo} style={{ height: '30px', width: 'auto' }} />
									</Box>
									<Box sx={{ fontFamily: 'Poppins-Bold', fontSize: window?.innerWidth <= 320 ? '19px' : '20px' }}>
										{ld.title}
									</Box>
									{ld.subTitle && <Box sx={{ fontFamily: 'Poppins-SemiBold', fontSize: '18px', color: '#0c71c3' }}>
										{ld.subTitle}
									</Box>}
									<Box sx={{ fontFamily: 'Poppins-Light', fontSize: '14px' }}>
										{ld.desc}
									</Box>
								</Grid>
							</Box>
						</Box>
					})}
				</Slider>
				<Box sx={{ display: 'flex', flexDirection: 'column', height: '160px', pt: '40px', justifyContent: 'center' }}>
					<Grid container sx={{ pt: '10px', pb: '5px', px: 2 }}>
						<RSButton onClick={() => setStart(true)}>
							{cId ? 'Continue' : (!localStorageRequestKey && activeSale) ? 'Sign Up' : 'Sign Up for Free'}
						</RSButton>
					</Grid>
					<Grid container sx={{ pb: '10px', pt: '5px', px: 2 }}>
						<RSButton secondary onClick={cId ? signOut : () => navigate('/login', { replace: true })}>
							{`Log ${cId ? 'Out' : 'In'}`}
						</RSButton>
					</Grid>
				</Box>

			</> : <></>}

			<RSDrawer
				light
				open={start}
				onClose={() => setStart(false)}
				sx={{ maxWidth: '100%' }}
			>
				<Grid container xs={12} sx={{ width: '100%' }} justifyContent='center'>
					{progressUI}
				</Grid>
			</RSDrawer>
		</Box>
	</>
}

export default WithAuth(LogInPage, true)
