import { Box, Grid } from "@mui/material";
import RSButton from "../components/buttons/RSButton";
import Lottie from 'lottie-react'
import { signOut } from '../modules/accountActions'

import RunningLoader from '../components/animation/runsmart_loader.json'

const ReturnPage = () => {

    return <>

        <Box sx={{ width: '100vw', height: '100vh', alignItems: 'center', justifyContent: 'center', alignContent: 'center', textAlign: 'center' }}>

            <h1 style={{fontWeight: 'bold'}}>RunSmart</h1>

            <Box sx={{ width: '100vw', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                <Grid sx={{ width: '200px', height: '200px' }}>
                    <Lottie animationData={RunningLoader} />
                </Grid>
            </Box>

            <h2 style={{ fontStyle: 'italic' }}>redirecting you shortly . . . </h2>
            <h2 style={{ fontStyle: 'italic' }}>or click the button below</h2>

            <RSButton sx={{ width: '8rem' }} onClick={() => { signOut() }}>Continue</RSButton>

        </Box>

    </>
}

export default ReturnPage;