// import { FavoriteBorder } from '@mui/icons-material'
import Favorite from '@mui/icons-material/Favorite'
// import FavoriteBorder from '@mui/icons-material/FavoriteBorder'
import classNames from 'classnames'
import { FC, useState, useEffect } from 'react'

// Props
export type HeartProps = {
	isLiked?: boolean
	hasSpace?: boolean
	className?: string
	onLike?: (x: boolean) => any
	isNotAbsolute?: boolean,
	isFromMetadata?: boolean
}

/**
 * Heart Component
 */

const Heart: FC<HeartProps> = ({ isLiked, hasSpace, className, onLike, isNotAbsolute, isFromMetadata }) => {
	// State
	const [hasLiked, setHasLiked] = useState(isLiked ? true : false)
	const curClassName = classNames(isNotAbsolute ?
		'heart-icon-not-abs' : 'heart-icon',
		className,
		hasSpace ? 'video-card-icon' : undefined,
		isFromMetadata ? 'heart-metadata': undefined,
		isFromMetadata && !hasLiked ? 'heart-metadata-unlike': undefined
	)

	// Toggle Like
	const toggleLiked = (e: any) => {
		setHasLiked(!hasLiked)
		if (onLike) onLike(!hasLiked)
		e?.stopPropagation()
	}

	// Like Changed Effect
	useEffect(() => {
		setHasLiked(isLiked ? true : false)
	}, [isLiked])

	// Render
	return (
		<div className={curClassName} onClick={toggleLiked}>
			{hasLiked ? (<>
				<Favorite className="is-heart-icon" htmlColor='#F83D61' />
			</>

			) : (<>
				<Favorite className="is-heart-icon" htmlColor='#fff' />
			</>
			)}
		</div>
	)
}

export default Heart
