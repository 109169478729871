import Card from 'react-bootstrap/Card'
import { FC } from 'react'
import VimeoPlayerDefault from '../misc/VimeoPlayerDefault'

// Props
export type BodyComponentProps = {
	bgImage?: string
	vimeoId?: string | number
	showVimeoPlayer?: boolean
	isPlaying?: boolean
}

/**
 * BodyComponent
 */

const BodyComponent: FC<BodyComponentProps> = ({
	children,
	bgImage,
	vimeoId,
	showVimeoPlayer,
	isPlaying = true,
}) => {
	return (
		<>
			{(bgImage && !showVimeoPlayer ? true : false) ? (
				<>
					<Card.Img src={bgImage} />
					<Card.ImgOverlay>{children}</Card.ImgOverlay>
				</>
			) : (
				<Card.Body>
					{children}
					<div className={vimeoId && showVimeoPlayer ? undefined : 'd-none'}>
						{vimeoId && <VimeoPlayerDefault id={vimeoId} isPlaying={isPlaying} />}
					</div>
				</Card.Body>
			)}
		</>
	)
}

export default BodyComponent