/* eslint-disable @typescript-eslint/no-unused-vars */
import { useContext, useEffect } from 'react'
import VimeoPlayer from '../components/misc/VimeoPlayer'
import useScreenOrientation from '../hooks/useScreenOrientation'
import { IVimeoPlayerStore } from '../store/createVimeoPlayerStore'
import useStore from '../store/useStore'
import { Slider, styled } from '@mui/material';
import { Airplay, ArrowBackIos, Forward10, Replay10 } from '@mui/icons-material'
import { FaBackwardStep, FaChromecast, FaForwardStep, FaRegCirclePause, FaRegCirclePlay } from 'react-icons/fa6'
import Duration from '../components/misc/Duration'
import { WebSocketContext } from '../WebsocketProvider'
import useQueryParams from '../hooks/useQueryParams'
import GoogleCastButton from '../components/buttons/GoogleCastButton'
import useDeviceDetect from '../hooks/useDeviceDetect'
import usePlayCastVideo from '../hooks/usePlayCastVideo'

const VideoPlayer = (props: any) => {

    const { vimeoId, showVimeoPlayer, isPlaying, isFullScreen, isShowControls, setIsFullScreen, setIsPlaying, setShowVimeoPlayer, setVimeoId, currentAction, setCurrentAction, setIsShowControls, currentTitle, setCurrentTitle, currentTimeValue, setCurrentTimeValue, currentList, setCurrentList } = useStore((state: IVimeoPlayerStore) => state)
    const { backToProgramPage, progress } = props

    const orientation = useScreenOrientation()

    const googleCastStatus = localStorage.getItem('google-cast-status');

    const { isMobile, mobileType } = useDeviceDetect()

    const { requestKey: deviceKey } = useQueryParams()

    const requestKey = localStorage.getItem('deviceRequestKey') || deviceKey

    const { playVideo } = usePlayCastVideo(requestKey)

    const { sendJsonMessage } = useContext(WebSocketContext);

    const RunsmartSlider = styled(Slider)({
        background: 'unset',
        color: '#9fcd2b',
        height: `${orientation?.includes('landscape') ? '' : '90vh'}`,
        '& .MuiSlider-track': {
            border: 'none',
        },
        '& .MuiSlider-thumb': {
            height: 16,
            width: 16,
            backgroundColor: '#fff',
            border: '2px solid currentColor',
            '&:focus, &:hover, &.Mui-active, &.Mui-focusVisible': {
                boxShadow: 'inherit',
            },
            '&:before': {
                display: 'none',
            },
        },
        '& .MuiSlider-valueLabel': {
            visibility: 'hidden',
            lineHeight: 1.2,
            fontSize: 12,
            background: 'unset',
            padding: 0,
            width: 32,
            height: 32,
            borderRadius: '50% 50% 50% 0',
            backgroundColor: '#fff',
            transformOrigin: 'bottom left',
            transform: 'translate(50%, -100%) rotate(-45deg) scale(0)',
            '&:before': { display: 'none' },
            '&.MuiSlider-valueLabelOpen': {
                transform: 'translate(50%, -100%) rotate(-45deg) scale(1)',
            },
            '& > *': {
                transform: 'rotate(45deg)',
            },
        },
    });

    const handleClosePlayer = () => {
        if (isFullScreen) {
            backToProgramPage ? backToProgramPage() : undefined
            setCurrentTimeValue({
                duration: 0,
                currentSec: 0,
                percent: 0
            })
            setShowVimeoPlayer(false)
            setIsFullScreen(false)
            setIsPlaying(false)
            setVimeoId(undefined)
            setCurrentAction(undefined)
            setIsShowControls(false)
        }
        else {
            setShowVimeoPlayer(false)
            setVimeoId(undefined)
            setIsFullScreen(false)
            setIsPlaying(false)
            setIsShowControls(false)
        }
    }

    const handlePlayOrPause = () => {
        if ((googleCastStatus === 'active' || googleCastStatus === 'playing') && vimeoId) {
            // playVideo(vimeoId)
            null
        }
        else {
            if (currentAction === "play")
                setCurrentAction('pause')
            else
                setCurrentAction("play")
        }
    }

    const onChromeCastClick = () => {
        setCurrentAction('pause')

        localStorage.setItem('google-cast-status', '')
        sendJsonMessage && sendJsonMessage({
            requestType: 'google-cast-toggle-dialog',
            requestKey: requestKey,
            payload: undefined,
            timestamp: new Date().getTime(),
        })
    }

    const onAirPlayClick = () => {
        if ((googleCastStatus === 'active' || googleCastStatus === 'playing') && vimeoId) {
            null
        } else {
            sendJsonMessage && sendJsonMessage({
                requestType: 'airplay-toggle',
                requestKey: requestKey,
                payload: undefined,
                timestamp: new Date().getTime(),
            })
        }

    }

    const handleChangeSlider = (event: Event | undefined, newValue: number | number[]) => {
        localStorage.setItem('currentPlayerPercent', String(newValue))
        setCurrentAction('seek-video')
        const percent = Math.abs(Number(newValue) - 100)
        const newTimeValue = (percent * currentTimeValue.duration) / 100
        setCurrentTimeValue({
            duration: currentTimeValue.duration,
            percent: percent,
            currentSec: newTimeValue
        })
        setTimeout(() => { setCurrentAction('play') }, 1000)
    }

    const handleNextVideo = () => {
        const currentVideo = currentList.find((x) => x.id === vimeoId)
        if (currentVideo) {
            const currentIndex = currentList.indexOf(currentVideo)
            const nextVideoIndex = currentIndex + 1;
            if (nextVideoIndex < currentList.length) {
                setCurrentAction('next-video')
                const nextVideo = currentList[nextVideoIndex]
                const title = `${nextVideo?.category} | ${nextVideo?.headingTitle}`
                setCurrentTitle(title)
                setVimeoId(nextVideo.id)
            }
        }
    }

    useEffect(() => {
        if (currentTimeValue.percent == 100) {
            handleClosePlayer()
        }
    }, [currentTimeValue])


    useEffect(() => {
        currentAction ? localStorage.setItem('currentAction', currentAction) : undefined
    }, [currentAction])

    useEffect(() => {
        if (vimeoId && isPlaying && isFullScreen) {
            setIsShowControls(true)
            setCurrentAction('play')
        }
    }, [vimeoId, isPlaying, isFullScreen])

    // useEffect(() => {
    //     if (progress && progress > 0) {
    //         handleChangeSlider(undefined, Number(progress))
    //     }
    // }, [progress])

    useEffect(() => {
        if (isFullScreen && showVimeoPlayer) {
            sendJsonMessage && sendJsonMessage({
                requestType: 'hide-status-bar',
                requestKey: requestKey,
                payload: {},
                timestamp: new Date().getTime()
            })
        }
        else if (!isFullScreen && !showVimeoPlayer) {
            sendJsonMessage && sendJsonMessage({
                requestType: 'show-status-bar',
                requestKey: requestKey,
                payload: {},
                timestamp: new Date().getTime()
            })
        }
    }, [showVimeoPlayer, isFullScreen])

    useEffect(() => {
        // const showControlDelay = setTimeout(() => {
        //     setIsShowControls(false)
        // }, 5000)

        // if (isShowControls && showVimeoPlayer) {
        //     showControlDelay
        // }
        // else if (!isShowControls && !showVimeoPlayer) {
        //     setIsShowControls(false)
        // }

        // return () => {
        //     clearTimeout(showControlDelay)
        // }

    }, [isShowControls, showVimeoPlayer])

    useEffect(() => {
        return () => {
            setCurrentTimeValue({
                duration: 0,
                currentSec: 0,
                percent: 0
            })
            setShowVimeoPlayer(false)
            setIsFullScreen(false)
            setIsPlaying(false)
            setVimeoId(undefined)
            setCurrentList([])
            setCurrentAction(undefined)
            setIsShowControls(false)
        }
    }, [])

    return (
        <div className={showVimeoPlayer && isFullScreen ? `${orientation?.includes('landscape') ? 'video-screen-landscape' : 'video-screen-portrait'}` : 'd-none'}>
            {/* <h1 style={{ position: 'absolute', color: '#F01', zIndex: 99999 }}>{seconds}</h1> */}

            {showVimeoPlayer && vimeoId && currentAction !== "next-video" ? <VimeoPlayer id={vimeoId} isPlaying={isPlaying} isMuted={false} currentProgress={progress} /> : undefined}
            <div className={`${isShowControls ? 'show-controls' : 'hide-controls'} ${orientation?.includes('landscape') ? 'landscape' : 'portrait'}`}>
                <div className='upper-controls'>
                    <div onTouchStart={handleClosePlayer} className={`full-video-player-close`}>
                        <ArrowBackIos fontSize="large" htmlColor="#fff" />
                    </div>
                    <div onTouchStart={handleClosePlayer} className='video-title'>
                        <p className=''>{currentTitle || 'RunSmart'}</p>
                    </div>
                    <div>
                        {
                            isMobile && mobileType === "Ios" ? (<div onTouchStart={onAirPlayClick} className={`full-video-chromecast-btn`}>
                                <Airplay fontSize='large' htmlColor={`${googleCastStatus === 'playing' || googleCastStatus === 'active' ? '#C0C0C0' : '#FFF'}`} />
                            </div>) : undefined
                        }


                        <div onTouchStart={() => setCurrentAction('pause')} className={`full-video-chromecast-btn`}>
                            {/* <FaChromecast size={30} color={`${localStorage.getItem('google-cast-status') == 'active' || localStorage.getItem('google-cast-status') == 'playing' ? '#9fcd2b' : '#fff'}`} /> */}
                            <GoogleCastButton isCustomPlayer={true} vimeoId={vimeoId} currentProgress={progress} />
                        </div>

                        {/* <div onClick={() => setCurrentAction('enter-pip')} className={`full-video-pip-btn`}>
                        <ContentCopyOutlined fontSize='large' htmlColor='#fff' />
                    </div> */}
                    </div>
                </div>
                <div className='controls'>
                    <div className='center-controls'>
                        {/* <FaArrowRotateLeft size={40} color='#fff' /> */}
                        <div style={{ width: 'auto', display: 'flex', alignItems: 'center', flexDirection: 'row' }} onTouchStart={() => {
                            if (googleCastStatus === 'playing' || googleCastStatus === 'active') {
                                null
                            } else {
                                setCurrentAction('rewind')
                                setTimeout(() => { setCurrentAction('play') }, 200)
                            }
                        }
                        }>
                            <Replay10 className='scaled-high' fontSize="large" htmlColor={`${googleCastStatus === 'playing' || googleCastStatus === 'active' ? '#C0C0C0' : '#FFF'}`} />
                        </div>
                        {
                            currentAction === "pause" ? (
                                <div onTouchStart={handlePlayOrPause} className={`full-video-play-btn`}>
                                    <FaRegCirclePlay size={60} color={`${googleCastStatus === 'playing' || googleCastStatus === 'active' ? '#C0C0C0' : '#FFF'}`} />
                                </div>
                            ) :
                                <div onTouchStart={handlePlayOrPause} className={`full-video-play-btn`}>
                                    <FaRegCirclePause size={60} color={`${googleCastStatus === 'playing' || googleCastStatus === 'active' ? '#C0C0C0' : '#FFF'}`} />
                                </div>
                        }
                        {/* <FaArrowRotateRight size={40} color='#fff' /> */}
                        <div style={{ width: 'auto', display: 'flex', alignItems: 'center', flexDirection: 'row' }} onTouchStart={() => {
                            if (googleCastStatus === 'playing' || googleCastStatus === 'active') {
                                null
                            } else {
                                setCurrentAction('forward')
                                setTimeout(() => { setCurrentAction('play') }, 200)
                            }
                        }
                        }>
                            <Forward10 className='scaled-high' fontSize="large" htmlColor={`${googleCastStatus === 'playing' || googleCastStatus === 'active' ? '#C0C0C0' : '#FFF'}`} />
                        </div>
                    </div>
                    {/* <div id="progress-bar-container"> */}
                    <div className="progress-bar">
                        <div className='rotate-90deg'>
                            <Duration cl='' seconds={currentTimeValue.currentSec} />
                        </div>
                        <RunsmartSlider
                            orientation="vertical"
                            defaultValue={100}
                            aria-label="VideoSlider"
                            valueLabelDisplay="auto"
                            value={100 - currentTimeValue.percent}
                            track='inverted'
                            step={1}
                            onChange={handleChangeSlider}
                            disabled={googleCastStatus === 'playing' || googleCastStatus === 'active' ? true : false}
                        // max={0}
                        // min={currentTimeValue.duration}

                        />
                        <div className='rotate-90deg'>
                            <Duration cl='' seconds={currentTimeValue.duration} />
                        </div>
                    </div>

                    <div className='bottom-controls'>
                        <div className='button-ctrl'>
                            <FaBackwardStep size={30} color={`${googleCastStatus === 'playing' || googleCastStatus === 'active' ? '#C0C0C0' : '#FFF'}`} />
                            <p onTouchStart={() => {
                                if (googleCastStatus === 'playing' || googleCastStatus === 'active') {
                                    null
                                } else {
                                    setCurrentAction('restart-video')
                                    setTimeout(() => { setCurrentAction('play') }, 200)
                                }
                            }}>Restart</p>
                        </div>
                        <div className='button-ctrl'>
                            <p onTouchStart={() => {
                                if (googleCastStatus === 'playing' || googleCastStatus === 'active') {
                                    null
                                } else {
                                    handleNextVideo()
                                    setCurrentAction('next-video')
                                    setTimeout(() => { setCurrentAction('play') }, 200)
                                }
                            }}>Next Video</p>
                            <FaForwardStep size={30} color={`${googleCastStatus === 'playing' || googleCastStatus === 'active' ? '#C0C0C0' : '#FFF'}`} />
                        </div>
                    </div>

                </div>
            </div>
        </div>
    )
}

export default VideoPlayer