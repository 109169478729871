/* eslint-disable @typescript-eslint/no-unused-vars */
import GenericForm, { GenericFormProps } from './GenericForm'
import { InputInfo } from './SignInForm'
import { isRequired, validEmail } from '../../modules/validation'
import { FC, useState, useEffect } from 'react'
import useFormInfo, { FormInfo } from '../../hooks/useFormInfo'
import secureLocalStorage from 'react-secure-storage'
import { Col, FloatingLabel, Form, Row } from 'react-bootstrap'
import BasicInput from '../input/BasicInput'
export type { FormInfo }

// Props
export type AccountFormProps = {
	setIsValid: (x: boolean) => any
	setInfo: (x: FormInfo) => any
	username?: string
	email?: string
	firstName?: string
	lastName?: string
	gender?: string
	dob?: string
	favorite?: string
	joinReason?: string
	isJoin?: boolean
	hasSurvey?: boolean
	type?: string,
	defaultUsername?: string,
	distanceUnit?: string,
	dayStart?: string
}

// Account Props
export const accountProps: GenericFormProps['inputs'] = [
	{
		id: 'user-input',
		placeholder: 'Username',
		label: 'Username',
		formKey: 'user',
		validations: [isRequired],
	},
	{
		id: 'email-input',
		placeholder: 'Email',
		label: 'Email',
		formKey: 'email',
		validations: [isRequired, validEmail],
	},
	{
		id: 'first-input',
		placeholder: 'First Name',
		label: 'First Name',
		formKey: 'firstName',
		validations: [isRequired],
	},
	{
		id: 'last-input',
		placeholder: 'Last Name',
		formKey: 'lastName',
		label: 'Last Name',
		skipNewRow: true,
		validations: [isRequired],
	},
	{
		id: 'gender-input',
		placeholder: 'Gender',
		label: 'Gender',
		formKey: 'gender',
		options: {
			Male: 'M',
			Female: 'F',
			Other: 'O',
		},
	},
	{
		id: 'dob-input',
		placeholder: 'Date of Birth',
		label: 'Date of Birth',
		formKey: 'dob',
		isDate: true,
		validations: [isRequired],
	},
	// {
	// 	id: 'fav-distance-input',
	// 	placeholder: 'Favorite Distance',
	// 	label: 'Favorite Distance',
	// 	formKey: 'favorite',
	// 	options: {
	// 		'5K': '5K',
	// 		'10K': '10K',
	// 		'Half Marathon': 'Half Marathon',
	// 		Marathon: 'Marathon',
	// 		'Ultra Marathon': 'Ultra Marathon',
	// 	},
	// },
	{
		id: 'distance-unit-input',
		placeholder: 'Distance Unit',
		label: 'Distance Unit',
		formKey: 'distanceUnit',
		options: {
			'Select Unit...': '',
			'Miles': 'mi',
			'Kilometer': 'km',
		},
	},
	{
		id: 'day-start-input',
		placeholder: 'Week Day Start',
		label: 'Week Day Start',
		formKey: 'dayStart',
		options: {
			'Select Day...': '',
			'Sunday': 'sunday',
			'Monday': 'monday',
		},
	},
]

export const joinProps: GenericFormProps['inputs'] = [
	...accountProps.slice(2),
	{
		id: 'join-reason-input',
		placeholder: 'Reason for Joining',
		label: 'Reason for Joining',
		formKey: 'joinReason',
		options: {
			'Get Stronger': 'strength',
			'Improve Your Form': 'form',
			'Prevent Injury': 'injury',
			'Train for a Race': 'race',
			'Get Back in Shape': 'shape'
		},
	},
]

export const joinPropsHasSurvey: GenericFormProps['inputs'] = [
	...accountProps.slice(2),
]

// Account Form Info
export const accountFormInfo = {
	user: { value: '', isValid: false } as InputInfo,
	email: { value: '', isValid: false } as InputInfo,
	firstName: { value: '', isValid: false } as InputInfo,
	lastName: { value: '', isValid: false } as InputInfo,
	gender: { value: '', isValid: true } as InputInfo,
	dob: { value: '', isValid: false } as InputInfo,
	favorite: { value: '', isValid: true } as InputInfo,
	distanceUnit: { value: '', isValid: true } as InputInfo,
	dayStart: { value: '', isValid: true } as InputInfo
}

// Join Form Info
export const joinFormInfo = {
	firstName: accountFormInfo.firstName,
	lastName: accountFormInfo.lastName,
	gender: accountFormInfo.gender,
	dob: accountFormInfo.dob,
	favorite: accountFormInfo.favorite,
	joinReason: { value: '', isValid: true } as InputInfo,
}

// Join Form Info
export const joinFormInfoHasSurvey = {
	firstName: accountFormInfo.firstName,
	lastName: accountFormInfo.lastName,
	gender: accountFormInfo.gender,
	dob: accountFormInfo.dob,
	favorite: accountFormInfo.favorite,
}

// Account Form Info
export type AccountFormInfo = typeof accountFormInfo

// Join Form Info
export type JoinFormInfo = typeof joinFormInfo

/**
 * AccountForm Component
 */

const AccountForm: FC<AccountFormProps> = ({
	setIsValid,
	setInfo,
	username,
	email,
	firstName,
	lastName,
	gender,
	dob,
	favorite,
	joinReason,
	isJoin,
	hasSurvey,
	type,
	defaultUsername,
	distanceUnit,
	dayStart
}) => {
	// Properties
	const isAppleSignup = secureLocalStorage.getItem("isAppleSignup")
	const isGoogleSignup = secureLocalStorage.getItem("isGoogleSignup")
	const localStorageRequestKey = localStorage.getItem('deviceRequestKey')

	!isJoin && (isAppleSignup || isGoogleSignup) ? accountProps[0].disabled = true : accountProps[0].disabled = false;
	!isJoin && (isAppleSignup || isGoogleSignup) ? accountProps[1].disabled = true : accountProps[1].disabled = false;

	let inputProps = isJoin && !hasSurvey ? joinProps : isJoin && hasSurvey ? joinPropsHasSurvey : accountProps
	const defaultFormInfo = isJoin && !hasSurvey ? joinFormInfo : isJoin && hasSurvey ? joinFormInfoHasSurvey : accountFormInfo



	// State
	const [formInfo, setFormInfo] = useState<FormInfo>(defaultFormInfo)

	// Info Change Effect
	useEffect(() => {
		const newFormInfo = { ...formInfo }
		if ((newFormInfo as AccountFormInfo).user)
			(newFormInfo as AccountFormInfo).user.value = (isAppleSignup || isGoogleSignup) ? defaultUsername as string : username || ''
		if ((newFormInfo as AccountFormInfo).email)
			(newFormInfo as AccountFormInfo).email.value = email || ''
		if ((newFormInfo as AccountFormInfo).distanceUnit)
			(newFormInfo as AccountFormInfo).distanceUnit.value = distanceUnit || ''
		if ((newFormInfo as AccountFormInfo).dayStart)
			(newFormInfo as AccountFormInfo).dayStart.value = dayStart || ''
		if ((newFormInfo as JoinFormInfo).joinReason)
			(newFormInfo as JoinFormInfo).joinReason.value = joinReason || ''
		newFormInfo.firstName.value = firstName || ''
		newFormInfo.lastName.value = lastName || ''
		newFormInfo.gender.value = gender || ''
		newFormInfo.dob.value = dob || ''
		newFormInfo.favorite.value = favorite || ''
		setFormInfo(newFormInfo)
	}, [username, firstName, lastName, gender, dob, favorite, distanceUnit, dayStart])

	// Hooks
	useFormInfo({ inputProps, defaultFormInfo: formInfo, setInfo, setIsValid })
	if (type !== "profile") {
		inputProps = inputProps.filter((x, k) => (k != 5)).filter((x, k) => k != 5)
	}
	// Render
	return (
		<>
			{type === "profile" ? (
				<>
					<Form className='mb-3 text-center' id='account-form'>
						{!isAppleSignup || !isGoogleSignup ? (
							<>
								<Row className='mb-2' xs={12}>
									<Col xs={12}>
										<BasicInput floatingLabel {...accountProps[0]} />
									</Col>
								</Row>
								<Row className='mb-2' xs={12}>
									<Col xs={12}>
										<BasicInput floatingLabel {...accountProps[1]} />
									</Col>
								</Row>
							</>
						) : undefined
						}

						<Row className='mb-2' xs={12}>
							<Col xs={6}>
								<BasicInput floatingLabel {...accountProps[2]} />
							</Col>
							<Col xs={6}>
								<BasicInput floatingLabel {...accountProps[3]} />
							</Col>
						</Row>

						<Row className='mb-2' xs={12}>
							<Col xs={6}>
								<BasicInput floatingLabel {...accountProps[4]} />
							</Col>
							<Col xs={6}>
								<BasicInput floatingLabel {...accountProps[5]} />
							</Col>
						</Row>
						{/* <Row className='mb-2' xs={12}>
							<Col xs={12}>
								<BasicInput floatingLabel {...accountProps[6]} />
							</Col>
						</Row> */}
						<Row className='mb-2' xs={12}>
							<Col xs={6}>
								<BasicInput floatingLabel {...accountProps[6]} />
							</Col>
							<Col xs={6}>
								<BasicInput floatingLabel {...accountProps[7]} />
							</Col>
						</Row>
					</Form>
				</>
			) : <GenericForm isHorizontal inputs={inputProps} />}
		</>
	)
}

export default AccountForm
