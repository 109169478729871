import { FC } from 'react';

import Chip from '@mui/material/Chip';

export type RSChipButtonProps = {
	disabled?: boolean,
	removed?: boolean,
	onClick?: Function,
	setRemove?: Function,
	setAdd?: Function,
	label?: string,
	sx?: object,
	icon?: any,
	iconRight?: boolean,
	secondary?: boolean
}



const RSChipButton: FC<RSChipButtonProps> = ({disabled, removed, setRemove, setAdd, label, sx={}, icon, iconRight, secondary}) => {

	return <Chip
		sx={{
			height: '28px', minWidth: '55px', borderRadius: '5px !important', color: disabled && secondary ? '#555555' : '#010101', backgroundColor: `${disabled && secondary ? '#cbcbcb' : (removed ? 'white':(secondary ? '#FCC26A' : '#9fcd2b'))} !important`, border: `1px solid ${removed ? '#BDBDBD' : (secondary ? '#FF9A00' : '#9fcd2b')}`,
			'& .MuiChip-label': {
				fontSize: '14px !important',
				fontFamily: 'Poppins-Light !important'
			},
			...sx
		}}
		icon={(!secondary && !iconRight && icon) || undefined}
		label={label || ''}
		variant='outlined'
		disabled={disabled && secondary}
		onClick={()=>!disabled && (removed ? setAdd?.() : setRemove?.())}
		onDelete={()=>!disabled && (removed ? setAdd?.() : setRemove?.())}
		deleteIcon={!secondary ? (iconRight && icon ? icon : (removed == false? <i className={`fa-solid fa-circle-${removed ? 'plus' : 'xmark'}`} style={{color: removed ? 'rgba(0, 0, 0, 0.7)' : '#010101', fontSize: '15px'}} /> : <></>)) : <></>}
	/>
}

export default RSChipButton;
