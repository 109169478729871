/* eslint-disable @typescript-eslint/no-unused-vars */

import Player from '@vimeo/player'
import { updateVideoProgressV2 } from '../modules/accountActions'
import { ITimeValue } from '../store/createVimeoPlayerStore'

/**
 * Use Vimeo Player
 */

const useVimeoPlayer = async ({
	player,
	userId,
	isMuted,
	// action,
	// setAction,
	// showControls,
	isMobileApp,
	// setIsFullScreen,
	// setShowPlayer
	// setVideoVisible,
	isFullScreen,
	setCurrentTimeValue,
	currentProgress
}: {
	player: Player,
	// setAction: (currentAction: string | undefined) => void
	userId: number,
	isMuted?: boolean
	// action?: string,
	// showControls: (isShowControls: boolean) => void,
	// setIsFullScreen: (isFullScreen: boolean) => void,
	// setShowPlayer: (showPlayer: boolean) => void,
	// setVideoVisible: (videoVisible: boolean) => void,
	isMobileApp?: boolean,
	isFullScreen?: boolean,
	setCurrentTimeValue: (value: ITimeValue) => void,
	currentProgress?: number
}) => {
	// Properties
	let title = ''
	let id = 0
	let duration = 0
	let lastSent = 0
	let lastUpdate = 0
	let isFirstSync = true
	let progress: number[] = []
	let lastSyncProgress = '[]'

	if (isMuted)
		await player?.setMuted(true)
	else
		await player?.setMuted(false)

	try {
		if (isMobileApp && isFullScreen)
			window?.addEventListener('touchstart', async () => {
				setTimeout(async () => {
					const action = localStorage.getItem('currentAction')
					const playerPercentClick = Number(localStorage.getItem('currentPlayerPercent') || 0)

					const percent = Math?.abs(playerPercentClick - 100)
					const newTimeValue = (percent * duration) / 100

					if (action && action === "seek-video") {
						player?.pause()
						player?.setCurrentTime(newTimeValue)
						player?.play()
					}

					if (action && action === "pause") {
						player?.pause()
					}
					else if (action && action === "play") {
						player?.play()
					}
					else if (action && action === "forward") {
						const currentTime = await player.getCurrentTime()
						player.setCurrentTime(currentTime + 10)
						player.play()
					}
					else if (action && action === "rewind") {
						const currentTime = await player?.getCurrentTime()
						player?.setCurrentTime(currentTime - 10)
						player?.play()
					}
					else if (action && action === "restart-video") {
						player?.setCurrentTime(0)
						player?.play()
					}
				}, 100)

			})
	}
	catch (error) {
		alert(error)
	}


	// Init Object
	const initObject = async () => {
		const response = await Promise?.all([
			player?.getVideoId(),
			player?.getVideoTitle(),
			player?.getDuration(),
			player?.ready(),
		])

		lastSent = 0
		lastUpdate = 0
		isFirstSync = true
		progress = []
		lastSyncProgress = '[]'
		id = response[0] || 0
		title = response[1] || ''
		duration = Math?.floor(response[2] || 0)
	}

	// Init Data
	const initData = async () => {
		if (!title && !duration && !id)
			await initObject()

		player.on('loaded', async () => {
			if (currentProgress && (currentProgress > 10 && currentProgress < 90)) {
				const curProg = (currentProgress * duration) / 100
				await player?.pause()
				await player?.setCurrentTime(curProg)
				await player?.play()
			}
		})

		player.on('progress', onProgress)
		player.on('progress', !isMuted ? progressUpdate : () => { null })
		player.on('seeked', onSeek)

		if (isMobileApp && isFullScreen)
			player?.on('timeupdate', async (data) => {
				if (data) {
					const action = localStorage.getItem('currentAction')
					if (action !== 'seek-video') {
						setCurrentTimeValue({
							duration: data?.duration,
							currentSec: Math?.ceil(Number(data?.seconds.toFixed())),
							percent: Number((data?.percent * 100)?.toFixed(2))
						})
					}
				}
			});
	}

	// On Seek
	const onSeek = ({ seconds }: { seconds: number }) => {
		; (window as any)?.webkit?.messageHandlers?.cordova_iab?.postMessage(
			JSON.stringify({
				type: 'vimeoSeekEvent',
				id: id || 0,
				time: Number(seconds) || 0,
			})
		)
	}

	// On Progress
	const onProgress = (args: { percent: number; duration: number }) => {
		const { percent, duration: curDuration } = args
		const videoDuration = Math?.floor(curDuration)
		const currentPercent = Math?.min(Math?.ceil(percent * 100), 100)
		if (!duration) duration = videoDuration
		if (!progress?.includes(currentPercent)) {
			progress?.push(currentPercent)
			lastUpdate = new Date().getTime()
		}
	}

	// Progress Update
	const progressUpdate = async () => {
		const curProgress = JSON.stringify(progress) || '[]'
		if (duration && lastSent != lastUpdate && curProgress != lastSyncProgress) {
			await updateVideoProgressV2({
				accountId: userId,
				videoId: id,
				title,
				progress,
				duration,
				isFirst: isFirstSync ? 1 : 0
			})
		}
		lastSyncProgress = curProgress
		lastSent = lastUpdate
		if (isFirstSync) isFirstSync = false
	}

	// Init
	initData()

}

export default useVimeoPlayer
