import Button from 'react-bootstrap/Button'
import { FC } from 'react'
import Drawer from '@mui/material/Drawer'
import Box from '@mui/material/Box'
import Grid from '@mui/material/Grid'


// Props
export type MobileModalProps = {
	show: boolean
	setShow: (x: boolean) => any
	title: string
	text: string
	subtext?: string
	button1Text?: string
	button1Action?: () => any
	button2Text?: string
	button2Action?: () => any
	button3Text?: string
	button3Action?: () => any
	centered?: boolean,
	backdrop?: "static" | boolean,
	keyboard?: boolean
	setEmail?: (email: string) => any
	email?: string
	type?: string
}

/**
 * MobileModal Component
 */

const MobileModal: FC<MobileModalProps> = ({
	show,
	title,
	text,
	button1Text,
	button1Action,
	button2Text,
	button2Action,
}) => {


	// Render
	return (

		<Drawer
			className='bg-main'
			anchor='right'
			open={show}
			sx={{
				'& *': {
					fontFamily: 'Poppins'
				},
				'& .MuiDrawer-paperAnchorRight': {
					width: '100%',
					border: 'none !important',
					backgroundColor: 'inherit',
					margin: 'auto'
				}
			}}>

			<Box className='bg-main' sx={{ display: 'flex', justifyContent: 'flex-start', flexDirection: 'column', position: 'absolute', borderRadius: '5px', pt: '10px', top: '35%', left: '50%', width: '80%', maxWidth: '400px', backgroundColor: 'white', transform: 'translate(-50%, -50%)' }}>

				<Grid container sx={{ pb: '20px' }}>
					<h3 className='text-center w-100 mb-12'>{title}</h3>
				</Grid>

				<Grid container xs={12} sx={{display: 'flex'}}>
					<p className='text-center'>{text}</p>
				</Grid>

				<Grid container xs={12} sx={{ px: '20px', py: '30px' }} justifyContent='center'>

					{button1Text && (
						<>
							<div className="d-inline-block mx-2">
								<Button
									variant="dark"
									onClick={button1Action}
								>
									{button1Text}
								</Button>
							</div>
						</>
					)}

					{/* Button 2 */}
					{button2Text && (
						<div className="d-inline-block mx-2">
							<Button
								variant="dark"
								onClick={button2Action}
							>
								{button2Text}
							</Button>
						</div>
					)}


				</Grid>

			</Box>

		</Drawer>

	)
}

export default MobileModal
