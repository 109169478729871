/* eslint-disable @typescript-eslint/no-unused-vars */
import { useCheckoutPricing, useRecurly } from '@recurly/react-recurly';
import React, { FC, useEffect, useState } from 'react'
import AppleIcon from '@mui/icons-material/Apple';
import { PrimaryButton } from './AuthSubmitButtons';
import { createPurchase, createSubscription, destroySession, getAllAccountInfo, getSubscriptionById, getSubscriptionInfo, getSubscriptionInfoWithError, logIn, updateBillingInfo } from '../../modules/accountActions';
import { navReq } from '../../modules/apiConsume'
import { titleCase } from '../../modules/miscUtils'
import useStore from '../../store/useStore';
import { IComponentStore } from '../../store/createComponentStore';
import useAppStorage from '../../hooks/useAppStorage';
import useLocalStorage from '../../hooks/useLocalStorage';
import { AUTH_COOKIE } from '../../modules/cliEnvValues';
import secureLocalStorage from "react-secure-storage";
import { RouletteSpinnerOverlay } from 'react-spinner-overlay';

interface IAppleButton {
    planCode: string,
    tranxType: 'renew' | 'update' | 'create' | 'reactivate' | "update-renew" | "create-signup",
    afterBillingProcess?: () => any
    afterBillingChange?: () => any
    handleContinue?: () => any
    isAppleSignup?: boolean,
    sx?: object
    // setIsApplePaySupport?: any
}

const ApplePayButton: FC<IAppleButton> = ({ planCode = 'runsmart-monthly', tranxType, afterBillingChange, afterBillingProcess, handleContinue, isAppleSignup = false, sx = {} }) => {
    // const appleUser = localStorage.getItem('rs_login_type') === "apple"
    const recurly = useRecurly()
    const [textError, setTextError] = useState<any | undefined>()
    const [pricing, setPricing] = useState<any | undefined>()
    const [showPrompt, setShowPrompt] = useState(false)
    const [hasError, setHasError] = useState(false)
    const [message, setMessage] = useState("")
    const [variant, setVariant] = useState("success")
    const [title, setTitle] = useState("")
    const [afterAction, setAfterAction] = useState<any>()

    const { setIsLoading, setIsSuccess, isLoading, isSuccess, setToast } = useStore((state: IComponentStore) => state)

    const checkoutPricing = recurly.Pricing.Checkout();
    const subscription = recurly.Pricing.Subscription();

    const getPlanLabelByCode = (planCode: string, hasTrial: boolean) => {
        const trialText = hasTrial ? " (14-Day free trial)" : ""
        return titleCase(planCode) + trialText;
    }

    const [redirect, setRedirect] = useState('')

    // Hooks
    useAppStorage()
    const { storedValue: authCookie } = useLocalStorage({ key: AUTH_COOKIE })

    const hasPlanTrial = (planCode: string) => {
        return planCode === "runsmart-annual" || planCode === "runsmart-monthly" || planCode === "runsmart-annual-discount" ? true : false
    }

    checkoutPricing
        .currency("USD")
        .subscription(subscription.plan(planCode, { quantity: 1 }).currency("USD").done())
        .catch((err) => { alert(err) })
        .done()

    const applePay = recurly.ApplePay({
        country: "US",
        currency: "USD",
        label: tranxType === "update" ? "RunSmart Update Billing" : getPlanLabelByCode(planCode, hasPlanTrial(planCode)),
        total: "0.00",
        pricing: tranxType === "update" ? undefined : checkoutPricing
    })

    const handleApplePayClick = () => applePay?.ready(function () {
        applePay.begin();
    });

    applePay.on('error', function (err: any) {
        // alert(err)
        setTextError(err)
    });

    const errorMsg = () => {
        setTitle("Error: Create Subscription")
        setMessage(`Could not ${tranxType === "update" ? 'update' : 'create'
            } subscription.`)
        setVariant("danger")
        setHasError(true)
        setShowPrompt(true)
        return false
    }
    const successMessage = (amount: any, nextDate: string) => {
        setTitle("Success: Create Subscription")
        setMessage(`Your subscription has been ${tranxType === "update" ? 'updated' : 'created'
            }. You will be charged $${amount} on ${nextDate}.`)
        setVariant("success")
        setHasError(false)
        setShowPrompt(true)
    }

    applePay.on('token', async function (token: any) {
        setIsLoading(true)
        try {
            if (!token) {
                setIsLoading(false)
                return errorMsg()
            } else {
                if (tranxType === "create" || tranxType === "renew" || tranxType === "create-signup")
                    processCreateSubscription(token)
                else if (tranxType === "update")
                    processUpdateBilling(token)
            }
        } catch (err) {
            setIsLoading(false)
            return errorMsg()
        }
    });

    const processCreateSubscription = async (token: any) => {
        try {
            const billingResult = await updateBillingInfo(token.id)
            if (billingResult?.data?.id) {
                // After Billing Update
                if (afterBillingChange) await afterBillingChange()
                const { isActive, subscription } = await getSubscriptionInfo()
                if (isActive && subscription?.id) {
                    // const amount = subscription.amount || 0
                    // const nextDate = subscription?.next_billing
                    //     ? new Date(subscription.next_billing).toLocaleDateString()
                    //     : new Date().toLocaleDateString()

                    // successMessage(amount, nextDate)


                    setIsLoading(false)
                    setIsSuccess(true)
                    setTimeout(() => {
                        setIsSuccess(false)
                        setTimeout(async () => {
                            if (tranxType === "renew")
                                navReq("/#/")
                            if (afterBillingProcess) await afterBillingProcess()
                        }, 500)
                    }, 3000)

                    // After Billing Process
                    // setTimeout(async () => {
                    //     if (tranxType === "renew")
                    //         navReq("/#/")
                    //     if (afterBillingProcess) await afterBillingProcess()
                    //     setIsLoading(false)

                    //     return true
                    // }, 3500)

                }
            }
            else {
                setIsLoading(false)
                return errorMsg()
            }
        }
        catch (err) {
            setIsLoading(false)
            return errorMsg()
        }
    }

    const processUpdateBilling = async (token: any) => {
        try {
            const billingResult = await updateBillingInfo(token.id)
            if (billingResult?.data?.id && billingResult?.data?.valid) {
                const { isActive, subscription } = await getSubscriptionInfo()
                if (isActive && subscription?.id) {
                    // const amount = subscription.amount || 0
                    // const nextDate = subscription?.next_billing
                    //     ? new Date(subscription.next_billing).toLocaleDateString()
                    //     : new Date().toLocaleDateString()

                    // successMessage(amount, nextDate)

                    setIsLoading(false)
                    setIsSuccess(true)
                    setTimeout(() => {
                        setIsSuccess(false)
                        setTimeout(async () => {
                            navReq("/#/account")
                        }, 500)
                    }, 3000)

                    // // After Update Process
                    // setTimeout(async () => {
                    //     navReq("/#/account")
                    //     setIsLoading(false)
                    //     return true
                    // }, 3500)
                }
            } else {
                setHasError(true)
                setIsLoading(false)
            }
        }
        catch (err) {
            setHasError(true)
            setIsLoading(false)
        }

    }
    useEffect(() => {
        setToast({
            show: showPrompt,
            title: title,
            message: message,
            variant: variant,
            onClose: () => {
                setShowPrompt(false)
            },
            duration: 3000
        })
    }, [showPrompt])

    // useEffect(() => {
    //     if (authCookie && redirect) navReq(redirect)
    // }, [authCookie, redirect])

    // useEffect(() => {
    //     if (isLoading)
    //         setTimeout(() => {
    //             setIsLoading(false)
    //             setIsSuccess(true)
    //         }, 3000)
    // }, [isLoading])

    // useEffect(() => {
    //     if (isSuccess)
    //         setTimeout(() => {
    //             setIsSuccess(false)
    //         }, 2000)
    // }, [isSuccess])

    // useEffect(() => {
    //     setIsLoading(true)
    // }, [])


    // useEffect(() => {
    //     // const isSupported = textError?.code === "apple-pay-not-supported" ? false : true
    //     // setIsApplePaySupport(isSupported)
    //     alert(textError)
    // }, [textError])

    return (
        <>
            {/* <RouletteSpinnerOverlay loading={isLoading || isSuccess} color={isLoading ? '#322' : 'none'} size={50} message={
                (<span className='text-center' style={{ marginTop: `${isLoading ? '-90px' : '-20px'}` }}>
                    <h3>{isLoading ? 'PLEASE WAIT' : 'SUCCESS'}</h3>
                    {isSuccess ? (<img width={60} src="images/icons/correct.png" />) : undefined}
                </span>)} /> */}
            {
                tranxType === "create" && textError ? (
                    <PrimaryButton
                        primaryText={"Continue"}
                        primaryAction={() => handleContinue ? handleContinue() : undefined}
                    />
                ) :
                    !textError ?
                        (
                            <>
                                <PrimaryButton
                                    primaryText={tranxType === "create" ? "Continue" : tranxType === "create-signup" && hasPlanTrial(planCode) ? "Subscribe with"
                                        : tranxType === "create-signup" && !hasPlanTrial(planCode) ? "Subscribe with" : tranxType === "renew" || tranxType === "update-renew" ? "Renew with"
                                            : tranxType === "update" ? "Update with"
                                                : "Pay with"}
                                    primaryAction={handleApplePayClick}
                                    primaryDisabled={textError ? true : false}
                                    icon={tranxType === "create" ? undefined : <AppleIcon />}
                                    isPay={true}
                                    addClassName='choose-plan-btn'
                                    sx={sx}
                                />
                                <br />
                                <br />
                                {/* <p style={{ color: "#F01" }}>{textError?.message}</p> */}
                            </>
                        ) : undefined
            }

            {/* {textError?.code === "apple-pay-not-supported" ? (
                <p style={{ color: "#F01" }}>{textError?.message}</p>
            ) : undefined} */}

        </>

    )
}

export default ApplePayButton
