import Player from '@vimeo/player'
import { fetchMediaVideoUrl } from './accountActions'

// Properties
declare let cast: any
declare let chrome: any
declare const videoObjectInit: any
declare const videoProgressEvent: any
declare const videoProgressUpdate: any
declare global {
	interface Window {
		castVideoId: any
		castVideoUrl: any
		castCurPlayer: any
		castIsMobile: boolean
		castIsAvailable: boolean
		hasCastSession: boolean
		initChromecast: () => any
		appShowButtons: () => any
		appToggleActive: () => any
		appToggleInactive: () => any
		appSetCurrentVideoTime: (time: any) => Promise<void>
		appUpdateVideoData: (times: string) => Promise<void>
		chromeClickEvent: (item: any, message?: boolean) => Promise<void>
	}
}

// Format Vimeo URL
const getCastVideoUrl = (item: any) => {
	let element = item
	while (!element.classList.contains('cc-link')) {
		element = element.parentElement
	}
	window.castVideoId = element.getAttribute('vimeo-id')
	window.castVideoUrl = `https://player.vimeo.com/video/${window.castVideoId}`
	const frames = document.querySelectorAll('iframe')
	for (const index in frames) {
		const frame = frames[index]
		const curFrameSrcNoPrefix = frame.src
			? frame.src.substring(frame.src.indexOf('player'))
			: ''
		if (curFrameSrcNoPrefix.includes(window.castVideoId)) {
			window.castCurPlayer = new Player(frame)
			break
		}
	}
}

// Request Raw Video URL
const getRawVideoUrl = async (message = false) => {
	if (!window.hasCastSession) {
		const result = await fetchMediaVideoUrl()
		try {
			window.castVideoUrl = result?.data?.url || ''
			if (message) {
				try {
					; (window as any)?.webkit?.messageHandlers?.cordova_iab?.postMessage(
						JSON.stringify({
							type: 'chromeCastUrlEvent',
							url: window.castVideoUrl,
							id: window.castVideoId,
							time: await getCurrentVideoTime(),
						})
					)
				} catch (error) {
					// Do Nothing
				}
			}
		} catch (error) {
			// Do Nothing
		}
	} else if (window.hasCastSession && message) {
		; (window as any)?.webkit?.messageHandlers?.cordova_iab?.postMessage(
			JSON.stringify({ type: 'chromeCastCloseEvent' })
		)
	}
}

// Get Current Video Time
const getCurrentVideoTime = async () => {
	try {
		if (window.castCurPlayer) {
			const time = await window.castCurPlayer.getCurrentTime()
			return time ? Number(time) : 0
		}
	} catch (err) {
		// Do Nothing
	}
	return 0
}

// Chrome Click Event
export const chromeClickEvent = async (item: any, message = false) => {
	getCastVideoUrl(item)
	getRawVideoUrl(message)
}

// APP - Show Buttons
export const appShowButtons = () => {
	window.castIsMobile = true
	window.castIsAvailable = true
	document.querySelectorAll('.cc-native').forEach((item) => {
		item.classList.remove('cc-hide')
	})
}

// APP - Toggle Active
export const appToggleActive = () => {
	window.hasCastSession = true
	document.querySelectorAll('.cc-btn-active').forEach((item) => {
		item.classList.remove('cc-hide')
	})
	document.querySelectorAll('.cc-btn-inactive').forEach((item) => {
		item.classList.add('cc-hide')
	})
}

// APP - Toggle Inactive
export const appToggleInactive = () => {
	window.hasCastSession = false
	document.querySelectorAll('.cc-btn-inactive').forEach((item) => {
		item.classList.remove('cc-hide')
	})
	document.querySelectorAll('.cc-btn-active').forEach((item) => {
		item.classList.add('cc-hide')
	})
}

// APP - Set Current Video Time
export async function appSetCurrentVideoTime(time: any) {
	try {
		if (window.castCurPlayer) {
			await window.castCurPlayer.setCurrentTime(Number(time))
		}
	} catch (err) {
		// Do Nothing
	}
}

// APP - Update Video Data
export async function appUpdateVideoData(times: string) {
	if (window.castCurPlayer) {
		if (!window.castCurPlayer.duration) {
			await videoObjectInit.bind(window.castCurPlayer)(true)
		}
		const duration = window.castCurPlayer.duration
		const timesArray = JSON.parse(times)
		for (const time of timesArray) {
			const percent = Number(time) / duration
			videoProgressEvent.bind(window.castCurPlayer)({
				percent,
				duration,
			})
		}
		videoProgressUpdate.bind(window.castCurPlayer)()
	}
}

// Cast Available Event
; (window as any).__onGCastApiAvailable = (isAvailable: boolean) => {
	window.castIsAvailable = isAvailable
	if (!isAvailable) return false
	document.querySelectorAll('.cc-desktop').forEach((item) => {
		item.classList.remove('cc-tuck')
	})
	if (cast) {
		const castContext = cast?.framework?.CastContext?.getInstance()
		castContext.setOptions({
			autoJoinPolicy: chrome?.cast?.AutoJoinPolicy?.ORIGIN_SCOPED,
			receiverApplicationId: chrome?.cast?.media?.DEFAULT_MEDIA_RECEIVER_APP_ID,
		})
		const stateChanged = cast?.framework?.CastContextEventType?.CAST_STATE_CHANGED
		castContext.addEventListener(stateChanged, async () => {
			const castSession = castContext.getCurrentSession()
			const media = new chrome.cast.media.MediaInfo(
				window.castVideoUrl,
				'video/mp4'
			)
			const request = new chrome.cast.media.LoadRequest(media)
			if (castSession) {
				try {
					await castSession.loadMedia(request)
				} catch (error) {
					// Do Nothing
				}
			}
		})
	}
}

// Init Chromecast
export const initChromecast = () => {
	window.castVideoId = null
	window.castVideoUrl = null
	window.castCurPlayer = null
	window.hasCastSession = false
	if ((window as any).__onGCastApiAvailable && window.castIsAvailable) {
		setTimeout(() => (window as any).__onGCastApiAvailable(true), 500)
	}
}

window.appShowButtons = appShowButtons
window.appToggleActive = appToggleActive
window.appToggleInactive = appToggleInactive
window.appSetCurrentVideoTime = appSetCurrentVideoTime
window.appUpdateVideoData = appUpdateVideoData
window.chromeClickEvent = chromeClickEvent
window.initChromecast = initChromecast
initChromecast()
