import Line from './Line'
import TextCard, { TextCardProps } from '../../cards/TextCard'
import { FC } from 'react'

// AboutCard Props
export type AboutCardProps = {
	size?: TextCardProps['size']
	title?: string
	className?: string
	isCentered?: boolean
	aboutHeadlines: string[]
	aboutDescriptions: string[]
}

/**
 * AboutCard Component
 */

const AboutCard: FC<AboutCardProps> = ({
	size = 'xl',
	title,
	className,
	isCentered,
	aboutHeadlines,
	aboutDescriptions,
}) => (
	<TextCard
		size={size}
		title={title}
		className={className}
		isCentered={isCentered}
	>
		{aboutHeadlines.map((value, index) => (
			<Line key={index} title={value} text={aboutDescriptions[index] || ''} />
		))}
	</TextCard>
)

export default AboutCard
