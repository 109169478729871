/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useEffect, useState } from 'react';
import { Document, Page, Text, View, StyleSheet, Image, Font, PDFRenderer, PDFViewer } from '@react-pdf/renderer';
import { DAYS, DAYS_ALT, DAYS_OF_WEEK, DAYS_OF_WEEK_ALT } from '../../pages/training-plan/build/DaysOptions';
import useBuildTrainingPlan from '../../pages/training-plan/hooks/useBuildTrainingPlan';
import useTrainingPlanUtils from '../../hooks/useTrainingPlanUtils';
import { UserNotesRecord, UsersRestCommentsRecord } from '../../api/v2/types';
import moment from 'moment';

// Create styles
const styles = StyleSheet.create({
    page: {
        color: '#221E20',
        flexDirection: 'column',
        fontFamily: 'Poppins',
        padding: 30
    },
    section: {
        margin: '0px auto',
        padding: 10,
        flexGrow: 1
    },
    logoContainer: {
        width: '100%',
        textAlign: 'center'
    },
    logo: {
        width: '180px',
        margin: '0px auto',
    },
    profileContainer: {
        marginTop: 10
    },

    title: {
        fontFamily: 'Poppins-Bold',
        textAlign: 'right',
        fontSize: 17,
        color: '#9FCD2A'
    },
    week: {
        fontFamily: 'Poppins-Bold',
        fontSize: 17,
        color: '#9FCD2A'
    },
    name: {
        fontFamily: 'Poppins-Bold',
        textAlign: 'right',
        fontSize: 13,
    },

    raceDetailsRow: {
        marginTop: 15,
        marginBottom: 10,
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'flex-end'
    },

    raceDetailsContainer: {
        borderRadius: 5,
        backgroundColor: '#eee',
        padding: 15,
        width: 200

    },

    weekTotalDistance: {
        display: 'flex',
        flexDirection: 'column',
        width: 260,
        justifyContent: 'flex-end'
    },

    raceName: {
        fontFamily: 'Poppins-Bold',
        fontSize: 16,
    },

    marathonName: {
        fontFamily: 'Poppins-Bold',
        fontSize: 13,
    },

    label: {
        fontFamily: 'Poppins-Bold',
    },

    raceDetailsText: {
        fontSize: 12
    },

    trainingHeader: {
        display: 'flex',
        flexDirection: 'row'
    },

    trainingTitle: {
        width: 75,
        // border: '1px solid #999',
        textAlign: 'center',
        fontSize: 11,
        fontFamily: 'Poppins-Semibold'
    },

    hrTitle: {
        width: 60,
        // border: '1px solid #999',
        textAlign: 'center',
        fontSize: 11,
        fontFamily: 'Poppins-Semibold'
    },

    avePace: {
        width: 161,
        // border: '1px solid #999',
        textAlign: 'center',
        fontSize: 11,
        fontFamily: 'Poppins-Semibold'
    },

    avePaceNotesContainerOdd: {
        width: 160,
        minHeight: 63,
        backgroundColor: '#eee',
        textAlign: 'center',
        fontSize: 11,
        fontFamily: 'Poppins-Semibold',
        marginLeft: 1,
        // overflow: 'hidden'
    },

    avePaceNotesContainerEven: {
        width: 160,
        minHeight: 63,
        textAlign: 'center',
        fontSize: 11,
        fontFamily: 'Poppins-Semibold',
        marginLeft: 1,
        // overflow: 'hidden'
    },

    notes: {
        width: 161,
        // border: '1px solid #999',
        textAlign: 'center',
        fontSize: 11,
        fontFamily: 'Poppins-Semibold'
    },

    notesValues: {
        // border: '1px solid #999',
        textAlign: 'left',
        fontSize: 11,
        fontFamily: 'Poppins',
        margin: 3
    },

    trainingCol: {
        minHeight: 63,
        margin: '0 0 0 0'
    },

    trainingRow: {
        display: 'flex',
        flexDirection: 'row',
    },
    dateCol: {
        width: 80,
        // border: '1px solid #999',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'flex-end',
    },
    dayName: {
        fontFamily: 'Poppins-Bold',
        fontSize: 12
    },
    dateValue: {
        fontSize: 11
    },
    heart: {
        width: 20,
        height: 18,
        padding: 2,
        margin: '0 auto'
    },

    trainingEven: {
        width: 75,
        minHeight: 63,
        fontSize: 8,
        fontFamily: 'Poppins-Bold',
        textAlign: 'center',
        marginLeft: 8,
        padding: 5
    },

    trainingOdd: {
        minHeight: 63,
        width: 75,
        fontSize: 8,
        fontFamily: 'Poppins-Bold',
        textAlign: 'center',
        backgroundColor: '#eee',
        borderTopLeftRadius: 3,
        borderBottomLeftRadius: 3,
        marginLeft: 8,
        padding: 5
    },
    crossRest: {
        fontSize: 9
    },
    distance: {
        fontSize: 22,
        fontFamily: 'Poppins',
    },
    units: {
        marginTop: -3,
        fontSize: 9,
        fontFamily: 'Poppins-Bold',

    },
    distUnitContainer: {
    },

    heartRateContainer: {
        width: 60,
        fontFamily: 'Poppins',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        padding: 5,
        color: '#FFF',
        backgroundColor: '#221E20'
    },
    smallText: {
        fontSize: 8,
        fontFamily: 'Poppins',
    },
    singlePace: {
        marginTop: 20,
        fontSize: 12,
        fontFamily: 'Poppins',
    },

    multiPace: {
        fontSize: 9,
        fontFamily: 'Poppins',
    },

});

Font.register(
    {
        family: 'Poppins',
        src: '/fonts/poppins/Poppins-Regular.ttf',
    }
);
Font.register(
    {
        family: 'Poppins-Bold',
        src: '/fonts/poppins/Poppins-Bold.ttf',
    }
);

Font.register(
    {
        family: 'Poppins-Semibold',
        src: '/fonts/poppins/Poppins-SemiBold.ttf',
    }
);

const TrainingPlanPDF = (props: any) => {
    const { trainingData, userInfo, currentUser, pdfData, vdotAdjustments, customPaces, userRestComments } = props;
    const dataWeek = trainingData?.filter((x: any) => x.marathon.week == 1);
    const [pages, setPages] = useState<Array<any>>([])

    const { getRaceDayDetails, getDailyActivityDisplay, computelWeekTotalDistance, extractPace, distanceInterpret } = useBuildTrainingPlan()
    const { ConvertM2Mt, getWeekDays } = useTrainingPlanUtils()

    const getName = () => {
        if (currentUser?.first_name && currentUser?.first_name !== "") {
            return `${currentUser?.first_name} ${currentUser?.last_name}`
        }
        else {
            return currentUser?.email
        }
    }

    const getCurrentVDOT = () => {
        const data = vdotAdjustments[vdotAdjustments?.length - 1]
        const res = data ? data : userInfo
        return res
    }

    const getConvertedPace = (val: any, week: number, day: number) => {
        const dayData = findData(week, day)
        const convert = extractPace(val, dayData?.marathon?.distance, 0, getCurrentVDOT()?.vdot, (userInfo?.km === 'Y' ? true : false), getCurrentVDOT()?.targetm, userInfo?.aggressiveness, customPaces, userInfo?.distance);
        const value = convert.setPace.split("/")

        const rawPace = convert.rawPace;

        return { value, rawPace }
    }

    const checkPosition = (key: number) => {
        if (key % 2 == 0)
            return 'even'
        else
            return 'odd'
    }

    const getMarathonName = (marathonType: number) => {
        switch (marathonType) {
            case 0:
                return "HALF MARATHON"

            case 1:
                return "FULL MARATHON"
            case 2:
                return "5K"
            case 3:
                return "10K"
            case 4:
                return "Maintenance"
            default:
                break;
        }
    }
    const findData = (week: number, day: number) => {
        const data = trainingData?.find((x: any) => x.marathon.week == week && x.marathon.day == day)
        return data;
    }

    const filterDataWeek = (week: number) => {
        const data = trainingData?.filter((x: any) => x.marathon.week == week)
        return data;
    }

    const extractActivity = (week: number, day: number) => {
        let activity = getDailyActivityDisplay(
            findData(week, day),
            userInfo,
            findData(week, day)?.marathonPaces,
            userInfo?.type as number,
            findData(week, day)?.marathon?.race_day
        )?.toUpperCase() || "REST"

        activity = activity.split(" | ")[0]

        if (userInfo?.km === 'Y') {
            activity = activity.replace("KMS", " KMS")
        }
        return activity;
    }

    const getTotalDistance = (marathonDailyValues: any) => {
        if (userInfo?.km === 'Y')
            return (ConvertM2Mt(+computelWeekTotalDistance(marathonDailyValues)) / 1000).toFixed(1).toString().replace(/(\.0+|0+)$/, '') + ' Kms'
        else if (userInfo?.km === 'N')
            return computelWeekTotalDistance(marathonDailyValues) + ' Miles'
    }

    const getWorkout = (week: number, day: number) => {
        const data = findData(week, day)
        const result = data && data?.workouts ? data.workouts[0]?.title : undefined

        return result === "Cross Train" ? "" : result;
    }

    const isMultiPaces = (week: number, day: number) => {
        const res = findData(week, day)?.marathonPaces?.length > 1 ? true : false
        return res;
    }

    const getUserNotes = (week: number, day: number) => {
        const data = findData(week, day)
        const result: UserNotesRecord = data && data?.userNotes ? data.userNotes : undefined
        return result;
        // const result = data && data?.userNotes ? data.userNotes[0]?.title : undefined
    }

    const getUserRestComments = (week: number, day: number) => {
        const data = userRestComments.find((x: UsersRestCommentsRecord) => x.week == week && x.day == day)
        const result: UsersRestCommentsRecord = data || undefined
        return result;
    }

    const buildPage = () => {
        const days = userInfo?.monday_start ? DAYS_ALT : DAYS
        const pages: Array<any> = []
        const { start, monday_start, race_date, weeks, type, distance } = userInfo

        const details = getRaceDayDetails(userInfo)
        const saturdayRaceMondayStart = details.raceDay == 5 && details.saturdayRace;
        const saturdayRaceSundayStart = details.raceDay == 6 && !details.saturdayRace;

        const weekDays = getWeekDays(new Date(), userInfo.start, userInfo.race_date, userInfo.monday_start ? DAYS_OF_WEEK_ALT : DAYS_OF_WEEK);

        for (let i = 1; i <= weekDays.length; i++) {

            const checkRaceWeek = () => {
                //sunday start - monday race
                if (details.raceDay == 1 && (+weeks + 1) == i && type != 4)
                    return true
                //sunday start - sunday race
                else if (details.raceDay == 0 && (+weeks + 1) == i && type != 4)
                    return true
                //sunday start - saturday race
                else if (saturdayRaceSundayStart && weeks == i && type != 4)
                    return true
                else if (saturdayRaceMondayStart && weeks == i && type != 4) {
                    return true;
                }
                else
                    return false;
            }

            const isRaceWeek = checkRaceWeek();

            pages.push(
                <Page key={i} size="LETTER" style={styles.page}>
                    <View style={styles.logoContainer}>
                        <Image style={styles.logo} src="/images/icons/RunSmart.png" />
                    </View>
                    <View style={styles.profileContainer}>
                        <Text style={styles.title}>YOUR TRAINING PLAN</Text>
                        <Text style={styles.name}>{getName()}</Text>
                    </View>

                    <View style={styles.raceDetailsRow}>
                        <View style={styles.weekTotalDistance}>
                            <Text style={styles.week}>
                                WEEK {i}
                            </Text>
                            <Text style={styles.raceDetailsText}>
                                <Text style={styles.label}>Total Distance: </Text>
                                {getTotalDistance(filterDataWeek(i))}
                            </Text>
                        </View>

                        <View style={styles.raceDetailsContainer}>
                            <Text style={styles.raceName}>{pdfData?.race_name}</Text>
                            <Text style={styles.marathonName}>{getMarathonName(type)}</Text>
                            <Text style={styles.raceDetailsText}>
                                <Text style={styles.label}>Race Day: </Text>
                                {moment.utc(pdfData?.race_date).format('MM/DD/YYYY')}
                            </Text>
                            <Text style={styles.raceDetailsText}>
                                <Text style={styles.label}>Finish Time: </Text>
                                {pdfData?.finish_time}
                            </Text>
                            <Text style={styles.raceDetailsText}>
                                <Text style={styles.label}>Goal Time: </Text>
                                {pdfData?.goal_time}
                            </Text>
                        </View >
                    </View>

                    <View style={{ marginTop: 10 }}>
                        <View style={styles.trainingHeader}>
                            <Text style={{ width: 88 }}></Text>
                            <Text style={styles.trainingTitle}>Training</Text>
                            <View style={styles.hrTitle}><Image style={styles.heart} src="/images/icons/heart-outlined.png" /></View>
                            <Text style={styles.avePace}>Average Pace</Text>
                            <Text style={styles.notes}>Notes</Text>
                        </View>

                        {days.map((day: any, idx: number) => (
                            <View style={styles.trainingCol} key={day.id}>
                                <View style={styles.trainingRow}>
                                    <View style={styles.dateCol}>
                                        <Text style={styles.dayName}>{day.day}</Text>
                                        <Text style={styles.dateValue}>
                                            {new Date(findData(i, idx + 1)?.trainingDate)?.toLocaleDateString()}
                                        </Text>
                                    </View>

                                    <Text style={checkPosition(idx + 1) === "even" ? styles.trainingEven : styles.trainingOdd}>
                                        {
                                            extractActivity(i, idx + 1) === "CROSS TRAIN" || extractActivity(i, idx + 1) === "SEE DETAILS" || extractActivity(i, idx + 1) === "REST" ? (
                                                <>
                                                    <Text style={styles.crossRest}>
                                                        {extractActivity(i, idx + 1)}
                                                    </Text>
                                                    {"\n"}
                                                    <Text style={styles.smallText}>
                                                        {getWorkout(i, idx + 1)}
                                                    </Text>
                                                </>
                                            )
                                                : (
                                                    <>
                                                        <Text style={styles.distance}>
                                                            {extractActivity(i, idx + 1).split(" ")[0]}
                                                        </Text>
                                                        {"\n"}
                                                        <Text style={styles.units}>
                                                            {extractActivity(i, idx + 1).split(" ")[1]}
                                                        </Text>
                                                    </>
                                                )
                                        }
                                    </Text>

                                    <View style={styles.heartRateContainer}>
                                        <Text>
                                            {getUserNotes(i, idx + 1)?.heart_rate || "-"}
                                        </Text>
                                    </View>

                                    <View style={checkPosition(idx + 1) === "even" ? styles.avePaceNotesContainerEven : styles.avePaceNotesContainerOdd}>
                                        {findData(i, idx + 1)?.marathonPaces?.map((val: any, id: number) => (
                                            <View key={id}>
                                                <Text style={isMultiPaces(i, idx + 1) ? styles.multiPace : styles.singlePace}>
                                                    {distanceInterpret(val?.notes, userInfo?.km == 'Y' ? true : false)}

                                                    {getConvertedPace(val, i, id + 1).value[0] ? ' at ' + getConvertedPace(val, i, id + 1).value[0] : ''}

                                                    {getConvertedPace(val, i, id + 1).value[0] ? "/" + getConvertedPace(val, i, id + 1).value[1] : ""
                                                    }
                                                </Text>
                                            </View>
                                        ))}
                                    </View>

                                    <View style={checkPosition(idx + 1) === "even" ? styles.avePaceNotesContainerEven : styles.avePaceNotesContainerOdd}>
                                        <Text style={styles.notesValues} >
                                            {extractActivity(i, idx + 1) === "REST" ? getUserRestComments(i, idx)?.comments : getUserNotes(i, idx + 1)?.comments || ""}
                                        </Text>
                                    </View>
                                </View>
                            </View>
                        ))}
                    </View>
                </Page >
            )
        }
        const pagesValues = pages.map((page) => page)
        setPages(pagesValues)
    }

    useEffect(() => {
        if (userInfo) buildPage()
    }, [userInfo])

    return (
        // <PDFViewer width={'100%'} height={'100%'}>
        <Document>
            {pages}
        </Document>
        // </PDFViewer>
    )

}

export default TrainingPlanPDF