import allVideos from './videos/allVideos'
import { titleCase } from './miscUtils'
import { trainingLinks } from './linkInfo'
import { getVideoTitleAndSubtitle } from './videoUtils'

// Page Type
export type Page = {
	title: string
	subtitle?: string
	url: string
}

// Get All Pages
const getAllPages = () => {
	const pages: Page[] = [
		{
			title: 'Home',
			url: '/#/',
		},
		// {
		// 	title: 'Account',
		// 	url: '/#/my-account',
		// },
		{
			title: 'Invite a Friend',
			url: '/#/invites',
		},
		{
			title: 'Favorites',
			url: '/#/favorites',
		},
		{
			title: 'Exercises',
			url: '/#/exercises',
		},
		{
			title: 'RunSmart Reset',
			url: '/#/programs?program_id=1',
		},
		{
			title: 'Midfoot Project',
			url: '/#/programs?program_id=16',
		},
	]

	// Iterate all Videos
	for (const id in allVideos) {
		const video = allVideos[id as keyof typeof allVideos]
		const { title, subtitle } = getVideoTitleAndSubtitle(video)
		if (video.url)
			pages.push({
				title,
				subtitle: subtitle || undefined,
				url: video.url,
			})
	}

	// Iterate Training Links
	for (const key in trainingLinks) {
		const url = trainingLinks[key as keyof typeof trainingLinks]
		const subtitle = titleCase(`${key} Plan`)
		pages.push({
			title: 'Training',
			subtitle,
			url,
		})
	}

	return pages
}

// All Pages
export const allPages = getAllPages()
