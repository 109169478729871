import React from 'react'
import { Container } from 'react-bootstrap'
import AppMenus from '../../components/layout/AppMenus'
import InstabotHide from '../../components/styles/InstabotHide'
import RSButton from '../../components/buttons/RSButton'
import { navReq } from '../../modules/apiConsume'

const EndSurvey = () => {
    return (
        <div>
            {/* Styles */}
            <InstabotHide />
            {/* Page */}
            <AppMenus hideOptions hideBackButton>
                <Container fluid="md" className={'survey-form-container'}>
                    <div className="content center-content">
                        <h1 className='headline'>Thank You!</h1>
                        <p className='subhead'>As your needs & training changes, you <br />can update this information in the {`"my account"`} section.</p>
                        <RSButton sx={{mx: 2, width: '90%'}} onClick={() => navReq('/')}>BACK TO RUNSMART </RSButton>
                    </div>
                </Container>
            </AppMenus>
        </div>
    )
}

export default EndSurvey
