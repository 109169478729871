import { useState, useEffect, useRef } from 'react'

/**
 * useComponentVisible Hook
 */

const useComponentVisible = (initialIsVisible = false) => {
	// State
	const [isComponentVisible, setIsComponentVisible] = useState(initialIsVisible)

	// Ref
	const ref = useRef(null)

	// Handle Click Outside
	const handleClickOutside = (event: any) => {
		if (ref.current && !(ref.current as any)?.contains(event.target)) {
			setIsComponentVisible(false)
		}
	}

	// Mount Effect
	useEffect(() => {
		document.addEventListener('click', handleClickOutside, true)
		return () => {
			document.removeEventListener('click', handleClickOutside, true)
		}
	}, [])

	// Return
	return { ref, isComponentVisible, setIsComponentVisible }
}

export default useComponentVisible
