import InstabotHide from '../../components/styles/InstabotHide'
import AppMenus from '../../components/layout/AppMenus'
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import GenericAlert from '../../components/misc/GenericAlert'
import useAlert from '../../hooks/useAlert'
import AccountSubmitButtons from '../../components/buttons/AuthSubmitButtons'
import ResetPassForm, {
	ResetPassFormInfo,
	defaultFormInfo,
} from '../../components/forms/ResetPassForm'
import { navReq } from '../../modules/apiConsume'
import { updatePasswordFromEmail } from '../../modules/accountActions'
import { FC, useState } from 'react'
import useQueryParams from '../../hooks/useQueryParams'

/**
 * ResetPassword Component
 */

const ResetPassword: FC = () => {
	// Properties
	const title = 'Reset your Password'
	const [info, setInfo] = useState<ResetPassFormInfo>(defaultFormInfo)
	const [isValid, setIsValid] = useState(false)
	const { email, code } = useQueryParams()

	// Hooks
	const {
		showAlert,
		onAlertOpen,
		onAlertClose,
		alertTitle,
		alertMessage,
		setAlertMessage,
		alertVariant,
		setAlertVariant,
	} = useAlert()

	// On Submit
	const onSubmit = async () => {
		const response = await updatePasswordFromEmail({
			email,
			code,
			newPassword: info.newPassword.value,
		})
		if (response?.status == 'ok') {
			setAlertVariant('success')
			setAlertMessage('Password was reset successfully')
			onAlertOpen()
			setTimeout(() => navReq('/#/login'), 2000)
		} else {
			setAlertVariant('danger')
			setAlertMessage('Could not reset password')
			onAlertOpen()
		}
	}

	// On Cancel
	const onCancel = () => navReq('/#/login')

	// Render
	return (
		<>
			{/* Styles */}
			<InstabotHide />

			{/* Alert */}
			<GenericAlert
				show={showAlert}
				title={alertTitle}
				message={alertMessage}
				variant={alertVariant}
				onClose={onAlertClose}
			/>

			{/* Page */}
			<AppMenus title={title} hideOptions hideBackButton>
				<Container fluid="md" className="px-form-5">
					<div className="account-form m-auto">
						{/* Instructions */}
						<Row className="mb-5">
							<Col className="text-center">
								<span>
									Please enter and confirm a new password below.
								</span>
							</Col>
						</Row>

						{/* Form */}
						<ResetPassForm setInfo={setInfo} setIsValid={setIsValid} />

						{/* Submit Buttons */}
						<AccountSubmitButtons
							primaryText="Submit"
							secondaryText="Cancel"
							primaryAction={onSubmit}
							secondaryAction={onCancel}
							primaryDisabled={!isValid}
						/>
					</div>
				</Container>
			</AppMenus>
		</>
	)
}

export default ResetPassword
