/* eslint-disable @typescript-eslint/no-unused-vars */
import { strtotime } from 'locutus/php/datetime'
import { useEffect, useState } from 'react'
import { Modal, Toast, ToastContainer } from 'react-bootstrap'
import moment from 'moment'
import 'moment-timezone';
import is_numeric from 'locutus/php/var/is_numeric'
import useStore from '../../store/useStore'
import { ITrainingPlanStore } from '../../store/createTrainingPlanStore'
import useCalendarBase from '../../pages/training-plan/hooks/useCalendarBase'
import useTrainingPlanUtils from '../../hooks/useTrainingPlanUtils';
import { DAYS_OF_WEEK, DAYS_OF_WEEK_ALT } from '../../pages/training-plan/build/DaysOptions';
interface IResData {
    week: number
    day: number,
    dateString: string,
    dateVal: string,
    monthDigits: Array<number>
}
const TrainingCalendarModal = (props: any) => {
    const { userOverrides } = useStore((state: ITrainingPlanStore) => state)
    const { getWeekDays } = useTrainingPlanUtils()
    const { showViewCalandar, handleCloseViewCalandar, userInfo, getDailyActivityDisplay, selectedRace, currentWeek, handleClose, allUserNotes } = props

    const [dateObject, setDateObject] = useState<any>()
    const { buildCalendarMonth: buildCalendarMonthBase, loadingValues } = useCalendarBase(userOverrides);
    const [daysInMonth, setDaysInMonth] = useState<Array<any>>([])
    const [monthDigits] = useState<Array<number>>([])
    const [weekdayShort, setWeekdayShort] = useState<Array<any>>([])
    const [calendarData, setCalendarData] = useState<Array<any>>([])
    const [filteredCalendarData, setFilteredCalendarData] = useState<Array<any>>([])
    const [monthRange, setMonthRange] = useState<{ maxMonth: number, minMonth: number } | undefined>(undefined)

    const handleSelectDate = (week: number, day: number) => {
        if (week && day) {
            const nextURL = `/#/training-plan/view?week=${week}&day=${day}`
            window.location.assign(nextURL)
            handleClose()
            handleCloseViewCalandar()
        }
    }

    const buildCalendarUI = (data: Array<any>) => {
        const { monday_start, km, start, race_date, weeks, type } = userInfo
        const isKm = km === "Y" ? "KMS" : "MILES";
        const momentOptions = { week: { dow: 1 } }
        const newLocal: any = monday_start ? true : false;

        const raceDay = moment(race_date).format('YYYY-MM-D')

        let dailyValue = "";
        const weekdayshort: any = moment.updateLocale('en', momentOptions).weekdaysMin(newLocal);
        setWeekdayShort(weekdayshort)

        const blanks = [];
        for (let i = monday_start ? 1 : 0; i < (+firstDayOfMonth() != 0 ? +firstDayOfMonth() : 14); i++) {
            blanks.push(
                <td key={i} className="react-datepicker__day blank">{""}</td>
            );
        }

        let dailyActivity;
        const daysInMonth = [];
        for (let d = 1; d <= moment(dateObject).daysInMonth(); d++) {
            const params = (moment(dateObject).format('YYYY-MM') + "-" + d).toString()
            const result = data?.find((x: any) => x.monthData.dateVal === params)
            dailyActivity = getDailyActivityDisplay(
                result?.activitiesValue,
                userInfo,
                result?.activitiesValue?.marathonPaces,
                selectedRace?.value as number,
                result?.activitiesValue?.marathon?.race_day,
                allUserNotes
            )?.toUpperCase().split("|")

            if (dailyActivity)
                dailyValue = dailyActivity[0] === "REST" && strtotime(params) <= strtotime(start)
                    || dailyActivity[0] === "REST" && strtotime(params) >= strtotime(race_date) ? "" : dailyActivity[0] !== "REST" ? dailyActivity[0].replace(isKm, "") : raceDay === params ? (type != 4 ? 'Race Day' : 'Training Ends') : 'REST'

            else {
                dailyActivity = []
                dailyValue = ""
            }

            daysInMonth.push(
                <td onClick={() => handleSelectDate(result?.monthData?.week, result?.monthData?.day)} key={d + blanks.length} className={`react-datepicker__day`}>
                    <span className="date-wrap">{d}</span>
                    <div className="date-text-wrap">
                        <span className={`base text-uppercase text-wrap ${is_numeric(+dailyValue) ? 'numeric' : 'non-numeric'}`}>{dailyValue}</span><br />
                        <span className="highlated-date text-primary">{dailyActivity[1]}</span>
                    </div>
                </td>
            );
        }

        const totalSlots = [...blanks, ...daysInMonth];
        const rows: Array<any> = [];
        let cells: Array<any> = [];



        totalSlots.forEach((row, i) => {
            if (i % 7 !== 0) {
                cells.push(row); // if index not equal 7 that means not go to next week
            } else {
                rows.push(cells); // when reach next week we contain all td in last week to rows
                cells = []; // empty container
                cells.push(row); // in current loop we still push current row to new container
            }
            if (i === totalSlots.length - 1) { // when end loop we add remain date
                rows.push(cells);
            }
        });

        const daysInMonthValues = rows.map((d, i) => {
            return <tr className='react-datepicker__week' key={i}>{d}</tr>;
        });

        setDaysInMonth(daysInMonthValues)
    }


    const loadBlankDates = () => {
        const momentOptions = { week: { dow: 1 } }
        const newLocal: any = true;

        const raceDay = moment(new Date()).format('YYYY-MM-D')

        const monday_start = 1;

        let dailyValue = "";
        const weekdayshort: any = moment.updateLocale('en', momentOptions).weekdaysMin(newLocal);
        setWeekdayShort(weekdayshort)

        const blanks = [];
        for (let i = monday_start ? 1 : 0; i < (+firstDayOfMonth() != 0 ? +firstDayOfMonth() : 14); i++) {
            blanks.push(
                <td key={i} className="react-datepicker__day blank">{""}</td>
            );
        }

        let dailyActivity: any;
        const daysInMonth = [];
        for (let d = 1; d <= moment(dateObject).daysInMonth(); d++) {
            dailyActivity = []
            dailyValue = ""

            daysInMonth.push(
                <td key={d + blanks.length} className={`react-datepicker__day`}>
                    <span className="date-wrap">{d}</span>
                    <div className="date-text-wrap">
                        <span className={`base text-uppercase text-wrap ${is_numeric(+dailyValue) ? 'numeric' : 'non-numeric'}`}>{dailyValue}</span><br />
                        <span className="highlated-date text-primary">{dailyActivity[1]}</span>
                    </div>
                </td>
            );
        }

        const totalSlots = [...blanks, ...daysInMonth];
        const rows: Array<any> = [];
        let cells: Array<any> = [];

        totalSlots.forEach((row, i) => {
            if (i % 7 !== 0) {
                cells.push(row); // if index not equal 7 that means not go to next week
            } else {
                rows.push(cells); // when reach next week we contain all td in last week to rows
                cells = []; // empty container
                cells.push(row); // in current loop we still push current row to new container
            }
            if (i === totalSlots.length - 1) { // when end loop we add remain date
                rows.push(cells);
            }
        });

        const daysInMonthValues = rows.map((d, i) => {
            return <tr className='react-datepicker__week' key={i}>{d}</tr>;
        });

        setDaysInMonth(daysInMonthValues)
    }

    const getMonthRange = () => {
        const weekDays = getWeekDays(new Date(), userInfo.start, userInfo.race_date, userInfo.monday_start ? DAYS_OF_WEEK_ALT : DAYS_OF_WEEK);
        setMonthRange({
            maxMonth: +weekDays[weekDays.length - 1].split('-')[1],
            minMonth: +weekDays[0].split('-')[1]
        })
    }


    const onPrev = () => {
        const curr = "month";
        const curMonth = moment(dateObject).month() == 12 ? 12 : moment(dateObject).month();
        if (curMonth + 1 != monthRange?.minMonth)
            setDateObject(moment(dateObject).subtract(1, curr))
    };

    const onNext = () => {
        const curr = "month";
        const curMonth = moment(dateObject).month() == 12 ? 12 : moment(dateObject).month();
        if (curMonth + 1 != monthRange?.maxMonth)
            setDateObject(moment(dateObject).add(1, curr))

    };


    const firstDayOfMonth = () => {
        const firstDay = moment(dateObject)
            .startOf("month")
            .format("d");
        return firstDay;
    };

    useEffect(() => {
        const abortController = new AbortController();

        if (userInfo && showViewCalandar) {
            moment.tz("America/New_York")
            setDateObject(moment(currentWeek.key <= 0 ? userInfo?.start : new Date()))
            buildCalendarMonthBase(moment(dateObject).month(), userInfo, '').then((res) => setCalendarData(res))
        }

        return () => {
            abortController.abort();
        }

    }, [showViewCalandar])


    useEffect(() => {
        if (calendarData && calendarData.length > 0) {
            setFilteredCalendarData(calendarData.filter((x: any) => x?.monthData?.dateVal.split('-')[1] == moment(dateObject).month() + 1))
        }
    }, [calendarData, moment(dateObject).month(), dateObject])

    useEffect(() => {
        if (filteredCalendarData && filteredCalendarData.length > 0)
            buildCalendarUI(filteredCalendarData)
    }, [filteredCalendarData])

    useEffect(() => {
        if (loadingValues)
            loadBlankDates()
    }, [loadingValues])

    useEffect(() => {
        if (userInfo)
            getMonthRange()
    }, [userInfo])

    return (
        <Modal
            size="lg"
            className="common-modal build-plan"
            show={showViewCalandar}
            onHide={handleCloseViewCalandar}
            backdrop='static'
            keyboard={false}
        >
            <Modal.Header closeButton={!loadingValues}>
                <h2 className="traning-popup--title mb-0 mb-md-3">Training Calendar</h2>
            </Modal.Header>
            <Modal.Body>
                <div className={`training-calendar d-flex justify-content-center`}>
                    <div className="react-datepicker">
                        <button
                            disabled={loadingValues}
                            onClick={onPrev}
                            type="button"
                            className={`react-datepicker__navigation react-datepicker__navigation--previous ${loadingValues ? 'd-none' : ''}`}
                            aria-label="Previous Month"
                        >
                            <span className="react-datepicker__navigation-icon react-datepicker__navigation-icon--previous">
                                Previous Month
                            </span>
                        </button>
                        <button
                            disabled={loadingValues}
                            onClick={onNext}
                            type="button"
                            className={`react-datepicker__navigation react-datepicker__navigation--next ${loadingValues ? 'd-none' : ''}`}
                            aria-label="Next Month"
                        >
                            <span className="react-datepicker__navigation-icon react-datepicker__navigation-icon--next">
                                Next Month
                            </span>
                        </button>
                        <div className="react-datepicker__month-container">
                            <div className="react-datepicker__header">
                                <div className="react-datepicker__current-month">
                                    {moment(dateObject).format('MMMM')} {moment(dateObject).format('YYYY')}
                                </div>
                                <div className="react-datepicker__header__dropdown react-datepicker__header__dropdown--scroll"></div>
                                <div className="react-datepicker__day-names">
                                    {weekdayShort?.map((day, idx) => (
                                        <div key={idx} className="react-datepicker__day-name">{day.charAt(0)}</div>
                                    ))}
                                </div>
                            </div>
                            <div className={`react-datepicker__month ${loadingValues ? 'disable-mouse-action' : ''}`}>
                                {daysInMonth}
                            </div>

                        </div>
                    </div>
                </div>
                {/* <div className="center-align">
                    <Button
                        disabled={isLoading}
                        variant="dark"
                        onClick={handleCloseViewCalandar}
                        className="rounded-pill color-black-bg font-12 mt-3 mb-2 px-4 bg-mobile-green center-align text-capitalize letter-space"
                    >
                        Back
                    </Button>
                </div> */}
                <ToastContainer position='middle-center'>
                    <Toast show={loadingValues}>
                        <Toast.Header closeButton={false} style={{ background: '#F6F5F5' }}>
                            <strong className="me-auto">Calendar</strong>
                        </Toast.Header>
                        <Toast.Body className='toast--success'>Loading training plan calendar, please wait...</Toast.Body>
                    </Toast>
                </ToastContainer>
            </Modal.Body>
        </Modal>
    )
}

export default TrainingCalendarModal
