import { FC, ReactElement } from 'react'
import { AnimatePresence, motion} from "framer-motion";

export type MotionProps = {
	className?: string,
	clear?: boolean,
	children?: ReactElement,
	isVisible?: boolean,
	isMobile?: boolean,
	direction?: {slide?:string}
}

const Motion: FC<MotionProps> = ({className, children, isMobile, isVisible, direction, clear}) => {
	const checkDirection = () => {
		let axis = "x";
		let distance = "100%";

		switch(direction?.slide) {
			case "left":
				break;
			case "right":
				distance = `-${distance}`
				break;
			case "down":
				axis = "y"
				distance = `-${distance}`
				break;
			case "up":
				axis = "y"
				break;
			default:
				distance= "0px"
				break;
		}

		return {axis, distance}
	}

	const {axis, distance} =  checkDirection()

	return <>
		{isMobile ? <AnimatePresence>
				{isVisible &&
					<motion.div
						className={`motion-container ${className ? className : ''}`}
						initial={{ [axis]:distance, zIndex: 20, position: 'absolute'}}
						animate={{ [axis]: "0", transitionEnd:{position: "initial", [axis]: "none" }}}
						onAnimationComplete={()=>{
							clear && localStorage.setItem('page', '')
						}}
						transition={{duration: 0.3}}
					>
						{children}
					</motion.div>
				}
			</AnimatePresence>
			: <>{isVisible && children}</>
		}
	</>
}

export default Motion;
