/* eslint-disable @typescript-eslint/no-unused-vars */
import moment from "moment";
import { MarathonValRecord, UserNotesRecord, UsersRestCommentsRecord } from "../../../api/v2/types"
import useTrainingPlanUtils from "../../../hooks/useTrainingPlanUtils";
import { DAYS_OF_WEEK, DAYS_OF_WEEK_ALT } from "../build/DaysOptions"
import useBuildTrainingPlan from "./useBuildTrainingPlan";
import useCalendar from "./useCalendar";

interface IResData {
    week: number
    day: number,
    dateString: string,
    dateVal: string,
    monthDigits: Array<number>
}

const useTrainingPlanData = () => {

    const { buildCalendarAllMonths } = useCalendar();
    const { getRaceDayDetails, getRaceDistance, getRaceDistancePace } = useBuildTrainingPlan()
    const { getWeekDays } = useTrainingPlanUtils()


    const getWholePlan = async (userInfo: any, marathons: Array<any>, userNotes: Array<UserNotesRecord>, comments: Array<UsersRestCommentsRecord>) => {
        let monthData: Array<IResData> = []
        const mergedMonthData: any = []

        const details = getRaceDayDetails(userInfo)

        const saturdayRaceMondayStart = details.raceDay == 5 && details.saturdayRace;
        const saturdayRaceSundayStart = details.raceDay == 6 && !details.saturdayRace;

        monthData = buildCalendarAllMonths(userInfo)

        const firstMonthData = monthData[0]
        const sliceOne = saturdayRaceSundayStart;
        const sliceTwo = saturdayRaceMondayStart;
        monthData = saturdayRaceSundayStart ? monthData.slice(1) : saturdayRaceMondayStart ? monthData.slice(2) : monthData
        // console.log(hasDup)
        // console.log(monthData)

        const { start, monday_start, race_date, weeks, type, distance } = userInfo

        const days = monday_start ? DAYS_OF_WEEK_ALT : DAYS_OF_WEEK;
        const values: Array<any> = []
        const weekDays = getWeekDays(new Date(), userInfo.start, userInfo.race_date, userInfo.monday_start ? DAYS_OF_WEEK_ALT : DAYS_OF_WEEK);
        // setMonthDigits(weeksMonth[0]?.monthDigits)
        // setIsLoading(true)
        for (let i = 1; i <= weekDays.length; i++) {
            // const isRaceWeek = weeks == w.week && type != 4

            const checkRaceWeek = () => {
                //sunday start - monday race
                if (details.raceDay == 1 && (+weeks + 1) == i && type != 4)
                    return true
                //sunday start - sunday race
                else if (details.raceDay == 0 && (+weeks + 1) == i && type != 4)
                    return true
                //sunday start - saturday race
                else if (saturdayRaceSundayStart && weeks == i && type != 4)
                    return true
                else if (saturdayRaceMondayStart && weeks == i && type != 4) {
                    return true;
                }
                else
                    return false;
            }

            const isRaceWeek = checkRaceWeek();

            if (!isRaceWeek)
                for (const [index, d] of Object.entries(days)) {
                    const indexVal = +(index as unknown as number) + 1
                    // const marathon = res.data.result.marathon.find((x: any) => x.day == indexVal)
                    const marathon = marathons?.filter((x: any) => x.week == i && x.day == indexVal);
                    if (marathon.length != 0) {
                        const data = {
                            marathon: {
                                id: marathon[0]?.id,
                                marathon: marathon[0]?.marathon,
                                type: marathon[0]?.type,
                                training_goal: marathon[0]?.training_goal,
                                week: marathon[0]?.week,
                                day: marathon[0]?.day,
                                distance: marathon[0]?.distance,
                                race_day: marathon[0]?.race_day,
                            },
                            marathonPaces: buildMarathonPaces(marathon),
                            workouts: buildWorkouts(marathon),
                            userNotes: userNotes?.find((x) => x.uid == userInfo?.uid && x.marid == marathon[0]?.id),
                            // userRestComments: comments?.find(x => x.week == i && x.day == indexVal),
                            trainingDate: monthData.find((x) => x.week == i && x.day == indexVal)?.dateVal
                            // overrides: buildOverrides(marathon)
                        }
                        values.push({ ...data })
                    }
                    else {
                        const data = {
                            marathon: { week: i, day: indexVal },
                            marathonPaces: undefined,
                            workouts: undefined,
                            userNotes: undefined,
                            // userRestComments: comments?.find(x => x.uid == userInfo?.uid && x.week == i && x.day == indexVal - 1),
                            trainingDate: monthData.find((x) => x.week == i && x.day == indexVal)?.dateVal
                            // overrides: buildOverrides(marathon)
                        }
                        values.push({ ...data })
                    }

                }
            else if (isRaceWeek)
                for (const [index, d] of Object.entries(days)) {
                    const indexVal = +(index as unknown as number) + 2 - (saturdayRaceMondayStart ? 1 : 0)
                    // const marathon = res.data.result.marathon.find((x: any) => x.day == indexVal)
                    const marathon = marathons?.filter((x: any) => x.week == i && x.day == indexVal);
                    if (marathon && +index + 1 != 7) {
                        const data = {
                            marathon: {
                                id: marathon[0]?.id,
                                marathon: marathon[0]?.marathon,
                                type: marathon[0]?.type,
                                training_goal: marathon[0]?.training_goal,
                                week: marathon[0]?.week,
                                day: marathon[0]?.day - (saturdayRaceMondayStart ? 0 : 1),
                                distance: marathon[0]?.distance,
                                race_day: marathon[0]?.race_day,
                            },
                            marathonPaces: buildMarathonPaces(marathon),
                            workouts: buildWorkouts(marathon),
                            userNotes: userNotes?.find((x) => x.uid == userInfo?.uid && x.marid == marathon[0]?.id),
                            // userRestComments: comments?.find(x => x.uid == userInfo?.uid && x.week == i && x.day == indexVal - (saturdayRaceMondayStart ? 0 : 1)),
                            trainingDate: monthData.find((x) => x.week == marathon[0]?.week && x.day == (marathon[0]?.day - (saturdayRaceMondayStart ? 0 : 1)))?.dateVal
                            // overrides: buildOverrides(marathon)
                        }
                        if (saturdayRaceMondayStart && data.marathon.day == 6) {
                            //
                        }
                        else
                            // if (saturdayRaceSundayStart && data.marathon.day != 6) {
                            values.push({ ...data })
                        // }

                    }
                    else {
                        values.push({
                            marathon: {
                                id: 5000 + userInfo?.uid,
                                marathon: userInfo?.type,
                                type: userInfo?.weeks,
                                training_goal: userInfo?.distance,
                                week: i,
                                day: details.raceDay == 1 ? 2 : details.raceDay == 0 ? 1 : saturdayRaceMondayStart ? 6 : 7,
                                distance: getRaceDistance(userInfo?.type as number),
                                race_day: 1,
                            },
                            marathonPaces: [{ mar_id: 5000 + userInfo?.uid, paceId: 9999 + userInfo?.uid, pace: 'Race Day', notes: `${getRaceDistancePace(userInfo)} ${userInfo?.km === "Y" ? "kms" : "miles"}`, orderid: userInfo?.type }],
                            workouts: [],
                            userNotes: userNotes?.find((x) => x.uid == userInfo?.uid && x.marid == 5000 + userInfo?.uid),
                            userRestComments: comments?.find(x => x.week == i && x.day == (details.raceDay == 1 ? 2 : details.raceDay == 0 ? 1 : saturdayRaceMondayStart ? 6 : 7)),
                            trainingDate: monthData.find((x) => x.week == i && x.day == (details.raceDay == 1 ? 2 : details.raceDay == 0 ? 1 : saturdayRaceMondayStart ? 6 : 7))?.dateVal
                        })
                    }
                }
        }

        return values
    }

    const buildMarathonPaces = (values: Array<any>) => {

        const paces: Array<any> = []
        values.forEach(value => {
            paces.push({ mar_id: value.paceMarId, paceId: value.paceId, pace: value.pace, notes: value.notes, orderid: value.paceOrderId })
        })

        const uniqueValues = paces.filter((a, i) => paces.findIndex((s) => a.paceId === s.paceId) === i)
        return uniqueValues;
    }

    const buildWorkouts = (values: Array<any>) => {
        const workouts: Array<any> = []
        values.forEach(value => {
            workouts.push({ mid: value.workoutMarId, workoutId: value.woId, title: value.title, description: value.description, link: value.link, orderid: value.wOrderid })
        })

        const uniqueValues = workouts.filter((a, i) => workouts.findIndex((s) => a.workoutId === s.workoutId) === i)
        return uniqueValues;
    }

    return { getWholePlan }
}

export default useTrainingPlanData