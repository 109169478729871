/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/no-empty-function */
import { useState, useEffect, useRef } from 'react'
import { useNavigate } from 'react-router-dom'
import moment from 'moment';
import is_numeric from 'locutus/php/var/is_numeric'
import confetti from 'canvas-confetti'

import { motion } from "framer-motion";
import SwipeableDrawer from '@mui/material/SwipeableDrawer';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import ArrowForward from '@mui/icons-material/ArrowForwardIos';
import ArrowBack from '@mui/icons-material/ArrowBackIosNew'

import { titleCase } from '../../modules/miscUtils'
import useTrainingPlanUtils from '../../hooks/useTrainingPlanUtils';
import { UserRaceResults } from '../../api/v2/types'
import useStore from '../../store/useStore';
import { ITrainingPlanStore } from '../../store/createTrainingPlanStore';
import RSButton from '../../components/buttons/RSButton'
import RSDrawer from '../../components/layout/RSDrawer'
import RSHeaderCont from '../../components/layout/RSHeaderCont'

const CompletePlan = (props: any) => {
	const { userInfo, handleSubmitCompletePlan, selectedRace, updateRaceResult, setUpdateRaceResult, raceResults } = props
	const [raceName, setRaceName] = useState<any>('')
	const [rrTime, setRrTime] = useState<any>({ hr: '', min: '', sec: '' })
	const [isPR, setIsPR] = useState(false);
	const [isBQ, setIsBQ] = useState(false);
	const [newComment, setNewComment] = useState('')
	const [isDNF, setIsDNF] = useState(false);
	const { ConvertFullTo5k, ConvertFullToHalf, ConvertM2H2, ConvertFullto10k, ConvertS2TimeObject } = useTrainingPlanUtils()
	const { userVdotAdjustments } = useStore((state: ITrainingPlanStore) => state)

	const confettiFire = (particleRatio: any, opts: any) => {
		confetti({
			...opts,
			origin: { y: 0.8 },
			zIndex: 10000,
			particleCount: Math.floor(200 * particleRatio)
		});
	}

	const showConfetti = () => {

    confettiFire(0.25, {
      spread: 26,
      startVelocity: 55,
    });
    confettiFire(0.2, {
      spread: 60,
    });
    confettiFire(0.35, {
      spread: 100,
      decay: 0.91,
      scalar: 0.8
    });
    confettiFire(0.1, {
      spread: 120,
      startVelocity: 25,
      decay: 0.92,
      scalar: 1.2
    });
    confettiFire(0.1, {
      spread: 120,
      startVelocity: 45,
    });
	}

	useEffect(() => {
		!raceResults?.id && updateRaceResult && showConfetti()
		if (raceResults?.id && updateRaceResult) {
			setRaceName(raceResults?.race_name ? raceResults.race_name : '')
			setIsPR(raceResults?.is_best_pr ? true : false)
			setIsBQ(raceResults?.is_bq ? true : false)
			setNewComment(raceResults?.notes ? raceResults.notes : '')

			const fTime = raceResults?.finished_time_in_sec
			if (fTime) {
				const { hr, min, sec } = ConvertS2TimeObject(fTime)
				setRrTime({ hr: hr ? hr : '', min: min ? min : '', sec: sec ? sec : '' })
			}
		} else {
			raceName && setRaceName('')
			isPR && setIsPR(false)
			isBQ && setIsBQ(false)
			newComment && setNewComment('')
		}
	}, [raceResults, updateRaceResult])

	const isUpdated = () => {
		if (raceResults?.id) {
			const rPR = raceResults?.is_best_pr ? true : false
			const rBQ = raceResults?.is_bq ? true : false
			const hr = rrTime?.hr ? rrTime.hr * 3600 : 0
			const min = rrTime?.min ? rrTime.min * 60 : 0
			const sec = rrTime?.sec ? rrTime.sec : 0
			const totalTime = hr + min + sec
			return (raceResults?.race_name !== raceName || raceResults?.finished_time_in_sec !== totalTime || rPR !== isPR || rBQ !== isBQ || raceResults?.notes !== newComment) ? false : true
		} else {
			return (rrTime.sec || rrTime.min || rrTime.hr) && raceName ? false : true
		}
	}

	const getRG = () => {
		const targetTime = userVdotAdjustments?.length > 0 ? (userVdotAdjustments[userVdotAdjustments?.length - 1]?.targetm) : userInfo?.targetm;
		const type = userInfo?.type;

		switch (type) {
			case 0:
				return ConvertFullToHalf(targetTime)
			case 1:
				return ConvertM2H2(targetTime)
			case 2:
				return ConvertFullTo5k(targetTime)
			case 3:
				return ConvertFullto10k(targetTime)
			case 4:
				return ConvertM2H2(targetTime)
			default:
				return ''
		}
	}

	const handleSubmit = async () => {
		const { hr, min, sec } = rrTime
		const fTimeInSec = (hr ? hr * 3600 : 0) + (min ? min * 60 : 0) + (sec ? sec * 1 : 0)

		const timeStr = getRG()?.split(' ')
		const dHR = timeStr?.find((t: string) => t.includes('h'))
		const dMIN = timeStr?.find((t: string) => t.includes('m'))
		const dSEC = timeStr?.find((t: string) => t.includes('s'))
		const finalDefaultTime = (dHR ? Number(dHR.replace('h', '')) * 3600 : 0) + (dMIN ? Number(dMIN.replace('m', '')) * 60 : 0) + (dSEC ? Number(dSEC.replace('s', '')) : 0)

		const body: UserRaceResults = {
			user_id: userInfo?.internal_id,
			training_plan_id: userInfo?.uid,
			race_type: (selectedRace?.value == 0 || selectedRace?.value == 1) ? `${titleCase(selectedRace?.title)}${titleCase(selectedRace?.subTitle)}` : selectedRace?.title,
			race_name: raceName,
			race_date: moment(userInfo?.race_date).day() == 1 ? moment(userInfo?.race_date).subtract(1, 'day').format('YYYY-MM-DD') : moment(userInfo?.race_date).format('YYYY-MM-DD'),
			finished_time_in_sec: isDNF ? finalDefaultTime : fTimeInSec,
			is_best_pr: isPR && !isDNF ? 1 : 0,
			is_bq: isBQ && !isDNF ? 1 : 0,
			is_dnf: isDNF ? 1 : 0,
			notes: newComment,
			is_km: userInfo?.km == "Y" ? 1 : 0,
			is_monday_start: userInfo?.monday_start || 0,
			distance: userInfo?.distance || 20,
		}

		await handleSubmitCompletePlan?.(body)
		handleExitRaceResult()
	}

	const handleReset = () => {
		setRaceName('')
		setRrTime({ hr: '', min: '', sec: '' })
		setIsPR(false);
		setIsBQ(false);
		setNewComment('')
		setIsDNF(false)
	}

	const handleExitRaceResult = () => {
		handleReset()
		setUpdateRaceResult?.(false)
	}

	const handleRrTimeChange = (e: any, t: string) => {
		if (!((t === 'min' || t === 'sec') && Number(e?.target?.value) > 59)) {
			const newRrTime = { ...rrTime, [`${t}`]: e?.target?.value }
			setRrTime(newRrTime)
		}
	}
	const handleDNFClick = (dnf: boolean) => {
		handleReset()
		setIsDNF(dnf)
	}


	const achievementList = [{
		label: 'Did you PR?',
		value: isPR,
		set: setIsPR
	}, {
		label: userInfo?.type === 1 ? 'Is this BQ?' : '',
		value: isBQ,
		set: setIsBQ
	}]

	const renderDNF = () => {
		return <RSDrawer
			open={isDNF}
			onClose={() => handleDNFClick(false)}
		>
			<Box className='bg-main' sx={{minHeight: window.innerHeight }}>
				<RSHeaderCont drawer>
					<Grid container sx={{ position: 'relative' }}>
						<Grid container xs={12} justifyContent="space-between" alignItems="center">
							<Box sx={{ display: 'flex', justifyContent: 'flex-start', alignItems: 'center' }}>
								<ArrowBack onClick={() => handleDNFClick(false)} />
								<Box sx={{ ml: '10px', fontFamily: 'Poppins', fontSize: '18px', letterSpacing: 'normal' }}>
									Training Plan
								</Box>
							</Box>
						</Grid>
					</Grid>
				</RSHeaderCont>
				<Grid sx={{ p: 2, pr: '10px', pt: 3}} container>
					<Grid container xs={12} sx={{ fontSize: '18px', fontFamily: 'Poppins-medium', px: 2 }} justifyContent='center' alignItems='center'>
						{`I didn't finish the race.`}
					</Grid>
					<Grid container xs={12} sx={{ px: 2 }} justifyContent='center' alignItems='center'>
						<Grid container xs={10} sx={{ fontSize: '16px', fontFamily: 'Poppins-Light', textAlign: 'center', lineHeight: '1em' }}>
							{`Are you sure you don't have results to enter?`}
						</Grid>
					</Grid>

					<Grid container xs={12} sx={{ pt: '30px', pb: '10px' }} justifyContent="center" alignItems="center">
						<Grid container xs={10}>
							<input value={raceName} onChange={(e) => setRaceName(e.target.value)} placeholder="Enter Race Name" className='enter-time' />
						</Grid>
					</Grid>

					<Grid container xs={12} sx={{ px: 2, lineHeight: '1em' }} justifyContent='center' alignItems='center'>
						<Grid container xs={11}>
							<Grid container xs={12} sx={{ mt: '5px', pb: '5px' }} justifyContent="flex-start" alignItems="center">
								<Box sx={{ fontFamily: 'Poppins', fontSize: '16px' }}>Notes</Box>
								<Box sx={{ ml: '2px', mt: '2px', fontFamily: 'Poppins-ExtraLight', fontSize: '12px' }}>(Optional)</Box>
							</Grid>
							<Grid container xs={12} justifyContent='center' alignItems="center">
								<textarea className='text-area-complete h-100' placeholder='Tell us how it went!' value={newComment} onChange={(e) => setNewComment(e.target.value)} />
							</Grid>
						</Grid>
					</Grid>



					<Grid container xs={12} sx={{ mt: '40px', px: 2 }} justifyContent='center' alignItems='center'>
						<Grid container xs={8}>
							<Button onClick={handleSubmit} disabled={raceName?.trim() ? false : true} variant='contained' sx={{ boxShadow: 'none', width: '100%', fontFamily: 'Poppins', color: '#010101', border: `1px solid ${raceName?.trim() ? '#9FCD2B !important' : 'transparent'}`, p: '8px 0px', borderRadius: '5px', fontSize: '14px', backgroundColor: raceName?.trim() ? '#9FCD2B  !important' : 'default', textTransform: 'none' }}>
								Confirm
							</Button>
						</Grid>
					</Grid>
				</Grid>
			</Box>
		</RSDrawer>
	}

	return <>
		<RSDrawer
			bottom
			fitContent
			popUpWidth='fit-content'
			open={updateRaceResult && !isDNF}
			onClose={handleExitRaceResult}
		>
			<Grid container sx={{ p: 2 }}>
				<Grid container xs={12} sx={{ fontSize: '18px', fontFamily: 'Poppins' }} >
					Complete Plan
				</Grid>
				<Grid container xs={12} sx={{ pt: '20px', pb: '5px', fontFamily: 'Poppins-Medium', fontSize: '20px' }} justifyContent="center" alignItems="center">
					Congratulations!
				</Grid>
				<Grid container xs={12} sx={{ pt: '10px', pb: '5px', fontFamily: 'Poppins', fontSize: '16px' }} justifyContent="flex-start" alignItems="center">
					{`Let's finalize your results.`}
				</Grid>
				<Grid container xs={12} justifyContent="center" alignItems="center">
					<input value={raceName} onChange={(e) => setRaceName(e.target.value)} placeholder="Enter Race Name" className='enter-time' />
				</Grid>
				<Grid container xs={12} sx={{ pt: '10px' }} justifyContent="center" alignItems="center">
					<Grid container xs={4} justifyContent="flex-start" alignItems="center">
						<input value={rrTime.hr} onChange={(e) => handleRrTimeChange(e, 'hr')} placeholder="HH" type='number' className='enter-time' onKeyDown={(e) => (e.keyCode === 69 || e.keyCode === 187 || e.keyCode === 189) && e.preventDefault()} />
					</Grid>
					<Grid container xs={4} justifyContent="center" alignItems="center">
						<input value={rrTime.min} onChange={(e) => handleRrTimeChange(e, 'min')} placeholder="MM" type='number' className='enter-time' onKeyDown={(e) => (e.keyCode === 69 || e.keyCode === 187 || e.keyCode === 189) && e.preventDefault()} />
					</Grid>
					<Grid container xs={4} justifyContent="flex-end" alignItems="center">
						<input value={rrTime.sec} onChange={(e) => handleRrTimeChange(e, 'sec')} placeholder="SS" type='number' className='enter-time' onKeyDown={(e) => (e.keyCode === 69 || e.keyCode === 187 || e.keyCode === 189) && e.preventDefault()} />
					</Grid>
				</Grid>
				<Grid container xs={12} sx={{ mt: '15px', pb: '5px', fontFamily: 'Poppins', fontSize: '16px' }} justifyContent="flex-start" alignItems="center">
					Achievements
				</Grid>
				{achievementList.map((v: any, iv: number) => {
					return v.label && <Grid container key={iv + 'acv'} xs={12} sx={{ mb: '10px', backgroundColor: 'white', border: '1px solid rgba(0, 0, 0, 0.176)', p: '10px', borderRadius: '5px' }} justifyContent="center" alignItems="center">
						<Grid container xs={8} sx={{ pl: '10px' }} justifyContent="flex-start" alignItems="center">
							<Box sx={{ fontFamily: 'Poppins', fontSize: '16px' }} >
								{v.label}
							</Box>
						</Grid>

						<Grid container xs={4} justifyContent="flex-end" alignItems="center">
							<Box className={`switch ${v.value ? 'fe' : ''}`} sx={{ backgroundColor: v.value ? '#9FCD2B !important' : '#80808070' }} onClick={() => v.set(!v.value)}>
								<motion.div className="handle" layout
									transition={{
										type: "spring",
										stiffness: 700,
										damping: 30
									}} />
							</Box>
						</Grid>
					</Grid>
				})
				}

				<Grid container xs={12} sx={{ mt: '5px', pb: '5px' }} justifyContent="flex-start" alignItems="center">
					<Box sx={{ fontFamily: 'Poppins', fontSize: '16px' }}>Notes</Box>
					<Box sx={{ ml: '2px', mt: '2px', fontFamily: 'Poppins-ExtraLight', fontSize: '12px' }}>(Optional)</Box>
				</Grid>
				<Grid container xs={12} justifyContent='center' alignItems="center">
					<textarea className='text-area-complete h-100' placeholder='Tell us how it went!' value={newComment} onChange={(e) => setNewComment(e.target.value)} />
				</Grid>
				<Grid container xs={12} sx={{ mt: '30px' }} justifyContent='center' alignItems="center">
					<RSButton onClick={handleSubmit} disabled={isUpdated()} sx={{ width: '100%', mt: '10px'}} >Submit</RSButton>
				</Grid>

				{!raceResults?.id && <Grid container xs={12} sx={{ pt: '20px', pb: '5px', fontSize: '14px', textDecoration: 'underline', color: '' }} justifyContent="center" alignItems="center">
					<Button onClick={() => handleDNFClick(true)} sx={{ textTransform: 'none', color: '#485D13' }}>{`I didn't finish the race`}</Button>
				</Grid>}
			</Grid>
		</RSDrawer>
		{renderDNF()}
	</>

}

export default CompletePlan;
