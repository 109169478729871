export const FEATURED_RACES = [
	{
		"name": "Buffalo Marathon Weekend",
		"date": "May 24, 2025",
		"datePerRaceType": [
			{
				"type": "FULL",
				"date": "May 25, 2025"
			},
			{
				"type": "HALF",
				"date": "May 25, 2025"
			},
			{
				"type": "5K",
				"date": "May 24, 2025"
			}
		],
		"dateEnd": "05/25/2025",
		"place": "Buffalo, NY, US",
		"logo": "https://d368g9lw5ileu7.cloudfront.net/races/race37602-logo-0.bL40AV.png",
		"type": ["FULL", "HALF", "5K"],
		"desc": "<p>2025 marks the 24th anniversary of the Buffalo Marathon Weekend. Come out and enjoy a Full Marathon, Half Marathon, Heart-To-Heart Relay, the Buffalo Marathon 5K, Kids Mini-Marathon, Diaper Dash or Walk 5k. This is a fast, scenic Boston Marathon qualifier that takes you through the best streets, parks and waterways in Buffalo. Registration changes (deferrals, race transfers, participant transfers) close April 30, 2025.</p><p><b>Please be Aware There are No Refunds. </b><br />Please understand to provide you with the best event possible we need to plan, budget and control our costs based on our registration numbers.  We do realize life does happen and things do come up that may prevent you from participating. With that in mind we are providing several options in the unfortunate event you cannot make the race. </p><p><b>Protect Your Race Registration</b><br />Registration insurance is available for the nominal charge of 7.5%  of the registration cost including processing fee (minimum charge is $8.99).  Register with peace of mind knowing you can recover 100% of your registration cost plus fees If you cannot attend the race for a number of reasons, including covered injury, illness, traffic accidents, and more.</p>",
		"homepage": "https://runsignup.com/Race/NY/Buffalo/BuffaloMarathon",
		"source": "RunSignUp",
		"isFeatured": true,
		"color": "#2b2e5c"
	},
	{
		"name": "Guthrie Wineglass Marathon, Wegmans Wineglass Half Marathon & Williams Toyota Wineglass 5K",
		"date": "October 5, 2024",
		"datePerRaceType": [
			{
				"type": "FULL",
				"date": "October 6, 2024"
			},
			{
				"type": "HALF",
				"date": "October 6, 2024"
			},
			{
				"type": "5K",
				"date": "October 5, 2024"
			}
		],
		"dateEnd": "10/06/2024",
		"place": "Corning, NY, US",
		"logo": "https://d368g9lw5ileu7.cloudfront.net/races/race26594-logo-0.bLxH1V.png",
		"type": ['FULL', 'HALF', '5K'],
		"desc": "<p>Join us for the 43rd Guthrie Wineglass Marathon and 14th Annual Wegmans Wineglass Half Marathon on Sunday, October 6, 2024. In the heart of the Finger Lakes and Historic Corning, NY, your weekend promises to be packed with great museums, fine wine, great shopping and topped off with a scenic run!  Your reward, a handcrafted glass medal and memories to last a lifetime.  Wineglass weekend kicks off with our Health &amp; Fitness Expo beginning Friday, October 4th, 2024 and continues with the Williams Toyota Wineglass 5K which is held Saturday, October 5th, 2024.  Combine the Wineglass 8K (May 25, 2024) with the Williams Toyota Wineglasss 5K and either the Full or Half Marathon for a fun &amp; exciting Challenge (Riesling or Pinot Challenge) and earn a special medal. </p>\r\n<p>All Wineglass Race Series Events are USATF certified and sanctioned courses. (Course Time Limit - 6.5 hours for the Marathon &amp; 7 hours for the Half Marathon)</p>",
		"homepage": "https://runsignup.com/Race/NY/Corning/WineGlassMarathon",
		"source": "RunSignUp",
		"isFeatured": true,
		"color": "#ba252b"
	}
];

export const isFeaturedActivated = true;
