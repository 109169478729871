import { getReq, postReq, putReq } from "./apiConsume";


export const CreateAItrainingPlan = async (tableData: any) =>
    await postReq('/v2/create-training-plan', tableData)

export const UpdateAItrainingPlan = async (tableData: any) =>
    await putReq(`/v2/update-training-plan`, tableData )

export const GetAItrainingPlan = async (user_id: number, plan_id: string) =>
    await getReq(`/v2/get-training-plan?user_id=${user_id}&plan_id=${plan_id}`, { user_id, plan_id })

export const DeactivateGPTPlan = async (planId: number, signal: AbortSignal) =>
    await putReq(`/v2/gpt-training-plan-info/deactivate?plan_id=${planId}`, { signal: signal })

export const ActivateGPTPlan = async (planId: number, signal: AbortSignal) =>
    await putReq(`/v2/gpt-training-plan-info/activate?plan_id=${planId}`, { signal: signal })

export const GetTrainingPlanInfo = async (planId: number, signal: AbortSignal) =>
    await getReq(`/v2/gpt-training-plan-info?plan_id=${planId}`, { signal: signal })

export const GetTrainingPlanByUser = async (userId: number, signal: AbortSignal) =>
    await getReq(`/v2/gpt-training-plan-info/all?userId=${userId}`, { signal: signal })

export const ChangePlanLevel = async (planId: number, level: number, signal: AbortSignal) =>
    await putReq(`/v2/gpt-training-plan-info/change-level?plan_id=${planId}&level=${level}`, { signal: signal })

