/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/no-empty-function */
import { FC, useState, useEffect, useRef } from 'react'
import { motion } from "framer-motion";
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';

import RSButton from '../../../../components/buttons/RSButton'

export type RunningStyleInfoProps = {
    done?: any,
		bodyData?: any,
		setBodyData?: any,
		nextAvailability?: any
}

const RunningStyleInfo: FC<RunningStyleInfoProps> = ({done, bodyData, setBodyData, nextAvailability}) => {
		const [isLongRunSat, setIsLongRunSat] = useState(bodyData?.isLongRunSat === undefined ? 0 : bodyData?.isLongRunSat);
		const [runningStyle, setRunningStyle] = useState(bodyData?.runningStyle?.index === undefined ? '' : bodyData.runningStyle.index);

		const dayList = ['Sunday', 'Saturday']

		const activities = [{
			title: 'Heel strike',
			rotate: -20
		},{
			title: 'Midfoot Strike',
			rotate: 0
		},{
			title: 'Toe Strike',
			rotate: 20
		},{
			title: 'Unsure'
		}]

		useEffect(()=>{
			setBodyData({
				...bodyData,
				isLongRunSat,
				runningStyle: {
					index: runningStyle,
					title: activities[runningStyle]?.title
				}
			})
			nextAvailability(runningStyle !== '')
		},[runningStyle, isLongRunSat])

    return <Box sx={{display: 'flex', flexDirection: 'column', width: '100%'}}>
			<Grid container xs={12} sx={{pb: '5px'}} justifyContent='space-between' >
				<Grid container xs={12} sx={{fontFamily: 'Poppins-Bold', fontSize: '14px', lineHeight: '1.4em', pb: '10px'}}>
					What is your preferred long run day?
				</Grid>
				<Grid container xs={12} justifyContent='space-between'>
					{dayList.map((activity: any, ai: number)=>{
						const isActive = ai === isLongRunSat
						return <Grid item container xs={5.6} key={'activity-'+ai} sx={{minHeight: '100px', width: '100px', mb: '20px'}} justifyContent='center' alignItems='center'>
							<RSButton card secondary={!isActive} sx={{height: '100%', flexDirection: 'column'}} onClick={()=>setIsLongRunSat(ai)}>
								<Box sx={{fontFamily: 'Poppins-Medium !important', fontSize: '14px !important', textAlign: 'center', lineHeight: '1em', pb: '5px'}}>{activity}</Box>
							</RSButton>
						</Grid>
					})}
				</Grid>
			</Grid>

			<Grid container xs={12} sx={{pb: '5px'}} justifyContent='space-between' >
				<Grid container xs={12} sx={{fontFamily: 'Poppins-Bold', fontSize: '14px', lineHeight: '1.4em', pb: '10px'}}>
					What is your current running style?
				</Grid>
				<Grid container xs={12} justifyContent='space-between'>
					{activities.map((activity: any, ai: number)=>{
						const isActive = ai === runningStyle
						const hasImg = activity.rotate !== undefined
						return <Grid item container xs={5.6} key={'activity-'+ai} sx={{minHeight: '100px', width: '100px', mb: '20px'}} justifyContent='center' alignItems='center'>
							<RSButton card secondary={!isActive} sx={{height: '100%', flexDirection: 'column'}} onClick={()=>setRunningStyle(ai)}>
								{hasImg && <Box sx={{mt: '-10px'}}><img src="./img-new/get-started/running-style-shoe.png" style={{width: '70px', transform: `rotate(${activity.rotate}deg)`}} /></Box>}
								<Box sx={{fontFamily: 'Poppins-Medium !important', fontSize: '14px !important', textAlign: 'center', lineHeight: '1em', pb: '5px', mb: hasImg ? '-10px' : 0}}>{activity.title}</Box>
							</RSButton>
						</Grid>
					})}
				</Grid>
			</Grid>
		</Box>
}

export default RunningStyleInfo
