/* eslint-disable @typescript-eslint/no-unused-vars */
import { FC, useState} from 'react'
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';

import RSButton from './RSButton';

export type RSFlagButtonProps = {
	symbol: string,
	title: boolean,
	children?: any,
    date?: any,
	place?: any,
	onClick?: Function,
	disabled?: boolean,
	className?: string,
	sx?: object,
	isFeatured?: boolean
	hasLoader?: boolean
}


const RSFlagButton: FC<RSFlagButtonProps> = ({symbol, children, date, place, sx = {}, title, onClick, disabled, className, isFeatured = false, hasLoader}) => {
	const [isLoading, setIsLoading] = useState(hasLoader ? true : false)
	const style = {
		px: '15px',
		py: '15px',
		height: '126px',
		width: '100%',
		// maxWidth: '500px',
		textAlign: 'left',
		lineHeight: '1em !important',
		justifyContent: 'space-between',
		borderRadius: '10px',
		...sx
	}


	const handleClick = (e: any) => {
		onClick?.(e)
	}

	const onErrorLoadImg = () => {
		return (e: any) => {
			// e.target.src = './images/icons/runsmart-guy.svg'
			e.target.src = './img-new/race-events/marathon_placeholder.png'
			e.target.style = 'scale: 0.8'
		}

	}



	return <RSButton customized secondary card className={className ? className : ''} onClick={handleClick} sx={style} disabled={disabled ? disabled : false}>
		<Box sx={{height: '100%', pr: '15px'}}>
			<Grid container sx={{ position: 'absolute', height: '65px', width: '65px', textAlign: 'center', fontFamily: 'Poppins-SemiBold !important', fontSize: '25px !important', color: '#742F4E', overflow: 'hidden'}} justifyContent='center' alignItems='flex-start'>
				{hasLoader && isLoading && <Box className='loading-days toRight' sx={{ border: 'none !important' }} /> }
				<img onLoad={()=> setIsLoading(false)} src={symbol} style={{ borderRadius: '10px', height: '100%', width: '100%' }} onError={onErrorLoadImg()}/>
			</Grid>
		</Box>
		<Box sx={{display: 'flex', flexDirection: 'column', width: 'calc(100% - 80px)', color: isFeatured ? '#fff' : '#010101' }}>
			<Box sx={{ fontSize: '18px !important', fontFamily: 'Poppins-Medium !important', lineHeight: '1.2em !important', letterSpacing: '-0.3px !important', height: '22px', overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap', width: '100%'}}>
				{title}
			</Box>
			<Box sx={{pr: '14px', fontSize: '1em !important', fontFamily: 'Poppins !important', lineHeight: '1.2em !important'}}>
				{date}
			</Box>
			<Box sx={{ mr: '14px', fontSize: '1em !important', fontFamily: 'Poppins !important', lineHeight: '1.2em !important' }}>
				{place}
			</Box>
			{children}
		</Box>
	</RSButton>
}

export default RSFlagButton;
