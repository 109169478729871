/* eslint-disable @typescript-eslint/no-unused-vars */
import { date, strtotime } from 'locutus/php/datetime'
import useBuildTrainingPlan from '../../../pages/training-plan/hooks/useBuildTrainingPlan'
import useTrainingPlanUtils from '../../../hooks/useTrainingPlanUtils'
import { count } from 'locutus/php/array'
import { values } from 'lodash'
import moment from 'moment'
import { DAYS_OF_WEEK, DAYS_OF_WEEK_ALT } from '../build/DaysOptions'


const useCalendar = () => {
    const { getThisDate, getCurrentWeek, DaysInMonth, GetWeek, GetWeek2, convertDateToUTC, getWeekDays } = useTrainingPlanUtils()
    const { getRaceDayDetails, getRaceDistance, getRaceDistancePace } = useBuildTrainingPlan()

    const convertTime = (value: any) => {
        const minsToSubtract = 121;
        return strtotime('+' + minsToSubtract + ' minutes', strtotime(value))
    }

    const buildCalendarMonth = (monthKey: number, userInfo: any) => {
        const weekDays = getWeekDays(new Date(), userInfo.start, userInfo.race_date, userInfo.monday_start ? DAYS_OF_WEEK_ALT : DAYS_OF_WEEK);
        const { start, monday_start, race_date, weeks, type, distance } = userInfo
        let day = date("w")
        // console.log(dateNow)
        // let day = (date("w"))

        // console.log(day)
        day = monday_start ? (day > 0 ? day - 1 : 6) : day;
        const raceDetails = getRaceDayDetails(userInfo)
        // const race_day = date('w', strtotime(race_date))
        const currentWeek = getCurrentWeek(start, monday_start) + 1;
        // console.log(currentWeek)
        const today_day = getThisDate(start, currentWeek, day, monday_start)

        const starting_date = date("Y-m-d", strtotime(today_day));
        const starting_month = date("F", strtotime(today_day));
        const race_date_time = strtotime(convertDateToUTC(race_date));


        // const convertedDate = date("Y-m-d H:i:m", strtotime('+1 hours', strtotime(dateNow)))
        // console.log(new Date(""))
        let js_month = date("m", strtotime(today_day));
        js_month = js_month > 0 ? js_month - 1 : 12;
        const starting_year = starting_month + " " + date("Y", strtotime(today_day));

        const calMonths: Array<any> = [];
        const calMonthDates: Array<number> = [];
        const calMonthDigits: Array<number> = [];
        let monthItt = 0;
        const curMonth = convertDateToUTC(userInfo?.type == 0 ? weekDays[0] : start);
        const curMonthFirst = date("Y-m", strtotime(curMonth));
        let monDigit;
        const monDigits: Array<number> = []

        if (strtotime(curMonth) <= race_date_time) {
            while (strtotime(curMonthFirst + " + " + monthItt + " months") <= race_date_time) {
                if (monthItt == 0) {
                    calMonthDates.push(date("Y-m-d", strtotime(curMonth)));
                    monDigit = date("m", strtotime(curMonth));
                } else {
                    calMonthDates.push(date("Y-m-d", strtotime(curMonthFirst + " + " + monthItt + " months")));
                    monDigit = date("m", strtotime(curMonthFirst + " + " + monthItt + " months"));
                }
                monDigit = monDigit > 0 ? monDigit - 1 : 12;
                calMonthDigits.push(monDigit);
                monthItt += 1;
                monDigits.push(monDigit)
            }
        }
        let firstWeek;
        for (let x = 0; x < count(calMonthDates); x++) {
            const curMonthOutput: Array<any> = [];
            const ref_day = calMonthDates[x];
            // console.log(ref_day)
            const d = date('d', strtotime(ref_day));
            let m = date('m', strtotime(ref_day));
            m = m > 0 ? +m - 1 : 12;
            const y = date('Y', strtotime(ref_day));
            const number = DaysInMonth(date('m', strtotime(ref_day)), date('Y', strtotime(ref_day)));
            for (let i = 1; i <= number; i++) {
                const first_day_of_month = date('Y', strtotime(ref_day)) + '-' + date('m', strtotime(ref_day)) + '-' + i;
                let week = GetWeek(first_day_of_month, new Date(convertDateToUTC(weekDays[0])), monday_start);

                let to_week = weeks;
                if (raceDetails.raceDay == 0 || raceDetails.raceDay == 1) {
                    to_week += 1;
                }

                const sunSun = raceDetails.raceDay == 0 && !raceDetails.saturdayRace && !raceDetails.sundayRace;
                const sunMon = raceDetails.raceDay == 1 && !raceDetails.saturdayRace && !raceDetails.sundayRace;
                const sunSat = raceDetails.raceDay == 6 && !raceDetails.saturdayRace && !raceDetails.sundayRace;

                //monday start
                const monSat = raceDetails.raceDay == 5 && raceDetails.saturdayRace && !raceDetails.sundayRace;
                const monSun = raceDetails.raceDay == 6 && !raceDetails.saturdayRace && raceDetails.sundayRace;
                const monMon = raceDetails.raceDay == 0 && !raceDetails.saturdayRace && !raceDetails.sundayRace;

                !firstWeek ? firstWeek = week : null;

                if (userInfo?.type == 1 && firstWeek == -5 && sunSun || userInfo?.type == 0 && firstWeek == -5 && sunSun) {
                    week += 0
                }
                //half
                else if (!sunMon && firstWeek == -5 || firstWeek == -6 || firstWeek == -2 && sunSat || sunSat && firstWeek == -3) {
                    week += 1
                }

                //full
                if (userInfo?.type == 1 && firstWeek == -4 && sunSat || userInfo?.type == 1 && firstWeek == -2 && sunSat) {
                    week += 1
                }


                if (!monday_start) {
                    if (userInfo?.type != 0) {
                        if (week >= (sunSun ? -1 : 0) && week <= to_week) {
                            week += (sunSun ? 2 : 1);

                            const diem = date('w', strtotime(date(first_day_of_month)));
                            const week_s = week;
                            const diems = diem;

                            const dateVal = `${y}-${m + 1}-${+i}`;

                            if (+week_s <= +to_week)
                                curMonthOutput.push({
                                    week: week_s,
                                    day: +diems + 1,
                                    // dateString: Intl.DateTimeFormat('en-us', { month: 'long' }).format(new Date((m + 1).toString())) + " " + i,
                                    dateString: "",
                                    dateVal: dateVal,
                                    monthDigits: monDigits
                                });
                        }
                    }
                    else {
                        if (week >= (sunSun || sunMon ? -1 : 0) && week <= to_week) {
                            week += (sunSun || sunMon ? 2 : 1);

                            const diem = date('w', strtotime(date(first_day_of_month)));
                            const week_s = week;
                            const diems = diem;

                            const dateVal = `${y}-${m + 1}-${+i}`;

                            if (+week_s <= +to_week)
                                curMonthOutput.push({
                                    week: week_s,
                                    day: +diems + 1,
                                    dateString: Intl.DateTimeFormat('en-us', { month: 'long' }).format(new Date((m + 1).toString())) + " " + i,
                                    // dateString: "",
                                    dateVal: dateVal,
                                    monthDigits: monDigits
                                });
                        }
                    }
                }
                else if (monday_start) {
                    if (week >= 0 && week <= to_week) {
                        week += 1;
                        // if (week >= 0 && week <= to_week) {
                        //     week += 1;
                        const diem = date('w', strtotime(date(first_day_of_month)));
                        const week_s = week;

                        const diems = +diem;

                        const dateVal = `${y}-${m + 1}-${+i}`;

                        if (+week_s <= +to_week)
                            curMonthOutput.push({
                                week: week_s,
                                day: +diems == 0 ? 7 : +diems,
                                // dateString: Intl.DateTimeFormat('en', { month: 'long' }).format(new Date((m + 1).toString())) + " " + i,
                                dateVal: dateVal,
                                monthDigits: monDigits
                            });
                    }
                }
            }
            calMonths[m] = curMonthOutput;
        }
        return calMonths[monthKey]
    }


    const buildCalendarAllMonths = (userInfo: any) => {
        const { start, monday_start, race_date, weeks, type, distance } = userInfo
        let day = date("w")
        day = monday_start ? (day > 0 ? day - 1 : 6) : day;
        const raceDetails = getRaceDayDetails(userInfo)
        // const race_day = date('w', strtotime(race_date))
        const currentWeek = getCurrentWeek(start, monday_start) + 1;
        const today_day = getThisDate(start, currentWeek, day, monday_start)

        const starting_date = date("Y-m-d", strtotime(today_day));
        const starting_month = date("F", strtotime(today_day));
        const race_date_time = strtotime(convertDateToUTC(race_date));

        let js_month = date("m", strtotime(today_day));
        js_month = js_month > 0 ? js_month - 1 : 12;
        const starting_year = starting_month + " " + date("Y", strtotime(today_day));

        const calMonths: Array<any> = [];
        const calMonthDates: Array<number> = [];
        const calMonthDigits: Array<number> = [];
        let monthItt = 0;
        const curMonth = convertDateToUTC(start);
        const curMonthFirst = date("Y-m", strtotime(curMonth));
        let monDigit;
        const monDigits: Array<number> = []

        if (strtotime(curMonth) <= race_date_time) {
            while (strtotime(curMonthFirst + " + " + monthItt + " months") <= race_date_time) {
                if (monthItt == 0) {
                    calMonthDates.push(date("Y-m-d", strtotime(curMonth)));
                    monDigit = date("m", strtotime(curMonth));
                } else {
                    calMonthDates.push(date("Y-m-d", strtotime(curMonthFirst + " + " + monthItt + " months")));
                    monDigit = date("m", strtotime(curMonthFirst + " + " + monthItt + " months"));
                }
                monDigit = monDigit > 0 ? monDigit - 1 : 12;
                calMonthDigits.push(monDigit);
                monthItt += 1;
                monDigits.push(monDigit)
            }
        }

        for (let x = 0; x < count(calMonthDates); x++) {
            const curMonthOutput: Array<any> = [];
            const ref_day = calMonthDates[x];
            const d = date('d', strtotime(ref_day));
            let m = date('m', strtotime(ref_day));
            m = m > 0 ? +m - 1 : 12;
            const y = date('Y', strtotime(ref_day));
            const number = DaysInMonth(date('m', strtotime(ref_day)), date('Y', strtotime(ref_day)));

            for (let i = 1; i <= number; i++) {
                const first_day_of_month = date('Y', strtotime(ref_day)) + '-' + date('m', strtotime(ref_day)) + '-' + i;
                let week = GetWeek(first_day_of_month, start, monday_start);

                // let week = GetWeek2(first_day_of_month, start);

                // console.log(week)
                let to_week = weeks;
                if (raceDetails.raceDay == 0 || raceDetails.raceDay == 1) {
                    to_week += 1;
                }

                // console.log(to_week)

                //sunday start
                const sunSun = raceDetails.raceDay == 0 && !raceDetails.saturdayRace && !raceDetails.sundayRace;
                const sunMon = raceDetails.raceDay == 1 && !raceDetails.saturdayRace && !raceDetails.sundayRace;
                const sunSat = raceDetails.raceDay == 6 && !raceDetails.saturdayRace && !raceDetails.sundayRace;

                //monday start
                const monSat = raceDetails.raceDay == 5 && raceDetails.saturdayRace && !raceDetails.sundayRace;
                const monSun = raceDetails.raceDay == 6 && !raceDetails.saturdayRace && raceDetails.sundayRace;
                const monMon = raceDetails.raceDay == 0 && !raceDetails.saturdayRace && !raceDetails.sundayRace;
                // console.log(week)
                // console.log(to_week)
                if (!monday_start) {
                    if (userInfo?.type != 0) {
                        if (week >= (sunSun ? -1 : sunMon ? 1 : 0) && week <= to_week) {
                            week += (sunSun ? 2 : 1);

                            // if (week >= (sunSun || sunMon ? -1 : 0) && week <= to_week) {
                            //     week += (sunSun || sunMon ? 2 : 1);
                            const diem = date('w', strtotime(date(first_day_of_month)));
                            // console.log(diem)
                            const week_s = week;
                            const diems = diem;
                            // console.log(raceDetails.raceDay)

                            const dateVal = `${y}-${m + 1}-${+i}`;

                            if (+week_s <= +to_week)
                                curMonthOutput.push({
                                    week: week_s,
                                    day: +diems + 1,
                                    dateString: Intl.DateTimeFormat('en-us', { month: 'long' }).format(new Date((m + 1).toString())) + " " + i,
                                    // dateString: "",
                                    dateVal: dateVal,
                                    monthDigits: monDigits
                                });
                        }
                    }
                    else {
                        if (week >= (sunSun || sunMon ? -1 : 0) && week <= to_week) {
                            week += (sunSun || sunMon ? 2 : 2);

                            // if (week >= (sunSun || sunMon ? -1 : 0) && week <= to_week) {
                            //     week += (sunSun || sunMon ? 2 : 1);
                            const diem = date('w', strtotime(date(first_day_of_month)));
                            // console.log(diem)
                            const week_s = week;
                            const diems = diem;
                            // console.log(raceDetails.raceDay)

                            const dateVal = `${y}-${m + 1}-${+i}`;

                            if (+week_s <= +to_week)
                                curMonthOutput.push({
                                    week: week_s,
                                    day: +diems + 1,
                                    dateString: Intl.DateTimeFormat('en-us', { month: 'long' }).format(new Date((m + 1).toString())) + " " + i,
                                    // dateString: "",
                                    dateVal: dateVal,
                                    monthDigits: monDigits
                                });
                        }
                    }
                }
                else if (monday_start) {
                    if (userInfo?.type == 1 || userInfo?.type == 2 || userInfo?.type == 3) {
                        if (week >= (monSat ? 0 : 0) && week <= to_week) {
                            week += (monSun || monMon ? 1 : monSat ? 1 : 1);
                            // if (week >= 0 && week <= to_week) {
                            //     week += 1;
                            const diem = date('w', strtotime(date(first_day_of_month)));
                            const week_s = week;

                            const diems = +diem;

                            const dateVal = `${y}-${m + 1}-${+i}`;

                            if (+week_s <= +to_week)
                                curMonthOutput.push({
                                    week: week_s,
                                    day: +diems == 0 ? 7 : +diems,
                                    // dateString: Intl.DateTimeFormat('en', { month: 'long' }).format(new Date((m + 1).toString())) + " " + i,
                                    dateVal: dateVal,
                                    monthDigits: monDigits
                                });
                        }
                    }
                    else if (userInfo?.type == 0) {
                        if (week == -3) {
                            week + 3
                        }
                        if (week >= (monSat ? 0 : 0) && week <= to_week) {
                            week += (monSun || monMon ? 1 : monSat ? 1 : 1);
                            // if (week >= 0 && week <= to_week) {
                            //     week += 1;
                            const diem = date('w', strtotime(date(first_day_of_month)));
                            const week_s = week;

                            const diems = +diem;

                            const dateVal = `${y}-${m + 1}-${+i}`;

                            if (+week_s <= +to_week)
                                curMonthOutput.push({
                                    week: week_s,
                                    day: +diems == 0 ? 7 : +diems,
                                    dateString: Intl.DateTimeFormat('en', { month: 'long' }).format(new Date((m + 1).toString())) + " " + i,
                                    dateVal: dateVal,
                                    monthDigits: monDigits
                                });
                        }
                    }
                    else {
                        if (week >= 0 && week <= to_week) {
                            week += 1;
                            const diem = date('w', strtotime(date(first_day_of_month)));
                            const week_s = week;
                            const diems = diem;
                            const dateVal = `${y}-${m + 1}-${+i}`;
                            if (+week_s <= +to_week)
                                curMonthOutput.push({
                                    week: week_s,
                                    day: +diems == 0 ? 7 : +diems,
                                    dateString: Intl.DateTimeFormat('en', { month: 'long' }).format(new Date((m + 1).toString())) + " " + i,
                                    dateVal: dateVal,
                                    monthDigits: monDigits
                                });
                        }
                    }
                }
            }
            calMonths.push(...curMonthOutput);
        }
        return calMonths
    }

    return { buildCalendarMonth, buildCalendarAllMonths }
}

export default useCalendar