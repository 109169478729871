import WithAuth from '../components/WithAuth'
import AppMenus from '../components/layout/AppMenus'
import MainCard from '../components/cards/MainCard'
import Button from 'react-bootstrap/Button'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import PageTitle from '../components/text/PageTitle'
import AboutCard from '../components/page-specific/video/AboutCard'
import WorkoutVideos from '../components/page-specific/video/WorkoutVideos'
import MoreVert from '@mui/icons-material/MoreVert'
import classNames from 'classnames'
import { AuthPageProps } from '../modules/types'
import { useLocation, useParams } from 'react-router-dom'
import useExerciseTypePage, {
	exerciseTypes,
} from '../hooks/useExerciseTypePage'
import { exerciseLinks } from '../modules/linkInfo'
import { FC, useEffect, useState } from 'react'

// Exercises Props
export type ExerciseTypeProps = AuthPageProps & {
	exerciseType: keyof typeof exerciseTypes
}

/**
 * Exercises Component
 */

const Exercises: FC<ExerciseTypeProps> = ({ exerciseType, userInfo }) => {
	// Hooks
	const { videoId } = useParams()
	const { videos, currentVideo, progressInfo } = useExerciseTypePage({
		exerciseType,
		videoId,
	})
	const [showButtons, setShowButtons] = useState(false)

	const location = useLocation()

	// Properties
	const exerciseIndex = Object.keys(exerciseLinks).indexOf(exerciseType)
	const hasVideoId = videoId ? true : false

	// TO-DO: On Like
	const onLike = () => 0

	// On Toggle Buttons
	const onToggleButtons = () => setShowButtons(!showButtons)

	const [isPlaying, setIsPlaying] = useState(false)

	useEffect(() => {
		setIsPlaying(false)
	}, [location])

	// Render
	return (
		<AppMenus isMobileTitle hideTitleDesktop>
			{/* Video Card Row */}
			{hasVideoId && (
				<Row className="get-started-training-plan justify-content-center mt-n4 mt-md-0 mb-md-5 mb-3">
					<Col className="px-0 px-sm-3">
						<div className="video-wrapper blue-bg">
							{!isPlaying ? (
								<>
									<h4 className="heading-meta-data text-white fw-regular">
										{exerciseType}
									</h4>
								</>) : null}

							<MainCard
								hasPlayButton
								playButtonIsCentered
								type="responsiveCardLg"
								bgImage={currentVideo.image}
								vimeoId={currentVideo.id}
								progress={progressInfo?.progress}
								onLike={onLike}
								onPlay={() => setIsPlaying(true)}
							/>
							{/* {!isPlaying ? (
								<div className="banner-top-icon fav-icon heart-image">
									<input type="checkbox" id="heart" hidden />
									<label htmlFor="heart">
										<div className="animation">
											<span className="dot-1"></span>
											<span className="dot-2"></span>
											<span className="dot-3"></span>
											<span className="dot-4"></span>
											<span className="dot-5"></span>
											<span className="dot-6"></span>
											<span className="dot-7"></span>
											<span className="dot-8"></span>
										</div>
										<img
											className="fill pointer"
											src="./images/icons/heart-red.svg"
											alt="Like"
										/>
										<img
											className="without-fill pointer"
											src="./images/icons/heart-white-big.png"
											alt="Like"
										/>
									</label>
								</div>
							) : undefined} */}



							{!isPlaying ? (
								<>
									<div className="banner-info">
										<h4 className="text-white fw-medium text-capitalize">{currentVideo?.headingTitle}</h4>

									</div>
									{/* <div className="tick-mark">
										<img
											src="./images/icons/tick-mark.png"
											alt="Like"
										/>
									</div> */}
								</>
							) : null}
						</div>
					</Col>
				</Row>
			)}

			{/* Title Row */}
			<Row className="justify-content-center d-none d-md-block">
				<Col className="text-center">
					<PageTitle text={exerciseType} />
				</Col>
			</Row>

			{/* Desktop Buttons Row */}
			<Row className="justify-content-center text-center mb-5 d-none d-lg-block">
				<Col xs={12}>
					<div>
						{Object.keys(exerciseLinks).map((key, index) => {
							const className = classNames(
								'fixed-sm-btn rounded-pill small-text',
								exerciseIndex == index ? undefined : 'color-primary-bg'
							)
							return (
								<span key={index} className="mx-2">
									<Button
										variant="dark"
										className={className}
										as="a"
										href={exerciseLinks[key as keyof typeof exerciseLinks]}
									>
										{key}
									</Button>
								</span>
							)
						})}
					</div>
				</Col>
			</Row>

			{/* Mobile Buttons Row */}
			<Row className="justify-content-center mb-5 d-block d-lg-none">
				<Col xs={12} className="text-center mb-3">
					<div className="ps-4">
						<Button
							size="lg"
							variant="dark"
							className={classNames(
								'rounded-pill me-1',
								exerciseIndex == 0 ? undefined : 'color-primary-bg'
							)}
							style={{ width: '12rem' }}
							as="a"
							href={exerciseLinks['All Exercises']}
						>
							All Exercises
						</Button>
						<MoreVert
							className="has-pointer icon-lg text-secondary"
							onClick={onToggleButtons}
						/>
					</div>
				</Col>
				{showButtons && (
					<>
						{Object.keys(exerciseLinks)
							.slice(1)
							.map((key, index) => {
								const className = classNames(
									'fixed-md-btn rounded-pill',
									exerciseIndex == index + 1 ? undefined : 'color-primary-bg'
								)
								return (
									<Col key={index} xs={12} className="text-center mb-2">
										<Button
											variant="dark"
											className={className}
											as="a"
											href={exerciseLinks[key as keyof typeof exerciseLinks]}
										>
											{key}
										</Button>
									</Col>
								)
							})}
					</>
				)}
			</Row>

			{/* About Card */}
			{hasVideoId && (
				<Row className="mb-5">
					<Col>
						<AboutCard
							aboutHeadlines={currentVideo.headlines || []}
							aboutDescriptions={currentVideo.descriptions || []}
						/>
					</Col>
				</Row>
			)}

			{/* Workout Videos */}
			<WorkoutVideos videos={videos} userInfo={userInfo} hideWeeks />
		</AppMenus>
	)
}

export default WithAuth(Exercises)
