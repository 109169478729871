import { useEffect, useState } from "react"
import { IImpactClickId } from "../api/v2/impact/types"
import { getReq } from "../modules/apiConsume"


const useImpact = (userId: number) => {

    const [isLoading, setIsLoading] = useState(true)
    const [impactData, setImpactData] = useState<IImpactClickId | undefined>()

    const getImpactData = async () => {
        setIsLoading(true)
        const result = await getReq(`/v2/impact/clickid?user_id=${userId}`)
        setImpactData(result?.data?.result[0])
        setIsLoading(false)
    }
    useEffect(() => {
        if (userId)
            getImpactData()
    }, [userId])

    return { isLoading, impactData }

}

export default useImpact