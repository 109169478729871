import { AppleHealthActivity } from "../api/v2/apple-health-kit/apple-health-kit.types";
import { getReq, postReq, putReq } from "./apiConsume";

export const GetAppleHealtActivity = async (user_id: number, activity_date: string) =>
    await getReq(`/v2/apple-health-kit?user_id=${user_id}&activity_date=${activity_date}`)

export const CreateAppleHealtActivity = async (activityData: AppleHealthActivity) =>
    await postReq('/v2/apple-health-kit', activityData)

export const UpdateAppleHealtActivity = async (activityData: AppleHealthActivity) =>
    await putReq('/v2/apple-health-kit', activityData)