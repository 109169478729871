import useUserInfo from '../../hooks/useUserInfo'
import { FC, useRef, useEffect, useState } from 'react'
import useVimeoPlayerDefault from '../../hooks/useVimeoPlayerDefault'
import usePlayCastVideo from '../../hooks/usePlayCastVideo'
import useStore from '../../store/useStore'
import { IGoogleCastStore } from '../../store/createGoogleCastStore'

// Props
export type VimeoPlayerProps = {
    id: string | number
    isPlaying?: boolean
    currentProgress?: number
}

// Get Link
const getLink = (id: string | number, isPlaying = false) =>
    `https://player.vimeo.com/video/${id}${isPlaying ? '?autoplay=1' : ''}`

/**
 * VimeoPlayer Component
 */

const VimeoPlayerDefault: FC<VimeoPlayerProps> = ({ id, isPlaying, currentProgress }) => {

    const localStorageRequestKey = localStorage.getItem('deviceRequestKey') || undefined

    const { castStatus } = useStore((state: IGoogleCastStore) => state)

    // State
    const [src, setSrc] = useState(getLink(id, isPlaying))

    const { playVideo } = usePlayCastVideo(localStorageRequestKey)

    // Ref
    const iframeRef = useRef(null)

    // Hooks
    const { accountId } = useUserInfo()

    // Mount Effect
    useEffect(() => {
        if (iframeRef.current && accountId) {
            useVimeoPlayerDefault({ iframe: iframeRef.current, userId: accountId, playCastVideo: playVideo, isCastActive: castStatus == 'active' || castStatus == 'playing' ? true : false, currentProgress: currentProgress })
        }
    }, [iframeRef.current, accountId])

    // Is Playing Effect
    useEffect(() => {
        setSrc(getLink(id, isPlaying))
    }, [isPlaying, id])

    // Render
    return (
        <div style={{ boxSizing: 'unset' }}>
            <iframe
                ref={iframeRef}
                className="iframe-player"
                src={src}
                frameBorder="0"
                allowFullScreen
                allow="autoplay"
                data-ready
            />
        </div>
    )
}

export default VimeoPlayerDefault