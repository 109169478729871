import React, { FC } from 'react'
import RSCardCont from '../../../components/cards/RSCardCont';
import { Box, Grid } from '@mui/material';

interface WorkoutVideoContainerProps {
    thumbnail: string,
    index: number,
    headingTitle: string,
    subTitle: string,
    onClick?: () => Function
    duration: number

}

const WorkoutVideoContainer: FC<WorkoutVideoContainerProps> = ({ onClick, index, thumbnail, headingTitle, subTitle, duration }) => {

    return (
        <RSCardCont onClick={() => onClick && onClick()} key={index} sx={{ maxWidth: '450px', height: '80px', p: 0, overflow: 'hidden' }}>
						<Box sx={{ height: '80px', width: '100%', display: 'flex', justifyContent: 'flex-start', alignItems: 'center' }}>
								<Grid container xs={5} sx={{ position: 'relative', height: '80px', zIndex: 2 }}>
										<Box className='card-slanted' sx={{ backgroundSize: 'cover', backgroundRepeat: "no-repeat", backgroundImage: `url("${thumbnail}")`, position: 'absolute', height: '100%', width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
												{/* <img src={thumbnail} style={{ scale: '0.8', margin: 'auto', marginRight: '-3px' }} /> */}
										</Box>
								</Grid>
								<Grid container xs={7} sx={{ height: '80px', position: 'relative', }}>
										<Grid container xs={12} sx={{ ml: '15px', pl: '5px', pr: '24px', flexDirection: 'column', height: '80px', zIndex: 3, background: '#FFF' }} justifyContent='center' alignItems='flex-start'>
												<Box sx={{ fontSize: '16px', fontFamily: 'Poppins-Medium', lineHeight: '1.2em', textAlign: 'left' }}>{headingTitle}</Box>
												<Box sx={{ fontSize: '13px', fontFamily: 'Poppins', lineHeight: '1.2em', textAlign: 'left' }}>{subTitle}</Box>
												<Box sx={{ fontSize: '13px', fontFamily: 'Poppins', lineHeight: '1.2em', textAlign: 'left', fontStyle: 'italic' }}>{Math.round(duration / 60)} min</Box>
										</Grid>
								</Grid>
						</Box>
				</RSCardCont >
    )
}

export default WorkoutVideoContainer
