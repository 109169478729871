import { FC, useState, useEffect, useRef } from 'react'
import { useSearchParams } from 'react-router-dom'

import Grid from '@mui/material/Grid'

import { AuthPageProps } from '../../modules/types'
import WithAuth from '../../components/WithAuth'
import RSNavCont from '../../components/layout/RSNavCont'
import useRSTabs from '../../hooks/useRSTabs'
import useRSSearch from '../../hooks/useRSSearch'
import useQueryParams from '../../hooks/useQueryParams';
import { RSTabsDataProps } from '../../modules/types'
import Programs from '../programs/v2/Programs'
import ExerciseTab from './ExerciseTab'
import RunningTab from './RunningTab'
import RSHeaderCont from '../../components/layout/RSHeaderCont'
import useIsMobileScreen from '../../hooks/useIsMobileScreen'


const Explore: FC<AuthPageProps> = ({ hasLoaded, userInfo }) => {
	const initRef = useRef<boolean>(false);
	const { p_id, page, train } = useQueryParams()
	const { searchUI, searching, result, searchValue, resetSearch } = useRSSearch({})
	const [programsLoading, setProgramsLoading] = useState(false)
	const [showSplash, setShowSplash] = useState(false)
	const [searchResult, setSearchResult] = useState<Array<any> | undefined>()
	const [searchParams, setSearchParams] = useSearchParams();

	const isMobile = useIsMobileScreen()

	const tabList: RSTabsDataProps = [{
		title: 'RUNNING',
		body: <RunningTab openTrain={train ? true : false} />
	}, {
		title: 'PROGRAMS',
		body: <Programs userInfo={userInfo} setMainLoading={setProgramsLoading} setMainSplash={setShowSplash} />
	}, {
		title: 'EXERCISES',
		body: <ExerciseTab userInfo={userInfo} searchResult={searchResult} searchValue={searchValue} />
	}]

	const { tabsUI, tab, setTab } = useRSTabs({
		data: tabList,
		btnContSX: { width: '100%', justifyContent: 'space-evenly' },
		btnTitleSX: { fontFamily: 'Poppins-Bold !important', fontSize: '14px', letterSpacing: 'normal' }
	})

	useEffect(() => {
		p_id && setTab(1)
	}, [p_id])

	useEffect(() => {
		page && setTab(parseInt(page))
	}, [page])


	useEffect(() => {
		if (!initRef.current) {
			initRef.current = true
		} else if (!tab) {
			searchParams.delete('p_id')
			searchParams.delete('page')
			searchParams.delete('train')
			setSearchParams(searchParams)
		}
		resetSearch()
	}, [tab])

	useEffect(() => {
		!searching && result && setSearchResult(result)
	}, [result, searching])

	return (
		<RSNavCont loaderProps={{ active: programsLoading || !hasLoaded || searching }} hideHeader={isMobile} hideFooter={showSplash}>
			{isMobile && (
				<RSHeaderCont>
					<Grid container sx={{ fontSize: '16px', fontFamily: 'Poppins-Bold' }} justifyContent='center' alignItems='center'>
						Explore
					</Grid>
				</RSHeaderCont>
			)}

			<Grid container sx={{ pt: 2 }}>
				{tabsUI}
				{tab === 2 && searchUI}
			</Grid>
		</RSNavCont>
	)
}

export default WithAuth(Explore);
