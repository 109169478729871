/* eslint-disable @typescript-eslint/no-unused-vars */
import { FC } from 'react';
import AudioPlayer from 'react-h5-audio-player';
import 'react-h5-audio-player/lib/styles.css';
import { webUrl } from '../../modules/apiConsume';

const AudioPlayerComponent: FC<{ file: string, title: string, handlePlay?: Function }> = ({ file, title, handlePlay }) => {
    const mediaPath = `https://app.runsmartonline.com/api/static/files/${file}`;
    // const mediaPath = `http://localhost:4000/api/static/files/${file}`;
    return (
        <>
            <AudioPlayer
                autoPlay={false}
                autoPlayAfterSrcChange={false}
                header={<p className="w-100 text-center mb-n1">{title}</p>}
                src={mediaPath}
                onPlay={() => handlePlay?.()}
                preload='auto'
            />
        </>
    )
}



export default AudioPlayerComponent
