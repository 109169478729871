/* eslint-disable @typescript-eslint/no-unused-vars */
import { FC, Key, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import moment from 'moment'

import Grid from '@mui/material/Grid'
import Box from '@mui/material/Box'
import { Button } from '@mui/material';

import RaceBuild from '../race/RaceBuild';
import RSChipButton from '../../components/buttons/RSChipButton';
import RSFlexCard from '../../components/cards/RSFlexCard';
import RSChurrosButton from '../../components/buttons/RSChurrosButton';
import RSButton from '../../components/buttons/RSButton';
import RSDrawer from '../../components/layout/RSDrawer'
import raceSelection from '../training-plan/build/RaceSelection.json'
import { IRaceType } from '../training-plan/build/RaceType';
import { RaceOptions } from '../training-plan/build/RaceOptions';
import useStore from '../../store/useStore';
import { ITrainingPlanStore } from '../../store/createTrainingPlanStore';
import affiliateToken from '../../modules/defaultAffiliateToken.json';
import { DISABLED_RACE_TYPES } from '../../modules/values'
import { AccountProfile } from '../../api/v2/account/Account.types'
import useIsMobileScreen from '../../hooks/useIsMobileScreen'

export type RaceEventDetailsProps = {
    accountProfile: AccountProfile,
    userInfo?: any,
    details: any,
    logo?: any,
    stepOpen: any
    disabledRaces?: any
}

const RaceEventDetails: FC<RaceEventDetailsProps> = ({ accountProfile, details, stepOpen, userInfo, disabledRaces }) => {
    const [raceTitle, setRaceTitle] = useState('')
    const [openOops, setOpenOops] = useState(false)
    const { name, place, date, dateEnd, desc, homepage, type, logo, datePerRaceType } = details
    const { setSelectedRace } = useStore((state: ITrainingPlanStore) => state)
    const [selectedRaceDistance, setSelectedRaceDistance] = useState('')
    const [expandEventDescription, setExpandEventDescription] = useState(false)
    const [isLoading, setIsLoading] = useState(true)
    const navigate = useNavigate()

    const isMobile = useIsMobileScreen()

    const img = logo || '/img-new/race-events/marathon_placeholder.png'

    const affiliateTokenRunsignup = '?aflt_token=' + affiliateToken.RunSignup.toString()

		const gender = accountProfile?.gender === 'Male' ? 'man' : 'woman'

    useEffect(() => { if (type.length == 1) setSelectedRaceDistance(type[0]) }, [])

    const get30Char = (text?: string) => {
        if (text && text?.length > 30) {
            let newName = ''
            let charLength = 0
            for (let i = 0; i < text.length; i++) {
                newName += text[i]
                if (text[i] !== ' ')
                    charLength++
                if (charLength === 30) {
                    newName += (i + 1 < text.length ? '...' : '')
                    break
                }
            }
            return newName
        } else
            return text
    }

    const cleanUpText = (text: string) => {
        let newText = text.replace(/<[^>]*>/g, ' ');
        newText = newText.replace(/&nbsp;/g, '\n\n');
        newText = newText.replace(/click here/g, 'Go to our site');
        const convertedText = new DOMParser().parseFromString(newText, 'text/html');
        // newText = newText.replace(/&nbsp;/g, '\n');
        // newText = newText.replace(/&amp;/g, '&');
        // newText = newText.replace(/&quot;/g, '"');
        // newText = newText.replace(/&apos;/g, "'");
        // newText = newText.replace(/&lt;/g, '<');
        // newText = newText.replace(/&gt;/g, '>');
        // newText = newText.replace(/&ldquo;/g, '"');
        // newText = newText.replace(/&#39;/g, '\'');
        return convertedText.body.textContent || ''
    }

    const formatDateTime = (dateTimeString: string) => {
        const formattedDateTime = new Date(dateTimeString).toLocaleString('en-US', {
            weekday: 'long',
            year: 'numeric',
            month: 'long',
            day: 'numeric',
            // hour: 'numeric',
            // minute: 'numeric',
            // second: 'numeric',
            // hour12: true,
        });

        return formattedDateTime
    }

    const onErrorLoadImg = () => {
        return (e: any) => {
            // e.target.src = './images/icons/runsmart-guy.svg'
            e.target.src = './img-new/race-events/marathon_placeholder.png'
            e.target.style = 'scale: 0.8'
        }
    }

    const handleBuildPlan = (selectedRaceOption: IRaceType) => {
        setSelectedRace(selectedRaceOption)
        // navigate('/training-plan/build/step-2', { replace: true })
    }

    const handleRaceTitle = (raceTitle: any, type?: string) => {
        setSelectedRaceDistance(type || '')
        setRaceTitle(raceTitle)
        stepOpen(raceTitle ? true : false)
    }

    const checkSameDatePerRaceType = (datePerRaceType: any) => {
        const firstDate = datePerRaceType[0].date
        return datePerRaceType.every((item: any) => item.date === firstDate);
    }

    const renderRaceDistances = () => type.map((rd: any, rdI: number) => {
        const isDisabled = disabledRaces && disabledRaces.length ? disabledRaces.indexOf(rd.toLowerCase()) > -1 : false
        const rs = raceSelection.find(rs => rs.title.toUpperCase().includes(rd.toUpperCase()))
        const distanceValue = rs?.distanceValue || 0
        const notAllowed = moment(details?.date).diff(moment(), 'days') < 13
        return rs && <RSChurrosButton
            disabled={isDisabled}
            key={rdI}
            symbol={rs.logo}
            title={rs.subtitle}
            dipColor={rs.color}
            onClick={() => (notAllowed || rs.not_available) ? setOpenOops(true) : (handleRaceTitle(distanceValue > 13 ? rs.subtitle : rs.title, rd))}
        />
    })

    const renderRaceEventDetailsOLD = () => {
        return <>

            <Grid><h3>{name}</h3></Grid>

            <Box sx={{ mt: '1rem', mr: '15px', mb: '0.5rem', color: '#010101' }}>

                <Grid sx={{ fontSize: '1.1rem !important' }}>{place}</Grid>
                <Grid sx={{ fontSize: '1rem !important', fontFamily: 'Poppins-Light !important' }}>Starts: {date} </Grid>
                <Grid sx={{ fontSize: '1rem !important', fontFamily: 'Poppins-Light !important' }}>Ends: {dateEnd}</Grid>

            </Box>

            <Box sx={{ backgroundColor: '#eee' }}>

                <Grid sx={{ pt: '1rem', px: '1rem', borderTop: '1px solid #bbb', lineHeight: '1.2em !important' }}> Plan for this marathon by pressing <br /> one of the distances below: </Grid>

                <Grid sx={{ width: 'full', display: 'flex', flexDirection: 'row', borderBottom: '1px solid #ccc', py: '1rem', px: '1rem' }}>
                    {type?.map((type: any, typeI: number) => (<>

                        <Button key={typeI + 'type'}
                            sx={{ mx: '5px', height: '30px', width: '90px', mt: '3px', borderRadius: '6px', fontSize: '18px !important', fontFamily: 'Poppins-Medium !important', lineHeight: '1em !important', backgroundColor: 'brown', color: '#fff', alignContent: 'center', justifyContent: 'center', textAlign: 'center' }}>
                            {type}
                        </Button>

                    </>))}
                </Grid>
            </Box>


            <Grid sx={{ mt: '1.4rem', mb: '1rem', display: 'flex', flexDirection: 'column', alignContent: 'center', justifyContent: 'center', textAlign: 'center' }}>

                {logo && <img src={logo} style={{ scale: '1.1', width: '50vw', maxWidth: '350px', borderRadius: '12px', margin: '1rem 0 2rem', alignSelf: 'center' }} />}

                {homepage &&
                    <a href={homepage} target='_blank' rel="noreferrer noopener">
                        <RSChipButton label='EVENT HOMEPAGE' sx={{ fontWeight: 'bold', width: '60vw', maxWidth: '400px', my: '5px', alignContent: 'center', justifyContent: 'center', textAlign: 'center' }}>
                            EVENT HOMEPAGE
                        </RSChipButton></a>}

            </Grid>

            <Grid sx={{ mt: '2rem', whiteSpace: 'pre-line' }}><p>{desc}</p></Grid>

            {/* Organizer: {organizer} */}
        </>
    }

    return <Box sx={{ p: '2rem' }}>

        <Box sx={{ mb: 3, justifyContent: 'center', alignItems: 'center', display: 'flex', flexDirection: 'row' }}>

            <div style={{ position: 'relative', display: 'flex', width: '45%', minWidth: '107.3px', minHeight: '107.3px', height: '100%', justifyContent: 'center', alignItems: 'center' }} >
                {isLoading && <Box className='loading-days toRight' sx={{ border: 'none !important', marginRight: '10px' }} /> }
								<img onLoad={()=> setIsLoading(false)} src={img} style={{ marginLeft: '4px', marginRight: '14px', borderRadius: '12px' }} onError={onErrorLoadImg()} />
            </div>

            <Box sx={{ display: 'flex', flexDirection: 'column', width: '100%', marginLeft: '12px' }}>
                <Grid><h3>{get30Char(name)}</h3></Grid>
                <Grid sx={{ fontSize: '1rem !important' }}>
                    <p style={{ fontSize: '1rem', fontFamily: 'Poppins-Light' }}>

                        {(datePerRaceType && datePerRaceType.length > 0 && !checkSameDatePerRaceType(datePerRaceType)) ? datePerRaceType.map((marathon: any, i: Key | null | undefined) => {
                            return <><span key={i} style={{ fontFamily: 'Poppins-Medium' }}>{marathon.type}: </span>{formatDateTime(marathon.date)}<br /></>
                        })
                            :
                            <><span style={{ fontFamily: 'Poppins-Medium' }}>Race Date:</span> {formatDateTime(date)}</>
                        }

                    </p>
                </Grid>
            </Box>
        </Box>

        <Box sx={{ mb: 3 }}>
            <Grid sx={{ mb: 2, fontSize: '16px', fontFamily: 'Poppins-Medium' }} >
                About the Event
            </Grid>

            <Grid sx={{ maxHeight: expandEventDescription ? '100rem' : '5.4rem', lineHeight: '1.4rem', overflow: 'hidden', transitionProperty: 'max-height', transitionDuration: '0.6s' }}>
                <p>{cleanUpText(desc)}</p>
            </Grid>

            <Grid sx={{ pt: '3px', color: 'blue' }}
                onClick={() => { setExpandEventDescription(!expandEventDescription) }}>{expandEventDescription ? '< Show Less' : 'Read More >'}</Grid>
        </Box>

        <Box>
            <Grid sx={{ mb: 1, fontSize: '16px', fontFamily: 'Poppins-Medium' }} >
                Choose Event Distance
            </Grid>

            <Grid container xs={12} sx={{ lineHeight: '1.2em', fontFamily: 'Poppins-Light !important', fontSize: '18px' }} justifyContent="flex-start" alignItems="center">
                <RSFlexCard videoList={renderRaceDistances()} sx={{ pt: 0, my: 0, overflow: 'hidden' }} />
            </Grid>
        </Box>

        {/*<Box sx={{ mt: '4rem' }}>
            <RSButton disabled secondary={ true || selectedRaceDistance == ''}
                onClick={() => { selectedRaceDistance && handleBuildPlan(RaceOptions.find(x => x.title.toUpperCase().includes(selectedRaceDistance)) as IRaceType) }}>
                {'Build a Plan (upcoming feature)*'}
            </RSButton>
        </Box>*/}
        {/* {console.log(accountProfile?.user_id ? accountProfile : { user_id: userInfo?.account_id })} */}
        <RSDrawer bottom fullHeight fullWidth popUp popUpHeight='600px' sx={{ borderRadius: 0 }} open={raceTitle ? true : false} onClose={() => handleRaceTitle('')}>
            <RaceBuild popUpHeight={'600px'} accountProfile={accountProfile?.user_id ? accountProfile : { user_id: userInfo?.account_id }} raceDetails={{ ...details, raceTitle, selectedRaceDistance }} onClose={() => handleRaceTitle('')} />
        </RSDrawer>

        <RSDrawer noClose className={`sorry-pop-up ${gender}`} bottom maxHeight={isMobile ? '40%' : '100%'} popUpWidth='400px' popUpHeight='650px' open={openOops} onClose={() => setOpenOops(false)}>

            {!isMobile && <Box className={`sorry-pop-up-desktop ${gender}`} />}
            <Grid container className={isMobile ? '' : `sorry-message ${gender}`} xs={12} sx={{ flexDirection: 'column', height: isMobile ? '100%' : '250px'}} justifyContent='space-between' alignItems='center'>
                <Box>
                    <Box sx={{ color: 'rgb(220,53,69) !important', width: '100%', fontSize: '24px', fontFamily: 'Poppins-Bold', p: 2, display: 'flex', justifyContent: 'center' }}>
                        Run Plan Not Available
                    </Box>
                    <Box sx={{ fontSize: '18px', fontFamily: 'Poppins', display: 'flex', justifyContent: 'center', px: 2, lineHeight: '1.2em', textAlign: 'center' }}>
                        {`Given how soon this event is, we're not able to build a plan.`}
                    </Box>
                </Box>
                <RSButton onClick={() => setOpenOops(false)} sx={{ width: isMobile ? '100%' : '200px', mb: isMobile ? 0 : 3 }}>
                    Go Back
                </RSButton>
            </Grid>
        </RSDrawer>

        {/* {renderRaceEventDetailsOLD()} */}
    </Box>
}

export default RaceEventDetails;
