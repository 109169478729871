/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/no-empty-function */
import { useState, useEffect, useRef } from 'react'
import { useNavigate } from 'react-router-dom'
import moment from 'moment';
import is_numeric from 'locutus/php/var/is_numeric'

import { motion, AnimatePresence } from "framer-motion";
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import SwipeableDrawer from '@mui/material/SwipeableDrawer';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import IconButton from '@mui/material/IconButton';
import Button from '@mui/material/Button';
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import WeatherIcon from '@mui/icons-material/WbSunny';
import ArrowForward from '@mui/icons-material/ArrowForwardIos';
import ArrowBack from '@mui/icons-material/ArrowBackIosNew'
import DArrowForward from '@mui/icons-material/KeyboardDoubleArrowRight';
import AddCircleIcon from '@mui/icons-material/AddCircleOutline';
import StarFilled from '@mui/icons-material/StarOutlined';
import StarEmpty from '@mui/icons-material/StarOutlineOutlined';
import StarHalf from '@mui/icons-material/StarHalfOutlined';
import CheckIcon from '@mui/icons-material/Check';
import SportsScoreIcon from '@mui/icons-material/SportsScore';
import { CheckCircleOutlineRounded, HighlightOffOutlined } from '@mui/icons-material';
import recoverVideos from '../../pages/recover/DailyRecoveryVideos.json'

import AddMaintenancePlan from './AddMaintenancePlan';
import AppleWatchWorkout from './AppleWatchWorkout';
import AppleWatchWorkoutLaps from './AppleWatchWorkoutLaps';
import CompletePlan from './CompletePlan'
import RecoveryVideos from './RecoveryVideos';
import RecoveryAssessments from './RecoveryAssessments';
import useStore from '../../store/useStore'
import { ITrainingPlanStore } from '../../store/createTrainingPlanStore'
import { RaceResult, UserNotesRecord, UserRaceResults } from '../../api/v2/types'
import { titleCase } from '../../modules/miscUtils'
import { CreateUserCustomPace, UpdateUserCustomPace, CreateUserNotes, UpdateUserNotes, CreateRaceResult, UpdateRaceResult, GetUserInfo, getGarminSyncData, CreateChangeLog } from '../../modules/trainingPlanActions'
import useIsMobileScreen from '../../hooks/useIsMobileScreen'
import useTrainingPlanUtils from '../../hooks/useTrainingPlanUtils'
import useBuildTrainingPlan from '../../pages/training-plan/hooks/useBuildTrainingPlan'
import raceSelection from '../../pages/training-plan/build/RaceSelection.json'
import IntegrationsMainPage from '../../pages/integrations/IntegrationsMainPage';
import { navReq, putReq } from '../../modules/apiConsume';
import { Video } from '../../modules/videos/allVideos';
import defaultExercises from './default-exercise.json'
import RSButton from '../../components/buttons/RSButton'
import RSFlexCard from '../../components/cards/RSFlexCard'
import RSVideoCard from '../../components/cards/RSVideoCard'
import RSDrawer from '../../components/layout/RSDrawer'
import WorkoutVideoContainer from '../../pages/training-plan/components/WorkoutVideoContainer';
import numberHelperUtil from '../../utils/numberHelperUtil';
import textHelperUtils from '../../utils/textHelperUtils';
import { IAppleHealthKitStore } from '../../store/createAppleHealthKitStore';
import { AppleHealthActivity } from '../../api/v2/apple-health-kit/apple-health-kit.types';
import { CreateAppleHealtActivity, GetAppleHealtActivity, UpdateAppleHealtActivity } from '../../modules/appleHealthKitActions';
import useHealthActivity, { WorkoutData } from '../../hooks/useHealthActivity';
import useDeviceDetect from '../../hooks/useDeviceDetect';
import SurfaceTypes from './SurfaceTypes';
import RSIcon from '../../components/icons/RSIcon'
import { changeDaySurfaceType, getSurfaceTypeByPlanId } from '../../modules/trainingPlanActions'
const variants = {
	enter: (direction: number) => {
		return {
			opacity: 0,
			x: direction > 0 ? "100%" : "-100%"
		};
	},
	center: {
		opacity: 1,
		x: 0
	},
	exit: (direction: number) => {
		return {
			opacity: 0,
			x: direction > 0 ? "-100%" : "100%"
		};
	}
};

const enabledTestFeatures = true
const defaultSurfaceType = 'road' || 'tread'

const DailyActivity = (props: any) => {
	const navigate = useNavigate()
	const [initTab, setInitTab] = useState(0)
	const [activeTab, setActiveTab] = useState(0)
	const prevActiveTab = useRef(0)
	const [isEditing, setIsEditing] = useState(false)
	const [miles, setMiles] = useState('')
	const [paces, setPaces] = useState<any>({})
	const [isMarkComplete, setIsMarkComplete] = useState(false)
	const [isTakingNotes, setIsTakingNotes] = useState(false)
	const [comment, setComment] = useState('')
	const [newComment, setNewComment] = useState('')
	const [currentId, setCurrentId] = useState(undefined)
	const [isDayComplete, setIsDayComplete] = useState(0)
	const [showWeatherAlert, setShowWeatherAlert] = useState(false)
	const [weatherAlertAdjustList, setWeatherAlertAdjustList] = useState<any>([])
	const [checkedAdjustPlan, setCheckedAdjustPlan] = useState<any>({})
	const [checkAll, setCheckAll] = useState(true)
	const [isValid, setIsValid] = useState(false)
	const [hasRecoveryAssessment, setHasRecoveryAssessment] = useState(false)
	const [workoutVideos, setWorkoutVideos] = useState<any>({ videos1: [], videos2: [] })
	const { currentDayActivity, currentLocation } = useStore((state: ITrainingPlanStore) => state)
	const { computeKmPace, ConvertMetersToMiles, ConvertS2M, getGarminPace, ConvertS2TimeObject } = useTrainingPlanUtils();
	const [isAfterCurrentDate, setIsAfterCurrentDate] = useState(false)
	const [rate, setRate] = useState(0)
	const [lapsData, setLapsData] = useState<any>([])
	const [updateRaceResult, setUpdateRaceResult] = useState(false)
	const [showAssessment, setShowAssessment] = useState(false)
	const { getWTAValue, weatherAdjust, getWeatherData, getAdjustmentValue } = useBuildTrainingPlan();
	const [invalidGarminPermission, setInvalidGarminPermission] = useState(false)
	const [hasSyncData, setHasSyncData] = useState(false)
	const [addMaintenance, setAddMaintenance] = useState(false)
	const [doNotAddMaintenance, setDoNotAddMaintenance] = useState(false)
	const [workOutDuration, setWorkOutDuration] = useState<Array<any> | null>(null)
	const [durationList1, setDurationList1] = useState<Array<any> | null>(null)
	const [durationList2, setDurationList2] = useState<Array<any> | null>(null)
	const isMobile = useIsMobileScreen();
	const { findNumberWord } = numberHelperUtil()
	const { capitalizeEachWord } = textHelperUtils()
	const { setPermission, permission: appleHealthPermission, dataGenerated, setDataGenerated } = useStore((state: IAppleHealthKitStore) => state)
	const [appleHealthActivity, setAppleHealthActivity] = useState<AppleHealthActivity | undefined>()
	const [appleHealthActivityLaps, setAppleHealthActivityLaps] = useState<any | undefined>()
	const [isLoadingAppleHealth, setIsLoadingAppleHealth] = useState<boolean>(false)
	const [displayCrossTrainingModal, setDisplayCrossTrainingModal] = useState(false)
	const [hasAppleHealthData, setHasAppleHealthData] = useState<boolean>(false)
	const { getWorkoutLaps } = useHealthActivity()
	// >> Surface Related
	const [activeSurface, setActiveSurface] = useState('road')
	const [prevSurfaceType, setPrevSurfaceType] = useState('')
	const [prevSurfaceModifier, setPrevSurfaceModifier] = useState(1)
	const [surfaceModifier, setSurfaceModifier] = useState(1)
	const [surfaceUnit, setSurfaceUnit] = useState('TIME')
	const [openSurface, setOpenSurface] = useState(false)
	const [updatePaceValues, setUpdatePaceValues] = useState(false)
	const [currentTotalDistance, setCurrentTotalDistance] = useState(0)
	const [intervalPaces, setIntervalPaces] = useState<any>([])
	const [allDaySurfaceType, setAllDaySurfaceType] = useState<any>([])
	const [activeSurfaceIcon, setActiveSurfaceIcon] = useState()
	const [updateCurrentSurfaceType, setUpdateCurrentSurfaceType] = useState(false)
	const [defaultPaces, setDefaultPaces] = useState<any>([])
	const [displayTread, setDisplayTread] = useState(false)
	const [isSurfaceAdjustedPaceValues, setIsSurfaceAdjustedPaceValues] = useState(false)
	let currentPaces = {}
	// <<

	const requestKey = localStorage.getItem('deviceRequestKey')
	const { mobileType } = useDeviceDetect()

	const isNativeIOS = requestKey && mobileType.toLowerCase() === 'ios'

	const { userNotes, allUserNotes, userInfo, dayData, selectedRace, getDailyActivityDisplay, getColorCode, getWorkoutVideoDetails, handleOpenVideo, distanceInterpret, convertPaceBase, getConvertedPace, userCustomPaces, getOverridePaceData, getAllUserNotes, getUserNotes, setLoadingValues, currentDayActivities, userGarminDetails, weekDays, createGarminWorkout, groupPaces, setSelectedDay, setSelectedWeek, selectedDay, selectedWeek, isForward, setChangeLoc, finishedGarminSync, hasGarminSyncError, setFinishedGarminSync, getRaceResult, raceResults, loadingValues, accountInfo, loadingNewData } = props
	const isRaceDay = userInfo?.race_date && dayData?.date && moment(userInfo?.race_date)?.format('YYYY MM DD') === moment(moment(dayData?.date))?.format('YYYY MM DD') && userInfo?.type !== 4
	let isLastDay = userInfo?.race_date && dayData?.date && moment(dayData?.date)?.isAfter(moment(moment(userInfo?.race_date).format('YYYY/MM/DD'))) && userInfo?.type !== 4
	isLastDay = userInfo?.race_date && userInfo?.type === 4 && userInfo?.raceResults?.id && moment(dayData?.date)?.isSameOrAfter(moment(moment(userInfo?.race_date).format('YYYY/MM/DD'))) ? true : isLastDay


	const handleUpdateSurfaceType = async () => {

		// console.log('----changing surface type----')

		let maxWeeks: [] | undefined = []

		if (userInfo?.raceResults) {
			maxWeeks = JSON.parse(rr?.recoveryPlan?.recoveryType?.max_week_percentage as string | "[]");
		}

		const overrideDayActivity = getDailyActivityDisplay(
			dayData,
			userInfo,
			dayData?.marathonPaces,
			selectedRace?.value as number,
			dayData?.marathon?.race_day,
			allUserNotes)

		const dailyActivity = overrideDayActivity || allUserNotes ? overrideDayActivity?.split('|') : currentDayActivity?.split('|')
		const isKm = userInfo?.km === "Y" ? "KMS" : "MILES";
		const isKm2 = userInfo?.km === "Y" ? "KM" : "MI";
		let trainVal = dailyActivity?.[0]?.includes(isKm) ? dailyActivity[0] : dailyActivity[0]?.replace(isKm2, isKm)
		trainVal = trainVal?.trim()
		trainVal = trainVal?.split(' ')?.map((tv: any) => is_numeric(tv) ? tv : tv.charAt(0) + tv.slice(1).toLowerCase())?.join(' ')
		const valSplit = trainVal.split(' ')

		let distance = valSplit[0]
		const isNumber = is_numeric(distance || valSplit[0])

		const hasCustomDistance = allUserNotes?.find((x: any) => x?.marid == dayData?.marathon?.id)?.distance ? true : false

		distance = !hasCustomDistance && isNumber && rr && maxWeeks ? (distance * maxWeeks[selectedWeek?.key - 1]).toFixed(1) : valSplit[0]

		setCurrentTotalDistance(distance)


		setIntervalPaces([])

		// console.log('selectedRace: ', selectedRace)

		dayData?.marathonPaces?.map((val: any, idx: number) => {

			
			let paceValue = getConvertedPace(val).value[0]
			const isDefaultSurface = surfaceModifier === 1 || activeSurface === defaultSurfaceType

			// console.log(': activeSurface = ', activeSurface, ': surfaceModifier = ', surfaceModifier)

			// enable this if you want to save edits in default pace when reverting back to default surface
			// if (prevSurfaceType == defaultSurfaceType) {
			// 	initialPaces.push({ pid: val.paceId, pace: String(paceValue) })
			// }


			if (isDefaultSurface) {

				// updateCurrentSurfaceType && console.log(': dayData.week = ', dayData?.marathon?.week, ': selectedWeek = ', selectedWeek.key)
				// updateCurrentSurfaceType && console.log(': dayData.day = ', dayData?.marathon?.day, ': selectedDay = ', selectedDay)

				// const originalInitialValues = JSON.parse(allDaySurfaceType.find((x: any) => x.day === dayData?.marathon?.day && x.week === dayData?.marathon?.week && userInfo?.uid === x.plan_id)?.original_pace || '[]')
				const dayNum = moment(dayData?.date).day() + 1 || moment(dayData?.date).day() + 1 || selectedDay
				const originalInitialValues = JSON.parse(allDaySurfaceType?.find((e: any) => userInfo?.uid === e.plan_id && (e.activity_id === dayData?.marathon?.id))?.original_pace || '[]')

				// console.log(': originalInitialValues = ', originalInitialValues)

				// choose whether to get from DB or from saved state
				// enable this to get from saved state first (edits get carried over surface change pace)
				// const revertToOriginalPaceValue = defaultPaces.length == 0 ? defaultPaces[idx]?.pace : originalInitialValues && originalInitialValues[idx]?.pace
				// enable this to get from DB first (when reverting back to default pace, original pace from DB is used)
				const revertToOriginalPaceValue = originalInitialValues.length > 0 ? originalInitialValues[idx]?.pace : defaultPaces.length > 0 && defaultPaces[idx]?.pace

				// originalInitialValues.length > 0 && console.log('>>> getting original pace value from DB: ', revertToOriginalPaceValue)
				// originalInitialValues.length == 0 && defaultPaces.length > 0 && console.log('>>> getting original pace value from state: ', revertToOriginalPaceValue)

				if (revertToOriginalPaceValue) {
					paceValue = revertToOriginalPaceValue
					// console.log('>>> switching back to default values: ', paceValue)
				}

			}
			
			if (val.multiplier){
				paceValue = revertedMultipliedPace(paceValue, val.multiplier)
			}



			const currentMinVal = Number(paceValue.split(' ')[0]?.replace('m', '')) || 0
			const currentSecVal = Number(paceValue.split(' ')[1]?.replace('s', '')) || 0

			const totalMins = currentMinVal + (1 / (60 / currentSecVal))

			const newTotalMins = totalMins / prevSurfaceModifier * surfaceModifier
			const newLeftoverSecs = (newTotalMins % 1) * 60

			const shouldApplyDefaultValues = isDefaultSurface && prevSurfaceType !== defaultSurfaceType
			const newMinVal = shouldApplyDefaultValues ? currentMinVal.toString() : (Math.floor(newTotalMins)).toFixed(0)
			const newSecVal = shouldApplyDefaultValues ? (currentSecVal).toString() : newLeftoverSecs.toFixed(0)
			// const newMinVal = (Math.floor(newTotalMins)).toFixed(0)
			// const newSecVal = newLeftoverSecs.toFixed(0)

			// shouldApplyDefaultValues && console.log('>>> applying default values...', newMinVal, newSecVal)

			handlePacesChange(newMinVal, idx, 'm', val, getConvertedPace(val).value[0])

			handlePacesChange(newSecVal, idx, 's', val, getConvertedPace(val).value[0])

		})


		setUpdatePaceValues(true)
		setIsSurfaceAdjustedPaceValues(true)

		setPrevSurfaceModifier(surfaceModifier)
		// setPrevSurfaceType(activeSurface)

		// moved after successful updating paces
		// setUpdateCurrentSurfaceType(true)

	}


	const handlePacesChange = (e: any, parentI: any, childI: any, val: any, defaultValue: any) => {

		let inputVal = e.target?.value ? e.target.value : e

		inputVal = isNaN(Number(inputVal)) ? '' : inputVal

		defaultValue = defaultValue.replaceAll('[object', '')

		if (!(childI === 's' && Number(inputVal) > 59)) {
			let newVal = inputVal !== '' ? `${inputVal}${childI}|` : defaultValue.split(' ').find((c: any) => c.includes(childI))
			newVal = inputVal === '0' ? `${childI}|` : newVal
			const dSplit = defaultValue.split(' ')
			const isExisting = dSplit.find((x: any) => x.includes(childI))
			const dPace = isExisting ? dSplit.map((x: string) => x.includes(childI) ? newVal : x) : (childI === 's' ? [...dSplit, newVal] : [newVal, ...dSplit])
			const customPace = userCustomPaces.find((x: any) => x.pid == val.paceId)
			const paceData = customPace ? { ...customPace, pace: dPace.join(' ').trim(), update: true } : { pid: val.paceId, uid: userInfo?.uid, pace: dPace.join(' ').trim() }
			paceData.defaultValue = defaultValue
			const pacesClone = Object.keys(currentPaces).length === 0 ? { ...paces } : { ...currentPaces }
			const parentClone = pacesClone?.[parentI]

			if (parentClone) {
				const pCSplit = parentClone.pace.split(' ')
				const isPCExisting = pCSplit.find((x: any) => x.includes(childI))
				const splitStored = isPCExisting ? pCSplit.map((x: string) => x.includes(childI) ? newVal : x) : (childI === 's' ? [...pCSplit, newVal] : [newVal, ...pCSplit])
				paceData.pace = splitStored.join(' ').trim()
			}

			pacesClone[parentI] = paceData
			currentPaces = pacesClone
			setPaces(pacesClone)
		}
	}

	const handleUpdateRecovery = async (mobility: Array<string>, strength: Array<string>) => {
		const data = {
			id: userInfo?.raceResults?.recoveryPlan?.id,
			strength_video_ids: strength,
			mobility_video_ids: mobility
		}
		const request = await putReq('/v2/recovery-plan', data)
		if (request.status == "ok") {
			await getRaceResult(undefined, true)
			setHasRecoveryAssessment(true)
		}
	}

	const handleMarkComplete = async (woComplete: boolean, isCommentOnly?: boolean) => {
		setLoadingValues(true)
		const currentNote = userNotes?.find((x: any) => x.marid == dayData?.marathon?.id && x.uid == userInfo?.uid)

		let nData = currentNote ? { ...currentNote } : {
			marid: dayData?.marathon?.id,
			uid: userInfo?.uid,
			comments: "",
			heart_rate: "",
			distance: null,
			pace: null,
			wo_complete: 0
		}
		const insetThis = isCommentOnly ? {} : { wo_complete: woComplete as boolean }
		nData = { ...nData, ...insetThis, comments: newComment }
		currentNote ? await UpdateUserNotes(nData as UserNotesRecord) : await CreateUserNotes(nData as UserNotesRecord)
		handleCloseComplete()
		handleCloseEditNotes()
		setLoadingValues(false)
	}

	const handleCompleteNSync = (i: number) => {
		if (i) handleMarkComplete(true)
		else {
			if (userGarminDetails?.permissions.includes('WORKOUT_IMPORT')) {
				createGarminWorkout()
			}
			else {
				setInvalidGarminPermission(true)
				setFinishedGarminSync(true)
			}
		}

	}

	const handleUpdatePlan = async () => {
		setLoadingValues(true)
		const capKeys = Object.keys(checkedAdjustPlan)
		for (const ck of capKeys) {
			if (checkedAdjustPlan[ck]) {
				const customPace = userCustomPaces.find((x: any) => x.pid == ck)
				if (customPace) {
					await UpdateUserCustomPace({ ...customPace, pace: checkedAdjustPlan[ck], update: true });
				} else {
					await CreateUserCustomPace({ pid: Number(ck), uid: userInfo?.uid, pace: checkedAdjustPlan[ck] });
				}
			}
		}
		getOverridePaceData(userInfo?.uid)
		setShowWeatherAlert(false)
		setLoadingValues(false)
	}

	const handleSubmit = async (currentDistance: number) => {

		setLoadingValues(true)
		const currentNote = userNotes?.find((x: any) => x.marid == dayData?.marathon?.id && x.uid == userInfo?.uid)
		if (miles && currentDayActivity?.split('|')?.[0].split(' ')?.[0] !== miles) {
			if (currentNote) {
				if (miles !== currentNote?.distance) {
					const dData = { ...currentNote, distance: userInfo?.km === 'Y' ? (+computeKmPace(+miles)?.toFixed(1)) : +miles }
					await UpdateUserNotes(dData as UserNotesRecord).then(async (res) => {
						if (res.status === 'ok') {
							await CreateChangeLog(
								{
									user_id: userInfo?.internal_id,
									plan_id: userInfo?.uid,
									change_type: 'CHANGE_DAY_DISTANCE',
									week: selectedWeek.key,
									day: selectedDay + 1,
									previous_value: String(currentDistance),
									new_value: String(miles),
								})
						}
					})
					getUserNotes(dayData)
					await getAllUserNotes()
				}
			} else {
				const dData = {
					marid: dayData?.marathon?.id,
					uid: userInfo?.uid, comments: "", heart_rate: "",
					distance: userInfo?.km === 'Y' ? (+computeKmPace(+miles)?.toFixed(1)) : +miles,
					pace: null,
					wo_complete: false
				}
				await CreateUserNotes(dData as UserNotesRecord).then(async (res) => {
					if (res.status === 'ok') {
						await CreateChangeLog(
							{
								user_id: userInfo?.internal_id,
								plan_id: userInfo?.uid,
								change_type: 'CHANGE_DAY_DISTANCE',
								week: selectedWeek.key,
								day: selectedDay + 1,
								previous_value: String(currentDistance),
								new_value: String(miles),
							})
					}
				})
				getUserNotes(dayData)
				await getAllUserNotes()
			}
		}

		const initialPaces: any = []
		let paceInterval = 0
		for (const d of Object.keys(paces)) {
			paceInterval++
			const pClone = paces[d]

			pClone.pace = pClone?.pace?.replaceAll('|', '')
			pClone.pace = pClone.pace.split(' ').map((pd: any) => (pd === 'm' || pd === 's') ? '' : pd).join(' ').trim()

			if (pClone.update) {
				await UpdateUserCustomPace(pClone).then(async (res) => {

					// console.log('>>> updated pace. previous value: ', pClone.defaultValue)

					if (defaultPaces.length == 0 && (activeSurface == defaultSurfaceType || prevSurfaceType == defaultSurfaceType)) {
						// console.log('>>> setting default paces: ', String(pClone?.defaultValue))
						initialPaces.push({ pid: pClone.pid, pace: String(pClone?.defaultValue) })
					}

					// if changed surface type
					if (updatePaceValues) setUpdateCurrentSurfaceType(true)

					// console.log('>>> updated pace. old value: ', String(pClone?.defaultValue))

					if (res.status === 'ok') {
						await CreateChangeLog(
							{
								user_id: userInfo?.internal_id,
								plan_id: userInfo?.uid,
								change_type: 'CHANGE_PACE_' + d,
								week: selectedWeek.key,
								day: selectedDay + 1,
								previous_value: String(pClone?.defaultValue),
								new_value: String(pClone?.pace),
							})
					}
				});
			} else {
				await CreateUserCustomPace(pClone).then(async (res) => {

					if (defaultPaces.length == 0 && (activeSurface == defaultSurfaceType || prevSurfaceType == defaultSurfaceType)) {
						// console.log('>>> setting default paces: ', String(pClone?.defaultValue))
						initialPaces.push({ pid: pClone.pid, pace: String(pClone?.defaultValue) })
					}

					// if changed surface type
					if (updatePaceValues) setUpdateCurrentSurfaceType(true)

					// console.log('>>> updated pace. old value: ', String(pClone?.defaultValue))

					if (res.status === 'ok') {
						await CreateChangeLog(
							{
								user_id: userInfo?.internal_id,
								plan_id: userInfo?.uid,
								change_type: 'CHANGE_PACE_' + d,
								week: selectedWeek.key,
								day: selectedDay + 1,
								previous_value: String(pClone?.defaultValue),
								new_value: String(pClone?.pace),
							})
					}
				});
			}
			getOverridePaceData(userInfo?.uid)

		}

		setDefaultPaces(initialPaces)

		setLoadingValues(false)
		handleCloseEdit()
	};

	const handleCloseEdit = () => {
		setMiles('')
		setPaces({})
		setIsEditing(false)
	}

	const handleCloseCrossTrainingModal = () => {
		setDisplayCrossTrainingModal(false)
	}

	const handleCloseEditNotes = () => {
		setNewComment(comment)
		setIsTakingNotes(false)
	}

	const handleChangeActive = (event: React.SyntheticEvent, val: number) => {
		prevActiveTab['current'] = activeTab - val
		setInitTab(val);
	};

	useEffect(() => {
		setActiveTab(initTab)
	}, [initTab])

	const handleCloseComplete = () => {
		setIsMarkComplete(false);
	};

	const getFoamRollingLink = (link: string) => {
		const linkA = link.replace('foam-rolling', '').split('#')[1].split('/')
		const convertedLink = `/${linkA[1]}/${linkA[3]}`
		return convertedLink
	}

	const timeToString = (props: any) => {
		const { hr, min, sec } = props
		return (hr || min || sec) ? `${hr ? `${hr.toString().padStart(2, '0')}:` : ''}${min || (!min && hr) ? `${min.toString().padStart(2, '0')}:` : ''}${(sec || !sec && (min || hr)) ? `${Math.floor(sec).toString().padStart(2, '0')}` : ''}` : ''
	}

	const isLongText = (val: any) => {
		return (val?.toString().toLowerCase().includes("repeat") || val?.toString().toLowerCase().includes("recover") || val?.toString().toLowerCase().includes("walk")) ? true : false
	}

	const getCurrentDistance = (marId: number) => {
		const distance = userNotes?.find((x: any) => x.marid == marId)?.distance
		return distance ? distance : undefined
	}

	const convertTextToIntervalTime = (notes: string, prevVal: any) => {
		const divisor = notes.includes("is equal") ? 1 : notes.includes("is half") ? 2 : notes.includes("is quarter") ? 4 : notes.includes("is 40%") ? 2.5 : 1

		const convertedTime = convertPaceBase(
			prevVal?.notes as string,
			getConvertedPace(prevVal).rawPace,
			userInfo?.km == 'Y' ? true : false, divisor)

		return convertedTime && notes.toLowerCase().includes('recovery') ? "Recover for " + convertedTime : undefined
	}

	const isGarminConnected = (userGarminDetails: any) => {
		if (userGarminDetails?.status == 1)
			return true
		else
			return false
	}

	const handleChangeMyLocation = () => {
		// setShowWeatherAlert(false)
		// setChangeLoc(true)
		navReq('/#/profile?changeLocation=1')
	}

	const handleGetGarminSyncData = async (dayData: any, abort: AbortController) => {
		if (dayData?.marathon?.id) {
			const garminSyncData = await getGarminSyncData(userInfo?.uid, dayData.marathon.id, abort.signal)

			if (garminSyncData?.data?.result && garminSyncData?.data?.result.length > 0) {
				setHasSyncData(true)
			}
			else
				setHasSyncData(false)
		}
		else
			setHasSyncData(false)
	}

	useEffect(() => {

		// updateCurrentSurfaceType && console.log('>>> updating current surface type...')

		// setLoadingValues(true)
		let originalPaces: any = []

		if (defaultPaces.length == 0) {
			for (const d of Object.keys(paces)) {
				const pClone = paces[d]
				originalPaces.push({ pid: pClone.pid, pace: String(pClone?.defaultValue) })
			}
			// updateCurrentSurfaceType && console.log('>>> no default paces found. setting original paces: ', originalPaces)
		} else originalPaces = [...defaultPaces]

		// updateCurrentSurfaceType && console.log('::: Original Paces: ', originalPaces)
		// updateCurrentSurfaceType && console.log(': dayData.week = ', dayData?.marathon?.week, ': selectedWeek = ', selectedWeek.key)
		// updateCurrentSurfaceType && console.log(': dayData.day = ', dayData?.marathon?.day, ': selectedDay = ', selectedDay + 1)


		updateCurrentSurfaceType && prevSurfaceType != activeSurface && changeDaySurfaceType({ plan_id: userInfo?.uid, user_id: userInfo?.internal_id, activity_id: dayData?.marathon?.id, week: selectedWeek.key, day: selectedDay + 1, surface_type: activeSurface, original_pace: JSON.stringify(originalPaces) })
			.then((res) => {
				// console.log('[[[ updated surface type ]]]', res)
				CreateChangeLog({
					user_id: userInfo?.internal_id,
					plan_id: userInfo?.uid,
					change_type: 'CHANGE_STATUS',
					week: selectedWeek.key,
					day: selectedDay + 1,
					previous_value: prevSurfaceType,
					new_value: activeSurface,
				}).then((res) => {
					// console.log('[[[ created change log ]]]', res)
				}).catch((err) => {
					console.log('[[[ error creating change log ]]]', err)
				})

				setUpdateCurrentSurfaceType(false)
				setLoadingValues(false)
			}).catch((err) => {
				console.log([[['error changing surface type']]], err)
				setUpdateCurrentSurfaceType(false)
				setLoadingValues(false)
			})

		updateCurrentSurfaceType && prevSurfaceType == activeSurface && setUpdateCurrentSurfaceType(false)

	}, [updateCurrentSurfaceType])

	useEffect(() => {
		// console.log('[[[ SWITCHED DAY ]]]')
		setWorkOutDuration(null)
		setDurationList1(null)
		setDurationList2(null)

		const abortController = new AbortController();
		if (dayData) {
			handleGetGarminSyncData(dayData, abortController)
		}
		return () => {
			abortController.abort();
		}
	}, [dayData])


	// changing surface type
	useEffect(() => {

		getSurfaceTypeByPlanId(userInfo?.uid).then((res: any) => {
			if (res.status === 'ok') {
				setAllDaySurfaceType(res.data.result)
			}
		}).catch((err) => {
			console.log('[[[error getting plan daily surface types]]]', err)
		})
	}, [dayData, updatePaceValues])


	useEffect(() => {

		const dayNum = moment(dayData?.date).day() + 1 || moment(dayData?.date).day() + 1 || selectedDay
		const initialSurfaceType = allDaySurfaceType?.find((e: any) => userInfo?.uid === e.plan_id && e.activity_id === dayData?.marathon?.id)?.surface_type
		initialSurfaceType ? setPrevSurfaceType(initialSurfaceType) : setPrevSurfaceType('road')

		initialSurfaceType && initialSurfaceType.includes('tread') ? setDisplayTread(true) : setDisplayTread(false)
	}, [allDaySurfaceType])


	useEffect(() => {
		const abortController = new AbortController();
		const adjustment = getAdjustmentValue() as number;
		if (!getWeatherData || getWeatherData?.length == 0 || adjustment <= 0 || getWTAValue() < 83)
			setIsValid(false)
		else if (getWeatherData && currentLocation && dayData?.marathonPaces.length > 0 && dayData?.marathonPaces[0].pace !== '') {
			setIsValid(true)
		} else
			setIsValid(false)
		return () => {
			abortController.abort();
		}
	}, [getWeatherData, dayData, currentLocation])

	useEffect(() => {
		if (userInfo && dayData) {
			setCurrentId(dayData?.marathon?.id)
			if (dayData?.workouts) {
				const workoutsList = dayData?.workouts
				const videos1: Array<any> = [];
				const videos2: Array<any> = [];
				for (const x in dayData?.workouts) {
					const workout = workoutsList[x];
					if (workout.mid == dayData.marathon?.id && workout.title) {
						if ((workout.title === "Cross Train" || workout.title !== "Cross Train" && workout.title.length > 4) && workout.link === "")
							videos1.push(workout)
						else {
							let id = workout?.link?.split('/').pop()
							id = id && Number(id) ? Number(id) : ''
							videos2.push({ ...workout, id })
						}
					}
				}
				getVideoDurations(videos2.map((x: any) => x.id), setWorkOutDuration, workOutDuration)
				setWorkoutVideos({ videos1, videos2 })
			}
			if (userNotes) {
				const currentUserNote = userNotes?.find((x: any) => x.marid == dayData?.marathon?.id && x.uid == userInfo?.uid)
				const userComment = currentUserNote?.comments ? currentUserNote.comments : ''
				const isWoComplete = currentUserNote?.wo_complete ? currentUserNote.wo_complete : 0
				if (userComment !== comment) {
					setComment(userComment)
					setNewComment(userComment)
				}
				setIsDayComplete(isWoComplete)
				isWoComplete && isRaceDay && raceResults?.length && setUpdateRaceResult(true)
			}

			if (dayData?.marathonPaces) {
				const mpFiltered = dayData.marathonPaces?.filter((x: any) => x.pace !== '')
				if (mpFiltered?.length > 0) {
					const newMpFiltered = []
					let newAdjustedPlan = {}
					for (const val of mpFiltered) {
						const adjustDefault = weatherAdjust(getConvertedPace(val, true)?.rawPace)
						const curConvertedPace = getConvertedPace(val)?.rawPace
						if (getTotalSec(curConvertedPace) < getTotalSec(adjustDefault)) {
							newAdjustedPlan = { ...newAdjustedPlan, [`${val.paceId}`]: adjustDefault }
							newMpFiltered.push({ ...val, adjustDefault, curConvertedPace })
						}
					}
					setCheckedAdjustPlan(newAdjustedPlan)
					setCheckAll(true)
					setWeatherAlertAdjustList(newMpFiltered)
				}
			}
		}
	}, [userInfo, dayData, userNotes, dayData?.workouts, dayData?.marathonPaces, allUserNotes, currentDayActivity])

	useEffect(() => {
		if (isGarminConnected(userGarminDetails)) {
			if (currentDayActivities?.length == 0) {
				setLapsData('')
				setInitTab(0)
			} else if (currentDayActivities?.length > 0) {
				const lapsData = currentDayActivities?.filter((e: any) => e?.laps?.filter((e2: any) => e2?.totalDistanceInMeters > 0 && e2?.timerDurationInSeconds > 0)?.length > 0)
				lapsData?.length > 0 ? setLapsData(lapsData) : setLapsData([])
				setInitTab(1)
			}
			// else if (appleHealthPermission?.status === "granted" && dataGenerated) {
			// 	alert(JSON.stringify(dataGenerated))
			// 	setInitTab(1)
			// }
		}
		else if (appleHealthActivity) {
			setInitTab(1)
		}
		else
			setInitTab(0)

	}, [currentDayActivities, dayData, userGarminDetails, appleHealthActivity])

	useEffect(() => {
		// console.log('[[[ switched day ]]]')
		setDefaultPaces([])

		const selectedDate = moment(weekDays?.[selectedWeek.key - 1]?.replaceAll('-', '/'))?.add(selectedDay, 'd')?.format('YYYY/MM/DD')
		compareDate(selectedDate)
	}, [selectedDay, selectedWeek])

	useEffect(() => {
		const permission = JSON.parse(localStorage.getItem('healthkit-permission') || '{}')
		if (permission.status === "granted") {
			setPermission(permission)
		}
		// console.log('>>> initial load paces: ', paces)
	}, [])

	useEffect(() => {
		if (userInfo?.internal_id)
			GetAppleHealtActivity(userInfo.internal_id, '').then((res) => {
				if (res && res?.data?.result?.length > 0) {
					setHasAppleHealthData(true)
				}
			})
	}, [userInfo])

	useEffect(() => {
		// alert(JSON.stringify(dataGenerated))
		const currentDate = selectedWeek?.week ? moment(selectedWeek?.week).add(selectedDay, 'd').format('YYYY-MM-DD') : ''
		if (currentDate && userInfo?.internal_id) {
			setIsLoadingAppleHealth(true)
			GetAppleHealtActivity(userInfo?.internal_id, currentDate).then((res) => {
				if (res) {
					const data = res?.data.result[0]
					if (data) {
						setAppleHealthActivity(data)
						const activity = JSON.parse(data.activity) as WorkoutData[]
						if (activity) {
							const laps = getWorkoutLaps(activity, userInfo)
							setAppleHealthActivityLaps(laps)
						}
					}

					else if (dataGenerated.length > 0 && currentDate === dataGenerated[0]?.dateParam) {
						try {
							const data: AppleHealthActivity = {
								user_id: userInfo?.internal_id,
								activity_date: currentDate,
								device_id: requestKey ? requestKey : '',
								activity: JSON.stringify(dataGenerated),
								raw_data: '',
							}

							CreateAppleHealtActivity(data).then(() => {
								GetAppleHealtActivity(userInfo?.internal_id, currentDate).then((res) => {
									if (res) {
										// setAppleHealthActivity(res?.data.result[0])
										const activity = JSON.parse(res?.data.result[0]?.activity) as WorkoutData[]
										if (activity) {
											const laps = getWorkoutLaps(activity, userInfo)
											setAppleHealthActivityLaps(laps)
										}
										setDataGenerated([])
									}
								})
							})
						} catch (error) {
							alert(JSON.stringify(error))
						}
					}
					else {
						setAppleHealthActivityLaps(undefined)
						setAppleHealthActivity(undefined)
					}
					setIsLoadingAppleHealth(false)
				}
			})
		}
	}, [selectedWeek, selectedDay, userInfo, requestKey, dataGenerated])


	useEffect(() => {
		const currentDate = selectedWeek?.week ? moment(selectedWeek?.week).add(selectedDay, 'd').format('YYYY-MM-DD') : ''
		if (appleHealthActivity && !isLoadingAppleHealth) {
			const activity = JSON.parse(appleHealthActivity.activity)
			if (activity && activity.length > 0 && dataGenerated.length > 0 && activity.length != dataGenerated.length) {
				const activityData = JSON.stringify(dataGenerated)
				const data = { ...appleHealthActivity, activity: activityData }
				UpdateAppleHealtActivity(data).then((res) => {
					if (res) {
						GetAppleHealtActivity(userInfo?.internal_id, currentDate).then((res) => {
							if (res) {
								setAppleHealthActivity(res?.data.result[0])
								const activity = JSON.parse(res?.data.result[0]?.activity) as WorkoutData[]
								if (activity) {
									const laps = getWorkoutLaps(activity)
									setAppleHealthActivityLaps(laps)
								}
								setDataGenerated([])
							}
						})
					}
				})
			}
		}
	}, [appleHealthActivity, dataGenerated, isLoadingAppleHealth, selectedWeek, selectedDay,])

	useEffect(() => {
		if (updatePaceValues) {

			handleSubmit(currentTotalDistance)
			setUpdatePaceValues(false)
			currentPaces = {}

		}
	}, [updatePaceValues])


	// useEffect(() => {
	// 	paces && paces != null && paces?.paces != prevPace && currentTotalDistance != 0 && !isEditing && handleSubmit(currentTotalDistance)
	// }, [paces] )

	const compareDate = (dateToCheck: string) => {
		const currentDate = moment(moment().format('YYYY/MM/DD'));
		const dateToCompare = moment(dateToCheck);
		setIsAfterCurrentDate(currentDate < dateToCompare)
	}

	const getTodayDate = () => {
		const aDate = weekDays[selectedWeek?.key - 1]
		if (aDate)
			return moment(aDate?.replaceAll('-', '/')).add(selectedDay + 1, 'd')
		else
			return moment()
	}

	const handleGoToDate = () => {
		const currentDayIndex = weekDays?.findIndex((w: any) => moment().isBetween(moment(w.replaceAll('-', '/')).subtract(1, 'days'), moment(w.replaceAll('-', '/')).add(7, 'd')))
		const newD = moment().diff(weekDays[currentDayIndex]?.replaceAll('-', '/'), 'd')
		const d = newD > 0 && newD < 7 ? newD : 0
		setSelectedWeek({ week: weekDays[currentDayIndex], key: currentDayIndex + 1 })
		setSelectedDay(d)
	}

	const todayDate = (date?: any) => {
		const today = moment().format("YYYY/MM/DD")
		const aDate = weekDays[selectedWeek?.key - 1]
		const sWeek = weekDays[0]?.replaceAll('-', '/')
		const cDate = date ? moment(date) : (aDate ? moment(aDate.replaceAll('-', '/')).add(selectedDay, 'd') : moment(sWeek).add((selectedDay ? selectedDay : 0) - 7, 'd'))
		const selectedDisplay = cDate.format("YYYY/MM/DD")
		const isNotToday = selectedDisplay && selectedDisplay !== today
		const displayDate = isNotToday ? moment(selectedDisplay).format('dddd, MMM DD, YYYY') : `Today, ${moment(today).format("MMM DD, YYYY")}`
		const isFuture = moment(selectedDisplay).isAfter(moment(today))
		return <Grid container xs={12} justifyContent="center" alignItems="center" sx={{ p: 0, height: '27px' }}>
			<Box sx={{ fontFamily: 'Poppins-Medium', fontSize: '16px', letterSpacing: 'normal', position: 'relative' }}>
				{userInfo?.is_active_plan && isNotToday && (isFuture ? <IconButton onClick={handleGoToDate} sx={{ position: 'absolute', color: '#010101', fontSize: '18px', p: 0, left: -25 }}>
					<i className="fa-regular fa-arrow-turn-left" />
				</IconButton> : <IconButton onClick={handleGoToDate} sx={{ position: 'absolute', color: '#010101', fontSize: '18px', p: 0, right: -25 }}>
					<i className="fa-regular fa-arrow-turn-right" />
				</IconButton>)}
				{displayDate}
			</Box>
		</Grid>
	}

	const getTotalSec = (time: string) => {
		let total = 0
		if (time) {
			for (const t of time.split(' ')) {
				if (t.includes('m')) {
					total += Number(t.replace('m', '')) * 60
				} else
					total += Number(t.replace('s', ''))
			}
		}

		return total
	}

	const handleCheckChange = (isThisChecked: any, paceId: any, adjustDefault: any) => {
		const newAdjustedPlan = { ...checkedAdjustPlan, [`${paceId}`]: isThisChecked ? adjustDefault : false }
		if (isThisChecked) {
			const allKeys = Object.keys(newAdjustedPlan)
			if (allKeys?.filter((cp: any) => newAdjustedPlan?.[cp])?.length === allKeys?.length) {
				setCheckAll(true)
			}
		} else setCheckAll(false)
		setCheckedAdjustPlan(newAdjustedPlan)
	}

	const handleCheckAll = (isThisChecked: any) => {
		const newCheckAdjustPlan = { ...checkedAdjustPlan }
		for (const cap in checkedAdjustPlan) {
			const val = weatherAlertAdjustList?.find((wal: any) => wal.paceId == cap)
			const adjustDefault = val ? weatherAdjust(getConvertedPace(val, true)?.rawPace) : ''
			newCheckAdjustPlan[cap] = isThisChecked ? adjustDefault : isThisChecked
		}
		setCheckedAdjustPlan(newCheckAdjustPlan)
		setCheckAll(isThisChecked)
	}

	const getVideoDetails = (vidId?: string, type?: string) => {
		const vids = recoverVideos.find(x => x.type === type)
		if (vids) {
			const video = vids.videos.find(x => x.vimeo_id === vidId as string)

			return { id: video?.id, videoId: video?.vimeo_id, title: vids?.type || video?.corrective, subtitle: vids?.type ? video?.corrective : video?.part }
		}
		else {
			return undefined
		}

	}

	const assignVideo = (videoId: string, type: string) => {
		const video: Video = {
			id: videoId,
			url: '/#/programs/runsmart-recover?videoId=' + videoId,
			// image: '\\img\\recover.jpg',
			image: `https://vumbnail.com/${videoId}.jpg`,
			category: 'Recover',
			headingTitle: getVideoDetails(videoId, type)?.subtitle as string,
			headingSubtitle: getVideoDetails(videoId, type)?.title as string,
			detailsLine1: '',
			detailsLine2: '',
			headlines: '' || [],
			descriptions: [] || []
		}

		return video
	}

	const getVideoDurations = async (vids: Array<any>, setList: Function, data: Array<any> | null) => {
		if (!data && vids?.length) {
			const finalData: Array<any> = []
			for (const v of vids) {
				if (v) {
					const response = await fetch(
						`https://vimeo.com/api/oembed.json?url=https://player.vimeo.com/video/${v}`
					);
					const data = await response.json();
					const duration = data?.duration ? data.duration : 0
					finalData.push({ [v]: duration })
				}
			}
			setList(finalData)
		}
	}

	const handleGetRecoveryWorkoutVideos = (videoIds: string, type: string) => {
		if (videoIds) {
			const vids = videoIds.split(',')
			const videos: Video[] = []
			getVideoDurations(vids, type === 'rwv1' ? setDurationList1 : setDurationList2, type === 'rwv1' ? durationList1 : durationList2)
			vids.forEach((id: any) => {
				videos.push(assignVideo(id, type))
			});
			return videos;
			// setRecoveryVideos(videos)
		}
		else
			return []
	}

	const wrapBorder = (data: any, k: any) => {
		const nW = window.innerWidth > 800 ? 800 : window.innerWidth
		const videoPerRow = Math.floor(nW / 300)
		const colSpace = 12 / videoPerRow
		return data?.length > 0 ? <Grid container key={k + 'v'} alignItems="center" sx={{
			width: '100%',
			fontSize: '16px',
			borderRadius: '5px',
			position: 'relative',
			mt: '36px'
		}}
		>
			<Grid container xs={12} rowSpacing={2} columnSpacing={colSpace !== 12 ? 2 : 0} justifyContent='flex-start' alignItems="center">
				{data?.map((d: any, di: number) => <Grid item xs={colSpace} key={'wB' + di}>{d}</Grid>)}
			</Grid>
		</Grid>
			: ''
	}

	const parseProgramTitle = (title: string) => {
		if (title === "base3")
			return "Base 3"
		else if (title === "basesix")
			return "Base 6"
		else if (title === "yoga")
			return "RunSmart Yoga"
		else
			return title
	}

	const videoContainerList = (data: Array<any>) => {
		const newVL = data.map((x: any, xi: number) => {
			const { headingSubtitle, id, onClick, src, image } = x
			const d = workOutDuration ? workOutDuration : []
			const d1 = durationList1 ? durationList1 : []
			const d2 = durationList2 ? durationList2 : []
			const durationData = [...d, ...d1, ...d2].find((x: any) => x[id])
			const duration = durationData?.[id] ? durationData[id] : 0
			const programTitle = x?.src?.toLowerCase().includes('programs') ? x?.src?.split('/')[3] : x.headingTitle
			let headingTitle = headingSubtitle?.toLowerCase().includes('day') ? headingSubtitle?.replace('Day', 'Worktout') : headingSubtitle

			const numbered = findNumberWord(headingTitle)
			if (numbered)
				headingTitle = headingTitle.toLowerCase().replace(numbered.word, numbered.number)

			return {
				programTitle: parseProgramTitle(programTitle),
				headingTitle: capitalizeEachWord(headingTitle),
				duration,
				id,
				src: src ? src : (image || ''),
				onClick: onClick ? onClick : () => handleOpenVideo(x, "recovery")
			}
		})
		const workoutCont: Array<any> = newVL?.length ? newVL.map((x: any, xi: number) => (
			<>
				<WorkoutVideoContainer
					index={xi}
					headingTitle={x.programTitle}
					subTitle={x.headingTitle}
					duration={x.duration}
					thumbnail={x.src}
					onClick={x.onClick}
				/>
			</>
		)) : []
		return workoutCont?.length ? <RSFlexCard videoList={workoutCont} /> : <></>
		// return <RSVideoCard videoList={newVL} />
	}

	const videoContainer = (data: any) => {
		const { headingTitle, headingSubtitle, id, onClick, src } = data
		const d = workOutDuration ? workOutDuration : []
		const d1 = durationList1 ? durationList1 : []
		const d2 = durationList2 ? durationList2 : []
		const durationData = [...d, ...d1, ...d2].find((x: any) => x[id])
		const duration = durationData ? Math.round(durationData[id] / 60) : ''
		return <RSButton secondary customized card key={data.id} sx={{ height: '110px' }}
			onClick={onClick ? onClick : () => handleOpenVideo(data, "recovery")}
		>
			<Grid container xs={12} justifyContent='space-between' alignItems='center' sx={{ py: '10px', height: 'inherit' }}>
				<Grid container xs={5.5} sx={{ position: 'relative', overflow: 'hidden', boxShadow: '0px 0px 3px #80808024' }} justifyContent='center' alignItems='center'>
					<img
						className='pointer'
						style={{ width: '100%', borderRadius: '4px' }}
						src={src ? src : `https://vumbnail.com/${id}.jpg`}
					/>
				</Grid>
				<Grid container xs={6.5} sx={{ height: '100%' }}>
					<Grid container xs={12} sx={{ px: 2 }} justifyContent='space-between' alignItems='space-between'>
						<Grid container sx={{ flexDirection: 'column' }} justifyContent='flex-start' alignItems='flex-start'>
							<Box sx={{ fontFamily: 'Poppins-SemiBold', fontSize: '16px', textAlign: 'left', lineHeight: '1.2em', mt: '2px', letterSpacing: '-0.2px' }}>
								{headingSubtitle}
							</Box>
							{duration ? <Box sx={{ fontFamily: 'Poppins-Light', fontSize: '16px' }}>
								{duration}min
							</Box> : (duration !== 0 ? <Box sx={{ position: 'relative', width: '80px', height: '20px' }}>
								<Box className='loading-days toRight' sx={{ border: 'none !important' }} />
							</Box> : '')
							}
						</Grid>
						{/*<Grid container justifyContent='flex-start' alignItems='flex-end'>
							<Box sx={{ fontFamily: 'Poppins-Light', fontSize: '14px', textAlign: 'left', fontStyle: 'italic', lineHeight: '1.2em' }}>{headingTitle}</Box>
						</Grid>*/}
					</Grid>
				</Grid>
			</Grid>
		</RSButton>
	}

	const handleMarkFinish = (isC: any) => {
		if (isC) {
			handleMarkComplete(false)
		} else {
			handleCompleteNSync(0)
			setUpdateRaceResult(true)
		}
	}


	// used for surface type changing
	const revertedMultipliedPace = (pace: string, paceAppliedMultiplier: number) => {
		const minutesMatch = pace.match(/(\d+)m/)
		const minutes = minutesMatch ? parseInt(minutesMatch[1]) : 0
		const secondsMatch = pace.match(/(\d+)s/)
		const seconds = secondsMatch ? parseInt(secondsMatch[1]) : 0
		const paceMinutes = ( minutes + (seconds / 60) ) / paceAppliedMultiplier

		const newMinutes = Math.floor(paceMinutes) + 'm'
		const newSeconds = Math.ceil((paceMinutes - Math.floor(paceMinutes)) * 60) + 's'

		return newMinutes + ' ' + newSeconds
	}



	const calculatePaceSpeed = (pace: string) => {
		const minutesMatch = pace.match(/(\d+)m/)
		const minutes = minutesMatch ? parseInt(minutesMatch[1]) : 0
		const secondsMatch = pace.match(/(\d+)s/);
		const seconds = secondsMatch ? parseInt(secondsMatch[1]) : 0

		const paceMinutes = minutes + (seconds / 60)
		const speed = 1 / (paceMinutes / 60)
		return speed.toFixed(1)
	}

	const calculateTotalDuration = (isDayActivity: any) => {

		let totalDuration = 0
		let currentExtraTimeMins = 0
		let currentPaceValue = 0

		let maxWeeks: any = []

		if (userInfo?.raceResults) {
			maxWeeks = JSON.parse(rr?.recoveryPlan?.recoveryType?.max_week_percentage as string | "[]");
		} else maxWeeks = false

		dayData?.marathonPaces?.map((val: any, idx: number) => {
			const curDistance = getCurrentDistance(dayData?.marathon?.id)
			const distanceValue = distanceInterpret(val?.notes, userInfo?.km == 'Y', val?.pace,
				curDistance && isDayActivity ? curDistance : dayData?.marathon?.distance,
				convertTextToIntervalTime(val?.notes, dayData.marathonPaces[idx - 1]),
				isDayActivity ? curDistance : undefined
			)

			// console.log('>>> currentDistance', curDistance)
			// console.log('>>> distanceValue', distanceValue)

			const isValueLong = isLongText(distanceValue)
			const isMiles = distanceValue?.toLowerCase().includes('mile')
			const isKm = distanceValue?.toLowerCase().includes('k')
			const finalDValue = distanceValue?.split(' ')

			const preferredUnitKm = userInfo?.km === 'Y' ? true : false

			const finalDistance = (isMiles || isKm)
				? isNaN(distanceValue?.split(' ')[0]) ? 0 : distanceValue?.split(' ')[0]
				: (!isValueLong && distanceValue?.toLowerCase().includes('m'))
					? preferredUnitKm ? (Number(distanceValue?.replace(/m/g, '')) * 0.001) : (Number(distanceValue?.replace(/m/g, '')) * 0.000621371)
					: Number(distanceValue) > 0 ? Number(distanceValue) : 0

			// we subtract 1 since the first value was already included in total duration
			const repeatTimes = distanceValue?.split(' ')[0].toLowerCase().includes('repeat') ? parseInt(finalDValue.slice(1).join(' ').match(/\d+/)[0] || '1') - 1 : 0

			const extraTimeMins = distanceValue?.split(' ')[0].toLowerCase().includes('recover') || distanceValue?.split(' ')[0].toLowerCase().includes('walk') ? computePaceTime(extractTime(finalDValue.slice(1).join(' '))) : 0
			currentExtraTimeMins = extraTimeMins != 0 ? extraTimeMins : currentExtraTimeMins


			const repeatDistance = isValueLong && distanceValue?.split(' ')[0].toLowerCase().includes('repeat')
				? distanceInterpret(dayData?.marathonPaces[idx - 2]?.notes, userInfo?.km == 'Y', dayData?.marathonPaces[idx - 2]?.pace,
					curDistance && isDayActivity ? curDistance : dayData?.marathon?.distance,
					convertTextToIntervalTime(val?.notes, dayData.marathonPaces[idx - 3]),
					isDayActivity ? curDistance : undefined)?.split(' ')[0]
				: '0'

			const intervalRecoverTime = repeatDistance != 0 && currentExtraTimeMins

			const repeatExtraTimeTotalMins = intervalRecoverTime ? intervalRecoverTime * repeatTimes : 0

			const repeatDistanceConverted = distanceValue?.split(' ')[0].toLowerCase().includes('repeat')
				? repeatDistance?.toString().includes('m') ?
					preferredUnitKm ? (Number(repeatDistance?.toString().replace(/m/g, '')) * 0.001) : (Number(repeatDistance?.toString().replace(/m/g, '')) * 0.000621371)
					: repeatDistance
				: 0

			const paceValue = getConvertedPace(val).value[0].toString()
			currentPaceValue = paceValue == 0 ? currentPaceValue : Number(computePaceTime(paceValue))

			const intervalTotalDistance = finalDistance > 0 ? finalDistance : repeatDistanceConverted * Number(repeatTimes) * currentPaceValue

			// console.log('-------------------')
			// console.log('paceValue', paceValue)
			// console.log('distanceValue', distanceValue)
			// console.log('currentPaceValue', currentPaceValue)
			// console.log('finalDistance', finalDistance)
			// console.log('repeatDistance', repeatDistance)
			// console.log('intervalRecoverTime', intervalRecoverTime)
			// console.log('extraTimeTotalMins', repeatExtraTimeTotalMins)
			// console.log('repeatTimes', repeatTimes)
			// console.log('intervalTotalDistance', intervalTotalDistance)
			// console.log('-------------------')

			const computedDistance = maxWeeks && maxWeeks.length > 0 ? Number(finalDistance) * maxWeeks[selectedWeek?.key - 1] : Number(finalDistance)

			finalDistance === 0 ? totalDuration += (Number(intervalTotalDistance) + Number(repeatExtraTimeTotalMins)) : totalDuration += (Number(computePaceTime(paceValue)) * computedDistance )

			// console.log('finalDistance',  distanceValue )
			// console.log('computePaceTime', Number(computePaceTime(paceValue)))
			// console.log('::::::::::::::::::::::')
			// console.log('>>> totalDuration', totalDuration)
			// console.log('::::::::::::::::::::::')

		})

		function computePaceTime(lap: string) {
			const pace = lap
			const minutesMatch = pace.match(/(\d+)m/)
			const minutes = minutesMatch ? parseInt(minutesMatch[1]) : 0
			const secondsMatch = pace.match(/(\d+)s/);
			const seconds = secondsMatch ? parseInt(secondsMatch[1]) : 0

			return minutes + (seconds / 60)
		}

		function extractTime(timePhrase: any) {
			const matchShort = timePhrase.match(/(\d+m\s*)?(\d+s)/)
			const matchLong = !matchShort && timePhrase.toLowerCase().includes('min') && timePhrase.match(/(\d+)\s*min?/i)

			const time = matchLong ? `${matchLong[1]}m` : matchShort ? matchShort[0].trim() : '0'
			return time.toString()
		}

		// console.log('>>> totalDuration', totalDuration)

		return totalDuration

	}

	const renderSuggestedPace = () => {
		return weatherAlertAdjustList?.map((val: any, idx: number) => {
			const adjustDefault = val?.adjustDefault
			const curConvertedPace = val?.curConvertedPace
			const label = <Grid container xs={12} key={idx}>
				<Box sx={{ fontFamily: 'Poppins-Light', fontSize: '16px' }}>
					{curConvertedPace}/{userInfo?.km === "Y" ? 'km' : "mile"}
				</Box>
				<Box sx={{ px: '3px' }}>
					<DArrowForward sx={{ fontSize: '28px' }} />
				</Box>
				<Box sx={{ fontFamily: 'Poppins-medium', fontSize: '16px' }}>
					{adjustDefault}/{userInfo?.km === "Y" ? 'km' : "mile"}
				</Box>
			</Grid>
			return <Grid key={idx} container xs={12} sx={{ pl: '15px' }} justifyContent='flex-start' alignItems='center'>
				<FormControlLabel control={<Checkbox checked={checkedAdjustPlan?.[val?.paceId] ? true : false}
					onChange={(e) => handleCheckChange(e.target.checked, val?.paceId, adjustDefault)} />} label={label} />
			</Grid>
		}
		)
	}

	const renderWeatherAlert = () => {
		const suggestedMarkUp = renderSuggestedPace()
		return <Grid container sx={{ p: 2, width: '100%', fontFamily: 'Poppins', position: 'relative' }} justifyContent='flex-start' alignItems="center">
			<Grid container xs={12} sx={{ fontSize: '18px', fontFamily: 'Poppins', pb: '5px' }}>Weather Adjustment</Grid>
			<Box sx={{ pb: '5px' }}>
				<p style={{ fontSize: '16px', fontFamily: 'Poppins-Light' }}>Your weather score for <span style={{ fontSize: '16px', fontFamily: 'Poppins' }}>{getWeatherData?.data?.name} </span> is <span style={{ fontSize: '16px', fontFamily: 'Poppins' }}> {getWTAValue()}/100. </span>Suggestions to change your pacing below.</p>
			</Box>
			<Grid container xs={12} sx={{ fontSize: '16px', fontFamily: 'Poppins', py: '20px', pl: '10px' }} justifyContent='flex-end' alignItems='center'>
				{weatherAlertAdjustList?.length > 1 &&
					<Grid container xs={12} justifyContent='flex-start' alignItems='center'>
						<FormControlLabel
							control={<Checkbox checked={checkAll} onChange={(e) => handleCheckAll(e.target.checked)} />}
							label={<Box sx={{ fontFamily: 'Poppins', fontSize: '16px' }}>Select All</Box>}
						/>
					</Grid>}
				{weatherAlertAdjustList?.length > 0 ? suggestedMarkUp
					: <Grid container xs={12} sx={{ fontFamily: 'Poppins', fontSize: '16px' }} justifyContent='center' alignItems='center'>{`You're All Set!`}</Grid>}
			</Grid>
			<Grid container xs={12} sx={{ mb: '20px', mt: '30px' }} justifyContent='center' alignItems="center">
				{['Change My Location', 'Update Plan'].map((t, i) => {
					const isDisabled = Object.keys(checkedAdjustPlan)?.filter((cp: any) => checkedAdjustPlan?.[cp])?.length === 0
					return <Grid key={i} container xs={6} justifyContent={i ? 'flex-end' : 'flex-start'} alignItems="center">
						<RSButton secondary={i ? false : true} disabled={i && isDisabled ? true : false} onClick={() => i ? handleUpdatePlan() : handleChangeMyLocation()} sx={{ p: '8px 0px', width: 'calc(100% - 2px)' }}>{t}</RSButton>
					</Grid>
				})
				}
			</Grid>
		</Grid>
	}

	const renderSyncDevice = (noData: boolean, syncDevice: boolean) => {
		return (
			<Box sx={{ width: '100%', px: '10px', mt: '30px' }}>
				{noData ? (
					<div className='no-border mt-4 text-center p-2 mx-n4 mx-md-3'>
						<p>No activity for this date. Rest up <br />or complete your training and check back.</p>
					</div>
				) : syncDevice ? (
					<>
						<p className='text-gray text-center'>
							Sync your watch or device to your training plan.
						</p>
						<IntegrationsMainPage type='training-plan' />
					</>
				) : ''}
			</Box>
		)

	}

	const renderGarminSyncNotification = (isFromDrawer?: boolean, hasGarminSyncError?: boolean) => {
		return (
			<Grid container xs={12} sx={{ mt: '10px', p: isFromDrawer ? '0px 16px 20px 16px' : 0 }} justifyContent='flex-start' alignItems="center">
				<Grid container xs={12} alignItems='flex-end'>
					<Box sx={{ fontSize: '16px', fontFamily: 'Poppins', width: '100%' }}>{`Sync to Garmin`}</Box>
					{!hasGarminSyncError && !invalidGarminPermission ? (
						<Box sx={{ marginTop: '30px', marginBottom: '30px' }}>
							<CheckCircleOutlineRounded sx={{ color: 'green', scale: '2', marginBottom: '10px', marginLeft: '5px' }} />
							<Box sx={{ fontSize: '20px', fontFamily: 'Poppins', width: '100%', marginBottom: '10px' }}>{`Activity has been sent!`}</Box>
							<Box sx={{ fontSize: '15px', lineHeight: 1, fontFamily: 'Poppins', width: '100%' }}>{`Open the Garmin Connect App to push the activity to your watch.`}</Box>
						</Box>
					) : invalidGarminPermission ? (
						<Box sx={{ marginTop: '30px', marginBottom: '30px' }}>
							<Box sx={{ fontSize: '20px', fontFamily: 'Poppins', width: '100%', marginBottom: '10px' }}>
								<HighlightOffOutlined sx={{ color: '#F01', scale: '2', marginBottom: '10px', marginLeft: '5px', marginRight: '15px' }} />
								{`Update Permissions`}</Box>
							<Box sx={{ fontSize: '15px', lineHeight: 1.3, fontFamily: 'Poppins', width: '100%' }}>{`To sync your plan and your Garmin device, please reconnect your account and enable “Activity” and “Training” permissions.`}</Box>
							<div className='mt-3 mb-n5'>
								<IntegrationsMainPage type='training-plan' />
							</div>
						</Box>
					) : (
						<Box sx={{ marginTop: '30px', marginBottom: '30px' }}>
							<HighlightOffOutlined sx={{ color: '#F01', scale: '2', marginBottom: '10px', marginLeft: '5px' }} />
							<Box sx={{ fontSize: '20px', fontFamily: 'Poppins', width: '100%', marginBottom: '10px' }}>{`Error synching workout data!`}</Box>
							<Box sx={{ fontSize: '15px', lineHeight: 1, fontFamily: 'Poppins', width: '100%' }}>{`An error occurred during synching workout data to Garmin, please try again.`}</Box>
						</Box>
					)}

				</Grid>
			</Grid>
		)
	}

	const renderNotesArea = (isFromDrawer?: boolean) => {
		return (
			<Grid container xs={12} sx={{ mt: '10px', p: isFromDrawer ? '0px 16px 20px 16px' : 0 }} justifyContent='flex-start' alignItems="center">
				<Grid container xs={12} sx={{ pb: '5px' }} alignItems='flex-end'>
					<Box sx={{ fontSize: '16px', fontFamily: 'Poppins' }}>{`Notes`}</Box>
				</Grid>

				{!isFromDrawer && <Grid container xs={12}>
					<Button
						onClick={() => setIsTakingNotes(true)}
						sx={{
							border: isFromDrawer ? 'none' : '1px solid rgba(0, 0, 0, 0.176)',
							width: '100%',
							fontSize: '16px',
							py: '10px',
							pr: '35px',
							borderRadius: '5px',
							backgroundColor: isFromDrawer ? 'transparent' : 'white',
							position: 'relative',
							textTransform: 'none',
							color: comment ? 'black' : 'gray',
							justifyContent: 'flex-start'
						}}
					>
						{!isFromDrawer && <IconButton sx={{ color: 'black', position: 'absolute', top: 0, right: 0 }}>
							{comment ? <svg height="22px" fill="#505050" viewBox="0 0 512 512"><path d="M441 58.9L453.1 71c9.4 9.4 9.4 24.6 0 33.9L424 134.1 377.9 88 407 58.9c9.4-9.4 24.6-9.4 33.9 0zM209.8 256.2L344 121.9 390.1 168 255.8 302.2c-2.9 2.9-6.5 5-10.4 6.1l-58.5 16.7 16.7-58.5c1.1-3.9 3.2-7.5 6.1-10.4zM373.1 25L175.8 222.2c-8.7 8.7-15 19.4-18.3 31.1l-28.6 100c-2.4 8.4-.1 17.4 6.1 23.6s15.2 8.5 23.6 6.1l100-28.6c11.8-3.4 22.5-9.7 31.1-18.3L487 138.9c28.1-28.1 28.1-73.7 0-101.8L474.9 25C446.8-3.1 401.2-3.1 373.1 25zM88 64C39.4 64 0 103.4 0 152V424c0 48.6 39.4 88 88 88H360c48.6 0 88-39.4 88-88V312c0-13.3-10.7-24-24-24s-24 10.7-24 24V424c0 22.1-17.9 40-40 40H88c-22.1 0-40-17.9-40-40V152c0-22.1 17.9-40 40-40H200c13.3 0 24-10.7 24-24s-10.7-24-24-24H88z" /></svg>
								: <AddCircleIcon sx={{ color: '#505050', fontSize: '30px', stroke: 'white', strokeWidth: '1px' }} />
							}
						</IconButton>}
						<Box className='notes-ellipsis' sx={{ fontFamily: 'Poppins-light', fontSize: '16px' }} >
							{comment ? comment : 'Add notes'}
						</Box>
					</Button>
				</Grid>}

				{isFromDrawer && false && <Grid container sx={{ mb: '5px' }} justifyContent='flex-start' alignItems="center">
					{[...Array(5)].map((e: any, i: number) =>
						<Box key={i} sx={{ position: 'relative', mr: '2px' }} >
							{i < rate ? (rate - i === 0.5 ?
								<StarHalf sx={{ position: 'absolute', fontSize: '26px', color: '#FFC352' }} />
								: <StarFilled sx={{ position: 'absolute', color: '#FFC352', fontSize: '26px' }} />)
								: rate !== 0 ?
									<StarEmpty sx={{ position: 'absolute', color: 'rgb(0 0 0 / 16%)', fontSize: '26px' }} />
									: <StarFilled sx={{ position: 'absolute', color: 'rgb(0 0 0 / 16%)', fontSize: '26px' }} />}
							<Button onClick={() => setRate(rate === i + 0.5 ? 0 : i + 0.5)} sx={{ p: 0, minWidth: '0px', width: '13px', height: '26px', borderRadius: 0 }} />
							<Button onClick={() => setRate(rate === i + 1 ? 0 : i + 1)} sx={{ p: 0, minWidth: '0px', width: '13px', height: '26px', borderRadius: 0 }} />
						</Box>
					)}
				</Grid>}
				{isFromDrawer && <><Grid container xs={12} justifyContent='center' alignItems="center">
					<textarea className='text-area-complete' placeholder='Tell us how it went!' value={newComment} onChange={(e) => setNewComment(e.target.value)} />
				</Grid>
					<Grid container xs={12} sx={{ my: '10px' }} justifyContent='center' alignItems="center">
						<RSButton sx={{ mt: '10px' }} onClick={() => handleMarkComplete(false, true)}>Save</RSButton>
					</Grid></>}
			</Grid>)
	}

	const
		renderGarminLaps = (activityNum: number) => {
			if (lapsData?.length > 0) {
				let activityIndex = -1
				const running = []
				const otherActivities = []
				for (const act of lapsData) {
					activityIndex++
					if (activityIndex != activityNum) continue
					const isRunning = act.activityType === 'RUNNING'
					const paceUnit = userInfo?.km == "N" ? 'mi' : 'km'
					const table = act.laps?.length > 1 && <>
						{/* <Grid container sx={{ fontSize: '16px', fontFamily: 'Poppins' }}>{act.activityName}</Grid> */}
						<Grid container xs={12} sx={{ p: '0px 10px', backgroundColor: 'white', borderRadius: '4px' }} justifyContent='flex-start' alignItems="center">
							<Grid container xs={12} sx={{ p: '10px 5px 10px 5px' }} justifyContent='flex-start' alignItems="center">
								<Grid container xs={1} justifyContent='center' alignItems='center' sx={{ fontFamily: 'Poppins-Semibold', fontSize: '14px' }}>Lap</Grid>
								<Grid container xs={3} justifyContent='center' alignItems='center' sx={{ fontFamily: 'Poppins-Semibold', fontSize: '14px' }}>Time</Grid>
								<Grid container xs={3} justifyContent='flex-end' alignItems='center' sx={{ fontFamily: 'Poppins-Semibold', fontSize: '14px', position: 'relative' }}>
									Distance<Box sx={{ position: 'absolute', fontFamily: 'Poppins', fontSize: '11px', right: 0, bottom: -10 }} >{paceUnit}</Box>
								</Grid>
								<Grid container xs={5} justifyContent='flex-end' alignItems='center' sx={{ fontFamily: 'Poppins-Semibold', fontSize: '14px', position: 'relative' }}>
									Avg Pace<Box sx={{ position: 'absolute', fontFamily: 'Poppins', fontSize: '11px', right: 0, bottom: -10 }} >min/{paceUnit}</Box>
								</Grid>
							</Grid>

							{act.laps.map((lap: any, i: number) => {
								const lapDistance = act.laps[i - 1] ? lap.totalDistanceInMeters - act.laps[i - 1].totalDistanceInMeters : lap.totalDistanceInMeters
								const lD = userInfo?.km == 'Y' ? (lapDistance / 1000)?.toFixed(2) : ConvertMetersToMiles(lapDistance)?.toFixed(2)

								const lapTime = act.laps[i - 1] ? lap.timerDurationInSeconds - act.laps[i - 1].timerDurationInSeconds : lap.timerDurationInSeconds
								const lapUnit = userInfo?.km == 'Y' ? 'km' : 'mile'

								return i ? (
									<Grid container xs={12} sx={{ p: '5px' }}>
										<Grid container xs={1} justifyContent='center' alignItems='center' sx={{ fontFamily: 'Poppins-light', fontSize: '16px', color: 'black' }}>
											{i}
										</Grid>
										<Grid container xs={3} justifyContent='center' alignItems='center' sx={{ fontFamily: 'Poppins-light', fontSize: '16px', color: 'black' }}>
											{ConvertS2M(lap.timerDurationInSeconds - act?.laps?.[i - 1].timerDurationInSeconds)}
										</Grid>
										<Grid container xs={3} justifyContent='flex-end' alignItems='center' sx={{ fontFamily: 'Poppins-light', fontSize: '16px', color: 'black' }}>
											{lD}
											{/* {lapUnit}{Number(lD) > 1 ? 's' : ''} */}
										</Grid>
										<Grid container xs={5} justifyContent='flex-end' alignItems='center' sx={{ fontFamily: 'Poppins-light', fontSize: '16px' }}>
											{/* {ConvertS2M(lapTime)}/{lapUnit} */}
											{/* {getGarminPace(userInfo?.km == 'Y' ? true : false, lap.speedMetersPerSecond)} */}
											{/* sometime results get NaN or Infinity, temporary fix is we replace it with 1 */}
											{
												i == act.laps.length - 1 || (userInfo?.km == 'Y' && Number(String((i > 0 ? (lap.totalDistanceInMeters - act.laps[i - 1].totalDistanceInMeters) / 1000 : lap.totalDistanceInMeters / 1000))) < 1 || userInfo?.km != 'Y' && Number(ConvertMetersToMiles((i > 0 ? Number(lap.totalDistanceInMeters - act.laps[i - 1].totalDistanceInMeters) : lap.totalDistanceInMeters))?.toFixed(1)) < 1)
													? getGarminPace(userInfo?.km == 'Y' ? true : false, lap.speedMetersPerSecond).replace(/\s*\/\s*mile/i, '').replace(/\s*\/\s*km/i, '').replace(/Infinity/g, '1').replace(/NaN/g, '1').trim() :
													((i > 0 ? ConvertS2M(lap.timerDurationInSeconds - act.laps[i - 1].timerDurationInSeconds) : ConvertS2M(lap.timerDurationInSeconds)))
												// + (i !== act.laps.length - 1 ? userInfo?.km == 'Y' ? ' / km' : ' / mile' : '')
												// + (i !== act.laps.length - 1 ? userInfo?.km == 'Y' ? ' / km' : ' / mile' : '')
											}
										</Grid>
										{/* {renderGarminLaps(i)} */}
									</Grid>
								) : ''
							})}
						</Grid>
					</>

					table && (isRunning ? running.push(table) : otherActivities.push(table))
				}
				return <Box sx={{ width: '100%', fontFamily: 'Poppins', position: 'relative', mt: '10px' }}>
					<Grid container xs={12} justifyContent='flex-start' alignItems="center">
						{[...running, ...otherActivities]}
					</Grid>
				</Box>
			}
		}

	const renderGarminActivity = (isFromDrawer?: boolean) => {
		if (currentDayActivities?.length > 0) {
			let activityIndex = -1
			const running = []
			const otherActivities = []
			for (const act of currentDayActivities) {
				activityIndex++
				const actInfoList = []
				const isRunning = act.activityType === 'RUNNING'
				const actDistance = act.distanceInMeters ? (userInfo?.km == "N" ? ConvertMetersToMiles(act.distanceInMeters as number)?.toFixed(1) : Number(act.distanceInMeters as number / 1000)?.toFixed(1)) : ''
				const actUnit = userInfo?.km == "N" ? 'Miles' : 'km'
				const paceUnit = userInfo?.km == "N" ? 'mi' : 'km'

				if (act.averageSpeedInMetersPerSecond) {
					const paceDuration = getGarminPace(userInfo?.km == 'Y' ? true : false, act.averageSpeedInMetersPerSecond)
					const paceValueArr = paceDuration ? paceDuration.split(' ') : []
					const paceMin = paceValueArr?.find((v: any) => v.includes('m'))?.replace('m', ':')
					const paceSec = paceValueArr?.find((v: any) => v.includes('s'))?.replace('s', '')?.padStart(2, '0')
					actInfoList.push(<Grid container justifyContent='center' alignItems='center' sx={{ width: 'calc(50% - 5px)' }}>
						<Grid container justifyContent='center' alignItems='center' sx={{ color: 'black !important', p: '15px 0px 5px 0px', my: '5px', borderRadius: '5px', flexDirection: 'column', backgroundColor: 'white !important' }}>
							<Grid container justifyContent='center' alignItems='flex-end'>
								<Box sx={{ lineHeight: '1em', fontFamily: 'Poppins', fontSize: '20px', mr: '5px' }}>{paceMin ? paceMin : ''}{paceSec ? paceSec : ''}</Box>
								<Box sx={{ fontFamily: 'Poppins-light', fontSize: '12px' }}>/{paceUnit}</Box>
							</Grid>
							<Box sx={{ fontFamily: 'Poppins-light', fontSize: '12px' }}>Pace</Box>
						</Grid>
					</Grid>)
				}


				act.averageHeartRateInBeatsPerMinute &&
					actInfoList.push(<Grid container justifyContent='center' alignItems='center' sx={{ width: 'calc(50% - 5px)' }}>
						<Grid container justifyContent='center' alignItems='center' sx={{ color: 'black !important', p: '15px 0px 5px 0px', my: '5px', borderRadius: '5px', flexDirection: 'column', backgroundColor: 'white !important' }}>
							<Box sx={{ lineHeight: '1em', fontFamily: 'Poppins', fontSize: '20px', mr: '5px' }}>{act.averageHeartRateInBeatsPerMinute}</Box>
							<Box sx={{ fontFamily: 'Poppins-light', fontSize: '12px' }}>Average Heart Rate</Box>
						</Grid>
					</Grid>)

				if (act.durationInSeconds) {
					const actDuration = timeToString(ConvertS2TimeObject(act.durationInSeconds))
					actDuration && actInfoList.push(<Grid container justifyContent='center' alignItems='center' sx={{ width: 'calc(50% - 5px)' }}>
						<Grid container justifyContent='center' alignItems='center' sx={{ color: 'black !important', p: '15px 0px 5px 0px', my: '5px', borderRadius: '5px', flexDirection: 'column', backgroundColor: 'white !important' }}>
							<Box sx={{ lineHeight: '1em', fontFamily: 'Poppins', fontSize: '20px', mr: '5px' }}>{actDuration}</Box>
							<Box sx={{ fontFamily: 'Poppins-light', fontSize: '12px' }}>Total Time</Box>
						</Grid>
					</Grid>)
				}

				act.activeKilocalories
					&& actInfoList.push(<Grid container justifyContent='center' alignItems='center' sx={{ width: 'calc(50% - 5px)' }}>
						<Grid container justifyContent='center' alignItems='center' sx={{ color: 'black !important', p: '15px 0px 5px 0px', my: '5px', borderRadius: '5px', flexDirection: 'column', backgroundColor: 'white !important' }}>
							<Box sx={{ lineHeight: '1em', fontFamily: 'Poppins', fontSize: '20px', mr: '5px' }}>{act.activeKilocalories}</Box>
							<Box sx={{ fontFamily: 'Poppins-light', fontSize: '12px' }}>Total Calories</Box>
						</Grid>
					</Grid>)

				const finalList = []
				let rowList = []
				for (const actInfo in actInfoList) {
					rowList.push(actInfoList[actInfo])
					if (Number(actInfo) % 2 === 1 || Number(actInfo) === actInfoList.length - 1) {
						finalList.push(rowList)
						rowList = []
					}
				}


				const element = <Grid container sx={{ mb: '15px' }}>

					{activityIndex != 0 && <Grid sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', mt: '1rem' }}>

						{/* @@@ Daily Activity Results Header */}
						<i className={`fa-duotone fa-solid fa-person-running`} style={{ fontSize: '40px', marginRight: '0.4rem' }}></i>
						<Grid container xs={12} sx={{ fontFamily: 'Poppins', fontSize: '16px', display: 'flex', flexDirection: 'column' }} justifyContent='flex-start'>
							<Box sx={{ backgroundColor: '#DDD', width: 'fit-content', px: '1rem', borderRadius: '10px', display: 'flex', justifyContent: 'center' }} alignContent={'center'} alignItems={'center'}>
								<Grid container sx={{ fontSize: '16px', fontFamily: 'Poppins' }}>{act.activityName}</Grid>
							</Box>
						</Grid>
					</Grid>}

					{/* @@@ Main Distance Value Results Tab */}

					{/* old garmin activity header */}
					{/* <Grid container sx={{ fontSize: '16px', fontFamily: 'Poppins' }}>{act.activityName}</Grid> */}
					{/* {actDistance && <Grid container xs={12}>
						<Grid container justifyContent='center' alignItems='center' sx={{ textTransform: 'none', color: 'black !important', p: '15px 0px 5px 0px', my: '5px', mr: '5px', borderRadius: '5px', flexDirection: 'column', backgroundColor: 'white !important', width: '120px' }}>
							<Box sx={{ lineHeight: '1em', fontFamily: 'Poppins', fontSize: '25px' }}>{actDistance}</Box>
							<Box sx={{ fontFamily: 'Poppins', fontSize: '16px' }}>{actUnit}</Box>
						</Grid>
					</Grid>} */}

					<Grid container xs={12} sx={{ flexDirection: 'row', mt: '1.5rem', pb: '1rem', borderBottom: '1px solid #AAA' }}>
						<Grid container sx={{ flexDirection: 'column' }} >
							<> {Number(actDistance) != 0 && <Grid sx={{ fontFamily: 'Poppins-Semibold', fontSize: '14px', mb: '0.8rem' }}>Distance</Grid>}
								<Grid sx={{ fontFamily: 'Poppins-Semibold', fontSize: '40px', lineHeight: '1.4rem' }} >
									{actDistance}
									<span style={{ fontFamily: 'Poppins', fontSize: '20px', color: '#999' }}>{Number(actDistance) != 0 ? actUnit : 'New Run'}</span>
								</Grid> </>
						</Grid>
					</Grid>

					{finalList.map((e: any, idx: number) => <Grid key={idx + 'info'} container xs={12} justifyContent='space-between' alignItems='center'>{e}</Grid>)}
					{renderGarminLaps(activityIndex)}
				</Grid>

				isRunning ? running.push(element) : otherActivities.push(element)
			}
			return <Box sx={{ width: '100%', fontFamily: 'Poppins', position: 'relative', mt: isFromDrawer ? '0px' : '10px' }}>
				<Box sx={{ display: 'flex', flexDirection: 'column', width: '100%', backgroundColor: 'white', p: '1.4rem', borderRadius: '10px' }}>

					{currentDayActivities[0].activityType === 'RUNNING' && <Grid sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>

						{/* @@@ Daily Activity Results Header */}
						<i className={`fa-duotone fa-solid fa-person-running`} style={{ fontSize: '40px', marginRight: '0.4rem' }}></i>
						<Grid container xs={12} sx={{ fontFamily: 'Poppins', fontSize: '16px', display: 'flex', flexDirection: 'column' }} justifyContent='flex-start'>
							<Grid sx={{ fontFamily: 'Poppins-Bold', fontSize: '23px' }} >{'Running'} </Grid>
							<Box sx={{ backgroundColor: '#DDD', width: 'fit-content', px: '1rem', borderRadius: '10px', display: 'flex', justifyContent: 'center' }} alignContent={'center'} alignItems={'center'}>
								<Grid container sx={{ fontSize: '16px', fontFamily: 'Poppins' }}>{currentDayActivities[0].activityName}</Grid>
							</Box>
						</Grid>
					</Grid>}

					<Grid container xs={12} justifyContent='flex-start' alignItems="center">
						{[...running, ...otherActivities]}

						{!isFromDrawer && (currentDayActivities && currentDayActivities.length == 0) ?
							<Grid container xs={6} justifyContent='flex-center' alignItems="center">
								<Button onClick={() => isDayComplete ? handleMarkComplete(false) : handleCompleteNSync(1)} variant={`${isDayComplete ? 'contained' : 'outlined'}`} sx={{ color: '#010101', fontFamily: 'Poppins', border: `1px solid ${isDayComplete ? '#9FCD2B' : 'rgba(0, 0, 0, 0.176)'}`, p: '8px 0px', borderRadius: '5px', width: 'calc(100% - 4px)', fontSize: '14px', backgroundColor: `${isDayComplete ? '#9FCD2B !important' : 'white !important'}`, boxShadow: 'none !important', textTransform: 'none' }}>
									<Grid sx={{ position: 'relative' }}>{isDayComplete && <CheckIcon sx={{ position: 'absolute', left: -25, mt: '1px' }} />}{isDayComplete ? 'Completed' : 'Mark Complete'}</Grid>
								</Button>
							</Grid>
							: ''
						}
					</Grid>

					{/* {renderGarminLaps(0)} */}
					{!isFromDrawer && !(moment(selectedWeek?.week?.replaceAll('-', '/'))?.add(selectedDay, 'd')?.isAfter(moment(userInfo?.race_date)) && userInfo?.type !== 4) && <Grid container sx={{ mt: '20px' }}>{renderNotesArea()}</Grid>}

				</Box>
			</Box>
		}
		return ''
	}

	const renderCrossTrainingButton = (trainIcon: string) =>
		<Button sx={{
			border: `1px solid rgba(0, 0, 0, 0.176)`, borderRadius: '5px', backgroundColor: 'white !important',
			width: isMobile ? '100%' : '50%', height: '5.5rem', p: '1.5rem 1rem 1.5rem 1.5rem', my: '5px', mr: '5px', textTransform: 'none', color: 'black',
			justifyContent: 'flex-start', flexDirection: 'column', display: 'flex', alignItems: 'start'
		}}
			onClick={() => { setDisplayCrossTrainingModal(true) }}
		>
			<Grid sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', width: '100%', alignItems: 'center', alignContent: 'center' }}>
				<Grid sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'center', alignContent: 'center', alignItems: 'center' }}>
					<i className={`fa-duotone fa-solid ${trainIcon}`} style={{ fontSize: '22px', marginRight: '0.4rem' }}></i>
					<Grid container sx={{ fontFamily: 'Poppins', fontSize: '16px', display: 'flex', flexDirection: 'row' }} justifyContent='flex-start'>
						<Grid sx={{ fontFamily: 'Poppins-Medium', fontSize: '20px' }} >Cross Training</Grid>
					</Grid>
				</Grid>

				<ArrowForward fontSize="medium" sx={{ stroke: 'black', strokeWidth: '2.2px' }} />
			</Grid>

		</Button>


	const renderActivity = (isFromDrawer?: boolean, isAssessmentDone?: boolean, isRD?: boolean) => {
		let rr: RaceResult | undefined = undefined;
		let maxWeeks: [] | undefined = []

		if (userInfo?.raceResults) {
			rr = userInfo?.raceResults
			maxWeeks = JSON.parse(rr?.recoveryPlan?.recoveryType?.max_week_percentage as string | "[]");
		}

		// console.log('userInfo', userInfo)
		// console.log('selectedRace', selectedRace)
		// console.log('allUserNotes', allUserNotes)

		const overrideDayActivity = getDailyActivityDisplay(
			dayData,
			userInfo,
			dayData?.marathonPaces,
			selectedRace?.value as number,
			dayData?.marathon?.race_day,
			allUserNotes)
		const isEdited = userNotes?.find((x: any) => x.marid == dayData?.marathon?.id && x.uid == userInfo?.uid) || dayData?.marathonPaces?.find((val: any) => userCustomPaces?.find((x: any) => x.pid == val.paceId))
		const dailyActivity = overrideDayActivity || allUserNotes ? overrideDayActivity?.split('|') : currentDayActivity?.split('|')
		const isKm = userInfo?.km === "Y" ? "KMS" : "MILES";
		const isKm2 = userInfo?.km === "Y" ? "KM" : "MI";
		let trainVal = isAssessmentDone ? 'Cross Train' : dailyActivity?.[0]?.includes(isKm) ? dailyActivity[0] : dailyActivity[0]?.replace(isKm2, isKm)
		trainVal = trainVal?.trim()
		const isShowEdit = trainVal?.toUpperCase() === 'REST' || trainVal?.toUpperCase() === 'CROSS TRAIN'
		trainVal = trainVal?.split(' ')?.map((tv: any) => is_numeric(tv) ? tv : tv.charAt(0) + tv.slice(1).toLowerCase())?.join(' ')

		const desc = dailyActivity?.[1]?.trim()
		const isDayActivity = desc?.toUpperCase() === 'BASE' || (desc?.toUpperCase() === "RECOVERY" && !(dayData?.marathonPaces?.length > 1)) || desc?.toUpperCase() === "10K PACE" || isRD || dayData?.marathonPaces.length < 2
		const valSplit = trainVal.split(' ')

		let distance = valSplit[0]
		const isNumber = is_numeric(distance || valSplit[0])

		const hasCustomDistance = allUserNotes?.find((x: any) => x?.marid == dayData?.marathon?.id)?.distance ? true : false

		distance = !hasCustomDistance && isNumber && rr && maxWeeks ? (distance * maxWeeks[selectedWeek?.key - 1]).toFixed(1) : valSplit[0]

		const hasDuration = dayData?.marathonPaces?.length > 0
		const totalDuration = Number(calculateTotalDuration(isDayActivity).toFixed(0))

		const trainType = dailyActivity.length > 1 ? dailyActivity[2] : 'none'
		const isYoga = trainType?.toLowerCase().includes('yoga')
		const isStrength = trainType?.toLowerCase().includes('strength')
		const isStretch = trainType?.toLowerCase().includes('stretch')
		const isRoll = trainType?.toLowerCase().includes('roll')
		const trainIcon = isYoga ? 'fa-solid fa-spa' : isStrength ? 'fa-solid fa-dumbbell' : isStretch ? 'fa-solid fa-child-reaching' : isRoll ? 'fa-solid fa-circle-dashed' : 'fa-solid fa-running'
		const isTraining = titleCase(valSplit.slice(1).join(' ')).toLowerCase().includes('train')

		const isRest = titleCase(valSplit).toLowerCase().includes('rest') || trainVal.toLowerCase().includes('rest')
		const isRacePace = trainVal.toLowerCase().includes('race')

		// dayNum can be used instead of id, but reordering days will cause issues
		// const dayNum =  moment(dayData?.date).day() + 1 || moment(dayData?.date).day() + 1 || selectedDay 

		const daySurfaceType = allDaySurfaceType?.find((e: any) => userInfo?.uid === e.plan_id && (e.activity_id === dayData?.marathon?.id))?.surface_type

		const isCompleteInActive = isFromDrawer && !isMarkComplete
		const isEditable = !isShowEdit && !isFromDrawer && !currentDayActivities?.length
		const isSeeLaps = trainVal?.toUpperCase() === 'RACE PACE'
		const isStretchRoll = isSeeLaps && (desc?.toLowerCase() === 'stretch,roll' || desc?.toLowerCase() === 'stretch')
		const isTodayAndFuture = moment(getTodayDate()).isSameOrAfter(moment())
		const allButtons = <>
			{!isFromDrawer && !isShowEdit && isValid && <Grid sx={{ mt: '10px' }} container xs={12} justifyContent='flex-end' alignItems="flex-end">
				<Button
					onClick={() => {
						setShowWeatherAlert(true);
						handleCheckAll(true)
					}}
					variant='outlined'
					sx={{ fontFamily: 'Poppins', mt: '1rem', backgroundColor: 'white', border: '1px solid rgba(0, 0, 0, 0.176)', p: '8px 0px', borderRadius: '5px', width: !isMobile ? '33%' : '100%', fontSize: '14px', textTransform: 'none' }}
				>
					<Box sx={{ position: 'relative', display: 'flex', alignItems: 'center', color: '#010101' }}>
						<WeatherIcon sx={{ left: '-25px', position: 'absolute', color: '#FFC352' }} />
						Weather Alert
					</Box>
				</Button>
			</Grid>}
			{
				(currentDayActivities && currentDayActivities?.length == 0 && trainVal?.toUpperCase() !== 'REST') ?
					!isMarkComplete && <Grid container alignItems="center" justifyContent={'flex-end'} sx={{ mt: isValid ? '0rem' : '1rem', mb: '0.5rem', pr: '-1rem' }}>
						{!isTraining && <Grid sx={{ mt: '10px', mr: '-0.4rem' }} justifyContent={'flex-end'} alignItems="center">
							{!isFromDrawer && <Button onClick={() => setOpenSurface(true)}>
								<Grid sx={{ position: 'relative', display: 'flex', flexDirection: 'column' }} justifyContent='center' alignItems='center'>
									<Box sx={{ height: '30px' }}>{activeSurfaceIcon && daySurfaceType ? activeSurfaceIcon : <RSIcon type='road' />}</Box>
									<Grid sx={{ fontSize: '11px', fontFamily: 'Poppins-Light', color: 'black' }}>{'Surface'}</Grid>
								</Grid>
							</Button>}
						</Grid>}
						{!isFromDrawer ?
							[isTodayAndFuture && (trainVal?.toUpperCase() !== "CROSS TRAIN") ? `${userGarminDetails?.status && !hasSyncData ? 'Send' : userGarminDetails?.status && hasSyncData ? 'Synced' : ''}` : undefined
								, `${isDayComplete ? 'Completed' : 'Complete'}`,
							].map((t, i) => {
								const isCompleted = t === 'Completed'
								const isSynced = t === "Synced"
								const isActionToComplete = t === 'Complete'
								const isActionToSend = t === 'Send'
								return !(i && isAfterCurrentDate) && t && <Grid key={i} sx={{ mt: '10px', mr: '-0.4rem' }} justifyContent={'flex-end'} alignItems="center">
									<Button onClick={() => (isCompleted || isSynced) ? handleMarkComplete(false) : handleCompleteNSync(i)}
										sx={{ color: '#010101' }}>
										<Grid sx={{ position: 'relative', display: 'flex', flexDirection: 'column' }} justifyContent='center' alignItems='center'>
											{/* {isC && (i ? <i className="fa-light fa-watch" style={{ position: 'absolute', left: -25, marginTop: '1px', fontSize: '40px' }} /> : <CheckIcon sx={{ position: 'absolute', left: -25, mt: '1px', fontSize: '40px' }} />)} */}
											{isActionToSend && !isSynced && <i className="fa-kit fa-regular-watch-smart-circle-arrow-left" style={{ fontSize: '30px' }}></i>}
											{isActionToComplete && <i className="fa-solid fa-circle-check" aria-hidden="true" style={{ fontSize: '30px', color: '#999' }}></i>}
											{isCompleted && <i className="fa-solid fa-circle-check" aria-hidden="true" style={{ fontSize: '30px', color: '#9FCD2B' }}></i>}
											{isSynced && <i className="fa-kit fa-regular-watch-smart-circle-arrow-left" aria-hidden="true" style={{ fontSize: '30px', color: '#9FCD2B' }}></i>}
											<Grid sx={{ fontSize: '11px', fontFamily: 'Poppins-Light' }}>{t}</Grid>
										</Grid>
									</Button>
								</Grid>
							}
							) :
							// @@@ Edit Activity Details Save Button
							<Grid container xs={12} sx={{ mx: '20px', mb: '10px', mt: '1rem' }} justifyContent='center' alignItems="center">
								<RSButton sx={{ mt: '1rem' }} 
								onClick={async () => {
									 await handleSubmit(distance).then(() => {
										setIsSurfaceAdjustedPaceValues(false)
									 })
									 
								}}>Save</RSButton>
							</Grid>}
					</Grid>
					: ''
			}
		</>

		const activityActionsButtons = <>
			{
				(currentDayActivities && currentDayActivities?.length == 0 && trainVal?.toUpperCase() !== 'REST') ?
					!isMarkComplete && <Grid container alignItems="center" justifyContent={'flex-end'}
						sx={{ mt: isValid ? '0rem' : '1rem', pr: '-1rem' }} >
						{!isTraining && <Grid sx={{ mt: '10px', mr: '-0.4rem' }} justifyContent={'flex-end'} alignItems="center">
							{!isFromDrawer && <Button onClick={() => setOpenSurface(true)}>
								<Grid sx={{ position: 'relative', display: 'flex', flexDirection: 'column' }} justifyContent='center' alignItems='center'>
									<Box sx={{ height: '30px' }}>{activeSurfaceIcon && daySurfaceType ? activeSurfaceIcon : <RSIcon type='road' />}</Box>
									<Grid sx={{ fontSize: '11px', fontFamily: 'Poppins-Light', color: 'black' }}>{'Surface'}</Grid>
								</Grid>
							</Button>}
						</Grid>}
						{!isFromDrawer ?
							[isTodayAndFuture && (trainVal?.toUpperCase() !== "CROSS TRAIN") ? `${userGarminDetails?.status && !hasSyncData ? 'Send' : userGarminDetails?.status && hasSyncData ? 'Synced' : ''}` : undefined,
							`${isDayComplete ? 'Completed' : 'Complete'}`
							].map((t, i) => {
								const isCompleted = t === 'Completed'
								const isSynced = t === "Synced"
								const isActionToComplete = t === 'Complete'
								const isActionToSend = t === 'Send'
								return !(i && isAfterCurrentDate) && t && <Grid key={i} sx={{ mt: '10px', mr: '-0.4rem' }} justifyContent={'flex-end'} alignItems="center">
									<Button onClick={() => (isCompleted || isSynced) ? handleMarkComplete(false) : handleCompleteNSync(i)}
										sx={{ color: '#010101' }}>
										<Grid sx={{ position: 'relative', display: 'flex', flexDirection: 'column' }} justifyContent='center' alignItems='center'>
											{/* {isC && (i ? <i className="fa-light fa-watch" style={{ position: 'absolute', left: -25, marginTop: '1px', fontSize: '40px' }} /> : <CheckIcon sx={{ position: 'absolute', left: -25, mt: '1px', fontSize: '40px' }} />)} */}
											{isActionToSend && !isSynced && <i className="fa-kit fa-regular-watch-smart-circle-arrow-left" aria-hidden="true" style={{ fontSize: '30px' }}></i>}
											{isActionToComplete && <i className="fa-solid fa-circle-check" aria-hidden="true" style={{ fontSize: '30px', color: '#999' }}></i>}
											{isCompleted && <i className="fa-solid fa-circle-check" aria-hidden="true" style={{ fontSize: '30px', color: '#9FCD2B' }}></i>}
											{isSynced && <i className="fa-kit fa-regular-watch-smart-circle-arrow-left" aria-hidden="true" style={{ fontSize: '30px', color: '#9FCD2B' }}></i>}
											<Grid sx={{ fontSize: '11px', fontFamily: 'Poppins-Light' }}>{t}</Grid>
										</Grid>
									</Button>
								</Grid>
							}
							) : <Grid container xs={12} sx={{ mx: '20px', mb: '10px' }} justifyContent='center' alignItems="center">
								<RSButton sx={{ mt: '10px' }} onClick={async () => await handleSubmit(distance)}>Save</RSButton>
							</Grid>}
					</Grid>
					: ''
			}
		</>

		const renderCrossTrainingVideos = () => <>
			{
				rr && recoveryAssessmentsDays && isAssessmentDone && workoutVideos?.videos2?.length == 0 ? (
					<>
						{
							videoContainerList(handleGetRecoveryWorkoutVideos(rr.recoveryPlan.mobility_video_ids && rr.recoveryPlan.mobility_video_ids !== "" ? rr.recoveryPlan.mobility_video_ids : defaultExercises.mobility, 'mobility'))
						}
					</>

				) : undefined
			}

			{
				!isFromDrawer && trainVal?.toLowerCase() !== "rest" && Object.keys(workoutVideos).map((w, k) =>
					workoutVideos[w]?.length > 0 &&
					<>
						{
							w === 'videos1' && !rr ?
								<Grid container key={k + 'v'} alignItems="center" sx={{
									border: '1px solid rgba(0, 0, 0, 0.176)',
									width: '100%',
									fontSize: '16px',
									borderRadius: '5px',
									backgroundColor: 'white',
									position: 'relative',
									mt: '36px'
								}}
								>
									<Grid container xs={12} sx={{ p: '15px' }} justifyContent='flex-start' alignItems="center">
										{workoutVideos[w].map((wv: any, i: number) =>
											<Grid container key={i + w} sx={{ fontFamily: 'Poppins-Light', fontSize: '16px' }} justifyContent='flex-start' alignItems="center">
												<img
													style={{ width: '20px', height: '20px', marginRight: '10px' }}
													src="./images/icons/tick-mark.png"
													alt="tick-mark"
												/>
												{wv?.description} {wv?.title?.toUpperCase() !== 'CROSS TRAIN' ? wv.title : ''}
											</Grid>
										)}
									</Grid>
								</Grid>
								: w === 'videos2' && rr ? (
									<Grid container xs={12} justifyContent='flex-start' alignItems="center">
										{
											rr && (workoutVideos.videos2[0]?.link.toLowerCase().includes('foam') || trainVal?.toUpperCase() === "CROSS TRAIN") ? (
												<>
													{
														!recoveryAssessmentsDays && (hasAssessment || rr.recoveryPlan.mobility_video_ids) && <Grid container xs={12} sx={{ pt: '20px', textDecoration: 'underline', color: '#010101', mb: '-25px' }} justifyContent="flex-start" alignItems="center">
															<Button onClick={() => setShowAssessment(true)} sx={{ textTransform: 'none', color: '#010101', fontSize: '14px', FontFamily: 'Poppins-Light' }}>Retake assessment</Button>
														</Grid>
													}
													{
														videoContainerList(handleGetRecoveryWorkoutVideos(rr.recoveryPlan.mobility_video_ids && rr.recoveryPlan.mobility_video_ids !== "" ? rr.recoveryPlan.mobility_video_ids : defaultExercises.mobility, 'mobility'))
													}
												</>

											) : rr && (workoutVideos.videos2[0]?.link.toLowerCase().includes('base') || workoutVideos.videos2[0]?.link.toLowerCase().includes('yoga') || workoutVideos.videos2[0]?.link.toLowerCase().includes('exercises')) ? (
												<>
													{
														!recoveryAssessmentsDays && (hasAssessment || rr.recoveryPlan.strength_video_ids) && <Grid container xs={12} sx={{ pt: '20px', textDecoration: 'underline', color: '#010101', mb: '-25px' }} justifyContent="flex-start" alignItems="center">
															<Button onClick={() => setShowAssessment(true)} sx={{ textTransform: 'none', color: '#010101', fontSize: '14px', fontFamily: 'Poppins-Light' }}>Retake assessment</Button>
														</Grid>
													}
													{
														videoContainerList(handleGetRecoveryWorkoutVideos(rr.recoveryPlan.strength_video_ids && rr.recoveryPlan.strength_video_ids !== "" ? rr.recoveryPlan.strength_video_ids : defaultExercises.strength, 'strength'))
													}
												</>
											) : undefined
										}
									</Grid>
								) : w === 'videos2' ?
									<>{videoContainerList(workoutVideos[w].map((wv: any, i: number) => {
										const wvData = getWorkoutVideoDetails(wv?.link)
										const eType = wvData?.exercise_type
										return { id: wv.id, headingSubtitle: eType ? eType : wvData?.main_headline, headingTitle: eType ? wvData?.main_headline : wv?.title, src: wvData?.img_title, onClick: () => handleOpenVideo(wv?.link) }
									}))}</> : undefined
						}
					</>
				)
			}
			{
				rr && trainVal?.toUpperCase() === "REST" ?
					<>
						{
							videoContainerList(handleGetRecoveryWorkoutVideos(rr.recoveryPlan.mobility_video_ids && rr.recoveryPlan.mobility_video_ids !== "" ? rr.recoveryPlan.mobility_video_ids : defaultExercises.mobility, 'mobility'))
						}
					</>
					: undefined
			}
		</>

		return (
			<Box key={trainVal} sx={{ display: 'flex', flexDirection: `column${isFromDrawer && isMarkComplete ? '-reverse' : ''}`, width: '100%', mt: isFromDrawer ? '0px' : '10px', fontFamily: 'Poppins', position: 'relative' }}>

				<Box sx={{ display: 'flex', flexDirection: 'column', backgroundColor: 'white', width: '100%', p: isTraining ? '1.2rem 0.9rem 0rem 0.9rem' : '1.4rem 1.4rem 0rem 1.4rem', borderRadius: '10px' }}>

					{!isFromDrawer && <>
						{isAssessmentDone || isRD || isStretchRoll ? <></> : (

							<Grid sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', ml: isTraining ? '0.4rem' : '0rem' }}>

								{/* @@@ Daily Activity Plan Header */}
								<i className={`fa-duotone fa-solid ${isTraining ? trainIcon : isRest ? 'fa-face-sleeping' : 'fa-person-running'}`} style={{ fontSize: isTraining ? '22px' : '40px', marginRight: '0.4rem' }}></i>
								<Grid container xs={12} sx={{ fontFamily: 'Poppins', fontSize: '16px', display: 'flex', flexDirection: 'column' }} justifyContent='flex-start'>
									<Grid sx={{ fontFamily: 'Poppins-Bold', fontSize: '23px' }} >{ !isRest && !isTraining && daySurfaceType ? daySurfaceType.charAt(0).toUpperCase() + daySurfaceType.slice(1) : ''} {isTraining ? 'Cross Training' : isRest ? 'Rest' : 'Running'}</Grid>
									{/* {desc && <Box sx={{ width: '14px', height: '14px', backgroundColor: `${getColorCode(desc)}`, mr: '8px', borderRadius: '2px' }} />} */}
									<Box sx={{ backgroundColor: getColorCode(desc), width: 'fit-content', px: '1rem', borderRadius: '10px', display: 'flex', justifyContent: 'center' }} alignContent={'center'} alignItems={'center'}>{desc && (desc === 'RECOVERY' ? 'Recover' : desc)}</Box>
								</Grid>
							</Grid>)}
					</>}

					{isTraining && <Grid sx={{ mt: '-1rem' }}>{renderCrossTrainingVideos()}</Grid>}


					<Grid container xs={12} sx={{ flexDirection: 'column', mt: '1rem', mb: '0.5rem' }}>

						{/* @@@ DISTANCE AND DURATION HEADER & VALUES */}
						{!isTraining && !isRest && !isRacePace && !isFromDrawer && <Grid container sx={{ flexDirection: 'row', mt: '0.5rem', pb: '1rem', borderBottom: '1px solid #AAA' }}>

							<Grid container xs={isCompleteInActive ? 6 : 7} sx={{ flexDirection: 'column' }} >
								{!isRacePace && <> <Grid sx={{ fontFamily: 'Poppins-Semibold', fontSize: '14px', mb: '1rem' }}>Distance</Grid>
									<Grid sx={{ fontFamily: 'Poppins-Semibold', fontSize: '50px', lineHeight: '1rem' }} >
										{isNumber ? Number(distance).toFixed(1) : trainVal}
										<span style={{ fontFamily: 'Poppins', fontSize: '20px', color: '#999' }}>{titleCase(valSplit.slice(1).join(' ')).replace(/les/g, '').replace(/kilometers/g, 'km').toLowerCase()}</span>
									</Grid> </>}
							</Grid>

							<Grid container xs={isCompleteInActive ? 6 : 5} sx={{ flexDirection: 'column' }} >
								<Grid sx={{ fontFamily: 'Poppins-Semibold', fontSize: '14px', mt: '1.4rem' }}>Est. Duration</Grid>
								<Grid sx={{ fontFamily: 'Poppins-Medium', fontSize: '20px', lineHeight: '1rem' }} >
									<span style={{ fontFamily: 'Poppins-Medium', fontSize: '20px', marginRight: '0.1rem' }}>~{hasDuration ? totalDuration < 60 ? totalDuration : Math.floor(totalDuration / 60) : ''}</span>
									{hasDuration && totalDuration >= 60 && <span style={{ fontFamily: 'Poppins', fontSize: '16px', color: '#999' }}>hr </span>}
									<span style={{ fontFamily: 'Poppins-Medium', fontSize: '20px', marginRight: '0.1rem' }}>{hasDuration ? totalDuration < 60 ? '' : totalDuration % 60 : ''}</span>
									<span style={{ fontFamily: 'Poppins', fontSize: '16px', color: '#999' }}>mins</span>
								</Grid>
							</Grid>
						</Grid>}



						{/* old distance box */}
						{/* {!isFromDrawer && <Button onClick={() => setIsEditing(true)} disabled={!isEditable} sx={{ border: `1px solid ${isEditable ? 'rgba(0, 0, 0, 0.176)' : 'none'}`, textTransform: 'none', color: isEditable ? '#010101' : 'black !important', p: `${isShowEdit || isSeeLaps ? '20px' : '15px 0px 5px 0px'}`, my: '5px', mr: '5px', borderRadius: '5px', flexDirection: 'column', backgroundColor: 'white !important', width: `${isShowEdit || isSeeLaps ? 'fit-content' : '120px'}` }}>
						<Box sx={{ lineHeight: '1em', fontFamily: 'Poppins', fontSize: '25px' }}></Box>
						{!isShowEdit && !isSeeLaps && <Box sx={{ fontFamily: 'Poppins', fontSize: '16px' }}>{titleCase(valSplit.slice(1).join(' '))}</Box>}
						</Button>} */}

						{/* old sync to garmin button + mark as complete button */}
						{/* {!isDayActivity && !isFromDrawer && allButtons} */}

						{!isShowEdit && <Grid container sx={{ p: 0 }}>

							{/* old interval box style */}
							{/* <Grid container alignItems="center" sx={{
							border: isCompleteInActive || isDayActivity ? 'none' : '1px solid rgba(0, 0, 0, 0.176)',
							width: isDayActivity ? 'fit-content' : '100%',
							fontSize: '16px',
							p: isDayActivity && !isFromDrawer ? 0 : `${isFromDrawer ? '25px 20px' : '15px 10px 25px 10px'}`,
							borderRadius: '5px',
							backgroundColor: isFromDrawer || isDayActivity ? 'transparent' : 'white',
							position: 'relative',
							mt: isDayActivity ? 0 : '10px'
							}}
							> */}

							<Grid container alignItems="center" sx={{ p: '0px 5px 10px' }}
							>

								{/* @@@ Edit Button / Pencil Icon */}
								{/* old edit button icon */}
								{/* {isEditable && <IconButton sx={{ color: 'black', position: 'absolute', top: 0, right: 0 }} onClick={() => setIsEditing(true)}>
									<svg height="22px" fill="#505050" viewBox="0 0 512 512"><path d="M441 58.9L453.1 71c9.4 9.4 9.4 24.6 0 33.9L424 134.1 377.9 88 407 58.9c9.4-9.4 24.6-9.4 33.9 0zM209.8 256.2L344 121.9 390.1 168 255.8 302.2c-2.9 2.9-6.5 5-10.4 6.1l-58.5 16.7 16.7-58.5c1.1-3.9 3.2-7.5 6.1-10.4zM373.1 25L175.8 222.2c-8.7 8.7-15 19.4-18.3 31.1l-28.6 100c-2.4 8.4-.1 17.4 6.1 23.6s15.2 8.5 23.6 6.1l100-28.6c11.8-3.4 22.5-9.7 31.1-18.3L487 138.9c28.1-28.1 28.1-73.7 0-101.8L474.9 25C446.8-3.1 401.2-3.1 373.1 25zM88 64C39.4 64 0 103.4 0 152V424c0 48.6 39.4 88 88 88H360c48.6 0 88-39.4 88-88V312c0-13.3-10.7-24-24-24s-24 10.7-24 24V424c0 22.1-17.9 40-40 40H88c-22.1 0-40-17.9-40-40V152c0-22.1 17.9-40 40-40H200c13.3 0 24-10.7 24-24s-10.7-24-24-24H88z" /></svg>
								</IconButton>} */}
								{isEditable && <Grid>
									<i className="fa-light fa-pen" style={{ position: 'absolute', top: '1.2rem', right: '1.2rem', fontSize: '20px' }} onClick={() => setIsEditing(true)}></i>
								</Grid>
								}

								{isFromDrawer && <Grid container xs={12} sx={{ px: '5px', pb: '1rem', borderBottom: '1px solid #AAA' }} justifyContent='flex-start' alignItems="center">
									{/* @@@ Edit Total Distance */}
									<Grid container xs={desc ? 5 : 12} sx={{ lineHeight: 1.2, fontFamily: 'Poppins-Light', fontSize: '20px' }} justifyContent='flex-start' alignItems="center">
										{isNumber && isCompleteInActive ? <><input className='daily-activity-input' type='number' value={miles} onKeyDown={(e) => (e.keyCode === 69 || e.keyCode === 187 || e.keyCode === 189) && e.preventDefault()} onChange={(e) => setMiles(e.target.value)} placeholder={distance} /> {valSplit.slice(1).join(' ')}</> : trainVal}
									</Grid>
									<Grid container xs={7} sx={{ lineHeight: 1.2, fontFamily: 'Poppins-Light', fontSize: '20px', textTransform: 'uppercase' }} justifyContent='flex-start' alignItems="center">
										{desc && <Box sx={{ width: '16px', height: '16px', backgroundColor: `${getColorCode(desc)}`, mr: '8px', borderRadius: '2px' }} />}
										{desc && (desc === 'RECOVERY' ? 'RECOVER' : desc)}
									</Grid>
								</Grid>}

								{dayData?.marathonPaces.length > 0 && (dayData?.marathonPaces[0].notes !== "" || dayData?.marathonPaces[0].pace !== "") &&
									<Grid container xs={12} sx={{ pt: isFromDrawer ? '15px' : 0 }} justifyContent='flex-start' alignItems="center">

										{/* {isFromDrawer && <Box sx={{ width: '100%', height: '1px', mt: '15px', mb: '10px', borderBottom: '2px solid rgba(0, 0, 0, 0.176)' }} />} */}

										<b style={{ fontFamily: 'Poppins-Bold', fontSize: '18px', marginTop: '0.5rem', marginBottom: '0.5rem' }}>Details</b>
										{(!isDayActivity || isFromDrawer) && <Grid container xs={12} sx={{ p: '10px 5px 2px 5px', pt: isFromDrawer ? '10px' : 0 }} justifyContent='flex-start' alignItems="center">
											{!isDayActivity && <Grid container xs={isCompleteInActive ? 6 : 7} sx={{ fontFamily: 'Poppins', fontSize: '16px' }}>
												<span style={{ fontFamily: 'Poppins-Semibold', fontSize: '1.1rem' }}>Distance </span>
												{/* <span style={{ fontFamily: 'Poppins-Semibold' }}>{userInfo?.km == 'Y' ? ' (km)' : ' (mi)'}</span> */}
											</Grid>}
											<Grid container xs={isCompleteInActive ? 6 : 5} sx={{ fontFamily: 'Poppins', fontSize: '16px' }}>
												<Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'end' }}>
													<span style={{ fontFamily: 'Poppins-Semibold', fontSize: '1.1rem' }}>Pace</span>
													{/* {prevSurfaceType?.toLowerCase().includes('tread') && <Box sx={{ color: 'black', fontSize: '11px', lineHeight: '0.3rem', fontFamily: 'Poppins' }} >{userInfo?.km == 'Y' ? 'kmph' : 'mph'}</Box>} */}
												</Box>
												{/* {surfaceUnit != 'MPH' ? '' : '(mph)'} */}
												{/* <span style={{ fontFamily: 'Poppins-Semibold' }}>{userInfo?.km == 'Y' ? ' (kmph)' : ' (mph)'}</span> */}
											</Grid>
										</Grid>}

										{isDayActivity &&
											<Grid container xs={12} sx={{ p: '10px 5px 2px 5px', pt: isFromDrawer ? '10px' : 0 }} justifyContent='space-between' alignItems="center">
												<Grid container xs={isCompleteInActive ? 6 : 7} sx={{ fontFamily: 'Poppins', fontSize: '16px' }}>
													<span style={{ fontFamily: 'Poppins-Semibold' }}>Distance</span>
												</Grid>
												<Grid container xs={isCompleteInActive ? 6 : 5} sx={{ fontFamily: 'Poppins', fontSize: '16px' }}>
													<Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'end' }}>
														<span style={{ fontFamily: 'Poppins-Semibold' }}>Pace</span>
														{/* {prevSurfaceType?.toLowerCase().includes('tread') && <Box sx={{ color: 'black', fontSize: '11px', lineHeight: '0.3rem', fontFamily: 'Poppins' }} >{userInfo?.km == 'Y' ? 'kmph' : 'mph'}</Box>} */}
													</Box>
													{/* {surfaceUnit != 'MPH' ? '' : '(mph)'} */}
													{/* <span style={{ fontFamily: 'Poppins-Semibold' }}>{userInfo?.km == 'Y' ? ' (kmph)' : ' (mph)'}</span> */}
												</Grid>

											</Grid>
										}

										{dayData?.marathonPaces?.map((val: any, idx: number) => {
											const curDistance = getCurrentDistance(dayData?.marathon?.id)
											let distanceValue = distanceInterpret(val?.notes, userInfo?.km == 'Y', val?.pace,
												curDistance && isDayActivity ? curDistance : dayData?.marathon?.distance,
												convertTextToIntervalTime(val?.notes, dayData.marathonPaces[idx - 1]),
												isDayActivity ? curDistance : undefined
											)
											
											const paceValue = getConvertedPace(val).value[0]

											// console.log('>>> dayData marathonPaces', val)
											// console.log('>>> orig paceValue', paceValue)

											// paceValue = (isSurfaceAdjustedPaceValues && val.multiplier && activeSurface != defaultSurfaceType) ? revertedMultipliedPace(paceValue, val.multiplier) : paceValue

											// isSurfaceAdjustedPaceValues && val.multiplier && console.log('updated value', revertedMultipliedPace(paceValue, val.multiplier))
											// console.log('>>> new paceValue', paceValue)

											const paceUnit = getConvertedPace(val).value[0] !== "" ? "/" + getConvertedPace(val)?.value[1]?.trim() : ""
											const isValueLong = isLongText(distanceValue)
											const hasTimeTrial = !isDayActivity ? distanceValue?.includes('Time Trial') : false
											distanceValue = distanceValue?.toLowerCase()?.includes("repeat") ? `${distanceValue} total.` : distanceValue
											distanceValue = hasTimeTrial ? distanceValue?.replace(' Time Trial', 's') : distanceValue
											const finalDValue = distanceValue?.split(' ')
											const paceValueArr = paceValue?.split(' ')
											const v1 = paceValueArr?.find((v: any) => v.includes('m'))
											const v2 = paceValueArr?.find((v: any) => v.includes('s'))
											const curVal = paces?.[idx]?.pace?.split(' ')
											const c1 = curVal?.find((v: any) => v.includes('m'))
											const c2 = curVal?.find((v: any) => v.includes('s'))
											const grouped = groupPaces(dayData?.marathonPaces[idx + 1]?.notes.trim(), val?.notes?.trim(), val?.pace?.trim(), idx)
											const isValueLongNext = isLongText(dayData?.marathonPaces[idx + 1]?.notes.trim())


											let rr: RaceResult | undefined = undefined;
											let maxWeeks: [] | undefined = []

											if (userInfo?.raceResults) {
												rr = userInfo?.raceResults
												maxWeeks = JSON.parse(rr?.recoveryPlan?.recoveryType?.max_week_percentage as string | "[]");
											}

											const isNumber = finalDValue ? is_numeric(finalDValue[0]) : false

											if (isNumber) {
												if (finalDValue[0]) {
													finalDValue[0] = !hasCustomDistance && is_numeric(finalDValue[0]) && rr && maxWeeks ? (finalDValue[0] * maxWeeks[selectedWeek?.key - 1]).toFixed(1) : finalDValue[0]
												}
												if (finalDValue[1]) {
													finalDValue[1] = titleCase(finalDValue[1])
												}
											}

											const treadUnit = displayTread ? userInfo?.km === "Y" ? ' kmph' : ' mph' : ''

											// !isNaN(Number(finalDValue[0])) ? Number(finalDValue[0]).toFixed(1) : finalDValue[0].includes

											return <Grid container key={idx + 'dp'} sx={{ p: isDayActivity ? 0 : '5px', mb: isDayActivity ? '0' : 0 }}>

												{/* @@@ Distance Intervals Values */}
												{<Grid container xs={isValueLong ? 6 : 6} alignItems='center' sx={{ overflowWrap: 'break-word', ml: isDayActivity ? '0.4rem' : 0, mr: '-0.4rem', mt: isValueLong ? '-10px' : 0, mb: isValueLong ? '3px' : (grouped && !isValueLongNext) ? '-15px' : 0, lineHeight: isValueLong ? '1em' : '', fontFamily: 'Poppins-light', fontSize: isValueLong ? '14px' : '16px', color: isValueLong ? 'gray' : 'black' }}>
													{/* {!isNaN(Number(finalDValue[0])) ? Number(finalDValue[0]).toFixed(1) : finalDValue[0]} */}

													{/* {finalDValue.slice(1).join(' ').includes('mile') || finalDValue.slice(1).join(' ').includes('kilo') ? '' : finalDValue.slice(1).join(' ')} */}
													{finalDValue[0]} {!isDayActivity ? finalDValue.slice(1).join(' ') : userInfo?.km === "Y" ? 'km' : 'miles'}
													{/* {testDistance} */}
												</Grid>
												}

												{hasTimeTrial && <Grid container xs={12} alignItems='center' sx={{ lineHeight: '1em', fontFamily: 'Poppins-light', fontSize: '14px', color: 'gray' }}>
													Time Trial
												</Grid>
												}

												{/* @@@ Pace Values */}
												{!isValueLong && <Grid container xs={6} justifyContent='flex-end' alignItems='center' >
													<Grid container xs={isCompleteInActive ? 12 : 10} sx={{ fontFamily: 'Poppins-light', fontSize: '16px' }} alignItems='center'>
														{/* old mb for the grid above */}
														{/* mb: isDayActivity ? '-2.2rem' : (grouped && !isValueLongNext) ? (isFromDrawer && !isMarkComplete && !isValueLong ? '-10px' : '-15px') : '' */}

														{isFromDrawer && !isMarkComplete && !isValueLong && paceValue ?
															<>
																{/* @@@ Edit Pace Input */}
																<input type='number' className='daily-activity-input' placeholder={v1 ? v1 : '0m'} value={(c1 || c1 === 'm|') && c1.includes('|') ? (c1 === 'm|' ? 0 : c1.replace('m|', '')) : ''} onKeyDown={(e) => (e.keyCode === 69 || e.keyCode === 187 || e.keyCode === 189) && e.preventDefault()} onChange={(e) => handlePacesChange(e, idx, 'm', val, paceValue)} />
																<input type='number' max="59" className='daily-activity-input' placeholder={v2 ? v2 : '0s'} value={(c2 || c2 === 's|') && c2.includes('|') ? (c2 === 's|' ? 0 : c2.replace('s|', '')) : ''} onKeyDown={(e) => (e.keyCode === 69 || e.keyCode === 187 || e.keyCode === 189) && e.preventDefault()} onChange={(e) => handlePacesChange(e, idx, 's', val, paceValue)} />
															</>
															: isDayActivity ? <Grid sx={{ display: 'flex', flexDirection: 'row', fontFamily: 'Poppins-light', fontSize: '16px' }}>
																{/* <Button onClick={() => setIsEditing(true)} disabled={!isEditable} sx={{ width: `${isShowEdit ? 'fit-content' : '120px'}`, border: `1px solid ${isEditable ? 'rgba(0, 0, 0, 0.176)' : 'none'}`, textTransform: 'none', color: isEditable ? '#010101' : 'black !important', p: `${isShowEdit ? '20px' : '15px 0px 5px 0px'}`, my: '5px', mr: '5px', borderRadius: '5px', flexDirection: 'column', backgroundColor: 'white !important' }}>
																	<Box sx={{ lineHeight: '1em', fontFamily: 'Poppins', fontSize: '25px' }}>{v1 ? v1.replace('m', ':') : ''}{v2 ? v2.replace('s', '').padStart(2, '0') : ''}</Box>
																	<Box sx={{ fontFamily: 'Poppins', fontSize: '16px' }}>Pace</Box>
																</Button> */}
																{/* {v1 ? v1.replace('m', ':') : ''}{v2 ? v2.replace('s', '').padStart(2, '0') : ''} */}
																{prevSurfaceType?.toLowerCase().includes('tread') ? calculatePaceSpeed(paceValue) : paceValue} {treadUnit} {treadUnit == '' && paceUnit}
															</Grid>
																:
																<>{prevSurfaceType?.toLowerCase().includes('tread') ? calculatePaceSpeed(paceValue) : paceValue}{treadUnit} {treadUnit == '' && paceUnit}</>}
													</Grid>
												</Grid>
												}


												{/* {
												groupPaces(dayData?.marathonPaces[idx + 1]?.notes.trim(), val?.notes?.trim(), val?.pace?.trim(), idx) || isLongText(dayData?.marathonPaces[idx + 1]) ? '' : (
													<>
														<Box sx={{ width: '100%', height: '1px', borderBottom: '1px solid rgba(0, 0, 0, 0.176)' }} />
													</>)
											} */}
											</Grid>
										}
										)}
									</Grid>
								}

								{/* @@@ Complete and Sync/Send Buttons */}
								{!isDayActivity && !isFromDrawer && activityActionsButtons}
							</Grid>
						</Grid>}
					</Grid>

					{(isDayActivity || isFromDrawer) && allButtons}

				</Box>

				{/* @@@ notes area plan tab*/}
				{!isFromDrawer && <Box sx={{ display: 'flex', width: '100%', backgroundColor: 'white', p: '0.4rem 1.4rem 1.4rem 1.4rem', m: '0.8rem 0 0.4rem 0', borderRadius: '10px' }}>
					{!(moment(selectedWeek?.week?.replaceAll('-', '/'))?.add(selectedDay, 'd')?.isAfter(moment(userInfo?.race_date)) && userInfo?.type !== 4) && renderNotesArea()}
				</Box>}

				{
					rr && recoveryAssessmentsDays && (isAssessmentDone || rr.recoveryPlan.mobility_video_ids || rr.recoveryPlan.strength_video_ids) && <Grid container xs={12} sx={{ pt: '20px', textDecoration: 'underline', color: '#010101', mb: '-25px' }} justifyContent="flex-start" alignItems="center">
						<Button onClick={() => setShowAssessment(true)} sx={{ textTransform: 'none', color: '#010101', fontSize: '14px', fontFamily: 'Poppins-Light' }}>Retake assessment</Button>
					</Grid>
				}

				{!isFromDrawer && workoutVideos.videos2.length > 0 && !isTraining && renderCrossTrainingButton(trainIcon)}


				<RSDrawer
					bottom
					fitContent
					open={displayCrossTrainingModal}
					popUpWidth='fit-content'
					onClose={handleCloseCrossTrainingModal}
				>

					<Box sx={{ px: '1rem', pb: '1rem', minHeight: isMobile ? '36vh' : '50vh', width: isMobile ? '100%' : '50rem' }}>

						<Grid sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', ml: '0.4rem', mt: '1rem' }}>

							<i className={`fa-duotone fa-solid ${trainIcon}`} style={{ fontSize: '22px', marginRight: '0.4rem' }}></i>
							<Grid container xs={12} sx={{ fontFamily: 'Poppins', fontSize: '16px', display: 'flex', flexDirection: 'column' }} justifyContent='flex-start'>
								<Grid sx={{ fontFamily: 'Poppins-Bold', fontSize: '23px' }} >{'Cross Training'}</Grid>
							</Grid>
						</Grid>

						<Grid sx={{ mt: '-1rem' }}>{renderCrossTrainingVideos()}</Grid>

					</Box>

				</RSDrawer>



			</Box >
		)
	}

	const renderStartRecover = () => {
		const isBeforeRD = userInfo?.race_date ? moment(moment().format('YYYY/MM/DD')).isBefore(moment(userInfo.race_date).add(1, 'd').format('YYYY/MM/DD')) : false
		return <Grid container>
			<Grid container className='faded faded-bottom' xs={12} sx={{ overflow: 'hidden' }} justifyContent="flex-start" alignItems="center">
				<span>
					<img src="img/recover.jpg" />
				</span>
			</Grid>
			<Grid container xs={12} sx={{ fontSize: '18px', fontFamily: 'Poppins-medium', px: 2 }} justifyContent='center' alignItems='center'>
				Your Recovery Plan is ready.
			</Grid>
			<Grid container xs={12} sx={{ px: 2, lineHeight: '1em', fontSize: '16px', fontFamily: 'Poppins-Light', textAlign: 'center' }} >
				Take the next step in your running journey with your Physical Therapist-designed recovery assessment and plan.
			</Grid>
			{(!raceResults || raceResults.length === 0) && <Grid container xs={12} sx={{ mt: '40px', px: 2 }} >
				<RSButton disabled={isBeforeRD} onClick={() => setUpdateRaceResult(true)} sx={{ p: '8px 0px' }}>
					{isBeforeRD ? `Begins ${moment(userInfo?.race_date).add(1, 'd').format('MMM DD')}` : 'Enter Race Results'}
				</RSButton>
			</Grid>}
		</Grid>
	}

	const renderAddMaintenancePlan = () => {
		return <AddMaintenancePlan addMaintenance={addMaintenance} setAddMaintenance={setAddMaintenance} doNotAddMaintenance={doNotAddMaintenance} setDoNotAddMaintenance={setDoNotAddMaintenance} userInfo={userInfo} getRaceResult={getRaceResult} setLoadingValues={setLoadingValues} />
	}

	const renderTakeNextStep = () => {
		return <Grid container>
			<Grid container className='faded faded-bottom' xs={12} sx={{ overflow: 'hidden' }} justifyContent="flex-start" alignItems="center">
				<span>
					<img src="img/recover.jpg" />
				</span>
			</Grid>
			<Grid container xs={12} sx={{ fontSize: '18px', fontFamily: 'Poppins-medium', px: 2 }} justifyContent='center' alignItems='center'>
				Take the Next Step
			</Grid>
			<Grid container xs={12} sx={{ px: 2, lineHeight: '1em', fontSize: '16px', fontFamily: 'Poppins-Light', textAlign: 'center' }} >
				Build a plan to continue your running journey
			</Grid>
			<Grid container xs={12} sx={{ mt: '40px', px: 2 }} >
				<Button onClick={() => navigate('/training-plan/build/step-1', { replace: true })} variant='contained' sx={{ boxShadow: 'none', color: '#010101', width: '100%', fontFamily: 'Poppins', border: '1px solid #9FCD2B', p: '8px 0px', borderRadius: '5px', fontSize: '14px', backgroundColor: '#9FCD2B !important', textTransform: 'none' }}>
					Add Next Plan
				</Button>
			</Grid>
		</Grid>
	}

	const renderBuildMaintenancePlan = () => {
		return <Grid container>
			<Grid container className='faded faded-bottom' xs={12} sx={{ overflow: 'hidden' }} justifyContent="flex-start" alignItems="center">
				<span>
					<img src="img/recover.jpg" />
				</span>
			</Grid>
			<Grid container xs={12} sx={{ fontSize: '18px', fontFamily: 'Poppins-medium', px: 2 }} justifyContent='center' alignItems='center'>
				Build Your Maintenance Plan
			</Grid>
			<Grid container xs={12} sx={{ px: 2, lineHeight: '1em', fontSize: '16px', fontFamily: 'Poppins-Light', textAlign: 'center' }} >
				{`Remaining consistent in your running will not only keep you fit, it's essential to remaining injury-free.`}
			</Grid>
			<Grid container xs={12} sx={{ mt: '40px', px: 2 }} >
				<Button onClick={() => setAddMaintenance(true)} variant='contained' sx={{ boxShadow: 'none', color: '#010101', width: '100%', fontFamily: 'Poppins', border: '1px solid #9FCD2B', p: '8px 0px', borderRadius: '5px', fontSize: '14px', backgroundColor: '#9FCD2B !important', textTransform: 'none' }}>
					Add Maintenance Plan
				</Button>
			</Grid>
			<Grid container xs={12} sx={{ pt: '20px', pb: '5px', fontSize: '14px', textDecoration: 'underline', color: '' }} justifyContent="center" alignItems="center">
				<Button onClick={() => setDoNotAddMaintenance(true)} sx={{ textTransform: 'none', color: '#485D13' }}>{`Don't add maintenance plan`}</Button>
			</Grid>
		</Grid>
	}

	const renderLastDay = () => {
		if (userInfo?.type === 4 && userInfo?.raceResults?.id) {
			return userInfo?.raceResults?.recoveryPlan?.skip_maintenance_plan ? renderTakeNextStep() : renderBuildMaintenancePlan()
		}
		return renderStartRecover()
	}

	const renderRaceDay = () => {
		const isRaceDayAndBeyond = userInfo?.race_date ? moment(moment().format('YYYY/MM/DD')).isAfter(moment(moment(userInfo?.race_date).subtract(1, 'd').format('YYYY/MM/DD'))) : false
		const sqCount = window?.innerWidth ? Math.floor(window.innerWidth / 20) : 0
		const isC = isDayComplete ? true : false

		let distanceValue: any = raceSelection.find((rs: any) => rs.raceValue === selectedRace?.value)
		distanceValue = userInfo?.km === 'N' ? distanceValue?.distanceValue : distanceValue?.distanceValueKM
		const unit = userInfo?.km === 'N' ? 'MI' : 'KM'
		const finishValue = raceResults?.id && raceResults?.finished_time_in_sec ? raceResults.finished_time_in_sec : 0
		const finishTime = timeToString(ConvertS2TimeObject(finishValue))
		const paceValue = raceResults?.id && raceResults?.finished_time_in_sec && distanceValue ? raceResults.finished_time_in_sec / distanceValue : 0
		const paceTime = timeToString(ConvertS2TimeObject(paceValue))
		return <Grid container>
			<Grid container xs={12} sx={{ fontSize: '30px', fontFamily: 'Poppins' }} justifyContent='center' alignItems='center'>
				Race Day
			</Grid>
			{!raceResults?.id && (isC ? <>
				<Grid container xs={12} sx={{ textAlign: 'center', fontSize: '16px', fontFamily: 'Poppins' }} justifyContent='center' alignItems='center'>
					Congratulations! You did it!
				</Grid>
				<Grid container xs={12} sx={{ textAlign: 'center', fontSize: '16px', fontFamily: 'Poppins-light' }} justifyContent='center' alignItems='center'>
					How did it go?
				</Grid>
				<Grid container xs={12} sx={{ mt: '10px' }} justifyContent='center' alignItems='center'>
					<Box sx={{ width: 'fit-content' }} >
						<Button disabled={!(!raceResults || raceResults.length === 0)} onClick={() => isC && setUpdateRaceResult(true)} variant='outlined' sx={{ fontFamily: 'Poppins', border: '1px solid rgba(0, 0, 0, 0.176)', p: '8px 0px', borderRadius: '5px', px: '10px', fontSize: '14px', backgroundColor: 'white !important', textTransform: 'none', color: '#010101' }}>
							Enter Results
						</Button>
					</Box>
				</Grid>
			</>
				: <>
					<Grid container xs={12} sx={{ lineHeight: '1em', textAlign: 'center', fontSize: '16px', fontFamily: 'Poppins-light' }} justifyContent='center' alignItems='center'>
						Good Luck on your race!
					</Grid>
					<Grid container xs={12} sx={{ lineHeight: '1em', textAlign: 'center', fontSize: '16px', fontFamily: 'Poppins-light' }} justifyContent='center' alignItems='center'>
						You worked hard for this!
					</Grid>
				</>)
			}

			{isRaceDayAndBeyond && !raceResults?.id ? <Grid container xs={12} sx={{ mt: '40px' }} >
				<Button disabled={!(!raceResults || raceResults.length === 0)} onClick={() => handleMarkFinish(isC)} variant={`${isC ? 'contained' : 'outlined'}`} sx={{ fontFamily: 'Poppins', border: `1px solid ${isC && (!raceResults || raceResults.length === 0) ? '#9FCD2B' : 'rgba(0, 0, 0, 0.176)'}`, p: '8px 0px', borderRadius: '5px', width: 'calc(100% - 4px)', fontSize: '14px', backgroundColor: `${isC ? `#9FCD2B ${!(!raceResults || raceResults.length === 0) ? '' : '!important'}` : 'white !important'}`, textTransform: 'none', color: '#010101' }}>
					<Grid sx={{ position: 'relative' }}>
						{isC && <SportsScoreIcon sx={{ position: 'absolute', left: -25, mt: '1px' }} />}
						{isC ? 'Race Finished' : 'Enter Results'}
					</Grid>
				</Button>
			</Grid>
				: !raceResults?.id && <Grid container xs={12} sx={{ mt: '20px' }} >{renderActivity(false, false, true)}</Grid>}

			{raceResults?.id && <Grid container xs={12} sx={{ pt: '10px' }} justifyContent='center' alignItems='center'>
				<Grid container xs={6} sx={{ pr: '10px' }}>
					<Button onClick={() => setUpdateRaceResult(true)} sx={{ width: '100%', border: '1px solid rgba(0, 0, 0, 0.176)', color: '#010101', flexDirection: 'column', py: '5px', backgroundColor: 'white', borderRadius: '10px' }}>
						<Box sx={{ fontSize: '30px', fontFamily: 'Poppins-SemiBold' }}>{finishTime}</Box>
						<Box sx={{ fontSize: '16px', fontFamily: 'Poppins', lineHeight: '1.5em' }}>FINISH TIME</Box>
					</Button>
				</Grid>
				<Grid container xs={6} sx={{ pl: '10px' }}>
					<Button onClick={() => setUpdateRaceResult(true)} sx={{ width: '100%', border: '1px solid rgba(0, 0, 0, 0.176)', color: '#010101', flexDirection: 'column', py: '5px', backgroundColor: 'white', borderRadius: '10px' }}>
						<Box sx={{ fontSize: '30px', fontFamily: 'Poppins-SemiBold' }}>{paceTime}</Box>
						<Box sx={{ fontSize: '16px', fontFamily: 'Poppins', lineHeight: '1.5em' }}>PACE / {unit}</Box>
					</Button>
				</Grid>

				{raceResults?.notes && <Grid container xs={12} sx={{ pt: '25px', px: '5px' }} justifyContent='flex-start' alignItems='center'>
					<Grid container xs={12} sx={{ fontSize: '16px', fontFamily: 'Poppins-Bold' }}>
						NOTES:
					</Grid>
					<Grid container xs={12} sx={{ fontSize: '14px', fontFamily: 'Poppins' }}>
						{raceResults.notes}
					</Grid>
				</Grid>}
			</Grid>
			}
		</Grid>
	}

	const handleSubmitCompletePlan = async (body: any) => {
		if (!raceResults?.id && body) {
			localStorage.setItem('rpReady', '1')
			localStorage.setItem('recoveryAssessment', '0')
			await CreateRaceResult(body as UserRaceResults)
		}
		await getRaceResult()
		setUpdateRaceResult(false)
		navigate('/profile?timeline=true', { replace: true })
	}

	const renderRaceResult = () => {
		return <CompletePlan updateRaceResult={updateRaceResult} setUpdateRaceResult={setUpdateRaceResult} userInfo={userInfo} raceResults={raceResults} selectedRace={selectedRace} handleSubmitCompletePlan={handleSubmitCompletePlan} />
	}

	const renderActiveTab = (isAssessmentDone?: boolean) => {
		if (activeTab === 1) {
			if (currentDayActivities?.length > 0) {
				return renderGarminActivity()
			}
			else if (appleHealthPermission?.status === "granted" || appleHealthActivity || !isNativeIOS && hasAppleHealthData) {
				return <>
					<AppleWatchWorkout
						userInfo={userInfo}
						selectedDay={selectedDay}
						selectedWeek={selectedWeek}
						appleHealthActivity={appleHealthActivity}
						isLoading={isLoadingAppleHealth}
						workoutLaps={appleHealthActivityLaps}
					/>
					{/* {appleHealthActivityLaps && appleHealthActivityLaps?.length > 0 && <AppleWatchWorkoutLaps data={appleHealthActivityLaps} userInfo={userInfo} />} */}
				</>
			}
			else {
				return isGarminConnected(userGarminDetails) ? renderSyncDevice(true, false) : renderSyncDevice(false, true)
			}
		} else if (activeTab === 2) {
			// if (currentDayActivities.length > 0)
			// 	return renderGarminLaps()
		}
		else
			return renderActivity(false, isAssessmentDone)
	}

	const renderCrossTrainActivities = () => <>
	</>

	const renderAssessmentIntro = () => {
		return <Grid container sx={{ pt: '10px' }}>
			<Grid container xs={12} sx={{ fontFamily: 'Poppins-SemiBold', fontSize: '20px', lineHeight: '1.4em' }}>
				Welcome to Your Recovery Assessment!
			</Grid>

			<Grid container xs={12} sx={{ flexDirection: 'column', pt: '25px' }}>
				<Box sx={{ fontFamily: 'Poppins-SemiBold', fontSize: '16px', lineHeight: '1.2em' }}>Estimated Time:</Box>
				<Box sx={{ fontFamily: 'Poppins-Light', fontSize: '16px', lineHeight: '1.5em' }}>15 - 30 minutes</Box>
			</Grid>

			<Grid container xs={12} sx={{ flexDirection: 'column', pt: '25px' }}>
				<Box sx={{ fontFamily: 'Poppins-SemiBold', fontSize: '16px', lineHeight: '1.2em' }}>About:</Box>
				<Box sx={{ fontFamily: 'Poppins-Light', fontSize: '16px', lineHeight: '1.4em' }}>
					Recovery is a crucial part of your training journey. It’s more than a few days off or waiting for soreness to subside.
				</Box>
				<Box sx={{ fontFamily: 'Poppins-Light', fontSize: '16px', lineHeight: '1.4em', pt: '25px' }}>
					Use our Physical Therapist-designed assessment to uncover and fix imbalances that developed through training.
				</Box>
			</Grid>

			<Grid container xs={12} sx={{ mt: '30px' }} justifyContent='flex-start' alignItems='center'>
				<Button onClick={() => setShowAssessment(true)} variant='contained' sx={{ boxShadow: 'none', height: '50px', width: '200px', fontFamily: 'Poppins', border: '1px solid #9FCD2B !important', p: '8px 20px', borderRadius: '10px', fontSize: '14px', backgroundColor: '#9FCD2B !important', color: '#010101', textTransform: 'none', justifyContent: 'flex-start', alignItems: 'center' }}>
					Begin Assessment
					<ArrowForward sx={{ position: 'absolute', right: 0, fontSize: '25px', stroke: '#9FCD2B', strokeWidth: '1px', mr: '5px' }} />
				</Button>
			</Grid>
		</Grid>
	}

	const rr: RaceResult = userInfo?.raceResults
	const recWeeks = rr?.recoveryPlan?.recoveryType?.recovery_length_in_weeks || 0
	const isRecovery = userInfo?.uid == rr?.maintenance_plan_id;

	const hasAssessment = localStorage.getItem('recoveryAssessment') == "1" || hasRecoveryAssessment ? true : false

	const startDate = weekDays[0]
	const currentWeek = weekDays[selectedWeek.key - 1]
	const currDate = moment(dayData?.date)

	let assessmentVideosDays = false;
	let recoveryAssessmentsDays = false;

	if (rr && isRecovery) {
		const recoveryType = rr?.recoveryPlan?.recoveryType;
		assessmentVideosDays = moment(currDate).isBefore(moment(startDate).add(recoveryType?.assessment_starts_in_days, 'days'))

		recoveryAssessmentsDays = moment(currDate).isSameOrAfter(moment(startDate).add(recoveryType?.assessment_starts_in_days, 'days')) && moment(currDate).isBefore(moment(startDate).add(recoveryType?.resume_running_on_day, 'days'))
	}


	const renderAssessmentDrawer = () => {
		return <RSDrawer
			bottom
			open={showAssessment}
			onClose={() => setShowAssessment(false)}
		>
			<Grid container xs={12} sx={{ pt: '20px' }}>
				<RecoveryAssessments closeAssessment={() => setShowAssessment(false)} handleUpdateRecovery={handleUpdateRecovery} currentData={userInfo} userInfo={accountInfo} />
			</Grid>
		</RSDrawer>
	}

	return <Box className='bg-main' sx={{ p: isLastDay ? 0 : 2, pt: '20px', height: 'fit-content' }}>

		<SurfaceTypes open={openSurface} setOpen={setOpenSurface} activeSurface={activeSurface} setActiveSurface={setActiveSurface} setPrevSurfaceModifier={setPrevSurfaceModifier} prevSurfaceType={prevSurfaceType} setSurfaceModifier={setSurfaceModifier} setSurfaceUnit={setSurfaceUnit} handleUpdateSurfaceType={handleUpdateSurfaceType} setActiveSurfaceIcon={setActiveSurfaceIcon} isKm={userInfo?.km === "Y"} />
		{!loadingValues && isRecovery && renderAssessmentDrawer()}
		{!isLastDay && todayDate(dayData?.date)}

		{ !loadingNewData && <AnimatePresence mode='wait' initial={false} >
			{currentId === dayData?.marathon?.id && <motion.div
				key={dayData?.date}
				initial={{ x: isForward ? '100%' : '-100%', opacity: 0 }}
				animate={{ x: 0, opacity: 1 }}
				exit={{ x: isForward ? '-100%' : '100%', opacity: 0 }}
				transition={{ duration: 0.2 }}
			>
				{
					!loadingValues && isRecovery && assessmentVideosDays ? (
						<>
							<RecoveryVideos userInfo={accountInfo} handleOpenVideo={handleOpenVideo} />
						</>
					)
						: !loadingValues && isRecovery && recoveryAssessmentsDays && !hasAssessment ? (
							<>
								{renderAssessmentIntro()}
							</>
						)
							: !loadingValues ? (<>

								{!isLastDay && <Grid container xs={12} justifyContent='center' alignItems='center'>
									{/* isGarminConnected(userGarminDetails) ? 'Garmin' : (appleHealthPermission?.status === "granted" || appleHealthActivity) ? 'Apple Watch' : 'Sync Device', lapsData?.length ? 'Laps' : '' */}
									{
										['Plan',
											'Results'
											// isGarminConnected(userGarminDetails) ? 'Garmin' : (appleHealthPermission?.status === "granted" || appleHealthActivity) ? 'Apple Watch' : 'Sync Device',
											// lapsData?.length ? 'Laps' : ''
										].map((t, i) => {
											const isGarminDisabled = t === 'Garmin' && (!currentDayActivities?.length || !userGarminDetails?.status)
											return t && <Button sx={{ textTransform: 'none', px: '15px', pb: '10px', color: 'black !important' }}
												key={i}
												className={i === activeTab ? "selected" : ""}
												onClick={(e) => handleChangeActive(e, i)}
											>
												<Box sx={{ position: 'relative', p: '0px 4px 2px 4px', fontFamily: 'Poppins-Bold !important', fontSize: '16px', letterSpacing: 'normal', textTransform: 'upperCase' }}>
													{t}
													{i === activeTab ? (
														<motion.div className="underlineTab default-tabs" layoutId="underline" />
													) : null}
												</Box>
											</Button>
										}
										)}
								</Grid>}


								{/* @@@ Tab Contents */}
								<Box sx={{ mt: '6px' }}>

									<AnimatePresence mode='wait' initial={false}>
										<motion.div
											key={activeTab}
											custom={initTab - prevActiveTab?.current}
											variants={variants}
											initial="enter"
											animate="center"
											exit='exit'
											transition={{ duration: 0.2 }}
										>
											{(isRaceDay || isLastDay) && renderRaceResult()}
											{isRaceDay && !activeTab ?
												<>
													<RSDrawer
														bottom
														fitContent
														open={isEditing}
														onClose={handleCloseEdit}
													>
														{renderActivity(true, false, true)}
													</RSDrawer>
													{renderRaceDay()}
												</>
												: isLastDay ?
													renderLastDay()
													: <>
														{/* @@@ Main Contents */}
														{renderActiveTab((isRecovery && recoveryAssessmentsDays && hasAssessment))}

														{/*Edit Activities Drawer*/}
														{activeTab === 0 &&
															<RSDrawer
																bottom
																fitContent
																open={isEditing}
																popUpWidth='fit-content'
																onClose={handleCloseEdit}
															>
																{renderActivity(true, (isRecovery && recoveryAssessmentsDays && hasAssessment))}
															</RSDrawer>
														}

														{/*Mark complete drawer depreciated???*/}
														<RSDrawer
															bottom
															fitContent
															open={isMarkComplete}
															onClose={() => setIsMarkComplete(false)}
														>
															{activeTab && currentDayActivities?.length > 0 ? renderGarminActivity(true) : renderActivity(true, (isRecovery && recoveryAssessmentsDays && hasAssessment))}
														</RSDrawer>
														{/*Weather Alert Drawer*/}
														<RSDrawer
															bottom
															fitContent
															popUpWidth='fit-content'
															open={showWeatherAlert}
															onClose={() => setShowWeatherAlert(false)}
														>
															{renderWeatherAlert()}
														</RSDrawer>

														<RSDrawer
															bottom
															fitContent
															open={isTakingNotes}
															onClose={handleCloseEditNotes}
														>
															{renderNotesArea(true)}
														</RSDrawer>

														<RSDrawer
															bottom
															fitContent
															open={finishedGarminSync}
															onClose={() => setFinishedGarminSync(false)}
														>
															{renderGarminSyncNotification(true, hasGarminSyncError)}
														</RSDrawer>
													</>
											}
										</motion.div>
									</AnimatePresence>

								</Box>
							</>) : undefined}
			</motion.div>}
		</AnimatePresence> }


	</Box>
}

export default DailyActivity;
