/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useEffect, useState } from 'react'
import { Form, Spinner } from 'react-bootstrap'
import { UserCustomPaceRecord } from '../../../api/v2/types'
import CustomPopOver from '../../../components/custom-popover'
import { CreateUserCustomPace, UpdateUserCustomPace } from '../../../modules/trainingPlanActions'
import { IComponentStore } from '../../../store/createComponentStore'
import { ITrainingPlanStore } from '../../../store/createTrainingPlanStore'
import useStore from '../../../store/useStore'

import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { AvTimerRounded, HorizontalRule } from '@mui/icons-material'
import moment from 'moment'
import { Button as MuiButton } from '@mui/material'
import { postReq } from '../../../modules/apiConsume'
import { GarminWorkout, GarminWorkoutStep, Step } from '../../../api/v2/garmin-connect/garmin-connect.types'


const TrainingPaces = (props: any) => {
    const { currentDayActivity } = useStore((state: ITrainingPlanStore) => state)

    const [dayActivity, setDayActivity] = useState(false)

    const [inputPaceValue, setInputPaceValue] = useState<string | undefined>(undefined)
    const [isBlankPaces, setIsBlankPaces] = useState(false)
    const [isLoading, setIsLoading] = useState(false)
    const [hasErrorSaving, setHasErrorSaving] = useState(false)
    const [isSuccessSave, setIsSuccessSave] = useState(false)
    const { setToast } = useStore((state: IComponentStore) => state)
    const [selectedUserCustomPace, setSelectedUserCustomPace] = useState<any>(undefined)
    const {
        dayData,
        distanceInterpret,
        convertPace,
        getConvertedPace,
        userCustomPaces,
        getOverridePaceData,
        convertPaceBase,
        weekDays,
        selectedWeek,
        selectedDay,
        userInfo,
        userGarminDetails,
        selectedRace,
        getDailyActivityDisplay,
        activities,
        userNotes
    } = props

    const handleSaveCustomPace = async () => {
        const currentPace = getUserCustomPace(selectedUserCustomPace?.paceId as number)
        if (currentPace && currentPace.pace !== inputPaceValue) {
            setIsLoading(true)
            const data: UserCustomPaceRecord = {
                uid: currentPace.uid,
                pid: currentPace.pid,
                pace: inputPaceValue as string
            }
            const request = await UpdateUserCustomPace(data);
            if (request.data.messages === "success") {
                setIsLoading(false)
                setIsSuccessSave(true)
                getOverridePaceData(currentPace.uid)
            }
            else {
                setIsLoading(false)
                setIsSuccessSave(false)
            }
        }

        else
            // console.log(selectedUserCustomPace)
            if (inputPaceValue && inputPaceValue !== getConvertedPace(selectedUserCustomPace).value[0]) {
                setIsLoading(true)
                const data: UserCustomPaceRecord = {
                    pid: selectedUserCustomPace.paceId,
                    uid: userInfo?.uid,
                    pace: inputPaceValue as string
                }
                const request = await CreateUserCustomPace(data);
                if (request.data.messages === "success") {
                    setIsLoading(false)
                    setIsSuccessSave(true)
                    getOverridePaceData(userInfo?.uid)
                }
                else {
                    setIsLoading(false)
                    setIsSuccessSave(false)
                }
            }
    }

    const showEditButtons = () => {
        return !userGarminDetails || userGarminDetails && activities?.length == 0 ? true : false
    }

    const getUserCustomPace = (paceId: number) => {
        const customPace = userCustomPaces.find((x: any) => x.pid == paceId)
        return customPace
    }

    const hasRepeatition = (paces: any) => {
        const repeat = paces.find((x: any) => x.pace === '')
        if (repeat)
            return true
        else
            return false
    }

    const noteRepeat = (notes: string) => {
        if (notes?.toLowerCase().includes('repeat')) {
            const repeatTimes = notes.split(' ')[1].replace('x', '') || 1
            if (repeatTimes === "repeat")
                return 1
            else if (Number(repeatTimes) > 0 && repeatTimes !== "repeat") {
                return repeatTimes
            }
            else return undefined
        }
    }

    const groupPaces = (nextNote: string, currentNote: string, pace: string, index?: number) => {
        if ((nextNote === ".5 miles" || nextNote === ".5 mile" || nextNote?.toLowerCase()?.includes('walk') || nextNote === ".25 mile") && (currentNote === "2 miles" || currentNote === "1 mile" || currentNote === "2.5 miles" || currentNote.includes('.75')) && pace === "Threshold") {
            return true
        }
        else if (hasRepeatition(dayData?.marathonPaces) && (nextNote === "1 mile" || nextNote?.toLowerCase().includes('recovery')) && (currentNote === "2 miles" || currentNote === "1 mile") && (pace === "HalfMarathon" || pace === "Threshold") && (index && dayData?.marathonPaces[index + 2]?.notes?.toLowerCase().includes('repeat'))) {
            return true
        }
        else if ((nextNote?.toLowerCase()?.includes('recover') || nextNote?.toLowerCase()?.includes('recovery')) && (currentNote === "200m" || currentNote === "400m" || currentNote === "600m" || currentNote === "800m" || currentNote === "1000m" || currentNote === "1200m" || currentNote === "1600m") && (pace === "Interval" || pace === "Repetition" || pace === "Threshold")) {
            return true
        }
        else if ((nextNote?.toLowerCase()?.includes('walk') || nextNote?.toLowerCase()?.includes('recovery')) && currentNote === "5 minutes" && (pace === "HalfMarathon" || pace === "Easy")) {
            return true
        }

        else {
            return false
        }
    }

    const getDinstanceValue = (pace: any) => {

        const meterDistance = Number(distanceInterpret(pace?.notes, userInfo?.km == 'Y' ? true : false, pace?.pace, dayData?.marathon?.distance).split('m')[0])

        if (meterDistance >= 200) {
            return meterDistance * 0.0006214
        }
        else {
            return distanceInterpret(pace?.notes, userInfo?.km == 'Y' ? true : false, pace?.pace, dayData?.marathon?.distance).split(' ')[0]
        }
    }

    const convertDistanceToMeter = (isKm: boolean, distance: number) => {
        if (isKm)
            return distance * 1000
        else if (!isKm)
            return distance * 1609.344
        else
            return 0
    }

    const getTargetValue = (type: number, pace: any) => {
        //1 low, 2 normal, 3 high
        return convertPaceToMpSec(pace, Number(convertDistanceToMeter(userInfo?.km == 'Y' ? true : false, getDinstanceValue(pace)).toPrecision(2))) + (type == 1 ? 0.08 : type == 2 ? 0 : -0.08)

    }

    const convertPaceToMpSec = (pace: string, distanceM: number) => {
        const value = getConvertedPace(pace)
        const mins = +value?.rawPace?.split(' ')[0].replace('m', '') * 60
        const secs = +value?.rawPace?.split(' ')[1].replace('s', '')

        const totalTimeInSec = mins + secs;

        return 1609.344 / totalTimeInSec
    }

    const isLongText = (val: any) => {
        return (val?.notes?.toString().toLowerCase().includes("repeat") || val?.notes?.toString().toLowerCase().includes("recovery") || val?.notes?.toString().toLowerCase().includes("walk")) ? true : false
    }

    const getIntensityName = (paces: any[], index: number) => {
        if (paces[index]?.notes.toLowerCase().includes('recovery'))
            return 'RECOVERY'
        else if (paces.length > 1 && index == 0)
            return 'WARMUP'
        else if (paces.length > 1 && index == paces.length - 1)
            return 'COOLDOWN'
        else
            return 'INTERVAL'
    }

    const convertTextToIntervalTime = (notes: string, prevVal: any) => {
        const divisor = notes.includes("is equal") ? 1 : notes.includes("is half") ? 2 : notes.includes("is quarter") ? 4 : 1

        const convertedTime = convertPaceBase(
            prevVal?.notes as string,
            getConvertedPace(prevVal).rawPace,
            userInfo?.km == 'Y' ? true : false, divisor)

        if (convertedTime && notes.toLowerCase().includes('recovery')) {
            return "Recover for " + convertedTime
        }
        else
            return undefined
    }

    const getCurrentDistance = (marId: number) => {
        const distance = userNotes?.find((x: any) => x.marid == marId)?.distance
        if (distance)
            return distance
        else
            return undefined
    }

    const convertMtoSec = (min: number, sec: number) => {
        return (min * 60) + sec
    }

    const getRecoveryTimeAndType = (notes: string, prevVal: any) => {
        const stringTime = convertTextToIntervalTime(notes, prevVal)?.toLowerCase()?.split('recover for')[1]?.trim()
        const min = Number(stringTime?.split(' ')[0].replace('m', ''))
        const sec = Number(stringTime?.split(' ')[1].replace('s', ''))

        const convertedTime = convertMtoSec(min, sec) || 0

        const type = notes.toLowerCase().includes('minute') || notes.toLowerCase().includes('minutes') ? 'minute' : notes.toLowerCase().includes('second') || notes.toLowerCase().includes('seconds') ? 'second' : undefined

        const validActivity = notes.toLowerCase().includes('walk') || notes.toLowerCase().includes('walk/jog') || notes.toLowerCase().includes('slow jog') || notes.toLowerCase().includes('walk / jog') || notes.toLowerCase().includes('recovery') || (notes.toLowerCase().includes('recovery') && (notes.toLowerCase().includes('is equal') || notes.toLowerCase().includes('is half') || notes.toLowerCase().includes('is quarter'))) ? true : false

        // console.log(notes)

        const timeValue = notes.match(/(\d+)/);

        const durationInSeconds = type === 'minute' ? (timeValue ? Number(timeValue[0]) * 60 : 0) : validActivity && convertedTime > 0 ? convertedTime : validActivity ? 90 : 0

        return { validActivity, durationInSeconds }
    }

    const generateSteps = (marathonPaces: Array<any>) => {
        const steps: GarminWorkoutStep[] = []
        let subStepsCount = 0
        let repeatSteps: Step[] = []
        let currentStep = 0;

        const generateSubSteps = (repeat: number, currentOrder: number, marathonPaces: any, index: number, type: string, duration?: number): Step[] => {
            const pace = marathonPaces[index]

            if (type === "run") {
                if (pace?.notes !== "5 minutes")
                    for (var i = 0; i < repeat; i++) {
                        subStepsCount = subStepsCount + 1
                        repeatSteps.push({
                            intensity: getIntensityName(marathonPaces, index),
                            type: "WorkoutStep",
                            stepOrder: currentOrder++,
                            description: "Run",
                            durationType: "DISTANCE",
                            durationValue: Number(convertDistanceToMeter(userInfo?.km == 'Y' ? true : false, getDinstanceValue(pace))) || 0,
                            targetType: "PACE",
                            targetValue: getTargetValue(2, pace),
                            targetValueLow: getTargetValue(1, pace),
                            targetValueHigh: getTargetValue(3, pace)
                        })
                    }
                else
                    for (var i = 0; i < repeat; i++) {
                        subStepsCount = subStepsCount + 1
                        repeatSteps.push({
                            intensity: getIntensityName(marathonPaces, index),
                            type: "WorkoutStep",
                            stepOrder: currentOrder++,
                            description: "Run",
                            durationType: "TIME",
                            durationValue: 300,
                            targetType: "PACE",
                            targetValue: getTargetValue(2, pace),
                            targetValueLow: getTargetValue(1, pace),
                            targetValueHigh: getTargetValue(3, pace)
                        })
                    }
            }
            else if (type === "recovery") {
                for (var i = 0; i < repeat; i++) {
                    subStepsCount = subStepsCount + 1
                    repeatSteps.push({
                        intensity: "RECOVERY",
                        type: "WorkoutStep",
                        stepOrder: currentOrder++,
                        description: "Walk/Jog",
                        durationType: "TIME",
                        durationValue: duration || 0,
                        targetType: "OPEN"
                    })
                }
            }

            return repeatSteps;
        }

        marathonPaces.forEach((pace, index) => {

            const recoveryActivity = getRecoveryTimeAndType(pace?.notes, marathonPaces[index - 1])

            const repeat: number = noteRepeat(marathonPaces[index + 1]?.notes) as number || noteRepeat(marathonPaces[index + 2]?.notes) as number || 0

            const isRepeat = (pace?.notes?.toLowerCase().includes('miles') || pace?.notes?.toLowerCase().includes('mile') || pace?.notes?.toLowerCase().includes('400m') || pace?.notes?.toLowerCase().includes('800m') || pace?.notes?.toLowerCase().includes('1000m') || pace?.notes?.toLowerCase().includes('1200m') || pace?.notes?.toLowerCase().includes('200m') || pace?.notes?.toLowerCase().includes('600m') || pace?.notes?.toLowerCase().includes('1600m')) && repeat > 0 || recoveryActivity.validActivity && repeat > 0 || pace?.notes?.toLowerCase().includes('5 minutes') && repeat > 0

            const isGrouped = groupPaces(marathonPaces[index + 1]?.notes.trim(), pace?.notes?.trim(), pace?.pace?.trim(), index)

            const isPreviousGroup = index > 0 ? groupPaces(marathonPaces[index]?.notes?.trim(), marathonPaces[index - 1]?.notes?.trim(), marathonPaces[index - 1]?.pace?.trim(), index) || recoveryActivity.validActivity && repeat > 0 : false

            // console.log(index, isGrouped, isPreviousGroup)
            // console.log(index, recoveryActivity)

            if (getConvertedPace(pace).value[1] && !isRepeat && !isPreviousGroup) {
                if (pace?.notes !== "5 minutes")
                    steps.push({
                        intensity: getIntensityName(marathonPaces, index),
                        type: "WorkoutStep",
                        stepOrder: subStepsCount > 0 ? ((steps.length + 1) + subStepsCount) : steps.length + 1,
                        description: "Run",
                        durationType: "DISTANCE",
                        durationValue: pace?.pace?.toLowerCase().trim() === "10k" ? convertDistanceToMeter(false, Number(dayData?.marathon?.distance?.toFixed(1))) : Number(convertDistanceToMeter(userInfo?.km == 'Y' ? true : false, getDinstanceValue(pace))) || 0,
                        targetType: "PACE",
                        targetValue: getTargetValue(2, pace),
                        targetValueLow: getTargetValue(1, pace),
                        targetValueHigh: getTargetValue(3, pace)
                    })
                else
                    steps.push({
                        intensity: getIntensityName(marathonPaces, index),
                        type: "WorkoutStep",
                        stepOrder: subStepsCount > 0 ? ((steps.length + 1) + subStepsCount) : steps.length + 1,
                        description: "Run",
                        durationType: "TIME",
                        durationValue: 300,
                        targetType: "PACE",
                        targetValue: getTargetValue(2, pace),
                        targetValueLow: getTargetValue(1, pace),
                        targetValueHigh: getTargetValue(3, pace)
                    })
            }
            else if (getConvertedPace(pace).value[1] && isRepeat || recoveryActivity.validActivity && isRepeat) {
                if (isGrouped) {
                    steps.push({
                        type: "WorkoutRepeatStep",
                        stepOrder: subStepsCount > 0 ? ((steps.length + 1) + subStepsCount) : steps.length + 1,
                        repeatType: "REPEAT_UNTIL_STEPS_CMPLT",
                        repeatValue: repeat,
                        steps: generateSubSteps(1, (subStepsCount > 0 ? ((steps.length + 2) + subStepsCount) : steps.length + 2), marathonPaces, index, "run")
                    })
                    currentStep = steps.length
                }
                else if (isPreviousGroup) {
                    steps[currentStep - 1].steps = generateSubSteps(1, (subStepsCount > 0 ? ((steps.length + 1) + subStepsCount) : steps.length + 1), marathonPaces, index, recoveryActivity.validActivity ? "recovery" : "run", recoveryActivity.durationInSeconds)
                    repeatSteps = []
                }

                else if (!isGrouped) {
                    repeatSteps = []
                    steps.push({
                        type: "WorkoutRepeatStep",
                        stepOrder: subStepsCount > 0 ? ((steps.length + 1) + subStepsCount) : steps.length + 1,
                        repeatType: "REPEAT_UNTIL_STEPS_CMPLT",
                        repeatValue: repeat,
                        steps: generateSubSteps(1, (subStepsCount > 0 ? ((steps.length + 2) + subStepsCount) : steps.length + 2), marathonPaces, index, "run")
                    })
                }
            }
        });

        return steps;
    }

    const createGarminWorkout = async () => {
        setIsLoading(true)
        const getDate = weekDays[selectedWeek.key - 1]
        const selectedDate = moment(getDate).add(selectedDay, 'days').format('YYYY-MM-DD')

        const access_token = userGarminDetails?.access_token
        const token_secret = userGarminDetails?.token_secret
        const garmin_id = userGarminDetails?.garmin_id
        const uid = userInfo?.uid
        const mar_id = dayData?.marathon?.id

        const activity = getDailyActivityDisplay(
            dayData,
            userInfo,
            dayData?.marathonPaces,
            selectedRace?.value as number,
            dayData?.marathon?.race_day,
            []
        )?.toUpperCase().split('|')

        const activityValue = activity[0].trim() + '' + (activity[1] || '')
        const todayActivity: GarminWorkout = {
            workoutName: activityValue,
            description: '',
            workoutProvider: 'RunSmart',
            workoutSourceId: '',
            sport: 'RUNNING',
            steps: generateSteps(dayData.marathonPaces)
        }

        // console.log(todayActivity)

        await postReq('/v2/garmin/user/workout', {
            access_token, token_secret, schedule: selectedDate, garmin_id, uid, mar_id, workout: todayActivity
        })

        setIsSuccessSave(true)
        setIsLoading(false)
    }

    useEffect(() => {
        const activity = getDailyActivityDisplay(
            dayData,
            userInfo,
            dayData?.marathonPaces,
            selectedRace?.value as number,
            dayData?.marathon?.race_day,
            []
        )?.toUpperCase().split('|')

        setDayActivity(activity[1]?.trim()?.toLowerCase()?.includes('base') || activity[1]?.trim()?.toLowerCase()?.includes('recovery') ? true : false)
        setIsBlankPaces(dayData?.marathonPaces?.length == 1 && dayData?.marathonPaces[0].pace === "" ? true : false)
    }, [dayData])

    useEffect(() => {
        setToast({
            show: hasErrorSaving,
            title: "Error Message",
            message: "An error occurred while saving the data",
            variant: "danger",
            onClose: () => setHasErrorSaving(false),
            duration: 5000
        })
    }, [hasErrorSaving])

    useEffect(() => {
        // setSelectedRaceDate(new Date())
        setToast({
            show: isSuccessSave,
            title: "Save data",
            message: "Data has been successfully saved",
            variant: "success",
            onClose: () => {
                setIsSuccessSave(false)
            },
            duration: 3000,
        })
    }, [isSuccessSave])

    // useEffect(() => {
    //     const notes = "Recovery is equal to interval";
    //     console.log(getRecoveryTimeAndType(notes))
    // }, [])

    return (
        <>
            {dayData?.marathonPaces.length > 0 && (dayData?.marathonPaces[0].notes !== "" || dayData?.marathonPaces[0].pace !== "") ? (
                <div className="mx-n4 mx-md-3 mt-4">
                    <TableContainer component={Paper}>
                        <div className='m-2'><AvTimerRounded className='mt-n1' /> Laps</div>
                        <Table size="small">
                            <TableHead>
                                <TableRow>
                                    <TableCell width={120} className='text-xs pe-0'>Distance</TableCell>
                                    <TableCell width={140} className='text-xs px-0'>Time/Interval</TableCell>
                                    <TableCell width={300} className='text-xs ps-2 pe-0'>Pace</TableCell>
                                    <TableCell colSpan={3}></TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {dayData?.marathonPaces?.map((val: any, idx: number) => (
                                    <TableRow
                                        key={idx}
                                        sx={{ '&:last-child td, &:last-child th': { border: 0, marginTop: '10px' } }}
                                        className={`${groupPaces(dayData?.marathonPaces[idx + 1]?.notes.trim(), val?.notes?.trim(), val?.pace?.trim(), idx) ? 'reduced-height' : ''}`}
                                    >
                                        <TableCell colSpan={isLongText(val) && isLongText(dayData?.marathonPaces[idx + 1]) || isLongText(val) ? 4 : 1} className={`pb-1 pe-0 ${isLongText(dayData?.marathonPaces[idx + 1]) || groupPaces(dayData?.marathonPaces[idx + 1]?.notes.trim(), val?.notes?.trim(), val?.pace?.trim(), idx) ? 'no-border' : ''} ${isLongText(val) ? 'py-0 text-gray-bold' : ''}`}>
                                            {
                                                val?.notes.trim() === "5 minutes" ? (<HorizontalRule />) :
                                                    distanceInterpret(
                                                        val?.notes,
                                                        userInfo?.km == 'Y' ? true : false,
                                                        val?.pace,
                                                        getCurrentDistance(dayData?.marathon?.id) && dayActivity ? getCurrentDistance(dayData?.marathon?.id) : dayData?.marathon?.distance,
                                                        convertTextToIntervalTime(val?.notes, dayData.marathonPaces[idx - 1]),
                                                        dayActivity ? getCurrentDistance(dayData?.marathon?.id) : undefined
                                                    )
                                            }
                                            {val?.notes.toString().toLowerCase().includes("repeat") ? " total." : ""}
                                        </TableCell>
                                        <TableCell className={`${isLongText(dayData?.marathonPaces[idx + 1]) || groupPaces(dayData?.marathonPaces[idx + 1]?.notes.trim(), val?.notes?.trim(), val?.pace?.trim(), idx) ? 'no-border' : ''} px-0`} >
                                            {
                                                val?.notes.trim() === "5 minutes" ? val?.notes.trim() :
                                                    convertPaceBase(
                                                        val?.notes as string,
                                                        getConvertedPace(val).rawPace,
                                                        userInfo?.km == 'Y' ? true : false) == "" && !isLongText(val) ? (<HorizontalRule />) :
                                                        convertPaceBase(
                                                            val?.notes as string,
                                                            getConvertedPace(val).rawPace,
                                                            userInfo?.km == 'Y' ? true : false, 1)
                                            }
                                        </TableCell>
                                        <TableCell className={`ps-2 pe-0 ${isLongText(dayData?.marathonPaces[idx + 1]) || groupPaces(dayData?.marathonPaces[idx + 1]?.notes.trim(), val?.notes?.trim(), val?.pace?.trim(), idx) ? 'no-border' : ''}`}>
                                            <h6 className="mb-0 me-2">
                                                {getConvertedPace(val).value[0]}

                                                {getConvertedPace(val).value[0] !== "" ? " /" + getConvertedPace(val).value[1] : ""}
                                            </h6>
                                        </TableCell>
                                        {
                                            !isLongText(val) && !isLongText(dayData?.marathonPaces[idx + 1]) || !isLongText(val) ? (
                                                <TableCell colSpan={3} className={`${isLongText(dayData?.marathonPaces[idx + 1]) || groupPaces(dayData?.marathonPaces[idx + 1]?.notes.trim(), val?.notes?.trim(), val?.pace?.trim(), idx) ? 'no-border' : ''} text-center`}>
                                                    {getConvertedPace(val).value[0] !== "" && showEditButtons() ? (
                                                        <CustomPopOver
                                                            autoClose={isSuccessSave}
                                                            icon={
                                                                <img
                                                                    onClick={() => {
                                                                        setInputPaceValue(getConvertedPace(val).value[0])
                                                                        setSelectedUserCustomPace(val)
                                                                    }}
                                                                    src="./images/icons/plus-icon.svg"
                                                                    alt="Edit"
                                                                    style={{ width: '21px', height: '21px' }}
                                                                />
                                                            }
                                                        >
                                                            <div className="popover-custom">
                                                                <h6 className="m-0 text-center box-title">
                                                                    {isLoading ? 'Saving Pace...' : 'ENTER PACE'}
                                                                </h6>
                                                                <Form>
                                                                    <Form.Group>
                                                                        <Form.Control onChange={(e) => setInputPaceValue(e.target.value)} type="text" defaultValue={getConvertedPace(val).value[0]} />
                                                                    </Form.Group>
                                                                </Form>
                                                                <div onClick={handleSaveCustomPace} className="thickmark-icon pointer">
                                                                    <img
                                                                        src="./images/icons/tick-mark.png"
                                                                        alt="Tick Mark"
                                                                    />
                                                                </div>
                                                            </div>
                                                        </CustomPopOver>
                                                    ) : undefined}
                                                </TableCell>
                                            ) : undefined
                                        }
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </TableContainer >
                    {
                        userGarminDetails && userGarminDetails.status == 1 ? (
                            <MuiButton className="mt-2" onClick={createGarminWorkout} fullWidth variant="outlined" color="success">
                                {isLoading ? (
                                    <div className='py-1'>
                                        <Spinner animation='border' size='sm' />
                                    </div>
                                ) : <div className='py-1'>Sync to Garmin Device</div>}

                            </MuiButton>
                        ) : undefined
                    }
                </div>

            ) : undefined
            }
        </>
    )
}

export default TrainingPaces