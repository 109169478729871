
import { getPendingDelete, getSubscriptionInfo, signOut } from '../../modules/accountActions'
import { useEffect, useState } from 'react'
import secureLocalStorage from 'react-secure-storage'
import { Row } from 'react-bootstrap'
import { navReq, postReq } from '../../modules/apiConsume'
import useDeviceDetect from '../../hooks/useDeviceDetect'
import GenericModal from '../../components/misc/GenericModal'
import RSButton from '../../components/buttons/RSButton'
import Box from '@mui/material/Box'



const CONFIRM_BUTTONS = ['DELETE ACCOUNT', 'CANCEL', 'DELETE IMMEDIATELY', 'AFTER SUBSCRIPTION ENDS', 'GO HERE TO CANCEL', 'OK']

const AccountDeletion = () => {

    const [showConfirmDelete, setShowConfirmDelete] = useState(false)
    const [hasPendingDelete, setHasPendingDelete] = useState(false)
    const [showSuccessDelete, setShowSuccessDelete] = useState(false)
    const [subscriptionInfo, setSubscriptionInfo] = useState<any>()
    const [isLoading, setIsLoading] = useState(false)
    const [showSelectDeleteMethod, setShowSelectDeleteMethod] = useState(false)
    const [showCancelFirst, setShowCancelFirst] = useState(false)
    const isAppleSignup = secureLocalStorage.getItem("isAppleSignup") || false
    const isGoogleSignup = secureLocalStorage.getItem("isGoogleSignup") || false
    const localStorageRequestKey = localStorage.getItem('deviceRequestKey')
    const { mobileType } = useDeviceDetect()


    const handleDeleteAccountImmediately = async () => {
        if (!subscriptionInfo?.subscription?.canceled && subscriptionInfo?.isActive) {
            setShowSelectDeleteMethod(false)
            setShowCancelFirst(true)
        }
        else {
            setShowCancelFirst(false)
            await postReq('/delete-account', { delete_type: 0 }).then(() => {
                setShowSelectDeleteMethod(false)
                signOut()
            })
        }

    }

    const handleDeleteAccountEndSub = async () => {

        if (!subscriptionInfo?.subscription?.canceled && subscriptionInfo?.isActive) {
            setShowSelectDeleteMethod(false)
            setShowCancelFirst(true)
        }

        else {
            setShowCancelFirst(false)
            await postReq('/delete-account', { delete_type: 1 }).then(() => {
                setShowSuccessDelete(true)
                setShowSelectDeleteMethod(false)
                // navReq('/')
            })
        }

    }

    const getSubscription = async () => {
        setIsLoading(true)
        const subscription = await getSubscriptionInfo()
        const hasPendingDeleteAccount = await getPendingDelete()
        setHasPendingDelete(hasPendingDeleteAccount)
        setSubscriptionInfo(subscription)
        setIsLoading(false)
    }

    useEffect(() => {
        if (localStorageRequestKey && mobileType === 'Ios') {
            getSubscription()
        }
    }, [localStorageRequestKey, mobileType])


    return (
        <>
            <GenericModal
                type='delete-account'
                show={showConfirmDelete}
                title="Account Deletion"
                text={'Please note, deleting your account data will result in the removal of your account and data. This includes the ability to access any content or videos. It may also result in the inability to re-create your account.'}
                setShow={setShowConfirmDelete}
                keyboard={false}
                backdrop="static"
                button1Action={() => {
                    setShowConfirmDelete(false)
                    setShowSuccessDelete(false)
                    setShowSelectDeleteMethod(true)
                }}
                button1Text={CONFIRM_BUTTONS[0]}
                button2Action={() => {
                    setShowSuccessDelete(false)
                    setShowConfirmDelete(false)
                }}
                button2Text={CONFIRM_BUTTONS[1]}
            />

            <GenericModal
                type='delete-account'
                show={showCancelFirst}
                title=""
                text={'You have an active subscription. Please cancel your subscription first, then return to your profile to finish deletion.'}
                setShow={setShowCancelFirst}
                keyboard={false}
                backdrop="static"
                button1Action={() => navReq('/#/cancel/step-1')}
                button1Text={CONFIRM_BUTTONS[4]}
                button2Action={() => setShowCancelFirst(false)}
                button2Text={CONFIRM_BUTTONS[1]}
            />

            <GenericModal
                type='delete-account'
                show={showSuccessDelete}
                title=""
                text={`Your request to delete your account has been ${hasPendingDelete ? 'queued' : ' received'}. Your account will be permanently deleted on ${new Date(subscriptionInfo?.subscription?.end_date).toLocaleDateString()}`}
                setShow={setShowSuccessDelete}
                keyboard={false}
                backdrop="static"
                button1Action={() => {
                    setShowSuccessDelete(false)
                    !hasPendingDelete ? navReq('/') : undefined
                }}
                button2Action={hasPendingDelete ? () => {
                    setShowSelectDeleteMethod(true)
                } : undefined}
                button2Text={hasPendingDelete ? 'DELETE IMMEDIATELY' : undefined}
                button1Text={CONFIRM_BUTTONS[5]}
            />


            <GenericModal
                type='delete-account'
                show={showSelectDeleteMethod}
                title="Account Deletion Confirmation"
                text={'This action cannot be undone. You have the option to delete your account immediately or at the end of your subscription period.'}
                setShow={setShowSelectDeleteMethod}
                keyboard={false}
                button1Action={async () => await handleDeleteAccountImmediately()}
                button1Text={CONFIRM_BUTTONS[2]}
                button2Action={hasPendingDelete ? undefined : async () => await handleDeleteAccountEndSub()}
                button2Text={hasPendingDelete ? undefined : CONFIRM_BUTTONS[3]}
                button3Action={() => {
                    setShowSelectDeleteMethod(false)
                    setShowSuccessDelete(false)
                }}
                button3Text={CONFIRM_BUTTONS[1]}
            />
            {isAppleSignup ? (
                <h5 className='mt-n3'>(Log with Apple ID)</h5>
            ) : undefined}
            {isGoogleSignup ? (
                <h5 className='mt-n3'>(Log with Google ID)</h5>
            ) : undefined}

            <Box sx={{ color: 'red',fontSize: '16px', fontFamily: 'Poppins-Medium' }}>Delete Account</Box>

            <div className='mt-2'>
                <Row className='mx-0 w-100'>
                    <RSButton secondary disabled={isLoading} onClick={() => hasPendingDelete ? setShowSuccessDelete(true) : setShowConfirmDelete(true)} className={`btn btn-outlined mx-auto my-4 text-secondary`}>
                        <span className='w-100 text-center'>
                            {mobileType === "Android" ? 'Request Account Deletion' : 'Continue'}
                        </span>
                    </RSButton>

                </Row>
            </div>
        </>
    )
}

export default AccountDeletion
