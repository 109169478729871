/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { FC, useEffect } from 'react'
import { Col, Container, Row } from 'react-bootstrap'
import AppMenus, { BodyComponent } from '../../components/layout/AppMenus'
import InstabotHide from '../../components/styles/InstabotHide'
import PageTitle from '../../components/text/PageTitle'
import WithAuth from '../../components/WithAuth'
import { AuthPageProps } from '../../modules/types'
import DoubleArrow from '@mui/icons-material/DoubleArrow'
import { navReq } from '../../modules/apiConsume'
import useScrollRow from '../../hooks/useScrollRow'
import ScrollRow from '../../components/layout/ScrollRow'
import Heart from '../../components/logos/Heart'
import useSurveyActions from './useSurveyActions'
import useSuggestedPrograms from './useSuggestedPrograms'
import useProgramFavorites from '../favorites/useProgramFavorites'
// import Heart from '../../components/logos/Heart'
import ReactPixel from 'react-facebook-pixel';
import { useLocation } from 'react-router-dom'

const DefaultPage: FC<AuthPageProps> = ({ userInfo }) => {

    const { setIsScrolling } = useScrollRow()

    const url = useLocation()

    const { suggestedProgram } = useSurveyActions(userInfo?.account_id)
    const { suggestedPrograms, getSuggestedPrograms, isLoading: isLoadingSuggestedPrograms } = useSuggestedPrograms(userInfo?.account_id as number, false)
    const { toggleProgramFavorite, programFavorites } = useProgramFavorites(userInfo?.account_id as number)

    const handleLikeProgram = async (programId: number) => {
        if (userInfo?.account_id && programId) {
            const current = programFavorites.find(x => x.program_id == programId)
            await toggleProgramFavorite(programId, userInfo.account_id, current);
        }
    }

    const handleIsFavoritedProgram = (programId: number) => {
        const current = programFavorites?.find(x => x.program_id == programId)
        if (current && current.favorited == 1)
            return true
        else
            return false
    }

    useEffect(() => {
        if (userInfo?.account_id)
            getSuggestedPrograms(userInfo?.join_reason, 200, true)
    }, [userInfo])

    useEffect(() => {
        if (userInfo?.account_id)
            ReactPixel.trackCustom('StartTrial', { k: new Date().getTime() });
    }, [userInfo])


    return (
        <>
            {/* Styles */}
            < InstabotHide />
            {/* Page */}
            <AppMenus hasContainer>
                <BodyComponent>
                    {/* Back Button */}

                    {/* Title */}
                    {/* <Row className="d-block d-md-none">
                        <Col>
                            <PageTitle text={`Welcome ${userInfo?.first_name || userInfo?.email || ''}`} isMobileTitle />
                        </Col>
                    </Row> */}
                    <Container>
                        <div className='default-page-container suggested-programs-page'>
                            <div className='w-100 text-center text-md-start'>
                                <button style={{ width: '100px', padding: '3px' }} className='pill rounded-pill border-0 text-white bg-tertiary'>My Day</button>
                            </div>
                            <div className='mt-4'>
                                <div className='card default-opt-card pointer' onClick={() => navReq('/#/guides/strength')}>
                                    <h4>Go to Strength Guide</h4>
                                    <DoubleArrow htmlColor='#9fcd2b' />
                                </div>

                                <div className='card default-opt-card mt-1 pointer' onClick={() => navReq('/#/training-plan/build/step-1')}>
                                    <h4>Build a Training Plan</h4>
                                    <DoubleArrow htmlColor='#9fcd2b' />
                                </div>

                                <p className='subtitle mx-2 mt-3'>Recommended For You</p>
                            </div>
                            <ScrollRow setIsScrolling={setIsScrolling}>
                                {isLoadingSuggestedPrograms ? (
                                    <>
                                        {
                                            [1, 2, 3].map((value) => (
                                                <div key={value} className="card bg-transparent border-0 mt-n3 mx-n3 mr-3" aria-hidden="true">
                                                    <div className="card-body">
                                                        <h5 className="card-title">
                                                            <span className="placeholder placeholder-program"></span>
                                                        </h5>
                                                        <p className="card-text">
                                                            <span className="placeholder col-6"></span>
                                                        </p>
                                                    </div>
                                                </div>
                                            ))
                                        }
                                    </>
                                ) :
                                    suggestedPrograms?.map((program, key) => (
                                        <div key={key}>
                                            <div className={`mt-n2 pointer card ${suggestedProgram?.answer == 5 ? 'image-card-right' : 'image-card'}`}>
                                                <img onClick={() => navReq(program.Url as string)} src={program.Featured_img.replace('.jpg', '_400px.jpg')} alt="" />
                                                <Heart className='suggested-like' onLike={() => handleLikeProgram(program.Program_ID)} isLiked={handleIsFavoritedProgram(program.Program_ID)} />
                                            </div>
                                            <p className='program-title'>{program?.Program}</p>
                                        </div>

                                    ))}
                            </ScrollRow>
                            {/* <ScrollRow setIsScrolling={setIsScrolling}>
                                <div className='mt-2'>
                                    <div className={`mt-n2 pointer card image-card-big`}>
                                        <img onClick={() => navReq('/#/programs/prime')} src={'/images/icons/rs-prime.png'} alt="" />
                                        <Heart className='suggested-like' onLike={() => null} isLiked={true} />
                                    </div>
                                    <p className='program-title'>RunSmart Prime</p>
                                </div>

                                <div className='mt-2'>
                                    <div className={`mt-n2 pointer card image-card`}>
                                        <img onClick={() => navReq('/#/webinars/runsmart-protocol')} src={'/images/suggested/Runsmart-Protocol_program_image.jpg'} alt="" />
                                        <Heart className='suggested-like' onLike={() => null} isLiked={true} />
                                    </div>
                                    <p className='program-title'>RunSmart Protocol</p>
                                </div>
                            </ScrollRow> */}
                        </div>
                    </Container>
                </BodyComponent>
            </AppMenus>
        </>
    )
}

export default WithAuth(DefaultPage)