import Grid from '@mui/material/Grid'
import Box from '@mui/material/Box'
import RSButton from '../../components/buttons/RSButton'
import RSInput from '../../components/input/RSInput';
import { UpdateAccountEmail } from '../../modules/accountActions';
import { FC, useState } from 'react';
import useStore from '../../store/useStore';
import { IComponentStore } from '../../store/createComponentStore';
import { AccountProfile } from '../../api/v2/account/Account.types';
import textHelperUtils from '../../utils/textHelperUtils';

type EmailProps = {
	onAlertOpen: () => any
	setAlertMessage: (x: string) => any
	setAlertVariant: (x: string) => any
	handleUpdateProfile: Function,
	data: AccountProfile
	reload: Function
	userInfo?: any
}

const Email: FC<EmailProps> = ({ onAlertOpen, setAlertMessage, setAlertVariant, data, reload, userInfo }) => {

	const [newEmail, setNewEmail] = useState('')
	const [responseMessage, setResponseMessage] = useState(0)
	const { validateEmail } = textHelperUtils()

	const { setIsLoading, setIsSuccess } = useStore((state: IComponentStore) => state)

	const updateEmail = async () => {
		setIsLoading(true)

		const result = await UpdateAccountEmail({
			username: data.username as string,
			newEmail,
			oldEmail: data.email as string,
		})

		if (result?.status == 'ok') {
			console.log("EMAIL UPDATED")
			setResponseMessage(1)
			setAlertVariant('success')
			setAlertMessage('Updated email successfully')
			setIsLoading(false)
			setIsSuccess(true)

			setTimeout(() => {
				setIsSuccess(false)
				reload()
			}, 2000)

		} else {
			console.log("ERROR: ", result?.error || result?.status)
			setResponseMessage(-1)
			setAlertVariant('danger')
			setAlertMessage('Could not update profile')
			setIsLoading(false)
		}
		onAlertOpen()
	}


	return (
		<Box>
			<Box sx={{ fontSize: '16px', fontFamily: 'Poppins-Medium', mb: 2 }}>Update Email</Box>
			<Box sx={{ fontSize: '14px', fontFamily: 'Poppins' }}>Current Email:</Box>
			<Box sx={{ fontSize: '14px', textAlign: 'left', lineHeight: '1.2em', fontFamily: 'Poppins-Light', mb: 2 }} >{data?.email || userInfo?.email}</Box>
			<Grid container xs={12} sx={{ mb: 1 }}>
				<RSInput value={newEmail} setValue={setNewEmail} title={'New Email:'} />
			</Grid>
			<Box sx={{ color: responseMessage == 1 ? '' : 'red', fontSize: '14px', fontFamily: 'Poppins-Light', height: '60px' }} justifyContent={'center'} alignItems={'center'}>
				{responseMessage == 1 ? '* Email changed succesfully' : responseMessage == -1 ? '* Could not change Email' : ''}</Box>
			<RSButton disabled={newEmail === "" || !newEmail || (data?.email === newEmail) || !validateEmail(newEmail)} onClick={() => { updateEmail() }} sx={{ mt: 2, mb: 3 }}>Save</RSButton>
		</Box>
	)
}

export default Email;
