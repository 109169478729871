import { ceil } from "locutus/php/math"
import moment from "moment"
import { useState } from "react"
import { getReq } from "../../modules/apiConsume"

const useMidfoot = () => {

    const [isLoading, setIsLoading] = useState(false)

    const getCurrentWeek = (dateStarted: Date) => {
        const dateNow = new Date()
        const diffDays = moment(dateNow).diff(dateStarted, 'days')
        const week = ceil(diffDays / 7)

        return week;
    }

    const getActiveUsers = async () => {
        setIsLoading(true)
        const result = await getReq(`/v2/midfoot/all-active-users`)
        setIsLoading(false)

        return result?.data?.result
    }

    return { getCurrentWeek, isLoading, getActiveUsers }
}

export default useMidfoot