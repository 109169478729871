
/* eslint-disable @typescript-eslint/no-unused-vars */

import { Apple, Close } from '@mui/icons-material'
import { Box, Grid } from '@mui/material'
import { FC } from 'react'
import { isIOS, isAndroid } from 'react-device-detect'
import { FaGooglePlay } from 'react-icons/fa6'
import RSButton from '../../components/buttons/RSButton'
import useScrollDirection from '../../hooks/useScrollDirection'

export type DownloadAppButtonsProps = {
	sx?: object,
}

const DownloadAppButtons: FC<DownloadAppButtonsProps> = ({sx = {}}) => {

    const scrollDirection = useScrollDirection()

    const handleDownloadApp = () => {
        if (isIOS) {
            window.open("http://apps.apple.com/app/1507476659", "_blank")
        }
        else if (isAndroid)
            window.open("http://play.google.com/store/apps/details?id=com.runsmartonline", "_blank")
    }

    return (
        <Grid sx={{ position: 'fixed', mt: '-50px', width: '100%', height: '50px', background: '#575556', visibility: scrollDirection == "down" ? 'hidden' : 'unset', display: 'flex', justifyContent: 'space-between', transition: 'margin-top 0.2s', ...sx }} sm={12} md={12} container >
            <Box sx={{ display: 'flex', ml: '5px' }}>
                {/* <Box sx={{ mt: '12px' }}>
                    <Close sx={{ color: '#fff', fontSize: '20px', cursor: 'pointer' }} onClick={() => handleClose && handleClose()} />
                </Box> */}
                <Box sx={{ px: '3px', py: '5px', borderRadius: '4px', mt: '8px', ml: '5px', height: '33px', background: '#FFFFFF' }}>
                    <img src='/images/icons/loader-logo.png' alt='RunSmart Logo' style={{ width: '30px', height: '25px', margin: 'auto', cursor: 'pointer' }} />
                </Box>
                <Box sx={{ display: 'flex', flexDirection: 'column', alignContent: 'start', ml: '5px', mt: '4px' }}>
                    <p style={{ color: '#fff', fontSize: '14px' }}><b>RunSmart</b></p>
                    <p style={{ color: '#fff', fontSize: '12px', marginTop: '-22px' }}>Open the RunSmart App</p>
                </Box>
            </Box>
            <Box>
                <button type='button' style={{ border: '0px', background: '#0885fe', color: '#FFF', borderRadius: '50px', padding: '3px 17px', margin: '12px 5px', cursor: 'pointer' }} onClick={() => handleDownloadApp()}>OPEN</button>
            </Box>
        </Grid >
    )
}

export default DownloadAppButtons
