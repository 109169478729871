import { getReq, postReq } from "./apiConsume";

const workoutsActions = () => {

    const buildParams = (params: any) => {
        let param = "";
        Object.keys(params).forEach((key: any, index: number) => {
            param = param + `${key}=${params[key]}${Object.keys(params).length - 1 != index ? '&' : ''}`
        });
        return param;
    }

    const getWorkoutsFavorites = async (params: any, signal?: AbortSignal) => {
        const param = params ? '?' + buildParams(params) : ""
        const result = await getReq(`/v2/workouts/user/watch-data${param}`, { signal: signal })
        return result?.data?.result
    }

    const likeWorkoutVideo = async (data: any) => {
        const result = await postReq(`/v2/workouts/user/like-video`, data)
        return result;
    }

    return { getWorkoutsFavorites, likeWorkoutVideo }
}

export default workoutsActions