import { useEffect } from 'react'
import { useLocation } from 'react-router-dom'

/**
 * ScrollToTop Hook
 */

const ScrollToTop = () => {
	const { pathname } = useLocation()

	useEffect(() => {
		window.scrollTo(0, 0)
		;(window as any)?.RokoInstabot?.trigger()
	}, [pathname])

	return null
}

export default ScrollToTop
