/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { FC, useEffect, useState } from 'react'
import { useNavigate, useLocation } from 'react-router-dom'
import { Container } from 'react-bootstrap'
import ReactPixel from 'react-facebook-pixel';
import Box from '@mui/material/Box';
// import BackButton from '../../components/buttons/BackButton'
import AppMenus, { BodyComponent } from '../../components/layout/AppMenus'
import Heart from '../../components/logos/Heart'
import RelatedVideosRow from '../../components/page-specific/video/RelatedVideosRow'
//import InstabotHide from '../../components/styles/InstabotHide'
// import PageTitle from '../../components/text/PageTitle'
import WithAuth from '../../components/WithAuth'
import RSNavCont from '../../components/layout/RSNavCont'
import { navReq } from '../../modules/apiConsume'
import { AuthPageProps } from '../../modules/types'
import useFavorites from '../favorites/useFavorites'
import useSurveyActions from './useSurveyActions'
import useGuidePage from '../../hooks/useGuidePage'
import useProgramFavorites from '../favorites/useProgramFavorites'
import useSuggestedPrograms from './useSuggestedPrograms'
import ScrollRow from '../../components/layout/ScrollRow'
import useScrollRow from '../../hooks/useScrollRow'
import { ISurveyDetails } from '../../api/v2/onboarding-survey/types'


const SuggestedPage: FC<AuthPageProps> = ({ userInfo }) => {

    const { suggestedProgram, suggestedExercises, userSurveyDetails, pushDetailsToZapier, isLoading: isLoadingExercises } = useSurveyActions(userInfo?.account_id)

    const { isLoading: isLoadingSuggestedPrograms, suggestedPrograms, getSuggestedPrograms, getSavedSuggestedPrograms, constructSurveyAnswers } = useSuggestedPrograms(userInfo?.account_id as number, false)

    const { isScrolling, setIsScrolling } = useScrollRow()

    const url = useLocation()
		const navigate = useNavigate()

    const [liked, setLiked] = useState(false)
    const [likedProgram, setLikedProgram] = useState(true)
    const [guideType, setGuideType] = useState<"Training" | "Strength" | undefined>()
    const [favType, setFavType] = useState<string>()
    const [onBoardingInfo, setOnBoardingInfo] = useState<any>('')
		const isStartOrTrain = userInfo?.onBoardingData?.goals?.find((x: any) => x.index === 0 || x.index === 1)
    // const { videos: guideVivdeos } = useGuidePage({ guideType: guideType })

    const { favorites: favoriteExercises, toggleFavorite: toggleFavoriteExercises } = useFavorites(userInfo?.account_id as number, undefined, "exercises", true)
    // const { favorites: favoriteWorkouts, toggleFavorite: toggleFavoriteWorkouts } = useFavorites(userInfo?.account_id as number, undefined, "workouts", true)
    const { toggleProgramFavorite, programFavorites } = useProgramFavorites(userInfo?.account_id as number)

    //On Like Multi
    const onLike = async (videoId: string) => {
        // const isLiked = favorites && favorites.find((x: any) => x.id === videoId) ? true : false;
        await toggleFavoriteExercises(userInfo?.account_id as number, Number(videoId), liked ? true : false)
    }

    //On Like Each
    const onLikeEach = async (videoId: string) => {
        const isLiked = favoriteExercises && favoriteExercises.find((x: any) => x.id === videoId) ? true : false;
        await toggleFavoriteExercises(userInfo?.account_id as number, Number(videoId), !isLiked)
    }

    const onLikeMultiple = async () => {
        suggestedExercises.forEach(async (x) => {
            await onLike(x.id)
        })
    }

    const handleLikeProgram = async (programId: number) => {
        if (userInfo?.account_id && programId) {
            const current = programFavorites.find(x => x.program_id == programId)
            if (liked && current?.favorited != 1)
                await toggleProgramFavorite(programId, userInfo.account_id, current);
            else if (!liked)
                await toggleProgramFavorite(programId, userInfo.account_id, current);

        }
    }

    const handleIsFavoritedProgram = (programId: number) => {
        const current = programFavorites?.find(x => x.program_id == programId)
        if (current && current.favorited == 1)
            return true
        else
            return false
    }

    const processInitialLike = async () => {
        await onLikeMultiple();
        if (suggestedPrograms && suggestedPrograms?.length > 0) {
            for (const program of suggestedPrograms) {
                await handleLikeProgram(program.Program_ID)
            }
        }

    }

    const newSPString = (obData: any) => {
    	if (obData) {
				const d = {...obData}
				let suggestedString = ''
				const rA = d?.runningActivity?.titleMI
				suggestedString += rA?.indexOf('No running') > -1 ? suggestedString : `running ${rA?.replaceAll(' ', '').replaceAll('to', '-').replaceAll('mi', '').replaceAll('mi', '').replaceAll('Morethan', '> ')} miles/km`
				d?.goals?.forEach((ag: any)=>{
					if (ag?.title?.details)
						suggestedString += `${suggestedString ? ', ' : ''}${ag?.title?.details}`
				})

				d?.problemAreas?.forEach((ag: any, agi: number)=>{
					if (ag?.title !== 'None') {
						const isLast = agi > 0 && agi === d?.problemAreas?.length-1
						suggestedString += !agi ? `${suggestedString ? ', ' : ''}struggle with ` : ''
						suggestedString += `${suggestedString && agi ? ', ' : ''}${isLast && ag?.title?.indexOf('/') === -1 ? 'and ' : ''}${ag?.title?.replaceAll('(s)', '').replaceAll(' /', `,${isLast? ' and' : ''}`)}`
					}
				})
				return suggestedString
			}
			return ''
    }

    useEffect(() => {
        const likeAsync = async () => await onLikeMultiple();
        if (suggestedExercises && suggestedExercises.length > 0)
            likeAsync()
    }, [suggestedExercises])

    useEffect(() => {
        processInitialLike()
    }, [liked])

    useEffect(() => {
        if (userInfo?.account_id) {
        	let strSP = userSurveyDetails ? constructSurveyAnswers(userSurveyDetails as ISurveyDetails) : ''
        	if (userInfo?.onBoardingData?.id) {
        		strSP = newSPString(userInfo.onBoardingData)
        		setOnBoardingInfo(userInfo.onBoardingData)
        	}

        	if (strSP)
            getSuggestedPrograms(strSP, 200, true).then(() => setLiked(true))
        }
    }, [userInfo, userSurveyDetails])

    // useEffect(() => {
    //     if (userInfo?.account_id) {
    //         getSavedSuggestedPrograms(userInfo?.account_id as number).then(res => console.log(res))
    //     }
    // }, [userInfo])


    useEffect(() => {
        if (userSurveyDetails && userSurveyDetails.running_goal === "2")
            setGuideType("Training")
        else if (userSurveyDetails && userSurveyDetails.running_goal === "3")
            setGuideType("Strength")
    }, [userSurveyDetails])

    useEffect(() => {
        if (userInfo?.account_id)
            ReactPixel.trackCustom('StartTrial', { k: new Date().getTime() });
    }, [userInfo])

    return (
			<RSNavCont>
				<Container>
					<div className='suggested-programs-page pt-3'>
							<p className='title'>
									{userInfo?.first_name ? 'Hi ' + (onBoardingInfo?.firstName ? onBoardingInfo.firstName : userInfo?.first_name) : 'Welcome'}! Here’s what we recommend based on your running history and goals. Get started with a program below and add the exercises to your routine!
									{/* {suggestedProgram?.title} */}
							</p>

							<div className='favorite-toggle-container'>
									<Heart isNotAbsolute={true} onLike={setLiked} isLiked={liked} />
									<div className='mx-2 reset-completed'>
											<p>ADD ALL TO FAVORITES</p>
									</div>
							</div>

							<p className='subtitle mt-5'>Start Training</p>
							<ScrollRow setIsScrolling={setIsScrolling}>

									{isLoadingSuggestedPrograms ? (
											<>
													{
															[1, 2, 3, isStartOrTrain ? 4 : undefined].map((value) => (
																	value && <div key={value} className="card bg-transparent border-0 mt-n3 mx-n3 mr-3" aria-hidden="true">
																			<div className="card-body">
																					<h5 className="card-title">
																							<span className="placeholder placeholder-program"></span>
																					</h5>
																					<p className="card-text">
																							<span className="placeholder col-6"></span>
																					</p>
																			</div>
																	</div>
															))
													}
											</>
									) : <>
										{isStartOrTrain && <div>
											<Box className={`pointer card`} onClick={()=>navigate('/training-plan/build/step-1', { replace: true })} sx={{backgroundColor: '#09BCD3' , width: '243px', height: '253px', mt: '5px', display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
												<i className="fa-light fa-medal" style={{fontSize: '100px', color: 'white'}}></i>
											</Box>
											<p className='program-title'>Build a Running Plan</p>
										</div>}
										{suggestedPrograms?.map((program: any, key: number) => (
											<div key={key}>
													<div className={`mt-n2 pointer card ${suggestedProgram?.answer == 5 ? 'image-card-right' : 'image-card'}`}>
															<img onClick={() => navReq(program.Url as string)} src={program.Featured_img.replace('.jpg', '_400px.jpg')} alt="" />
															<Heart className='suggested-like' onLike={() => handleLikeProgram(program.Program_ID)} isLiked={handleIsFavoritedProgram(program.Program_ID)} />
													</div>
													<p className='program-title'>{program?.Program}</p>
											</div>
											))}
										</>
									}



									{/* <div className={`mt-n2 pointer card ${suggestedProgram?.answer == 5 ? 'image-card-right' : 'image-card'}`}>
											<img onClick={() => navReq(suggestedProgram?.program.link as string)} src={suggestedProgram?.program.thumbnail} alt="" />
											<Heart className='suggested-like' onLike={handleLikeProgram} isLiked={likedProgram} />
									</div> */}
							</ScrollRow>


							{suggestedExercises.length > 0 ? (
									<>
											<div className='mt-5 reset-completed'>
													<p>PHYSICAL THERAPIST-DESIGNED</p>
											</div>

											<p className='subtitle mx-2 mt-3 mb-n1'>Recommended Exercises</p>
											<div className='related-videos'>
													{isLoadingExercises ? (
															<ScrollRow setIsScrolling={setIsScrolling}>
																	{[1, 2, 3, 4].map((value) => (
																			<div key={value} className="card bg-transparent border-0 mt-n3 mx-n3 mr-3" aria-hidden="true">
																					<div className="card-body">
																							<h5 className="card-title placeholder-glow">
																									<span className="placeholder placeholder-video"></span>
																							</h5>
																							<p className="card-text placeholder-glow">
																									<span className="placeholder col-6"></span>
																							</p>
																					</div>
																			</div>
																	))}
															</ScrollRow>
													) : (
															<RelatedVideosRow
																	title={''}
																	introSubtitleText={''}
																	videos={suggestedExercises}
																	userInfo={userInfo}
																	rowType='exercises'
																	hasLikeButtons={true}
																	onLike={onLikeEach}
																	favorites={favoriteExercises}
															/>)}

											</div>
									</>
							) : undefined}
					</div>
				</Container>
			</RSNavCont >
    )
}

export default WithAuth(SuggestedPage)
