import { FC } from 'react'

// Props
export type MultiLineTextProps = {
	text: string
	isSmall?: boolean
}

/**
 * MultiLineText Component
 */

const MultiLineText: FC<MultiLineTextProps> = ({ text }) => {
	const lines = (text || '').replace(/\r/g, '').split('\n')

	// Render
	return (
		<div>
			{lines.map((line, index) => (
				<div key={`l-${index}`}>
					{line}
					{index == lines.length - 1 && <br />}
				</div>
			))}
		</div>
	)
}

export default MultiLineText
