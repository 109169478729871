import allCategories from '../modules/static-images/allCategories'
import allVideos from '../modules/videos/allVideos'
import { DEFAULT_PROGRESS } from './useProgramPage'
import { getProgressData } from './useVideoProgress'
import { AuthPageProps } from '../modules/types'
import { webinars } from '../modules/videos/videoOrders'
import { useState, useEffect } from 'react'

/**
 * useWebinarPage Hook
 */

const useWebinarPage = ({
	webinarType,
	userInfo,
}: {
	webinarType: keyof typeof allCategories['webinars']
	userInfo?: AuthPageProps['userInfo']
}) => {
	// Get Info
	const getInfo = () => {
		const videoId = webinars[webinarType as keyof typeof webinars]
		const info = allCategories.webinars[webinarType]
		const video = allVideos[videoId as keyof typeof allVideos]
		const image = info.secondaryLogo
		const description = video.descriptions[0] || ''
		let headline = video.headlines[1] || video.headlines[0] || ''
		if (headline.toLowerCase() == webinarType.toLowerCase()) headline = ''
		return {
			headline,
			description,
			image,
			video,
			progressInfo: DEFAULT_PROGRESS,
		}
	}

	// State
	const [info, setInfo] = useState(getInfo())
	const [data, setData] = useState(undefined as any)

	// Get Progress Info
	const getProgressInfo = async (newInfo: ReturnType<typeof getInfo>) => {
		const { newData: progressData, newInfo: progressInfo } =
			await getProgressData([newInfo.video], userInfo, data)
		setData(progressData)
		setInfo({
			...newInfo,
			progressInfo: progressInfo?.[0] || { ...DEFAULT_PROGRESS },
		})
	}

	// Change Effect
	useEffect(() => {
		const newInfo = getInfo()
		getProgressInfo(newInfo)
	}, [userInfo, webinarType])

	// Return
	return info
}

export default useWebinarPage
