/* eslint-disable @typescript-eslint/no-unused-vars */

import moment from "moment";
import { Workout } from "./types/AppleHealthKit.types";
import useTrainingPlanUtils from "./useTrainingPlanUtils";

// Define types for workout data structure
interface Metadata {
    HKTimeZone: string;
    HKIndoorWorkout: number;
    HKAverageMETs: number | null;
    HKElevationAscended: number | null;
}

interface WorkoutEvent {
    startDate: string;
    endDate: string;
    eventType: string;
    eventTypeInt: number;
}

interface AnchoredWorkout {
    id: string;
    device: string;
    distance: number;
    activityName: string;
    sourceId: string;
    sourceName: string;
    tracked: boolean;
    duration: number;
    start: string;
    end: string;
    activityId: number;
    calories: number;
    metadata: Metadata;
    workoutEvents: WorkoutEvent[];
}

export interface WorkoutData {
    id: string;
    start: string;
    end: string;
    activityId: number;
    calories: number;
    activityName: string;
    distance: number;
    durationInSeconds: number;
    durationInMinutes: number;
    anchoredWorkouts: AnchoredWorkout[];
    workoutRoutes?: any;
    workoutRoutesError?: any
    allStatistics?: any;
    workout?: Workout
}

// Define the lap/split result type
type Lap = {
    start?: string;
    end?: string;
    timerDurationInSeconds: number; // Duration in seconds
    totalDistanceInMeters: number; // Distance in miles
    pauseDuration?: number; // Duration in seconds
};

type Split = {
    label: string;
    duration: number; // Duration in seconds
    distance: number; // Distance in miles
    durationText: string; // Distance in miles
    start?: string;
    end?: string;
};

interface GroupedWorkoutEvent {
    startDate: string; // ISO 8601 date string
    endDate: string; // ISO 8601 date string
    events: WorkoutEvent[];
}

type Unit = 'mile' | 'km';

const useHealthActivity = () => {
    const { ConvertS2M, getGarminSpeed, getGarminPace } = useTrainingPlanUtils();
    function removeOverlappingEvents(events: any) {
        const parsedEvents = events.map((event: any) => ({
            ...event,
            startDate: moment(event.startDate).toDate(),
            endDate: moment(event.endDate).toDate()
        }));

        parsedEvents.sort((a: any, b: any) => a.startDate - b.startDate);

        for (let i = 0; i < parsedEvents.length - 1; i++) {
            const current = parsedEvents[i];
            const next = parsedEvents[i + 1];

            if (next.startDate < current.endDate) {
                current.endDate = next.startDate;
            }
        }

        return parsedEvents.map((event: any) => ({
            ...event,
            startDate: event.startDate.toISOString(),
            endDate: event.endDate.toISOString()
        }));
    }

    function processData(data: string[]): string[] {
        const numbers: string[] = data.map(String);
        const uniqueWholeNumbers: string[] = Array.from(new Set(numbers.map(num => String(Math.floor(Number(num))))));
        const lastValue: string = numbers[numbers.length - 1];
        return [...uniqueWholeNumbers, lastValue];
    }

    const getWorkoutLaps = (workoutData: WorkoutData[], userInfo?: any): Lap[] => {
        const eventLaps: any = []
        if (!workoutData) return [];
        for (const wd of workoutData) {
            if (!wd.workout) return []
            const workout = wd.workout
            const isKm = userInfo?.km === "Y";
            const laps: Lap[] = [];
            let currentDistanceTotal = 0;
            let currentDurationTotal = 0;
            const splitSize = 1.0;
            let splitCount = 1;
            const expectedLaps = workout.totalDistance?.quantity ? (isKm ? (workout.totalDistance.quantity / 1000) : (workout.totalDistance.quantity * 0.000621371)) : 0
            const remainder = expectedLaps % splitSize
            workout.distanceWalkingRunning?.forEach((sample) => {
                const startDate = new Date(sample.startDate);
                const endDate = new Date(sample.endDate);

                const durationInSeconds = (moment(endDate).diff(moment(startDate), 'milliseconds') / 1000);
                const distance = isKm ? sample.quantity / 1000 : sample.quantity * 0.000621371;
                currentDistanceTotal += distance
                currentDurationTotal += durationInSeconds
                if (currentDistanceTotal > splitSize) {
                    const distanceOverflow = currentDistanceTotal - splitSize
                    const durationProportionBelongingToThisSplit = (distance - distanceOverflow) / distance
                    const durationProportionBelongingToNextSplit = 1 - durationProportionBelongingToThisSplit
                    const durationOverflow = durationInSeconds * durationProportionBelongingToNextSplit
                    currentDurationTotal -= durationOverflow

                    laps.push({
                        timerDurationInSeconds: currentDurationTotal,
                        totalDistanceInMeters: isKm ? 1000 : 1609.34,
                    })

                    // Reset the counters, beginning them with the overflows.
                    currentDistanceTotal = distanceOverflow
                    currentDurationTotal = durationOverflow
                    splitCount += 1
                }
                else if (splitCount === Math.ceil(expectedLaps) && Number(remainder.toFixed(2)) == Number(currentDistanceTotal.toFixed(2))) {
                    laps.push({
                        timerDurationInSeconds: currentDurationTotal,
                        totalDistanceInMeters: isKm ? currentDistanceTotal : currentDistanceTotal * 1609.34,
                    })
                    splitCount = 0
                }
            })

            eventLaps.push({
                activityId: workout.uuid,
                laps: laps,
                activityName: wd.activityName,
                type: "apple_health",
                activityType: wd.activityName.toUpperCase(),
            })
        }

        return eventLaps;
    }

    return { getWorkoutLaps }
}

export default useHealthActivity