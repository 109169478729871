import { StoreSlice, TrainingDetails } from "./types"
import { Recover } from '../api/v2/recover/recover.types'

export interface ITrainingStore {
    trainingDetails: TrainingDetails | undefined,
    setTrainingDetails: (trainingDetails: TrainingDetails) => void,
    currentRecover: Recover | undefined,
    setCurrentRecover: (currentRecover: Recover | undefined) => void
}

const createTrainingStore: StoreSlice<ITrainingStore> = (set) => ({
    trainingDetails: undefined,
    setTrainingDetails: (trainingDetails: TrainingDetails) => set(() => ({ trainingDetails: trainingDetails })),
    currentRecover: undefined,
    setCurrentRecover: (currentRecover: Recover | undefined) => set(() => ({ currentRecover: currentRecover })),
})

export default createTrainingStore