import { StoreSlice } from "./types"

export interface IProgramStore {
    programVideoProgress: any[]
    setProgramVideoProgress: (progress: any) => void
}
const createProgramStore: StoreSlice<IProgramStore> = (set) => ({
    programVideoProgress: [],
    setProgramVideoProgress: (programVideoProgress: []) => set(() => ({ programVideoProgress: programVideoProgress })),
})

export default createProgramStore