/* eslint-disable @typescript-eslint/no-unused-vars */

import { ReactJSXElement } from '@emotion/react/types/jsx-namespace';
import { AccessibilityNewRounded, AvTimerRounded, DateRangeRounded, DirectionsBikeRounded, DirectionsRunRounded, FavoriteBorderOutlined, HistoryToggleOffRounded, SettingsEthernetRounded, ShutterSpeedRounded, SpeedRounded } from '@mui/icons-material';
import { Box, Tab, Tabs, Typography } from '@mui/material'
import moment from 'moment';
import React, { FC, useEffect, useState } from 'react'
import { Card, Col, Row } from 'react-bootstrap';
import { Summary } from '../../../api/v2/garmin-connect/garmin-connect.types';
import useTrainingPlanUtils from '../../../hooks/useTrainingPlanUtils';
import { navReq } from '../../../modules/apiConsume';
import IntegrationsMainPage from '../../integrations/IntegrationsMainPage';
import SyncDeviceDismissModal from '../SyncDeviceDismissModal';
import GarminLaps from './GarminLaps';


interface TabPanelProps {
    children?: React.ReactNode;
    index: number;
    value: number;
}

const TabPanel = (props: TabPanelProps) => {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box sx={{ pt: 1 }}>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    );
}

const a11yProps = (index: number) => {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}

const GarminDetailsTab: FC<{ recommended: ReactJSXElement, activities: Summary[], notesForm: ReactJSXElement, userInfo: any, currentRun?: ReactJSXElement, userGarminDetails?: any }> = ({ recommended, activities, userInfo, currentRun, notesForm, userGarminDetails }) => {
    const [value, setValue] = useState(0);
    const [showDismiss, setShowDismiss] = useState(false);

    const { ConvertMetersToMiles, getGarminPace, ConvertS2M, getGarminSpeed } = useTrainingPlanUtils();

    const dismissSync = localStorage.getItem('dismissSync')

    const confirmDismiss = () => {
        localStorage.setItem('dismissSync', '1')
        setShowDismiss(false)
        navReq('/#/training-plan/view')
    }

    const handleChange = (event: React.SyntheticEvent, newValue: number) => {
        setValue(newValue);
    };

    useEffect(() => {
        if (activities.length > 0)
            setValue(1)
        else
            setValue(0)
    }, [activities])

    return (
        <Box className='mt-n5 mt-md-n4 mb-4'>
            <Row>
                <Col md={7}>
                    <Box className='mx-n4 mx-md-4' sx={{ borderBottom: 1, borderColor: 'divider' }}>
                        <Tabs className='garmin-details' variant='fullWidth' value={value} onChange={handleChange} aria-label="tabs">
                            <Tab className={`text-capitalized ${value == 0 ? 'active-tab' : 'inactive-tab'}`} label="My RunSmart Plan" {...a11yProps(0)} />
                            <Tab className={`text-capitalized ${value == 1 ? 'active-tab' : 'inactive-tab'}`} label={
                                userGarminDetails && userGarminDetails?.status == 1 ?
                                    'Garmin Connect'
                                    :
                                    (
                                        <>
                                            <div className="d-flex-row">
                                                <img
                                                    className="pointer mx-2"
                                                    src={`./images/icons/sync-icon-${value == 1 ? 'active' : 'inactive'}.svg`}
                                                    alt="garmin-connect"
                                                    style={{ width: '12%', color: '#2699FB' }}
                                                >
                                                </img>
                                                <span className={`${value == 1 ? 'active-tab' : ''}`}>{'Sync Device'}</span>
                                            </div>
                                        </>

                                    )
                            } {...a11yProps(1)} />
                        </Tabs>
                    </Box>
                    <TabPanel value={value} index={0}>
                        {recommended}
                    </TabPanel>
                    <TabPanel value={value} index={1}>
                        {userGarminDetails && userGarminDetails?.status == 1 && activities && activities.length > 0 ? currentRun :
                            userGarminDetails && userGarminDetails?.status == 1 && activities && activities.length == 0 ?
                                (

                                    <div className='no-border mt-4 text-center p-2 mx-n4 mx-md-3'>
                                        <p>No activity for this date. Rest up <br />or complete your training and check back.</p>
                                    </div>

                                ) : userGarminDetails && userGarminDetails?.status == 0 || !userGarminDetails ? (
                                    <>
                                        <div className='text-center mx-n5 px-2 text-small mt-3'>
                                            <p className='text-gray'>
                                                Sync your watch or device to your training plan.
                                            </p>
                                        </div>
                                        <IntegrationsMainPage type='training-plan' />
                                    </>

                                ) : undefined}
                        {
                            activities.map((x, index) => (
                                <div className='mx-n4 mx-md-3 mt-4 mb-4' key={index}>
                                    <Card className="px-0 pt-2">
                                        <Typography className='px-2 my-2' variant='subtitle1' color="CaptionText">
                                            {x.activityType.includes('RUNNING') ? (
                                                <DirectionsRunRounded />
                                            ) : x.activityType.includes('CYCLING') ? (
                                                <DirectionsBikeRounded />
                                            ) : <AccessibilityNewRounded />}
                                            <span className='mx-2 mt-1'>{x.activityName}</span>
                                        </Typography>

                                        <hr className='mt-1 hr-light-border' />

                                        {x.startTimeInSeconds ? (
                                            <>
                                                <Row className='mt-n2 px-2'>
                                                    <Col xs={6} sm={7} md={5}>
                                                        <Typography variant='subtitle1' color={'CaptionText'}>
                                                            <DateRangeRounded className='mt-n1' /> Date/Time:</Typography>
                                                    </Col>
                                                    <Col xs={6} sm={5} md={7}>
                                                        <Typography className='text-right' variant='subtitle1' color={'CaptionText'}>{
                                                            moment.unix(x.startTimeInSeconds).format('MMM DD, YYYY h:mm A')
                                                        }</Typography>
                                                    </Col>
                                                </Row>
                                                <hr className='mt-1 hr-light-border' />
                                            </>
                                        ) : undefined}

                                        {x.distanceInMeters && x.distanceInMeters > 0 ? (
                                            <>
                                                <Row className='mt-n2 px-2'>
                                                    <Col xs={6} sm={7} md={8}>
                                                        <Typography variant='subtitle1' color={'CaptionText'}>
                                                            <SettingsEthernetRounded className='mt-n1' /> Distance:</Typography>
                                                    </Col>
                                                    <Col xs={6} sm={5} md={4}>
                                                        <Typography variant='subtitle1' color={'CaptionText'}>
                                                            {
                                                                userInfo?.km == "N" ? ConvertMetersToMiles(x.distanceInMeters as number)?.toFixed(1) + ' Miles' : Number(x.distanceInMeters as number / 1000)?.toFixed(1) + ' km'
                                                            }</Typography>
                                                    </Col>
                                                </Row>
                                                <hr className='mt-1 hr-light-border' />

                                            </>
                                        ) : undefined}

                                        {x.durationInSeconds && x.durationInSeconds > 0 ? (
                                            <>
                                                <Row className='mt-n2 px-2'>
                                                    <Col xs={6} sm={7} md={8}>
                                                        <Typography variant='subtitle1' color={'CaptionText'}>
                                                            <HistoryToggleOffRounded className='mt-n1' /> Duration:</Typography>
                                                    </Col>
                                                    <Col xs={6} sm={5} md={4}>
                                                        <Typography variant='subtitle1' color={'CaptionText'}>{ConvertS2M(x.durationInSeconds)}</Typography>
                                                    </Col>
                                                </Row>
                                                <hr className='mt-1 hr-light-border' />
                                            </>
                                        ) : undefined}

                                        <Row className='mt-n2 px-2'>
                                            <Col xs={6} sm={7} md={8}>
                                                <Typography variant='subtitle1' color={'CaptionText'}>
                                                    <FavoriteBorderOutlined className='mt-n1' /> Avg. Heart rate:</Typography>
                                            </Col>
                                            <Col xs={6} sm={5} md={4}>
                                                <Typography variant='subtitle1' color={'CaptionText'}>{x.averageHeartRateInBeatsPerMinute} bpm</Typography>
                                            </Col>
                                        </Row>
                                        <hr className='mt-1 hr-light-border' />

                                        {x.averageSpeedInMetersPerSecond && x.averageSpeedInMetersPerSecond > 0 ? (
                                            <>
                                                <Row className='mt-n2 px-2'>
                                                    <Col xs={6} sm={7} md={8}>
                                                        <Typography variant='subtitle1' color={'CaptionText'}>
                                                            <ShutterSpeedRounded className='mt-n1' /> Avg. Pace:
                                                        </Typography>
                                                    </Col>
                                                    <Col xs={6} sm={5} md={4}>
                                                        <Typography variant='subtitle1' color={'CaptionText'}>{getGarminPace(userInfo?.km == 'Y' ? true : false, x.averageSpeedInMetersPerSecond)
                                                        }</Typography>
                                                    </Col>
                                                </Row>
                                                <hr className='mt-1 hr-light-border' />

                                                <Row className='mt-n2 px-2'>
                                                    <Col xs={6} sm={7} md={8}>
                                                        <Typography variant='subtitle1' color={'CaptionText'}>
                                                            <SpeedRounded className='mt-n1' /> Avg. Speed:
                                                        </Typography>
                                                    </Col>
                                                    <Col xs={6} sm={5} md={4}>
                                                        <Typography variant='subtitle1' color={'CaptionText'}>{getGarminSpeed(userInfo?.km == 'Y' ? true : false, x.averageSpeedInMetersPerSecond)
                                                        }</Typography>
                                                    </Col>
                                                </Row>
                                                <hr className='mt-1 hr-light-border' />

                                            </>
                                        ) : undefined}

                                        {x.laps && x.laps.length > 0 ? (
                                            <>
                                                <Row className='mt-n3'>
                                                    {/* <Col className='mb-2'>
                                                <Typography variant='subtitle1' color={'CaptionText'}>
                                                    <AvTimerRounded className='mt-n1' /> Laps
                                                </Typography>
                                            </Col> */}
                                                    {/* <Row className='px-3'> */}
                                                    <GarminLaps laps={x.laps.filter(x => x?.totalDistanceInMeters > 0 && x?.timerDurationInSeconds > 0)} userInfo={userInfo} />
                                                    {/* </Row> */}
                                                </Row>
                                            </>
                                        ) : undefined}
                                    </Card>
                                </div>
                            ))
                        }

                        {dismissSync == "1" || userGarminDetails && userGarminDetails?.status == 1 ? undefined : (
                            <div className='text-center mt-3'>
                                <p onClick={() => setShowDismiss(true)} className='text-gray'><u>Dismiss</u></p>
                            </div>
                        )}
                    </TabPanel >
                </Col>
                {value == 0 && activities.length == 0 ? notesForm : value == 1 && activities.length > 0 ? (
                    // <div className='mt-4'>
                    notesForm
                    // </div>
                ) : undefined}
            </Row >
            <SyncDeviceDismissModal showModal={showDismiss} closeModal={() => setShowDismiss(false)} confirm={confirmDismiss} />
        </Box >
    );
}


export default GarminDetailsTab