import {FC, useState, useEffect} from 'react'

import Grid from '@mui/material/Grid'
import Box from '@mui/material/Box'

import RSInput from '../../components/input/RSInput'
import RSButton from '../../components/buttons/RSButton'
import { AccountProfile } from '../../api/v2/account/Account.types'

export type NutritionProps = {
	data: AccountProfile,
	handleUpdateProfile: Function,
}

const Nutrition: FC<NutritionProps> = ({data, handleUpdateProfile}) => {
	const {dietary_preferences} = data
	const [dp, setDP] = useState<any>(dietary_preferences || '');

	const handleSave = async () => {
		handleUpdateProfile({ dietary_preferences: dp })
	}

	useEffect(()=>{
		setDP(dietary_preferences)
	},[dietary_preferences])

	return (
		<Box>
			<Grid container xs={12} sx={{ fontFamily: 'Poppins-Medium', fontSize: '16px', lineHeight: '1.4em'}}>
				Do you have any specific dietary preferences?
			</Grid>
			<Grid container xs={12}>
				<RSInput value={dp} setValue={setDP} title={'gluten-free, vegan, etc'}/>
			</Grid>
			<RSButton onClick={handleSave} disabled={dietary_preferences == dp} sx={{mt: 3}}>Save</RSButton>
		</Box>
	)
}

export default Nutrition;
