import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import RSNavCont from '../../../components/layout/RSNavCont'
import RSHeaderCont from '../../../components/layout/RSHeaderCont'
import RSCircledSymbol from '../../../components/buttons/RSCircledSymbol'
import WithAuth from '../../../components/WithAuth'
import AppMenus, { BodyComponent } from '../../../components/layout/AppMenus'
import { FC, useEffect, useState } from 'react'
import { Container, Row, Card } from 'react-bootstrap'
import { useLocation, useNavigate } from 'react-router-dom'
import { IRaceType } from './RaceType'
import { RaceOptions } from './RaceOptions'
import { ITrainingPlanStore } from '../../../store/createTrainingPlanStore'
import useStore from '../../../store/useStore'
import { CheckActivePlan } from '../../../modules/trainingPlanActions'
import { ICurrentUser } from '../../../store/createAccountStore'
import moment from 'moment'
import levelSelection from './LevelSeletion.json'
import { navReq } from '../../../modules/apiConsume'
import Progress from './Progress'
import useIsMobileScreen from '../../../hooks/useIsMobileScreen'


const SelectLevel: FC = () => {

    const navigate = useNavigate()
    const url = useLocation()
		const isMobile = useIsMobileScreen();

    const { selectedRace, agreedCreateNewPlan, setAgreedCreateNewPlan, currentPath, setCurrentPath, hasActivePlan, setHasActivePlan, setSelectedRaceDate } = useStore((state: ITrainingPlanStore) => state)
    const { currentUser } = useStore((state: ICurrentUser) => state)
    const [loadingCheck, setLoadingCheck] = useState(true)

    function handleClick(level: number) {
        navigate('/training-plan/build/step-3?level=' + level, { replace: true })
    }

    useEffect(() => {
        if (!selectedRace) {
            navigate('/training-plan/build/step-1', { replace: true })
        }
    }, [selectedRace])

    // Render
    return (
        <RSNavCont hideFooter={true} hideHeader={isMobile}>
        	<RSHeaderCont isStatic={!isMobile} title='Cancel' noShadow={!isMobile} barSX={{backgroundColor: isMobile ? 'white' : 'transparent'}} closeClick={()=>navigate('/explore')}/>
					<div className="build-plan pt-5">
							<Container>
									<Progress progress={Number(url.pathname.split('/')[3].split('-')[1])} />
									<div className="topbar-info text-center">
											<h1 className='text-caps'>
													<b>Choose a Level</b>
											</h1>
											<div className='text-center'>
													<p className='w-75 mx-auto text-center'>You can adjust after your plan is built.</p>
											</div>
									</div>
									<Grid container xs={12} sx={{ lineHeight: '1.2em', fontFamily: 'Poppins-Light !important', fontSize: '18px'}} justifyContent="flex-start" alignItems="center">
										{levelSelection.map((level: any, lvlI: number) =>
											<Grid container className='race-selection-container' key={lvlI+'build-plan-lvl'} xs={12} sx={{fontFamily: 'Poppins-Light !important', fontSize: '18px !important', mb: '10px' }} justifyContent="flex-start" alignItems="center">
												<RSCircledSymbol
													symbol={level.id}
													title={level.title}
													subtitle={level.description}
													onClick={() => handleClick(level.id)}
													sx={{minHeight: '200px'}}
												>
													<Grid container xs={12} sx={{pt: '10px', color: '#010101', fontSize: `1${window.innerWidth > 991 ? '.25' : ''}rem !important`, fontFamily: 'Poppins !important', lineHeight: '1em !important'}}>
														Type of Runs
													</Grid>
													<Grid container xs={12}>
														{level?.typeOfRuns?.map((type: string, typeI: number) => (
																<Box key={typeI+'type'}
																	sx={{mr: level.typeOfRuns.length-1 === typeI  ? 0 : '5px', mt: '5px', px: '10px', py: '5px', borderRadius: '7px', fontSize: '15px !important', fontFamily: 'Poppins-light !important', lineHeight: '1em !important', backgroundColor: '#742F4E', color: '#fff'}}
																>
																	{type.toUpperCase()}
																</Box>
														))}
													</Grid>
												</RSCircledSymbol>
											</Grid>
										)}
									</Grid>
							</Container>
					</div>
        </RSNavCont >
    )
}

export default WithAuth(SelectLevel)
