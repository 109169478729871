import { StoreSlice } from "./types"

interface DataGenerated {
    type: string,
    data: any,
}

export interface IAiTrainingPlanStore {
    dataGenerated: DataGenerated[],
    setDataGenerated: (newData: DataGenerated) => void,
    clearDataGenerated: () => void
}

function updateArrayWithUniqueData(existing: DataGenerated[], newData: DataGenerated[]) {
    newData.forEach(item => {
        const existingItem = existing.find(x => x.type === item.type);
        if (existingItem !== undefined) {
            existingItem.data = item.data;
        }
        else {
            existing.push(item);
        }
    });
    return existing;
}

const createAITrainingPlanStore: StoreSlice<IAiTrainingPlanStore> = (set) => ({
    dataGenerated: [],
    setDataGenerated: (newData: DataGenerated) => {
        set((state) => ({ dataGenerated: updateArrayWithUniqueData(state.dataGenerated, [newData]) }))
    },
    clearDataGenerated: () => {
        set(() => ({ dataGenerated: [] }))
    }
})

export default createAITrainingPlanStore