import React, { useState } from 'react'
import { Button } from 'react-bootstrap'
import DownloadPlanModal from './DownloadPlanModal';

const DownloadPlan = (props: any) => {
    const requestKey = localStorage.getItem('deviceRequestKey')
    const { userInfo, getRaceDistance, userCustomPaces, isPDFExist } = props
    const [show, setShow] = useState(false)
    const handleCloseModal = () => setShow(false);
    const handleOpenModal = () => setShow(true);


    return (
        <div className='download-plan--page'>
            <div className="download-button">
                <Button
                    disabled={requestKey ? true : false}
                    onClick={handleOpenModal}
                    size="sm"
                    variant="dark"
                    className="rounded-pill color-primary-bg next-btn mt-2 text-uppercase"
                >
                    DOWNLOAD PLAN
                </Button>
                {/* <div className="download-icon ms-4 pointer mt-3 mt-lg-0">
                    <img
                        src="./images/icons/download-plan.svg"
                        alt="Download Plan"
                    />
                </div> */}
            </div>

            <div>
                {requestKey ? (
                    <p className='message-text' style={{ color: '#ff0011' }}>Feature not available in-app. Please use your browser.</p>
                ) : undefined}
                <p className='top-title'>Congratulations! You’ve completed your training plan.</p>
                <p className='message-text'>All training plans are available for download on race day. A PDF file will be generated that will include all your distances, paces, heart rate and notes.</p>
            </div>
            <DownloadPlanModal isPDFExist={isPDFExist} userCustomPaces={userCustomPaces} getRaceDistance={getRaceDistance} userInfo={userInfo} show={show} handleClose={handleCloseModal} />
        </div >
    )
}

export default DownloadPlan