import { ArrowBack } from "@mui/icons-material"
import { useEffect } from "react"
import useQueryParams from "../../hooks/useQueryParams"
import { backNav, navReq } from "../../modules/apiConsume"

const PrivacyPolicy = () => {
    const { redirect, requestKey } = useQueryParams()

    useEffect(() => {
        if (requestKey)
            localStorage.setItem("deviceRequestKey", requestKey)
    }, [requestKey])
    return (
        <div className="misc">
            <span className="has-pointer back-btn-misc" onClick={() => redirect === "1" ? navReq('/#/signup') : backNav()}>
                <ArrowBack />
            </span>
            <h1>Privacy Policy</h1>
            <p>Your Privacy RunSmart Online, LLC recognizes and respects the importance of maintaining the privacy of our customers, registered members (Members) and users, and has established this Privacy Policy as a result. The purpose of this Privacy Policy is to inform you that we may collect demographic information, such as your ZIP code, age, gender, preferences, interests, and favorites. If you choose to make a purchase or sign up for a paid subscription service, we will ask for additional information, such as your credit card number and billing address, which is used to create a billing account with our secure 3rd party billers (PayPal). This Privacy Policy is part of the Terms of Use, which governs your use of our website located at www.runsmartonline.com.</p>

            <h2>Why do we gather information?</h2>
            <p>RunSmart Online, LLC gathers personal information to help improve our products and customer service, to communicate with you, to process your orders, to provide an enhanced and more personalized experience and to inform you of special offers from RunSmart Online, LLC.</p>

            <h2>What information do we gather?</h2>
            <p>We collect information through our website, emails, mail, fax, and telephone, including when you: place an order, participate in our surveys, contests, sweepstakes, promotions, content submissions, requests for suggestions, and membership registrations, and engage in other activities, services, products and resources we make accessible to our customers, members or users. Depending on how you interact with us, we collect some or all of the following types of information: your name, address, telephone number, email address, billing information (credit card number, expiration date, and billing address), contacts names, addresses, telephone numbers and email addresses. The information you provide to us may be collected by us even if an order, registration, or other process is started but not completed or otherwise canceled.</p>

            <p>For our membership, we also collect information regarding your use of our services, including the pages visited, the videos watched, and how you interact with the website. We collect this information to improve the user experience, offer rewards, and provide suggested content.</p>

            <h2>How we use “Cookies”</h2>
            <p>A “cookie” is a small data file sent to your browser from a web server and stored on your computer. We use cookies to let us know that you are a prior customer, user or Member and to provide certain features to you. Most web browsers allow you to instruct the browser to prevent the use of cookies. However, if you disable this feature, certain aspects of RunSmart Online, LLC, may not function properly now or in the future.</p>

            <h2>How we use the information we collect.</h2>
            <p>We use the information we collect to communicate with you, to process your orders, to provide an enhanced and more personalized user experience, to inform you and your gift and message recipients of offers and discounts. We do not share or rent information for direct marketing purposes to Third Parties.</p>
        </div>
    )
}

export default PrivacyPolicy