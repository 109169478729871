import React, { FC } from 'react'

const Answer: FC<{ selected: boolean, option: string, setAnswer?: () => any }> = ({ selected = false, setAnswer, option, }) => {
    return (
        <div className='recover-div'>
            <div onClick={setAnswer} className={`${selected ? 'answer selected' : 'answer'}`} >
                <p>{option}</p>
            </div>
        </div>
    )
}

export default Answer