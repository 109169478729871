import { FC } from 'react';
import OutlinedInput from '@mui/material/OutlinedInput';
import InputAdornment from '@mui/material/InputAdornment';
import FormControl from '@mui/material/FormControl';

export type RSInputAdornmentProps = {
	value: string,
	setValue: any,
	label: string,
	arial: string,
	min?: number,
	max?: number,
	wholeNumber?: boolean,
	disabled?: boolean,
	sx?: object,
	labelSX?: object
}



const RSInputAdornment: FC<RSInputAdornmentProps> = ({value, setValue, label, arial, min, max, wholeNumber, disabled = false, sx = {}, labelSX = {}}) => {

	return <FormControl sx={{ mt: '3px', width: '100%'}} size="small" variant="outlined">
		<OutlinedInput
			sx={{
				'& .MuiInputBase-input': {
					mt: '5px',
					zIndex: 1
				},
				'& .MuiTypography-root': {
					mt: '6px',
					zIndex: 1
				},
				'& .MuiOutlinedInput-notchedOutline': {
					backgroundColor: 'white',
					borderColor: disabled ? 'white !important' : 'rgba(0, 0, 0, 0.23)'
				},
				height: '44px',
				opacity: disabled ? 1 : 1,
				...sx
			}}
			disabled={disabled}
			type='number'
			id="outlined-adornment-weight"
			endAdornment={<InputAdornment position="end" sx={labelSX}>{label}</InputAdornment>}
			aria-describedby="outlined-weight-helper-text"
			value={value}
			onKeyDown={(e: any) => (e.keyCode === 69 || e.keyCode === 187 || e.keyCode === 189 || (wholeNumber && e.keyCode === 180)) && e.preventDefault()}
			onChange={(e: any)=>{
				let val = e.target.value
				if (min !== undefined && val && Number(val) < min)
					val = value
				else if (max !== undefined && val && Number(val) > max)
					val = value
				setValue?.(val)
			}}
			inputProps={{
				'aria-label': arial,
			}}
		/>
	</FormControl>
}

export default RSInputAdornment;
