import AppMenus from '../../components/layout/AppMenus'
import MainCard from '../../components/cards/MainCard'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import WithAuth from '../../components/WithAuth'
import useWebinarsPage from '../../hooks/useWebinarsPage'
import { webinarLinks } from '../../modules/linkInfo'
import { FC } from 'react'

/**
 * Webinars Component
 */

const Webinars: FC = () => {
	// Hooks
	const { webinars } = useWebinarsPage()

	// Render
	return (
		<AppMenus>
			<Row>
				{Object.keys(webinars).map((key, index) => (
					<Col xs={12} md={6} key={index}>
						<MainCard
							type="responsiveCard"
							logoImage={webinars[key as keyof typeof webinars].mainLogo}
							bgImage={webinars[key as keyof typeof webinars].cover}
							link={webinarLinks[key as keyof typeof webinarLinks]}
						/>
					</Col>
				))}
			</Row>
		</AppMenus>
	)
}

export default WithAuth(Webinars)
