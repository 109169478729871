import WithAuth from '../../../components/WithAuth'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import MainCard from '../../../components/cards/MainCard'
import RSNavCont from '../../../components/layout/RSNavCont'
import { getSubscriptionInfo } from '../../../modules/accountActions'
// import { navReq } from '../../../modules/apiConsume'
import { FC, useEffect, useState } from 'react'

// Get End Date
const getEndDate = async () => {
	const { subscription } = await getSubscriptionInfo()
	const endDate = new Date(subscription.end_date).toLocaleDateString()
	return endDate
}

/**
 * Step5 Component
 */

const Step5: FC = () => {
	// State
	const [endDate, setEndDate] = useState('')

	// Get Date
	const getData = async () => {
		const newEndDate = await getEndDate()
		setEndDate(newEndDate || '')
	}

	// On Submit
	// const onSubmit = () => navReq('/#/')

	// Mount Effectu
	useEffect(() => {
		getData()
	}, [])

	// Render
	return (
		<RSNavCont contSX={{px: 1}}>
			<Row className="mb-0 d-flex justify-content-center cancel-plan-card account-container-small mt-4">
				<Col xs={12}>
					<MainCard
						cardType="split"
					>
						{/* Title */}
						<Row className="text-center mt-4 mb-4">
							<Col>
								{/* <div>
									<h1 className='cancel--title'>All Set</h1>
								</div> */}
								<div>
									<h3 className='cancel--title'>Your subscription has been cancelled</h3>
								</div>
							</Col>
						</Row>

						{/* Tagline */}
						<Row className="text-center">
							<Col>
								<p className='text-small'>
									You can access your RunSmart account until
									<h5 className="text-danger cancel--title mb-1">{`${endDate}`}</h5>
									<br />
									If at any time you want to renew your subscription, simply
									login and select a plan
									<br />
									<br />
									We hope to see you again soon!
								</p>
							</Col>
						</Row>
					</MainCard>
				</Col>
			</Row>
		</RSNavCont>
	)
}

export default WithAuth(Step5)
