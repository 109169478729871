import React, { FC } from 'react'
import { useNavigate } from 'react-router-dom'
import useQueryParams from '../../../hooks/useQueryParams'
import Indicator from '../../onboarding/Indicator'

const PROGRESS = [1, 2, 3, 4]

const Progress: FC<{ progress: number }> = ({ progress }) => {
    const { level } = useQueryParams()

    const navigate = useNavigate()

    return (
        <div className='content-questions'>
            <div className='indicators-small'>
                {PROGRESS.map(x => (
                    <Indicator navigate={() => x == 1 ? () => { null } : navigate(`/training-plan/build/step-${x}${level ? '?level=' + level : ''}`, { replace: true })} key={x} active={x <= progress ? true : false} />
                ))}
            </div>
        </div>
    )
}

export default Progress
