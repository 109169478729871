/* eslint-disable @typescript-eslint/no-unused-vars */
import { FC, useContext, useEffect, useState } from "react"
import { Col, Row } from "react-bootstrap"
import MainCard from "../../../components/cards/MainCard"
import AppMenus, { BodyComponent } from "../../../components/layout/AppMenus"
import GenericAlert from "../../../components/misc/GenericAlert"
import InstabotHide from "../../../components/styles/InstabotHide"
import useAlert from "../../../hooks/useAlert"
import useJoinVideo from "../../../hooks/useJoinVideo"
import useQueryParams from "../../../hooks/useQueryParams"
import { createSubscription, getExternalAccount, getPlanCosts, getSubscriptionInfo, updateBillingInfo, updateImpactClickId } from "../../../modules/accountActions"
import { HAS_SALE, IMPACT_TRIAL_ID, RECURLY_PUBLIC_KEY, WS_URL } from "../../../modules/cliEnvValues"
import { AuthPageProps } from "../../../modules/types"
import { DEFAULT_COSTS, GET_STARTED_IMG } from "../../../modules/values"
import { IComponentStore } from "../../../store/createComponentStore"
import useStore from "../../../store/useStore"
import PlanSelector from "./PlanSelector"
import ReactPixel from 'react-facebook-pixel';
import ApplePayButton from "../../../components/buttons/ApplePayButton"
import { Elements, RecurlyProvider, useRecurly } from "@recurly/react-recurly"
import { getProperties } from "../../../components/forms/ChooseYourPlanForm"
import usePlanProperties from "./usePlanProperties"
import PaymentDetails from "./PaymentDetails"
import moment from "moment"
import { navReq, postReq } from "../../../modules/apiConsume"
import GooglePayButton from "../../../components/buttons/GooglePayButton"
import WithAuth from "../../../components/WithAuth"
import RSButton from "../../../components/buttons/RSButton"
import useIsMobileScreen from "../../../hooks/useIsMobileScreen"
import useSale from "../../../hooks/useSale"
import Countdown from "react-countdown"
import { useLocation } from "react-router-dom"
import sha1 from "locutus/php/strings/sha1"
import useSurveyActions from "../../onboarding/useSurveyActions"
import useImpact from "../../../hooks/useImpact"
// import useWebSocket from "react-use-websocket"
import useDeviceDetect from "../../../hooks/useDeviceDetect"
import { PrimaryButton } from "../../../components/buttons/AuthSubmitButtons"
import GoogleIcon from '@mui/icons-material/Google';
import axios from "axios"
import useStoreWSMessages from "../../../hooks/useStoreWSMessages"
import { ArrowForwardIos, ArrowRight } from "@mui/icons-material"
import CouponModal from "./CouponModal"
import secureLocalStorage from "react-secure-storage"
import { WebSocketContext } from "../../../WebsocketProvider"

const Checkout: FC<AuthPageProps> = ({ userInfo }) => {

    // Hooks
    const {
        showAlert,
        onAlertClose,
        alertTitle,
        alertMessage,
        alertVariant,
        setAlertVariant,
        setAlertMessage,
        onAlertOpen
    } = useAlert()

    const isMobileScreen = useIsMobileScreen();

    const { impactData } = useImpact(userInfo?.account_id as number)

    const CLICK_ID = localStorage.getItem("irclickid") || undefined
    const localStorageRequestKey = localStorage.getItem('deviceRequestKey') || undefined


    //survey Hook
    const { pushDetailsToZapier, userSurveyDetails } = useSurveyActions(userInfo?.account_id as number)
    const [messageData, setMessageData] = useState<{ requestKey: string, payload: any, requestType: string, timestamp: number } | undefined>();
    // Hooks
    const { video } = useJoinVideo({ userInfo, isGetStarted: true })
    const [isPlaying, setIsPlaying] = useState(false)
    const [isNativeMobile, setIsNativeMobile] = useState(false)
    const [externalSubData, setExternalSubData] = useState<any>()
    const [hasSale, setHasSale] = useState(false)
    const [showCouponModal, setShowCouponModal] = useState(false)
    const [loadingGooglePay, setLoadingGooglePay] = useState(false)
    const [isSavingExternalSubscription, setIsSavingExternalSubscription] = useState(false)

    const isAppleSignup = secureLocalStorage.getItem("isAppleSignup") || false
    const isGoogleSignup = secureLocalStorage.getItem("isGoogleSignup") || false

    const { activeSale, isLoadingSale } = useSale()

    const [monthly, setMonthly] = useState(false)
    const [isValidCard, setIsValidCard] = useState(false)
    const [costs, setCosts] = useState({ ...DEFAULT_COSTS })

    // Params
    const url = useLocation()
    const { offer, skip_info, development, skipSurvey, requestKey } = useQueryParams()
    const hasOffer = Number(offer) == 2 || Number(offer) == 3 ? true : false
    // const hasSale = Number(offer) == 2 || HAS_SALE || activeSale ? true : false
    const skipInfo = skip_info ? true : false

    const { setIsLoading, setLoadingMsg, setIsSuccess, isLoading } = useStore((state: IComponentStore) => state)
    const { storeWebSocketMessages, webSocketMessages, removeMessageByType } = useStoreWSMessages()

    const getProperties = usePlanProperties()

    const [firstName, setFirstName] = useState("")
    const [lastName, setLastName] = useState("")
    const [postalCode, setPostalCode] = useState("")
    const [planProperties, setPlanProperties] = useState<any>()

    const recurly = useRecurly()

    const { mobileType } = useDeviceDetect()

    const { sendJsonMessage, lastJsonMessage } = useContext(WebSocketContext);

    // const { sendJsonMessage, lastMessage, lastJsonMessage } = useWebSocket(WS_URL,
    //     {
    //         shouldReconnect: (closeEvent) => {
    //             return true;
    //         },
    //         reconnectAttempts: 3,
    //         reconnectInterval: 30000,
    //     }, requestKey || localStorageRequestKey ? true : false);
    // const { monthCost, yearCost, yearlyByMonthCost, savingsPercent, trialDate, yearlyDate, expirationDate, } = getProperties(costs.monthly, costs.annual, monthly, hasSale)

    const handleSwitchSelected = () => {
        setMonthly((prev) => !prev)
    }

    const FORM_VALID = firstName !== "" && lastName !== "" && postalCode !== "" && isValidCard ? true : false;
    const IMAGE_BG = isMobileScreen ? "/images/icons/mobile-header.jpg" : "/images/icons/desktop-header.jpg"

    // After Billing Change
    const afterBillingChange = async () => {
        const signupMethod = (isNativeMobile && mobileType === "Ios") ? 'Ios' : (isNativeMobile && mobileType === "Android") ? 'Android' : 'Web'
        // ReactPixel.trackCustom('AddPaymentInfo', { k: new Date().getTime() });
        const planCode = monthly ? costs.monthlyCode : costs.annualCode
        await createSubscription(planCode, signupMethod)
    }

    // On Change
    const onChange = (event: any) => {
        if (event.valid != null) {
            setIsValidCard(event.valid)
        }
    }

    // After Billing Process
    const afterBillingProcess = async () => {
        // ReactPixel.trackCustom('AddPaymentInfo', null);
        ReactPixel.trackCustom('StartTrial', { k: new Date().getTime() });
        if (skipSurvey && skipSurvey === "1" || !userSurveyDetails || userSurveyDetails && !userSurveyDetails.running_goal)
            navReq('/#/user-info')
        else
            navReq('/#/suggested-programs')
    }

    // On Submit
    const onSubmit = () => {
        // ReactPixel.trackCustom('InitiateCheckout', { k: new Date().getTime() });
        setIsLoading(true)
        const errorMsg = () => {
            setAlertVariant('danger')
            setAlertMessage(
                `Could not create subscription.`
            )
            onAlertOpen()
            return false
        }
        try {
            const form = document.querySelector('#billing-form') as HTMLFormElement
            recurly.token(form, async (err, token) => {
                if (err) {
                    setIsLoading(false)
                    return errorMsg()
                } else {
                    const billingResult = await updateBillingInfo(token.id)
                    if (billingResult?.data?.id) {

                        // After Billing Update
                        await afterBillingChange()
                        const { isActive, subscription } = await getSubscriptionInfo()
                        if (isActive && subscription?.id) {
                            setIsLoading(false)
                            setIsSuccess(true)
                            setTimeout(() => {
                                setIsSuccess(false)
                                setTimeout(async () => {
                                    await afterBillingProcess()
                                }, 500)
                            }, 3000)


                            return true
                        }
                    }
                    setIsLoading(false)
                    return errorMsg()
                }
            })
        } catch (err) {
            setIsLoading(false)
            return errorMsg()
        }
    }

    // Set Plan Costs
    const setPlanCosts = async (isSale?: boolean) => {
        if (isSale) {
            setLoadingMsg('Please wait')
            setIsLoading(true)
        }
        await getPlanCosts({
            hasOffer,
            isRenew: false,
            isYearlyRenewOnly: false,
            hasSale: isSale,
        }).then(newCosts => {
            setCosts(newCosts)
        })

        if (isSale) {
            setLoadingMsg(undefined)
            setIsLoading(false)
        }

    }

    const initiateImpactConversion = async () => {
        if (CLICK_ID && impactData) {
            const isCreated = impactData?.status === "created" ? true : false;
            if (isCreated) {
                const data = { id: impactData?.id as number, status: 'in-trial', user_id: impactData.user_id }
                window?.callConversion?.(IMPACT_TRIAL_ID, impactData?.conversion_oid as string, String(userInfo?.account_id), sha1(userInfo?.email))
                await updateImpactClickId(data)
            }
        }
    }

    const handleInitPurchaseSubscription = async () => {
        const localStorageRequestKey = localStorage.getItem('deviceRequestKey')
        // const accountToken = await
        if (requestKey && userInfo?.account_id || localStorageRequestKey && userInfo?.account_id) {
            sendJsonMessage && sendJsonMessage({
                requestType: 'google-choose-plan-webapp',
                requestKey: requestKey || localStorageRequestKey,
                payload: monthly ?
                    { amount: costs.monthly, planCode: costs.monthlyCode, type: 'trial', account_id: userInfo.account_id }
                    : { amount: costs.annual, planCode: costs.annualCode, type: 'trial', account_id: userInfo.account_id },
                timestamp: new Date().getTime()
            })
        }
    }

    const handleSaveExternalSubscription = async (data: any) => {
        removeMessageByType('apple-success-sub-mobileapp')
        removeMessageByType('google-success-sub-mobileapp')
        const localStorageRequestKey = localStorage.getItem('deviceRequestKey')
        // setIsSavingExternalSubscription(true)
        if ((messageData?.requestKey === requestKey || messageData?.requestKey === localStorageRequestKey) && messageData.payload) {
            // await axios.post('http://192.168.1.3:4000/api/v2/external-subscription', data).catch(err => alert(JSON.stringify(err)))
            await postReq('/v2/external-subscription', data).then(async () => await afterBillingProcess())
        }

    }

    const handleGetExternalAccount = async () => {
        const localStorageRequestKey = localStorage.getItem('deviceRequestKey')
        const external = await getExternalAccount("AppleAppStore", requestKey || localStorageRequestKey as string)
        console.log(external)
    }

    useEffect(() => {
        // setPlanCosts(hasSale)
        ReactPixel.trackCustom('Lead', { k: new Date().getTime() });
        // ReactPixel.trackCustom('track', 'StartTrial');
    }, [])

    useEffect(() => {
        const processZap = async () => await pushDetailsToZapier(Number(userInfo?.account_id), isAppleSignup ? 'Apple' : isGoogleSignup ? 'Google' : (isNativeMobile && mobileType === "Ios") ? 'Ios' : (isNativeMobile && mobileType === "Android") ? 'Android' : 'Web')

        if (userInfo?.account_id && skipSurvey && skipSurvey === "1")
            processZap()
    }, [skipSurvey, userInfo])

    useEffect(() => {
        const init = async () => await initiateImpactConversion();
        init()
    }, [impactData])

    useEffect(() => {
        // console.log(activeSale)
        if (activeSale && !localStorageRequestKey) {
            if (activeSale.active == 1)
                setHasSale(true)

            else if (activeSale.active == 0)
                setHasSale(false)
        }
        else
            setHasSale(false)
    }, [activeSale, localStorageRequestKey])

    useEffect(() => {
        if (hasSale && costs.annual == DEFAULT_COSTS.annual) {
            setPlanCosts(hasSale)
        }
        else {
            const newPlanProps = getProperties(costs.monthly, costs.annual, monthly, hasSale)
            setPlanProperties(newPlanProps)
        }

    }, [costs, hasSale])

    useEffect(() => {
        setPlanCosts(hasSale)
    }, [hasSale])

    useEffect(() => {
        hasOffer ? setHasSale(true) : setHasSale(false)
    }, [offer])

    useEffect(() => {
        if (requestKey) {
            localStorage.setItem("deviceRequestKey", requestKey)
        }
    }, [requestKey])

    useEffect(() => {
        setIsNativeMobile((requestKey || localStorage.getItem("deviceRequestKey")) ? true : false)
    }, [localStorage.getItem("deviceRequestKey")])

    useEffect(() => {
        console.log(lastJsonMessage)
        // handleGetExternalAccount()
        const localStorageRequestKey = localStorage.getItem('deviceRequestKey')
        if (userInfo?.account_id && lastJsonMessage && (lastJsonMessage.requestKey === requestKey || lastJsonMessage.requestKey === localStorageRequestKey)) {
            setMessageData(lastJsonMessage)
            storeWebSocketMessages(lastJsonMessage)
        }
    }, [lastJsonMessage, userInfo]);

    useEffect(() => {
        // alert(JSON.stringify(messageData))
        const localStorageRequestKey = localStorage.getItem('deviceRequestKey')

        const handleSucessSub = webSocketMessages.find(x => (x?.requestKey === requestKey || x?.requestKey === localStorageRequestKey) && (x?.requestType === "google-success-sub-mobileapp"))

        const handleSucessSubIos = webSocketMessages.find(x => (x?.requestKey === requestKey || x?.requestKey === localStorageRequestKey) && (x?.requestType === "apple-success-sub-mobileapp"))

        // alert(handleSucessSub)
        // alert(handleSucessSubIos)

        if (userInfo?.account_id && handleSucessSub) {
            const externalData = { user_id: userInfo?.account_id, type: 'google', purchase_token: handleSucessSub.payload, is_expired: 0, app_account_token: requestKey || localStorageRequestKey }
            handleSaveExternalSubscription(externalData)
        }
        else if (userInfo?.account_id && handleSucessSubIos) {
            const externalData = { user_id: userInfo?.account_id, type: 'apple', purchase_token: handleSucessSubIos.payload, is_expired: 0, app_account_token: requestKey || localStorageRequestKey }
            handleSaveExternalSubscription(externalData)

            // alert(externalData)
        }
        // alert(isSavingExternalSubscription)

    }, [webSocketMessages]);


    // useEffect(() => {
    //     if (externalSubData) {
    //         // alert(JSON.stringify(externalSubData))
    //         handleSaveExternalSubscription(externalSubData)
    //     }

    //     return () => {
    //         setExternalSubData(undefined);
    //     };
    // }, [externalSubData]);
    // useEffect(() => {
    //     if (userInfo?.account_id && CLICK_ID)
    //         window.callConversion(34841, "", String(userInfo?.account_id), sha1(userInfo?.email))
    // }, [CLICK_ID, userInfo])

    const renderer = ({ days, hours, minutes, seconds, completed }: any) => {
        if (completed) {
            // Render a complete state
            return (
                <></>
            )
        } else {
            // Render a countdown
            return (
                <Row className={`sale-banner ${offer === "3" ? 'bg-tertiary' : 'bg-primary'}  `}>
                    <p className={`fw-bold ${offer === "3" ? 'text-white' : 'text-tertiary'}`}>SALE ENDS:
                        <span className="text-white">
                            {offer === "3" ? (<>
                                {' '} {hours} hr {minutes} min {seconds} sec
                            </>
                            ) :
                                <>
                                    {' '} {days} DAYS {hours} HRS {minutes} MIN {seconds} SEC
                                </>
                            }
                        </span>
                    </p>
                </Row>
            );
        }
    };

    const getCountDownToday = () => {
        const now = new Date();
        const startOfDay = new Date(now.getFullYear(), now.getMonth(), now.getDate());
        const secondsElapsed = (now.getTime() - startOfDay.getTime()) / 1000;
        const remainingSecs = 86400 - secondsElapsed;
        return moment(now).add(remainingSecs, 'seconds').toLocaleString()
    }

    return (
        <AppMenus hideOptions hideBackButton showLogout>
            <InstabotHide />
            <div className="checkout full-white-bg mt-md-n4">
                <CouponModal
                    email={userInfo?.email}
                    signupMethod={(isNativeMobile && mobileType === "Ios") ? 'Ios' : (isNativeMobile && mobileType === "Android") ? 'Android' : 'Web'} showModal={showCouponModal}
                    handleCloseModal={setShowCouponModal}
                    afterBillingProcess={afterBillingProcess}
                />
                {activeSale && hasSale ? (
                    <Countdown date={moment(activeSale.end).add(3, 'hours').toLocaleString()} renderer={renderer} />
                ) : offer === "3" ? (
                    <Countdown date={getCountDownToday()} renderer={renderer} />
                ) : undefined}
                <Row className="mt-md-4">
                    <Col xs={12} className="d-md-none">
                        <Row className="justify-content-center mb-md-5 mb-3">
                            <Col className="px-0 px-sm-3">
                                <div className="video-wrapper checkout-video blue-bg">
                                    <MainCard
                                        cardType="normal"
                                        hasPlayButton={false}
                                        // playButtonIsCentered
                                        type={`${isMobileScreen ? 'responsiveCardLg' : 'responsiveCardFill'}`}
                                        bgImage={IMAGE_BG}
                                    // vimeoId={778417005}
                                    // onPlay={() => setIsPlaying(true)}
                                    />

                                </div>
                            </Col>
                        </Row>
                    </Col>
                    <Col className="scaled-div" xs={12} md={6}>
                        <div className="title">
                            {/* <h3>Choose Your Plan</h3> */}
                            {activeSale && hasSale ? (
                                <h3>{activeSale.headline}</h3>
                            ) : (
                                // <h3>Your first 14 days are free –
                                //     cancel any time</h3>
                                <h3>Your training is ready.</h3>
                            )}

                        </div>

                        <div className="subtitle">
                            {/* <p>You’re steps away from locking in all the training you need to PR in 2023! <br />Your first 14 days are <b><u>free</u></b>, cancel anytime.</p> */}
                            {activeSale && hasSale ? (
                                <p className='text-center mt-4 mb-4' dangerouslySetInnerHTML={{
                                    __html: `${activeSale.subtext}`
                                }} />
                                // <p dangerouslySetInnerHTML={}>{activeSale.subtext} <b>-- all from one app.</b></p>
                            ) : (
                                // <p>Personalized training plans, strength workouts & injury prevention for all levels of runners.</p>
                                <p>Choose a plan below to get started. Your first 14 days are <b><u>free</u></b>, cancel any time</p>
                            )}
                        </div>
                        <div className="mb-5">
                            <PlanSelector
                                banner={hasSale ? activeSale?.banner : 'MOST POPULAR'}
                                selected={!monthly}
                                months="12 Months"
                                rate={`$${planProperties?.yearlyByMonthCost || ""}/month`}
                                discount={`${planProperties?.savingsPercent || 0}% OFF MONTHLY PLAN`}
                                total={planProperties?.yearCost || 0}
                                onSelected={!loadingGooglePay ? handleSwitchSelected : undefined}
                            />
                            <PlanSelector
                                selected={monthly}
                                months="1 Month"
                                rate="$15/month"
                                discount="FIRST TWO WEEKS FREE"
                                total={planProperties?.monthCost || 0}
                                onSelected={!loadingGooglePay ? handleSwitchSelected : undefined}
                            />

                            <div className="w-100 coupon-link">
                                <p onClick={() => { setShowCouponModal(true) }} className="">Enter Race Code<ArrowForwardIos /></p>
                            </div>
                        </div>

                        <div className="">
                            <div className="other-btn">
                                {/* {costs.annualCode} */}
                                {/* <ApplePayButton
                                    planCode={monthly ? costs.monthlyCode : costs.annualCode}
                                    afterBillingChange={afterBillingChange}
                                    afterBillingProcess={afterBillingProcess}
                                    tranxType={"create-signup"}
                                /> */}
                                {/* {(!(mobileType === "Android" || mobileType === "Ios") && hasSale && !isLoadingSale) || !(mobileType === "Android" || mobileType === "Ios") && !hasSale && !isLoading && userInfo?.account_id ? ( */}
                                {!localStorage.getItem("deviceRequestKey") ? (
                                    <>
                                        {/* <ApplePayButton
                                            planCode={monthly ? costs.monthlyCode : costs.annualCode}
                                            afterBillingChange={afterBillingChange}
                                            afterBillingProcess={afterBillingProcess}
                                            tranxType={"create-signup"}
                                        />
                                        <GooglePayButton
                                            planCode={monthly ? costs.monthlyCode : costs.annualCode}
                                            afterBillingChange={afterBillingChange}
                                            afterBillingProcess={afterBillingProcess}
                                            tranxType={"create-signup"}
                                            setLoadingGooglePay={setLoadingGooglePay}
                                            loadingGooglePay={loadingGooglePay}
                                        /> */}
                                    </>
                                ) : undefined}
                                {/* {(mobileType === "Android" || mobileType === "Ios") && (requestKey || localStorage.getItem("deviceRequestKey")) ? (
                                    <PrimaryButton
                                        primaryText={"Subscribe with Google"}
                                        primaryAction={handleInitPurchaseSubscription}
                                        primaryDisabled={false}
                                        icon={<GoogleIcon />}
                                    />
                                ) : undefined} */}

                            </div>
                            {isNativeMobile ? (<br />) :
                                <PaymentDetails
                                    firstName={firstName}
                                    lastName={lastName}
                                    postalCode={postalCode}
                                    setFirstName={(e) => setFirstName(e.target.value)}
                                    setLastName={(e) => setLastName(e.target.value)}
                                    setPostalCode={(e) => setPostalCode(e.target.value)}
                                    onChangeCard={onChange}
                                />
                            }

                        </div>
                        <div className="trial-info">
                            <span className='trial-ends mt-n3'>
                                {monthly || !hasSale && !monthly ? (
                                    <p>After Trial Ends</p>
                                ) : (
                                    <p>Total</p>
                                )}

                                <p>${monthly ? planProperties?.monthCost || 0 : planProperties?.yearCost || 0} USD</p>
                            </span>

                            <span className='billing-and-trial-period mt-n2'>
                                <p>
                                    {monthly || !hasSale && !monthly ? 'First 14 days are free, cancel anytime.' : ''}
                                    <br />Billed every {monthly ? 'month' : '12 months'} from {monthly || !hasSale && !monthly ? moment(planProperties?.trialDate).format('MMMM D, YYYY') : moment(planProperties?.yearlyDate).add(1, 'years').format('MMMM D, YYYY')}. </p>
                            </span>
                        </div>
                        <RSButton
                            onClick={!isNativeMobile ? onSubmit : isNativeMobile ? handleInitPurchaseSubscription : undefined}
                            disabled={(!isNativeMobile && !FORM_VALID) ? true : isNativeMobile ? false : false}
                            sx={{ height: '75px', fontSize: '20px', maxWidth: '420px', mx: 'auto', display: 'flex' }}>
                            {monthly || !hasSale && !monthly ? 'START FREE TRIAL' : 'SUBSCRIBE NOW'}
                        </RSButton>

                        <div className="chosen-plan-info mt-4">
                            <p>
                                {!monthly ? `Your chosen 12 months plan saves you ${planProperties?.savingsPercent || 0}% off the monthly price. ` : ''}
                                Your account will be charged every {monthly ? 'month' : '12 months'} from {monthly || !hasSale && !monthly ? moment(planProperties?.trialDate).format('MMMM D, YYYY') : moment(planProperties?.yearlyDate).add(1, 'years').format('MMMM D, YYYY')}. Upgrade or cancel your plan
                                on the website in your account settings anytime.
                            </p>
                        </div>

                        <div className='mt-4 mb-3 text-center footer-info'>
                            <p>
                                Read our {' '}
                                <a href={localStorageRequestKey ? undefined : 'https://runsmartonline.com/privacy-policy/'} target={localStorageRequestKey ? '' : 'blank'} onClick={() => {
                                    localStorageRequestKey ? navReq('/#/privacy-policy') : undefined
                                }} className='pointer'>
                                    <u>Privacy Policy, </u>
                                </a>{' '}

                                <a href={localStorageRequestKey ? undefined : 'https://runsmartonline.com/waiver-of-liability/'} target={localStorageRequestKey ? '' : 'blank'} onClick={() => {
                                    localStorageRequestKey ? navReq('/#/waiver-of-liability') : undefined
                                }} className='pointer'>
                                    <u>Waiver of Liability, </u>
                                </a>{' '}
                                and {' '}
                                <a href={localStorageRequestKey ? undefined : 'https://runsmartonline.com/terms-of-use/'} target={localStorageRequestKey ? '' : 'blank'} onClick={() => {
                                    localStorageRequestKey ? navReq('/#/terms-of-use') : undefined
                                }} className='pointer'>
                                    <u>Terms of Service</u>
                                </a>
                            </p>
                        </div>
                        {!isNativeMobile ? (
                            <div className="stripe-footer mt-4 mb-5">
                                <img className="guard" src="/images/icons/security-badge.svg" />
                                <img src="/images/icons/Powered by Stripe - black.svg" />
                            </div>
                        ) : undefined}
                    </Col>
                    <Col xs={12} md={6} className="d-none d-md-block">
                        <Row className="justify-content-center mb-md-5 mb-3">
                            <Col className="px-0 px-sm-3">
                                <div className="">
                                    <MainCard
                                        cardType="normal"
                                        hasPlayButton={false}
                                        // playButtonIsCentered
                                        type={`${isMobileScreen ? 'responsiveCardLg' : 'responsiveCardFill'}`}
                                        bgImage={IMAGE_BG}
                                    // vimeoId={778417005}
                                    // onPlay={() => setIsPlaying(true)}
                                    />

                                </div>
                            </Col>
                        </Row>
                    </Col>
                </Row>
            </div>
        </AppMenus >
    )
}

export default WithAuth(Checkout, true)
