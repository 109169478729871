// Guide Features
export const guideFeatures = {
	Strength:
		'/img-new/guides/strength/featured_images/strength_guide_featured_image_desktop.jpg',
	Training:
		'/img-new/guides/training_guides/featured_images/training_guides_featured_image_desktop.jpg',
}

// Guide Downloads
export const guideDownloads = {
	'5K 10K':
		'/img-new/guides/download images/5-10k-thumbnail-download-image.jpg',
	'Half Marathon':
		'/img-new/guides/download images/Half-Marathon-guide-thumbnail-download-image.jpg',
	Marathon:
		'/img-new/guides/download images/Marathon-guide-thumbnail-download-image.jpg',
	Strength: '/img-new/guides/download images/Strength-guide-thumbnail.jpg',
}
