/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/no-empty-function */
import { useState, useEffect} from 'react'
import { useNavigate } from 'react-router-dom'
import moment from 'moment';
import dayjs from 'dayjs';

import Drawer from '@mui/material/Drawer';
import Slider from '@mui/material/Slider';
import Modal from '@mui/material/Modal';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import ArrowBack from '@mui/icons-material/ArrowBackIosNew'
import TextField from '@mui/material/TextField';
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';

import useTrainingPlanUtils from '../../hooks/useTrainingPlanUtils';
import { SkipMaintenancePlan, ICreateTrainingPlanParams } from '../../api/v2/types'
import { SkipMaintenance, GetRaceResult, CreateTrainingPlan } from '../../modules/trainingPlanActions'
import useStore from '../../store/useStore'
import { ICurrentUser } from '../../store/createAccountStore'

const marks = [
    {
      value: 20,
      label: '20'
    },
    {
      value: 30,
      label: '30'
    },
		{
			value: 40,
			label: '40'
		}
  ];

const AddMaintenancePlan = (props: any) => {
	const navigate = useNavigate()
	const {addMaintenance, setAddMaintenance, doNotAddMaintenance, setDoNotAddMaintenance, userInfo, getRaceResult, setLoadingValues, handleGetJourney} = props
	const [planName, setPlanName] = useState<any>('Maintenance Plan')
	const [mileage, setMileage] = useState(marks[0].value);
  const [startDate, setStartDate] = useState<any>('');
	const { ConvertS2TimeObject } = useTrainingPlanUtils()
  const { currentUser } = useStore((state: ICurrentUser) => state)

  const handleCreateMaintenance = async () => {
  	const type = userInfo?.raceResults?.race_type?.toLowerCase()
  	const data: ICreateTrainingPlanParams = {
			userId: currentUser?.account_id as number,
			marathon: 4,
			weeks: 10,
			mileage: mileage,
			plan: (type === '5k' || type === '10k') ? '5k' : type === 'fullmarathon' ? 'M' : 'hm',
			time: ConvertS2TimeObject(userInfo?.raceResults?.finished_time_in_sec),
			date: moment(new Date(startDate))?.subtract(1, 'd')?.format('MM/DD/YYYY') as string,
			km: userInfo?.km === 'N' ? false : true,
			agg: userInfo?.aggressiveness,
			monday: userInfo?.monday_start ? true : false,
			vdot_adj: false,
			training_level: 3,
			recovery_id: userInfo?.raceResults?.recoveryPlan?.id
		}
		await CreateTrainingPlan(data)
		await SkipMaintenance({recovery_id: userInfo?.raceResults?.recoveryPlan?.id} as SkipMaintenancePlan)
		handleGetJourney?.()
		setAddMaintenance(false)
		navigate('/training-plan-journey/view', { replace: true })
  }

  const handleSkipMaintenance = async () => {
  	const recovery_id = userInfo?.raceResults?.recoveryPlan?.id
  	if (recovery_id) {
			setLoadingValues?.(true)
  		await SkipMaintenance({recovery_id} as SkipMaintenancePlan)
  		getRaceResult && await getRaceResult()
  		handleGetJourney?.()
  	}
  	setDoNotAddMaintenance(false)
  }

  const handleBack = () => {
  	setPlanName('Maintenance Plan')
  	setMileage(marks[0].value)
  	setDoNotAddMaintenance(false)
  	setAddMaintenance(false)
  }

	useEffect(() => {
		const day = moment(userInfo?.race_date).add(1, 'd').day()
		if (day != 0 && day != 1) {
			setStartDate(dayjs(moment(userInfo?.race_date).add(8-day,'d').format('YYYY-MM-DD')))
		} else
			setStartDate(dayjs(moment(userInfo?.race_date).add(1,'d').format('YYYY-MM-DD')))
	},[userInfo?.race_date, addMaintenance])

	const disableWeekends = (t: any) => {
		const day =  moment(new Date(t))?.day()
		return (day != 0 && day != 1 )|| moment(new Date(t))?.isBefore(moment(userInfo?.race_date))
	}

	return <>
		<Drawer
			className='bg-main'
			anchor='right'
			open={addMaintenance}
			onClose={() => setAddMaintenance(false)}
			sx={{
				'& *': {
					fontFamily: 'Poppins'
				},
				'& .MuiDrawer-paperAnchorRight': {
					width: '100%',
					border: 'none !important',
					backgroundColor: 'inherit',
					margin: 'auto'
				}
			}}
		>
			<Box className='bg-main' sx={{ minHeight: window.innerHeight }}>
				<Box sx={{ p: 2, pr: '10px', height: '60px', pb: '0px', width: '100%', position: 'fixed', zIndex: 10, backgroundColor: 'white', boxShadow: '-2px 2px 4px rgba(0, 0, 0, 0.15)' }}>
					<Grid container sx={{ position: 'relative' }}>
						<Grid container xs={12} justifyContent="space-between" alignItems="center">
							<Box sx={{ display: 'flex', justifyContent: 'flex-start', alignItems: 'center' }}>
								<ArrowBack onClick={handleBack} />
								<Box sx={{ ml: '10px', fontFamily: 'Poppins', fontSize: '18px', letterSpacing: 'normal' }}>
									Add Maintenance Plan
								</Box>
							</Box>
						</Grid>
					</Grid>
				</Box>
				<Grid container sx={{ p: 2, pr: '10px', pt: '81px', maxWidth: '820px', mx: 'auto' }}>
					<Grid container xs={12} justifyContent="center" alignItems="center">
						<TextField
							value={planName}
							onChange={(e) => setPlanName(e.target.value)}
							sx={{
								'& .MuiInputLabel-root': {
									color: '#80808061',
									fontSize: '16px',
									fontFamily: '#80808061'
								},
								'& .MuiInputLabel-shrink.Mui-focused': {
									color: '#1976d2'
								},
								width: '100%',
								backgroundColor:'white',
								mt: '10px'
							}}
							label="Enter Plan Name"
							variant="outlined"
						/>
					</Grid>
					<Grid container xs={6} sx={{mt: '10px'}} justifyContent="flex-start" alignItems="center">
						<LocalizationProvider dateAdapter={AdapterDayjs}>
							<DemoContainer sx={{
								'& .MuiTextField-root': {
									minWidth: '100% !important'
								}}} components={['DatePicker']}>
								<DatePicker
									sx={{
										'& .MuiOutlinedInput-root': {
											height: '55px'
										},
										'& .MuiInputLabel-root': {
											color: '#80808061',
											fontSize: '16px',
											fontFamily: '#80808061'
										},
										'& .MuiInputLabel-shrink.Mui-focused': {
											color: '#1976d2'
										},
										width: '100%',
										backgroundColor:'white',
										mt: '10px'
									}}
									shouldDisableDate={disableWeekends}
									value={startDate}
									onChange={(newValue: any) => setStartDate(dayjs(moment(new Date(newValue)).format('YYYY/MM/DD')))}
									label="Start Date" />
							</DemoContainer>
						</LocalizationProvider>
					</Grid>
					<Grid container xs={12} sx={{ mb: '10px', pt: '30px', px: '5px' }} justifyContent="flex-start" alignItems="center">
						 <Grid container>
							<Grid container xs={12} justifyContent="center" alignItems="center">
								<Box sx={{ fontFamily: 'Poppins !important', fontSize: '16px !important', textAlign: 'center' }} >
									How much do you want to run each week?
								</Box>
							</Grid>
							<Grid container sx={{px: '10px'}} justifyContent="center" alignItems="center">
								<Slider
									sx={{
										width: 300,
										color: '#9FCD2B'
									}}
									value={mileage}
									onChange={(e: any, val: number | number[])=>setMileage(val as number)}
									valueLabelFormat='auto'
									step={10}
									marks={marks}
									min={marks[0].value}
									max={marks[marks.length-1].value}
								/>
							</Grid>
						</Grid>
					</Grid>
					<Grid container xs={12} sx={{ mt: '20px', px: 2 }} >
						<Button onClick={handleCreateMaintenance} disabled={!(planName && startDate)} variant='contained' sx={{ boxShadow: 'none', color: '#010101', width: '100%', fontFamily: 'Poppins', p: '8px 0px', borderRadius: '5px', fontSize: '14px', backgroundColor: (planName && startDate) ? '#9FCD2B !important': 'default', textTransform: 'none', border: `1px solid ${!(planName && startDate) ? 'transparent' : '#9FCD2B'}` }}>
							Build Plan
						</Button>
					</Grid>
				</Grid>
			</Box>
		</Drawer>

		<Modal
			open={doNotAddMaintenance}
			onClose={() => setDoNotAddMaintenance(false)}
		>
			<Box sx={{ display: 'flex', backgroundColor: '#EFEFF6', justifyContent: 'space-between', flexDirection: 'column', position: 'absolute', borderRadius: '5px', py: '25px', top: '50%', left: '50%', minWidth: window.innerWidth > 990 ? '500px' : '90%' , transform: 'translate(-50%, -50%)' }}>
				<Grid container xs={12} sx={{ fontSize: '18px', fontFamily: 'Poppins-medium', px: '5px', textAlign: 'center'}} justifyContent='center' alignItems='center'>
					{`Don't Add Maintenance Plan`}
				</Grid>
				<Grid container xs={12} sx={{py: '15px'}} justifyContent='center' alignItems='center'>
					<Button onClick={handleSkipMaintenance} variant='contained' sx={{ boxShadow: 'none', color: '#010101', width: '70%', fontFamily: 'Poppins', border: '1px solid #9FCD2B', p: '8px 0px', borderRadius: '5px', fontSize: '14px', backgroundColor: '#9FCD2B !important', textTransform: 'none' }}>
						Confirm
					</Button>
				</Grid>
				<Grid container xs={12} sx={{fontSize: '14px', textDecoration: 'underline', color: '' }} justifyContent="center" alignItems="center">
					<Button onClick={() => setDoNotAddMaintenance(false)} sx={{ textTransform: 'none', color: '#485D13' }}>Cancel</Button>
				</Grid>
			</Box>
		</Modal>
	</>

}

export default AddMaintenancePlan;
