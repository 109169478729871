/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/no-empty-function */
import { FC, useState, useEffect, useRef } from 'react'
import { motion } from "framer-motion";
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';

import RSButton from '../../../../components/buttons/RSButton'

export type PhysicalActivityInfoProps = {
    done?: any,
		bodyData?: any,
		setBodyData?: any,
		nextAvailability?: any
}

const PhysicalActivityInfo: FC<PhysicalActivityInfoProps> = ({done, bodyData, setBodyData, nextAvailability}) => {
		const [physicalActivity, setPhysicalActivity] = useState(bodyData?.physicalActivity?.index === undefined ? '' : bodyData?.physicalActivity.index);


		const activities = [{
			title: 'Little to No Exercise'
		},{
			title: 'Slightly Active',
			desc: 'Light, 1 - 3 times per week'
		},{
			title: 'Moderately Active',
			desc: 'Moderate intensity, 4 - 5 times per week'
		},{
			title: 'Very Active',
			desc: 'Daily exercise or training for half marathon'
		},{
			title: 'Extra Active',
			desc: 'Exercise more than 1x/day training for >= 26.2'
		}]

		useEffect(()=>{
			setBodyData({
				...bodyData,
				physicalActivity: {
					index: physicalActivity,
					...activities[physicalActivity]
				}
			})

			nextAvailability(physicalActivity !== '')
		},[physicalActivity])

    return <Box sx={{display: 'flex', flexDirection: 'column', width: '100%'}}>
			<Grid container xs={12} sx={{pb: '15px'}} justifyContent='space-between' >
				<Grid container xs={12} sx={{fontFamily: 'Poppins-Bold', fontSize: '14px', lineHeight: '1.4em', pb: '10px'}}>
					How would you rate your current physical activity?
				</Grid>
				<Grid container xs={12} justifyContent='space-between'>
					{activities.map((activity: any, ai: number)=>{
						const isActive = ai === physicalActivity
						return <Grid item container xs={5.6} key={'activity-'+ai} sx={{minHeight: '100px', width: '100px', mb: '20px'}} justifyContent='center' alignItems='center'>
							<RSButton card secondary={!isActive} sx={{height: '100%', flexDirection: 'column'}} onClick={()=>setPhysicalActivity(ai)}>
								<Box sx={{fontFamily: 'Poppins-Medium !important', fontSize: '14px !important', textAlign: 'center', lineHeight: '1em', pb: '5px'}}>{activity.title}</Box>
								{activity.desc && <Box sx={{fontFamily: 'Poppins-light !important', fontSize: '10px !important', lineHeight: '1.2em', textAlign: 'center'}}>{activity.desc}</Box>}
							</RSButton>
						</Grid>
					})}
				</Grid>
			</Grid>
		</Box>
}

export default PhysicalActivityInfo
