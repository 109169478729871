import { FC } from 'react'

import { createTheme, ThemeProvider } from '@mui/material/styles';

const theme = createTheme({
	palette: {
		primary: {
			main: '#9FCD2B',
			light: '#9fcd2b66',
			dark: '#fff',
			contrastText: '#010101',
		},
	},
});


export type RsThemeProps = {
	children?: any
}


const RsTheme: FC<RsThemeProps> = ({children}) => {

	return <>
		<ThemeProvider theme={theme}>
			{children}
		</ThemeProvider>
	</>
}

export default RsTheme;
