import VideoDetailCard from './VideoDetailCard'
import VideoDetailText from '../text/VideoDetailText'
import classNames from 'classnames'
import ImageCard, { ImageCardProps } from './ImageCard'
import HomeDetailCard, { HomeDetailCardProps } from './HomeDetailCard'
import SplitCard, { SplitCardProps } from './SplitCard'
import { navReq } from '../../modules/apiConsume'
import { doNothingClick } from '../../modules/miscUtils'
import { NavLink, useNavigate } from 'react-router-dom'
import { FC, useEffect, useState } from 'react'

// Props
export type MainCardProps = Partial<ImageCardProps> &
	Partial<HomeDetailCardProps> &
	Partial<SplitCardProps> & {
		hasCheck?: boolean
		detailTitle?: string
		detailSubtitle?: string
		isInline?: boolean
		cardType?: 'normal' | 'video' | 'split'
		isFullHeight?: boolean
		isScrolling?: boolean
		link?: string
		vimeoId?: number | string,
		replaceURL?: boolean
	}

/**
 * MainCard Component
 */

const MainCard: FC<MainCardProps> = ({
	videoId,
	title,
	text,
	bgImage,
	logoImage,
	logoType,
	logoIsLg,
	logoIsMd,
	type,
	hasCheck,
	detailTitle,
	detailSubtitle,
	hasPlayButton,
	playButtonIsCentered,
	cardType,
	isFullHeight,
	link,
	vimeoId,
	isInline,
	isScrolling,
	buttonText,
	buttonLink,
	buttonDisabled,
	children,
	progress,
	isColored,
	isSmall,
	className,
	replaceURL,
	showProgressBar,
	showDaysUntilRace,
	progressPercent,
	daysUntilRace,
	trainingType,
	scheduleText,
	buttonAction,
	onPlay,
	onLike,
	hasLikeButton,
	favorites,
	hasInfo,
	otherInfo,
	isComplete,
	isProgram,
	isSelected
}) => {
	// Properties
	const containerClass = classNames('mb-3', isFullHeight ? 'h-100' : undefined)
	const hasText = text || title ? true : false
	const curCardType = cardType || 'normal'
	const isNormal = curCardType == 'normal'
	const isSplit = curCardType == 'split'
	// const showPlayButton =
	// 	hasPlayButton || onPlay || (link && onLike) ? true : false
	//Video Data
	const [vimeoData, setVimeoData] = useState<any>()

	const navigate = useNavigate();

	// On Click
	const onClick = (link?: string, e?: any) => {
		doNothingClick(e)
		const childClasses: string[] = e?.target?.classList
			? Array.from(e.target.classList.values())
			: []
		const parentClasses: string[] = e?.target?.parentElement?.classList
			? Array.from(e.target.parentElement.classList.values())
			: []
		const allClasses = [...childClasses, ...parentClasses]
		const isHeartIcon = allClasses.includes('is-heart-icon')
		if (link && !isHeartIcon && !isScrolling) {
			if (replaceURL) {
				const element = document.getElementsByClassName('exercise-page-list')[0]
				if (element?.scrollTop) {
					element.scrollTop = 0
				}
				navigate(link?.replace('/#', ''), { replace: true })
			} else
				if (link.includes('training-plan/view'))
					navigate(link?.replace('/#', ''), { replace: true })
				else
					navReq(link)
		}
	}

	const getVideoDuration = async (videoId: string) => {

		const response = await fetch(
			`https://vimeo.com/api/oembed.json?url=https://player.vimeo.com/video/${videoId}`
		);
		const data = await response.json();
		if (data) {
			setVimeoData({ videoId: videoId, duration: data?.duration })
		}
	}

	useEffect(() => {
		const handleSaveToStorage = async () => await getVideoDuration(videoId as string)

		if (videoId && cardType == "video" && videoId.length > 4)
			handleSaveToStorage()
	}, [videoId, cardType])


	// Render
	return (
		<div className={containerClass}>
			{/* Card */}

			<NavLink to="#" className="card-link" onClick={onClick.bind(null, link)}>
				{isNormal && hasText ? (
					<HomeDetailCard
						title={title}
						text={text as string}
						bgImage={bgImage}
						logoImage={logoImage}
						logoType={logoType}
						showProgressBar={showProgressBar}
						showDaysUntilRace={showDaysUntilRace}
						progressPercent={progressPercent}
						daysUntilRace={daysUntilRace}
						trainingType={trainingType}
						scheduleText={scheduleText}
						hasInfo={hasInfo}
						otherInfo={otherInfo}
					/>
				) : isNormal && !hasText ? (
					<ImageCard
						type={type}
						progress={progress}
						bgImage={bgImage}
						logoImage={logoImage}
						logoIsLg={logoIsLg}
						logoIsMd={logoIsMd}
						playButtonIsCentered={playButtonIsCentered}
						hasPlayButton={hasPlayButton}
						vimeoId={vimeoId}
						onPlay={onPlay}
						onLike={onLike}
						hasLikeButton={hasLikeButton}
						favorites={favorites}
						videoId={videoId}
					/>
				) : isSplit ? (
					<SplitCard
						text={text as string}
						title={title as string}
						bgImage={bgImage as string}
						logoImage={logoImage as string}
						buttonText={buttonText}
						buttonLink={buttonLink}
						buttonDisabled={buttonDisabled}
						isColored={isColored}
						isSmall={isSmall}
						className={className}
						buttonAction={buttonAction}
					>
						{children}
					</SplitCard>
				) : (
					<VideoDetailCard
						videoId={videoId as string}
						text={text || (detailSubtitle as string)}
						title={detailTitle}
						logoImage={logoImage}
						logoType={logoType}
						hasCheck={hasCheck}
						onPlay={onPlay}
						onLike={onLike}
						hasLikeButton={hasLikeButton}
						favorites={favorites}
						className={className}
						hasPlayButton={hasPlayButton}
						isComplete={isComplete}
						isProgram={isProgram}
						duration={Math.round(vimeoData?.duration / 60)}
						isSelected={isSelected}
					/>
				)
				}
			</NavLink>
			{/* </NavLink> */}

			{/* Detail Text */}
			{
				curCardType != 'video' && (
					<VideoDetailText
						hasCheck={hasCheck}
						detailTitle={detailTitle}
						detailSubtitle={detailSubtitle}
						isInline={isInline}
					/>
				)
			}
		</div >
	)
}

export default MainCard
