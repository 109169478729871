import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import RSCircledSymbol from '../../../components/buttons/RSCircledSymbol'
import WithAuth from '../../../components/WithAuth'
import AppMenus, { BodyComponent } from '../../../components/layout/AppMenus'
import { FC, useEffect, useRef, useState } from 'react'
import { Container, Row, Col, Card } from 'react-bootstrap'
import { useLocation, useNavigate } from 'react-router-dom'
import { IRaceType } from './RaceType'
import { RaceOptions } from './RaceOptions'
import { ITrainingPlanStore } from '../../../store/createTrainingPlanStore'
import useStore from '../../../store/useStore'
import { CheckActivePlan } from '../../../modules/trainingPlanActions'
import { ICurrentUser } from '../../../store/createAccountStore'
import GenericModal from '../../../components/misc/GenericModal'
import RSNavCont from '../../../components/layout/RSNavCont'
import { guideText } from '../../../modules/values'
import moment from 'moment'
import raceSelection from './RaceSelection.json'
import { navReq } from '../../../modules/apiConsume'
import Indicator from '../../onboarding/Indicator'
import Progress from './Progress'
import useQueryParams from '../../../hooks/useQueryParams';

/**
 * Step 1 Component
 */

const CONFIRM_BUTTONS = ['Yes, continue', 'No, cancel']

const StepOne: FC = () => {

	const navigate = useNavigate()

	const url = useLocation()

	const { race } = useQueryParams()

	const { setSelectedRace, agreedCreateNewPlan, setAgreedCreateNewPlan, currentPath, setCurrentPath, hasActivePlan, setHasActivePlan, setSelectedRaceDate } = useStore((state: ITrainingPlanStore) => state)
	const { currentUser } = useStore((state: ICurrentUser) => state)
	const [loadingCheck, setLoadingCheck] = useState(true)

	function handleClick(selectedRaceOption: IRaceType) {
		if (!loadingCheck || currentPath === "/training-plan/build/step-2") {
			setSelectedRace(selectedRaceOption)
			navigate(selectedRaceOption?.value === 7 ? '/training-plan/build/step-recover' : '/training-plan/build/step-2', { replace: true })
		}
	}

	const handleCancelButton = () => {
		setAgreedCreateNewPlan(false)
		navigate('/', { replace: true })
	}

	const handleContinueButton = () => {
		setAgreedCreateNewPlan(true)
		setHasActivePlan(false)
	}

	useEffect(() => {
		setLoadingCheck(false)
		setHasActivePlan(false)
		// const abortController = new AbortController();
		// //check if user has active plan
		// if (currentUser?.account_id && !hasActivePlan && !agreedCreateNewPlan && currentPath !== "/training-plan/build/step-2")
		// 	CheckActivePlan(currentUser?.account_id as number).then(res => {
		// 		if (res.data.result && res.status === "ok") {
		// 			setHasActivePlan(res.data.result)
		// 			// setHasActivePlan()
		// 			setLoadingCheck(false)
		// 		}
		// 		else
		// 			setHasActivePlan(res.data.result)
		// 		setLoadingCheck(false)
		// 	})

		// return () => {
		// 	abortController.abort();
		// }

	}, [hasActivePlan, currentUser, agreedCreateNewPlan]);

	useEffect(() => {
		return () => {
			setAgreedCreateNewPlan(false);
			setHasActivePlan(false)
			setCurrentPath('')
		};
	}, []);

	useEffect(() => {
		setSelectedRaceDate(moment(new Date()).format('MM/DD/YYYY'))
	}, []);

	useEffect(() => {
		if (race) {
			setSelectedRace(RaceOptions.find(x => x.title.toLowerCase() === race) as IRaceType)
			navigate('/training-plan/build/step-2', { replace: true })
		}
	}, [race]);

	// Render
	return (
		<RSNavCont>
			<GenericModal
				show={hasActivePlan}
				title="Are you sure?"
				text={guideText.CreateNewWarning}
				setShow={setHasActivePlan}
				keyboard={false}
				backdrop="static"
				button1Action={handleContinueButton}
				button1Text={CONFIRM_BUTTONS[0]}
				button2Action={handleCancelButton}
				button2Text={CONFIRM_BUTTONS[1]}
			/>
			<div className="build-plan pt-5">
				<Container>
					<Progress progress={Number(url.pathname.split('/')[3].split('-')[1])} />
					<div className="topbar-info text-center">
						<h1 className='text-caps'>
							<b>Build a Training Plan</b>
						</h1>
						<div className='text-center'>
							<p className='w-75 mx-auto text-center'>Our plans are flexible, customized, and can be adjusted on the fly.</p>
						</div>
					</div>
					<Grid container xs={12} sx={{ lineHeight: '1.2em', fontFamily: 'Poppins-Light !important', fontSize: '18px' }} justifyContent="flex-start" alignItems="center">
						{raceSelection.map((rs: any, rsI: number) =>
							<Grid container className='race-selection-container' key={rsI + 'rs'} xs={12} sx={{ fontFamily: 'Poppins-Light !important', fontSize: '18px !important', mb: '10px' }} justifyContent="flex-start" alignItems="center">
								<RSCircledSymbol
									symbol={rs.title}
									title={rs.subtitle}
									subtitle={rs.details}
									sx={{ height: '110px' }}
									onClick={() => {
										rs.id == 1 ? navReq('/#/programs?p_id=1') :
											handleClick(RaceOptions.find(x => x.value == rs.raceValue) as IRaceType)
									}}
								/>
							</Grid>
						)}
					</Grid>
				</Container>
			</div>
		</RSNavCont >
	)
}

export default WithAuth(StepOne)
