import ArrowBack from '@mui/icons-material/ArrowBackIos'
import { backNav, navReq } from '../../modules/apiConsume'
import { FC } from 'react'

/**
 * BackButton Component
 */

const BackButton: FC<{
	toPrograms?: boolean
	toHomePage?: boolean
	toSplashPage?: boolean
	toTrainingPage?: boolean
	toProgramSplash?: any
}> = ({
	toPrograms,
	toHomePage,
	toSplashPage,
	toProgramSplash,
	toTrainingPage,
}) => {
	// Render
	return (
		<span
			className="has-pointer d-lg-none"
			onClick={() =>
				toProgramSplash && toProgramSplash[0]
					? navReq('/#/programs?program_id=' + toProgramSplash[1])
					: toPrograms
					? navReq('/#/programs')
					: toHomePage
					? navReq('/#/')
					: toSplashPage
					? navReq('/#/signup?resetApp=1')
					: toTrainingPage
					? navReq('/#/traning-plan')
					: backNav()
			}
		>
			<ArrowBack />
		</span>
	)
}

export default BackButton
