import MainCard from '../../cards/MainCard'
import ScrollRow from '../../layout/ScrollRow'
import HomeSubtitle from '../../text/HomeSubtitle'
import useScrollRow from '../../../hooks/useScrollRow'
import { getVideoTitleAndSubtitle } from '../../../modules/videoUtils'
import { VideoWithProgress } from '../../../hooks/useHomePage'
import { FC } from 'react'
import { navReq } from '../../../modules/apiConsume'
// import allVideos from '../../../modules/videos/allVideos'
// import { IVimeoPlayerStore } from '../../../store/createVimeoPlayerStore'
// import useStore from '../../../store/useStore'

// Video Row Props
export type VideoRowProps = {
	videos: VideoWithProgress[],
	isMobile?: boolean
}

/**
 * ContinueRow Component
 */

const ContinueRow: FC<VideoRowProps> = ({ videos }) => {
	// Hooks
	const { isScrolling, setIsScrolling } = useScrollRow()


	// const { setIsShowControls, setCurrentTitle, setCurrentList, setShowVimeoPlayer, setIsFullScreen, setVimeoId, setIsPlaying: setCustomPlayerPlay } = useStore((state: IVimeoPlayerStore) => state)

	// TO-DO: On Like
	const onLike = () => 0

	// const handleOpenVideo = (videoId: number) => {
	// 	const selectedVideo = allVideos[videoId]

	// 	const title = `${selectedVideo?.category.includes('All') ? selectedVideo?.category.split(' ')[1] : selectedVideo?.category} | ${selectedVideo?.headingTitle}`
	// 	setCurrentTitle(title)
	// 	setVimeoId(selectedVideo.id)
	// 	setCurrentList([])
	// 	setShowVimeoPlayer(true)
	// 	setIsFullScreen(true)
	// 	setIsShowControls(true)
	// 	setCustomPlayerPlay(true)
	// }

	const handleOpenVideo = (url: string) => {
		const program = url.split('/')[3]
		const videoId = url.split('/')[4].split('?')[0]
		navReq(`/#/programs?program=${program}&videoId=
		${videoId}`)
	}

	// Return
	return (
		<>
			<HomeSubtitle isHome>{videos[0]?.type === "suggested" ? 'Suggested Videos' : 'Continue Training'} </HomeSubtitle>
			<ScrollRow isHome setIsScrolling={setIsScrolling}>
				{videos.length == 0 ? (
					<>
						{[1, 2, 3, 4, 5, 6].map((value) => (
							<div key={value} className="card bg-transparent border-0 mt-n3 mx-n3 mr-3 mb-5" aria-hidden="true">
								<div className="card-body">
									<h5 className="card-title placeholder-glow">
										<span className="placeholder placeholder-home-videos"></span>
									</h5>
									<p className="card-text placeholder-glow">
										<span className="placeholder col-6"></span>
									</p>
								</div>
							</div>
						))}
					</>
				) : (
					<>
						{videos?.filter((vf: any)=>!(vf?.url?.includes('peak-on-race-day'))).map((video, index) => {
							const { title, subtitle } = getVideoTitleAndSubtitle(video)
							return (
								<div key={index} onClick={() => {
									video.url.includes('programs') ? handleOpenVideo(video.url) : undefined
								}}>
									<MainCard
										key={index}
										isInline
										type="video"
										bgImage={video.image}
										detailTitle={title}
										detailSubtitle={subtitle}
										hasCheck={video.isComplete}
										progress={video.progress}
										link={video.url.includes('programs') ? undefined : video.url}
										// link={video.url}
										isScrolling={isScrolling}
										onLike={onLike}
									/>
								</div>
							)
						})}
					</>)}
			</ScrollRow>
		</>
	)
}

export default ContinueRow
