import {
    EmailShareButton,
    EmailIcon,
    FacebookShareButton,
    FacebookIcon,
    // GabShareButton,
    // HatenaShareButton,
    // InstapaperShareButton,
    // LineShareButton,
    // LinkedinShareButton,
    // LivejournalShareButton,
    // MailruShareButton,
    // OKShareButton,
    // PinterestShareButton,
    // PocketShareButton,
    // RedditShareButton,
    // TelegramShareButton,
    // TumblrShareButton,
    TwitterShareButton,
    TwitterIcon,
    ViberShareButton,
    ViberIcon,
    // VKShareButton,
    // WhatsappShareButton,
    // WorkplaceShareButton,
} from "react-share"

//import Grid from "@mui/material/Grid"
import Box from "@mui/material/Box"
import RSButton from "../../components/buttons/RSButton"
import RSDrawer from "../../components/layout/RSDrawer"
import { useState } from "react"
import useRNBridge from "../../hooks/useRNBridge"
import useStore from "../../store/useStore"
import { ICurrentUser } from "../../store/createAccountStore"
import { GenerateRefLink } from "../../modules/shareActions"

const ShareInvite = () => {

    const { currentUser } = useStore((state: ICurrentUser) => state)

    //////////////////////////////////////////
    // const shareUrl = window.location.href
    // const shareReferralLink = 'https://runsmartonline.com/programs/'
    const isNativeApp = localStorage.getItem('deviceRequestKey') ? true : false

    const messageHeader1 = "Let's get fit together!"
    const messageHeader2 = 'Runsmart'


    const { sendDataToReactNative } = useRNBridge()

    const [openPopupShare, setOpenPopupShare] = useState(false)
    const [referralLink, setReferralLink] = useState('')
    //////////////////////////////////////////


    const handleShareInvitationLink = () => {

        console.log('>>> sharing invite link')
        GenerateRefLink(currentUser?.account_id as number).then(res => {
            const refLink = res.data.result.referral_link
            console.log('>>> refLink: ', refLink)
            setReferralLink(refLink)
            isNativeApp && handleShareInviteNative(refLink)
            !isNativeApp && setOpenPopupShare(true)
        })

    }

    const handleShareInviteNative = (shareReferralLink: string) => {

        const payload = {
            title: 'Runsmart Invitation',
            // message: 'Why take the stairs when you can take the elevator?',
            message: 'Join me on Runsmart and let\'s get fit together!',
            // replace with user's referral link
            url: shareReferralLink,
        }

        const dataToSend = {
            requestType: 'share-invite-native',
            payload: payload,
            timestamp: new Date().getTime(),
        }
        sendDataToReactNative(dataToSend)
    }


    const renderPopupShare = () => <>
        <RSDrawer bottom maxHeight="70%" open={openPopupShare} onClose={() => setOpenPopupShare(false)}>
            <div style={{ justifyContent: 'center', alignContent: 'center', alignItems: 'center' }}>
                <div style={{ marginTop: '2rem', marginBottom: '1rem', fontSize: '1.2rem', width: 'auto', fontWeight: 'bold', textAlign: 'center' }}>Invite your Run Buddy to get points!</div>
                <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-evenly', marginBottom: '1rem' }}>
                    <EmailShareButton url={referralLink} title={messageHeader1} >
                        <EmailIcon size={40} round={true} />
                    </EmailShareButton>
                    <FacebookShareButton url={referralLink} title={messageHeader1} hashtag={messageHeader2} >
                        <FacebookIcon size={40} round={true} />
                    </FacebookShareButton >
                    <TwitterShareButton url={referralLink} title={messageHeader1} hashtags={[messageHeader2]} >
                        <TwitterIcon size={40} round={true} />
                    </TwitterShareButton>
                    <ViberShareButton url={referralLink} title={messageHeader1} >
                        <ViberIcon size={40} round={true} />
                    </ViberShareButton>
                </div>
                <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignContent: 'center', alignItems: 'center', marginBottom: '1rem' }}>
                    under construction
                    <img src="https://encrypted-tbn3.gstatic.com/licensed-image?q=tbn:ANd9GcTnH30P8_pt24IFQOZ9T6yUMMUTL9zAiGKMJxOODMcpnIu__t_yI6g8KmsQuvpnAWv3xk-h-XaUlae1K8o"
                        alt="share" style={{ width: '20%', height: 'auto' }} />
                </div>


            </div>
        </RSDrawer>
    </>

    return <>
        <RSButton onClick={handleShareInvitationLink} secondary card addBorder>
            <Box sx={{ px: 1, display: 'flex' }}>
                <i className="fa-duotone fa-solid fa-share-nodes duo-tone" style={{ fontSize: '30px' }} />
            </Box>
            <Box sx={{ pr: 1 }}>
                Refer a friend
            </Box>

        </RSButton>

        {renderPopupShare()}

        {/* <Grid container xs={12} sx={{ position: 'relative' }} alignItems='center' justifyContent='flex-start'>
        <Box onClick={handleShareInvitationLink} sx={{ p: 1, zIndex: 1, display: 'flex', position: 'absolute', left: 0, top: '50%', transform: 'translateY(-50%)', borderRadius: '50%', height: '60px', width: '60px', alignItems: 'center', justifyContent: 'center', backgroundColor: 'white', border: '4px solid #9fcd2b' }}>
            <i className="fa-duotone fa-solid fa-share-nodes duo-tone" style={{ fontSize: '30px', marginLeft: '-4px' }} />
        </Box>
        <RSButton onClick={handleShareInvitationLink} sx={{ width: 'calc(100% - 45px)', height: '50px', borderLeft: 'none !important', borderRadius: '0px 5px 5px 0px', ml: '45px', textAlign: 'left', justifyContent: 'center' }}>
         Refer a friend
        </RSButton>

        </Grid> */}
    </>
}


export default ShareInvite
