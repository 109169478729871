import { FC } from 'react'

import RSButton from '../buttons/RSButton'

export type RSCardContProps = {
	className?: string,
	children?: any,
	onClick?: any,
	sx?: object,
	disabled?: boolean
	isLoading?: boolean,
}

const RSCardCont: FC<RSCardContProps> = ({ className = '', children, onClick, sx, disabled, isLoading }) => {

	return <RSButton className={`${className} ${isLoading ? 'loading-days toRight' : ''}`} secondary card onClick={() => !disabled && !isLoading && onClick?.()} sx={{ p: '10px', alignItems: 'flex-start', overflow: 'hidden', ...sx }}>
		{!isLoading && children}
	</RSButton>
}

export default RSCardCont;
