/* eslint-disable @typescript-eslint/no-unused-vars */
import { Addchart, AirlineSeatReclineExtraOutlined, Chair, Check, CheckCircleOutlineOutlined, DirectionsRunRounded, FavoriteBorder, FitnessCenterOutlined } from '@mui/icons-material'
import moment from 'moment'
import { useEffect, useState } from 'react'
import { Button, Card, Placeholder, Spinner } from 'react-bootstrap'
import { UserNotesRecord } from '../../../api/v2/types'
import useTrainingPlanUtils from '../../../hooks/useTrainingPlanUtils'
import { CreateUserNotes, UpdateUserNotes } from '../../../modules/trainingPlanActions'
import { IComponentStore } from '../../../store/createComponentStore'
import { GarminDailyDetail, IGarminStore } from '../../../store/createGarminStore'
import { ITrainingPlanStore } from '../../../store/createTrainingPlanStore'
import useStore from '../../../store/useStore'
import UpdateDistance from './UpdateDistance'
import UpdateHeartRate from './UpdateHeartRate'
import { FaPersonRunning, FaCouch } from 'react-icons/fa6';


const LeftPanelContainer = (props: any) => {
    const { computeKmPace, ConvertM2Mt, ConvertMetersToMiles } = useTrainingPlanUtils();
    const { getDistanceValue, userInfo, userNotes, dayData, getUserNotes, loadingValues, getAllUserNotes, selectedWeek, selectedDay, userGarminDetails, type, activities } = props
    const [distanceValue, setDistanceValue] = useState<any>(undefined)
    const [distanceValueInput, setDistanceValueInput] = useState<any>(undefined)
    const [heartRateValue, setHeartRateValue] = useState<string>("")
    const [isLoading, setIsLoading] = useState(false)
    const [isLoadingDistance, setIsLoadingDistance] = useState(false)
    const [isLoadingWoComplete, setIsLoadingWoComplete] = useState(false)
    const [hasErrorSaving, setHasErrorSaving] = useState(false)
    const [isSuccessSave, setIsSuccessSave] = useState(false)
    const [isCompleted, setIsCompleted] = useState(false)
    const { setToast } = useStore((state: IComponentStore) => state)
    const { garminDailyDetails } = useStore((state: IGarminStore) => state)
    const { currentDayActivity } = useStore((state: ITrainingPlanStore) => state)
    const [currentUserNote, setCurrentUserNote] = useState<UserNotesRecord | undefined>(undefined)
    const [currentGarminDay, setCurrentGarminDay] = useState<GarminDailyDetail | undefined>()

    const handleWoCompleteChange = (checked: boolean) => {
        // const checked = event.target.checked;
        handleSaveUserNotes("wo_complete", checked)
    }

    const handleSaveUserNotes = async (type: string, woComplete?: boolean) => {
        if (currentUserNote) {
            if (type === "distance" && !isLoadingDistance && distanceValueInput && distanceValueInput !== distanceValue && distanceValueInput !== currentUserNote?.distance) {
                setIsLoadingDistance(true)
                const update = await UpdateUserNotes(buildData("distance", "update") as UserNotesRecord)

                if (update.data.messages === "success") {
                    setIsLoadingDistance(false)
                    setIsSuccessSave(true)
                    getUserNotes(dayData)
                    await getAllUserNotes()
                }
                else {
                    setIsLoadingDistance(false)
                    setIsSuccessSave(false)
                }
            }

            else if (type === "heart_rate" && !isLoading && heartRateValue && heartRateValue !== currentUserNote?.heart_rate) {
                setIsLoading(true)
                const update = await UpdateUserNotes(buildData("heart_rate", "update") as UserNotesRecord)

                if (update.data.messages === "success") {
                    setIsLoading(false)
                    setIsSuccessSave(true)
                    getUserNotes(dayData)
                }
                else {
                    setIsLoading(false)
                    setIsSuccessSave(false)
                }
            }

            else if (type === "wo_complete") {
                setIsLoadingWoComplete(true)
                const update = await UpdateUserNotes(buildData("wo_complete", "update", woComplete) as UserNotesRecord)

                if (update.data.messages === "success") {
                    setIsLoadingWoComplete(false)
                    setIsSuccessSave(true)
                    getUserNotes(dayData)
                }
                else {
                    setIsLoadingWoComplete(false)
                    setIsSuccessSave(false)
                }
            }
        }
        else {
            if (type === "distance" && !isLoadingDistance && distanceValueInput) {
                setIsLoadingDistance(true)
                const create = await CreateUserNotes(buildData("distance", "insert") as UserNotesRecord)
                if (create.data.messages === "success") {
                    setIsLoadingDistance(false)
                    setIsSuccessSave(true)
                    setDistanceValueInput(null)
                    getUserNotes(dayData)
                    await getAllUserNotes()

                }
                else {
                    setIsLoadingDistance(false)
                    setIsSuccessSave(false)
                }

            }

            else if (type === "heart_rate" && !isLoading && heartRateValue) {
                setIsLoading(true)
                const create = await CreateUserNotes(buildData("heart_rate", "insert") as UserNotesRecord)
                if (create.data.messages === "success") {
                    setIsLoading(false)
                    setIsSuccessSave(true)
                    getUserNotes(dayData)
                }
                else {
                    setIsLoading(false)
                    setIsSuccessSave(false)
                }

            }

            else if (type === "wo_complete") {
                setIsLoadingWoComplete(true)
                const create = await CreateUserNotes(buildData("wo_complete", "insert", woComplete) as UserNotesRecord)
                if (create.data.messages === "success") {
                    setIsLoadingWoComplete(false)
                    setIsSuccessSave(true)
                    getUserNotes(dayData)
                }
                else {
                    setIsLoadingWoComplete(false)
                    setIsSuccessSave(false)
                }

            }
        }

    }

    const buildData = (type: string, method: string, woComplete?: boolean) => {

        let data: UserNotesRecord;

        if (type === "distance" && method === "insert") {
            data = {
                marid: dayData?.marathon?.id,
                uid: userInfo?.uid,
                comments: "",
                heart_rate: "",
                distance: userInfo?.km === 'Y' ? (+computeKmPace(+distanceValueInput)?.toFixed(1)) : +distanceValueInput,
                pace: null,
                wo_complete: false
            }

            return data
        }
        else if (type === "distance" && method === "update") {
            data = {
                ...currentUserNote as UserNotesRecord,
                distance: userInfo?.km === 'Y' ? (+computeKmPace(+distanceValueInput)?.toFixed(1)) : +distanceValueInput,
                pace: null
            }
            return data
        }

        else if (type === "heart_rate" && method === "insert") {
            data = {
                marid: dayData?.marathon?.id,
                uid: userInfo?.uid,
                comments: "",
                heart_rate: heartRateValue,
                distance: null,
                pace: null,
                wo_complete: false
            }

            return data
        }

        else if (type === "heart_rate" && method === "update") {
            data = {
                ...currentUserNote as UserNotesRecord,
                heart_rate: heartRateValue,
                pace: null
            }

            return data
        }

        else if (type === "wo_complete" && method === "insert") {

            data = {
                marid: dayData?.marathon?.id,
                uid: userInfo?.uid,
                comments: "",
                heart_rate: "",
                distance: null,
                pace: null,
                wo_complete: woComplete as boolean
            }

            return data
        }

        else if (type === "wo_complete" && method === "update") {

            data = {
                ...currentUserNote as UserNotesRecord,
                wo_complete: woComplete as boolean,
                pace: null
            }
            return data
        }
    }

    const saveData = async (completed: boolean) => {
        await handleSaveUserNotes('heart_rate')
        await handleSaveUserNotes('distance')
        await handleSaveUserNotes('wo_complete', completed)
    }

    const showEditButtons = () => {
        return type === "runsmart" && !userGarminDetails || type === "runsmart" && userGarminDetails && (!activities || activities && activities?.length == 0) ? true : false
    }

    useEffect(() => {
        setToast({
            show: hasErrorSaving,
            title: "Error Message",
            message: "An error occurred while saving the data",
            variant: "danger",
            onClose: () => setHasErrorSaving(false),
            duration: 5000
        })
    }, [hasErrorSaving])

    useEffect(() => {
        // setSelectedRaceDate(new Date())
        setToast({
            show: isSuccessSave,
            title: "Save data",
            message: "Data has been successfully saved",
            variant: "success",
            onClose: () => {
                setIsSuccessSave(false)
            },
            duration: 3000,
        })
    }, [isSuccessSave])

    useEffect(() => {
        const abortController = new AbortController();
        setDistanceValueInput(null)
        const userNote = userNotes?.find((x: any) => x.marid == dayData?.marathon?.id && x.uid == userInfo?.uid)
        if (userNote && (!userGarminDetails || userGarminDetails && userGarminDetails?.status == 0)) {
            setCurrentUserNote(userNote)
            // setHeartRateValue(userNote?.heart_rate)
            setIsCompleted(userNote?.wo_complete)
            if (userNote?.heart_rate === "")
                setHeartRateValue("0")
            else
                setHeartRateValue(userNote?.heart_rate)

            if (userNote?.distance == null)
                setDistanceValue(getDistanceValue() ? getDistanceValue()?.toFixed(1) : null)
            else
                setDistanceValue(userInfo?.km === 'Y' ? (+ConvertM2Mt(+userNote.distance) / 1000)?.toFixed(1)?.replace(/(\.0+|0+)$/, '') : userNote.distance?.toFixed(1)?.replace(/(\.0+|0+)$/, ''))

        }
        else if (userNote && (userGarminDetails && userGarminDetails?.status == 1)) {
            // console.log(getDistanceValue())

            setCurrentUserNote(userNote)
            let totalDistanceInMeters = 0
            let heartRate = 0
            //set distance
            if (!userNote?.distance) {
                // console.log(currentGarminDay, currentGarminDay?.activities.length)
                if (currentGarminDay && currentGarminDay.activities.length > 0 && type === "garmin-connect") {
                    // console.log(currentGarminDay.activities)
                    for (const summary of currentGarminDay.activities.filter(x => x.activityType.includes("RUNNING"))) {
                        heartRate += summary.averageHeartRateInBeatsPerMinute
                        totalDistanceInMeters += summary?.distanceInMeters ? summary?.distanceInMeters as number : 0
                        const garminDistance = userInfo?.km === 'Y' ? Number(summary?.distanceInMeters as number / 1000)?.toFixed(1) : ConvertMetersToMiles(totalDistanceInMeters)?.toFixed(1)
                        setDistanceValue(garminDistance)
                    }
                }
                else {
                    setDistanceValue(getDistanceValue() ?
                        userInfo?.km === 'Y' ? getDistanceValue()
                            : getDistanceValue()?.toFixed(1)?.replace(/(\.0+|0+)$/, '')
                        : null)
                }


            }
            else
                setDistanceValue(userInfo?.km === 'Y' ? (+ConvertM2Mt(+userNote.distance) / 1000)?.toFixed(1)?.replace(/(\.0+|0+)$/, '') : userNote.distance?.toFixed(1)?.replace(/(\.0+|0+)$/, ''))


            //set heart rate
            if (userNote?.heart_rate === "" || !userNote?.heart_rate || userNote?.heart_rate === "0") {
                if (currentGarminDay && currentGarminDay.activities.length > 0) {
                    const hpm = heartRate > 0 ? String(Math.floor(heartRate / currentGarminDay.activities.filter(x => x.activityType.includes("RUNNING")).length)) : heartRate == 0 ? "0" : "0"
                    setHeartRateValue(hpm)
                } else
                    setHeartRateValue("0")

            }
            else
                setHeartRateValue(userNote?.heart_rate)


            //workout complete
            if (userNote?.wo_complete == 0 || !userNote?.wo_complete) {
                if (currentGarminDay && currentGarminDay.activities.length > 0) {
                    const completed = currentGarminDay.activities.length > 0 ? true : false
                    setIsCompleted(completed)
                }
                else
                    setIsCompleted(false)
            }
            else
                setIsCompleted(userNote?.wo_complete)

        }
        else if (userGarminDetails && dayData && !userNote && (currentGarminDay && currentGarminDay?.activities.length > 0) && type === "garmin-connect") {
            //SET HEARTRATE, COMPLETED AND DISTANCE FROM GARMIN
            let heartRate = 0;
            let totalDistanceInMeters = 0
            let completed = false;

            for (const summary of currentGarminDay.activities.filter(x => x.activityType.includes("RUNNING"))) {
                heartRate += summary.averageHeartRateInBeatsPerMinute
                totalDistanceInMeters += summary?.distanceInMeters ? summary?.distanceInMeters as number : 0
            }

            const garminDistance = userInfo?.km === 'Y' ? Number(totalDistanceInMeters / 1000)?.toFixed(1) : ConvertMetersToMiles(totalDistanceInMeters)?.toFixed(1)

            const hpm = heartRate ? String(Math.floor(heartRate / currentGarminDay.activities.filter(x => x.activityType.includes("RUNNING")).length)) : heartRate == 0 ? "0" : "0"
            setHeartRateValue(hpm)

            completed = currentGarminDay.activities.length > 0 ? true : false
            setDistanceValue(garminDistance)
            setIsCompleted(completed)
            // saveData(completed)
        }
        else {
            setIsCompleted(false)
            setDistanceValue(getDistanceValue() ?
                userInfo?.km === 'Y' ? getDistanceValue()
                    : getDistanceValue()?.toFixed(1)?.replace(/(\.0+|0+)$/, '')
                : null)
            setHeartRateValue("0")
            setCurrentUserNote(undefined)
        }
        return () => {
            abortController.abort();
        }

    }, [dayData, userNotes, currentGarminDay, type, selectedDay])


    useEffect(() => {
        userInfo?.km == 'Y' && distanceValue == 0 && setDistanceValue(null)
    }, [distanceValue])

    useEffect(() => {
        if (userGarminDetails && userGarminDetails.status == 1 && (activities && activities.length > 0)) {
            const selectedDate = moment(selectedWeek?.week).add(selectedDay, 'days').format('YYYY-MM-DD')
            setCurrentGarminDay({
                date: selectedDate,
                activities: activities
            })
        }
    }, [activities, userGarminDetails, garminDailyDetails, selectedDay, selectedWeek, type])

    return (
        <div className="mx-n4 mx-md-0">
            {/* {loadingValues ? (
                <Card className='px-3 mt-5' style={{ width: '100%', height: 65 }}>
                    <Placeholder style={{ marginTop: '15px' }} as="p" animation="glow">
                        <Placeholder style={{ borderRadius: '50%', width: 30, height: 30, marginRight: 2 }} />
                        <Placeholder style={{ borderRadius: 5, width: '40%', height: 20, marginLeft: 4 }} />
                        <Placeholder style={{ borderRadius: 5, width: '20%', marginTop: '5px', height: 20, float: 'right' }} />
                    </Placeholder>
                </Card>
            ) : ( */}
            <div>
                <div className='d-flex-row flex-space-even mx-md-3 mt-2'>
                    <h4 className='mt-3 mb-n2'>{`Today's Activity`}</h4>
                    {type === "runsmart" && (!currentGarminDay || currentGarminDay && currentGarminDay.activities.length == 0) && currentDayActivity !== "REST" && !loadingValues ? (

                        <Button className={`${isCompleted ? 'primary-btn-completed' : 'primary-btn-incomplete'} mb-n2`} disabled={isLoadingWoComplete} onClick={() => handleWoCompleteChange(!isCompleted)} >
                            {
                                isLoadingWoComplete ? (<Spinner className={`mx-n1 mb-0 ${isCompleted ? 'mb-md-2' : 'me-md-0'}`} animation='border' size='sm' />) : undefined
                            }
                            {
                                isCompleted ? (
                                    <Check className='mx-n2 mb-3 mt-md-n1 mb-md-1' fontSize='small' />
                                ) : <>
                                    {/* {!isLoadingWoComplete ? (<Check className='mt-n1 me-1' fontSize='small' />) : undefined} */}
                                    <span className={`${isLoadingWoComplete ? 'ps-2' : ''}`}>Mark As Complete</span>
                                </>
                            }
                        </Button>


                    ) : undefined
                    }

                </div>
                {distanceValue && distanceValue > 0 && distanceValue < 1000 || distanceValue == 0 && currentDayActivity !== "CROSS TRAIN" ? (
                    <>
                        {distanceValue == 0 && currentDayActivity == "REST" ? (
                            <>
                                <Card className='d-flex-row px-3 mt-3' style={{ width: '100%', height: 65 }}>
                                    <FaCouch size={'30'} />
                                    <h4 className="my-0 ms-2 me-1 font-weight-bold">
                                        Rest Day
                                    </h4>
                                    {showEditButtons() ? (
                                        <UpdateDistance
                                            isSuccessSave={isSuccessSave}
                                            isLoadingDistance={isLoadingDistance}
                                            handleSaveUserNotes={handleSaveUserNotes}
                                            userInfo={userInfo}
                                            distanceValue={distanceValue}
                                            setDistanceValueInput={setDistanceValueInput} />
                                    ) : undefined}
                                </Card>
                            </>
                        ) :
                            <div className='mx-md-3 mt-3'>
                                <Card className='d-flex-row flex-space-even px-3' style={{ width: '100%', height: 65 }}>
                                    <div className='d-flex-row'>
                                        {/* <img style={{ width: '30px', height: '30px' }} alt='runner' src='./images/icons/runner.svg' /> */}
                                        <FaPersonRunning size={'30'} />

                                        <h3 className="font-20 my-0 mx-2">
                                            {userInfo?.km == 'Y' ? distanceValue + ' Kms' : distanceValue + ' Miles'}
                                        </h3>
                                        {showEditButtons() ? (
                                            <UpdateDistance
                                                isSuccessSave={isSuccessSave}
                                                isLoadingDistance={isLoadingDistance}
                                                handleSaveUserNotes={handleSaveUserNotes}
                                                userInfo={userInfo}
                                                distanceValue={distanceValue}
                                                setDistanceValueInput={setDistanceValueInput} />
                                        ) : undefined}
                                    </div>
                                    <div className='d-flex-row'>
                                        <FavoriteBorder fontSize='large' />
                                        <h3 className="font-20 my-0 ms-0 me-1">
                                            {+heartRateValue == 0 ? undefined : heartRateValue}
                                        </h3>
                                        {showEditButtons() ? (
                                            <UpdateHeartRate
                                                isSuccessSave={isSuccessSave}
                                                isLoading={isLoading}
                                                heartRateValue={heartRateValue}
                                                setHeartRateValue={setHeartRateValue}
                                                handleSaveUserNotes={handleSaveUserNotes}
                                            />) : undefined}
                                    </div>

                                </Card>
                            </div>
                        }
                    </>) : (!distanceValue || distanceValue == 0) && currentDayActivity === "CROSS TRAIN" ?
                    (
                        <div className='mx-md-3 mt-3'>
                            <Card className='d-flex-row flex-space-even px-2' style={{ width: '100%', height: 65 }}>
                                <div className='d-flex-row'>
                                    <FitnessCenterOutlined />
                                    <h4 className="my-0 mx-1 ms-2">
                                        Cross Train
                                    </h4>
                                    {showEditButtons() ? (
                                        <UpdateDistance
                                            isSuccessSave={isSuccessSave}
                                            isLoadingDistance={isLoadingDistance}
                                            handleSaveUserNotes={handleSaveUserNotes}
                                            userInfo={userInfo}
                                            distanceValue={distanceValue}
                                            setDistanceValueInput={setDistanceValueInput} />
                                    ) : undefined}
                                </div>
                                <div className='d-flex-row'>
                                    <FavoriteBorder fontSize='large' />
                                    <h3 className="font-20 my-0 ms-0">
                                        {+heartRateValue == 0 ? undefined : heartRateValue}
                                    </h3>
                                    {showEditButtons() ? (
                                        <UpdateHeartRate
                                            isSuccessSave={isSuccessSave}
                                            isLoading={isLoading}
                                            heartRateValue={heartRateValue}
                                            setHeartRateValue={setHeartRateValue}
                                            handleSaveUserNotes={handleSaveUserNotes}
                                        />) : undefined}
                                </div>

                            </Card>
                        </div>
                    ) : (!distanceValue || distanceValue == 0) && currentDayActivity === "REST" && !loadingValues ? (
                        <Card className='d-flex-row px-3 mt-3 mb-4 mt-md-4 mx-md-3' style={{ width: '100%', height: 65 }}>
                            <FaCouch size={'30'} />
                            <h4 className="my-0 mx-3 font-weight-bold">
                                Rest Day
                            </h4>
                        </Card>
                    ) : !loadingValues ? (
                        <div className='mx-md-3 mt-3'>
                            <Card className='d-flex-row flex-space-even px-2' style={{ width: '100%', height: 65 }}>
                                <div className='d-flex-row'>
                                    <Addchart />
                                    <h5 className="font-15 my-0 mx-2 text-center">
                                        See Laps <br /> {userInfo?.km == 'Y' ? '(Kms)' : '(Miles)'}
                                    </h5>
                                    {showEditButtons() ? (
                                        <UpdateDistance
                                            isSuccessSave={isSuccessSave}
                                            isLoadingDistance={isLoadingDistance}
                                            handleSaveUserNotes={handleSaveUserNotes}
                                            userInfo={userInfo}
                                            distanceValue={distanceValue}
                                            setDistanceValueInput={setDistanceValueInput} />
                                    ) : undefined}
                                </div>
                                <div className='d-flex-row'>
                                    <FavoriteBorder fontSize='large' />
                                    <h3 className="font-20 my-0 ms-0">
                                        {+heartRateValue == 0 ? undefined : heartRateValue}
                                    </h3>
                                    {showEditButtons() ? (
                                        <UpdateHeartRate
                                            isSuccessSave={isSuccessSave}
                                            isLoading={isLoading}
                                            heartRateValue={heartRateValue}
                                            setHeartRateValue={setHeartRateValue}
                                            handleSaveUserNotes={handleSaveUserNotes}
                                        />) : undefined}
                                </div>
                            </Card>
                        </div>
                    ) : undefined
                }
            </div>
            {/* )
            } */}
        </div >
    )
}

export default LeftPanelContainer