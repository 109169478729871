// import CheckCircle from '@mui/icons-material/CheckCircle'
import classNames from 'classnames'
import MultiLineText from '../text/MultiLineText'
import { FC } from 'react'

// Props
export type VideoDetailTextProps = {
	detailTitle?: string
	detailSubtitle?: string
	isInline?: boolean
	hasCheck?: boolean
	isComplete?: boolean
	isProgram?: boolean
}

/**
 * VideoDetailText Component
 */

const VideoDetailText: FC<VideoDetailTextProps> = ({
	hasCheck,
	isInline,
	detailTitle,
	detailSubtitle,
	isComplete,
	isProgram
}) => {
	const hasDetailTitle = detailTitle ? true : false
	const hasDetailSubtitle = detailSubtitle ? true : false
	const titleText = hasDetailTitle && hasDetailSubtitle && isInline ? `${detailTitle} | ` : detailTitle
	const divClassName = classNames(
		'mt-2',
		isInline ? 'd-inline-flex vertical-center-container' : undefined
	)
	const titleClassName = isInline || !hasDetailSubtitle ? 'mb-0' : 'mb-2 card-title-line'

	// Render
	return (
		<>
			<div>
				{(hasDetailTitle || hasDetailSubtitle) && (
					<div className={divClassName} style={{ height: 'unset' }}>
						<div className="vertical-center-container">

							{hasDetailTitle && isInline ? (
								<span className="no-line-height d-flex">
									<img
										style={{ width: 'auto', marginTop: '-2px' }}
										src={`${isComplete ? './images/icons/video-card-check-mark_watched.svg' : './images/icons/video-card-check-mark_unwatched.svg'}`}
										alt="Like"
									/>
									<h6 className={titleClassName + ' ms-1'}>{titleText}</h6>

								</span>
							) : (
								<h6 className={titleClassName}>{titleText}</h6>
							)}
							{/* {

								<h6 className={titleClassName}>{titleText}</h6>
							} */}
						</div>
						{hasDetailSubtitle && (
							<div className={`${isProgram ? 'text-secondary' : 'text-dark program-video-container'} no-line-height vertical-center-container`}>
								<span className={`flex-content`}>
									{isInline ? (
										<div className='ms-1'>
											<p>{detailSubtitle}</p>
										</div>
									) : (
										<div className='mt-1'>
											<h6 style={{ marginLeft: '1px' }} className='d-block d-md-none'>
												<MultiLineText isSmall text={detailSubtitle as string} />
											</h6>

											<h6 style={{ marginLeft: '4px' }} className='d-none d-md-block'>
												<MultiLineText isSmall text={detailSubtitle as string} />
											</h6>
										</div>
									)}
								</span>

								{hasCheck && !isInline && (
									<span style={{ position: 'absolute', bottom: 10, right: 10 }}>
										<>
											<img
												style={{ width: 'auto', opacity: `${isComplete ? 1 : 0}` }}
												src={`${isComplete ? './images/icons/video-card-check-mark_watched.svg' : './images/icons/video-card-check-mark_unwatched.svg'}`}
												alt="Like"
											/>
										</>
										{/* <CheckCircle style={{ opacity: isComplete ? 1 : 0.3, fontSize: '1.3rem' }} className="mt-n1" /> */}
									</span>
								)}
							</div>
						)}
					</div>
				)
				}
			</div>
		</>
	)
}

export default VideoDetailText
