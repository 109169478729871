/* eslint-disable @typescript-eslint/no-unused-vars */
import { useCheckoutPricing, useRecurly } from '@recurly/react-recurly';
import React, { FC, useEffect, useState } from 'react'
import AppleIcon from '@mui/icons-material/Apple';
import { PrimaryButton } from './AuthSubmitButtons';
import { createPurchase, createSubscription, destroySession, getAllAccountInfo, getSubscriptionById, getSubscriptionInfo, getSubscriptionInfoWithError, logIn, updateBillingInfo } from '../../modules/accountActions';
import { navReq } from '../../modules/apiConsume'
import { titleCase } from '../../modules/miscUtils'
import useStore from '../../store/useStore';
import { IComponentStore } from '../../store/createComponentStore';
import useAppStorage from '../../hooks/useAppStorage';
import GoogleIcon from '@mui/icons-material/Google';
import { Spinner } from 'react-bootstrap';
// import recurlyJs from '../../scripts/recurly'


interface IGooglePayButton {
    planCode: string,
    tranxType: 'renew' | 'update' | 'create' | 'reactivate' | "update-renew" | "create-signup",
    afterBillingProcess?: () => any
    afterBillingChange?: () => any
    handleContinue?: () => any
    isGoogleSignup?: boolean
    setLoadingGooglePay?: (bool: boolean) => any
    loadingGooglePay?: boolean,
    buttonStyle?: object,
    className?: string
    // setIsApplePaySupport?: any
}

const GooglePayButton: FC<IGooglePayButton> = ({ planCode = 'runsmart-monthly', tranxType, afterBillingChange, afterBillingProcess, handleContinue, isGoogleSignup = false, setLoadingGooglePay, loadingGooglePay, buttonStyle = {}, className = '' }) => {
    const recurly = useRecurly()
    const [textError, setTextError] = useState<any | undefined>()
    const [showPrompt, setShowPrompt] = useState(false)
    const [hasError, setHasError] = useState(false)
    const [message, setMessage] = useState("")
    const [currentPlanCode, setCurrentPlanCode] = useState(planCode)
    const [variant, setVariant] = useState("success")
    const [title, setTitle] = useState("")
    const [googlePayIsReady, setGooglePayIsReady] = useState(false)
    const [showButton, setShowButton] = useState(false)

    const { setIsLoading, setIsSuccess, setToast } = useStore((state: IComponentStore) => state)

    const checkoutPricing = recurly.Pricing.Checkout();
    const subscription = recurly.Pricing.Subscription();

    const [googleButton, setGoogleButton] = useState<any | undefined>()


    const getPlanLabelByCode = (planCode: string, hasTrial: boolean) => {
        const trialText = hasTrial ? " (14-Day free trial)" : ""
        return titleCase(planCode) + trialText;
    }

    // Hooks
    useAppStorage()

    const hasPlanTrial = (planCode: string) => {
        return planCode === "runsmart-annual" || planCode === "runsmart-monthly" || planCode === "runsmart-annual-discount" ? true : false
    }

    checkoutPricing
        .currency("USD")
        .subscription(subscription.plan(planCode, { quantity: 1 }).currency("USD").done())
        .catch((err) => { alert(err) })
        .done()



    // const handleApplePayClick = googlePay.on('ready', function () {
    //     googlePay.emit();
    // });

    const errorMsg = () => {
        setTitle("Error: Create Subscription")
        setMessage(`Could not ${tranxType === "update" ? 'update' : 'create'
            } subscription.`)
        setVariant("danger")
        setHasError(true)
        setShowPrompt(true)
    }
    const successMessage = (amount: any, nextDate: string) => {
        setTitle("Success: Create Subscription")
        setMessage(`Your subscription has been ${tranxType === "update" ? 'updated' : 'created'
            }. You will be charged $${amount} on ${nextDate}.`)
        setVariant("success")
        setHasError(false)
        setShowPrompt(true)
    }


    const processCreateSubscription = async (token: any) => {
        try {
            const billingResult = await updateBillingInfo(token.id)
            if (billingResult?.data?.id) {
                // After Billing Update
                if (afterBillingChange) await afterBillingChange()
                const { isActive, subscription } = await getSubscriptionInfo()
                if (isActive && subscription?.id) {
                    setIsLoading(false)
                    setIsSuccess(true)
                    setTimeout(() => {
                        setIsSuccess(false)
                        setTimeout(async () => {
                            if (tranxType === "renew")
                                navReq("/#/")
                            if (afterBillingProcess) await afterBillingProcess()
                        }, 500)
                    }, 3000)
                }
                else {
                    setIsLoading(false)
                    errorMsg()
                }
            }
            else {
                setIsLoading(false)
                return errorMsg()
            }
        }
        catch (err) {
            setIsLoading(false)
            return errorMsg()
        }
    }


    const processUpdateBilling = async (token: any) => {
        try {
            const billingResult = await updateBillingInfo(token.id)
            if (billingResult?.data?.id && billingResult?.data?.valid) {
                const { isActive, subscription } = await getSubscriptionInfo()
                if (isActive && subscription?.id) {
                    setIsLoading(false)
                    setIsSuccess(true)
                    setTimeout(() => {
                        setIsSuccess(false)
                        setTimeout(async () => {
                            navReq("/#/account")
                        }, 500)
                    }, 3000)
                }
            } else {
                setHasError(true)
                setIsLoading(false)
            }
        }
        catch (err) {
            setHasError(true)
            setIsLoading(false)
        }

    }
    useEffect(() => {
        setToast({
            show: showPrompt,
            title: title,
            message: message,
            variant: variant,
            onClose: () => {
                setShowPrompt(false)
            },
            duration: 3000
        })
    }, [showPrompt])

    useEffect(() => {
        if (setLoadingGooglePay)
            setLoadingGooglePay(true)

        const googlePay = recurly.GooglePay({
            googleMerchantId: "BCR2DN4TQDWKVHYS",
            requireBillingAddress: true,
            gatewayCode: "qn5is67fpxoh",
            country: "US",
            currency: "USD",
            total: hasPlanTrial(planCode) ? '0.00' : planCode === "runsmart-annual-flash" ? '99.00' : '0.00',
            buttonOptions: {
                buttonType: 'subscribe',
                ...buttonStyle
            }
        })

        googlePay.on('error', function (err: any) {
            // console.log(err)
            setTextError(err)
            setLoadingGooglePay ? setLoadingGooglePay(false) : undefined
            // setShowButton(false)
        });

        googlePay.on('token', async function (token: any) {
            setIsLoading(true)
            try {
                if (!token) {
                    setIsLoading(false)
                    return errorMsg()
                } else {
                    if (tranxType === "create" || tranxType === "renew" || tranxType === "create-signup")
                        await processCreateSubscription(token)
                    else if (tranxType === "update")
                        await processUpdateBilling(token)
                }
            } catch (err) {
                setIsLoading(false)
                return errorMsg()
            }
        });

        googlePay.on('ready', function (googlePayButton) {
            if (setLoadingGooglePay)
                setLoadingGooglePay(false)
            const el = document.getElementById("google-pay-button-container");

            if (el && el.children.length > 0) {
                el?.removeChild(el?.children[0])
            }

            if (el) {
                el?.appendChild(googlePayButton);
                const gpayBtn = document.getElementsByClassName("gpay-button")
                gpayBtn.item(0)?.classList.add("choose-plan-btn")
            }
            // setShowButton(true)
        });

        return () => {
            const el = document.getElementById("google-pay-button-container");
            el && el.children.length > 0 ? el?.removeChild(el?.children[0]) : undefined
        }
    }, [planCode])

    return (
        <>
            {loadingGooglePay ? (<Spinner className='mt-3' animation='border' />) : undefined}
            <div id="google-pay-button-container" className={className} />
        </>

    )
}

export default GooglePayButton
