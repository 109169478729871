/* eslint-disable @typescript-eslint/no-unused-vars */
import InstabotHide from '../../components/styles/InstabotHide'
import WithAuth from '../../components/WithAuth'
import AppMenus from '../../components/layout/AppMenus'
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Billing from '../../components/page-specific/account/Billing'
// import AccountSubmitButtons from '../../components/buttons/AuthSubmitButtons'
import ChooseYourPlanForm from '../../components/forms/ChooseYourPlanForm'
import useQueryParams from '../../hooks/useQueryParams'
import GenericAlert from '../../components/misc/GenericAlert'
import useAlert from '../../hooks/useAlert'
import Badge from 'react-bootstrap/Badge'
import { DEFAULT_COSTS } from '../../modules/values'
import { AuthPageProps } from '../../modules/types'
import { RECURLY_PUBLIC_KEY } from '../../modules/cliEnvValues'
import { getPlanCosts, createSubscription } from '../../modules/accountActions'
import { Elements, RecurlyProvider } from '@recurly/react-recurly'
import { FC, useState, useEffect } from 'react'
import { navReq } from '../../modules/apiConsume'
import ReactPixel from 'react-facebook-pixel';
import ApplePayButton from '../../components/buttons/ApplePayButton'
// import ApplePayButton from '../../components/buttons/ApplePayButton'


const HEADER_1 = `
When your free trial ends, your account will be
charged for the selected plan.You can change
or cancel anytime.
`

// Header Tagline
// const HEADER_TAGLINE = `
// You can change or cancel your plan at any time.
// `

// Header Billing
const HEADER_BILLING = `
You're two steps away from fast & injury-free running.
You may cancel anytime during your free trial to avoid charges.
`

// Default Header
// const DEFAULT_HEADER = `Choose a plan below to get started.`

/**
 * ChoosePlan Component
 */

const ApplePay: FC<AuthPageProps> = ({ userInfo }) => {
    // Properties
    const [monthly, setMonthly] = useState(true)
    const [showBilling, setShowBilling] = useState(false)
    const [title, setTitle] = useState('Choose Your Plan')
    const [costs, setCosts] = useState({ ...DEFAULT_COSTS })
    const [planCode, setPlanCode] = useState<string>("")
    // const [isApplePaySupport, setIsApplePaySupport] = useState<boolean>(false)

    // const loginType = localStorage.getItem("rs_login_type") || "email";

    // Hooks
    const {
        showAlert,
        onAlertOpen,
        onAlertClose,
        alertTitle,
        alertMessage,
        setAlertMessage,
        alertVariant,
        setAlertVariant,
    } = useAlert()

    // Params
    const { offer, skip_info } = useQueryParams()
    const hasOffer = offer ? true : false
    const hasSale = Number(offer) === 2 ? true : false
    const skipInfo = skip_info ? true : false

    // Set Plan Costs
    const setPlanCosts = async () => {
        const newCosts = await getPlanCosts({
            hasOffer,
            isRenew: hasSale,
            isYearlyRenewOnly: hasSale,
            hasSale,
        })
        setCosts(newCosts)
    }

    // On Choose Plan Submit
    const onChoosePlanSubmit = () => {
        // ReactPixel.trackCustom('InitiateCheckout', { k: new Date().getTime() });
        setShowBilling(true)
        setTitle('Billing')
    }

    // After Billing Change
    const afterBillingChange = async () => {
        // ReactPixel.trackCustom('AddPaymentInfo', { k: new Date().getTime() });
        const planCode = monthly ? costs.monthlyCode : costs.annualCode
        await createSubscription(planCode)
    }

    // After Billing Process
    const afterBillingProcess = () => {
        // ReactPixel.trackCustom('AddPaymentInfo', { k: new Date().getTime() });
        if (!skipInfo) navReq('/#/user-info')
    }


    // Mount Effect
    useEffect(() => {
        setPlanCosts()
        // ReactPixel.trackCustom('Lead', null);
    }, [])

    // console.log(isApplePaySupport)
    useEffect(() => {
        let planCode;
        planCode = monthly ? costs.monthlyCode : costs.annualCode;
        planCode = planCode === "monthly" ? "runsmart-monthly" : planCode;
        setPlanCode(planCode)
    }, [monthly])

    // Render
    return (
        <>
            {/* Styles */}
            <InstabotHide />

            {/* Alerts */}
            <GenericAlert
                show={showAlert}
                title={alertTitle}
                message={alertMessage}
                variant={alertVariant}
                onClose={onAlertClose}
                mustClose
            />

            {/* Page */}
            <AppMenus hideOptions hideBackButton showLogout>

                <Container fluid="md" className="px-form-5 signup-container">
                    <div className="user-info-page--wrapper">
                        <Row className="mb-3 user-step--title  d-block text-cetner d-md-none">
                            <Col className="text-center">
                                <Badge
                                    as="h5"
                                    style={{ fontSize: '1rem' }}
                                    bg="black"
                                    className="rounded-pill--signup bg-black"
                                >
                                    {showBilling ? 'Step 3 of 4' : 'Step 2 of 4'}
                                </Badge>
                            </Col>
                        </Row>

                        {/* Title */}
                        <Row className="mb-2 user-login--title">
                            <Col>
                                <h3>{title}</h3>
                            </Col>
                        </Row>

                        {/* Switch Page Link */}
                        <Row className="mb-3 user-step--title">
                            <Col>
                                <Badge
                                    as="h5"
                                    style={{ fontSize: '1rem' }}
                                    bg="dark"
                                    className="rounded-pill--signup bg-black"
                                >
                                    {showBilling ? 'Step 3 of 4' : 'Step 2 of 4'}
                                </Badge>
                            </Col>
                        </Row>

                        {/* Signup Header */}
                        <Row className="m-auto">
                            <Col className="m-auto">
                                <p className="choose-plan--text">
                                    {showBilling ? (
                                        HEADER_BILLING
                                    ) : (
                                        <>
                                            {HEADER_1}
                                            {/* <br />
											<br />
											<strong>{HEADER_TAGLINE}</strong> */}
                                        </>
                                    )}
                                </p>
                            </Col>
                        </Row>
                    </div>
                    <div className="account-form m-auto">
                        {/* Choose Plan Form */}
                        {!showBilling ? (
                            <>
                                <ChooseYourPlanForm
                                    hasSale={hasSale}
                                    setMonthly={setMonthly}
                                    monthCost={costs.monthly}
                                    yearCost={costs.annual}
                                />

                                {/* <AccountSubmitButtons
                                    primaryText="Continue"
                                    primaryAction={onChoosePlanSubmit}
                                /> */}

                                {planCode ? (
                                    <ApplePayButton planCode={planCode} tranxType="create" />
                                ) : undefined}
                            </>
                        ) : (
                            <RecurlyProvider publicKey={RECURLY_PUBLIC_KEY}>
                                <Elements>
                                    <Billing
                                        userInfo={userInfo}
                                        hideDetails
                                        onAlertOpen={onAlertOpen}
                                        setAlertMessage={setAlertMessage}
                                        setAlertVariant={setAlertVariant}
                                        afterBillingChange={afterBillingChange}
                                        afterBillingProcess={afterBillingProcess}
                                        isAccount={false}
                                    />
                                </Elements>
                            </RecurlyProvider>
                        )}
                    </div>
                </Container>
            </AppMenus>
        </>
    )
}

export default WithAuth(ApplePay, true)
