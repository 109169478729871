import MainCard from '../../cards/MainCard'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import HomeSubtitle from '../../text/HomeSubtitle'
import useVideoProgress from '../../../hooks/useVideoProgress'
import { AuthPageProps } from '../../../modules/types'
import { getVideoTitleAndSubtitle } from '../../../modules/videoUtils'
import { Video } from '../../../modules/videos/allVideos'
import { FC, Fragment } from 'react'
// import useFavorites from '../../../pages/favorites/useFavorites'

// WorkoutVideos Props
export type WorkoutVideosProps = {
	videos: Video[]
	hideWeeks?: boolean
	userInfo?: AuthPageProps['userInfo']
	favorites?: any
	onLike?: (videoId: any) => any,
	className?: string
}

/**
 * WorkoutVideos Component
 */

const WorkoutVideos: FC<WorkoutVideosProps> = ({
	videos,
	hideWeeks,
	userInfo,
	favorites,
	onLike,
	className
}) => {
	// Hooks
	const info = useVideoProgress({ videos, userInfo })

	// const { toggleFavorite } = useFavorites(userInfo?.account_id as number, undefined, "workouts", false)

	// Split into Pairs
	const pairs: Video[][] = []
	for (const index in videos) {
		if (Number(index) % 2 == 0) {
			pairs.push([])
		}
		pairs[pairs.length - 1].push(videos[index])
	}

	// TO-DO: On Like
	// const onLike = async (videoId: string) => {
	// 	const isLiked = favorites && favorites.find((x: any) => x.id === videoId) ? true : false;
	// 	await toggleFavorite(userInfo?.account_id as number, Number(videoId), !isLiked)
	// }

	// Render
	return (
		<>
			{pairs.map((pair, weekIndex) => {
				return (
					<Row key={`w-${weekIndex}`} className="justify-content-center">
						<Col className="justify-content-center">
							{!hideWeeks && (
								<HomeSubtitle>{`Week ${weekIndex + 1}`}</HomeSubtitle>
							)}
							<div className="d-block d-md-flex justify-content-center">
								{pair.map((video, dayIndex) => {
									const videoIndex = weekIndex * 2 + dayIndex
									const { isComplete, progress } = info?.[videoIndex] || {}
									const { title, subtitle } = getVideoTitleAndSubtitle(video)
									return (
										<Fragment key={`d-${dayIndex}`}>
											<div
												key={`d-${weekIndex}-${dayIndex}-d`}
												className="mx-4 d-none d-md-block"
											>
												<MainCard
													isInline
													type="videoLgStretch"
													hasCheck={isComplete}
													progress={progress}
													bgImage={video.image}
													detailTitle={title}
													detailSubtitle={subtitle}
													link={video.url}
													onLike={() => onLike ? onLike(video.id) : undefined}
													hasLikeButton={onLike ? true : false}
													videoId={video.id}
													favorites={favorites}
													className={className}
												/>
											</div>
											<div
												key={`d-${weekIndex}-${dayIndex}-m`}
												className="d-block d-md-none phone-video-list"
											>
												<MainCard
													cardType="video"
													type="videoLgStretch"
													hasCheck={isComplete}
													progress={progress}
													logoImage={video.image}
													detailTitle={title}
													detailSubtitle={subtitle}
													link={video.url}
													onLike={() => onLike ? onLike(video.id) : undefined}
													hasLikeButton={onLike ? true : false}
													videoId={video.id}
													favorites={favorites}
													className={className}
												/>
											</div>
										</Fragment>
									)
								})}
							</div>
						</Col>
					</Row>
				)
			})}
		</>
	)
}

export default WorkoutVideos
