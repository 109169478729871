import WithAuth from '../../../components/WithAuth'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import MultiLineText from '../../../components/text/MultiLineText'
import MainCard from '../../../components/cards/MainCard'
import RSButton from "../../../components/buttons/RSButton"
import RSNavCont from '../../../components/layout/RSNavCont'
// import PageTitle from '../../../components/text/PageTitle'
import { CANCEL_IMAGES } from '../../../modules/values'
import { webinarLinks } from '../../../modules/linkInfo'
import { guideLinks } from '../../../modules/linkInfo'
import { CANCEL_STEP_1_TEXT } from '../../../modules/values'
import { FC } from 'react'
import { useNavigate } from 'react-router-dom'

// Info Text
const {
	title,
	info,
	trainingTitle,
	trainingInfo,
	trainingButton,
	strengthTitle,
	strengthInfo,
	strengthButton,
	injuryTitle,
	injuryInfo,
	injuryButton,
} = CANCEL_STEP_1_TEXT


/**
 * Step1 Component
 */

const Step1: FC = () => {
	const navigate = useNavigate()
	// Render
	return (
		<RSNavCont>
			<div className='cancel-plan-card'>
				{/* Title Row */}
				{/* <Row className="justify-content-center d-none d-md-block">
				<Col className="text-center">
					<PageTitle text={CANCEL_TITLE} />
				</Col>
			</Row> */}

				{/* Header Row */}
				<Row className="text-center mb-4 cancel--title">
					<Col>
						<h1 className='cancel--title'>{title}</h1>
					</Col>
				</Row>

				{/* Info */}
				<Row className="text-center mb-4">
					<Col>
						<MultiLineText text={info} />
					</Col>
				</Row>

				{/* Cards */}
				<Row className="mb-5">
					{/* Training Card */}
					<Col sm={12} md={6}>
						<MainCard
							cardType="split"
							bgImage={CANCEL_IMAGES.training}
							link={guideLinks.Training}
							buttonLink={guideLinks.Training}
							buttonText={trainingButton}
							className="card-fixed-body-height"
							isColored
							isSmall
						>
							<div className="mb-4">
								<h3 className=''>{trainingTitle}</h3>
							</div>
							<div className='text-small'>
								<MultiLineText text={trainingInfo} />
							</div>
						</MainCard>
					</Col>

					{/* Strength Card */}
					<Col sm={12} md={6}>
						<MainCard
							cardType="split"
							bgImage={CANCEL_IMAGES.strength}
							link={guideLinks.Strength}
							buttonLink={guideLinks.Strength}
							buttonText={strengthButton}
							className="card-fixed-body-height"
							isColored
							isSmall
						>
							<div className="mb-4">
								<h3 className=''>{strengthTitle}</h3>
							</div>
							<div className='text-small'>
								<MultiLineText text={strengthInfo} />
							</div>
						</MainCard>
					</Col>

					{/* Injury Card */}
					<Col sm={12} md={6}>
						<MainCard
							cardType="split"
							bgImage={CANCEL_IMAGES.injury}
							link={webinarLinks['RunSmart Protocol']}
							buttonLink={webinarLinks['RunSmart Protocol']}
							buttonText={injuryButton}
							className="card-fixed-body-height"
							isColored
							isSmall
						>
							<div className="mb-4">
								<h3 className=''>{injuryTitle}</h3>
							</div>
							<div className='text-small'>
								<MultiLineText text={injuryInfo} />
							</div>
						</MainCard>
					</Col>
				</Row>
			</div>
			{/* Prompt */}
			<Row className="text-center mb-4">
				<Col>
					<h3 className=''>Not interested?</h3>
				</Col>
			</Row>

			{/* Cancel Button */}
			<Row className="text-center pb-4">
				<Col>
					<RSButton
						sx={{ width: '16rem', textTransform: 'uppercase' }}
						onClick={()=>navigate("/cancel/step-2", { replace: true })}
					>
						Click Here to Cancel
					</RSButton>
				</Col>
			</Row>
		</RSNavCont>
	)
}

export default WithAuth(Step1)
