/* eslint-disable @typescript-eslint/no-unused-vars */
import { useEffect, useState } from 'react'
import WithAuth from '../../components/WithAuth'
import AppMenus, { BodyComponent } from '../../components/layout/AppMenus'
import { FC } from 'react'
import {
	Container,
	Tab,
	Form,
	Row,
	Col,
	Button,
	Offcanvas,
	Nav,
	Placeholder,
} from 'react-bootstrap'
import { FaGear } from 'react-icons/fa6';
import YourTrainingPlanSidebar from '../../components/your-training-plan-sidebar'
import useStore from '../../store/useStore'
import { ICurrentUser } from '../../store/createAccountStore'
import {
	GetAllUserNotes,
	GetMarathonValues,
	GetUserInfo,
	GetUserNotes,
	GetUserOverride,
	GetUserRestComments,
	GetUserVdotChanges,
} from '../../modules/trainingPlanActions'
import { IRaceType } from './build/RaceType'
import useTrainingPlanUtils from '../../hooks/useTrainingPlanUtils'
import {
	DAYS_OF_WEEK,
	DAYS_OF_WEEK_ABBV,
	DAYS_OF_WEEK_ALT,
	DAYS_OF_WEEK_ALT_ABBV,
} from './build/DaysOptions'
import date from 'locutus/php/datetime/date'
import { strtotime } from 'locutus/php/datetime'
import { RaceOptions } from './build/RaceOptions'
import {
	MarathonValRecord,
	UserNotesRecord,
	UserOverrideRecord,
} from '../../api/v2/types'
import useBuildTrainingPlan from './hooks/useBuildTrainingPlan'
import Header from './components/Header'
import WeekSelector from './components/WeekSelector'
import DaysSelector from './components/DaysSelector'
import LeftPanelContainer from './components/LeftPanelContainer'
import WeatherAlert from './components/WeatherAlert'
import TrainingPaces from './components/TrainingPaces'
import { IRaceDetails } from './types'
import CrossTraining from './components/CrossTraining'
import GoalRaces from './components/GoalRaces'
import NotesBox from './components/NotesBox'
import { ITrainingPlanStore } from '../../store/createTrainingPlanStore'
import useWeather from './hooks/useWeather'
import { useLocation, useNavigate } from 'react-router-dom'
import { getReq, navReq } from '../../modules/apiConsume'

import {
	slideInRight,
	slideOutRight,
	slideInLeft,
	slideOutLeft,
	fadeIn,
} from 'react-animations'
import styled, { keyframes } from 'styled-components'
import useIsMobileScreen from '../../hooks/useIsMobileScreen'
import { AuthPageProps } from '../../modules/types'
import useQueryParams from '../../hooks/useQueryParams'
import moment from 'moment'
import { GarminDailyDetail, IGarminStore } from '../../store/createGarminStore'
import { ActivityDetailsStorage, Summary } from '../../api/v2/garmin-connect/garmin-connect.types'
import GarminDetailsTab from './components/GarminDetailsTab'

const AslideInLeft = keyframes`${slideInLeft}`
const AslideInRight = keyframes`${slideInRight}`

const AslideOutLeft = keyframes`${slideOutLeft}`
const AslideOutRight = keyframes`${slideOutRight}`

const FadeIn = keyframes`${fadeIn}`

const DivFadeIn = styled.div`
	animation: 0.2s ${FadeIn};
`
const DivInLeft = styled.div`
	animation: 0s ${AslideInLeft};
`
const DivInRight = styled.div`
	animation: 0.3s ${AslideInRight};
`

const DivOutLeft = styled.div`
	animation: 0.3s ${AslideOutLeft};
`
const DivOutRight = styled.div`
	animation: 0.3s ${AslideOutRight};
`

const YourTrainingPlan: FC<AuthPageProps> = ({ hasLoaded }) => {
	const location = useLocation()

	const dismissSync = localStorage.getItem('dismissSync')

	const {
		getWeekDays,
		getCurrentWeek,
		ConvertM2Mt,
		convertDistance,
		getCurrentVDOT,
		convertDateToUTC,
	} = useTrainingPlanUtils()

	const [userInfo, setUserInfo] = useState<any>(null)

	const {
		getDailyActivityDisplay,
		getRaceDetailsValues,
		computelWeekTotalDistance,
		extractPace,
		getOverridePace,
		distanceInterpret,
		convertPace,
		getWorkoutVideoDetails,
		getRaceDistance,
		getRaceDistancePace,
		getRaceDayDetails,
		handleAdjustPaceByLevel,
		convertPaceBase
	} = useBuildTrainingPlan(Number(userInfo?.training_level))
	const { currentUser } = useStore((state: ICurrentUser) => state)
	const { garminDailyDetails, setGarminDailyDetails, setCurrentDayActivities, currentDayActivities, currentWeekActivities, setCurrentWeekActivities } = useStore((state: IGarminStore) => state)
	const {
		setCurrentLocation,
		showSideBar,
		setShowSideBar,
		setUserRestComments,
		setUserOverrides,
		userOverrides,
		setUserVdotAdjustments,
		userVdotAdjustments,
	} = useStore((state: ITrainingPlanStore) => state)
	const [show, setShow] = useState(false)
	const [selectedRace, setSelectedRace] = useState<IRaceType | null>(null)
	const [reload, setReload] = useState(false)
	const [weekDays, setWeekDays] = useState<Array<any>>([])
	const [daysOfWeekAbbv, setDaysOfWeekAbbv] = useState<Array<any>>([])
	const [userCustomPaces, setUserCustomPaces] = useState<Array<any>>([])
	const [initArray] = useState<Array<any>>([1, 2, 3, 4, 5, 6, 7, 8, 9, 10])
	const [raceDetails, setRaceDetails] = useState<IRaceDetails | undefined>(
		undefined
	)
	const [selectedWeek, setSelectedWeek] = useState<{
		week: string
		key: number
	}>({ week: '', key: 1 })
	const [currentWeek, setCurrentWeek] = useState<{ week: string; key: number }>(
		{ week: '', key: 1 }
	)
	const [selectedDay, setSelectedDay] = useState<number | undefined>(undefined)
	const [userNotes, setUserNotes] = useState<UserNotesRecord | undefined>(
		undefined
	)
	const [allUserNotes, setAllUserNotes] = useState<
		Array<UserNotesRecord> | undefined
	>(undefined)
	const [title, setTitle] = useState<string | undefined>(undefined)

	const [dayData, setDayData] = useState<
		| { marathon?: any; marathonPaces?: Array<any>; workouts?: Array<any> }
		| undefined
	>(undefined)

	const [marathonDailyValues, setMarathonDailyValues] = useState<Array<any>>([])
	const [loadingValues, setLoadingValues] = useState(true)
	const [selectedDate, setSelectedDate] = useState('')

	const [raceDay, setRaceDay] = useState<number | undefined>(undefined)
	const [currentVdot, setCurrentVdot] = useState({ vdot: 0, targetm: 0 })
	// const [isRaceWeek, setIsRaceWeek] = useState(false)

	const [userGarminDetails, setUserGarminDetails] = useState<any>()

	const handleClose = () => setShowSideBar(false)
	const handleShow = () => setShowSideBar(true)
	const { planId } = useQueryParams()
	const getWeeklyActivities = async (signal: AbortSignal) => {

		const { type, weeks, distance, monday_start } = userInfo
		const details = getRaceDayDetails(userInfo)
		const saturdayRaceMondayStart = details.raceDay == 5 && details.saturdayRace
		const saturdayRaceSundayStart =
			details.raceDay == 6 && !details.saturdayRace
		let userNote
		// console.log(details)


		// console.log(userInfo?.weeks)
		// console.log(weekDays.length)

		const checkRaceWeek = () => {
			//sunday start - monday race
			if (details.raceDay == 1 && +weeks + 1 == selectedWeek.key && type != 4)
				return true
			//sunday start - sunday race
			else if (
				details.raceDay == 0 &&
				+weeks + 1 == selectedWeek.key &&
				type != 4
			)
				return true
			//sunday start - saturday race
			else if (
				saturdayRaceSundayStart &&
				weeks == selectedWeek.key &&
				type != 4
			)
				return true
			else if (
				saturdayRaceMondayStart &&
				weeks == selectedWeek.key &&
				type != 4
			) {
				return true
			} else return false
		}
		const isRaceWeek = checkRaceWeek()

		const values: Array<any> = []
		let marathons: Array<any> = []
		let paces: Array<any> = []
		let workouts: Array<any> = []

		const days = monday_start ? DAYS_OF_WEEK_ALT : DAYS_OF_WEEK
		setLoadingValues(true)

		// console.log(selectedWeek?.key)

		const params: MarathonValRecord = {
			marathon: type,
			type: weeks,
			training_goal: distance,
			week: selectedWeek?.key,
			distance: distance,
			// day: +(index as unknown as number) + 1
		}

		await GetMarathonValues(params, signal).then(async (res) => {
			if (res && res.data.messages === 'success') {
				marathons = res.data.result.marathon
				paces = res.data.result.marathonPaces
				workouts = res.data.result.workouts
			}
		})

		if (!isRaceWeek)
			for (const [index, d] of Object.entries(days)) {
				const indexVal = +(index as unknown as number) + 1
				// const marathon = res.data.result.marathon.find((x: any) => x.day == indexVal)
				const marathon = marathons?.filter((x: any) => x.day == indexVal)

				if (allUserNotes && allUserNotes.length > 0 && marathon)
					userNote = allUserNotes?.find((x) => x?.marid == marathon[0]?.id)

				if (marathon) {
					const data = {
						marathon: {
							id: marathon[0]?.id,
							marathon: marathon[0]?.marathon,
							type: marathon[0]?.type,
							training_goal: marathon[0]?.training_goal,
							week: marathon[0]?.week,
							day: marathon[0]?.day,
							distance: marathon[0]?.distance,
							race_day: marathon[0]?.race_day,
						},
						marathonPaces: buildMarathonPaces(marathon),
						workouts: buildWorkouts(marathon),
						// overrides: buildOverrides(marathon)
					}
					values.push({ ...data })
				} else values.push([])
			}
		else if (isRaceWeek)
			for (const [index, d] of Object.entries(days)) {
				const indexVal =
					+(index as unknown as number) + 2 - (saturdayRaceMondayStart ? 1 : 0)
				// const marathon = res.data.result.marathon.find((x: any) => x.day == indexVal)
				const marathon = marathons?.filter((x: any) => x.day == indexVal)
				if (allUserNotes && allUserNotes.length > 0 && marathon)
					userNote = allUserNotes?.find((x) => x.marid == marathon[0]?.id)

				if (marathon && +index + 1 != 7) {
					const data = {
						marathon: {
							id: marathon[0]?.id,
							marathon: marathon[0]?.marathon,
							type: marathon[0]?.type,
							training_goal: marathon[0]?.training_goal,
							week: marathon[0]?.week,
							day: marathon[0]?.day - (saturdayRaceMondayStart ? 0 : 1),
							distance: marathon[0]?.distance,
							race_day: marathon[0]?.race_day,
						},
						marathonPaces: buildMarathonPaces(marathon),
						workouts: buildWorkouts(marathon),
						// overrides: buildOverrides(marathon)
					}
					if (saturdayRaceMondayStart && data.marathon.day == 6) {
						//
					}
					// if (saturdayRaceSundayStart && data.marathon.day != 6) {
					else values.push({ ...data })
					// }
				} else {
					values.push({
						marathon: {
							id: 5000 + userInfo?.uid,
							marathon: userInfo?.type,
							type: userInfo?.weeks,
							training_goal: userInfo?.distance,
							week: selectedWeek.key,
							day:
								details.raceDay == 1
									? 2
									: details.raceDay == 0
										? 1
										: saturdayRaceMondayStart
											? 6
											: 7,
							distance: getRaceDistance(userInfo?.type as number),
							race_day: 1,
						},
						marathonPaces: [
							{
								mar_id: 5000 + userInfo?.uid,
								paceId: 9999 + userInfo?.uid,
								pace: 'Race Day',
								notes: `${getRaceDistancePace(userInfo)} ${userInfo?.km === 'Y' ? 'kms' : 'miles'
									}`,
								orderid: userInfo?.type,
							},
						],
						workouts: [],
					})
				}
			}

		setLoadingValues(false)
		setMarathonDailyValues(values)
		if (title) setTitle((state) => state)
		else
			setTitle(
				`${selectedRace?.title} ${selectedRace?.value != 4 ? selectedRace?.subTitle : ''
				}`
			)
	}

	const getUserRestComments = async (abort: AbortSignal) =>
		GetUserRestComments(userInfo?.uid, abort).then((res) =>
			setUserRestComments(res?.data?.result)
		)

	const buildMarathonPaces = (values: Array<any>) => {
		const paces: Array<any> = []
		values.forEach((value) => {
			paces.push({
				mar_id: value.paceMarId,
				paceId: value.paceId,
				pace: value.pace,
				notes: value.notes,
				orderid: value.paceOrderId,
			})
		})

		const uniqueValues = paces.filter(
			(a, i) => paces.findIndex((s) => a.paceId === s.paceId) === i
		)
		return uniqueValues
	}

	const buildWorkouts = (values: Array<any>) => {
		const workouts: Array<any> = []
		values.forEach((value) => {
			workouts.push({
				mid: value.workoutMarId,
				workoutId: value.woId,
				title: value.title,
				description: value.description,
				link: value.link,
				orderid: value.wOrderid,
			})
		})

		const uniqueValues = workouts.filter(
			(a, i) => workouts.findIndex((s) => a.workoutId === s.workoutId) === i
		)
		return uniqueValues
	}

	const getConvertedPace = (val: any) => {
		const convert = extractPace(
			val,
			dayData?.marathon?.distance,
			0,
			currentVdot?.vdot,
			userInfo?.km === 'Y' ? true : false,
			currentVdot?.targetm,
			userInfo?.aggressiveness,
			userCustomPaces,
			userInfo?.distance
		)
		const value = convert.setPace.split('/')

		const rawPace = convert.rawPace

		return { value, rawPace }
	}

	const getOverridePaceData = async (userId: number, abort: AbortSignal) => {
		await getOverridePace(userId, abort).then((res) =>
			setUserCustomPaces(res?.data?.result)
		)
	}

	const getDistanceValue = () => {
		const val =
			userInfo?.km == 'Y'
				? convertDistance(ConvertM2Mt(dayData?.marathon?.distance) / 1000)
				: dayData?.marathon?.distance

		if (!isNaN(val)) return val
		return 0
	}

	const getAllUserNotes = async (abort?: AbortSignal) => {
		const allUserNotes = await GetAllUserNotes(userInfo?.uid, abort)
		if (
			allUserNotes?.data?.messages === 'success' &&
			allUserNotes?.data?.result.length > 0
		) {
			setAllUserNotes(allUserNotes?.data.result)
		}
	}

	const getUserNotes = async (marathonDayValue: any, abort: AbortSignal) => {
		const userNotes = await GetUserNotes(
			userInfo?.uid,
			marathonDayValue?.marathon?.id,
			abort
		)
		if (
			userNotes?.data?.messages === 'success' &&
			userNotes?.data?.result?.length > 0
		) {
			setUserNotes(userNotes?.data?.result)
		}
	}

	const getUserOverrides = async (abort: AbortSignal) => {
		const userOverrides = await GetUserOverride(userInfo?.uid, abort)
		if (
			userOverrides?.data?.messages === 'success' &&
			userOverrides?.data?.result.length > 0
		) {
			setUserOverrides(userOverrides?.data?.result)
		}
	}

	const getUserVdotChanges = async (abort: AbortSignal) => {
		await GetUserVdotChanges(userInfo?.uid as number, abort).then((res) => {
			setUserVdotAdjustments(res?.data?.result)
			setRaceDetails(getRaceDetailsValues(raceDetails, userInfo, raceDay))
		})
	}

	const getTotalDistance = (details: any[], userNotes?: any) => {
		const isRaceWeek =
			userInfo?.weeks == selectedWeek.key && userInfo?.type != 4
		// if (!isRaceWeek) {
		if (userInfo?.km === 'Y')
			return (
				(ConvertM2Mt(+computelWeekTotalDistance(details, userNotes)) / 1000)?.toFixed(1)?.toString()?.replace(/(\.0+|0+)$/, '') + ' Kms'
			)
		else if (userInfo?.km === 'N')
			return computelWeekTotalDistance(details, userNotes) + ' Miles'
		// }
		// else
		// 	if (userInfo?.km === 'Y')
		// 		return getRaceDistancePace(userInfo)?.toString() + " Kms"
		// 	else if (userInfo?.km === 'N')
		// 		return getRaceDistancePace(userInfo)?.toString() + " Miles"
	}

	const getGarminDetails = async () => {
		const response = await getReq('/v2/garmin/user')
		const data = response.data.result[0]
		// console.log(data)
		setUserGarminDetails(data)
	}

	const handelGetDailyActivities = async (dateFrom: string, dateTo: string) => {
		setCurrentDayActivities([])

		if (userGarminDetails && userGarminDetails.status == 1) {
			setLoadingValues(true)
			const currentActivity = await getReq(`/v2/garmin/activities/details?user_id=${userGarminDetails?.garmin_id}&activity_date_from=${dateFrom}&activity_date_to=${dateTo}`)
			setLoadingValues(false)

			const results = currentActivity?.data?.result as ActivityDetailsStorage[]
			setCurrentWeekActivities(results)
		}
	}

	const handleEnableGarmin = (userGarminDetails: any) => {
		const momentJoined = moment(userGarminDetails?.last_modified.split('T')[0])
		const selectedMoment = moment(selectedDate)
		const isSameOrAfter = selectedMoment.isSameOrAfter(momentJoined);
		return isSameOrAfter;
	}

	useEffect(() => {
		// console.log(dayData)
		if (dayData && userInfo && selectedRace) {

			const filter = {
				...dayData['marathonPaces'] = dayData?.marathonPaces?.filter((x: any) => x.mar_id == dayData?.marathon?.id)
			}
			handleAdjustPaceByLevel(dayData, userInfo, selectedRace?.value as number, dayData?.marathon?.race_day)

		}
	}, [dayData, userInfo, selectedRace])

	useEffect(() => {
		const abortController = new AbortController()

		if (allUserNotes && allUserNotes?.length > 0) {
			getWeeklyActivities(abortController.signal)
		}
	}, [allUserNotes])

	useEffect(() => {
		const abortController = new AbortController()
		// console.log(currentUser)

		const reValidate = () => {
			if (!currentUser?.vdot_id)
				navReq('/#/training-plan/build/step-1')
		}

		if (hasLoaded && currentUser && !currentUser?.vdot_id) {
			navReq('/#/training-plan/build/step-1')
		} else if (planId && currentUser?.account_id) {
			console.log('here', planId)
			GetUserInfo(currentUser?.account_id as number, abortController.signal).then(
				(res) => {
					setReload(false)
					setUserInfo(res?.data?.result.find((x: any) => x.uid == planId))
				}
			)

		} else if (hasLoaded && currentUser && currentUser?.vdot_id && !userInfo) {
			GetUserInfo(currentUser?.account_id as number, abortController.signal).then(
				(res) => {
					setReload(false)
					setUserInfo(res?.data?.result[0])
				}
			)
		} else if (reload) {
			GetUserInfo(currentUser?.account_id as number, abortController.signal).then(
				(res) => {
					setReload(false)
					setUserInfo(res?.data?.result[0])
				}
			)
		}
		return () => {
			abortController.abort()
		}
	}, [currentUser, hasLoaded, reload])

	useEffect(() => {
		const abortController = new AbortController()
		if (userInfo) {
			getGarminDetails()
			let thisDay = Number(date("w"));
			// console.log(thisDay)
			thisDay = userInfo.monday_start ? (thisDay > 0 ? thisDay - 1 : 6) : Number(date("w"));
			const weekDays = getWeekDays(new Date(), userInfo.start, userInfo.race_date, userInfo.monday_start ? DAYS_OF_WEEK_ALT : DAYS_OF_WEEK);
			let thisWeek = getCurrentWeek(userInfo.start, userInfo.monday_start) + (weekDays.length > userInfo?.weeks && userInfo?.monday_start ? 0 : 1)
			thisWeek = thisDay == 6 && (weekDays.length == userInfo?.weeks) && !userInfo?.monday_start ? thisWeek - 1 : thisWeek
			// const thisWeek = getCurrentWeek(userInfo.start, userInfo.monday_start) + (thisDay == 0 ? 1 : thisDay == 6 ? 0 : 0)
			// console.log(thisWeek)

			setCurrentLocation(userInfo.location)
			setDaysOfWeekAbbv(
				userInfo.monday_start ? DAYS_OF_WEEK_ALT_ABBV : DAYS_OF_WEEK_ABBV
			)
			setRaceDay(date('w', strtotime(convertDateToUTC(userInfo.race_date))))
			getUserOverrides(abortController.signal)
			getUserVdotChanges(abortController.signal)
			setSelectedRace(
				RaceOptions.find(
					(x) => x.value == (userInfo.type as number)
				) as IRaceType
			)
			setWeekDays(weekDays)
			getAllUserNotes()
			getOverridePaceData(userInfo?.uid, abortController.signal)
			if (thisWeek < 0) {
				setSelectedWeek({ key: 1, week: weekDays[1 - 1] })
				setSelectedDay(0)
			} else if (thisWeek >= userInfo?.weeks) {
				setSelectedWeek({
					...selectedWeek,
					key: weekDays.length,
					week: weekDays[weekDays.length - 1],
				})
				setSelectedDay(0)
			} else {
				setSelectedDay(thisDay)
				setSelectedWeek({
					...selectedWeek,
					key: thisWeek + (weekDays.length > userInfo?.weeks ? 1 : 0),
					week: weekDays[thisWeek - weekDays.length > userInfo?.weeks ? 0 : 1],
				})
			}

			setCurrentWeek({
				...currentWeek,
				key: thisWeek + (weekDays.length > userInfo?.weeks ? 1 : 0),
				week: weekDays[thisWeek - weekDays.length > userInfo?.weeks ? 0 : 1],
			})

			getUserRestComments(abortController.signal)
		}

		return () => {
			abortController.abort()
		}
	}, [userInfo])

	useEffect(() => {
		const abortController = new AbortController()

		if (userInfo && selectedWeek) {
			setDayData({ marathonPaces: [], workouts: [], marathon: undefined })
			setMarathonDailyValues([])
			getWeeklyActivities(abortController.signal)
		}
		return () => {
			abortController.abort()
		}
	}, [selectedWeek])

	useEffect(() => {
		const abortController = new AbortController()

		if (!loadingValues && marathonDailyValues.length > 0) {
			const current = getCurrentVDOT(
				selectedWeek.key,
				selectedDay as number,
				currentVdot.vdot != 0 ? currentVdot.vdot : userInfo?.vdot,
				currentVdot.targetm != 0 ? currentVdot.targetm : userInfo?.targetm,
				userVdotAdjustments
			)
			setCurrentVdot(current)
			setRaceDetails({
				...raceDetails,
				totalDistance: getTotalDistance(
					marathonDailyValues,
					allUserNotes
				) as string,
			})

			let mval
			const overrideWeek = userOverrides?.filter(
				(x) => x.week == selectedWeek.key
			)

			if (overrideWeek && overrideWeek?.length > 0) {
				const currentOverride = overrideWeek.find((x) => x.day == selectedDay)
				if (currentOverride?.rest_day !== 'Y') {
					mval = marathonDailyValues?.find(
						(x) => x?.marathon?.id == currentOverride?.mid
					)
				} else if (currentOverride?.rest_day === 'Y') mval = undefined
			} else
				mval = marathonDailyValues?.find(
					(x) => x?.marathon?.day == +(selectedDay as number) + 1
				)

			if (mval) {
				getOverridePaceData(userInfo?.uid, abortController.signal)
				getUserNotes(mval, abortController.signal)
				// getAllUserNotes(abortController.signal)
				setDayData(mval as any)
			} else
				setDayData({ marathonPaces: [], workouts: [], marathon: undefined })
		}

		return () => {
			abortController.abort()
		}
	}, [
		loadingValues,
		selectedDay,
		marathonDailyValues,
		userOverrides,
		userVdotAdjustments,
	])

	useEffect(() => {
		const abortController = new AbortController()
		const search = location?.search?.replace('?', '')
		if (search.includes('level')) {
			const nextURL = `/#/training-plan/view${location?.search}`
			window.location.assign(nextURL)
		} else {
			const val = search?.split('&')
			const weekVal = +val[0]?.replace('week=', '')
			const dayVal = +val[1]?.replace('day=', '')

			if (search && userInfo) {
				const weekDays = getWeekDays(
					new Date(),
					userInfo.start,
					userInfo.race_date,
					userInfo.monday_start ? DAYS_OF_WEEK_ALT : DAYS_OF_WEEK
				)
				const thisWeek =
					getCurrentWeek(userInfo.start, userInfo.monday_start) + 1
				setSelectedDay(dayVal - 1)
				setSelectedWeek({ key: weekVal, week: weekDays[thisWeek - 1] })
				const nextURL = `/#/training-plan/view`
				window.location.assign(nextURL)
			}
		}

		return () => {
			abortController.abort()
		}
	}, [location.search])

	useEffect(() => {
		if (selectedWeek.key && userGarminDetails) {
			handleEnableGarmin(userGarminDetails)
			const getDate = weekDays[selectedWeek.key - 1]
			const selectedDate = moment(getDate).add(selectedDay, 'days').format('YYYY-MM-DD')
			setSelectedDate(selectedDate)
			handelGetDailyActivities(weekDays[selectedWeek.key - 1], moment(weekDays[selectedWeek.key - 1]).add(6, 'days').format('YYYY-MM-DD'))
		}
	}, [selectedWeek.key, userGarminDetails])

	useEffect(() => {
		if ((selectedDay || selectedDay == 0) && currentWeekActivities?.length > 0) {
			const summaries: Summary[] = []
			setCurrentDayActivities([])

			const getDate = weekDays[selectedWeek.key - 1]
			const selectedDate = moment(getDate).add(selectedDay, 'days').format('YYYY-MM-DD')

			const activities = currentWeekActivities?.filter(x => x.activity_date === selectedDate)

			if (activities.length > 0) {
				for (const activity of activities) {
					summaries.push(activity.activity_details.summary)
				}
				setCurrentDayActivities(summaries)
			}
			else
				setCurrentDayActivities([])
		}
	}, [selectedDay, currentWeekActivities, userGarminDetails, selectedWeek.key])

	useEffect(() => {
		localStorage.setItem('page', '')
	}, [])

	return (
		<div className={`${currentWeek?.key < 0 ? 'margin-info' : ''}`}>
			<AppMenus
				title={`${title ? title + ' TRAINING PLAN' : 'LOADING...'}`}
				isMobileTitle
				hideTitleDesktop
			>
				<DivFadeIn>
					<BodyComponent>
						<div className={`your-traning-plan pt-md-1`}>
							<Header
								selectedRace={selectedRace}
								userInfo={userInfo}
								selectedDay={selectedDay}
								selectedWeek={selectedWeek}
								currentWeek={currentWeek}
								getRaceDistance={getRaceDistance}
								userCustomPaces={userCustomPaces}
							/>
							<WeekSelector
								initArray={initArray}
								weekDays={weekDays}
								selectedWeek={selectedWeek}
								loadingValues={loadingValues}
								setSelectedWeek={setSelectedWeek}
							/>
							<Container>
								<Row>
									<Col xl={9}>
										<div className="bg-md-white px-md-2 pt-md-4 pb-0 pb-md-5 mt-2 radius-5">
											<div className="position-relative pb-lg-5">
												{!userInfo && selectedDay == undefined && (
													<Tab.Container id="placeholder">
														<Nav variant="pills" className="flex-column">
															{DAYS_OF_WEEK.map((day, idx) => (
																<Nav.Item key={idx}>
																	<Nav.Link disabled eventKey={idx}>
																		<Placeholder
																			as="nav"
																			animation="glow"
																			style={{ borderRadius: 50, height: 25 }}
																			xs={12}
																		/>
																	</Nav.Link>

																	<span className="font-12 font-weight-normal">
																		<Placeholder as="nav" animation="glow">
																			<Placeholder
																				style={{
																					borderRadius: 10,
																					height: '100%',
																					width: 90,
																					marginTop: 12,
																				}}
																				xs={12}
																			/>
																		</Placeholder>
																	</span>
																</Nav.Item>
															))}
														</Nav>
													</Tab.Container>
												)}
												{/* {
											userInfo && selectedDay != undefined && ( */}
												<Tab.Container
													defaultActiveKey={selectedDay}
													id="week-days-tabs"
												>
													{/* <Nav variant="pills" className="flex-column"> */}
													<DaysSelector
														daysOfWeekAbbv={daysOfWeekAbbv}
														loadingValues={loadingValues}
														setSelectedDay={setSelectedDay}
														getDailyActivityDisplay={getDailyActivityDisplay}
														marathonDailyValues={marathonDailyValues}
														userInfo={userInfo}
														selectedRace={selectedRace}
														selectedDay={selectedDay}
														dayData={dayData}
														selectedWeek={selectedWeek}
														allUserNotes={allUserNotes}
													/>
													<Tab.Content>
														<Row>
															{!loadingValues && dismissSync !== '1' && handleEnableGarmin(userGarminDetails) || !loadingValues && dismissSync !== '1' && currentDayActivities && currentDayActivities?.length > 0 ? (
																<Col sm={12} md={12}>
																	<GarminDetailsTab
																		userGarminDetails={userGarminDetails}
																		userInfo={userInfo}
																		activities={currentDayActivities ? currentDayActivities : []}
																		notesForm={
																			<Col lg={5} md={5}
																				className="px-md-3 mt-0 mt-md-4 p-0 device-reverse-content"
																			>
																				<NotesBox
																					userInfo={userInfo}
																					userNotes={userNotes}
																					getUserNotes={getUserNotes}
																					setUserNotes={setUserNotes}
																					dayData={dayData}
																					selectedDay={selectedDay}
																					selectedWeek={selectedWeek}
																					getUserRestComments={getUserRestComments}
																					loadingValues={loadingValues}
																					activities={currentDayActivities}
																					userGarminDetails={userGarminDetails}
																				/>
																			</Col>
																		}
																		currentRun={
																			<LeftPanelContainer
																				getDistanceValue={getDistanceValue}
																				userInfo={userInfo}
																				userNotes={userNotes}
																				getUserNotes={getUserNotes}
																				setUserNotes={setUserNotes}
																				loadingValues={loadingValues}
																				dayData={dayData}
																				getAllUserNotes={getAllUserNotes}
																				selectedDay={selectedDay}
																				selectedWeek={selectedWeek}
																				userGarminDetails={userGarminDetails}
																				activities={currentDayActivities ? currentDayActivities : []}
																				type={'garmin-connect'}
																			/>
																		}
																		recommended={
																			<>
																				<LeftPanelContainer
																					getDistanceValue={getDistanceValue}
																					userInfo={userInfo}
																					userNotes={userNotes}
																					getUserNotes={getUserNotes}
																					setUserNotes={setUserNotes}
																					loadingValues={loadingValues}
																					dayData={dayData}
																					getAllUserNotes={getAllUserNotes}
																					selectedDay={selectedDay}
																					selectedWeek={selectedWeek}
																					userGarminDetails={userGarminDetails}
																					activities={currentDayActivities ? currentDayActivities : []}
																					type={'runsmart'}
																				/>

																				<WeatherAlert
																					dayData={dayData}
																					getConvertedPace={getConvertedPace}
																					userInfo={userInfo}
																				/>
																				<TrainingPaces
																					dayData={dayData}
																					distanceInterpret={distanceInterpret}
																					convertPace={convertPace}
																					getConvertedPace={getConvertedPace}
																					userInfo={userInfo}
																					userCustomPaces={userCustomPaces}
																					getOverridePaceData={getOverridePaceData}
																					loadingValues={loadingValues}
																					convertPaceBase={convertPaceBase}
																					weekDays={weekDays}
																					selectedWeek={selectedWeek}
																					selectedDay={selectedDay}
																					userGarminDetails={userGarminDetails}
																					getDailyActivityDisplay={getDailyActivityDisplay}
																					selectedRace={selectedRace}
																					activities={currentDayActivities ? currentDayActivities : []}
																					userNotes={userNotes}
																				/>
																				<CrossTraining
																					dayData={dayData}
																					getWorkoutVideoDetails={
																						getWorkoutVideoDetails
																					}
																					loadingValues={loadingValues}
																					userGarminDetails={userGarminDetails}
																					weekDays={weekDays}
																					selectedWeek={selectedWeek}
																					selectedDay={selectedDay}
																					userInfo={userInfo}
																				/>
																				{/* {currentDayActivities && currentDayActivities?.length == 0 ? (
																				<Col lg={5} md={5}
																					className="px-md-3 p-0 device-reverse-content"
																				>
																					<NotesBox
																						userInfo={userInfo}
																						userNotes={userNotes}
																						getUserNotes={getUserNotes}
																						setUserNotes={setUserNotes}
																						dayData={dayData}
																						selectedDay={selectedDay}
																						selectedWeek={selectedWeek}
																						getUserRestComments={getUserRestComments}
																						loadingValues={loadingValues}
																						activities={currentDayActivities}
																						userGarminDetails={userGarminDetails}
																					/>
																				</Col>
																			) : undefined} */}

																			</>
																		} />
																</Col>
															) : (
																<>
																	<Col md={7}>
																		<div className="mt-n5 mt-md-0">
																			<LeftPanelContainer
																				getDistanceValue={getDistanceValue}
																				userInfo={userInfo}
																				userNotes={userNotes}
																				getUserNotes={getUserNotes}
																				setUserNotes={setUserNotes}
																				loadingValues={loadingValues}
																				dayData={dayData}
																				getAllUserNotes={getAllUserNotes}
																				selectedDay={selectedDay}
																				selectedWeek={selectedWeek}
																				userGarminDetails={userGarminDetails}
																				activities={currentDayActivities}
																				type={'runsmart'}
																			/>

																			<WeatherAlert
																				dayData={dayData}
																				getConvertedPace={getConvertedPace}
																				userInfo={userInfo}
																			/>
																			<TrainingPaces
																				dayData={dayData}
																				distanceInterpret={distanceInterpret}
																				convertPace={convertPace}
																				getConvertedPace={getConvertedPace}
																				userInfo={userInfo}
																				userCustomPaces={userCustomPaces}
																				getOverridePaceData={getOverridePaceData}
																				loadingValues={loadingValues}
																				convertPaceBase={convertPaceBase}
																				weekDays={weekDays}
																				selectedWeek={selectedWeek}
																				selectedDay={selectedDay}
																				userGarminDetails={userGarminDetails}
																				getDailyActivityDisplay={getDailyActivityDisplay}
																				selectedRace={selectedRace}
																				userNotes={userNotes}
																			/>
																			<CrossTraining
																				dayData={dayData}
																				getWorkoutVideoDetails={
																					getWorkoutVideoDetails
																				}
																				loadingValues={loadingValues}
																				userGarminDetails={userGarminDetails}
																				weekDays={weekDays}
																				selectedWeek={selectedWeek}
																				selectedDay={selectedDay}
																				userInfo={userInfo}
																			/>
																		</div>
																	</Col>
																	<Col lg={5} md={5}
																		className="px-md-3 p-0 device-reverse-content"
																	>
																		<NotesBox
																			userInfo={userInfo}
																			userNotes={userNotes}
																			getUserNotes={getUserNotes}
																			setUserNotes={setUserNotes}
																			dayData={dayData}
																			selectedDay={selectedDay}
																			selectedWeek={selectedWeek}
																			getUserRestComments={getUserRestComments}
																			loadingValues={loadingValues}
																			activities={currentDayActivities}
																			userGarminDetails={userGarminDetails}
																		/>
																	</Col>
																</>
															)
															}
															{/* <Col lg={5} md={9} className="px-md-5 p-0">
																<WeatherAlert
																	dayData={dayData}
																	getConvertedPace={getConvertedPace}
																	userInfo={userInfo}
																/>
																{userGarminDetails && currentDayActivities && currentDayActivities.length > 0 && !loadingValues ? (
																	<GarminDetailsTab
																		userInfo={userInfo}
																		activities={currentDayActivities}
																		recommended={
																			<>
																				<TrainingPaces
																					dayData={dayData}
																					distanceInterpret={distanceInterpret}
																					convertPace={convertPace}
																					getConvertedPace={getConvertedPace}
																					userInfo={userInfo}
																					userCustomPaces={userCustomPaces}
																					getOverridePaceData={getOverridePaceData}
																					loadingValues={loadingValues}
																				/>
																				<CrossTraining
																					dayData={dayData}
																					getWorkoutVideoDetails={
																						getWorkoutVideoDetails
																					}
																					loadingValues={loadingValues}
																					userGarminDetails={userGarminDetails}
																					weekDays={weekDays}
																					selectedWeek={selectedWeek}
																					selectedDay={selectedDay}
																					userInfo={userInfo}
																				/>
																			</>
																		} />
																) : (
																	<>
																		<TrainingPaces
																			dayData={dayData}
																			distanceInterpret={distanceInterpret}
																			convertPace={convertPace}
																			getConvertedPace={getConvertedPace}
																			userInfo={userInfo}
																			userCustomPaces={userCustomPaces}
																			getOverridePaceData={getOverridePaceData}
																			loadingValues={loadingValues}
																		/>
																		<CrossTraining
																			dayData={dayData}
																			getWorkoutVideoDetails={
																				getWorkoutVideoDetails
																			}
																			loadingValues={loadingValues}
																			userGarminDetails={userGarminDetails}
																			weekDays={weekDays}
																			selectedWeek={selectedWeek}
																			selectedDay={selectedDay}
																			userInfo={userInfo}
																		/>
																	</>
																)
																}

															</Col> */}

														</Row>
													</Tab.Content>
												</Tab.Container>
												{/* )
										} */}
											</div>
										</div>
										{userInfo?.type != 2 || userInfo?.type != 4 ? (
											<GoalRaces
												userInfo={userInfo}
												mainClass="d-none d-md-block"
											/>
										) : null}
									</Col>
									<Col xl={3}>
										<div className="desktop-plan-comp">
											<YourTrainingPlanSidebar
												userInfo={userInfo}
												selectedWeek={selectedWeek}
												raceDetails={raceDetails}
												selectedRace={selectedRace}
												loadingValues={loadingValues}
												marathonDailyValues={marathonDailyValues}
												getDailyActivityDisplay={getDailyActivityDisplay}
												getUserOverrides={getUserOverrides}
												getUserRestComments={getUserRestComments}
												selectedDay={selectedDay}
												getUserVdotChanges={getUserVdotChanges}
												currentWeek={currentWeek}
												handleClose={handleClose}
												allUserNotes={allUserNotes}
												setReload={setReload}
											></YourTrainingPlanSidebar>
										</div>
										{/* Device */}
										<div
											onClick={handleShow}
											className="d-md-none sidebar-open-btn"
										>
											<img
												className="pointer mx-2 mt-3 plus-button-training-plan"
												src="./images/icons/gear-solid.svg"
												alt="Plus"
												style={{ width: '40px', height: '40px' }}
											></img>
											{/* <FaGear color='#1E3050' size={'45'} /> */}
										</div>
										<Offcanvas show={showSideBar} onHide={handleClose}>
											<Offcanvas.Header closeButton></Offcanvas.Header>
											<Offcanvas.Body>
												<YourTrainingPlanSidebar
													userInfo={userInfo}
													selectedWeek={selectedWeek}
													raceDetails={raceDetails}
													selectedRace={selectedRace}
													loadingValues={loadingValues}
													marathonDailyValues={marathonDailyValues}
													getDailyActivityDisplay={getDailyActivityDisplay}
													getUserOverrides={getUserOverrides}
													getUserRestComments={getUserRestComments}
													selectedDay={selectedDay}
													getUserVdotChanges={getUserVdotChanges}
													currentWeek={currentWeek}
													setSelectedDay={setSelectedDay}
													setSelectedWeek={selectedWeek}
													handleClose={handleClose}
													allUserNotes={allUserNotes}
													setReload={setReload}
												></YourTrainingPlanSidebar>
											</Offcanvas.Body>
										</Offcanvas>
									</Col>
								</Row>
							</Container>
						</div>
					</BodyComponent>
				</DivFadeIn>
			</AppMenus>
		</div >
	)
}

export default WithAuth(YourTrainingPlan)
