import { FC, useState, useEffect } from 'react';

import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';

import RSButton from '../../components/buttons/RSButton'

export type StrengthYogaProps = {
	addDetails: any
	allStageDetails: any,
	handleIsStepComplete?: any
	setUpdatedAddonDetails?: any
}

const StrengthYoga: FC<StrengthYogaProps> = ({ addDetails, allStageDetails, handleIsStepComplete, setUpdatedAddonDetails }) => {
	const stageNumber = '3'
	const { crossTraining: ct } = allStageDetails?.[stageNumber] || {}
	const [crossTraining, setCrossTraining] = useState<Array<string>>(ct || [])

	//const initialMount = useRef(true)

	const activities = [{
		activity: 'Strength',
		length: '30 minutes',
		desc: 'Physical Therapist-designed workouts that focus on both performance and injury prevention'
	}, {
		activity: 'Yoga',
		length: '30 minutes',
		desc: 'Runner-specific practices that improve strength and mobility'
	}]

	useEffect(() => {
//		if(initialMount.current) {
//			initialMount.current = false
//			return
//		}

		if (allStageDetails?.[stageNumber]?.crossTraining != crossTraining) {
			// console.log('[updated crossTraining]')
			setUpdatedAddonDetails(true)
		}

		addDetails(stageNumber, { crossTraining: crossTraining })
		handleIsStepComplete(true, stageNumber)
	}, [crossTraining])

    return <Box sx={{px: 3}}>
			<Grid container xs={12} sx={{ pb: '10px', fontFamily: 'Poppins-Medium', fontSize: '20px', lineHeight: '1em' }} alignItems='flex-end'>
					What cross-training would you like to add?
			</Grid>
			<Grid container xs={12} sx={{ fontFamily: 'Poppins', fontSize: '14px', lineHeight: '1.2em'}} alignItems='flex-end'>
				{`We'll pair workouts to the best days of your plan to maximize performance and minimize injuries.`}
			</Grid>
			<Grid container xs={12} sx={{ pt: 1 }} justifyContent='space-between'>
				{activities.map((act: any, ai: number)=>{
					const { activity, length, desc } = act
					const isActive = crossTraining.includes(activity)
					return <Grid item container xs={12} key={'activity-'+ai} sx={{minHeight: '140px', width: '100px', mt: 2}} justifyContent='center' alignItems='center'>
						<RSButton card style2 customized secondary={isActive} sx={{height: '100%', flexDirection: 'column'}}
							onClick={() => setCrossTraining(isActive ? crossTraining.filter((ct: string)=> activity!==ct) : [...crossTraining, activity])}>
							<Grid container xs={12} sx={{p: 2}}>
								<Box sx={{fontFamily: 'Poppins-Medium !important', fontSize: '18px !important', textAlign: 'left', lineHeight: '1.2em', width: '100%'}}>{activity}</Box>
								<Box sx={{ fontFamily: 'Poppins !important', fontSize: '13px !important', lineHeight: '1.2em', textAlign: 'left', pb: '5px'}}>Average Length: {length}</Box>
								<Box sx={{ fontFamily: 'Poppins !important', fontSize: '13px !important', lineHeight: '1.5em', textAlign: 'left'}}>{desc}</Box>
							</Grid>
						</RSButton>
					</Grid>
				})}
			</Grid>
    </Box>
}

export default StrengthYoga;
