/* eslint-disable @typescript-eslint/no-unused-vars */

import React, { FC, useCallback, useEffect, useRef, useState } from 'react'
import { AccountProfile } from '../../api/v2/account/Account.types';
import RSButton from '../../components/buttons/RSButton';
import RSInput from '../../components/input/RSInput';
import Resizer from 'react-image-file-resizer';
import { Alert, Grid, Box } from '@mui/material'
import { useDropzone } from 'react-dropzone'
import AvatarEditor from 'react-avatar-editor'
import { postReq } from '../../modules/apiConsume'
export type NameProfileImageProps = {
    data: AccountProfile,
    handleUpdateProfile: Function,
    reload?: Function
    isMobile?: boolean
    userInfo?: any
}

const NameProfileImage: FC<NameProfileImageProps> = ({ data, handleUpdateProfile, reload, isMobile, userInfo }) => {

    const [firstName, setFirstName] = useState(data?.first_name || userInfo?.first_name || "");
    const [lastName, setLastName] = useState(data?.last_name || userInfo?.last_name || "");

    const editor = useRef<any>(null);
    // const imageUrl = src ? API_URL + src + '?refresh=' + Date.now() : undefined

    const [image, setImage] = useState<any>()
    const [showAlert, setShowAlert] = useState(false)
    const [showErrorAlert, setShowErrorAlert] = useState(false)
    const [isUploading, setIsUploading] = useState(false)

    const handleSave = async () => {
        if (data?.first_name === firstName && data?.last_name === lastName && image) {
            await handleSaveImage()
        }
        else if ((data?.first_name !== firstName || data?.last_name !== lastName) && !image) {
            handleUpdateProfile({ first_name: firstName, last_name: lastName, name: firstName + ' ' + lastName })
        }
        else {
            await handleSaveImage()
            handleUpdateProfile({ first_name: firstName, last_name: lastName, name: firstName + ' ' + lastName })
        }
    }

    const onDrop = useCallback(async (acceptedFiles: any) => {
        setImage(acceptedFiles[0])
    }, [])

    const getImageUrl = async () => {
        const dataUrl = editor.current?.getImage()?.toDataURL()
        const res = await fetch(dataUrl)
        const blob = await res.blob()
        return blob
    }

    const resizeFile = (file: Blob) => new Promise((resolve) => {
        Resizer.imageFileResizer(file, 300, 300, "JPEG", 70, 0,
            (uri) => {
                resolve(uri);
            },
            "blob"
        );
    });

    const handleSaveImage = async () => {
        if (editor.current === null) return
        setIsUploading(true)
        await getImageUrl().then(async (res) => {
            const formData = new FormData();
            await resizeFile(res).then((file: any) => {
                formData.append("profileImage", file)
            })
            data.user_id && formData.append("user_id", data.user_id.toString())
            try {
                await postReq('v2/upload-profile-image', formData).then((res) => {
                    if (res && res?.type === "success") {
                        setShowAlert(true)
                        setIsUploading(false)
                    }
                })
            } catch (e) {
                console.log(e)
                setIsUploading(false)
                setShowErrorAlert(true)
            }
        })
    }

    const renderAlert = (type: string) => {
        return (
            <Box sx={{ mt: 2 }}>
                {type === 'success' ? (
                    <Alert severity="success" onClose={() => { setShowAlert(false) }}>
                        Image uploaded successfully
                    </Alert>
                ) : type === "error" ? (
                    <Alert severity="warning" onClose={() => { setShowErrorAlert(false) }}>
                        Error uploading image
                    </Alert>
                ) : undefined}
            </Box>
        )
    }

    const { getRootProps, getInputProps } = useDropzone({
        onDrop,
        accept: {
            'image/jpeg': [],
            'image/png': [],
            'image/webp': [],
            'image/heic': [],
            'image/jfif': [],
        }, multiple: false
    })

    useEffect(() => {
        if (showAlert || showErrorAlert) setTimeout(() => {
            setShowAlert(false);
            setShowErrorAlert(false)
            setImage(null)
            reload && reload()
        }, 2000)
    }, [showAlert, showErrorAlert])

    useEffect(() => {
        if (data) {
            setFirstName(data?.first_name || "")
            setLastName(data?.last_name || "")
        }
    }, [data])

    return (
        <Box>
            <Grid container xs={12} sx={{ fontFamily: 'Poppins', fontSize: '16px', lineHeight: '1.4em', display: "flex", justifyContent: 'center', mt: 4 }}>
                Update Profile
            </Grid>
            {isMobile ? (
                <Box {...getRootProps()} sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignContent: 'center', alignItems: 'center', mt: '20px', mb: '20px' }}>
                    {!image &&
                        <Box sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignContent: 'center', alignItems: 'center', position: 'absolute' }}>
                            <i style={{ fontSize: "40px", color: "#1E3050" }} className="fa-solid fa-arrow-up-from-bracket" />
                            <Grid container xs={12} sx={{ fontFamily: 'Poppins', fontSize: '16px', lineHeight: '1.4em', display: "flex", justifyContent: 'center', mt: 2 }}>
                                Upload
                            </Grid>
                        </Box>
                    }
                    <AvatarEditor scale={1.2} ref={editor} style={{ backgroundColor: 'white', width: '180px', height: '180px', borderRadius: '5%' }} border={0} width={180} height={180} image={image ? image : undefined} />
                    <input type='image' {...getInputProps()} />
                    <Box>{showAlert ? renderAlert('success') : showErrorAlert ? renderAlert('error') : null}</Box>
                </Box>
            ) : (
                <Box sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignContent: 'center', alignItems: 'center', mt: '20px', mb: '20px' }}>
                    <AvatarEditor scale={1.2} ref={editor} style={{ backgroundColor: 'white', width: '180px', height: '180px', borderRadius: '5%' }} border={0} width={180} height={180} image={image ? image : undefined} />
                    <input type='image' {...getInputProps()} />
                    <Box>{showAlert ? renderAlert('success') : showErrorAlert ? renderAlert('error') : null}</Box>
                    <RSButton {...getRootProps()} sx={{ mt: 3, width: '50%' }}>Upload Photo</RSButton>
                </Box>
            )}
            <Grid container xs={12}>
                <RSInput value={firstName} setValue={setFirstName} title={'Fist Name'} />
                <RSInput value={lastName} setValue={setLastName} title={'Last Name'} />
            </Grid>
            <RSButton onClick={handleSave} disabled={data?.first_name === firstName && data?.last_name === lastName && !image || isUploading} sx={{ mt: 3 }}>Save</RSButton>
        </Box >
    )
}

export default NameProfileImage