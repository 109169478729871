/* eslint-disable @typescript-eslint/no-unused-vars */
import Button from 'react-bootstrap/Button'
import PlayButton from '../buttons/PlayButton'
import Container from 'react-bootstrap/Container'
import { FC } from 'react'
import { useNavigate } from 'react-router-dom'
import useAppStorage from '../../hooks/useAppStorage'
import useDeviceDetect from '../../hooks/useDeviceDetect'

// Props
export type RsHeroProps = {
	bgImage: string
	titleImage?: string
	title: string
	subtitle: string
	user: string
	actionText?: string
	detailsText?: string
	detailsLink?: string
	brightness?: number
	onAction?: string
	onPlay?: () => any
	onLike?: () => any
}

// Get Backgroun Image Style
const getBackgroundImageStyle = (bgImage: string, brightness?: number) => {
	let gradient = ''
	if (brightness && brightness < 1 && brightness >= 0) {
		const rgba = `rgba(0, 0, 0, ${1 - brightness})`
		gradient = `linear-gradient(${rgba}, ${rgba}),`
	}
	return `${gradient} url("${bgImage}")`
}

/**
 * RsHero Component
 */

const RsHero: FC<RsHeroProps> = ({
	bgImage,
	titleImage,
	title,
	subtitle,
	user,
	actionText,
	detailsText,
	detailsLink,
	brightness,
	onAction,
	onPlay,
}) => {
	const navigate = useNavigate();
	const { isIos } = useAppStorage()
	const { isMobile } = useDeviceDetect()
	// Properties
	// const welcomeText = `Welcome Back ${user}${isMobile && isIos ? '!' : ''}`.toUpperCase()

	// Render
	return (
		<Container fluid="lg" className="pt-6 mb-4 mt-1">
			<div className="full-width-relative">
				<div className="jumbotron">
					{/* Welcome Text */}
					{/* <h2
						className="text-center mb-4 d-md-none"
						style={{ marginTop: '2.5rem' }}
					>
						{welcomeText}
					</h2> */}
					{/* Background Image */}
					<div
						className="pointer img-fill"
						style={{
							backgroundImage: getBackgroundImageStyle(bgImage, brightness),
						}}
						onClick={() => onAction && navigate(onAction.replace('/#', ''))}
					>
						<div>
							{/* Left Info */}
							<div className="flex-content-end justify-content-start ms-2 btm-5">
								<div>
									{/* Title Image */}
									{titleImage && (
										<img src={titleImage} className="hero-image ms-n2" />
									)}

									{/* Title */}
									<h3 className="text-white mb-1 hero-title">{`${title.toUpperCase()}`}</h3>

									{/* Subtitle */}
									<h4 className="text-white mb-1 hero-subtitle">{`${subtitle.toUpperCase()}`}</h4>

									{/* Details Link */}
									<a className="link-light" href={detailsLink}>
										<h5 className="mb-0">{detailsText}</h5>
									</a>
								</div>
							</div>

							{/* Play Button */}
							{onPlay && (
								<div className="home-play-btn">
									<PlayButton isCentered isHome onPlay={onPlay} />
								</div>
							)}

							{/* Action Button */}
							<div className="flex-content-end justify-content-end me-2 btm-5">
								{actionText && (
									<Button
										as="a"
										size="lg"
										variant="outline-light rounded-pill"
										className="home-action-btn"
										href={onAction}
									>
										{actionText}
									</Button>
								)}
							</div>

							{/* Like Button */}
							{/* {(onLike ? true : false) && <Heart onLike={onLike} />} */}
						</div>
					</div>
				</div>
			</div>
		</Container>
	)
}

export default RsHero
