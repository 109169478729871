import WithAuth from '../../components/WithAuth'
import AppMenus from '../../components/layout/AppMenus'
import TextCard from '../../components/cards/TextCard'
import BasicInput from '../../components/input/BasicInput'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import DirectionsRun from '@mui/icons-material/DirectionsRun'
import PageTitle from '../../components/text/PageTitle'
import MultiLineText from '../../components/text/MultiLineText'
import { FC } from 'react'

// Invite Text
const INVITE_TEXT = `
Invite your running friends to try runsmart online using email or Facebook.

Your running crew will get an email from us to receive a one month trial for $1.

When accepted you friend will get unlimited access to all features - they can cancel anytime.
`

// Spam Text
const SPAM_TEXT = `
Don't worry we won't email your friend a bunch of spammy newsletters or sell their email
`

/**
 * Invite Component
 */

const Invite: FC = () => {
	// Render
	return (
		<AppMenus title="Invite a Friend">
			{/* Icons Row */}
			<Row className="text-center justify-content-center mb-5">
				<Col xs={12}>
					<div>
						{[0, 1, 2, 3, 4].map((value, index) => (
							<span key={index} className="mx-3">
								<DirectionsRun className="icon-xl color-primary" />
							</span>
						))}
					</div>
				</Col>
			</Row>

			{/* Title Row */}
			<Row className="text-center justify-content-center">
				<Col xs={12}>
					<PageTitle text="5 Invites Available" />
				</Col>
			</Row>

			{/* Info Row */}
			<Row className="text-center justify-content-center gx-5">
				<Col xs={12} sm={9} md={6} className="mb-4">
					<TextCard id="invite-card" title="How it Works">
						<MultiLineText text={INVITE_TEXT} />
					</TextCard>
				</Col>
				<Col xs={12} sm={9} md={6}>
					<TextCard noBackground>
						<div className="mb-4">
							<h5>Invite With Email</h5>
						</div>
						<div className="mb-3">
							<BasicInput id="name-input" placeholder="Friend's Name" />
						</div>
						<div className="mb-5">
							<BasicInput id="email-input" placeholder="Their Email Address" />
						</div>
						<div className="mb-5">
							<i>{SPAM_TEXT}</i>
						</div>
						<div className="mb-4">
							<h5>Message on Facebook</h5>
						</div>
						<div>
							<BasicInput id="name-input-2" placeholder="Friend's Name" />
						</div>
					</TextCard>
				</Col>
			</Row>
		</AppMenus>
	)
}

export default WithAuth(Invite)
