/* eslint-disable @typescript-eslint/no-unused-vars */
import { ArrowBack, ArrowBackIos, ArrowForward, ArrowForwardIos } from '@mui/icons-material'
import React, { FC, useEffect, useState, useRef } from 'react'
import { Button, Card, Col, Row } from 'react-bootstrap'
import MainCard from '../../components/cards/MainCard'
import AppMenus, { BodyComponent } from '../../components/layout/AppMenus'
import useIsMobileScreen from '../../hooks/useIsMobileScreen'
import useQueryParams from '../../hooks/useQueryParams'
import { backNav, getReq, navReq, postReq, putReq } from '../../modules/apiConsume'
import { Video } from '../../modules/videos/allVideos'
import { slideInRight, slideOutRight, slideInLeft, slideOutLeft } from 'react-animations'
import styled, { keyframes } from 'styled-components';
import { PrimaryButton } from '../../components/buttons/AuthSubmitButtons'
import ProgramMobileView from '../programs/v2/ProgramMobileView'
import useFavorites from '../favorites/useFavorites'
import WithAuth from '../../components/WithAuth'
import { AuthPageProps } from '../../modules/types'
import recoverVideos from './RecoverVideos.json'
import AnswerSmall from './AnswerSmall'
import Assessment from './Assessment'
import assessmentsDetails from './AssessmentsDetails.json'
import { Recover as IRecover } from '../../api/v2/recover/recover.types'
import useStore from '../../store/useStore'
import { IComponentStore } from '../../store/createComponentStore'
import moment from 'moment'
import allNewExercises from '../../modules/videos/allNewExercises.json'
import { useLocation } from 'react-router-dom'
import ScrollRow from '../../components/layout/ScrollRow'
import useScrollRow from '../../hooks/useScrollRow'
import NextSteps from './NextSteps'
import NextStepsData from './NextSteps.json'
import GenericModal from '../../components/misc/GenericModal'
import Motion from '../../components/animation/Motion'
import useMidfoot from '../midfoot-project/useMidfoot'
import { ITrainingStore } from '../../store/createTrainingStore'
import useVideoProgress from '../../hooks/useVideoProgress'
import useSlideDirection from '../../hooks/useSlideDirection'
import weeklyGuideText from './WeeklyInstructions.json'

const AslideInLeft = keyframes`${slideInLeft}`;
const AslideInRight = keyframes`${slideInRight}`;

const AslideOutLeft = keyframes`${slideOutLeft}`;
const AslideOutRight = keyframes`${slideOutRight}`;

const DivInLeft = styled.div` animation: 0.3s ${AslideInLeft};`;
const DivInRight = styled.div` animation: 0.3s ${AslideInRight};`;

interface ICurrentVideos {
    day: string,
    title: string,
    link: string
}

const DEFAULT_VIDEO: Video = {
    id: String(825035143),
    url: '/#/programs/runsmart-recover?videoId=' + 825035143,
    image: '\\img\\recover.jpg',
    category: 'Recover',
    headingTitle: '',
    headingSubtitle: 'Recovery Basics',
    detailsLine1: '',
    detailsLine2: '',
    headlines: '' || [],
    descriptions: [] || []
}

const CONFIRMATION = [
    {
        key: 1,
        value: "My daily activities are fine and without soreness",
        selected: false
    },
    {
        key: 2,
        value: "It has been at least three days since my race or event.",
        selected: false
    },
]

const SELECTOR_VALUES = ['Get Started', 'Assessments', 'Week 1', 'Week 2', 'Week 3', 'Next Steps']

interface IAssessment {
    assessmentId: number,
    areaId: number
}

const Recover: FC<AuthPageProps> = ({ userInfo }) => {

    const PROGRESS = 0 as number;

    const { setIsLoading, setLoadingMsg } = useStore((state: IComponentStore) => state)
    const { currentRecover, setCurrentRecover } = useStore((state: ITrainingStore) => state)

    const { videoId, type, redirect, week } = useQueryParams()
    const [isPlaying, setIsPlaying] = useState(false)
    const [todayVideo, setTodayVideo] = useState<ICurrentVideos | undefined>()
    const [showProgramView, setShowProgramView] = useState(false)
    const [currentSelectorValue, setCurrentSelectorValue] = useState<number>(0)
    const [currentWeekData, setCurrentWeekData] = useState<any | undefined>()
    const [currentWorkoutVideo, setCurrentWorkoutVideo] = useState<Video>(DEFAULT_VIDEO)
    const [weekVideo, setWeekVideo] = useState<Video>(DEFAULT_VIDEO)
    const [showPlayer, setShowPlayer] = useState(false)
    const [isLoadingMark, setIsLoadingMark] = useState(false)
    const [showAssessment, setShowAssessment] = useState(false)
    const [selector, setSelector] = useState(SELECTOR_VALUES)
    const [isFavorite, setIsFavorite] = useState(false)
    const [currentProgress, setCurrentProgress] = useState(0)
    const [selectedAssessment, setSelectedAssessment] = useState<IAssessment[]>([])
    const [assessmentValues, setAssessmentValues] = useState<any>()
    const [currentMobility, setCurrentMobility] = useState<any>()
    const [currentStrength, setCurrentStrength] = useState<any>()
    const [hasLoaded, setHasLoaded] = useState(false)
    const [showConfirmRecoverModal, setShowConfirmRecoverModal] = useState(false)
    const [recoveryVideos, setRecoveryVideos] = useState<Video[]>([])

    const { getCurrentWeek } = useMidfoot()

    const url = useLocation()

    const { isScrolling, setIsScrolling } = useScrollRow()

    const [confirmation, setConfirmation] = useState(CONFIRMATION)

    const { favorites, toggleFavorite, isLoading, getFavorites, getIsFavorited } = useFavorites(userInfo?.account_id as number, undefined, 'others', true)

    const isMobile = useIsMobileScreen()

    const slideDirection = useSlideDirection();

    const info = useVideoProgress({ videos: recoveryVideos as Video[], userInfo, autoReload: true, realoadTriggeer: videoId })

    const [vimeoData, setVimeoData] = useState<any>()

    // const TEMP_DAYS = 8;

    const getVideoDuration = async (videoId: string) => {

        const response = await fetch(
            `https://vimeo.com/api/oembed.json?url=https://player.vimeo.com/video/${videoId}`
        );
        const data = await response.json();
        if (data) {
            const duration = Math.round(data?.duration / 60)
            setVimeoData(`${duration} ${duration <= 1 ? 'minute' : 'minutes'}`)
        }
    }

    const onLike = async (videoId: string) => {
        const isLiked = favorites && favorites.find((x: any) => x.id === videoId) ? true : false;
        await toggleFavorite(userInfo?.account_id as number, Number(videoId), !isLiked)
    }

    const parseExerciseVideo = (c: any, val: any) => {

        const vimeoId = assessmentsDetails.filter((x) => x.id == val.id)[0].pieces.find((x: any) => x.id == c.areaId)?.vimeo_id
        const e = allNewExercises.find(x => x.video_id == Number(vimeoId))

        const data: Video = {
            id: String(e?.video_id),
            url: '/#/exercises/' + e?.video_id + '?redirect=recover',
            // image: e.featured_img,
            image: `https://vumbnail.com/${e?.video_id}.jpg`,
            category: e?.exercise_type as string,
            headingTitle: e?.exercise_title as string,
            headingSubtitle: e?.primary_region as string,
            detailsLine1: e?.tips_description as string,
            detailsLine2: '',
            headlines: [],
            descriptions: [e?.tips_description as string],
        }

        return data;
    }

    const parseExercisePiecesVideo = (vimeoId: string) => {

        const e = allNewExercises.find(x => x.video_id == Number(vimeoId))

        const data: Video = {
            id: String(e?.video_id),
            url: '/#/exercises/' + e?.video_id + '?redirect=recover',
            // image: e.featured_img,
            image: `https://vumbnail.com/${e?.video_id}.jpg`,
            category: e?.exercise_type as string,
            headingTitle: e?.exercise_title as string,
            headingSubtitle: e?.primary_region as string,
            detailsLine1: e?.tips_description as string,
            detailsLine2: '',
            headlines: [],
            descriptions: [e?.tips_description as string],
        }

        return data;
    }

    const getButtonText = () => {
        if (currentSelectorValue == 0)
            return 'Start Training'
        else if (currentSelectorValue == 1) {
            if (!showAssessment && !currentRecover)
                return 'Start Assessments'
            else if (!showAssessment && currentRecover)
                return 'Re-take Assessments'
            else {
                if (currentProgress != 7)
                    return 'Continue'
                else
                    return 'Submit Assessment'
            }
        }
        else
            return ''
    }

    const handleSubmitAssessment = async () => {
        const data: IRecover = {
            user_id: userInfo?.account_id as number,
            mobility_assessment: JSON.stringify(selectedAssessment.filter(x => x.assessmentId <= 4)),
            strength_assessment: JSON.stringify(selectedAssessment.filter(x => x.assessmentId >= 5)),
            status: 1,
        }

        setLoadingMsg(`Please wait while we customize your training.`)
        setIsLoading(true)

        if (currentRecover)
            await putReq('/v2/recover', { ...currentRecover, ...data }).then(async () => {
                await getCurrentRecover()
                setSelectedAssessment([])
                // navReq('/#/programs/runsmart-recover')
                setCurrentSelectorValue(2)
                setIsLoading(false)
                setLoadingMsg(undefined)
                localStorage.setItem('assessmentStatus', 'active')
                localStorage.setItem('selectedAssessment', '[]')
                localStorage.setItem('previousActivityStatus', 'parsed')

            })
        else
            await postReq('/v2/recover', data).then(async () => {
                await getCurrentRecover()
                setSelectedAssessment([])
                // navReq('/#/programs/runsmart-recover')
                setCurrentSelectorValue(2)
                setIsLoading(false)
                setLoadingMsg(undefined)
                localStorage.setItem('assessmentStatus', 'active')
                localStorage.setItem('selectedAssessment', '[]')
                localStorage.setItem('previousActivityStatus', 'parsed')

            })
    }

    const handleSelectAssessmentArea = (assessmentId: number, areaId: number) => {

        const current = selectedAssessment.find(x => x.assessmentId == assessmentId && x.areaId == areaId)

        if (current)
            // console.log(selectedAssessment.filter(x => x.assessmentId != current.assessmentId && x.areaId != current.assessmentId))
            setSelectedAssessment([...selectedAssessment.filter(x => x.areaId != current.areaId)])

        else
            setSelectedAssessment([...selectedAssessment, { assessmentId: assessmentId, areaId: areaId }])
    }

    const getVideoDetails = (vidId?: string) => {
        let video: typeof recoverVideos[0] | undefined;

        if (!redirect) {
            if (currentSelectorValue == 0) {
                video = recoverVideos.find(x => x.type === "pre-assessment" && x.vimeo_id === videoId)
            }
            else if (currentSelectorValue == 1)
                video = recoverVideos.find(x => x.type === "assessment" && x.vimeo_id === vidId)

            else if (currentSelectorValue >= 2)
                video = recoverVideos.find(x => x.vimeo_id === videoId)
        }
        else
            video = recoverVideos.find(x => x.vimeo_id === videoId)

        return { id: video?.id, videoId: video?.vimeo_id, title: video?.title }
    }

    const assignVideo = (videoId: string) => {
        const video: Video = {
            id: videoId,
            url: '/#/programs/runsmart-recover?videoId=' + videoId,
            // image: '\\img\\recover.jpg',
            image: `https://vumbnail.com/${videoId}.jpg`,
            category: 'Recover',
            headingTitle: '',
            headingSubtitle: getVideoDetails().title as string,
            detailsLine1: '',
            detailsLine2: '',
            headlines: '' || [],
            descriptions: [] || []
        }

        return video
    }

    const handleConfirmation = (i: number) => {
        // console.log(CONFIRMATION[i])
        CONFIRMATION[i].selected ? CONFIRMATION[i].selected = false : CONFIRMATION[i].selected = true
        setConfirmation([...CONFIRMATION])
    }

    const handleButtonClick = async () => {
        if (currentSelectorValue == 0)
            setCurrentSelectorValue(1)
        else if (currentSelectorValue == 1) {
            if (!isDisabled() && currentProgress == 0) {
                localStorage.setItem('assessmentStatus', 'started')
                localStorage.setItem('previousActivityStatus', 'parsed')
                localStorage.setItem('recoverStatus', 'unrecovered')
                setCurrentProgress(1)
                setShowAssessment(true)
            }
            else if (!isDisabled() && currentProgress > 0 && showAssessment) {
                if (currentProgress < assessmentsDetails.length) {
                    localStorage.setItem('selectedAssessment', JSON.stringify(selectedAssessment))
                    setCurrentProgress((prev) => prev + 1)
                }
                else if (currentProgress == 7) {
                    handleSubmitAssessment()
                }
            }
            else
                setShowAssessment(false)
        }
    }

    const handleMarkAsFavorite = async (isChecked: boolean) => {
        if (currentWorkoutVideo) {
            setIsLoadingMark(true)
            await toggleFavorite(userInfo?.account_id as number, Number(currentWorkoutVideo.id), isChecked)
            await getFavorites()
            setIsLoadingMark(false)
        }
    }

    const handleGenerateVideos = () => {
        const vids: Video[] = []
        recoverVideos.map((video) => {
            vids.push(assignVideo(video.vimeo_id))
        })
        setRecoveryVideos(vids)
    }

    const handleOnPlay = async () => {
        const playHistory = {
            videoId,
            currentSelectorValue
        }
        localStorage.setItem('playHistory', JSON.stringify(playHistory))
        localStorage.setItem('previousActivityStatus', 'unparsed')

        setIsPlaying(true)
    }

    const isDisabled = () => {

        const isAssessmentStarted = localStorage.getItem('assessmentStatus') === "started" ? true : false

        if (currentSelectorValue == 1) {
            let conf = CONFIRMATION
            conf = conf.filter(x => !x.selected)
            if (isAssessmentStarted)
                return false
            else if (conf.length > 0)
                return true
            else
                return false
        }
        else
            return false
    }

    const getCurrentRecover = async () => {
        // setIsLoading(true)
        const recover = await getReq('/v2/recover/user?user_id=' + userInfo?.account_id)
        setCurrentRecover(recover?.data?.result?.[0])
        // setIsLoading(false)
    }

    const getVideoLink = (link: string, redirect?: string) => {
        if (link && link?.includes('exercises')) {
            return link;
        }
        else if (redirect) {
            return `/#/programs/runsmart-recover?type=${isMobile ? 'mobile' : 'full'}&videoId=${link}&redirect=1`;
        }
        else {
            return `/#/programs/runsmart-recover?type=${isMobile ? 'mobile' : 'full'}&videoId=${link}`;
        }
    }

    const handleOnchangeSelector = (index: number) => {
        setCurrentSelectorValue(index)
    }

    const getAssessmentTypes = () => {
        const mainAssessment: any = [];

        if (currentRecover) {
            const mobilityAssessments = JSON.parse(currentRecover.mobility_assessment as string)
            const strengthAssessments = JSON.parse(currentRecover.strength_assessment as string)

            mobilityAssessments.forEach((e: any) => {
                const assessment = assessmentsDetails.find(x => x.id == e.assessmentId)
                mainAssessment.push({ id: assessment?.id, title: assessment?.assessment, type: assessment?.type })
            });

            strengthAssessments.forEach((e: any) => {
                const assessment = assessmentsDetails.find(x => x.id == e.assessmentId)
                mainAssessment.push({ id: assessment?.id, title: assessment?.assessment, type: assessment?.type })
            });
        }

        const uniqueValues = mainAssessment.filter((a: any, i: any) => mainAssessment.findIndex((s: any) => a.title === s.title) === i)

        setAssessmentValues(uniqueValues)

    }

    const handleImRecovered = async () => {
        // console.log(currentRecover)
        setShowConfirmRecoverModal(false)
        setLoadingMsg('Please wait while we reset your training.')
        setIsLoading(true)
        await putReq('/v2/recover/done', currentRecover)
        localStorage.setItem('recoverStatus', "recovered")
        localStorage.setItem('assessmentStatus', '')
        localStorage.setItem('playHistory', '')
        setCurrentRecover(undefined)
        setLoadingMsg(undefined)
        setIsLoading(false)
        setCurrentSelectorValue(5)
    }

    const handleResetAssessment = () => {
        const isAssessmentStarted = localStorage.getItem('assessmentStatus') === "started" ? true : false
        const currentProgress = Number(localStorage.getItem('currentProgress')) || undefined
        setIsPlaying(false)
        setShowAssessment(isAssessmentStarted ? true : false)
        setIsLoading(false)
        setCurrentProgress(isAssessmentStarted && currentProgress ? currentProgress : 0)
        // navReq('/#/programs/runsmart-recover')
    }

    const applyPreviousActivity = () => {

        let playHistory = localStorage.getItem('playHistory') as any

        const isAssessmentStarted = localStorage.getItem('assessmentStatus') === "started" ? true : false
        const isAssessmentActive = localStorage.getItem('assessmentStatus') === "active" ? true : false

        const currentProgress = Number(localStorage.getItem('currentProgress')) || undefined

        let selectedAssessment = localStorage.getItem('selectedAssessment') as any

        if (selectedAssessment)
            selectedAssessment = JSON.parse(selectedAssessment)
        else
            selectedAssessment = []

        if (!playHistory || playHistory === '') {
            playHistory = null;
        }
        else
            playHistory = JSON.parse(playHistory as string)

        if (playHistory && (!isAssessmentStarted && !isAssessmentActive)) {
            setCurrentSelectorValue(playHistory.currentSelectorValue)
            setCurrentWorkoutVideo(assignVideo(playHistory.videoId))
            window.location.assign(getVideoLink(playHistory?.videoId as string))
            localStorage.setItem('previousActivityStatus', 'parsed')
        }
        else if (isAssessmentStarted) {
            setCurrentSelectorValue(1)
            setSelectedAssessment(selectedAssessment)
            setCurrentProgress(currentProgress as number)
            CONFIRMATION.map((x, i) => {
                handleConfirmation(i)
            })

            setShowAssessment(true)

            localStorage.setItem('previousActivityStatus', 'parsed')
        }
    }

    const getIsSelectorDisabled = (index: number) => {
        let isRecovered: any = localStorage.getItem('recoverStatus')

        if (!isRecovered || isRecovered == null)
            isRecovered = "no-value"
        else {
            isRecovered = isRecovered === "recovered" ? true : false
        }

        let curWeek = getCurrentWeek(moment(currentRecover?.date_started).toDate()) + 1
        // let curWeek = getCurrentWeek(moment(currentRecover?.date_started).subtract(TEMP_DAYS, 'days').toDate()) + 1
        curWeek = curWeek == 1 ? curWeek + 1 : curWeek > 5 ? 5 : curWeek;
        if (index == 5 && isRecovered == "no-value" || index == 5 && !currentRecover && !isRecovered) {
            return true
        }
        else if (currentRecover) {
            if ((index >= 2 && index <= 4) && curWeek != index) {
                return true
            }
        }
        else if (!currentRecover && index >= 2 && index <= 4) {
            return true
        }
        else
            return false
    }

    const handleShowConfirmRecoverModal = () => {
        let curWeek = getCurrentWeek(moment(currentRecover?.date_started).toDate()) + 1
        // let curWeek = getCurrentWeek(moment(currentRecover?.date_started).subtract(TEMP_DAYS, 'days').toDate()) + 1
        curWeek = curWeek == 1 ? curWeek + 1 : curWeek > 5 ? 5 : curWeek;

        const isRecovered = localStorage.getItem('recoverStatus') === "recovered" ? true : false
        if (isRecovered) {
            setCurrentSelectorValue(5)
            setShowConfirmRecoverModal(!showConfirmRecoverModal)
        }
        else {
            setCurrentSelectorValue(curWeek)
            setShowConfirmRecoverModal(!showConfirmRecoverModal)
        }
    }

    useEffect(() => {
        !isMobile ?
            window.location.assign(getVideoLink(currentWeekData ? currentWeekData?.training_video : DEFAULT_VIDEO.id))
            : undefined

        // setCurrentWorkoutVideo(DEFAULT_VIDEO)
    }, [currentWeekData])

    useEffect(() => {
    	localStorage.setItem('page', 'pw')
        setTimeout(() => {
            setHasLoaded(true)
        }, 1000)
    }, [])

    useEffect(() => {
        handleGenerateVideos()
    }, [recoverVideos])

    useEffect(() => {
        const isFavorite = favorites.find(x => x.id === videoId)
        if (isFavorite)
            setIsFavorite(true)
        else
            setIsFavorite(false)

    }, [favorites, videoId])

    useEffect(() => {
        setIsPlaying(false)
        type === "mobile" && videoId && currentSelectorValue < 1 ? setShowProgramView(true) : setShowProgramView(false)

        if (!type && !videoId) {
            setCurrentWorkoutVideo(DEFAULT_VIDEO)
        }
        else {
            if (currentSelectorValue != 1) {
                setCurrentWorkoutVideo(assignVideo(videoId))
            }
            else {
                const recoverVideo = recoverVideos.find(x => x.id == (currentProgress + 6))
                const video: Video = {
                    id: recoverVideo?.vimeo_id as string,
                    url: '/#/programs/runsmart-recover?videoId=' + recoverVideo?.vimeo_id,
                    image: `https://vumbnail.com/${recoverVideo?.vimeo_id}.jpg`,
                    category: 'Recover',
                    headingTitle: 'Assessments',
                    headingSubtitle: getVideoDetails(recoverVideo?.vimeo_id).title as string,
                    detailsLine1: assessmentsDetails[currentProgress - 1]?.type,
                    detailsLine2: '',
                    headlines: '' || [],
                    descriptions: [] || []
                }
                setCurrentWorkoutVideo(video)
            }
        }


    }, [videoId, type, currentSelectorValue])


    useEffect(() => {
        if (videoId)
            getVideoDuration(videoId)
    }, [videoId])

    useEffect(() => {
        localStorage.setItem('previousActivityStatus', 'unparsed')
    }, [videoId, currentSelectorValue])


    useEffect(() => {
        setIsPlaying(false)
        if (currentProgress > 0) {
            localStorage.setItem('currentProgress', String(currentProgress))
            const recoverVideo = recoverVideos.find(x => x.id == (currentProgress + 6))
            window.location.assign(getVideoLink(recoverVideo?.vimeo_id as string))
        }
    }, [currentProgress])

    useEffect(() => {
        if (userInfo?.account_id && !currentRecover) {
            getCurrentRecover()
        }
    }, [userInfo?.account_id, currentRecover])


    useEffect(() => {
        if (currentRecover) {
            getAssessmentTypes()
            setCurrentStrength(JSON.parse(currentRecover.strength_assessment))
            setCurrentMobility(JSON.parse(currentRecover.mobility_assessment))

            let curWeek = getCurrentWeek(moment(currentRecover.date_started).toDate()) + 1
            // let curWeek = getCurrentWeek(moment(currentRecover.date_started).subtract(TEMP_DAYS, 'days').toDate()) + 1
            curWeek = curWeek == 1 ? curWeek + 1 : curWeek > 5 ? 5 : curWeek;
            if (!redirect)
                setCurrentSelectorValue(curWeek);
        }
    }, [currentRecover, redirect])

    useEffect(() => {

        const isRecovered = localStorage.getItem('recoverStatus') === "recovered" ? true : false

        if (!redirect)
            handleResetAssessment()

        if (currentSelectorValue == 5 && !isRecovered) {
            setShowConfirmRecoverModal(true)
        }
        else if (currentSelectorValue == 0 && redirect) {
            window.location.assign(getVideoLink(videoId as string, redirect))
        }
        else if (currentSelectorValue == 0 && !redirect) {
            const recoverVideo = recoverVideos[0]
            window.location.assign(getVideoLink(recoverVideo?.vimeo_id as string))
        }
        else if (currentSelectorValue == 2) {
            const recoverVideo = recoverVideos[13]
            window.location.assign(getVideoLink(recoverVideo?.vimeo_id as string))
        }
        else if (currentSelectorValue == 5) {
            const recoverVideo = recoverVideos[14]
            window.location.assign(getVideoLink(recoverVideo?.vimeo_id as string))
        }
        else {
            const recoverVideo = recoverVideos[currentSelectorValue]
            window.location.assign(getVideoLink(recoverVideo?.vimeo_id as string))
        }

    }, [currentSelectorValue, redirect])

    useEffect(() => {
        if (!redirect) {
            applyPreviousActivity()
        }
    }, [url.pathname, redirect]);


    return (
    	<Motion
				isVisible={true}
				isMobile={isMobile}
				direction={slideDirection.current}
			>
        <AppMenus hasContainer>
            <GenericModal
                type='recover'
                setShow={handleShowConfirmRecoverModal}
                show={showConfirmRecoverModal}
                title='Confirm Recovery'
                text='Ready to continue your running journey? Use the button below to confirm your recovery.'
                subtext={"Continue working through any imbalances that persist as you enter the next stage of training."}
                button1Text="I'm Recovered"
                button1Action={handleImRecovered}
            />
            <BodyComponent>
                <div className=''>
                    <Row className='d-none d-md-block mx-md-n4 mb-n0'>
                        <Col md={1}>
                            <span className="has-pointer back-btn w-auto pt-1" onClick={() => navReq("/#/programs?program_id=19")}>
                                <ArrowBack />
                            </span>
                        </Col>
                        <Col md={3}>
                            <h3 style={{ cursor: 'pointer', marginTop: '5px' }} onClick={() => navReq("/#/programs?program_id=19")} className='mx-5'>RunSmart Recover</h3>
                        </Col>
                    </Row>

                    <Row className=''>
                        <Col className={`d-none d-md-block`} xs={12} sm={12} md={12} lg={7} xl={8} xxl={8}>
                            <Row className="get-started-training-plan justify-content-center mb-md-2 mb-3">
                                <Col className="px-0 px-sm-3">
                                    <div className="video-wrapper w-100 blue-bg">
                                        <MainCard
                                            hasPlayButton
                                            playButtonIsCentered
                                            type="responsiveCardLg"
                                            progress={info.find(x => x.videoId == Number(videoId))?.progress}
                                            progressPercent={info.find(x => x.videoId == Number(videoId))?.progress}
                                            bgImage={`https://vumbnail.com/${videoId}.jpg`}
                                            vimeoId={currentWorkoutVideo?.id}
                                            onLike={() => onLike(currentWorkoutVideo?.id as string)}
                                            onPlay={handleOnPlay}
                                            hasCheck
                                        />
                                        {!isPlaying ? (
                                            <div className="banner-info">

                                                <h3 className="text-white fw-normal text-capitalize">
                                                    {currentWorkoutVideo.headingTitle}
                                                </h3>
                                                <h4 className="text-white fw-normal text-capitalize">
                                                    {currentWorkoutVideo.headingSubtitle}
                                                </h4>
                                                <h6 className='text-white'>{vimeoData}</h6>
                                            </div>
                                        ) : null}

                                        {!isPlaying && (info && info.length > 0) ? (
                                            <div className="tick-mark">
                                                <img
                                                    src={`${info.find(x => x.videoId == Number(videoId))?.progress as number >= 80 ? './images/icons/video-header-check-mark_watched.svg' : './images/icons/video-header-check-mark_unwatched.svg'}`}
                                                    alt="Like"
                                                />
                                            </div>
                                        ) : undefined}

                                    </div>
                                </Col>
                            </Row>
                        </Col>

                        <Col style={{ position: isMobile ? 'absolute' : 'relative' }} className={`${showPlayer ? 'mt-0' : 'mt-0'}`} xs={12} sm={12} md={12} lg={5} xl={4} xxl={4}>
                            <Row className="weekly-container active-training-container">
                                <div className='d-block d-md-none scroll-container-full'>
                                    <div className='pill-selector-container mt-md-2 mt-0 mb-4 mx-n4'>
                                        {
                                            SELECTOR_VALUES.map((value, index) => (
                                                <button disabled={getIsSelectorDisabled(index)} onClick={() => {
                                                    setCurrentSelectorValue(index)
                                                }} key={index} className={`squared-pill-sized ${currentSelectorValue == index ? 'active-pill' : getIsSelectorDisabled(index) ? 'disabled-pill' : ''}`}>
                                                    {value}
                                                </button>
                                            ))
                                        }
                                    </div>
                                </div>
                                {/* )} */}
                                <div className='d-none d-md-block'>
                                    <ScrollRow setIsScrolling={setIsScrolling}>
                                        <div className='pill-selector-container mt-md-2 mt-3 mx-n4'>
                                            {
                                                SELECTOR_VALUES.map((value, index) => (
                                                    <button disabled={getIsSelectorDisabled(index)} onClick={() => setCurrentSelectorValue(index)} key={index} className={`squared-pill-sized ${currentSelectorValue == index ? 'active-pill' : getIsSelectorDisabled(index) ? 'disabled-pill' : ''}`}>
                                                        {value}
                                                    </button>
                                                ))
                                            }
                                        </div>
                                    </ScrollRow>
                                </div>

                                {showAssessment && currentSelectorValue == 1 || currentSelectorValue != 1 ? (
                                    <div className='d-block d-md-none mt-n3 mb-n4'>
                                        <ProgramMobileView
                                            onLike={onLike}
                                            programType={'Recover'}
                                            title={currentWorkoutVideo.headingSubtitle}
                                            currentVideo={currentWorkoutVideo}
                                            handleMarkAsFavorite={handleMarkAsFavorite}
                                            handleOnPlay={handleOnPlay}
                                            isPlaying={isPlaying}
                                            isFavorite={isFavorite}
                                            isLoadingMark={isLoadingMark}
                                            subTitle={currentWorkoutVideo.detailsLine1}
                                            currentWorkoutVideoProgressInfo={info?.find(x => x.videoId == Number(videoId))}
                                            duration={vimeoData}
                                        />
                                    </div>
                                ) : undefined}

                                {currentSelectorValue == 0 ? (
                                    <>
                                        {/* {
                                                type == 'mobile' && showProgramView ? undefined : (
                                                    <div className='text-center px-4 px-md-2 mt-2 d-block d-md-none'>
                                                        <p className='mb-md-n2'>Watch the videos below to learn how to complete the assessments and work your recovery program.</p>
                                                    </div>
                                                )
                                            } */}

                                        <div className='text-center px-4 px-md-2 mt-0 d-none d-md-block'>
                                            <p className='mb-md-n1'>Watch the videos below to learn how to complete the assessments and work your recovery program.</p>
                                        </div>

                                        <Col lg={12} sm={12} className='mt-4 mb-5 md-mb-0'>
                                            {
                                                recoverVideos.filter(x => x.type === "pre-assessment").map((video) => (
                                                    <div key={video.id} className="justify-content-center phone-video-list mb-2 mt-n2">
                                                        <MainCard
                                                            videoId={video.vimeo_id}
                                                            key={video.vimeo_id}
                                                            cardType="video"
                                                            // type="videoLgStretch"
                                                            hasCheck={true}
                                                            progress={info?.find(x => x.videoId == Number(video.vimeo_id))?.progress}
                                                            isComplete={info && info.length >= 0 && info?.find(x => x?.videoId == Number(video.vimeo_id))?.progress as number >= 80}
                                                            logoImage={`https://vumbnail.com/${video.vimeo_id}.jpg`}
                                                            bgImage={`https://vumbnail.com/${video.vimeo_id}.jpg`}
                                                            // logoImage={DEFAULT_VIDEO.image}
                                                            // bgImage={DEFAULT_VIDEO.image}
                                                            detailTitle={''}
                                                            detailSubtitle={video.title}
                                                            link={getVideoLink(video.vimeo_id)}
                                                            hasLikeButton={true}
                                                            isProgram={false}
                                                            onLike={() => onLike(video.vimeo_id)}
                                                            isSelected={video.vimeo_id == videoId}
                                                            favorites={favorites}
                                                        />
                                                    </div>
                                                ))
                                            }
                                        </Col>
                                    </>
                                ) : currentSelectorValue == 1 ? (
                                    <>
                                        {!showAssessment ? (
                                            <div className='scroll-view'>
                                                {!currentRecover ? (
                                                    <>
                                                        <div className='px-4 px-md-2 mt-4 text-center text-md-start'>
                                                            <p className='mb-md-n1'>To customize your training, we will assess your mobility and strength in several key areas and test your readiness for this training.</p>
                                                        </div>

                                                        <div className='px-4 px-md-2 mt-1 mt-md-4 text-center text-md-start'>
                                                            <p className='mb-md-n1'>For each section, watch the video, then select any areas you struggle with.</p>
                                                        </div>
                                                    </>
                                                ) : (
                                                    <>
                                                        <div className='px-4 px-md-2 mt-4 text-center text-md-start'>
                                                            <p className='mb-md-n1'>Your assessment was completed {moment(currentRecover.date_started).format('MM/D/Y')}. Your recovery plan will focus on your {assessmentValues?.map((val: any) => val.title + ", ")}. </p>
                                                        </div>

                                                        <div className='px-4 px-md-2 mt-1 mt-md-4 text-center text-md-start'>
                                                            <p className='mb-md-n1'>You may repeat the assessment anytime.</p>
                                                        </div>
                                                    </>
                                                )}


                                                <div className='px-4 px-md-2 mt-1 mt-md-4 text-center text-md-start'>
                                                    <p className='mb-md-n1'>Acknowledge the questions below to begin.</p>
                                                </div>
                                                <div className='mt-1 mt-md-2 px-4 px-md-2'>
                                                    {confirmation.map((c, i) => (
                                                        <div className='mt-3 confirmation-container' key={i}>
                                                            <AnswerSmall selected={c.selected} option={'Yes'} setAnswer={() => handleConfirmation(i)} />
                                                            <p className='mb-n1'>{c.value}</p>
                                                        </div>
                                                    ))}
                                                </div>
                                            </div>
                                        ) :
                                            <>
                                                <Assessment navigate={setCurrentProgress} assesment={selectedAssessment} currentProgress={currentProgress} selectArea={handleSelectAssessmentArea} />
                                            </>
                                        }

                                    </>
                                ) : currentSelectorValue >= 2 ? (
                                    <div className='mb-5 mb-md-0'>

                                        <div className='px-2 px-md-2 mt-4 mb-4 text-center'>
                                            {currentSelectorValue != 5 ? (<p>Here’s what’s on tap for this week.</p>) : undefined}
                                        </div>

                                        <div>
                                            <Card className='px-3 pt-3 mb-3 no-border'>
                                                <span dangerouslySetInnerHTML={{
                                                    __html: `${weeklyGuideText[currentSelectorValue - 2].htmlText}`
                                                }} />
                                            </Card>
                                        </div>


                                        {/* <div className="justify-content-center phone-video-list mb-2 mt-md-2 px-0" >
                                            <MainCard
                                                videoId={currentWorkoutVideo.id}
                                                key={currentWorkoutVideo.id}
                                                cardType="video"
                                                // type="videoLgStretch"
                                                hasCheck={true}
                                                progress={info?.find(x => x.videoId == Number(currentWorkoutVideo.id))?.progress}
                                                isComplete={info && info.length >= 0 && info?.find(x => x?.videoId == Number(currentWorkoutVideo.id))?.progress as number >= 80}
                                                // logoImage={`https://vumbnail.com/${video.vimeo_id}.jpg`}
                                                // bgImage={`https://vumbnail.com/${video.vimeo_id}.jpg`}
                                                logoImage={currentWorkoutVideo.image}
                                                bgImage={currentWorkoutVideo.image}
                                                detailTitle={''}
                                                detailSubtitle={currentWorkoutVideo.headingSubtitle}
                                                // link={getVideoLink(currentWorkoutVideo.id)}
                                                hasLikeButton={true}
                                                onLike={() => onLike(currentWorkoutVideo.id)}
                                                isProgram={false}
                                                isSelected={currentWorkoutVideo.id == videoId}
                                                favorites={favorites}
                                            />
                                        </div> */}

                                        {
                                            currentSelectorValue != 5 ? (
                                                <div>
                                                    {currentMobility?.length > 0 ? (<h5 className='mx-2 mx-md-0 px-md-2 mb-2 mb-md-n3 mt-4'><b>Daily Exercises</b></h5>) : undefined}

                                                    {/* currentMobility.sort((a: any, b: any) => a.areaId - b.areaId).map((a: any) => {
                                                            assessmentsDetails.find((x) => x.id == a.assessmentId)?.pieces.find(x => x.id == a.areaId)
                                                        }) */}

                                                    <div className='d-block d-md-none'>
                                                        <ScrollRow noPadding setIsScrolling={setIsScrolling} >
                                                            {assessmentValues?.filter((x: any) => x.type === "mobility").map((value: any) => (
                                                                // <div>
                                                                <div className='mx-2 px-md-2 mt-1 mt-md-4' key={value.id}>
                                                                    <p className='title-container mb-0 mb-md-1' >{value.title}</p>
                                                                </div>
                                                                // </div>
                                                            ))}
                                                        </ScrollRow>
                                                        <div className='ms-n2'>
                                                            <ScrollRow setIsScrolling={setIsScrolling}>

                                                                {
                                                                    currentMobility && currentMobility.length > 0 && currentMobility?.sort((a: any, b: any) => a.areaId - b.areaId).map((a: any) => (
                                                                        <div key={a.areaId}>
                                                                            <MainCard
                                                                                videoId={assessmentsDetails.find((x) => x.id == a.assessmentId)?.pieces.find(x => x.id == a.areaId)?.vimeo_id}
                                                                                key={assessmentsDetails.find((x) => x.id == a.assessmentId)?.pieces.find(x => x.id == a.areaId)?.vimeo_id}
                                                                                hasCheck={true}
                                                                                progress={info?.find(x => x.videoId == Number(assessmentsDetails.find((x) => x.id == a.assessmentId)?.pieces.find(x => x.id == a.areaId)?.vimeo_id))?.progress}
                                                                                isComplete={info && info.length >= 0 && info?.find(x => x?.videoId == Number(assessmentsDetails.find((x) => x.id == a.assessmentId)?.pieces.find(x => x.id == a.areaId)?.vimeo_id))?.progress as number >= 80}
                                                                                isInline
                                                                                type="videoLg"
                                                                                bgImage={parseExercisePiecesVideo(assessmentsDetails.find((x) => x.id == a.assessmentId)?.pieces.find(x => x.id == a.areaId)?.vimeo_id as string).image}
                                                                                detailTitle={parseExercisePiecesVideo(assessmentsDetails.find((x) => x.id == a.assessmentId)?.pieces.find(x => x.id == a.areaId)?.vimeo_id as string).headingTitle}
                                                                                detailSubtitle={undefined}
                                                                                link={parseExercisePiecesVideo(assessmentsDetails.find((x) => x.id == a.assessmentId)?.pieces.find(x => x.id == a.areaId)?.vimeo_id as string).url}
                                                                                onLike={() => onLike(assessmentsDetails.find((x) => x.id == a.assessmentId)?.pieces.find(x => x.id == a.areaId)?.vimeo_id as string)}
                                                                                isScrolling={isScrolling}
                                                                                hasLikeButton={true}
                                                                                favorites={favorites}
                                                                            />
                                                                        </div>
                                                                    ))
                                                                }
                                                            </ScrollRow>
                                                        </div>
                                                    </div>

                                                    <div className='d-none d-md-block'>
                                                        {assessmentValues?.filter((x: any) => x.type === "mobility").map((value: any) => (
                                                            // <div>
                                                            <div className='mt-1 mt-md-4' key={value.id}>
                                                                <p className='title-container mb-2 mb-md-1' >{value.title}</p>
                                                                {
                                                                    // assessmentsDetails.filter((x) => x.id == value.id)[0]
                                                                    currentMobility?.filter((x: any) => x.assessmentId == assessmentsDetails.filter((x) => x.id == value.id)[0].id)
                                                                        .map((c: any) => (
                                                                            <div key={c.id} className="justify-content-center phone-video-list mb-n2">
                                                                                <MainCard
                                                                                    videoId={parseExerciseVideo(c, value).id}
                                                                                    cardType="video"
                                                                                    // type="videoLgStretch"
                                                                                    hasCheck={true}
                                                                                    progress={info?.find(x => x.videoId == Number(parseExerciseVideo(c, value).id))?.progress}
                                                                                    isComplete={info && info.length >= 0 && info?.find(x => x?.videoId == Number(parseExerciseVideo(c, value).id))?.progress as number >= 80}
                                                                                    logoImage={parseExerciseVideo(c, value).image}
                                                                                    bgImage={parseExerciseVideo(c, value).image}
                                                                                    detailTitle={parseExerciseVideo(c, value).headingTitle}
                                                                                    detailSubtitle={''}
                                                                                    link={parseExerciseVideo(c, value).url}
                                                                                    hasLikeButton={true}
                                                                                    onLike={() => onLike(parseExerciseVideo(c, value).id)}
                                                                                    favorites={favorites}
                                                                                    hasPlayButton={false}
                                                                                />
                                                                            </div>
                                                                        )
                                                                        )
                                                                }
                                                            </div>
                                                            // </div>
                                                        ))}
                                                    </div>


                                                    {currentStrength?.length > 0 ? (<h5 className={`px-3 px-md-2 mb-1 mb-md-n3 ${currentMobility && currentMobility.length > 0 ? 'mt-4' : 'mt-n5'}`}><b>Do 2-3x/week</b></h5>) : undefined}

                                                    <div className='d-block d-md-none'>
                                                        <ScrollRow noPadding setIsScrolling={setIsScrolling} >
                                                            {assessmentValues?.filter((x: any) => x.type === "strength").map((value: any) => (
                                                                // <div>
                                                                <div className='mx-2 px-md-2 mt-1 mt-md-4' key={value.id}>
                                                                    <p className='title-container mb-0 mb-md-1' >{value.title}</p>
                                                                </div>
                                                                // </div>
                                                            ))}
                                                        </ScrollRow>
                                                        <div className='ms-n2'>
                                                            <ScrollRow setIsScrolling={setIsScrolling}>

                                                                {
                                                                    currentStrength && currentStrength.length > 0 && currentStrength?.sort((a: any, b: any) => a.areaId - b.areaId).map((a: any) => (
                                                                        <div key={a.areaId}>
                                                                            <MainCard
                                                                                videoId={assessmentsDetails.find((x) => x.id == a.assessmentId)?.pieces.find(x => x.id == a.areaId)?.vimeo_id}
                                                                                key={assessmentsDetails.find((x) => x.id == a.assessmentId)?.pieces.find(x => x.id == a.areaId)?.vimeo_id}
                                                                                hasCheck={true}
                                                                                progress={info?.find(x => x.videoId == Number(assessmentsDetails.find((x) => x.id == a.assessmentId)?.pieces.find(x => x.id == a.areaId)?.vimeo_id))?.progress}
                                                                                isComplete={info && info.length >= 0 && info?.find(x => x?.videoId == Number(assessmentsDetails.find((x) => x.id == a.assessmentId)?.pieces.find(x => x.id == a.areaId)?.vimeo_id))?.progress as number >= 80}
                                                                                isInline
                                                                                type="videoLg"
                                                                                bgImage={parseExercisePiecesVideo(assessmentsDetails.find((x) => x.id == a.assessmentId)?.pieces.find(x => x.id == a.areaId)?.vimeo_id as string).image}
                                                                                detailTitle={parseExercisePiecesVideo(assessmentsDetails.find((x) => x.id == a.assessmentId)?.pieces.find(x => x.id == a.areaId)?.vimeo_id as string).headingTitle}
                                                                                detailSubtitle={undefined}
                                                                                link={parseExercisePiecesVideo(assessmentsDetails.find((x) => x.id == a.assessmentId)?.pieces.find(x => x.id == a.areaId)?.vimeo_id as string).url}
                                                                                onLike={() => onLike(assessmentsDetails.find((x) => x.id == a.assessmentId)?.pieces.find(x => x.id == a.areaId)?.vimeo_id as string)}
                                                                                isScrolling={isScrolling}
                                                                                hasLikeButton={true}
                                                                                favorites={favorites}
                                                                            />
                                                                        </div>
                                                                    ))
                                                                }
                                                            </ScrollRow>
                                                        </div>
                                                    </div>

                                                    <div className='d-none d-md-block'>
                                                        {assessmentValues?.filter((x: any) => x.type === "strength").map((value: any) => (
                                                            // <div>
                                                            <div className='mt-1 mt-md-4 mb-md-5' key={value.id}>
                                                                <p className='title-container mb-2 mb-md-1'>{value.title}</p>
                                                                {
                                                                    // assessmentsDetails.filter((x) => x.id == value.id)[0]
                                                                    currentStrength?.filter((x: any) => x.assessmentId == assessmentsDetails.filter((x) => x.id == value.id)[0].id)
                                                                        .map((c: any) => (
                                                                            <div key={c.id} className="justify-content-center phone-video-list mb-n2">
                                                                                <MainCard
                                                                                    videoId={parseExerciseVideo(c, value).id}
                                                                                    cardType="video"
                                                                                    // type="videoLgStretch"
                                                                                    hasCheck={true}
                                                                                    progress={info?.find(x => x.videoId == Number(parseExerciseVideo(c, value).id))?.progress}
                                                                                    isComplete={info && info.length >= 0 && info?.find(x => x?.videoId == Number(parseExerciseVideo(c, value).id))?.progress as number >= 80}
                                                                                    logoImage={parseExerciseVideo(c, value).image}
                                                                                    bgImage={parseExerciseVideo(c, value).image}
                                                                                    detailTitle={parseExerciseVideo(c, value).headingTitle}
                                                                                    detailSubtitle={''}
                                                                                    link={parseExerciseVideo(c, value).url}
                                                                                    hasLikeButton={true}
                                                                                    onLike={() => onLike(parseExerciseVideo(c, value).id)}
                                                                                    favorites={favorites}
                                                                                />
                                                                            </div>
                                                                        )
                                                                        )
                                                                }
                                                            </div>
                                                            // </div>
                                                        ))}
                                                    </div>
                                                </div>
                                            ) : (
                                                <>
                                                    <h4 className='mx-3 mb-n2 mb-md-0'>Where to now?</h4>
                                                    <div>
                                                        {NextStepsData.map(x => (
                                                            <NextSteps key={x.title} title={x.title} url={x.url} />
                                                        ))}
                                                    </div>
                                                </>
                                            )
                                        }


                                    </div>
                                ) : undefined
                                }
                                {currentSelectorValue >= 2 && currentSelectorValue < 5 ? (
                                    <div>
                                        <h4 className='text-center text-bigger mb-n2'>Feeling  better?</h4>
                                        <Button className={`d-block d-md-block bottom-button`} variant='none' onClick={() => handleShowConfirmRecoverModal()}>
                                            <span className='text-bigger'>{"I'm recovered."}</span>
                                        </Button>
                                    </div>
                                ) : undefined}
                                {
                                    currentSelectorValue == 0 ? (
                                        < Button className={`d-block d-md-none bottom-button-n-fixed`} variant='none' onClick={handleButtonClick}>
                                            <span className='text-white'>
                                                {getButtonText()}
                                            </span>
                                        </Button>
                                    ) : undefined
                                }

                            </Row>
                            {hasLoaded ? (
                                <>
                                    {currentSelectorValue >= 2 ? undefined : (
                                        <>
                                            <Button className={`d-none d-md-block bottom-button ${currentSelectorValue == 0 ? 'align-bottom' : ''} ${currentProgress == 7 ? 'submit-button' : ''} ${isDisabled() ? 'disabled-bot-button' : ''}`} variant='none' onClick={handleButtonClick}>
                                                <span className='text-white'>{getButtonText()}</span>
                                            </Button>

                                            {
                                                currentSelectorValue == 1 ?
                                                    (
                                                        < Button className={`d-block d-md-none bottom-button-sm ${currentProgress == 7 ? 'submit-button' : ''} ${isDisabled() ? 'disabled-bot-button' : ''}`} variant='none' onClick={handleButtonClick}>
                                                            <span className='text-white'>
                                                                {getButtonText()}
                                                            </span>
                                                        </Button>
                                                    ) : undefined
                                            }

                                        </>
                                    )}
                                </>
                            ) : undefined}

                        </Col>
                    </Row>
                </div>
            </BodyComponent >
        </AppMenus >
			</Motion>
    )
}

export default WithAuth(Recover)
