import { useEffect, useState } from 'react'
import { Button, Card } from 'react-bootstrap'
import CustomPopOver from '../../../components/custom-popover'
import { guideText } from '../../../modules/values'
import { ITrainingPlanStore } from '../../../store/createTrainingPlanStore'
import useStore from '../../../store/useStore'
import useBuildTrainingPlan from '../hooks/useBuildTrainingPlan'

const WeatherAlert = (props: any) => {
    const {
        dayData,
        getConvertedPace,
        userInfo,
    } = props

    const { getWTAValue, weatherAdjust, getWeatherData, getAdjustmentValue } = useBuildTrainingPlan();
    const { currentLocation, setShowWeatherFormModal } = useStore((state: ITrainingPlanStore) => state)
    const [closePopOver, setClosePopOver] = useState(false)
    const [isValid, setIsValid] = useState(false)

    const handleClosePopOver = () => {
        setClosePopOver(true)
        setShowWeatherFormModal(true)
        setTimeout(() => {
            setClosePopOver(false)
        }, 500)
    }

    useEffect(() => {
        const abortController = new AbortController();
        const adjustment = getAdjustmentValue() as number;
        if (!getWeatherData || getWeatherData?.length == 0 || adjustment <= 0 || getWTAValue() < 83)
            setIsValid(false)
        else if (getWeatherData && currentLocation && dayData?.marathonPaces.length > 0 && dayData?.marathonPaces[0].pace !== '') {
            setIsValid(true)
        }
        else
            setIsValid(false)

        return () => {
            abortController.abort();
        }


    }, [getWeatherData, dayData, currentLocation])

    return (
        <div className={`weather-alert mx-n4 mx-md-3 mt-3 mt-md-3 mb-n3 mb-md-3 ${!isValid ? 'd-none' : ''}`}>
            <div>
                <CustomPopOver
                    autoClose={closePopOver}
                    icon={
                        <h6 className="font-weight-semibold text-orange mb-0 pointer">
                            Weather Alert {' '}
                            <img
                                src="./images/icons/sun.png"
                                alt="Weather Alert"
                                className="weather-traning--icon ml-1"
                            />
                        </h6>
                    }
                >
                    <div className="popover-custom">
                        <h6 className="font-weight-bold">
                            Pace Adjustment for
                            <br />
                            <br />
                            <span className='text-primary font-weight-bold'>{getWeatherData?.data?.name?.toUpperCase()} , {getWeatherData?.data?.sys?.country?.toUpperCase()}</span>
                            {/* {currentLocation?.toString().toUpperCase()} */}
                        </h6>
                        <p className="font-15">
                            Maintaining the proper effort level is
                            important for smarter training. Below
                            you can alter your training paces based
                            on your current weather.
                        </p>

                        <Card
                            hidden={getWTAValue() < 93}
                            bg={'danger'}
                            text={'white'}
                            className="mb-2"
                            style={{ width: '90%', margin: "0 auto" }}
                        >
                            <Card.Body>
                                <Card.Text>
                                    {guideText.WeatherWarning}
                                </Card.Text>
                            </Card.Body>
                        </Card>

                        <p className="font-15">

                        </p>
                        <h6 className="text-primary font-weight-semibold">
                            Weather Training Factor (WTF): {getWTAValue()}/100
                        </h6>
                        {dayData?.marathonPaces?.filter((x: any) => x.pace !== '').map((val: any, idx: number) => (
                            <div key={idx} className="center-align my-4">
                                <p className="mb-0">{getConvertedPace(val).rawPace} / {userInfo?.km === "Y" ? 'KM' : "Mile"}</p>
                                <img
                                    className="mx-2 w-24 h-24"
                                    src="./images/icons/right-circle.png"
                                    alt="right circle"
                                />
                                <strong>{weatherAdjust(getConvertedPace(val).rawPace)} / {userInfo?.km === "Y" ? 'KM' : "Mile"}</strong>
                            </div>
                        ))}

                        {/* <div className="center-align pointer">
                            <strong onClick={() => setShowWeatherFormModal(true)} className="text-primary hover-link">
                                Change My Location
                            </strong> */}
                        {/* <img
																					className="w-24 h-24 ms-2"
																					src="./images/icons/edit-note.png"
																					alt="Edit"
																				/> */}
                        {/* <CustomPopOver
                                icon={
                                    <img
                                        className="w-24 h-24 ms-2"
                                        src="./images/icons/edit-note.png"
                                        alt="Edit"
                                    />
                                }
                            >
                                <div className="popover-custom weather-custom--popup">
                                    <Form>
																							<Form.Group controlId="formBasicEmail">
																								<Form.Control
																									type="text"
																									placeholder="Enter Zip or City Name & Country"
																								/>
																							</Form.Group>
																						</Form>
																						<div className="thickmark-icon">
																							<img
																								src="./images/icons/tick-mark.png"
																								alt="Tick Mark"
																							/>
																						</div>
                                    <Form className="edit-locations-modal">
                                        <Form.Group className="mt-4 position-relative">
                                            <Form.Control
                                                type="text"
                                                placeholder="Enter Zip or City Name Country"
                                            />
                                            <div className="edit-loations-pin pointer">
                                                <img
                                                    className="pointer"
                                                    src="./images/icons/edit-black.png"
                                                    alt="Edit"
                                                ></img>
                                            </div>
                                        </Form.Group>
                                    </Form>
                                </div>
                            </CustomPopOver> */}
                        {/* </div> */}

                        <Button
                            onClick={handleClosePopOver}
                            size="sm"
                            variant="dark"
                            className="rounded-pill color-primary-bg save-btn mt-4"
                        >
                            Change My Location
                        </Button>
                        <br />
                        {/* <Button
                            onClick={handleClosePopOver}
                            size="lg"
                            variant="dark"
                            className="rounded-pill color-primary-bg save-btn mt-4"
                        >
                            Close
                        </Button> */}
                    </div>
                </CustomPopOver>
            </div>
        </div>
    )
}

export default WeatherAlert