import Player from '@vimeo/player'
import { updateVideoProgressV2 } from '../modules/accountActions'

/**
 * Use Vimeo Player
 */

const useVimeoPlayerDefault = ({
    iframe,
    userId,
    playCastVideo,
    isCastActive,
    currentProgress
}: {
    iframe: HTMLIFrameElement
    userId: number,
    playCastVideo?: (id: any, progress: any) => any,
    isCastActive?: boolean,
    currentProgress?: number
}) => {
    // Player
    const player = new Player(iframe)

    // Properties
    let title = ''
    let id = 0
    let duration = 0
    let lastSent = 0
    let lastUpdate = 0
    let isFirstSync = true
    let progress: number[] = []
    let lastSyncProgress = '[]'

    // Init Object
    const initObject = async () => {
        const response = await Promise.all([
            player.getVideoId(),
            player.getVideoTitle(),
            player.getDuration(),
            player.ready(),
        ])
        lastSent = 0
        lastUpdate = 0
        isFirstSync = true
        progress = []
        lastSyncProgress = '[]'
        id = response[0] || 0
        title = response[1] || ''
        duration = Math.floor(response[2] || 0)
    }


    player.on('play', async () => {
        if (isCastActive) {
            playCastVideo ? playCastVideo(id, progress) : undefined
            await player.pause()
        }
    })
    // Init Data
    const initData = async () => {
        await initObject()

        player.on('loaded', async () => {
            if (currentProgress && (currentProgress > 10 && currentProgress < 90)) {
                const curProg = (currentProgress * duration) / 100
                await player?.pause()
                await player?.setCurrentTime(curProg)
                await player?.play()
            }
        })

        // player.on('pause', progressUpdate)
        player.on('progress', onProgress)
        player.on('seeked', onSeek)
        setInterval(progressUpdate, 3000)
    }

    // On Seek
    const onSeek = ({ seconds }: { seconds: number }) => {
        ; (window as any)?.webkit?.messageHandlers?.cordova_iab.postMessage(
            JSON.stringify({
                type: 'vimeoSeekEvent',
                id: id || 0,
                time: Number(seconds) || 0,
            })
        )
    }

    // On Progress
    const onProgress = (args: { percent: number; duration: number }) => {
        const { percent, duration: curDuration } = args
        const videoDuration = Math.floor(curDuration)
        const currentPercent = Math.min(Math.ceil(percent * 100), 100)
        if (!duration) duration = videoDuration
        if (!progress.includes(currentPercent)) {
            progress.push(currentPercent)
            lastUpdate = new Date().getTime()
        }
    }

    // Progress Update
    const progressUpdate = async () => {
        const curProgress = JSON.stringify(progress) || '[]'
        if (duration && lastSent != lastUpdate && curProgress != lastSyncProgress) {
            await updateVideoProgressV2({
                accountId: userId,
                videoId: id,
                title,
                progress,
                duration,
                isFirst: isFirstSync ? 1 : 0
            })
        }
        lastSyncProgress = curProgress
        lastSent = lastUpdate
        if (isFirstSync) isFirstSync = false
    }

    // Init
    initData()
}

export default useVimeoPlayerDefault