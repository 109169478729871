import { FC } from 'react'

import Grid from '@mui/material/Grid';

export type RSFlexCardProps = {
	videoList: Array<any>,
	sx?: object,
	className?: string
}


const RSFlexCard: FC<RSFlexCardProps> = ({ videoList, sx = {}, className=''}) => {
	const width = window.innerWidth > 900 ? 900 : window.innerWidth
	const videoPerRow = Math.floor(width / 400)
	const colSpace = 12 / (videoList?.length < videoPerRow ? 2 : videoPerRow)
	return videoList?.length ? <Grid container spacing={2} className={className} sx={{ py: '10px', mt: '0px', ...sx }}>
		{
			videoList.map((v: any, vi: number) => {
				return <Grid key={vi + '-vid-cont'} item xs={colSpace} sx={{ pt: '16px !important', position: 'relative', width: '100%' }}>
					{v}
				</Grid>
			})
		}
	</Grid> : <></>
}

export default RSFlexCard;
