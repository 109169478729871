/* eslint-disable @typescript-eslint/no-unused-vars */
import { date, strtotime } from "locutus/php/datetime";
import moment from "moment";
import { useState } from "react";
import { MarathonValRecord, UserNotesRecord } from "../api/v2/types";
import { GetTrainingPlanByUser } from "../modules/aiTrainingPlanActions";
// import { useEffect, useState } from "react";
import { GetAllUserNotes, GetMarathonValues, GetRaceResult, GetUserInfo, GetUserNotes, GetUserOverride, GetUserVdotChanges } from "../modules/trainingPlanActions";
import useGPTTrainingPlan from "../pages/race/useGPTTrainingPlan";
import { DAYS_OF_WEEK, DAYS_OF_WEEK_ALT } from "../pages/training-plan/build/DaysOptions";
import { RaceOptions } from "../pages/training-plan/build/RaceOptions";
import useBuildTrainingPlan from "../pages/training-plan/hooks/useBuildTrainingPlan";
// import { IRaceDetails } from "../pages/training-plan/types";
import { ICurrentUser } from "../store/createAccountStore";
import { ITrainingPlanStore } from "../store/createTrainingPlanStore";
import useStore from "../store/useStore";
import useTrainingPlanUtils from "./useTrainingPlanUtils";

export interface TrainingInfo {
    race_past: number;
    in_progress: number;
    percent_completed: number;
    days_before_race: number;
    type: string;
    workout_text: string;
    show: number;
    create_new: number;
    weeks_before_race: number;
    plan_length: number
    days_before_start: number
    race_date?: string
    plan_id?: number,
    pace?: string,
    paceDetails?: boolean,
    weeks?: number,
    currentWeek?: number,
    logo?: string,
    typeIndex?: number | undefined,
    plan_type?: string,
    training_start?: Date,
    event_name?: string,
    race_logo?: string
}

const useHomeTrainingPlan = (userNotes: any, userOverrides: any, userInfo: any, raceResult: any) => {
    const { currentUser } = useStore((state: ICurrentUser) => state)
    const { getRaceDetailsValues, getRaceDayDetails, getRaceDistance, getRaceDistancePace, getDailyActivityDisplay, handleAdjustPaceByLevel, extractPace, getOverridePace } = useBuildTrainingPlan()
    const { convertToLegacyTrainingInfo, buildProgressionWorkouts, buildTempoWorkouts, buildIntervalWorkouts, buildLastWeekRuns } = useGPTTrainingPlan()
    const { getWeekDays, getCurrentWeek, convertDateToUTC, getCurrentVDOT } = useTrainingPlanUtils()
    // const [allUserNotes, setAllUserNotes] = useState<Array<UserNotesRecord> | undefined>(undefined)
    const camelCase = (text: string) => {
        const arr = text.split(" ");
        for (let i = 0; i < arr.length; i++) {
            arr[i] = arr[i].charAt(0).toUpperCase() + arr[i].slice(1);
        }
        const result = arr.join(" ");
        return result;
    }

    const getOverridePaceData = async (userId: number, abort: AbortSignal) => {
        const op = await getOverridePace(userId, abort)
        return op?.data.result
    }

    const getUserNotes = async (marathonDayValue: any, abort: AbortSignal, userInfo: any) => {
        const userNotes = await GetUserNotes(userInfo?.uid, marathonDayValue?.marathon?.id, abort);
        if (userNotes?.data.messages === "success" && userNotes?.data.result.length > 0) {
            return userNotes?.data.result
        }
    }

    const buildMarathonPaces = (values: Array<any>) => {
        const paces: Array<any> = []
        values.forEach(value => {
            paces.push({ mar_id: value.paceMarId, paceId: value.paceId, pace: value.pace, notes: value.notes, orderid: value.paceOrderId })
        })

        const uniqueValues = paces.filter((a, i) => paces.findIndex((s) => a.paceId === s.paceId) === i)
        return uniqueValues;
    }

    const buildWorkouts = (values: Array<any>) => {
        const workouts: Array<any> = []
        values.forEach(value => {
            workouts.push({ mid: value.workoutMarId, workoutId: value.woId, title: value.title, description: value.description, link: value.link, orderid: value.wOrderid })
        })

        const uniqueValues = workouts.filter((a, i) => workouts.findIndex((s) => a.workoutId === s.workoutId) === i)
        return uniqueValues;
    }

    const getSelectedWeek = (weekDays: Array<any>, userInfo: any) => {
        const firstW = weekDays?.[0]?.replaceAll('-', '/')

        const isFuturePlan = !userInfo?.is_active_plan && moment().isSameOrBefore(moment(userInfo.start))
        let thisDay = Number(date("w"));
        const isBeforeStart = (userInfo?.start && moment(userInfo.start)?.add(1, 'day')?.isBetween(moment(firstW)?.subtract(7, 'd'), moment(firstW))) || isFuturePlan ? true : false
        const isAfterOrRaceDay = moment(new Date()).isSameOrAfter(moment(userInfo?.race_date))
        thisDay = isBeforeStart && moment().isBefore(moment(userInfo.start)) ? 0 : (moment().isBefore(moment(userInfo.start)) ? 0 : thisDay)

        let activeWeekNumber = isBeforeStart ? 1 : weekDays?.length
        const activeWeek = weekDays.find((wd: string, wdi: number) => {
            const checkWeek = weekDays?.[wdi + 1] ? moment().isBetween(moment(wd.replaceAll('-', '/')).subtract(1, 'd').format('YYYY/MM/DD'), weekDays?.[wdi + 1]?.replaceAll('-', '/')) : false
            activeWeekNumber = checkWeek ? wdi + 1 : activeWeekNumber
            return checkWeek
        })

        return { key: activeWeekNumber, value: activeWeek }
    }
    const getWeeklyActivitiesAI = async (userInfoTD: any, weekDays: any) => {
        const values: any = []

        const raceDay = userInfoTD?.training_plan?.find((x: any) => x.monthData?.dateVal == moment(userInfoTD?.race_date).format('YYYY-MM-D') || x.monthData?.dateVal == moment(userInfoTD?.race_date).format('YYYY-MM-DD'))

        if (raceDay) {
            raceDay.activitiesValue.marathon.distance = getRaceDistance(userInfoTD?.type as number)
            raceDay.activitiesValue.marathon.race_day = 1
            raceDay.activitiesValue.marathonPaces[0].pace = "Race Day"
            raceDay.activitiesValue.marathonPaces[0].notes = `${getRaceDistancePace(userInfoTD)} ${userInfoTD?.km === "Y" ? "kms" : "miles"}`
        }

        const afterRaceDay = userInfoTD?.training_plan?.filter((x: any) => x.activitiesValue.marathon.id > raceDay?.activitiesValue.marathon.id)

        const raceWeekRuns = userInfoTD?.training_plan?.filter((x: any) => x.activitiesValue.marathon.week == userInfoTD?.weeks && x.activitiesValue.marathon.race_day != 1 && x.activitiesValue.marathon.distance > 0)

        if (raceWeekRuns?.length)
            raceWeekRuns.forEach((x: any) => {
                const distace = x.activitiesValue.marathon.distance
                const runs = buildLastWeekRuns(distace, x.activitiesValue.marathonPaces, userInfoTD?.type, userInfoTD?.km)
                x.activitiesValue.marathonPaces = undefined
                x.activitiesValue.marathonPaces = runs
            })

        if (afterRaceDay?.length)
            afterRaceDay.forEach((x: any) => {
                if (x.activitiesValue.marathonPaces.length) {
                    x.activitiesValue.marathonPaces[0].pace = ""
                    x.activitiesValue.marathonPaces[0].notes = ""
                }

                if (x.activitiesValue.workouts.length)
                    x.activitiesValue.workouts = []

                x.activitiesValue.marathon.distance = 0
            })

        const progressions = userInfoTD?.training_plan?.filter((x: any) => x.activitiesValue.marathonPaces.find((y: any) => y.pace === "Progression"))

        const tempo = userInfoTD?.training_plan?.filter((x: any) => x.activitiesValue.marathonPaces.find((y: any) => y.pace === "Tempo"))

        const intervals = userInfoTD?.training_plan?.filter((x: any) => x.activitiesValue.marathonPaces.find((y: any) => y.pace === "Interval" || y.pace === "Track"))

        if (tempo?.length)
            tempo.forEach((x: any) => {
                const distace = x.activitiesValue.marathon.distance
                const workouts = buildTempoWorkouts(distace, x.activitiesValue.marathonPaces, userInfoTD?.km)
                x.activitiesValue.marathonPaces = undefined
                x.activitiesValue.marathonPaces = workouts
            })

        if (progressions?.length)
            progressions.forEach((x: any) => {
                const distace = x.activitiesValue.marathon.distance
                const workouts = buildProgressionWorkouts(distace, x.activitiesValue.marathonPaces, userInfoTD?.km)
                x.activitiesValue.marathonPaces = undefined
                x.activitiesValue.marathonPaces = workouts
            })

        if (intervals?.length)
            intervals.forEach((x: any) => {
                const distace = x.activitiesValue.marathon.distance
                const workouts = buildIntervalWorkouts(distace, x.activitiesValue.marathonPaces, userInfoTD?.km)
                x.activitiesValue.marathonPaces = undefined
                x.activitiesValue.marathonPaces = workouts
            })

        const weeklyData = userInfoTD?.training_plan?.filter((x: any) => x.week == getSelectedWeek(weekDays, userInfoTD)?.key)

        weeklyData?.forEach((x: any) => {
            values.push({ ...x.activitiesValue })
        })

        let newVal = {}
        for (const x of values) {
            if (x?.marathon?.day) {
                newVal = { ...newVal, [x.marathon.id]: x }
            }
        }

        return values
    }

    const removeTZ = (uiData: any) => {
        return uiData ? {
            ...uiData,
            start: uiData.start?.slice(0, uiData.start?.indexOf('T'))?.replaceAll('-', '/'),
            race_date: uiData.race_date?.slice(0, uiData?.race_date?.indexOf('T'))?.replaceAll('-', '/'),
            signup_date: uiData.signup_date?.slice(0, uiData?.signup_date?.indexOf('T'))?.replaceAll('-', '/')
        } : uiData
    }

    const getWeeklyActivities = async (signal: AbortSignal, userInfo: any, selectedWeek: any, allUserNotes: UserNotesRecord[]) => {
        const { type, weeks, distance, monday_start } = userInfo
        const details = getRaceDayDetails(userInfo)
        const saturdayRaceMondayStart = details.raceDay == 5 && details.saturdayRace;
        const saturdayRaceSundayStart = details.raceDay == 6 && !details.saturdayRace;
        let userNote;
        // console.log(details)

        const checkRaceWeek = () => {
            //sunday start - monday race
            if (details.raceDay == 1 && (+weeks + 1) == selectedWeek && type != 4)
                return true
            //sunday start - sunday race
            else if (details.raceDay == 0 && (+weeks + 1) == selectedWeek && type != 4)
                return true
            //sunday start - saturday race
            else if (saturdayRaceSundayStart && weeks == selectedWeek && type != 4)
                return true
            else if (saturdayRaceMondayStart && weeks == selectedWeek && type != 4) {
                return true;
            }
            else
                return false;
        }
        const isRaceWeek = checkRaceWeek();

        const values: Array<any> = []
        let marathons: Array<any> = []
        let paces: Array<any> = []
        let workouts: Array<any> = []

        const days = monday_start ? DAYS_OF_WEEK_ALT : DAYS_OF_WEEK;

        // console.log(selectedWeek)
        const params: MarathonValRecord = {
            marathon: type,
            type: weeks,
            training_goal: distance,
            week: selectedWeek,
            distance: distance,
            // day: +(index as unknown as number) + 1
        }

        await GetMarathonValues(params, signal).then(async (res) => {
            if (res && res?.data?.messages === "success") {
                marathons = res.data.result.marathon;
                paces = res.data.result.marathonPaces;
                workouts = res.data.result.workouts;
            }
        })

        if (!isRaceWeek)
            for (const [index, d] of Object.entries(days)) {
                const indexVal = +(index as unknown as number) + 1
                // const marathon = res.data.result.marathon.find((x: any) => x.day == indexVal)
                const marathon = marathons?.filter((x: any) => x.day == indexVal);

                if (allUserNotes && allUserNotes.length > 0 && marathon)
                    userNote = allUserNotes?.find(x => x?.marid == marathon[0]?.id)

                if (marathon) {
                    const data = {
                        marathon: {
                            id: marathon[0]?.id,
                            marathon: marathon[0]?.marathon,
                            type: marathon[0]?.type,
                            training_goal: marathon[0]?.training_goal,
                            week: marathon[0]?.week,
                            day: marathon[0]?.day,
                            distance: marathon[0]?.distance,
                            race_day: marathon[0]?.race_day,
                        },
                        marathonPaces: buildMarathonPaces(marathon),
                        workouts: buildWorkouts(marathon),
                        // overrides: buildOverrides(marathon)
                    }
                    values.push({ ...data })
                }
                else
                    values.push([])
            }
        else if (isRaceWeek)
            for (const [index, d] of Object.entries(days)) {
                const indexVal = +(index as unknown as number) + 2 - (saturdayRaceMondayStart ? 1 : 0)
                // const marathon = res.data.result.marathon.find((x: any) => x.day == indexVal)
                const marathon = marathons?.filter((x: any) => x.day == indexVal);
                if (allUserNotes && allUserNotes.length > 0 && marathon)
                    userNote = allUserNotes?.find(x => x.marid == marathon[0]?.id)

                if (marathon && +index + 1 != 7) {
                    const data = {
                        marathon: {
                            id: marathon[0]?.id,
                            marathon: marathon[0]?.marathon,
                            type: marathon[0]?.type,
                            training_goal: marathon[0]?.training_goal,
                            week: marathon[0]?.week,
                            day: marathon[0]?.day - (saturdayRaceMondayStart ? 0 : 1),
                            distance: marathon[0]?.distance,
                            race_day: marathon[0]?.race_day,
                        },
                        marathonPaces: buildMarathonPaces(marathon),
                        workouts: buildWorkouts(marathon),
                        // overrides: buildOverrides(marathon)
                    }
                    if (saturdayRaceMondayStart && data.marathon.day == 6) {
                        //
                    } else
                        // if (saturdayRaceSundayStart && data.marathon.day != 6) {
                        values.push({ ...data })
                    // }

                }
                else {
                    values.push({
                        marathon: {
                            id: 5000 + userInfo?.uid,
                            marathon: userInfo?.type,
                            type: userInfo?.weeks,
                            training_goal: userInfo?.distance,
                            week: selectedWeek,
                            day: details.raceDay == 1 ? 2 : details.raceDay == 0 ? 1 : saturdayRaceMondayStart ? 6 : 7,
                            distance: getRaceDistance(userInfo?.type as number),
                            race_day: 1,
                        },
                        marathonPaces: [{ mar_id: 5000 + userInfo?.uid, paceId: 9999 + userInfo?.uid, pace: 'Race Day', notes: `${getRaceDistancePace(userInfo)} ${userInfo?.km === "Y" ? "kms" : "miles"}`, orderid: userInfo?.type }],
                        workouts: []
                    })
                }
            }
        return values;
    }

    const getTrainingDetails = async (abortController: AbortController) => {
        let userInfoTD: any;
        let raceResult: any = undefined;

        if (currentUser?.account_id || currentUser?.vdot_id) {
            const request = await GetUserInfo(currentUser?.account_id as number, abortController.signal)
            const aiTrainingPlan = await GetTrainingPlanByUser(currentUser?.account_id as number, abortController.signal)
            if (aiTrainingPlan?.data?.result?.length) {
                aiTrainingPlan?.data?.result?.forEach((plan: any) => {
                    const legacyPlan = convertToLegacyTrainingInfo(plan)
                    request.data.result.push(legacyPlan)
                })
            }

            // if (aiPlans.length)
            //     request.data.result = [...request.data.result, ...aiPlans]
            if (request === undefined) return undefined
            let res = request?.data?.result?.filter((x: any) => x.status == 1 && x.is_active_plan == true)
            res = res?.sort((a: any, b: any) => {
                if (moment(a.start) > moment(b.start))
                    return -1
                else if (moment(a.start) < moment(b.start))
                    return 1
                else {
                    if (moment(a.race_date) > moment(b.race_date))
                        return -1
                    else if (moment(a.race_date) < moment(b.race_date))
                        return 1
                    else
                        return 0
                }

            })

            //get future plans that is not yet active/started
            if (res?.length == 0) {
                res = request?.data?.result?.filter((x: any) => x.status == 1 && x.is_active_plan == false && moment(x.start).isAfter(moment(new Date())))
            }

            let finalData: any = ''
            if (res?.length) {
                let jData = res[res?.length - 1]
                if (jData?.status) {
                    await GetUserVdotChanges(jData.uid as number, abortController.signal).then((res: any) => {
                        jData = { ...jData, vDotAdjustment: res?.data?.result }
                    })
                    await GetRaceResult(jData.internal_id as number, jData.uid as number, abortController.signal, jData.type as number).then((res: any) => {
                        jData = { ...jData, result: res?.data?.result }
                    })
                }
                finalData = removeTZ(jData)
            }

            userInfoTD = finalData || undefined
            if (!userInfoTD && request && request?.data?.result.length == 1)
                userInfoTD = request.data.result[0]
        }
        else {
            userInfoTD = undefined
        }

        raceResult = userInfoTD?.result ? userInfoTD?.result : raceResult
        userInfoTD = userInfoTD?.km && userInfo?.km ? { ...userInfoTD, km: userInfo.km } : userInfoTD
        if (userInfoTD) {
            // setUserInfo(userInfoTD)
            // const res = await GetRaceResult(userInfoTD?.internal_id, userInfoTD?.uid, abortController.signal, userInfoTD?.type)
            // if (res && res.data.result)
            if (moment(userInfoTD.race_date).isBefore(moment(new Date())))
                raceResult = userInfoTD?.result ? userInfoTD?.result : undefined
        }

        if (raceResult) {
            const weekTruncateCount = userInfoTD?.weeks - (raceResult?.recoveryPlan?.recoveryType?.recovery_length_in_weeks);
            userInfoTD = { ...userInfoTD, race_date: moment(userInfoTD?.race_date).subtract(weekTruncateCount, 'weeks').format('YYYY/MM/DD') }
        }

        let trainingInfo: TrainingInfo | undefined;
        let dayData: any;

        if (userInfoTD?.status) {
            const rec = raceResult?.recoveryPlan
            let thisDay = Number(date("w"));
            thisDay = userInfoTD.monday_start == 1 ? (thisDay > 0 ? thisDay - 1 : 6) : Number(date("w"));
            const weekDays = getWeekDays(new Date(), userInfoTD.start, userInfoTD.race_date, userInfoTD.monday_start ? DAYS_OF_WEEK_ALT : DAYS_OF_WEEK);
            let thisWeek = getCurrentWeek(userInfoTD.start, userInfoTD.monday_start) + (weekDays.length > userInfoTD?.weeks && userInfoTD?.monday_start ? 0 : 1)
            thisWeek = thisDay == 6 && (weekDays.length == userInfoTD?.weeks) && !userInfoTD?.monday_start ? thisWeek - 1 : thisWeek

            // const allUserNotes = await getAllUserNotes(userInfoTD, abortController.signal)
            const allUserNotes = userNotes?.data?.result

            // const userOverrides = await getUserOverrides(abortController.signal, userInfoTD)
            const userOverridesData = userOverrides

            thisWeek = weekDays.length > userInfoTD?.weeks ? thisWeek + 1 : thisWeek
            thisWeek = raceResult && raceResult?.recoveryPlan?.recoveryType?.max_week_percentage && !userInfoTD?.monday_start ? thisWeek + 1 : thisWeek
            const marathonDailyValues: any = userInfoTD?.plan_type === "GPT" ? await getWeeklyActivitiesAI(userInfoTD, weekDays) : await getWeeklyActivities(abortController.signal, userInfoTD, thisWeek, allUserNotes)
            let mval;
            const overrideWeek = userOverridesData?.filter((x: any) => x.week == (userInfoTD?.plan_type === "GPT" ? getSelectedWeek(weekDays, userInfoTD)?.key : thisWeek))

            if (overrideWeek && overrideWeek?.length > 0) {
                const currentOverride = overrideWeek.find((x: any) => x.day == thisDay)
                if (currentOverride?.rest_day !== "Y") {
                    mval = marathonDailyValues?.find((x: any) => x?.marathon?.id == currentOverride?.mid)
                }
                else if (currentOverride?.rest_day === "Y")
                    mval = undefined
            }

            else
                mval = marathonDailyValues?.find((x: any) => x?.marathon?.day == (+(thisDay as number) + 1))

            if (mval) {
                getUserNotes(mval, abortController.signal, userInfoTD)
                handleAdjustPaceByLevel(mval, userInfoTD, userInfoTD?.type as number, mval?.marathon?.race_day, userInfoTD?.training_level);
                dayData = mval
            }
            else
                dayData = { marathonPaces: [], workouts: [], marathon: undefined }

            const raceDay = date('w', strtotime(convertDateToUTC(userInfoTD?.race_date)));
            const trainingDetails = getRaceDetailsValues({}, userInfoTD, raceDay)
            const userCustomPaces = await getOverridePaceData(userInfoTD?.uid, abortController.signal)

            if (trainingDetails) {
                const currentDate = moment(new Date())
                let assessmentVideosDays = false;
                let recoveryAssessmentsDays = false

                assessmentVideosDays = moment(currentDate).isBefore(moment(weekDays[0].replaceAll('-', '/')).add(raceResult?.recoveryPlan?.recoveryType.assessment_starts_in_days, 'days'))

                recoveryAssessmentsDays = moment(currentDate).isSameOrAfter(moment(moment(weekDays[0].replaceAll('-', '/'))).add(raceResult?.recoveryPlan?.recoveryType.assessment_starts_in_days, 'days')) && moment(currentDate).isBefore(moment(weekDays[0].replaceAll('-', '/')).add(raceResult?.recoveryPlan?.recoveryType.resume_running_on_day, 'days'))

                const raceType = RaceOptions.find(x => x.value == (rec ? 7 : userInfoTD?.type));
                const inProgress = moment(moment().format('YYYY/MM/DD')).isBetween(moment(userInfoTD?.start).subtract(1, 'days'), moment(userInfoTD?.race_date).add(1, 'days')) ? 1 : 0

                let percentageAdjust = 0;
                if (raceResult && raceResult?.recoveryPlan?.recoveryType?.max_week_percentage && raceResult?.recoveryPlan?.recoveryType?.max_week_percentage.length > 0) {
                    percentageAdjust = JSON.parse(raceResult?.recoveryPlan?.recoveryType?.max_week_percentage)[dayData?.marathon?.week - 1]
                    dayData = {
                        ...dayData,
                        marathon: {
                            ...dayData.marathon,
                            distance: Number(dayData?.marathon?.distance * percentageAdjust)
                        }
                    }
                }

                const trainingText = getDailyActivityDisplay(dayData, userInfoTD, dayData?.marathonPaces, raceType?.value as number, dayData?.marathon?.race_day, allUserNotes)?.toString()

                const currentVdot = getCurrentVDOT(
                    thisWeek,
                    thisDay as number,
                    userInfoTD?.vdot,
                    userInfoTD?.targetm,
                    userInfoTD?.vDotAdjustment
                )

                const pace = extractPace(
                    dayData?.marathonPaces?.[0],
                    dayData?.marathon?.distance,
                    0,
                    currentVdot?.vdot,
                    userInfoTD?.km === 'Y' ? true : false,
                    currentVdot?.targetm,
                    userInfoTD?.aggressiveness,
                    userCustomPaces,
                    userInfoTD?.distance
                )
                trainingInfo = {
                    race_past: 0,
                    in_progress: inProgress,
                    percent_completed: trainingDetails.percentCompleted,
                    days_before_race: trainingDetails.daysUntilRace,
                    type: raceResult && raceResult?.id ? 'Recovery Plan' : camelCase((raceType?.title + " " + raceType?.subTitle).toLocaleLowerCase()),
                    workout_text: trainingDetails.percentCompleted == 100 && !raceResult?.id ? `It’s time to enter your results!` : raceResult?.id && assessmentVideosDays ? "Recovery Videos" : raceResult?.id && recoveryAssessmentsDays ? 'Assessment Time' : trainingText as string | '',
                    show: 1,
                    create_new: 0,
                    weeks_before_race: Number(((trainingDetails?.daysUntilRace - trainingDetails?.planLength) / 7).toFixed()) | 0,
                    plan_length: trainingDetails.planLength,
                    days_before_start: moment(userInfoTD?.start).diff(moment(moment().format('YYYY/MM/DD')), 'days') | 0,
                    race_date: moment(userInfoTD?.race_date).format('MMMM DD, YYYY'),
                    plan_id: raceResult && raceResult?.id ? raceResult?.maintenance_plan_id : userInfoTD?.uid,
                    pace: pace?.rawPace,
                    paceDetails: dayData?.marathonPaces?.length > 1,
                    weeks: rec?.recoveryType?.recovery_length_in_weeks ? rec.recoveryType.recovery_length_in_weeks : userInfoTD?.weeks,
                    currentWeek: thisWeek,
                    logo: raceType?.img,
                    typeIndex: rec ? 7 : userInfoTD?.type,
                    training_start: userInfoTD?.start,
                    plan_type: userInfoTD?.plan_type,
                    event_name: userInfoTD?.event_name || '',
                    race_logo: userInfoTD?.race_logo || '',
                }
            }
        }
        else {
            trainingInfo = {
                race_past: 0,
                in_progress: 0,
                percent_completed: 0,
                days_before_race: 0,
                type: "",
                workout_text: "",
                show: 1,
                create_new: 1,
                weeks_before_race: 0,
                plan_length: 0,
                days_before_start: 0,
                race_date: '',
                plan_id: 0
            }
        }

        return trainingInfo;
    }

    return { getTrainingDetails }
}

export default useHomeTrainingPlan
