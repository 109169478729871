/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { FC } from 'react'
import { Col, Modal, Row } from 'react-bootstrap'
import { useLocation } from 'react-router-dom'
import { navReq } from '../../modules/apiConsume'
import { ICurrentUser } from '../../store/createAccountStore'
import { IComponentStore } from '../../store/createComponentStore'
import useStore from '../../store/useStore'
import announcement from './announcement.json'
import { Program } from '../../api/v2/programs/programs.types'
import useIsMobileScreen from '../../hooks/useIsMobileScreen'
// import { isMobile } from 'react-device-detect'


interface AnnouncementPopupProps {
    showPopup: boolean,
    closePopup: () => any
    program: Program
}

const AnnouncementPopup: FC<AnnouncementPopupProps> = ({ showPopup, closePopup, program }) => {
    // const POPUP_VIEWED = program?.status == 1 ? localStorage.getItem("PopupViewed_Fuel") : "1"
    const POPUP_VIEWED = "1"
    const { showAnnouncementPopup, setShowAnnouncementPopup } = useStore((state: IComponentStore) => state)
    const { currentUser } = useStore((state: ICurrentUser) => state)
    const url = useLocation()
    const noPopup = ['signup', 'login', 'choose-plan', 'renew', 'signup-email', 'suggested-programs', 'onboarding-default', 'user-info', 'start', 'onboarding-survey', 'forgot-password', 'reset-password']
    const path = url.pathname.split('/')[1]

    const isMobile = useIsMobileScreen()

    const { title, image, subtitle, startText, url: announcementUrl, description } = announcement;

    const handleClickLink = () => {
        localStorage.setItem("PopupViewed_Fuel", "1")
        setShowAnnouncementPopup()
        navReq(announcementUrl)
    }

    return (
        <Modal
            size="xl"
            className="announcement-modal"
            show={POPUP_VIEWED && POPUP_VIEWED === "1" ? false : !currentUser ? false : noPopup.includes(path) ? false : true}
            // show={showAnnouncementPopup}
            onHide={closePopup}
            backdrop='static'
            keyboard={false}
            centered
        >
            <Modal.Header closeVariant={isMobile ? 'white' : undefined} closeButton={true}>

            </Modal.Header>
            <Modal.Body>
                <Row>
                    <Col xs={12} sm={12} md={12} lg={7} className=''>
                        <div className='image-container'>
                            <h5 className="heading-meta-data text-white fw-regular">
                                NEW!
                            </h5>
                            <img src={image} />
                        </div>
                    </Col>
                    <Col xs={12} sm={12} md={12} lg={5}>
                        <div className='details-container'>
                            <h3 className='header-text'>{title}</h3>
                            <h4 className='sub-text'>{subtitle}</h4>
                            <h5 className='start-date'>{startText}</h5>
                            <h6 className='details-text' dangerouslySetInnerHTML={{
                                __html: `${description}`
                            }} />
                            <a onClick={handleClickLink} className="link mt-3">Learn More</a>
                            {/* <a onClick={handleClickLink} className="link mt-3">Close</a> */}
                        </div>
                    </Col>
                </Row>
            </Modal.Body>
        </Modal>
    )
}

export default AnnouncementPopup