import { FC } from 'react'

import Box from '@mui/material/Box'
import { AuthPageProps } from '../modules/types'
import WithAuth from '../components/WithAuth'
import RSNavCont from '../components/layout/RSNavCont'
import AdditionalPlans from './home/AdditionalPlans'
import RunningPlan from './home/RunningPlan'
import Favorites from './home/FavoritesHome'
import History from './home/HistoryHome'

const Home: FC<AuthPageProps> = ({ userInfo, hasLoaded }) => {

	return (
		<RSNavCont>
			<Box sx={{ pt: 3 }}>
				<Box sx={{ px: 3 }}>
					<RunningPlan userInfo={userInfo} hasLoaded={hasLoaded} />
					<AdditionalPlans userInfo={userInfo} hasLoaded={hasLoaded} />
				</Box>
				<Favorites userInfo={userInfo} hasLoaded={hasLoaded} />
				<History userInfo={userInfo} hasLoaded={hasLoaded} />
			</Box>
		</RSNavCont>
	)
}

export default WithAuth(Home);
