import RsMenu from '../../components/layout/RsMenu'
import RsBottomMenu from '../../components/layout/RsBottomMenu'
import BasicInput from '../input/BasicInput'
import Container from 'react-bootstrap/Container'
import PageTitle from '../text/PageTitle'
// import BackButton from '../buttons/BackButton'
import { FC, useState, useEffect } from 'react'

/**
 * Props
 */

export type AppMenusProps = {
	title?: string
	isMobileTitle?: boolean
	hideTitleDesktop?: boolean
	hideOptions?: boolean
	hasContainer?: boolean
	hideBackButton?: boolean
	hasLoaded?: boolean
	hideSearch?: boolean
	showLogout?: boolean
	overrideSetShowSearch?: (x: boolean) => any
}

// Body Component
export const BodyComponent: FC<AppMenusProps> = ({
	hasContainer,
	children,
	hasLoaded,
}) => {
	return (
		<>
			{(hasLoaded === true || hasLoaded === undefined) && (
				<>
					{hasContainer ? (
						<>{children}</>
					) : (
						<Container fluid="lg" className="main-container pt-5">
							{children}
						</Container>
					)}
				</>
			)}
		</>
	)
}

/**
 * AppMenus Component
 */

const AppMenus: FC<AppMenusProps> = ({
	children,
	title,
	isMobileTitle,
	hideTitleDesktop,
	hasContainer,
	hideOptions,
	hideBackButton,
	hasLoaded,
	hideSearch,
	showLogout,
	overrideSetShowSearch,
}) => {
	// Properties
	const titleClass = hideTitleDesktop ? 'd-block d-md-none' : undefined

	// State
	const [showSearch, setShowSearch] = useState(false)

	// Search Change Effect
	useEffect(() => {
		if (overrideSetShowSearch) overrideSetShowSearch(showSearch)
	}, [showSearch])

	// Render
	return (
		<div className='main-top-margin'>
			<RsMenu
				setShowSearch={setShowSearch}
				hideOptions={hideOptions || hasLoaded === false}
				hideSearch={hideSearch}
				showLogout={showLogout}
			/>
			{/* Search */}
			{!overrideSetShowSearch && showSearch && !hideSearch && (
				<BodyComponent hasLoaded={hasLoaded}>
					<div className="search-row">
						<BasicInput
							isSearchBar
							id="search-input"
							placeholder="Search"
							className="float-end"
						/>
					</div>
				</BodyComponent>
			)}
			<BodyComponent
				hasLoaded={hasLoaded}
				hasContainer={hasContainer}
				hideBackButton={hideBackButton}
			>
				{/* {!hideBackButton && !hasContainer && <BackButton />} */}
				{(title ? true : false) && (
					<div className={titleClass}>
						<PageTitle text={title as string} isMobileTitle={isMobileTitle} />
					</div>
				)}
				{children}
			</BodyComponent>
			{!hideOptions && (hasLoaded === true || hasLoaded === undefined) && (
				<RsBottomMenu />
			)}
		</div>
	)
}

export default AppMenus
