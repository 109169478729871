/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { FC, useEffect, useState, useRef } from 'react'
import { Col, Row, Spinner } from 'react-bootstrap'
import BackButton from '../../components/buttons/BackButton'
import MainCard from '../../components/cards/MainCard'
import SelectPill from '../../components/input/SelectPill'
import AppMenus, { BodyComponent } from '../../components/layout/AppMenus'
import ScrollRow from '../../components/layout/ScrollRow'
import RelatedVideosRow from '../../components/page-specific/video/RelatedVideosRow'
import PageTitle from '../../components/text/PageTitle'
import WithAuth from '../../components/WithAuth'
import Loader from '../../components/animation/Loader'
import useScrollRow from '../../hooks/useScrollRow'
import { AuthPageProps } from '../../modules/types'
import useFavorites from './useFavorites'
import { IconButton } from '../../components/buttons/IconButton'
import Motion from '../../components/animation/Motion'
import CloseIcon from '@mui/icons-material/Delete'
import { Video } from '../../modules/videos/allVideos'
import exerciseActions from '../../modules/exerciseActions'
import { Exercise } from '../exercises/types'
import SwipeToDelete from 'react-swipe-to-delete-ios'
import { Replay } from '@mui/icons-material'
import useProgramFavorites from './useProgramFavorites'
import {
	slideInRight,
	slideOutRight,
	slideInLeft,
	slideOutLeft,
	fadeIn,
} from 'react-animations'
import styled, { keyframes } from 'styled-components'
import useIsMobileScreen from '../../hooks/useIsMobileScreen'

const AslideInLeft = keyframes`${slideInLeft}`
const AslideInRight = keyframes`${slideInRight}`
const FadeIn = keyframes`${fadeIn}`

const AslideOutLeft = keyframes`${slideOutLeft}`
const AslideOutRight = keyframes`${slideOutRight}`
const DivFadeIn = styled.div`
	animation: 0.2s ${FadeIn};
`
const DivInLeft = styled.div`
	animation: 0.2s ${AslideInLeft};
`
const DivInRight = styled.div`
	animation: 0.3s ${AslideInRight};
`

const DivOutLeft = styled.div`
	animation: 0.3s ${AslideOutLeft};
`
const DivOutRight = styled.div`
	animation: 0.3s ${AslideOutRight};
`

// Favorites Props
export type FavoritesTypeProps = AuthPageProps

const Favorites: FC<FavoritesTypeProps> = ({ userInfo, hasLoaded }) => {
	const PILL_OPTIONS = ['Programs', 'Exercises', 'Workouts', 'Others']
	const [pillKey, setPillKey] = useState(0)
	const [current, setCurrent] = useState('Programs')
	const [forDelete, setForDelete] = useState<string[]>([])

	const { isScrolling, setIsScrolling } = useScrollRow()

	const isMobileScreen = useIsMobileScreen()

	const slideDirection = useRef({slide: localStorage.getItem('page') === 'pw'? 'right' : ''})

	const { favorites, toggleFavorite, isLoading, getFavorites } = useFavorites(
		userInfo?.account_id as number,
		undefined,
		current.toLocaleLowerCase(),
		true
	)
	const { toggleProgramFavorite, programFavorites } = useProgramFavorites(
		userInfo?.account_id as number,
		current.toLocaleLowerCase()
	)

	// console.log(programFavorites)
	const onPillChange = (e: any) => {
		setCurrent(e)
	}

	// TO-DO: On Like
	const onLike = async (videoId: string) => {
		const isLiked =
			favorites && favorites.find((x: any) => x.id === videoId) ? true : false
		await toggleFavorite(
			userInfo?.account_id as number,
			Number(videoId),
			!isLiked
		)
	}

	const onLikeProgram = async (programId: number) => {
		const current = programFavorites.find(
			(x) => x.program_id == programId && x.favorited == 1
		)
		await toggleProgramFavorite(
			programId,
			userInfo?.account_id as number,
			current
		)
		await getFavorites()
	}

	const onDelete = async (videoId: string) => {
		setForDelete((prev) => [...prev, videoId])
	}

	const DeleteComponent = () => {
		return <CloseIcon htmlColor="#EA4335" />
	}

	useEffect(() => {
		let removeTimeOut: any
		if (forDelete.length > 0) {
			removeTimeOut = setTimeout(async () => {
				forDelete.forEach(async (video) => {
					if (current === 'Programs') await onLikeProgram(Number(video))
					else await onLike(video)
					setForDelete((prev) => prev.filter((x) => x !== video))
				})
			}, 3000)
		}
		return () => {
			clearTimeout(removeTimeOut)
		}
	}, [forDelete])

	return (
		<Motion
			isVisible={true}
			isMobile={isMobileScreen}
			direction={slideDirection.current}
			clear={true}
		>
			<>
				<Loader active={isLoading} isBehindHeader/>
				<AppMenus hasContainer>
					<BodyComponent>
						<Row className="mb-1 mb-md-4">
							<Col>
								{isMobileScreen ? (
									<div className={`text-center`}>
										<ScrollRow noPadding setIsScrolling={setIsScrolling}>
											<SelectPill
												index={pillKey}
												key={pillKey}
												options={PILL_OPTIONS}
												onChange={(e) => onPillChange(e)}
												isProgram={true}
											/>
										</ScrollRow>
									</div>
								) : (
									<SelectPill
										index={pillKey}
										key={pillKey}
										options={PILL_OPTIONS}
										onChange={(e) => onPillChange(e)}
									/>
								)}
							</Col>
						</Row>

						<Row>
							{favorites.length > 0 && !isLoading ? (
								<>
									<div className="d-none d-xs-none d-md-block">
										<RelatedVideosRow
											title={''}
											introSubtitleText={''}
											videos={favorites}
											userInfo={userInfo}
											rowType="favorites"
											onLike={onDelete}
											favorites={favorites}
											hasLikeButtons={true}
											forDelete={forDelete}
											onCancel={setForDelete}
											contentType={current === 'Programs' ? 'programs' : undefined}
										/>
									</div>
									<div className="mt-4 d-md-none">
										{favorites?.map((video) => (
											<Row className="justify-content-center mt-n2" key={video.id}>
												<Col className="justify-content-center phone-video-list">
													{forDelete.includes(video.id) ? (
														<>
															<div className="card undo-card">
																<p>
																	You have removed this <br />{' '}
																	{current === 'Exercises'
																		? 'exercise'
																		: current === 'workout'
																		? 'workout'
																		: 'program'}{' '}
																	from favorites{' '}
																</p>
																<div
																	onClick={() =>
																		setForDelete((prev) =>
																			prev.filter((x) => x !== video.id)
																		)
																	}
																>
																	<Replay fontSize="large" htmlColor="#742F4E" />
																</div>
															</div>
														</>
													) : (
														<SwipeToDelete
															onDelete={() => onDelete(video.id)} // required
															// optional
															height={150} // default
															transitionDuration={100} // default
															deleteWidth={75} // default
															// deleteThreshold={75} // default
															// showDeleteAction={true} //default
															deleteColor="transparent" // default
															deleteText="Delete" // default
															deleteComponent={<DeleteComponent />} // not default
															disabled={false} // default
															id="swiper-1" // not default
															className="my-swiper" // not default
															rtl={false} // default
															// onDeleteConfirm={(onSuccess: any, onCancel: any) => {
															//     // <h3>Are you sure?</h3>
															//     // not default - default is null
															//     setConfirmDelete(true)
															//     // if (window.confirm("Do you really want to delete this item ?")) {
															//     //     onSuccess();
															//     // } else {
															//     //     onCancel();
															//     // }
															// }}
														>
															<MainCard
																videoId={video.id}
																key={video.id}
																cardType="video"
																// type="videoLgStretch"
																hasCheck={false}
																progress={0}
																logoImage={
																	current === 'Programs'
																		? video.image.replace('.jpg', '_400px.jpg')
																		: video.image
																}
																bgImage={
																	current === 'Programs'
																		? video.image.replace('.jpg', '_400px.jpg')
																		: video.image
																}
																detailTitle={
																	current === 'Exercises' || current === 'Programs'
																		? video?.headingTitle
																		: video?.category
																}
																detailSubtitle={
																	current === 'Exercises'
																		? video?.category?.toUpperCase()
																		: current === 'Programs'
																		? video?.headingSubtitle
																		: video?.headingTitle?.toUpperCase()
																}
																link={video.url + (!video?.url?.includes('p_id') ?'?type=mobile' : '')}
																onLike={() => onDelete(video.id)}
																hasLikeButton={true}
																favorites={favorites}
																isProgram={current === 'Programs' ? false : true}
																// className={current === "Programs" ? 'video-detail-card-icon-default' : ''}
															/>
														</SwipeToDelete>
													)}
												</Col>
											</Row>
										))}
									</div>
								</>
							) : !isLoading && favorites.length == 0 ? (
								<div className="center-content text-center px-5">
									<h3>No Favorites Found</h3>
									<p>
										Tap the heart icon on your favorite{' '}
										{current === 'Programs' ? 'programs' : 'videos'} to add them to
										your list!
									</p>
								</div>
							) : undefined}
						</Row>
					</BodyComponent>
				</AppMenus>
			</>
		</Motion>
	)
}

export default WithAuth(Favorites)
