import { StoreSlice } from "./types"


export interface IAppleHealthKitStore {
    dataGenerated: any,
    setDataGenerated: (newData: any) => void,
    permission: any
    setPermission: (newPermission: any) => void
}

const createAppleHealthKitStore: StoreSlice<IAppleHealthKitStore> = (set) => ({
    dataGenerated: [],
    setDataGenerated: (newData: any) => {
        set(() => ({ dataGenerated: newData }))
    },
    permission: undefined,
    setPermission: (newPermission: any) => {
        set(() => ({ permission: newPermission }))
    }
})

export default createAppleHealthKitStore