/* eslint-disable @typescript-eslint/no-unused-vars */
import { IExerciseStore } from '../store/createExerciseStore';
import useStore from '../store/useStore';
import { getReq, postReq, putReq } from './apiConsume'

const exerciseActions = () => {

    const { setAllExercises, setIsLoadingExercises, setTrendingExercises, setPopularExercises, setUserWatchData } = useStore((state: IExerciseStore) => state)

    const buildParams = (params: any) => {
        let param = "";
        Object.keys(params).forEach((key: any, index: number) => {
            param = param + `${key}=${params[key]}${Object.keys(params).length - 1 != index ? '&' : ''}`
        });
        return param;
    }

    const getAllExercises = async (params?: any, signal?: AbortSignal) => {

        setIsLoadingExercises(true)
        const param = params ? '?' + buildParams(params) : ""
        const result = await getReq(`/v2/exercises${param}`, { signal: signal })
        setIsLoadingExercises(false)
        setAllExercises(result?.data?.result)
    }

    const getTrendingExercises = async (params: any, signal?: AbortSignal, updateStore?: boolean) => {
        setIsLoadingExercises(true)
        const param = params ? '?' + buildParams(params) : ""
        const result = await getReq(`/v2/exercises/trending${param}`, { signal: signal })
        setIsLoadingExercises(false)
        if (updateStore)
            setTrendingExercises(result?.data?.result)
        else
            return result?.data?.result
    }

    const getPopularExercises = async (params: any, signal?: AbortSignal, updateStore?: boolean) => {
        setIsLoadingExercises(true)
        const param = params ? '?' + buildParams(params) : ""
        const result = await getReq(`/v2/exercises/popular${param}`, { signal: signal })
        setIsLoadingExercises(false)
        if (updateStore)
            setPopularExercises(result?.data?.result)
        else
            return result?.data?.result
    }

    const getLastWatchedData = async (params: any, signal?: AbortSignal) => {
        setIsLoadingExercises(true)
        const param = params ? '?' + buildParams(params) : ""
        const result = await getReq(`/v2/exercises/user/watch-data${param}`, { signal: signal })
        setIsLoadingExercises(false)
        setUserWatchData(result?.data?.result)
    }

    const getExerciseFavorites = async (params: any, signal?: AbortSignal) => {
        const param = params ? '?' + buildParams(params) : ""
        const result = await getReq(`/v2/exercises/user/watch-data${param}`, { signal: signal })
        return result?.data?.result
    }

    const createWatchedData = async (data: any) => {
        await postReq(`/v2/exercises/user/watch-data`, data)
    }

    const likeExerciseVideo = async (data: any) => {
        const result = await postReq(`/v2/exercises/user/like-video`, data)
        return result;
    }

    const getExercisesByRegion = async (region: string) => 
        await getReq(`/v2/exercises/search-region?region=${region}`)

    const getBodyRegions = async () => 
        await getReq(`/v2/exercises/body-regions`)


    const getExerciseVideosByExerciseId = async (exerciseId: number) => 
        await getReq(`/v2/exercises/videos?exercise=${exerciseId}`)



    return { getAllExercises, getTrendingExercises, getPopularExercises, getLastWatchedData, createWatchedData, getExerciseFavorites, likeExerciseVideo, getExercisesByRegion, getBodyRegions, getExerciseVideosByExerciseId }
}

export default exerciseActions;
