/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { FC, useEffect, useState } from 'react'
import { Col, Row, Spinner } from 'react-bootstrap'
import { useNavigate, useLocation, useParams } from 'react-router-dom'
import { CloseOutlined } from "@mui/icons-material";
import BackButton from '../../components/buttons/BackButton'
import { IconButton } from '../../components/buttons/IconButton'
import MainCard from '../../components/cards/MainCard'
import SelectPill from '../../components/input/SelectPill'
import AppMenus, { BodyComponent } from '../../components/layout/AppMenus'
import ScrollRow from '../../components/layout/ScrollRow'
import AboutCard from '../../components/page-specific/video/AboutCard'
import WorkoutVideos from '../../components/page-specific/video/WorkoutVideos'
import PageTitle from '../../components/text/PageTitle'
import WithAuth from '../../components/WithAuth'
import Motion from '../../components/animation/Motion'
import RSNavCont from '../../components/layout/RSNavCont'
import RSCloseBtn from '../../components/buttons/RSCloseBtn'
import useScrollRow from '../../hooks/useScrollRow'
import { navReq } from '../../modules/apiConsume'
import { AuthPageProps } from '../../modules/types'
import allVideos, { Video } from '../../modules/videos/allVideos'
import { IExerciseStore } from '../../store/createExerciseStore'
import useStore from '../../store/useStore'
import { Exercise } from './types'
export type ExercisePageProps = AuthPageProps;
import Box from '@mui/material/Box';
import CloseIcon from '@mui/icons-material/CloseOutlined';
import TextCard from '../../components/cards/TextCard'
import Line from '../../components/page-specific/video/Line'
import exerciseActions from '../../modules/exerciseActions'
import useViewAll from './useViewAll'
import allExercises from '../../modules/videos/allNewExercises.json';
import useFavorites from '../favorites/useFavorites'
import useQueryParams from '../../hooks/useQueryParams'
import useIsMobileScreen from '../../hooks/useIsMobileScreen'
import GoogleCastButton from '../../components/buttons/GoogleCastButton';
import { IVimeoPlayerStore } from '../../store/createVimeoPlayerStore';
import { isMobile as isMobileDevice, isIOS, isAndroid } from 'react-device-detect'
import ChromeCastButtons from '../../components/buttons/ChromeCastButtons';
import usePlayCastVideo from '../../hooks/usePlayCastVideo';
import VideoPlayer from '../VideoPlayer';
import useRNBridge from '../../hooks/useRNBridge';

const parseVideoList = (exercises: Exercise[]) => {
	const videos: Video[] = []
	exercises?.forEach((e) => {
		const data: Video = {
			id: String(e?.video_id),
			url: '/#/exercises/' + e?.video_id,
			// image: e.featured_img,
			image: `https://vumbnail.com/${e?.video_id}.jpg`,
			category: e?.exercise_type,
			headingTitle: e?.exercise_title,
			headingSubtitle: e?.primary_region,
			detailsLine1: e?.tips_description,
			detailsLine2: '',
			headlines: [],
			descriptions: [e?.tips_description],
		}
		videos.push(data)
	})

	return videos;
}
const DEFAULT_EXERCISES = allExercises.filter(x => x.video_type === "exercise")
const DEFAULT_OFFSET = Math.floor(Math.random() * DEFAULT_EXERCISES.length);
const DEFAULT_VIDEOS = parseVideoList(DEFAULT_EXERCISES.splice(DEFAULT_OFFSET, 8) as unknown as Exercise[]) as Video[]

const ExercisePage: FC<ExercisePageProps & { videoIdProps?: string, closePlayer?: () => any, isFromTP?: boolean }> = ({ userInfo, videoIdProps, closePlayer, isFromTP }) => {

	const localStorageRequestKey = localStorage.getItem('deviceRequestKey')

	const PROGRESS = 0 as number;
	// Hooks

	const { allFilteredVideos, selectedFilter, isLoadingExercises: isLoading } = useStore((state: IExerciseStore) => state)
	const { createWatchedData } = exerciseActions()

	const { videoId } = useParams()
	const { redirect } = useQueryParams()
	const location = useLocation()
	const navigate = useNavigate()

	const { setIsShowControls, setCurrentTitle, setCurrentList, setShowVimeoPlayer, setIsFullScreen, setVimeoId, setIsPlaying: setCustomPlayerPlay } = useStore((state: IVimeoPlayerStore) => state)

	const { favorites, toggleFavorite, getIsFavorited } = useFavorites(userInfo?.account_id as number, videoId || videoIdProps, "exercises", true)
	// console.log(allExercises.filter(x => x.video_type === "exercise").splice(0, 8))

	const [isPlaying, setIsPlaying] = useState(false)
	const [showPlayer, setShowPlayer] = useState(videoIdProps ? true : false)
	const [showAll, setShowAll] = useState(false)
	const [isFavorite, setIsFavorite] = useState(false)
	const [isLoadingMark, setIsLoadingMark] = useState(false)
	const [exerciseData, setExerciseData] = useState<Exercise[]>()
	const [currentBgImage, setCurrentBgImage] = useState<string>()

	const [pillKey, setPillKey] = useState(0)
	const [selectedVideoType, setSelectedVideoType] = useState("Introduction")
	const [defaultVideos, setDefaultVideos] = useState<Video[]>(DEFAULT_VIDEOS)
	const PILL_OPTIONS = ['Introduction', 'Exercise']

	const { isScrolling, setIsScrolling } = useScrollRow()

	const requestKey = localStorage.getItem('deviceRequestKey') || undefined
	const googleCastStatus = localStorage.getItem('google-cast-status');

	const { playVideo } = usePlayCastVideo(requestKey)

	const url = useLocation()

	const { resultVideos } = useViewAll(allFilteredVideos !== undefined ? allFilteredVideos as Video[] : defaultVideos, showAll)

	const isMobile = useIsMobileScreen()

	const { sendDataToReactNative } = useRNBridge()

	const handleMarkAsFavorite = async (isChecked: boolean) => {
		if (exerciseData) {
			setIsLoadingMark(true)
			await toggleFavorite(userInfo?.account_id as number, exerciseData[0]?.video_id as number, isChecked)
			setIsLoadingMark(false)
		}
	}

	// TO-DO: On Like
	const onLike = async (videoId: string) => {
		const isLiked = favorites && favorites.find((x: any) => x.id === videoId) ? true : false;
		await toggleFavorite(userInfo?.account_id as number, Number(videoId), !isLiked)
	}


	const handlePlayVideoMobile = (video: Video) => {
		const title = `${video?.category.includes('All') ? video?.category.split(' ')[1].toUpperCase() : video?.category.toUpperCase()} | ${video?.headingTitle}`
		setCurrentTitle(title)
		setVimeoId(video.id)
		setCurrentList([])
		setShowVimeoPlayer(true)
		setIsFullScreen(true)
		setIsShowControls(true)
		setCustomPlayerPlay(true)
	}

	useEffect(() => {
		const exerciseId = allExercises?.find(x => x.video_id == Number(videoId || videoIdProps))?.exercise_id
		const exerciseData = allExercises?.filter(x => x.exercise_id == exerciseId) as unknown as Exercise[]
		setExerciseData(exerciseData)
	}, [videoId, videoIdProps])

	useEffect(() => {
		setIsPlaying(false)
	}, [isFromTP, videoIdProps])

	useEffect(() => {
		if (exerciseData) getIsFavorited(exerciseData[0]?.video_id).then(res => setIsFavorite(res))
	}, [favorites])

	useEffect(() => {
		!allExercises ? navReq('/#/exercises') : null
	}, [allExercises])

	useEffect(() => {
		localStorage.setItem('page', 'ep')
	}, [])

	const onPillChange = (value: string) => {
		setIsPlaying(false)
		setSelectedVideoType(value)
	}

	useEffect(() => {
		setIsPlaying(false)
	}, [location])

	useEffect(() => {
		if (selectedVideoType && exerciseData && exerciseData?.length > 0) {
			const value = selectedVideoType === "Introduction" ? `https://vumbnail.com/${exerciseData?.find(x => x.video_type === "intro")?.video_id}.jpg` : `https://vumbnail.com/${exerciseData?.find(x => x.video_type === "exercise")?.video_id}.jpg`
			setCurrentBgImage(value)
		}
	}, [selectedVideoType, exerciseData])

	const handleOnPlay = async () => {
		const videoId = selectedVideoType === "Introduction" ? exerciseData?.find(x => x.video_type === "intro")?.video_id : exerciseData?.find(x => x.video_type === "exercise")?.video_id
		setVimeoId(String(videoId))
		// if (googleCastStatus === "playing" || googleCastStatus === "active") {

		// 	videoId ? playVideo(String(videoId)) : undefined
		// }
		// else if (isMobileApp) {
		// 	if (videoId) {
		// 		const video = allExercises.find(x => x.video_id === videoId)
		// 		if (video) {
		// 			const data: Video = {
		// 				id: String(video?.video_id),
		// 				url: '/#/exercises/' + video?.video_id,
		// 				// image: e.featured_img,
		// 				image: `https://vumbnail.com/${video?.video_id}.jpg`,
		// 				category: video?.exercise_type,
		// 				headingTitle: video?.exercise_title,
		// 				headingSubtitle: video?.primary_region,
		// 				detailsLine1: video?.tips_description,
		// 				detailsLine2: '',
		// 				headlines: [],
		// 				descriptions: [video?.tips_description],
		// 			}

		// 			handlePlayVideoMobile(data)
		// 		}
		// 		setIsPlaying(false)
		// 	}
		// }
		// else {
		setIsPlaying(true)
		setTimeout(async () => {
			if (exerciseData) {
				const watchData = {
					user_id: userInfo?.account_id?.toString(),
					exercise_id: exerciseData[0]?.exercise_id.toString(),
					last_watched: 'now()',
					liked: "0"
				}
				await createWatchedData(watchData)
			}
		}, 10000)
		// }
	}

	useEffect(() => {
		if (requestKey) {
			const dataToSend = {
				requestType: 'cast-show-bottom-menu',
				payload: false,
				timestamp: new Date().getTime(),
			}

			sendDataToReactNative(dataToSend);
		}
	}, [])

	const renderWorkOut = () => {
		return (
			<>
				{(isMobile || isFromTP) && <div className={`pointer program-view-close ${isFromTP && !isMobile ? 'tp-close' : ''}`} onClick={() => videoIdProps && closePlayer ? closePlayer() : navigate(-1)}>
					<RSCloseBtn size={25} />
				</div>}
				<Row className=''>
					<Col className={`${redirect && redirect === "recover" ? 'mx-auto' : ''}`} lg={isFromTP ? 12 : 8} sm={12}>
						<Row className="get-started-training-plan justify-content-center mb-md-2 mb-3">
							<Col className="px-0 px-sm-3">
								<div className="video-wrapper w-100 blue-bg">
									{!isPlaying && exerciseData ? (
										<h5 className="heading-meta-data text-white fw-regular">
											{exerciseData[0]?.exercise_type}
										</h5>
									) : null}
									<MainCard
										hasPlayButton
										playButtonIsCentered
										type="responsiveCardLg"
										progress={0}
										bgImage={currentBgImage}
										vimeoId={selectedVideoType === "Introduction" ? exerciseData?.find(x => x.video_type === "intro")?.video_id : exerciseData?.find(x => x.video_type === "exercise")?.video_id}
										// onLike={onLike}
										onPlay={handleOnPlay}
										hasCheck
									/>
									<div>

									</div>
									{!isPlaying && !isLoadingMark ? (
										<>
											<div className="fav-icon heart-image mx-3">
												<input type="checkbox" checked={isFavorite} onChange={e => handleMarkAsFavorite(e.target.checked)} id="heart" hidden />
												<label htmlFor="heart">
													<div className="animation">
														<span className="dot-1"></span>
														<span className="dot-2"></span>
														<span className="dot-3"></span>
														<span className="dot-4"></span>
														<span className="dot-5"></span>
														<span className="dot-6"></span>
														<span className="dot-7"></span>
														<span className="dot-8"></span>
													</div>
													<img
														className="fill pointer"
														src="./images/icons/heart-red.svg"
														alt="Like"
													/>
													<img
														className="without-fill pointer"
														src="./images/icons/heart-white-big.png"
														alt="Like"
													/>
												</label>
												{localStorageRequestKey ? (
													<GoogleCastButton autoCast={isFromTP ? false : true} className='googlecast-button-player' vimeoId={selectedVideoType === "Introduction" ? String(exerciseData?.find(x => x.video_type === "intro")?.video_id) : String(exerciseData?.find(x => x.video_type === "exercise")?.video_id)} />
												) : <ChromeCastButtons vimeoId={url.pathname.split('/')[3]} />
												}

											</div>
										</>
									) :
										!isPlaying && isLoadingMark ? (<div className="fav-icon heart-image mb-n2 mx-n1">
											<Spinner animation='border' variant='danger' />
										</div>) : undefined
									}

									{!isPlaying ? (
										<div className="banner-info">
											<h3 className="text-white fw-normal text-capitalize">
												{/* {selectedVideoType === "Intro" ? (
																						<>
																								{exerciseData && exerciseData.length > 0 ? "Intro : " + exerciseData[0].exercise_title : ''}
																						</>
																				) : ( */}
												<>
													{exerciseData && exerciseData.length > 0 ? exerciseData[0].exercise_title : ''}
												</>
												{/* )
																				} */}
											</h3>
											{/* <h4 className="text-white text-capitalize">
						{currentWorkoutVideo?.headingTitle}
					</h4> */}

										</div>
									) : null}

									{!isPlaying ? (
										<div className="tick-mark mx-3">
											<img
												src={`${PROGRESS == 100 ? './images/icons/checkmark-done.svg' : './images/icons/checkmark.svg'}`}
												alt="Like"
											/>
										</div>
									) : undefined}
								</div>
							</Col>
						</Row>

						<Row className="mb-3 mt-n3 mt-md-0 mb-md-4">
							<Col>
								<SelectPill
									key={pillKey}
									options={PILL_OPTIONS}
									onChange={onPillChange}
								/>
							</Col>
						</Row>
						{exerciseData ? (
							<Row className="mb-5">
								<Col lg={6} sm={12}>
									<div className='mb-n5'>
										<TextCard
											size={'md'}
											title={''}
											isCentered={false}
											autoHeight={true}
											noBackground={true}
											className='p-0 mb-4'
										>
											<Line smallTitle title={'TIPS FOR PROPER FORM'} text={exerciseData[0]?.tips_description || ''} />

										</TextCard>
										{/* <AboutCard
																className="program-card-mobile"
																aboutHeadlines={[exerciseData && exerciseData.length > 0 ? exerciseData[0].exercise_title : '']}
																aboutDescriptions={[exerciseData && exerciseData.length > 0 ? exerciseData[0].tips_description : '']}
														/> */}
									</div>
								</Col>
								<Col lg={6} sm={12} className='mb-n4'>
									<TextCard
										title='REP & SETS'
										size={'md'}
										isCentered={false}
										autoHeight={true}
										className='p-0 mb-n2'
										smallTitle
									>
										<div>
											<Line smallTitle title={''} text={`
																${exerciseData[0]?.sets} set/s ${exerciseData[0]?.reps ? 'of ' + exerciseData[0]?.reps : ''}
																${exerciseData[0]?.hold ? 'Hold ' + exerciseData[0]?.hold : ''}
																` || ''} />
										</div>

										{exerciseData[0]?.make_it_harder_1 || exerciseData[0]?.make_it_harder_2 || exerciseData[0]?.make_it_harder_3 ? (
											<Line smallTitle title={'MAKE IT HARDER'} text={`
																		${exerciseData[0].make_it_harder_1}
																		${exerciseData[0].make_it_harder_2}
																		${exerciseData[0].make_it_harder_3}
																` || ''} />
										) : undefined}

										{exerciseData[0]?.make_it_easer_1 || exerciseData[0]?.make_it_easer_2 || exerciseData[0]?.make_it_easer_3 ? (
											<Line smallTitle title={'MAKE IT EASIER'} text={`
																			${exerciseData[0]?.make_it_easer_1}
																			${exerciseData[0]?.make_it_easer_2}
																			${exerciseData[0]?.make_it_easer_3}
																			` || ''} />
										) : undefined}
									</TextCard>
								</Col>
							</Row>
						) : undefined}

					</Col>
					{redirect && redirect === "recover" || videoIdProps ? undefined :
						<Col lg={4} sm={12}>
							{/* <WorkoutVideos videos={allFilteredVideos as Video[]} hideWeeks userInfo={userInfo} /> */}
							{allFilteredVideos && allFilteredVideos?.length > 0 ? (
								<ScrollRow setIsScrolling={setIsScrolling}>
									{selectedFilter.map((value, index) => (
										<div className='mx-0 mb-n3' key={index} >
											<IconButton
												primaryText={value.toUpperCase()}
												primaryAction={() => null}
												secondaryAction={() => null}
												icon={<CloseIcon color='disabled' />}
												isOption={true}
												isSelected={false}
											/>
										</div>
									))}
								</ScrollRow>
							) : undefined}
							{resultVideos?.filter(v => v.id !== videoId || videoIdProps)?.map((video) => (
								<Row className="justify-content-center mb-n2" key={video.id}>
									<Col className="justify-content-center phone-video-list">
										<MainCard
											videoId={video.id}
											cardType="video"
											type="videoLgStretch"
											hasCheck={false}
											progress={0}
											logoImage={video.image}
											detailTitle={video.headingTitle}
											detailSubtitle={" " + video.category.toUpperCase()}
											link={video.url}
											onLike={() => onLike(video.id)}
											hasLikeButton={true}
											favorites={favorites}
											isProgram={true}
											replaceURL={true}
										/>

									</Col>
								</Row>
							))}
							{!showAll && allFilteredVideos?.length as number > 5 || !showAll && defaultVideos?.length as number > 5 ? (
								<Row className='text-end'>
									<a onClick={() => setShowAll(true)} target={'blank'} className='text-secondary pointer mt-1 mx-n2'>
										<u>View All</u>
									</a>{' '}
								</Row>
							) : undefined}
						</Col>
					}
				</Row>
			</>
		)
	}

	return isFromTP ? renderWorkOut() : (
		<RSNavCont hideHeader={isMobile} hideFooter={isMobile}>
			<Motion
				className="exercise-page-list"
				isVisible={true}
				isMobile={isMobile}
				direction={{ slide: "up" }}
			>
				<Box sx={{ pt: isMobile ? 0 : 4 }}>
					{renderWorkOut()}
				</Box>
			</Motion>
		</RSNavCont>)
}

export default ExercisePage
