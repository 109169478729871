import { roundNumber } from "../../../modules/miscUtils"


const usePlanProperties = () => {

  // Get Next Date
  const getNextDate = (hasTrial = true) => {
    const nextDate = new Date()
    if (hasTrial) nextDate.setDate(nextDate.getDate() + 14)
    return nextDate.toLocaleDateString()
  }

  // Get Yearly Expiration Date
  const getYearlyExpirationDate = () => {
    const yearlyDate = new Date()
    yearlyDate.setFullYear(yearlyDate.getFullYear() + 1)
    yearlyDate.setDate(yearlyDate.getDate() + 14)
    return yearlyDate.toLocaleDateString()
  }

  // Get Monthly Expiration Date
  const getMonthlyExpirationDate = () => {
    const monthlyDate = new Date()
    monthlyDate.setMonth(monthlyDate.getMonth() + 1)
    monthlyDate.setDate(monthlyDate.getDate() + 14)
    return monthlyDate.toLocaleDateString()
  }

  const getProperties = (monthCost: number, yearCost: number, isMonthly = true, hasSale = false) => {
    const yearlyByMonthCost = roundNumber(yearCost / 12)
    const savingsPercent = Math.round(
      ((monthCost - yearlyByMonthCost) / monthCost) * 100
    )
    const saleDate = getNextDate(false)
    const trialDate = getNextDate(true)
    const yearlyDate = !hasSale ? trialDate : saleDate
    const expirationDate = isMonthly
      ? getMonthlyExpirationDate()
      : getYearlyExpirationDate()
    return {
      monthCost,
      yearCost,
      yearlyByMonthCost,
      savingsPercent,
      trialDate,
      yearlyDate,
      expirationDate,
    }
  }

  return getProperties
}

export default usePlanProperties