
export const WordToNumber: any = {
    one: 1,
    two: 2,
    three: 3,
    four: 4,
    five: 5,
    six: 6,
    seven: 7,
    eight: 8,
    nine: 9,
    ten: 10,
    eleven: 11,
    twelve: 12,
    thirteen: 13,
    fourteen: 14,
    fifteen: 15,
    sixteen: 16,
    seventeen: 17,
    eighteen: 18,
    nineteen: 19,
    twenty: 20,
};

export const NumberWords = [
    'one', 'two', 'three', 'four', 'five',
    'six', 'seven', 'eight', 'nine', 'ten',
    'eleven', 'twelve', 'thirteen', 'fourteen', 'fifteen',
    'sixteen', 'seventeen', 'eighteen', 'nineteen', 'twenty'
];

const numberHelperUtil = () => {

    const findNumberWord = (paragraph: string) => {
        const foundWord = NumberWords?.find(word => paragraph?.toLowerCase()?.includes(word));
        if (foundWord) {
            const number = NumberWords?.indexOf(foundWord) + 1; // Adding 1 because index is 0-based
            return { word: foundWord, number };
        } else {
            return null;
        }
    };
    const containsNumberWord = (paragraph: string) => {
        const iscontainsNumberWord = /\b(one|two|three|four|five|six|seven|eight|nine|ten|eleven|twelve|thirteen|fourteen|fifteen|sixteen|seventeen|eighteen|nineteen|twenty)\b/i.test(paragraph);
        return iscontainsNumberWord
    }
    const convertWordToNumber = (word: string) => {
        return WordToNumber[word];
    };

    const convertNumberToWord = (number: number) => {
        return NumberWords[number - 1];
    }

    return { convertWordToNumber, containsNumberWord, findNumberWord, convertNumberToWord }

}

export default numberHelperUtil