import { date, strtotime } from 'locutus/php/datetime'
import React, { useEffect, useState } from 'react'
import { Container, Placeholder } from 'react-bootstrap'
// import ScrollRow from '../../../components/layout/ScrollRow'
import useIsMobileScreen from '../../../hooks/useIsMobileScreen'
const WeekSelector = (props: any) => {

    const { initArray, weekDays, selectedWeek, loadingValues, setSelectedWeek } = props

    const [stickyClass, setStickyClass] = useState('relative');
    const [isMobileScreen, setIsMobileScreen] = useState(false);

    const isMobile = useIsMobileScreen();

    useEffect(() => {
        isMobileScreen ? setStickyClass('fixed-nav') : setStickyClass('relative')
    }, [isMobileScreen])

    useEffect(() => {
        setIsMobileScreen(isMobile)
    }, [isMobile])

    useEffect(() => {
        const container = document.querySelector(".list-unstyled") as any;
        if (container) {
            const middle = container?.children[selectedWeek.key - 1]
            container.scrollLeft = middle?.offsetLeft + middle?.offsetWidth / 2 - container?.offsetWidth / 2 - 5;
        }
    }, [selectedWeek])

    return (
        <div className={`week-select mb-4 mb-md-2 pb-0 pb-md-3 ${stickyClass}`}>
            <div className="bg-gray real">
                <Container className='week-select--container relative'>
                    {weekDays.length == 0 ?
                        (
                            <ul className="d-flex rso-scroll-row list-unstyled pt-2 mb-2">
                                {initArray.map((x: number) => (
                                    <div key={x} style={{ marginRight: 5 }}>
                                        <Placeholder as="li" animation="glow">
                                            <Placeholder style={{ borderRadius: 50, height: '100%' }} xs={12} />
                                        </Placeholder>
                                    </div>
                                ))}
                            </ul>
                        )
                        : (
                            <ul className="d-flex rso-scroll-row list-unstyled pt-2 mb-2">
                                {weekDays.map((week: any, id: number) => (
                                    <li id={id == (selectedWeek.key - 1) ? 'active' : ''} className={id == (selectedWeek.key - 1) ? 'active' : ''}
                                        onClick={() => loadingValues ? {} : setSelectedWeek({
                                            week: week, key: id + 1
                                        })} key={id}>
                                        <b>{date("M", strtotime(week)).toString().toUpperCase()}</b><span key={id}>{date("d", strtotime(week))}</span>
                                    </li>
                                ))}
                            </ul>
                        )}
                </Container>
            </div>
        </div>
    )
}

export default WeekSelector