import { FC, useState, useEffect } from 'react'
import {
	isRequired,
	validEmail,
	validPassword,
	validSignupPassword,
	validConfirmPassword,
	validConfirmEmail,
} from '../../modules/validation'
import { BasicInputProps } from '../input/BasicInput'
import GenericForm from './GenericForm'

// Input Info
export type InputInfo = {
	value: string
	isValid: boolean
}

// Props
export type SignInFormProps = {
	isSignUp?: boolean
	setIsValid: (x: boolean) => any
	setInfo: (x: SignInFormInfo) => any
}

// Default Form Info
export const defaultFormInfo = {
	user: { value: '', isValid: true } as InputInfo,
	email: { value: '', isValid: false } as InputInfo,
	password: { value: '', isValid: false } as InputInfo,
	confirmPassword: { value: '', isValid: false } as InputInfo,
	emailConfirm: { value: '', isValid: false } as InputInfo,
}

// Signin Form Info
export type SignInFormInfo = typeof defaultFormInfo

/**
 * SignInForm Component
 */

const SignInForm: FC<SignInFormProps> = ({ isSignUp, setIsValid, setInfo }) => {

	// User Input
	const userInput: BasicInputProps = {
		id: 'user-input',
		formKey: 'user',
		placeholder: 'Username or email',
		label: isSignUp ? 'Username' : '',
		validations: [],
	}

	// Email Input
	const emailInput: BasicInputProps = {
		id: 'email-input',
		formKey: 'email',
		placeholder: 'Email',
		label: 'Email',
		validations: [isRequired, validEmail],
	}

	// Pass Input
	const passInput: BasicInputProps = {
		id: 'pass-input',
		formKey: 'password',
		placeholder: 'Password',
		label: isSignUp ? 'Password' : '',
		isPassword: true,
		validations: [isRequired, validPassword],
	}

	// // Honeypot Field
	const emailConfirm: BasicInputProps = {
		id: 'email-confirm-input',
		formKey: 'emailConfirm',
		placeholder: 'Confirm Email',
		label: isSignUp ? 'Confirm Email' : '',
		validations: [validConfirmEmail]
	}

	// Set Field Value
	const setFieldValue = (
		field: keyof typeof defaultFormInfo,
		value: string
	) => {
		const newFormInfo = { ...formInfo }
		newFormInfo[field].value = value
		setFormInfo(newFormInfo)
		setAllValid(newFormInfo)
		setInfo(newFormInfo)
	}

	// Set Field Is Valid
	const setFieldIsValid = (
		field: keyof typeof defaultFormInfo,
		isValid: boolean
	) => {
		const newFormInfo = { ...formInfo }
		newFormInfo[field].isValid = isValid
		setAllValid(newFormInfo)
		setFormInfo(newFormInfo)
		setInfo(newFormInfo)
	}

	// Set All Valid
	const setAllValid = (newFormInfo: typeof defaultFormInfo) => {
		let allValid = true
		for (const key in newFormInfo) {
			const formInfoKey = key as keyof typeof newFormInfo
			if (!isSignUp && ['email', 'confirmPassword'].includes(formInfoKey)) {
				continue
			}
			const isValid = newFormInfo[formInfoKey].isValid
			if (!isValid) {
				allValid = false
				break
			}
		}
		if (valid != allValid) {
			setValid(allValid)
			if (setIsValid) setIsValid(allValid)
		}
	}

	// State
	const [formInfo, setFormInfo] = useState(defaultFormInfo)
	const [valid, setValid] = useState(false)
	const [curKey, setCurKey] = useState(0)

	// Confirm Input
	const confirmInput: BasicInputProps = {
		id: 'confirm-pass-input',
		formKey: 'confirmPassword',
		placeholder: 'Confirm Password',
		label: 'Confirm Password',
		isPassword: true,
		validations: [
			isRequired,
			validSignupPassword,
			validConfirmPassword.bind(null, formInfo.password.value),
		],
	}

	// Properties

	const loginProps = [userInput, passInput, emailConfirm]
	const signUpProps = [emailInput, passInput, confirmInput, emailConfirm]

	if (isSignUp) {
		for (const index in signUpProps) {
			const inputProps = signUpProps[index]
			const fieldInfoKey = inputProps.formKey || ''
			inputProps.setText = setFieldValue.bind(null, fieldInfoKey as any)
			inputProps.setIsValid = setFieldIsValid.bind(null, fieldInfoKey as any)
		}
	}
	else
		for (const index in loginProps) {
			const inputProps = loginProps[index]
			const fieldInfoKey = inputProps.formKey || ''
			inputProps.setText = setFieldValue.bind(null, fieldInfoKey as any)
			inputProps.setIsValid = setFieldIsValid.bind(null, fieldInfoKey as any)
		}

	// Set Signup Password Validation
	signUpProps[2] = { ...signUpProps[2] }
	if (signUpProps[2].validations) {
		signUpProps[2].validations = [isRequired, validSignupPassword]
	}

	// isSignUp Change Effect
	useEffect(() => {
		setCurKey(curKey + 1)
	}, [isSignUp])

	// Render
	return (
		<GenericForm
			key={curKey}
			isHorizontal
			inputs={isSignUp ? signUpProps : loginProps}
		/>
	)
}

export default SignInForm
