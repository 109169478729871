/* eslint-disable @typescript-eslint/no-unused-vars */
import WithAuth from '../../components/WithAuth'
import AppMenus from '../../components/layout/AppMenus'
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
// import Col from 'react-bootstrap/Col'
// import SelectPill from '../../components/input/SelectPill'
import Profile from '../../components/page-specific/account/Profile'
import ChangePassword from '../../components/page-specific/account/ChangePassword'
import Subscription from '../../components/page-specific/account/Subscription'
import Billing from '../../components/page-specific/account/Billing'
import GenericAlert from '../../components/misc/GenericAlert'
import useAlert from '../../hooks/useAlert'
// import BillingUpdateMessage from '../../components/misc/BillingUpdateMessage'
import { AuthPageProps } from '../../modules/types'
import { navReq } from '../../modules/apiConsume'
import { RECURLY_PUBLIC_KEY } from '../../modules/cliEnvValues'
import { FC, useState, useEffect } from 'react'
import { Elements, RecurlyProvider } from '@recurly/react-recurly'
import useStore from '../../store/useStore'
import { ICurrentUser } from '../../store/createAccountStore'
import classNames from 'classnames'
import useQueryParams from '../../hooks/useQueryParams'
import { ArrowBackIos } from '@mui/icons-material'
import { IComponentStore } from '../../store/createComponentStore'
import useIsMobileScreen from '../../hooks/useIsMobileScreen'
import { useLocation } from 'react-router-dom'
import { Col } from 'react-bootstrap'
import SelectPill from '../../components/input/SelectPill'
import RSNavCont from '../../components/layout/RSNavCont'
import IntegrationsMainPage from '../integrations/IntegrationsMainPage'
import useRNBridge from '../../hooks/useRNBridge'
import GenericModal from '../../components/misc/GenericModal'
import PaymentModal from '../../components/misc/PaymentModal'
// import { useLocation } from 'react-router-dom'

// Account Props
export type AccountProps = AuthPageProps & {
	isRenew?: boolean
	isBilling?: boolean
	isPastDue?: boolean
	page?: number,
	show?: boolean
	setShow?: (bool: boolean) => any
}

// Account Links
const ACCOUNT_LINKS = ['Profile', 'Billing', 'Subscription']

// Hidden Links
// const HIDDEN_LINKS = ['Change Password']

// Get Title
const getTitle = (isRenew?: boolean, isBilling?: boolean) =>
	isRenew
		? 'Renew Your Membership'
		: isBilling
			? 'Update Billing Info'
			: 'MY ACCOUNT'

/**
 * Account Component
 */

const Account: FC<AccountProps & AuthPageProps> = ({
	userInfo,
	isRenew = false,
	isBilling = false,
	isPastDue = false,
	show,
	page,
	setShow,
	hasLoaded
}) => {

	const { sendDataToReactNative } = useRNBridge()

	// State
	const [title, setTitle] = useState(getTitle(isRenew, isBilling))
	const [navIndex, setNavIndex] = useState(isRenew ? 1 : isBilling ? 3 : 0)

	// Get Current User
	const { currentUser } = useStore((state: ICurrentUser) => state)
	const { showAccountSideMenu, setShowAccountSideMenu, setShowSideMenu, selectedAccountPage, setselectedAccountPage } = useStore((state: IComponentStore) => state)

	const isMobile = useIsMobileScreen()

	const url = useLocation();
	// const isRenewPath = url.pathname.replace('/', "") === "renew" ? true : false;

	// Hooks
	const {
		showAlert,
		onAlertOpen,
		onAlertClose,
		alertTitle,
		alertMessage,
		setAlertMessage,
		alertVariant,
		setAlertVariant,
	} = useAlert()

	// On View Profile
	const onViewProfile = () => onNavChange('Profile')

	// On Change Password
	const onChangePassword = () => onNavChange('Change Password')

	// On Cancel Subscription
	const onCancelSubscription = () => navReq('/#/cancel/step-1')

	// On Update Info
	const onUpdateInfo = () => navReq('/#/account')

	// On Renew Success
	const onRenewSuccess = () => navReq('/#/')

	// On Nav Change
	const onNavChange = (value: string) => {
		if (value === "Change Password") {
			setNavIndex(3)
		} else {
			const newIndex = ACCOUNT_LINKS.indexOf(value)
			setNavIndex(newIndex)
		}
	}

	const handleBack = () => {
		setShowSideMenu(true)
		setShowAccountSideMenu(false)
		setShow ? setShow(false) : undefined
		// setselectedAccountPage(undefined)
	}

	// Is Renew Change Effecut
	useEffect(() => {
		if (isRenew) {
			onNavChange('Subscription')
			setTitle(getTitle(isRenew, false))
		}
	}, [isRenew])

	// Is Renew Change Effecut
	useEffect(() => {
		if (page !== undefined && show) {
			setShowSideMenu(false)
			setShowAccountSideMenu(true)
			setNavIndex(Number(page))
		}
		// return () => {
		// 	setShowSideMenu(true)
		// 	setShowAccountSideMenu(false)
		// }
	}, [page, show])

	useEffect(() => {
		isRenew ? setNavIndex(2) : undefined
	}, [isRenew])

	useEffect(() => {
		isBilling ? setNavIndex(1) : undefined
	}, [isBilling])

	useEffect(() => {
		setShowSideMenu(false)
		setShowAccountSideMenu(false)
	}, [url])

	// useEffect(() => {
	// 	if (requestKey) {
	// 		setTimeout(() => {
	// 			const dataToSend = {
	// 				requestType: 'cast-show-bottom-menu',
	// 				payload: false,
	// 				timestamp: new Date().getTime(),
	// 			}

	// 			sendDataToReactNative(dataToSend);
	// 		}, 100)
	// 	}
	// }, [show])

	// useEffect(() => {

	// 	return () => {
	// 		if (requestKey) {
	// 			const dataToSend = {
	// 				requestType: 'cast-show-bottom-menu',
	// 				payload: true,
	// 				timestamp: new Date().getTime(),
	// 			}

	// 			sendDataToReactNative(dataToSend);
	// 		}
	// 	}
	// }, [])


	// Is Billing Change Effect
	useEffect(() => {
		if (isBilling) {
			onNavChange('Billing')
			setTitle(getTitle(false, isBilling))
		}
	}, [isBilling])

	// Render
	return (
		<>
			{/* Alert */}
			<GenericAlert
				show={showAlert}
				title={alertTitle}
				message={alertMessage}
				variant={alertVariant}
				onClose={onAlertClose}
				mustClose={navIndex == 2 || navIndex == 3 || isBilling}
			/>

			<div>
				{isRenew && hasLoaded || isBilling && hasLoaded ? (
					<div className="account-container">
						{/* Page */}
						<RSNavCont disableHome plain showLogOut hideFooter>
							{/* Pill Select/Prompt Row */}
							<Row className="mb-5">
								<Row className={classNames(isRenew ? 'renew--title mb-3 text-center' : "account-title mt-1")}>
									<h3>{title}</h3>
								</Row>
								{isRenew ? (
									<Row>
										<p className="text-danger text-center mb-0">
											{/* Choose a plan below and get everything you need for fast &
									injury-free running - all in one place. You may cancel any time. */}
											Your membership has expired, please renew your membership below
											to continue.
											{/* For a limited time, get 7 days free when you renew. */}
										</p>
									</Row>
								) : isBilling ? (
									// <BillingUpdateMessage hasStyledText />
									<Row>
										{/* <p className="text-danger fw-bold text-center mb-0">
											Your billing information is invalid, please update your billing
											information to continue
										</p> */}
									</Row>
								) : (
									<Col>
										<SelectPill
											index={navIndex}
											options={ACCOUNT_LINKS}
											// mobileHiddenOptions={HIDDEN_LINKS}
											onChange={onNavChange}
											isAccount
										/>
									</Col>
								)}
							</Row>

							{/* Form */}
							<Container fluid="md">
								<div className="account-form-lg account-form m-auto">
									{navIndex == 0 ? (
										<Profile
											onChangePassword={onChangePassword}
											onAlertOpen={onAlertOpen}
											setAlertMessage={setAlertMessage}
											setAlertVariant={setAlertVariant}
										/>
									) : navIndex == 3 ? (
										<ChangePassword
											onCancel={onViewProfile}
											onAlertOpen={onAlertOpen}
											setAlertMessage={setAlertMessage}
											setAlertVariant={setAlertVariant}
										/>
									) : navIndex == 2 ? (
										<Subscription
											isRenew={isRenew}
											isExpiring={currentUser?.is_expiring}
											billingIsValid={currentUser?.is_valid}
											onSubmit={isRenew ? onRenewSuccess : undefined}
											onCancel={isRenew ? undefined : onCancelSubscription}
											onAlertOpen={onAlertOpen}
											setAlertMessage={setAlertMessage}
											setAlertVariant={setAlertVariant}
										/>
									) : navIndex == 1 ? (
										<RecurlyProvider publicKey={RECURLY_PUBLIC_KEY}>
											<Elements>
												<Billing
													userInfo={currentUser}
													afterBillingProcess={isBilling && !isRenew ? onUpdateInfo : isRenew ? onRenewSuccess : undefined}
													isUpdate={isBilling}
													onAlertOpen={onAlertOpen}
													setAlertMessage={setAlertMessage}
													setAlertVariant={setAlertVariant}
													isAccount={true}
												/>
											</Elements>
										</RecurlyProvider>
									) : (
										<></>
									)}
								</div>
							</Container>
						</RSNavCont>
					</div>
				) :
					<Container className={`slide-left-container ${showAccountSideMenu ? 'active' : 'display-none'}`} fluid="md">
						<div className="account-form-lg account-form">
							<div className='mb-4' onClick={() => handleBack()}>
								<ArrowBackIos />
							</div>
							{navIndex == 0 ? (
								<Profile
									onChangePassword={onChangePassword}
									onAlertOpen={onAlertOpen}
									setAlertMessage={setAlertMessage}
									setAlertVariant={setAlertVariant}
								/>
							) : navIndex == 3 ? (
								<ChangePassword
									onCancel={onViewProfile}
									onAlertOpen={onAlertOpen}
									setAlertMessage={setAlertMessage}
									setAlertVariant={setAlertVariant}
								/>
							) : navIndex == 2 ? (
								<Subscription
									isRenew={isRenew}
									isExpiring={currentUser?.is_expiring}
									billingIsValid={currentUser?.is_valid}
									onSubmit={isRenew ? onRenewSuccess : undefined}
									onCancel={isRenew ? undefined : onCancelSubscription}
									onAlertOpen={onAlertOpen}
									setAlertMessage={setAlertMessage}
									setAlertVariant={setAlertVariant}
								/>
							) : navIndex == 1 ? (
								<RecurlyProvider publicKey={RECURLY_PUBLIC_KEY}>
									<Elements>
										<Billing
											userInfo={currentUser}
											afterBillingProcess={isBilling && !isRenew ? onUpdateInfo : isRenew ? onRenewSuccess : undefined}
											isUpdate={isBilling}
											onAlertOpen={onAlertOpen}
											setAlertMessage={setAlertMessage}
											setAlertVariant={setAlertVariant}
											isAccount={true}
										/>
									</Elements>
								</RecurlyProvider>
							) : navIndex == 4 ? (
								<IntegrationsMainPage userInfo={userInfo} />
							) : (
								<></>
							)}
						</div>
					</Container>
				}
				{/* Page */}

			</div>
		</>
	)
}

export default WithAuth(Account, true)
