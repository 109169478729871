import React from 'react'
import { Form } from 'react-bootstrap'
import CustomPopOver from '../../../components/custom-popover'

const UpdateHeartRate = (props: any) => {
    const { isSuccessSave, isLoading, heartRateValue, setHeartRateValue, handleSaveUserNotes } = props
    return (
        <div className='mx-1'>
            <CustomPopOver
                autoClose={isSuccessSave}
                icon={
                    <img
                        src="./images/icons/plus-icon.svg"
                        alt="edit"
                        style={{ width: '21px', height: '21px' }}
                    />
                }
            >
                <div className="popover-custom">
                    <h6 className="m-0 text-center box-title">
                        {isLoading ? 'Saving Heart Rate...' : 'ENTER HEART RATE'}
                    </h6>
                    <Form>
                        <Form.Group>
                            <Form.Control type="number" min={0} defaultValue={heartRateValue} onChange={(e) => setHeartRateValue(e.target.value)} />
                        </Form.Group>
                    </Form>
                    <div onClick={() => handleSaveUserNotes("heart_rate")} className="thickmark-icon pointer">
                        <img
                            src="./images/icons/tick-mark.png"
                            alt="Tick Mark"
                        />
                    </div>
                </div>
            </CustomPopOver>
        </div>
    )
}

export default UpdateHeartRate