/* eslint-disable @typescript-eslint/no-unused-vars */
import InstabotHide from '../../components/styles/InstabotHide'
import WithAuth from '../../components/WithAuth'
import AppMenus from '../../components/layout/AppMenus'
import Container from 'react-bootstrap/Container'
import useUserInfo from '../../hooks/useUserInfo'
import GenericAlert from '../../components/misc/GenericAlert'
import useAlert from '../../hooks/useAlert'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
// import Badge from 'react-bootstrap/Badge'
import AccountForm, {
	FormInfo,
	joinFormInfo,
} from '../../components/forms/AccountForm'
import AccountSubmitButtons from '../../components/buttons/AuthSubmitButtons'
import { navReq } from '../../modules/apiConsume'
import { getSubscriptionInfo, updateAccountInfo } from '../../modules/accountActions'
import { FC, useEffect, useState } from 'react'
import ReactPixel from 'react-facebook-pixel';
import { Spinner } from 'react-bootstrap'
import { IMPACT_TRIAL_ID } from '../../modules/cliEnvValues'
import { AuthPageProps } from '../../modules/types'
import { sha1 } from 'locutus/php/strings'
import useSurveyActions from '../onboarding/useSurveyActions'
import suggestedPrograms from '../../pages/onboarding/suggested-program.json'
import { useLocation } from 'react-router-dom'
// import secureLocalStorage from "react-secure-storage";


// Header Text
// const HEADER_TEXT = `
// The home stretch.  Tell us about you and your running!
// `

const HEADER_TEXT = `
As final step, we ask that you tell us about your running & goals, This will help us customaize your traning.
`

/**
 * UserInfo Component
 */


// const subTypeApple = secureLocalStorage.getItem("subTypeApple")

const UserInfo: FC<AuthPageProps> = ({ userInfo }) => {
	// State
	const [info, setInfo] = useState(joinFormInfo as FormInfo)
	const [isValid, setIsValid] = useState(false)
	const [isLoading, setIsLoading] = useState(false)
	const [joinReason, setJoinReason] = useState<string | undefined>()

	const { userSurveyDetails } = useSurveyActions(userInfo?.account_id)

	const url = useLocation()

	//impact click id
	const CLICK_ID = localStorage.getItem("irclickid") || undefined

	// Hooks
	const {
		showAlert,
		onAlertOpen,
		onAlertClose,
		alertTitle,
		alertMessage,
		setAlertMessage,
		alertVariant,
		setAlertVariant,
	} = useAlert()
	const { firstName, lastName, dob, gender, favorite } =
		useUserInfo()

	// console.log(info.joinReason.value)

	// On Submit
	const onSubmit = async () => {
		setIsLoading(true)
		const data = {
			firstName: info.firstName.value,
			lastName: info.lastName.value,
			gender: info.gender.value,
			dob: info.dob.value,
			favorite: info.favorite.value,
			joinReason: userSurveyDetails && userSurveyDetails.running_goal ? getJoinReason() : info.joinReason.value === "" ? 'strength' : info.joinReason.value,
		}

		const result = await updateAccountInfo(data, "profile_update")
		if (result?.data?.id) {
			setIsLoading(false)
			if (userInfo?.account_id && userSurveyDetails)
				navReq('/#/suggested-programs')
			else
				navReq('/#/onboarding-default')
		} else {
			setIsLoading(false)

			setAlertVariant('danger')
			setAlertMessage('Could not update user info')
			onAlertOpen()
		}
	}

	const getJoinReason = () => {
		const suggested = suggestedPrograms.find(x => x.answer == Number(userSurveyDetails?.running_goal))
		return suggested?.join_reason
	}

	//check plan trial
	// const hasPlanTrial = (planCode: string) => {
	// 	return planCode === "runsmart-annual" || planCode === "runsmart-monthly" || planCode === "runsmart-annual-discount" ? true : false
	// }

	// const initImpactConversion = async () => {
	// 	const { subscription } = await getSubscriptionInfo()

	// 	if (subscription && hasPlanTrial(subscription?.plan_code) && CLICK_ID)
	// 		window.callConversion(IMPACT_TRIAL_ID, subscription?.id, String(userInfo?.account_id), sha1(userInfo?.email))
	// }


	// const relogIn = async () => {
	// 	const user = secureLocalStorage.getItem("workerKeys") as any
	// 	const data = {
	// 		username: user.username,
	// 		password: user.password
	// 	}
	// 	await destroySession()
	// 	const response = await logIn(data)

	// 	if (response?.data?.account_id != null) {
	// 		navReq('/#/join-video')
	// 	}
	// }

	useEffect(() => {
		if (userInfo?.account_id)
			ReactPixel.trackCustom('StartTrial', { k: new Date().getTime() });
	}, [userInfo])

	useEffect(() => {
		if (userSurveyDetails)
			setJoinReason(getJoinReason())
	}, [userSurveyDetails])

	// useEffect(() => {
	// 	if (userInfo?.account_id && CLICK_ID)
	// 		initImpactConversion()
	// }, [userInfo, CLICK_ID])

	// Render
	return (
		<>
			{/* Styles */}
			<InstabotHide />

			{/* Alerts */}
			<GenericAlert
				show={showAlert}
				title={alertTitle}
				message={alertMessage}
				variant={alertVariant}
				onClose={onAlertClose}
				mustClose
			/>

			{/* Page */}
			<AppMenus hideOptions hideBackButton>
				<Container
					fluid="md"
					className="px-form-4 signup-container welcome-page--wrapper"
				>
					<div className="user-info-page--wrapper container-center">

						{/* <Row className="mb-3 user-step--title d-block text-cetner d-md-none">
							<Col className="text-center">
								<Badge
									as="h5"
									style={{ fontSize: '1rem' }}
									bg="black"
									className="rounded-pill--signup bg-black"
								>
									Step 4 of 4
								</Badge>
							</Col>
						</Row> */}

						{/* Title */}
						<Row className="mb-2 user-login--title">
							<Col>
								<h3>Welcome!</h3>
							</Col>
						</Row>

						{/* Switch Page Link */}
						{/* <Row className="mb-3 user-step--title">
							<Col className="">
								<Badge
									as="h5"
									style={{ fontSize: '1rem' }}
									bg="black"
									className="rounded-pill--signup bg-black"
								>
									Step 4 of 4
								</Badge>
							</Col>
						</Row> */}

						{/* Signup Header */}
						<Row className="mb-3 m-auto">
							<Col className="m-auto d-flex justify-content-center sm-text-center subtitle-2">
								<p>{HEADER_TEXT}</p>
							</Col>
						</Row>
					</div>
					<Container fluid="md" className="">
						<div className="account-form m-auto">
							<AccountForm
								setIsValid={setIsValid}
								setInfo={setInfo}
								firstName={firstName}
								lastName={lastName}
								gender={gender}
								dob={dob}
								favorite={favorite}
								joinReason={joinReason}
								isJoin
								hasSurvey={userSurveyDetails && userSurveyDetails.running_goal ? true : false}
							/>
							<AccountSubmitButtons
								primaryText={isLoading ? <Spinner animation='border' size="sm" /> : "Get Started"}
								primaryAction={onSubmit}
								primaryDisabled={!isValid}
								addClassName="user-info-btn"
							/>
						</div>
					</Container>
				</Container>
			</AppMenus>
		</>
	)
}

export default WithAuth(UserInfo)
