/* eslint-disable @typescript-eslint/no-unused-vars */
import { FC, useEffect, useState } from 'react'
import { IFeaturedProgram } from '../../../api/v2/favorites/types'
import WithAuth from '../../../components/WithAuth'
import ArrowBack from '@mui/icons-material/ArrowBackIosRounded'
import Heart from '../../../components/logos/Heart'
import { CheckCircleOutlineOutlined } from '@mui/icons-material'
import { backNav, navReq } from '../../../modules/apiConsume'
import AppMenus, { BodyComponent } from '../../../components/layout/AppMenus'
import RSNavCont from '../../../components/layout/RSNavCont'
import { Col, Row } from 'react-bootstrap'
import ArrowForwardIosIcon from '@mui/icons-material/KeyboardDoubleArrowRight'
import { Helmet } from 'react-helmet'
import { Program } from '../../../api/v2/programs/programs.types'

const ProgramSplash: FC<{
	handleOnBack: () => void
	SelectedProgram: Program
	handleLikeProgram: (programId: number) => void
	handleIsFavoritedProgram: (programId: number) => boolean
	isFixed: boolean
}> = ({
	handleOnBack,
	SelectedProgram,
	handleLikeProgram,
	handleIsFavoritedProgram,
	isFixed,
}) => {
		return (
			<>
				{/* <Mobile View /> */}
				<div className="program-splash mobile d-block d-default-none">
					<div className={`program-image ${isFixed ? 'fixed' : ''}`}>
						<div onClick={handleOnBack} className="back-button">
							<ArrowBack />
							{/* <BackButton /> */}
						</div>
						<span className="faded faded-bottom">
							<Heart
								className="program-image-like"
								onLike={() => handleLikeProgram(SelectedProgram?.program_id)}
								isLiked={handleIsFavoritedProgram(SelectedProgram?.program_id)}
							/>

							{/* <CheckCircleOutlineOutlined className="program-image-like" /> */}
							<img src={SelectedProgram?.image} />
						</span>
					</div>

					<div className="program-info m-3">
						<span className="title">
							<h3 className="mb-0">{SelectedProgram?.program_title}</h3>
						</span>
						<div className="content bg-main px-3 pt-1 mx-n3">
							{SelectedProgram?.program_infos?.map((info: any, key) => (
								<div key={key}>
									<h4 className={`subtitle ${key == 0 ? 'mt-0' : 'mt-4'}`}>
										<b>{info.subtitle}</b>
									</h4>
									{info.paragraphs?.map((text: string, key: number) => (
										<p key={key}>{text}</p>
									))}
								</div>
							))}
						</div>
					</div>

					<div
						className={`program-button ${isFixed ? 'd-block' : 'd-none'}`}
						onClick={() => {
							navReq(
								`${SelectedProgram?.url as string}?program_title=${SelectedProgram?.program_title
								}`
							)
							// !SelectedProgram.StartDate
							// 	? navReq(
							// 		`${SelectedProgram.Url as string}?program_title=${SelectedProgram.Featured_Title
							// 		}`
							// 	)
							// 	: undefined
						}}
					>
						<p>{
							//SelectedProgram?.StartDate ||
							'Select Program'} </p>
					</div>
				</div>

				{/* <Desktop View /> */}
				<div
					className={`d-default-block d-none program-splash ${isFixed ? 'd-block' : 'd-none'
						}`}
				>
					<RSNavCont>
						<Row className="d-none d-default-block mx-n4">
							<Col md={1}>
								<span
									className="has-pointer back-btn w-auto pt-1"
									onClick={backNav}
								>
									<ArrowBack />
								</span>
							</Col>
							<Col md={11}>
								<h3
									style={{ cursor: 'pointer', marginTop: '5px' }}
									onClick={backNav}
									className="mx-5"
								>
									{SelectedProgram?.program_title}
								</h3>
							</Col>
						</Row>
						<Row>
							<Col md={7}>
								<div className="faded program-image">
									<Heart
										className="program-image-like"
										onLike={() => handleLikeProgram(SelectedProgram?.program_id)}
										isLiked={handleIsFavoritedProgram(
											SelectedProgram?.program_id
										)}
									/>
									<img src={SelectedProgram?.image} />
								</div>
								<div
									className={`program-button-desktop mt-1 ${isFixed ? 'd-block' : 'd-none'
										}`}
									onClick={() => {
										navReq(
											`${SelectedProgram?.url as string}?program_title=${SelectedProgram?.program_title
											}`
										)
										// !SelectedProgram.StartDate
										// 	? navReq(SelectedProgram.Url as string)
										// 	: undefined
									}}
								>
									<Row>
										<Col sm={8} md={9} xl={10}>
											<h4
												style={{ fontSize: '20px' }}
												className="text-right mt-md-4"
											>
												{
													// SelectedProgram?.StartDate ||
													'Select Program'}
											</h4>
										</Col>
										<Col
											className="arrow-forward-icon-container"
											sm={4}
											md={3}
											xl={2}
										>
											<ArrowForwardIosIcon fontSize="large" htmlColor="#FFF" />
										</Col>
									</Row>
								</div>
							</Col>
							<Col md={5}>
								<div className="content bg-main px-3 pt-0 mt-n4">
									{SelectedProgram?.program_infos?.map((info: any, infokey) => (
										<div key={infokey}>
											<h4 className="subtitle mt-4">
												<b>{info.subtitle}</b>
											</h4>
											{info.paragraphs?.map((text: string, key: number) =>
												SelectedProgram.program_infos &&
													SelectedProgram.program_infos?.length > 1 &&
													infokey == 0 &&
													key == 0 ? (
													<h3 key={key}>{text}</h3>
												) : (
													<p key={key}>{text}</p>
												)
											)}
										</div>
									))}
								</div>
							</Col>
						</Row>
					</RSNavCont>
				</div>
			</>
		)
	}

export default WithAuth(ProgramSplash)
