import App from './components/App'
// import { StrictMode } from 'react'
import { render } from 'react-dom'
import { GoogleOAuthProvider } from '@react-oauth/google'
import { API_URL, GOOGLE_CLIENT_ID } from './modules/cliEnvValues'
import { WebSocketProvider } from './WebsocketProvider'
import { SWRConfig } from 'swr'
import axios from 'axios'
import { HashRouter } from 'react-router-dom'
import SessionCheck from './components/session/SessionCheck'

const fetcher = (url: string) => axios.get(API_URL + url, { withCredentials: true }).then(res => res.data)
const sessionFallback = () => <h1>Runsmart Maintenance</h1>

// Mount App
render(
	// <StrictMode>
	<SessionCheck fallback={sessionFallback} main={true} return={true}>
		<GoogleOAuthProvider clientId={GOOGLE_CLIENT_ID}>
			<WebSocketProvider>
				<SWRConfig
					value={{
						revalidateIfStale: true,
						errorRetryInterval: 5000,
						fetcher: fetcher
					}}
				>
					<HashRouter>
						<App />
					</HashRouter>
				</SWRConfig>
			</WebSocketProvider>
		</GoogleOAuthProvider>
	</SessionCheck>,
	// </StrictMode>,
	document.getElementById('root')
)
