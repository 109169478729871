/* eslint-disable @typescript-eslint/no-unused-vars */
import AppMenus from '../components/layout/AppMenus'
import RsHero from '../components/layout/RsHero'
import InfoRow from '../components/page-specific/home/InfoRow'
import ContinueRow from '../components/page-specific/home/ContinueRow'
import ProgramsRow from '../components/page-specific/home/ProgramsRow'
import WebinarsRow from '../components/page-specific/home/WebinarsRow'
import TrainingGuidesRow from '../components/page-specific/home/TrainingGuidesRow'
import useHomePage from '../hooks/useHomePage'
import useAppStorage from '../hooks/useAppStorage'
import GenericAlert from '../components/misc/GenericAlert'
import useAlert from '../hooks/useAlert'
import BillingUpdateMessage from '../components/misc/BillingUpdateMessage'
import { CHANGE_ACCOUNT, AUTH_COOKIE } from '../modules/cliEnvValues'
import { navReq } from '../modules/apiConsume'
import { AuthPageProps } from '../modules/types'
import { FC, useEffect, useState } from 'react'
import useDeviceDetect from '../hooks/useDeviceDetect'
import WithAuth from '../components/WithAuth'
import useSuggestedPrograms from './onboarding/useSuggestedPrograms'
import { ISuggestedProgram } from '../api/v2/suggested-programs/types'
import { ISurveyStore } from '../store/createSurveyStore'
import useStore from '../store/useStore'

import { fadeIn } from 'react-animations'
import styled, { keyframes } from 'styled-components'
import useIsMobileScreen from '../hooks/useIsMobileScreen'
import { ICurrentUser } from '../store/createAccountStore'
import { IComponentStore } from '../store/createComponentStore'

/**
 * Home Component
 */
// Animations
const FadeIn = keyframes`${fadeIn}`

const DivFadeIn = styled.div`
	animation: 0.2s ${FadeIn};
`

const Home: FC<AuthPageProps> = ({ userInfo, hasLoaded }) => {

	const mobileScreen = useIsMobileScreen()
	// Properties
	const hasCookie = document.cookie.includes(`${AUTH_COOKIE}=`)

	// GetQueryString
	const queryString = window.location.search

	//Get Suggested Programs
	const { getWhatToShowProgram } = useSuggestedPrograms(
		userInfo?.account_id as number
	)

	//State
	// const [currentProgram, setCurrentProgram] = useState<ISuggestedProgram>()
	const { currentProgram, setCurrentProgram } = useStore(
		(state: ISurveyStore) => state
	)

	const { isMobile } = useStore((state: IComponentStore) => state)

	// Hooks
	const { isIos } = useAppStorage()
	const { infoRow, continueRow, programsRow, webinarsRow, guidesRow, header } =
		useHomePage({
			isIos: isIos || false,
			userInfo,
			isMobile: isMobile || false,
		})
	const {
		showAlert,
		onAlertOpen,
		onAlertClose,
		alertTitle,
		alertMessage,
		setAlertTitle,
		setAlertMessage,
		alertVariant,
		setAlertVariant,
	} = useAlert()

	// On Hero Play
	const onHeroPlay = () => navReq(header.mainLink)

	// On Hero Like
	const onHeroLike = () => 0

	// On Update Billing Close
	const onUpdateBillingClose = () => navReq('/#/update-billing')

	// User Info Change Effect
	useEffect(() => {
		if (CHANGE_ACCOUNT && userInfo?.account_id != null) {
			const isValid = userInfo?.paymentType === "coupon" ? true : userInfo?.is_valid
			if (isValid) {
				onAlertClose()
			} else {
				setAlertVariant('danger')
				setAlertTitle('')
				setAlertMessage(<BillingUpdateMessage />)
				onAlertOpen()
			}
		}
	}, [userInfo])

	useEffect(() => {
		if (queryString && queryString.includes('irclickid'))
			navReq('/#/signup' + queryString)
	}, [queryString])

	useEffect(() => {
		if (hasLoaded && !currentProgram) {
			getWhatToShowProgram(userInfo?.join_reason).then((res) => {
				setCurrentProgram(res as ISuggestedProgram)
			})
		}
	}, [hasLoaded, currentProgram])

	useEffect(() => {
		localStorage.setItem('page', '')
		// localStorage.setItem('google-cast-status', 'inactive')
	}, [])

	const PageContainer = () => {
		return (
			<>
				{/* <div style={{ margin: '80px 0px -32px 0px' }} className='w-100 text-center d-block d-lg-none'>
					<button style={{ width: '100px', padding: '3px' }} className='pill mx-2 rounded-pill border-0 text-white bg-tertiary'>My Day</button>
					<button style={{ width: '100px', padding: '3px' }} className='pill mx-2 rounded-pill border-0 text-black bg-white'>My Stats</button>
				</div> */}
				<RsHero
					user={userInfo?.first_name || ''}
					bgImage={
						(currentProgram?.Featured_img?.replace('\\', '')
							.replace('\\', '/')
							.replace('img', 'featured-programs') as string || '')
					}
					title={currentProgram?.Featured_Title || ''}
					subtitle={currentProgram?.Featured_Subtitle as string || ''}
					// actionText={currentProgram && currentProgram?.Program.includes('Guide') ? 'View' : currentProgram ? 'View' : ""}
					actionText="View"
					detailsLink={currentProgram?.Url || ''}
					brightness={0.6}
					onAction={currentProgram?.Url || ''}
					onLike={onHeroLike}
				/>
				{/* Info Row */}
				<InfoRow cards={infoRow} />
				{/* Continue Training Row */}
				<ContinueRow isMobile={isMobile} videos={continueRow} />

				{/* Top Programs Row */}
				{/* <ProgramsRow programs={programsRow} /> */}

				{/* Webinars Row */}
				{/* <WebinarsRow webinars={webinarsRow} /> */}

				{/* Training Guides */}
				{/* <TrainingGuidesRow guides={guidesRow} /> */}
			</>
		)
	}

	// Render
	return (
		<>
			{/* Alert */}
			<GenericAlert
				show={showAlert}
				title={alertTitle}
				message={alertMessage}
				variant={alertVariant}
				onClose={onUpdateBillingClose}
				mustClose
			/>

			{/* Page */}
			<AppMenus hasContainer hasLoaded={hasCookie || hasLoaded}>
				{mobileScreen ? (
					<DivFadeIn>
						<PageContainer />
					</DivFadeIn>
				) : (
					<PageContainer />
				)}
			</AppMenus>
		</>
	)
}

export default WithAuth(Home)
