import Navbar from 'react-bootstrap/Navbar'
import Container from 'react-bootstrap/Container'
import { NAVBAR_BOTTOM_ID, BottomMenuLinks } from './rsMenuHelpers'
import { FC } from 'react'

/**
 * RsBottomMenu Component
 */

const RsBottomMenu: FC = () => {
	// Render
	return (
		<Navbar id={NAVBAR_BOTTOM_ID} fixed="bottom" bg="light" expand>
			<Container className="d-block">
				<BottomMenuLinks />
			</Container>
		</Navbar>
	)
}

export default RsBottomMenu
