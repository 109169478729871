import WithAuth from '../../../components/WithAuth'
import AppMenus, { BodyComponent } from '../../../components/layout/AppMenus'
import RSNavCont from '../../../components/layout/RSNavCont'
import RSHeaderCont from '../../../components/layout/RSHeaderCont'
import { FC, useEffect, useState } from 'react'
import { Container, Row, Col, Button, Toast, ToastContainer, Spinner, Alert, Card } from 'react-bootstrap'
import Form from 'react-bootstrap/Form'
import Box from '@mui/material/Box';
import ArrowBack from '@mui/icons-material/ArrowBackIos'
import CustomPopOver from '../../../components/custom-popover'
import { useLocation, useNavigate } from 'react-router-dom'
import { IRaceDetails } from './RaceType';
import useStore from '../../../store/useStore'
import { ITrainingPlanStore } from '../../../store/createTrainingPlanStore'
import { CreateTrainingPlan, CheckActivePlan, UpdateTrainingPlan, GetUserInfo } from '../../../modules/trainingPlanActions'
import { ICreateTrainingPlanParams, ITimeObject } from '../../../api/v2/types'
import { ICurrentUser } from '../../../store/createAccountStore'
import GenericAlert from '../../../components/misc/GenericAlert'
import useAlert from '../../../hooks/useAlert'
import { IComponentStore } from '../../../store/createComponentStore'
import useTrainingPlanUtils from '../../../hooks/useTrainingPlanUtils'
import { guideText } from '../../../modules/values'
import moment from 'moment'
import Progress from './Progress'
import useQueryParams from '../../../hooks/useQueryParams'
import Loader from '../../../components/animation/Loader'
import RSButton from '../../../components/buttons/RSButton'
import useIsMobileScreen from '../../../hooks/useIsMobileScreen'
import { UpdateAccountProfile } from '../../../modules/accountActions'

/**
 * Programs Component
 */

const StepThree: FC = () => {
	const navigate = useNavigate()
	const url = useLocation()
	const isMobile = useIsMobileScreen();

	const { level } = useQueryParams()

	// Get Current User
	const { currentUser } = useStore((state: ICurrentUser) => state)

	const { isLateStart: IsLateStart } = useTrainingPlanUtils()
	//get selected race type from store
	const [selectedDetails, setSelectedDetails] = useState<IRaceDetails | null | undefined>(null);
	const { selectedRace, selectedRaceDate, setAgreedCreateNewPlan, setCurrentPath, setHasActivePlan, setSelectedRaceDate, setUserOverrides, setUserRestComments, setUserVdotAdjustments } = useStore((state: ITrainingPlanStore) => state)
	//training length switch value
	const [TLSwitch, setTLSwitch] = useState<boolean>(false);
	//selected Weeks
	const [selectedWeeks, setSelectedWeeks] = useState<number | undefined>(0);
	const [units, setUnits] = useState<Array<string>>(["Miles", "Kilometers"]);
	const [selectedUnit, setSelecteUnit] = useState<string>("Miles");
	const [unitSwitch, setUnitSwitch] = useState<boolean>(false);
	const [distance, setDistance] = useState<number>(0);
	const [distanceIndex, setDistanceIndex] = useState<number>(0);
	const [isMondayStart, setIsMondayStart] = useState<boolean>(false);
	const [timeType, setTimeType] = useState<string>("M");
	const [mTime, setMTime] = useState<ITimeObject>({ hr: 0, min: 0, sec: 0 });
	const [selectedTime, setSelectedTime] = useState<string>("0:0:0");
	const [aggressiveness, setAggressiveness] = useState<number>(0);
	const [isNoSetTime, setIsNoSetTime] = useState(false);
	const [hasErrorSaving, setHasErrorSaving] = useState(false);
	const [isSaving, setIsSaving] = useState(false);
	const [isSuccessSave, setIsSuccessSave] = useState(false);
	const [isLateStart, setIsLateStart] = useState(false);
	const [createdPlan, setCreatedPlan] = useState<any>();

	const { setToast } = useStore((state: IComponentStore) => state)
	const updateProfileMeasurementSystem = () => {

	}
	const handleBuildPlanSubmit = async (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
		const abortController = new AbortController()

		const data: ICreateTrainingPlanParams = {
			userId: currentUser?.account_id as number,
			marathon: selectedRace?.value as number,
			weeks: selectedWeeks as number,
			mileage: distance,
			plan: timeType,
			time: mTime,
			date: selectedRaceDate as string,
			km: selectedUnit === "Miles" ? false : true,
			agg: aggressiveness,
			monday: isMondayStart,
			vdot_adj: false,
			training_level: Number(level)
		}

		setIsSaving(true)
		const timeTotal = mTime.hr + mTime.min + mTime.sec
		if (timeTotal > 0 && !isSaving && currentUser?.account_id) {

			CreateTrainingPlan(data).then(async (res) => {
				if (res.status === "ok") {
					await GetUserInfo(currentUser?.account_id as number, abortController.signal).then(
						async (res) => {
							const info = res?.data?.result[0]
							await UpdateAccountProfile({ user_id: currentUser?.account_id, measurement_system: selectedUnit === "Miles" ? 'imperial' : 'metric' })
							setCreatedPlan(info)
							setIsSaving(false)
							setIsSuccessSave(true)
						}
					)
				}
				else {
					setIsSaving(false)
					setIsSuccessSave(false)
					setHasErrorSaving(true)
				}
			})
			// await CheckActivePlan(currentUser?.account_id as number).then(res => {
			// 	if (res.data.result && res.status === "ok") {
			// 		UpdateTrainingPlan(data).then((res) => {
			// 			if (res.status === "ok" && res.data.messages === "success") {
			// 				setIsSaving(false)
			// 				setIsSuccessSave(true)
			// 			}
			// 			else {
			// 				setIsSaving(false)
			// 				setIsSuccessSave(false)
			// 				setHasErrorSaving(true)
			// 			}
			// 		})
			// 	}
			// 	else {
			// 		CreateTrainingPlan(data).then((res) => {
			// 			if (res.status === "ok") {
			// 				setIsSaving(false)
			// 				setIsSuccessSave(true)
			// 			}
			// 			else {
			// 				setIsSaving(false)
			// 				setIsSuccessSave(false)
			// 				setHasErrorSaving(true)
			// 			}
			// 		})
			// 	}
			// })

		}
		else {
			setIsNoSetTime(true)
			setIsSaving(false)
		}
	}

	const loaderData = {
		title: 'Building your plan.',
		steps: [
			'Adding runs to the mix.',
			'Enhancing with Physical Therapist-designed exercises',
			'Personalizing your pacing and workouts.'
		],
		finishedTitle: 'Finished!',
		finishedDesc: 'Your plan is ready.'
	}

	const handleDayStartChange = (day: string) => {
		day === "Monday" ? setIsMondayStart(true) : setIsMondayStart(false)
	}

	const handleTimeTypeChange = (timeType: string) => {
		timeType === "FivekTime" && setMTime({ ...mTime, hr: 0 })
		timeType === "FivekTime" ? setTimeType("5k") : selectedRace?.value == 0 && timeType === "desiredFinishTime" || selectedRace?.value == 4 && timeType === "halfMarathonTime" ? setTimeType("hm") : setTimeType("M")
	}

	const handleSuccessSave = () => {
		if (isSuccessSave) {
			navigate(`/training-plan/view?planId=${createdPlan?.uid}`, { replace: true })
		}
	}

	useEffect(() => {
		if (selectedRaceDate)
			selectedRace?.value != 4 ?
				setIsLateStart(IsLateStart(selectedRaceDate as string, selectedWeeks as number))
				: setIsLateStart(false)
	}, [selectedRaceDate, selectedRace, selectedWeeks]);

	useEffect(() => {
		selectedRace && selectedRaceDate ? setSelectedDetails(selectedRace?.details) : navigate('/training-plan/build/step-1', { replace: true })
		setDistance(selectedRace?.details?.milesValues[0] as number)
	}, [selectedRace, selectedRaceDate])

	useEffect(() => {
		let index: number = 0;
		TLSwitch ? index = 1 : 0;

		selectedRace?.weekOptions.length == 2 ? setSelectedWeeks(selectedRace?.weekOptions[index]) : setSelectedWeeks(selectedRace?.weekOptions[0])

	}, [TLSwitch, selectedRace])

	useEffect(() => {
		unitSwitch ? setSelecteUnit("Kilometers") : setSelecteUnit("Miles");
	}, [unitSwitch, selectedRace])

	useEffect(() => {
		setDistanceIndex(selectedRace?.details?.milesValues.findIndex(val => val == distance) as number)
	}, [distance])

	useEffect(() => {
		setSelectedTime(`${mTime.hr}:${mTime.min}:${mTime.sec}`)
	}, [mTime])

	useEffect(() => {
		setToast({
			show: isNoSetTime,
			title: "Validation Error",
			message: "Desired finish time is required.",
			variant: "danger",
			onClose: () => setIsNoSetTime(false),
			duration: 3000
		})
	}, [isNoSetTime])

	useEffect(() => {
		setToast({
			show: hasErrorSaving,
			title: "Error Message",
			message: "An error occurred while saving the training plan, please try again.",
			variant: "danger",
			onClose: () => setHasErrorSaving(false),
			duration: 5000
		})
	}, [hasErrorSaving])

	useEffect(() => {
		return () => {
			setAgreedCreateNewPlan(false);
			setHasActivePlan(false)
			setCurrentPath('')
			setUserOverrides([])
			setUserVdotAdjustments([])
			setUserRestComments([])
		};
	}, []);


	// Render
	return (
		<>
			<Loader active={isSaving} isError={hasErrorSaving} onEnd={handleSuccessSave} data={loaderData} bg='white' />
			<RSNavCont hideFooter={true} hideHeader={isMobile}>
				<RSHeaderCont isStatic={!isMobile} title='Cancel' noShadow={!isMobile} barSX={{ backgroundColor: isMobile ? 'white' : 'transparent' }} closeClick={() => navigate('/explore')} />
				<div className="build-plane-stepthree build-plan regular-container pt-5">

					<Container>
						<Progress progress={Number(url.pathname.split('/')[3].split('-')[1])} />
						<div className="build-plane-wrap">
							<div className="topbar-info text-center">
								{/* <h3 className="letter-space mb-0 font-md-15 text-uppercase">
									BUILD A NEW PLAN{' '}
									<span className="d-none d-md-inline-block mb-0 step-label h3">
										{' '}
										STEP Three
									</span>
									<br />
								</h3>
								<hr className="border-bottom-primary mt-md-4 mb-4 d-none d-md-block" />
								<h4 className="text-normal mt-4 pt-2 lh-lg text-initial d-md-none h1 font-md-18 text-uppercase">
									STEP THREE:
								</h4>
								<hr className="border-bottom-primary mt-3 mt-md-4 d-md-none" /> */}
								<h1 className="text-caps mt-4 mt-md-0">Customize Your Plan</h1>
								{/* <hr className="border-bottom-primary mt-md-4 d-none d-md-block" /> */}
							</div>
							<Card
								hidden={!isLateStart}
								bg={'danger'}
								text={'white'}
								className="mb-4 mx-auto w-auto-view"
							// style={{ width: '90%' }}
							>
								{/* <Card.Header>Warning</Card.Header> */}
								<Card.Body>
									{/* <Card.Title> Card Title </Card.Title> */}
									<Card.Text>
										{guideText.LateStartWarning}
									</Card.Text>
								</Card.Body>
							</Card>


							<div className="mw-400 mx-md-auto mx-sm-auto mx-4 mt-3">
								<Form>
									<div className="swich-list">
										<div className="d-flex justify-content-between">
											<div className="mt-3 mw-170">
												<p className="font-15 font-md-12 font-sm-9 text-uppercase text-gray m-0">
													LENGTH OF TRAINING
												</p>

												{
													selectedRace?.weekOptions.length == 2 ? (
														<>
															<div className="text-center">
																<Form.Check type="switch" id="custom-switch" onChange={(e) => setTLSwitch(e.target.checked)} />
															</div>
															<div className="d-flex justify-content-center pt-2 ">
																<p className="font-14 plan-text-small font-md-12 mb-0 me-3 plan--text">
																	{selectedRace?.weekOptions[0]} Weeks
																</p>
																<p className="font-14 plan-text-small mt-10 font-md-12 mb-0 plan--text">
																	{selectedRace?.weekOptions[1]} Weeks
																</p>
															</div>
														</>

													) : (
														<div className="mx-4 px-1" style={{ marginTop: 6, textAlign: 'center' }}>
															<input id="Default" type="radio" checked disabled></input>
															<label htmlFor='Default' >
																<span className="font-md-12 plan--text plan-text-small">{selectedRace?.weekOptions[0]}   Weeks
																</span>
															</label>
														</div>
													)
												}

											</div>
											<div className="mt-3 mw-170">
												<p className="font-15 font-md-12 font-sm-9 text-uppercase text-gray m-0">
													CHOOSE YOUR UNITS
												</p>
												<div className="text-center">
													<Form.Check type="switch" id="custom-switch" onChange={(e) => setUnitSwitch(e.target.checked)} />
												</div>
												<div className="d-flex justify-content-center pt-2 ">
													<p className={`font-14 plan-text-small ${selectedRace?.value.toString() === "0" || selectedRace?.value.toString() === "1" ? '' : 'font-margin-plan--units'} mb-0 me-3 font-md-12 plan--text`}>
														{units[0]}
													</p>
													<p className={`font-14 plan-text-small ${selectedRace?.value.toString() === "0" || selectedRace?.value.toString() === "1" ? '' : 'font-margin-plan--units'} mb-0 me-3 font-md-12 plan--text`}>
														{units[1]}
													</p>
												</div>
											</div>
										</div>
									</div>
									<div className='build-plan-wrap'>
										<p className="font-15 font-md-12 font-sm-13 text-uppercase text-gray m-0 text-center mt-2">
											WHAT DAY STARTS YOUR WEEK?
										</p>
										<div className="d-flex justify-content-center mt-2 what-day">
											<div className="mx-4 px-1">
												<input
													type="radio"
													id="Sunday"
													name="radio-group"
													defaultChecked
													onChange={(e) => handleDayStartChange(e.target.id)}
												></input>
												<label htmlFor="Sunday">
													<span className="font-md-12 plan--text">Sunday</span>
												</label>
											</div>
											<div className="mx-4 px-1">
												<input
													type="radio"
													id="Monday"
													name="radio-group"
													onChange={(e) => handleDayStartChange(e.target.id)}
												></input>
												<label htmlFor="Monday">
													<span className="font-md-12 plan--text">Monday</span>
												</label>
											</div>
										</div>
									</div>
								</Form>
							</div>
							<div className="mx-lg-5 px-lg-5 px-5 mt-2 mb-2">
								<Row>
									<Col md={12}>
										<div className="mt-2 mx-3 w-auto-view mx-auto">
											<div className="font-sm-9 font-15 text-uppercase text-center d-flex align-items-center justify-content-center text-gray">
												CHOOSE WEEKLY MILEAGE
												<div className="info-wrap distance-info-wrap">
													<CustomPopOver
														icon={
															<img
																className="ms-1 align-middle pointer"
																src="./images/icons/info.png"
																alt="Info"
															/>
														}
													>
														<p className="font-12 m-0 text-gray font-weight-semibold">
															Select the distance you will run at your peak in
															training.
														</p>
														<p className="font-12 m-0">
															Note: The distance chosen below is not your weekly
															distance, but rather the maximum distance you’ll
															run in training when you reach your highest
															volume.
														</p>
													</CustomPopOver>
												</div>
											</div>
											<div className="d-flex justify-content-between">
												{
													selectedUnit === "Miles" ?
														selectedDetails?.milesValues.map((val, i) => (
															<span key={i} className="text-gray">{val}</span>
														))
														:
														selectedDetails?.kmsValues.map((val, i) => (
															<span key={i} className="text-gray">{val}</span>
														))
												}
												{/* <span className="text-gray">30</span>
												<span className="text-gray">40</span>
												<span className="text-gray">50</span>
												<span className="text-gray">60</span> */}
											</div>
											<Form.Range
												step={10}
												min={selectedDetails?.milesValues[0]}
												max={selectedDetails?.milesValues[selectedDetails?.milesValues.length - 1]}
												defaultValue={distance}
												onChange={(e) => { setDistance(+e.target.value) }}
											/>
											<div className="text-center">
												<p className="m-0 font-14 font-md-12 plan--text">
													{selectedUnit === "Miles" ? selectedDetails?.milesPerWeek[distanceIndex] : selectedDetails?.kmsValues[distanceIndex]} {selectedUnit} per Week
												</p>
												<p className="font-14 font-md-12 plan--text">
													{selectedDetails?.runsPerWeek[distanceIndex]} Runs per Week
												</p>
											</div>
										</div>
									</Col>
									{/* <Col md={6}>
										<div className="mx-3 build-plan-wrap adjust-speed-workout">
											<div className="font-sm-9 font-15  font-margin-build text-uppercase text-center d-flex align-items-center justify-content-center text-gray pb-2 pb-md-0">
												ADJUST SPEED WORKOUTS
												<div className="info-wrap distance-info-wrap">
													<CustomPopOver
														icon={
															<img
																className="ms-1 align-middle pointer"
																src="./images/icons/info.png"
																alt="Info"
															/>
														}
													>
														<p className="font-12 m-0 text-gray font-weight-semibold">
															Select the distance you will run at your peak in
															training.
														</p>
														<p className="font-12 m-0">
															Note: The distance chosen below is not your weekly
															distance, but rather the maximum distance you’ll
															run in training when you reach your highest
															volume.
														</p>
													</CustomPopOver>
												</div>
											</div>
											<div className="mt-lg-27"></div>
											<Form.Range defaultValue={1} max={3.5} step={0.5} onChange={(e) => setAggressiveness(+e.target.value)} />
											<div className="d-flex justify-content-between">
												<span className="text-gray plan--text">Harder</span>
												<span className="text-gray plan--text">Easier</span>
											</div>
										</div>
									</Col> */}
								</Row>
							</div>
							<div className="build-plan-wrap">
								<p className="font-15 font-md-12 font-sm-13 text-uppercase text-gray m-0 text-center mt-2">
									BUILD PLAN BASED ON ONE OF THE FOLLOWING
								</p>
								<div className="f-box d-flex justify-content-center mt-2">
									<div className="r-box timeType">
										<input
											type="radio"
											id="desiredFinishTime"
											name="radio-group"
											defaultChecked
											onChange={(e) => handleTimeTypeChange(e.target.id)}
										></input>
										<label htmlFor="desiredFinishTime">
											<span className="font-md-12 plan--text plan-text-small">
												{selectedRace?.value == 4 ? "Marathon Time" : "Desired Finish Time"}
											</span>
										</label>
									</div>
									{selectedRace?.value == 4 && (
										<div className="r-box timeType">
											<input
												type="radio"
												id="halfMarathonTime"
												name="radio-group"
												onChange={(e) => handleTimeTypeChange(e.target.id)}
											></input>
											<label htmlFor="halfMarathonTime">
												<span className="font-md-12 plan--text plan-text-small">
													Half Marathon Time
												</span>
											</label>
										</div>
									)}
									{selectedRace?.value.toString() === "0" || selectedRace?.value.toString() === "1" || selectedRace?.value.toString() === "4" ? (
										<div className="r-box timeType">
											<input
												type="radio"
												id="FivekTime"
												name="radio-group"
												onChange={(e) => handleTimeTypeChange(e.target.id)}
											></input>
											<label htmlFor="FivekTime">
												<span className="font-md-12 plan--text plan-text-small">5K Time</span>
											</label>
										</div>
									) : null}
								</div>
								<div className="input-box">
									<div className="d-flex justify-content-center mt-3">
										{

											selectedRace?.value.toString() === "0" && timeType !== "5k" || selectedRace?.value.toString() === "1" && timeType !== "5k" || selectedRace?.value.toString() === "4" && timeType !== "5k" ? (
												<input type="number" min={0} onChange={(e) => setMTime({ ...mTime, hr: +e.target.value })} placeholder="HH"></input>
											) : null

										}
										<input type="number" min={0} onChange={(e) => setMTime({ ...mTime, min: +e.target.value })} placeholder="MM"></input>
										<input type="number" min={0} onChange={(e) => setMTime({ ...mTime, sec: +e.target.value })} placeholder="SS"></input>
									</div>
								</div>
							</div>
							<Box sx={{ width: '100%', display: 'flex', justifyContent: 'center' }}>
								<RSButton
									sx={{ width: '280px', mt: 4 }}
									disabled={isSaving || isSuccessSave}
									onClick={(e: any) => handleBuildPlanSubmit(e)}
								>
									<Spinner
										className={!isSaving ? "visually-hidden" : ""}
										as="span"
										animation="border"
										size="sm"
										role="status"
										aria-hidden="true"
										style={{ marginRight: 10 }}
									/>
									{isSaving ? "Building Plan..." : isSuccessSave ? "Finalizing Plan..." : "Build Plan"}
								</RSButton>
							</Box>
						</div>
					</Container>
				</div >
			</RSNavCont >
		</>
	)
}

export default WithAuth(StepThree)
