import { getVideoProgressDataV2 } from '../modules/accountActions'
import { AuthPageProps } from '../modules/types'
import { JOIN_REASONS } from '../modules/values'
import { useEffect } from 'react'
import { Video } from '../modules/videos/allVideos'
import phpVideoIds from '../modules/videos/php-video-ids.json'
import useStore from '../store/useStore'
import { IVideoStore } from '../store/createVideoStore'
// import { IComponentStore } from '../store/createComponentStore'

// Progress Info
export type ProgressInfo = {
	progress: number
	isComplete: boolean
	videoId?: number
}

// Get Progress Data
export const getProgressData = async (
	videos: Video[],
	userInfo: AuthPageProps['userInfo'],
	data?: any,
	infoOnly?: boolean,
	autoReload?: boolean
) => {
	// console.log(data)
	let newData: any;
	const newInfo: ProgressInfo[] = []
	const accountId = userInfo?.account_id || 0
	const joinReason =
		JOIN_REASONS[
		(userInfo?.join_reason || 'strength') as keyof typeof JOIN_REASONS
		]
	if (userInfo?.account_id != null && !infoOnly) {
		newData = (await getVideoProgressDataV2(accountId, joinReason))?.data?.result || {}
		newData = newData?.filter((a: any, i: any) => newData?.findIndex((s: any) => a.id === s.id) === i)
	}
	for (const video of videos) {
		const videoId = video?.id
		const phpKey = Number((phpVideoIds as any)[videoId]) || Number(video?.id)
		const videoInfo = newData?.find((x: any) => x.id == phpKey) || newData?.find((x: any) => x.url == phpKey) || {}
		const videoProgress: number[] = videoInfo.progress ? JSON.parse(videoInfo.progress) : [0];
		const progress = videoInfo.progress ? videoProgress[videoProgress.length - 1] : 0 || 0
		const isComplete = progress >= 80
		newInfo.push({ progress, isComplete, videoId: phpKey })
	}
	return { newData, newInfo, autoReload }
}

/**
 * Use Video Progress Hook
 */

const useVideoProgress = ({
	videos,
	userInfo,
	autoReload,
	realoadTriggeer,
	forceReload
}: {
	videos: Video[]
	userInfo: AuthPageProps['userInfo'],
	autoReload?: boolean,
	realoadTriggeer?: any,
	forceReload?: any
}) => {
	const { setVideoProgressData, setVideoInfo, videoInfo, videoProgressData } = useStore((state: IVideoStore) => state)
	// const { setIsLoading } = useStore((state: IComponentStore) => state)

	// // State
	// const [isLoadingData, setIsLoadingData] = useState(false)
	// const [info, setInfo] = useState<ProgressInfo[]>([])

	// Set Progress Data
	const setProgressData = async () => {
		// setIsLoading(autoReload ? false : true)
		const { newData, newInfo } = await getProgressData(videos, userInfo, videoProgressData, false, autoReload)
		// setIsLoading(false)
		setVideoProgressData(newData)
		setVideoInfo(newInfo)
	}

	// Mount Effect
	useEffect(() => {
		if (userInfo?.account_id && realoadTriggeer || userInfo?.account_id && forceReload)
			setProgressData()

	}, [userInfo, realoadTriggeer, forceReload])

	return videoInfo
}

export default useVideoProgress
