import {FC, useState, useEffect} from 'react'

import Grid from '@mui/material/Grid'
import Box from '@mui/material/Box'

import RSButton from '../../components/buttons/RSButton'
import { AccountProfile } from '../../api/v2/account/Account.types'

export type PhysicalActivityProps = {
	data: AccountProfile,
	handleUpdateProfile: Function,
}

const PhysicalActivity: FC<PhysicalActivityProps> = ({data, handleUpdateProfile}) => {
	const {physical_activity} = data
	const [physicalActivity, setPhysicalActivity] = useState<any>(physical_activity || ''  );

	const activities = [{
		title: 'Little to No Exercise'
	},{
		title: 'Slightly Active',
		desc: 'Light, 1 - 3 times per week'
	},{
		title: 'Moderately Active',
		desc: 'Moderate intensity, 4 - 5 times per week'
	},{
		title: 'Very Active',
		desc: 'Daily exercise or training for half marathon'
	},{
		title: 'Extra Active',
		desc: 'Exercise more than 1x/day training for >= 26.2'
	}]

	const handleSave = async () => {
		handleUpdateProfile({ physical_activity: physicalActivity })
	}

	useEffect(()=>{
		setPhysicalActivity(physical_activity)
	},[physical_activity])
	return (
		<Box>
			<Box sx={{fontSize: '16px', fontFamily: 'Poppins-Medium', pb: 1}}>How would you rate your current physical activity?</Box>
			<Grid container xs={12} justifyContent='space-between'>
				{activities.map((activity: any, ai: number)=>{
					const {title, desc} = activity
					const isActive = title?.toLowerCase() === physicalActivity?.toLowerCase()
					return <Grid item container xs={5.6} key={'activity-'+ai} sx={{minHeight: '100px', width: '100px', mb: '20px'}} justifyContent='center' alignItems='center'>
						<RSButton card secondary={!isActive} sx={{height: '100%', flexDirection: 'column'}} onClick={()=>setPhysicalActivity(title)}>
							<Box sx={{fontFamily: 'Poppins-Medium !important', fontSize: '14px !important', textAlign: 'center', lineHeight: '1em', pb: '5px'}}>{title}</Box>
							{activity.desc && <Box sx={{fontFamily: 'Poppins-light !important', fontSize: '10px !important', lineHeight: '1.2em', textAlign: 'center'}}>{desc}</Box>}
						</RSButton>
					</Grid>
				})}
			</Grid>
			<RSButton onClick={handleSave} disabled={!physicalActivity || physicalActivity?.toLowerCase() === physical_activity?.toLowerCase() } sx={{mt: 1}}>Save</RSButton>
		</Box>
	)
}

export default PhysicalActivity;
