import { FC, useState, useEffect, useRef } from 'react'
import { motion, AnimatePresence } from "framer-motion";

import Lottie from 'lottie-react'
import Box from '@mui/material/Box';

import RunningLoader from './runsmart_loader.json'
import { LoaderProps } from '../../modules/types'


const Loader: FC<LoaderProps> = ({active = true, bg, iconBg, onEnd, data, isInside, isBehindHeader, stage, stageTitle, stageDesc, loader, buffer}) => {
	const [show, setShow] = useState(false)
	const [currentTime, setCurrentTime] = useState(0)
	const [isDone, setIsDone] = useState(false)
	const [isRedirect, setIsRedirect] = useState(false)
	const [loaderGIF, setLoaderGIF] = useState(loader || RunningLoader)

	const timer = useRef<any>({ current: '' });
	const time = useRef<any>({ current: 1 });
	const hw = data || stage ? 'fit-content' : '120px'
	const dataSteps = data?.steps
	const delay = dataSteps?.length ? dataSteps.length * 3 : 0
	let activeData = dataSteps?.length && delay ? Math.floor(currentTime / 3) % dataSteps.length : 0
	activeData = activeData < 0 ? 0 : activeData
	let closeTimeout: any = undefined

	const height = stage && stageTitle ? 170 : 100
	const width = stage && stageTitle ? 200 : 100

	useEffect(() => {
		return () => {
			timer['current'] && clearInterval(timer['current'])
		}
	}, [])

	useEffect(() => {
		if (!delay) {
			timer['current'] && clearInterval(timer['current'])
			setCurrentTime(0)
			time['current'] = 1
		}
	}, [delay])

	useEffect(() => {
		if (delay) {
			if (currentTime >= delay && !active) {
				setIsDone(true)
				clearInterval(timer['current'])
			}
		}
	}, [currentTime, active])

	useEffect(() => {
		if (isDone) {
			setTimeout(()=>{
				onEnd?.()
				setIsDone(true)
				setShow(false)
			},3000)
			setTimeout(()=>{
				setIsRedirect(true)
			},2000)
		}
	}, [isDone])

	useEffect(() => {
		timer['current'] && clearInterval(timer['current'])
		if (show) {
			if (delay) {
				timer['current'] = setInterval(() => {
					const t = time['current']
					time['current'] = t + 1
					setCurrentTime(t)
				}, 1000)
			}
		} else {
			setCurrentTime(0)
			time['current'] = 1
		}
	}, [show])

	useEffect(() => {
		!show && loader && setLoaderGIF(loader)
	}, [show, loader])

	useEffect(() => {
		if (active) {
			setShow(true)
		} else {
			if (!delay) {
				onEnd?.()
				if (buffer) {
					clearTimeout(closeTimeout)
					closeTimeout = setTimeout(()=>{
						setShow(false)
					},500)
				} else
					setShow(false)
			}
		}
		return () => {
				clearTimeout(closeTimeout)
		}
	}, [active])

	return <>{show &&
		<Box className='loader-cont' sx={{ position: isInside ? 'absolute' : 'fixed', background: bg ? bg : '#ffffffc2', zIndex: isBehindHeader ? 1000 : 2000, minHeight: '200px', top: 0, left: 0 }}>
			<AnimatePresence mode='wait' initial={false}>
				<motion.div
					key={stageTitle ? stageTitle : (isDone ? (isRedirect ? 'x' : 'y') : 'x')}
					initial={{opacity: 0}}
					animate={{opacity: 1,}}
					exit={{opacity: 0}}
					transition={{ duration: isDone || stage ? 1 : 0.5 }}
					style={{display: 'flex', justifyContent: 'center', alignItems: 'center', height: hw, width: hw, backgroundColor: iconBg ? iconBg : 'transparent', borderRadius: '4px'}}
				>
					<Box sx={{ height: `${height}px`, width: `${width}px`}}>
						<Lottie animationData={loaderGIF}/>
					</Box>
				</motion.div>
			</AnimatePresence>

			{stage && stageTitle && <Box sx={{display: 'flex', height: '150px', justifyContent: 'flex-start', alignItems: 'center', flexDirection: 'column', position: 'relative', px: 3, pt: '10px'}}>
				{stageTitle && <AnimatePresence mode='wait' initial={true}>
					<motion.div
						key={stageTitle}
						initial={{opacity: 0, x: '10%'}}
						animate={{opacity: 1, x: 0}}
						exit={{opacity: 0, x: '10%'}}
						transition={{ duration: 1 }}
						style={{fontFamily: 'Poppins-Bold', fontSize: '26px', textAlign: 'center', lineHeight: '1.2em'}}
					>
						{stageTitle}
					</motion.div>
				</AnimatePresence>}

				<AnimatePresence mode='wait' initial={true}>
					<motion.div
						key={stageDesc}
						initial={{opacity: 0, x: '10%'}}
						animate={{opacity: 1, x: 0}}
						exit={{opacity: 0, x: isDone ? 0 : '10%'}}
						transition={{ duration: 1 }}
						style={{width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}
					>
						{stageDesc && active && <Box sx={{maxWidth: '100%', px: 3, display: 'flex', alignItems: 'flex-end', textAlign: 'center', fontFamily: 'Poppins-Light', fontSize: '18px', lineHeight: '1.2em', pt: '10px' }}>
							{stageDesc}
						</Box>}
					</motion.div>
				</AnimatePresence>
			</Box>}


			{data &&  <Box sx={{display: 'flex', height: '100px', justifyContent: 'flex-start', alignItems: 'center', flexDirection: 'column', position: 'relative'}}>
				{data?.title && dataSteps?.length && <AnimatePresence mode='wait' initial={false}>
					<motion.div
						key={ isDone ? (isRedirect ? data.title : activeData) : data.title}
						animate={{opacity: 1, x: 0}}
						exit={{opacity: 0, x: isDone ? 0 : '10%'}}
						transition={{ duration: isDone ? 1 : 0.5 }}
						style={{fontFamily: 'Poppins-Bold', fontSize: '26px'}}
					>
						{isDone ? (data?.finishedTitle ?  data.finishedTitle : '') : data.title}
					</motion.div>
				</AnimatePresence>}

				<AnimatePresence mode='wait' initial={false}>
					<motion.div
						key={isRedirect ? data.title+dataSteps?.length : activeData}
						initial={{opacity: 0, x: '-10%'}}
						animate={{opacity: 1, x: 0}}
						exit={{opacity: 0, x: isDone ? 0 : '10%'}}
						transition={{ duration: isDone ? 1 : 0.5 }}
						style={{width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}
					>
						{dataSteps?.length && delay && data?.finishedDesc && <Box sx={{maxWidth: isDone ? '100%' : '90%', display: 'flex', alignItems: 'flex-end', textAlign: 'center', fontFamily: 'Poppins-Light', fontSize: '18px', lineHeight: '1.2em' }}>
							{isDone ? data.finishedDesc : dataSteps[activeData]}
						</Box>}
					</motion.div>
				</AnimatePresence>
			</Box>}
		</Box>}
	</>
}

export default Loader;
