import { useEffect, useState } from "react"
import { IProgramFavorite } from "../../api/v2/favorites/types"
import { getReq, postReq, putReq } from "../../modules/apiConsume"


const useProgramFavorites = (userId: number, trigger?: any) => {
    const [programFavorites, setProgramFavorites] = useState<IProgramFavorite[]>([])
    const [isLoading, setIsLoading] = useState(false)

    const getUserProgramFavorites = async () => {
        setIsLoading(true)
        const result = await getReq(`/v2/favorites/program?user_id=${userId}`)
        setProgramFavorites(result?.data?.result)
        setIsLoading(false)
        return result?.data?.result as IProgramFavorite[]
    }

    const toggleProgramFavorite = async (programId: number, userId: number, current?: IProgramFavorite) => {

        if (current) {
            const favorited = current?.favorited == 1 ? 0 : 1
            const data: IProgramFavorite = {
                id: current?.id,
                program_id: programId,
                user_id: userId,
                favorited: favorited
            }

            await updateUserProgramFavorites(data)
            await getUserProgramFavorites()
        }
        else {
            const data: IProgramFavorite = {
                program_id: programId,
                user_id: userId,
                favorited: 1
            }

            await createUserProgramFavorites(data)
            await getUserProgramFavorites()
        }

    }

    const createUserProgramFavorites = async (favoriteData: IProgramFavorite) => {
        setIsLoading(true)
        const result = await postReq(`/v2/favorites/program`, favoriteData)
        setIsLoading(false)

        return result?.data?.result
    }

    const updateUserProgramFavorites = async (favoriteData: IProgramFavorite) => {
        setIsLoading(true)
        const result = await putReq(`/v2/favorites/program`, favoriteData)
        setIsLoading(false)

        return result?.data?.result
    }

    useEffect(() => {
        if (userId)
            getUserProgramFavorites()
    }, [userId, trigger])

    return { programFavorites, isLoading, toggleProgramFavorite, getUserProgramFavorites }
}

export default useProgramFavorites