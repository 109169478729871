/* eslint-disable @typescript-eslint/no-unused-vars */
import { useEffect, useState } from "react"
import { getReq, putReq } from "../modules/apiConsume"

export interface ISale {
    id: number;
    headline: string;
    subtext: string;
    banner: string;
    start: Date;
    end: Date;
    active: number;
}

const useSale = () => {
    const [activeSale, setActiveSale] = useState<ISale | undefined>()
    const [allSale, setAllSale] = useState<ISale | undefined>()
    const [isLoadingSale, setIsLoadingSale] = useState(true)

    const getSale = async () => {
        setIsLoadingSale(true)
        const result = await getReq(`/v2/sale`)
        setActiveSale(result?.data?.result[0])
        setIsLoadingSale(false)
    }

    const getAllSale = async () => {
        setIsLoadingSale(true)
        const result = await getReq(`/v2/sale/all`)
        setAllSale(result?.data?.result[0])
        setIsLoadingSale(false)
    }

    const updateSale = async (data: ISale) => {
        setIsLoadingSale(true)
        await putReq(`/v2/sale`, { data })
        const result = await getReq(`/v2/sale`)
        setActiveSale(result?.data?.result[0])
        setIsLoadingSale(false)
    }

    useEffect(() => {
        getSale()
        getAllSale()
    }, [])

    return { activeSale, isLoadingSale, updateSale, allSale }
}

export default useSale