/* eslint-disable @typescript-eslint/no-unused-vars */
import moment from "moment"
import { useState } from "react"
import { getReq, postReq, putReq } from "../../modules/apiConsume"


const resetActions = () => {
    const [loading, setIsLoading] = useState(false)

    const getActiveReset = async (user_id?: number, signal?: AbortSignal) => {
        setIsLoading(true)
        const result = await getReq(`/v2/reset/user?user_id=${user_id}`, { signal: signal })
        setIsLoading(false)
        return result?.data?.result
    }

    const getDaysCompleted = async (user_id?: number, signal?: AbortSignal) => {
        setIsLoading(true)
        const result = await getReq(`/v2/reset/user?user_id=${user_id}`, { signal: signal })
        setIsLoading(false)
        return result?.data?.result[0].completed_days
    }

    const createResetData = async (user_id?: number) => {
        const data = { user_id: user_id, date_started: moment(new Date()).format("YYYY-MM-DD") }
        setIsLoading(true)
        const result = await postReq(`/v2/reset`, data)
        setIsLoading(false)
        return result?.data?.result
    }

    const restartReset = async (user_id?: number, reset_id?: number) => {
        const data = { user_id: user_id, date_started: moment(new Date()).format("YYYY-MM-DD") }
        setIsLoading(true)
        await postReq(`/v2/reset/restart/${reset_id}`, data)
        const result = await postReq(`/v2/reset`, data)
        setIsLoading(false)
        return result?.data?.result
    }

    const getDailyReset = async (resetId: number, signal?: AbortSignal) => {
        setIsLoading(true)
        const result = await getReq(`/v2/reset/daily?reset_id=${resetId}`, { signal: signal })
        setIsLoading(false)
        return result?.data?.result
    }

    const createResetDaily = async (reset_id: number, reset_date: string, day: number) => {
        const data = { reset_id: reset_id, reset_date: moment(reset_date).format("YYYY-MM-DD"), day: day }
        setIsLoading(true)
        const result = await postReq(`/v2/reset/daily?type=create`, data)
        setIsLoading(false)
        return result?.data?.result
    }

    const updateResetDaily = async (reset_id: number, completed: number, day: number) => {
        const data = { reset_id: reset_id, completed: completed, day: day }
        setIsLoading(true)
        const result = await postReq(`/v2/reset/daily?type=update`, data)
        setIsLoading(false)
        return result?.data?.result
    }

    return { getActiveReset, loading, createResetData, restartReset, getDailyReset, createResetDaily, updateResetDaily, getDaysCompleted }
}

export default resetActions