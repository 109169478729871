/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { FC, useState, useEffect, useRef } from 'react';

import Grid from '@mui/material/Grid'
import { Box } from '@mui/material'

export type WeekSwipeBarProps = {
	setCalendarOpen: any,
	calendarOpen: boolean,
	setCalendarMove: any,
	isLoading?: boolean
	calendarDefault: number,
	weekHeight: number
}

const WeekSwipeBar: FC<WeekSwipeBarProps> = ({setCalendarOpen, calendarOpen, setCalendarMove, isLoading, calendarDefault, weekHeight}) => {
	let mouseDown: number | undefined = undefined
	let isMoving = false
	const [isScrolling, setIsScrolling] = useState(false)
	const sliderRef = useRef(null)
	const handleMouseDown = (e: any) => {
		if (isLoading) return
		document.body.style.overflowY = 'hidden'
		document.body.style.position = 'fixed'
		mouseDown = e?.touches?.[0]?.clientY || 0
		const calendarCont = document.getElementById('renderWeekCalendar-cont')
		if(calendarCont?.style) {
			calendarCont.style.overflowY = 'hidden'
		}
	}

	const handleMouseUp = (isClick?: boolean) => {
		if (isLoading) return
		mouseDown = undefined
		const calendarCont = document.getElementById('renderWeekCalendar-cont')
		const calendarHeight = calendarCont?.clientHeight || 0
		calendarCont?.classList?.remove('transition-n')
		const slideBody = document.getElementsByClassName('slick-track')?.[0] as HTMLElement
		slideBody?.classList?.add('stop-slide')
		if(calendarHeight && calendarCont?.style) {
			if((calendarHeight >= (calendarDefault*0.6)) && !isClick) {
				setCalendarOpen(true)
				setTimeout(() => {

					calendarCont.style.height = `${calendarDefault}px`
					calendarCont.style.overflowY = 'auto'
				}, 250)
			} else {
				document.body.style.overflowY = 'auto'
				document.body.style.position = 'static'
				const backdropCont = document.getElementById('calendar-backdrop') as HTMLElement
				const backdropContChild = document.getElementById('calendar-backdrop-child') as HTMLElement

				setCalendarOpen(false)
				setTimeout(() => {
					calendarCont.style.height = `${weekHeight}px`
					calendarCont.style.overflowY = 'hidden'
					if (backdropCont) {
						backdropCont.style.height = '0'
						backdropContChild.style.opacity = '0'
					}
				}, 250)
			}
		}
	}

	const handleMouseMove = (e: any) => {
		if (isLoading) return
		isMoving = true
		const yPos = e?.touches?.[0]?.clientY || 0
		document.body.style.overflowY = 'hidden'
		document.body.style.position = 'fixed'
		if(mouseDown && mouseDown > -1 && yPos) {
			const calendarCont = document.getElementById('renderWeekCalendar-cont')
			const calendarHeight = calendarCont?.clientHeight || 0
			calendarCont?.classList?.add('transition-n')

			if (calendarHeight > 0 && calendarCont?.style) {
				let newHeight = calendarHeight + (yPos-mouseDown)
				newHeight = newHeight < weekHeight ? weekHeight : newHeight > calendarDefault ? calendarDefault : newHeight
				calendarCont.style.height = `${newHeight}px`
				mouseDown = yPos
				const backdropCont = document.getElementById('calendar-backdrop') as HTMLElement
				const backdropContChild = document.getElementById('calendar-backdrop-child') as HTMLElement
				if (backdropCont) {
					backdropCont.style.height = newHeight > weekHeight ? `${window.innerHeight}px` : '0'
					backdropContChild.style.opacity = newHeight > weekHeight ? `${0.5/(calendarDefault/newHeight)}` : '0'
				}
			}
		}
		isMoving = false
	}

	const handleClick = (e: any) => {
		if (calendarOpen) {
			e.stopPropagation()
			handleMouseUp(true)
		}
	}

	useEffect(() => {
		if (calendarOpen)	{
			document.body.style.overflowY = 'hidden'
			document.body.style.position = 'fixed'
		} else {
			document.body.style.overflowY = 'auto'
			document.body.style.position = 'static'
			setCalendarMove(false)
			const calendarCont = document.getElementById('renderWeekCalendar-cont')
			if (calendarCont?.style) {
				calendarCont.style.height = `${weekHeight}px`
				calendarCont.style.overflowY = 'hidden'
			}
			const backdropCont = document.getElementById('calendar-backdrop') as HTMLElement
			const backdropContChild = document.getElementById('calendar-backdrop-child') as HTMLElement
			if (backdropCont) {
				backdropCont.style.height = '0'
				backdropContChild.style.opacity = '0'
			}
		}
	},[calendarOpen])



	return <Grid onClick={handleClick} onMouseDown={handleMouseDown} onMouseMove={handleMouseMove} onTouchStart={handleMouseDown} onTouchEnd={()=>handleMouseUp()} onTouchMove={handleMouseMove}
		container xs={12} sx={{backgroundColor: 'transparent', height: calendarOpen ? window.innerHeight-425 : '100%'}} justifyContent='center' alignItems='flex-start'
	>
		<Grid container xs={12} sx={{backgroundColor: 'white', height: '20px', borderRadius: '0px 0px 4px 4px'}} justifyContent='center' alignItems='center'>
			<Box sx={{width: 40, height: 6, backgroundColor: 'gray', borderRadius: 3}}/>
		</Grid>
	</Grid>
}

export default WeekSwipeBar
