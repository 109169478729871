import { FC, useState, useEffect, useRef } from 'react'
import { useNavigate } from 'react-router-dom'
import moment from 'moment';

import { motion } from "framer-motion";
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import ArrowForward from '@mui/icons-material/ArrowForwardIos';
import ArrowBack from '@mui/icons-material/ArrowBackIosNew'
import Select, { SelectChangeEvent } from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import InputLabel from "@mui/material/InputLabel";
import FormControl from "@mui/material/FormControl";
import TextField from '@mui/material/TextField';
import Slider from '@mui/material/Slider';
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { Container} from 'react-bootstrap'

import { RaceOptions } from '../build/RaceOptions'
import AppMenus, { BodyComponent } from '../../../components/layout/AppMenus'
import WithAuth from '../../../components/WithAuth'
import Loader from '../../../components/animation/Loader'
import RSButton from '../../../components/buttons/RSButton'
import RSNavCont from '../../../components/layout/RSNavCont'
import RSHeaderCont from '../../../components/layout/RSHeaderCont'
import { AuthPageProps } from '../../../modules/types'
import { titleCase } from '../../../modules/miscUtils'
import { CreateRaceResult, GetUserInfo } from '../../../modules/trainingPlanActions'
import { UserRaceResults } from '../../../api/v2/types'
import useIsMobileScreen from '../../../hooks/useIsMobileScreen'


const StepRecover: FC<AuthPageProps> = ({ userInfo: accountInfo }) => {
	const navigate = useNavigate()
	const isMobile = useIsMobileScreen();
	const [raceName, setRaceName] = useState<any>('')
  const [raceType, setRaceType] = useState<any>('');
  const [raceDate, setRaceDate] = useState<any>('');
	const [rrTime, setRrTime] = useState<any>({ hr: '', min: '', sec: '' })
	const [isPR, setIsPR] = useState(false);
	const [isBQ, setIsBQ] = useState(false);
	const [isKm, setIsKm] = useState(false);
	const [isMondayStart, setIsMondayStart] = useState(false);
	const [mileage, setMileage] = useState(20);
	const [loading, setLoading] = useState(false);
	const [isDNF, setIsDNF] = useState(false);
	const [createdID, setCreatedID] = useState(0);

	const loaderData = {
		title: 'Building your plan.',
		steps: [
			'Adding runs to the mix.',
			'Enhancing with Physical Therapist-designed exercises',
			'Personalizing your pacing and workouts.'
		],
		finishedTitle: 'Finished!',
		finishedDesc: 'Your plan is ready.'
	}

	const isUpdated = () => {
		return (rrTime.sec || rrTime.min || rrTime.hr) && raceName && raceType && raceDate ? false : true
	}

	const handleRaceTypeChange = (event: SelectChangeEvent) => {
		const rVal = event?.target?.value
		const newMileage = marksList.find((e: any)=>e?.typeStart?.includes(rVal))?.value
		setMileage(newMileage ? newMileage : 20)
		setRaceType(rVal as string);
	};

	const handleSuccessSave = () => {
		navigate(createdID ? `/training-plan/view?planId=${createdID}` :  '/training-plan-journey/view', { replace: true })
	}

	const handleSubmit = async () => {
		const abortController = new AbortController()
		const { hr, min, sec } = rrTime
		const fTimeInSec = (hr ? hr * 3600 : 0) + (min ? min * 60 : 0) + (sec ? sec * 1 : 0)
		setLoading(true)
		const body: UserRaceResults = {
			user_id: accountInfo?.account_id,
			training_plan_id: undefined,
			race_type: raceType,
			race_name: raceName,
			race_date: moment(raceDate).format('YYYY-MM-DD'),
			finished_time_in_sec: fTimeInSec,
			is_best_pr: isPR && !isDNF ? 1 : 0,
			is_bq: isBQ && !isDNF ? 1 : 0,
			is_dnf: isDNF ? 1 : 0,
			notes: '',
			is_km: isKm ? 1 : 0,
			is_monday_start:  isMondayStart ? 1 : 0,
			distance: mileage,
		}
		localStorage.setItem('recoveryAssessment', '0')
		const ui = await GetUserInfo(accountInfo?.account_id as number, abortController.signal)
		const d = ui?.data?.result?.filter((f: any)=>f.type === 4 && f.status)
		const allUID = d?.map((ad: any)=>ad.uid)
		await CreateRaceResult(body as UserRaceResults)
		const gui = await GetUserInfo(accountInfo?.account_id as number, abortController.signal)
		const guid = gui?.data?.result?.length ? gui.data.result.filter((ad: any)=>ad.type === 4 && ad.status && !(allUID?.find((aUID: any)=>ad.uid === aUID))) : undefined
		if (guid?.length && guid[0]?.uid)
			setCreatedID(guid[0]?.uid)
		else
			setLoading(false)
	}

	const handleReset = () => {
		setRaceName('')
		setRaceType('')
		setRrTime({ hr: '', min: '', sec: '' })
		setRaceDate('')
		setIsPR(false);
		setIsBQ(false);
		setIsKm(false)
		setIsMondayStart(false)
		setMileage(20),
		setIsDNF(false)
	}

	const handleRrTimeChange = (e: any, t: string) => {
		if (!((t === 'min' || t === 'sec') && Number(e?.target?.value) > 59)) {
			const newRrTime = { ...rrTime, [`${t}`]: e?.target?.value }
			setRrTime(newRrTime)
		}
	}

	const marksList = [
    {
      value: 20,
      label: "20",
      typeStart: ['5K', '10K']
    },
    {
      value: 30,
      label: "30",
		 	typeStart: ['HalfMarathon']
    },
    {
      value: 40,
      label: "40",
		 	typeEnd: ['5K', '10K'],
			typeStart: ['FullMarathon'],
    },
		{
			value: 50,
			label: "50"
		},
		{
			value: 60,
			label: "60",
		 	typeEnd: ['HalfMarathon']
		},
		{
			value: 70,
			label: "70",
			typeEnd: ['FullMarathon']
		}
  ];

	const achievementList = [{
		label: 'Did you PR?',
		value: isPR,
		set: setIsPR
	}, {
		label: raceType === 'FullMarathon' ? 'Is this BQ?' : '',
		value: isBQ,
		set: setIsBQ
	}]

	useEffect(()=>{
		createdID && setLoading(false)
	},[createdID])

	return <>
		<Loader active={loading} onEnd={handleSuccessSave} data={loaderData} bg='white'/>
		<RSNavCont hideFooter={true} hideHeader={isMobile}>
			<RSHeaderCont isStatic={!isMobile} title='Cancel' noShadow={!isMobile} barSX={{backgroundColor: isMobile ? 'white' : 'transparent'}} closeClick={()=>navigate('/explore')}/>
			<Grid container justifyContent='center'>
				<Box sx={{pt: 3, maxWidth: '650px'}}>
					<div className="build-plane-wrap">
						<div className="topbar-info text-center">
							<h1 className="text-caps mt-4 mt-md-0">Enter Previous Race Result</h1>
						</div>
					</div>

					<Grid container sx={{ p: 2 }}>
						<Grid container sx={{ py: '10px', borderRadius: '5px' }} justifyContent="flex-start" alignItems="center">
							<Box sx={{ pr: '10px' }}>
								<Box sx={{ fontFamily: 'Poppins !important', fontSize: '16px !important' }} >
									Did you finish the race?
								</Box>
							</Box>

							<Box>
								<Box className={`switch ${!isDNF ? 'fe' : ''}`} sx={{ backgroundColor: !isDNF ? '#9FCD2B !important' : '#80808070' }} onClick={() => setIsDNF(!isDNF)}>
									<motion.div className="handle" layout
										transition={{
											type: "spring",
											stiffness: 700,
											damping: 30
										}} />
								</Box>
							</Box>
						</Grid>
						<Grid container xs={12} justifyContent="center" alignItems="center">
							<TextField
								value={raceName}
								onChange={(e) => setRaceName(e.target.value)}
								sx={{
									'& .MuiInputLabel-root': {
										color: '#80808061',
										fontSize: '16px',
										fontFamily: '#80808061'
									},
									'& .MuiInputLabel-shrink.Mui-focused': {
										color: '#1976d2'
									},
									width: '100%',
									backgroundColor:'white',
									mt: '10px'
								}}
								label="Enter Race Name"
								variant="outlined"
						/>
						</Grid>
						<Grid container xs={6} sx={{mt: '8px', pr: '5px'}} justifyContent="flex-start" alignItems="center">
							<FormControl sx={{
								'& .MuiInputBase-input': {
									height: '22px'
								},
								width: '100%', backgroundColor:'white', mt: '10px'}}
							>
								<InputLabel id="label" sx={{ color: '#80808061', fontSize: '16px', fontFamily: '#80808061'}}>Race Type</InputLabel>
								<Select
								 labelId="label"
									value={raceType}
									label="Race Type"
									onChange={handleRaceTypeChange}
								>
									{RaceOptions.filter((rsx: any)=>rsx.id < 5).sort((a: any, b: any)=>a.value-b.value).map((rs: any, rsI: number) =>
										<MenuItem key={rsI} value={rs.subTitle ? `${titleCase(rs.title)}${titleCase(rs.subTitle)}` : rs.title}>{rs.subTitle ? `${titleCase(rs.title)} ${titleCase(rs.subTitle)}` : rs.title}</MenuItem>)}
								</Select>
							</FormControl>
						</Grid>

						<Grid container xs={6} sx={{mt: '10px', pl: '5px'}} justifyContent="flex-start" alignItems="center">
							<LocalizationProvider dateAdapter={AdapterDayjs}>
								<DemoContainer sx={{
									'& .MuiTextField-root': {
										minWidth: '100% !important'
									}}} components={['DatePicker']}>
									<DatePicker
										sx={{
											'& .MuiOutlinedInput-root': {
												height: '55px'
											},
											'& .MuiInputLabel-root': {
												color: '#80808061',
												fontSize: '16px',
												fontFamily: '#80808061'
											},
											'& .MuiInputLabel-shrink.Mui-focused': {
												color: '#1976d2'
											},
											width: '100%',
											backgroundColor:'white',
											mt: '10px'
										}}
										value={raceDate}
										onChange={(newValue: any) => setRaceDate(moment(new Date(newValue)).format('YYYY/MM/DD'))}
										label="Previous Race Date" />
								</DemoContainer>
							</LocalizationProvider>
						</Grid>

						<Grid container xs={12} sx={{ mt: '15px', pb: '5px', fontFamily: 'Poppins !important', fontSize: '16px !important' }} justifyContent="flex-start" alignItems="center">
							{ isDNF ? 'Race Goal' : 'Finish Time'}
						</Grid>
						<Grid container xs={12} sx={{}} justifyContent="center" alignItems="center">
							<Grid container xs={4} justifyContent="flex-start" alignItems="center">
								<input value={rrTime.hr} onChange={(e) => handleRrTimeChange(e, 'hr')} placeholder="HH" type='number' className='enter-time' onKeyDown={(e) => (e.keyCode === 69 || e.keyCode === 187 || e.keyCode === 189) && e.preventDefault()} />
							</Grid>
							<Grid container xs={4} justifyContent="center" alignItems="center">
								<input value={rrTime.min} onChange={(e) => handleRrTimeChange(e, 'min')} placeholder="MM" type='number' className='enter-time' onKeyDown={(e) => (e.keyCode === 69 || e.keyCode === 187 || e.keyCode === 189) && e.preventDefault()} />
							</Grid>
							<Grid container xs={4} justifyContent="flex-end" alignItems="center">
								<input value={rrTime.sec} onChange={(e) => handleRrTimeChange(e, 'sec')} placeholder="SS" type='number' className='enter-time' onKeyDown={(e) => (e.keyCode === 69 || e.keyCode === 187 || e.keyCode === 189) && e.preventDefault()} />
							</Grid>
						</Grid>

						{!isDNF && <Grid container xs={12} sx={{ mt: '15px', pb: '5px', fontFamily: 'Poppins !important', fontSize: '16px !important' }} justifyContent="flex-start" alignItems="center">
							Achievements
						</Grid>}

						{!isDNF && achievementList.map((v: any, iv: number) => {
							return v.label && <Grid container key={iv + 'acv'} xs={12} sx={{ mb: '10px', backgroundColor: 'white', border: '1px solid rgba(0, 0, 0, 0.176)', p: '10px', borderRadius: '5px' }} justifyContent="center" alignItems="center">
								<Grid container xs={8} sx={{ pl: '10px' }} justifyContent="flex-start" alignItems="center">
									<Box sx={{ fontFamily: 'Poppins', fontSize: '16px' }} >
										{v.label}
									</Box>
								</Grid>

								<Grid container xs={4} justifyContent="flex-end" alignItems="center">
									<Box className={`switch ${v.value ? 'fe' : ''}`} sx={{ backgroundColor: v.value ? '#9FCD2B !important' : '#80808070' }} onClick={() => v.set(!v.value)}>
										<motion.div className="handle" layout
											transition={{
												type: "spring",
												stiffness: 700,
												damping: 30
											}} />
									</Box>
								</Grid>
							</Grid>
						})}

						<Grid container xs={12} sx={{ mb: '10px', pt: '30px' }} justifyContent="flex-start" alignItems="center">
							 <Grid container>
								<Grid container xs={12} justifyContent="center" alignItems="center">
									<Box sx={{ fontFamily: 'Poppins !important', fontSize: '16px !important', textAlign: 'center' }} >
										What was your peak weekly volume while training?
									</Box>
								</Grid>
								<Grid container justifyContent="center" alignItems="center">
									<Slider
										sx={{
											width: 300,
											color: '#9FCD2B',
										}}
										value={mileage}
										onChange={(e: any, val: number | number[])=>setMileage(val as number)}
										valueLabelFormat={(value: any)=>marksList.findIndex((mark) => mark.value === value) + 1}
										step={10}
										marks={marksList}
										min={raceType ? marksList.find((e: any)=>e?.typeStart?.includes(raceType))?.value : 20}
										max={raceType ? marksList.find((e: any)=>e?.typeEnd?.includes(raceType))?.value : 40}
									/>
								</Grid>
							</Grid>
						</Grid>

						<Grid container xs={12} sx={{ mb: '10px', pt: '10px' }} justifyContent="flex-start" alignItems="center">
							<Grid container>
								<Grid container justifyContent="center" alignItems="center">
									<Box sx={{ fontFamily: 'Poppins !important', fontSize: '16px !important' }} >
										Choose your units
									</Box>
								</Grid>
								<Grid container justifyContent="center" alignItems="center">
									<Box className={`switch recover-handle-switch ${isKm ? 'fe' : ''}`} sx={{ backgroundColor: '#9FCD2B !important', position: 'relative' }} onClick={() => setIsKm(!isKm)}>
										<motion.div className="recover-handle" layout
											transition={{
												type: "spring",
												stiffness: 700,
												damping: 30
											}} />
										<Box sx={{position: 'absolute', left: -10, bottom: -25, fontFamily: 'Poppins-Light !important', fontSize: '12px !important'}}>Miles</Box>
										<Box sx={{position: 'absolute', right: -30, bottom: -25, fontFamily: 'Poppins-Light !important', fontSize: '12px !important'}}>Kilometers</Box>
									</Box>
								</Grid>
							</Grid>
						</Grid>

						<Grid container xs={12} sx={{ mb: '10px', pt: '30px' }} justifyContent="flex-start" alignItems="center">
							<Grid container>
								<Grid container xs={12} justifyContent="center" alignItems="center">
									<Box sx={{ fontFamily: 'Poppins !important', fontSize: '16px !important' }} >
										What day starts your week?
									</Box>
								</Grid>
								<Grid container justifyContent="center" alignItems="center">
									<Box className={`switch recover-handle-switch ${isMondayStart ? 'fe' : ''}`} sx={{position: 'relative', backgroundColor: '#9FCD2B !important' }} onClick={() => setIsMondayStart(!isMondayStart)}>
										<motion.div className="recover-handle" layout
											transition={{
												type: "spring",
												stiffness: 700,
												damping: 30
											}} />

										<Box sx={{position: 'absolute', left: -20, bottom: -25, fontFamily: 'Poppins-Light !important', fontSize: '12px !important'}}>Sunday</Box>
										<Box sx={{position: 'absolute', right: -20, bottom: -25, fontFamily: 'Poppins-Light !important', fontSize: '12px !important'}}>Monday</Box>
									</Box>
								</Grid>
							</Grid>
						</Grid>

						<Grid container xs={12} sx={{ mt: '50px' }} justifyContent='center' alignItems="center">
							<RSButton onClick={handleSubmit} disabled={isUpdated()} sx={{mt: '10px'}} >Build Plan</RSButton>
						</Grid>
					</Grid>
				</Box>
			</Grid>
		</RSNavCont >
	</>
}

export default WithAuth(StepRecover);
