import WithAuth from '../../../components/WithAuth'
import AppMenus, { BodyComponent } from '../../../components/layout/AppMenus'
import RSNavCont from '../../../components/layout/RSNavCont'
import RSHeaderCont from '../../../components/layout/RSHeaderCont'
import { FC, useEffect, useState } from 'react'
import { Container, Button } from 'react-bootstrap'
import DatePicker from 'react-datepicker'
import ArrowBack from '@mui/icons-material/ArrowBackIos'
import { useLocation, useNavigate } from 'react-router-dom'
import useStore from '../../../store/useStore'
import { ITrainingPlanStore } from '../../../store/createTrainingPlanStore'
import { guideText } from '../../../modules/values'
import moment from 'moment'
import Progress from './Progress'
import useQueryParams from '../../../hooks/useQueryParams'
import useIsMobileScreen from '../../../hooks/useIsMobileScreen'

/**
 * Programs Component
 */

const StepTwo: FC = () => {
	const navigate = useNavigate()
	const location = useLocation();
	const isMobile = useIsMobileScreen();

	const { level } = useQueryParams()

	const { setSelectedRaceDate, setCurrentPath, selectedRace, selectedRaceDate } = useStore((state: ITrainingPlanStore) => state)


	const [startDate, setStartDate] = useState(null);

	// const disableWeekends = current => {
	// 	return current.day() !== 0 && current.day() !== 6;
	// }

	const handleClick = (selectedDate: string | null) => {
		setSelectedRaceDate(selectedDate)
		navigate('/training-plan/build/step-4?level=' + level, { replace: true })
	}
	const filterDates = (date: Date) => {
		const day = date.getDay();
		if (selectedRace?.value == 2 || selectedRace?.value == 3 || selectedRace?.value == 4)
			return !(day >= 1 && day <= 5)
		else
			return day !== 2 && day !== 3 && day !== 4 && day !== 5;

	};

	useEffect(() => {
		if (!selectedRace) {
			navigate('/training-plan/build/step-1', { replace: true })
		}
		else if (selectedRace && !level) {
			navigate('/training-plan/build/step-2', { replace: true })
		}
	}, [selectedRace, level])


	useEffect(() => {
		setCurrentPath(location.pathname)
	}, [location.pathname])

	// Render
	return (
		 <RSNavCont hideFooter={true} hideHeader={isMobile}>
			<RSHeaderCont isStatic={!isMobile} title='Cancel' noShadow={!isMobile} barSX={{backgroundColor: isMobile ? 'white' : 'transparent'}} closeClick={()=>navigate('/explore')}/>
			<div className="training-plan build-plan regular-container pt-5">
				<Container>
					<div className="build-plane-wrap">
						<div className="text-center">
							<Progress progress={Number(location.pathname.split('/')[3].split('-')[1])} />
							{/* <h3 className="letter-space mb-3 font-md-15">
								BUILD A NEW PLAN{' '}
								<span className="d-none d-md-inline-block step-label h3"> STEP TWO</span>
							</h3> */}
							{/* <hr className="border-bottom-primary mt-md-4 mb-4 d-none d-md-block" />
							<h4 className="text-normal mt-4 pt-2 lh-lg text-initial d-md-none h1 font-md-18">
								STEP TWO:
							</h4>
							<hr className="border-bottom-primary mt-3 mt-md-4 d-md-none" /> */}
							<h1 className="text-caps mt-4 mt-md-0">
								{selectedRace?.value == 4 ? "When do you want this plan to start?" : "When is race day?"}
							</h1>
							{/* <hr className="border-bottom-primary mt-md-4 d-none d-md-block" /> */}
							<p className="mt-md-4 mb-md-4 lh-lg w-75 mx-auto text-initial">
								{selectedRace?.value === 2 || selectedRace?.value === 3 ? guideText.SelectRaceDate1 : selectedRace?.value === 0 || selectedRace?.value === 1 ? guideText.SelectRaceDate2 : ''}
							</p>
						</div>
						<div className="text-center mt-4 mt-md-0">
							<DatePicker
								dateFormat={"MM/DD/YYYY"}
								selected={startDate}
								startDate={startDate}
								minDate={moment().toDate()}
								onChange={(e) => handleClick(moment(e).format("MM/DD/YYYY"))}
								inline
								filterDate={filterDates}
							/>

							{/* <Button
								// as="a"
								size="lg"
								variant="white"
								className="rounded-pill color-primary-bg step-back-btn mt-4"
								onClick={() => navigate('/training-plan/build/step-2')}
							>
								<ArrowBack style={{ width: '20px', marginRight: '10px' }} />
								Back
							</Button> */}
						</div>
					</div>
				</Container>
			</div>
		</RSNavCont>
	)
}

export default WithAuth(StepTwo)
