import InstabotHide from '../../components/styles/InstabotHide'
import AppMenus from '../../components/layout/AppMenus'
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import GenericAlert from '../../components/misc/GenericAlert'
import useAlert from '../../hooks/useAlert'
import AccountSubmitButtons from '../../components/buttons/AuthSubmitButtons'
import ForgotPasswordForm, {
	ForgotPassFormInfo as ForgotPasswordFormInfo,
	accountFormInfo,
} from '../../components/forms/ForgotPassForm'
import { resetPassword } from '../../modules/accountActions'
import { FC, useState } from 'react'

/**
 * ForgotPassword Component
 */

const ForgotPassword: FC = () => {
	// Properties
	const title = 'Forgot your Password?'
	const [info, setInfo] = useState<ForgotPasswordFormInfo>(accountFormInfo)
	const [isValid, setIsValid] = useState(false)

	// Hooks
	const {
		showAlert,
		onAlertOpen,
		onAlertClose,
		alertTitle,
		alertMessage,
		setAlertMessage,
		alertVariant,
		setAlertVariant,
	} = useAlert()

	// On Submit
	const onSubmit = async () => {
		const response = await resetPassword(info.email.value)
		if (response?.status == 'ok') {
			setAlertVariant('success')
			setAlertMessage(
				`Password Reset link sent to ${info.email.value}. Please visit the link to reset your password.`
			)
			onAlertOpen()
		} else {
			setAlertVariant('danger')
			setAlertMessage('Could not send password reset link')
			onAlertOpen()
		}
	}

	// Render
	return (
		<>
			{/* Styles */}
			<InstabotHide />

			{/* Alert */}
			<GenericAlert
				show={showAlert}
				title={alertTitle}
				message={alertMessage}
				variant={alertVariant}
				onClose={onAlertClose}
			/>

			{/* Page */}
			<AppMenus hideOptions hideBackButton>
				<Container fluid="md" className="px-form-5">
					<div className="account-form m-auto">
						<Row className="mb-3 user-login--title text-center">
							<Col>
								<h3>{title}</h3>
							</Col>
						</Row>

						{/* Instructions */}
						<Row className="mb-5">
							<Col className="text-center">
								<span>
									Forgot your password? Please enter your email address. You
									will receive a link to create a new password via email.
								</span>
							</Col>
						</Row>

						{/* Form */}
						<ForgotPasswordForm setInfo={setInfo} setIsValid={setIsValid} />

						{/* Submit Buttons */}
						<AccountSubmitButtons
							primaryText="Submit"
							primaryAction={onSubmit}
							primaryDisabled={!isValid}
						/>

						{/* Switch Page Link */}
						<Row className="mb-5">
							<Col className="text-center">
								<span>
									<a className='text-primary pointer' href="/#/login">Log In</a>
								</span>
							</Col>
						</Row>
					</div>
				</Container>
			</AppMenus>
		</>
	)
}

export default ForgotPassword
