import { getReq, postReq } from './apiConsume'
import { SearchFiltersProps } from './types'


export const getRaceEventsActiveAccess = async (place: string, date: string) =>
	await getReq(`/v2/race-events-activeaccess?place=${place}&date=${date}`)

export const GetRaceEvents = async (filters: SearchFiltersProps, page?: number) => {
	let f: any = {}
	f = {
		...filters,
		country: filters.country?.toUpperCase().slice(0, 2) || '',
		radius: parseFloat(((filters?.radius || 80) * (filters?.isKm ? 0.62137119 : 1)).toFixed(1)),
		page: page || 1,
		results_per_page: 20
	}
	// }
	// else {
	// f = {
	// 	...filters,
	// 	country: filters.country?.toUpperCase().slice(0, 2) || '',
	// 	zipcode: filters.zipcode || '',
	// 	radius: 50,
	// 	page: page || 1,
	// 	results_per_page: 10
	// }
	// delete f.isNearMe
	// delete f.zipcode
	// delete f.radius
	// }

	delete f?.isKm
	const query = Object.keys(f).map(key => `${key}=${f[key]}`).join('&')
	// console.log(`/v2/race-events?${query}`)
	return await getReq(`/v2/race-events?${query}`)
}


export const GetTrainingPlanAI = async (userScenario: string) => {
	return await postReq(`/v2/openai/chat-completion`, { user_input: userScenario })
}
