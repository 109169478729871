import { useCookies } from 'react-cookie'
import { useState } from 'react'
import { IS_PROD } from '../modules/cliEnvValues'

// Expiration
const EXPIRATION = 2147483647000

/**
 * useLocalStorage Hook
 */

const useLocalStorage = ({
	key,
	initialValue = {},
}: {
	key: string
	initialValue?: any
}) => {
	// Hooks
	const [cookies, setCookie] = useCookies([key])

	// Pass initial state function to useState so logic is only executed once
	const [storedValue, setStoredValue] = useState(() => {
		try {
			// Get from cookies
			const item = cookies[key]
			// Parse stored json or if none return initialValue
			return item || initialValue
		} catch (error) {
			// If error also return initialValue
			return initialValue
		}
	})

	// Return a wrapped version of useState's setter function that ...
	// ... persists the new value to localStorage.
	const setValue = (value: any) => {
		try {
			// Save state
			const newValue = { ...storedValue, ...value }
			setStoredValue(newValue)
			// Save to local storage
			if (typeof window !== 'undefined') {
				setCookie(key, JSON.stringify(newValue), {
					secure: IS_PROD,
					httpOnly: false,
					expires: new Date(EXPIRATION),
				})
			}
		} catch (err) {
			// Do Nothing
		}
	}

	// Return
	return { storedValue, setValue }
}

export default useLocalStorage
