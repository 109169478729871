import { Video } from "../modules/videos/allVideos"
import { Exercise, WatchData } from "../pages/exercises/types"
import { StoreSlice } from "./types"


export interface IExerciseStore {
    isLoadingExercises: boolean,
    setIsLoadingExercises: (exercises: boolean) => void,
    allExercises: Array<Exercise> | undefined,
    setAllExercises: (exercises: Array<Exercise> | undefined) => void,
    trendingExercises: Array<Exercise> | undefined,
    setTrendingExercises: (exercises: Array<Exercise> | undefined) => void,
    popularExercises: Array<Exercise> | undefined,
    setPopularExercises: (exercises: Array<Exercise> | undefined) => void,
    userWatchData: Array<WatchData> | undefined,
    setUserWatchData: (userWatchData: Array<WatchData> | undefined) => void,
    allFilteredVideos: Array<Video> | undefined,
    setAllFilteredVideos: (allFilteredVideos: Array<Video> | undefined) => void,
    activeFilter: Array<string>,
    setActiveFilter: (allFilteredVideos: Array<string>) => void,
    selectedFilter: Array<string>,
    setSelectedFilter: (selectedFilter: Array<string>) => void
}

const createExerciseStore: StoreSlice<IExerciseStore> = (set) => ({
    isLoadingExercises: false,
    setIsLoadingExercises: (isLoading: boolean) => set(() => ({ isLoadingExercises: isLoading })),
    allExercises: undefined,
    setAllExercises: (exercises: Array<Exercise> | undefined) => set(() => ({ allExercises: exercises })),
    trendingExercises: undefined,
    setTrendingExercises: (exercises: Array<Exercise> | undefined) => set(() => ({ trendingExercises: exercises })),
    popularExercises: undefined,
    setPopularExercises: (exercises: Array<Exercise> | undefined) => set(() => ({ popularExercises: exercises })),
    userWatchData: undefined,
    setUserWatchData: (userWatchData: Array<WatchData> | undefined) => set(() => ({ userWatchData: userWatchData })),
    allFilteredVideos: undefined,
    setAllFilteredVideos: (allFilteredVideos: Array<Video> | undefined) => set(() => ({ allFilteredVideos: allFilteredVideos })),
    activeFilter: ["all"],
    setActiveFilter: (activeFilter: Array<string>) => set(() => ({ activeFilter: activeFilter })),
    selectedFilter: ["all"],
    setSelectedFilter: (selectedFilter: Array<string>) => set(() => ({ selectedFilter: selectedFilter }))
})

export default createExerciseStore