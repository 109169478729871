import React, { FC } from 'react'
import { Placeholder } from 'react-bootstrap'

const Answer: FC<{ selected: boolean, option: string, isLoading: boolean, isMultiple?: boolean, setAnswer?: () => any }> = ({ selected = false, setAnswer, option, isLoading, isMultiple }) => {
    return (
        <>{isLoading ? (
            <>
                <Placeholder as="div" animation="glow">
                    <Placeholder className='answer' />
                </Placeholder>
            </>) : (
            <>
                {!isMultiple ? (
                    <div onClick={setAnswer} className={`${selected ? 'answer selected' : 'answer'}`} >
                        <p>{option}</p>
                    </div>
                ) : (
                    <div onClick={setAnswer} className={`${selected ? 'answer selected' : 'answer'}`} >
                        <p>{option}</p>
                    </div>
                )}
            </>
        )}

        </>
    )
}

export default Answer