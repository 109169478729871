/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useEffect, useState } from 'react'
import { Col, Row } from 'react-bootstrap'
import useVideoProgress from '../../hooks/useVideoProgress'
import { Video } from '../../modules/videos/allVideos'
import useFavorites from '../../pages/favorites/useFavorites'
import recoverVideos from '../../pages/recover/RecoverVideos.json'
import MainCard from '../cards/MainCard'

const DEFAULT_VIDEO: Video = {
    id: String(825035143),
    url: '/#/programs/runsmart-recover?videoId=' + 825035143,
    image: '\\img\\recover.jpg',
    category: 'Recover',
    headingTitle: '',
    headingSubtitle: 'Recovery Basics',
    detailsLine1: '',
    detailsLine2: '',
    headlines: '' || [],
    descriptions: [] || []
}

const RecoveryVideos = (props: any) => {
    const [currentVideoId, setCurrentVideoId] = useState("")
    const [recoveryVideos, setRecoveryVideos] = useState<Video[]>([])
    const [currentWorkoutVideo, setCurrentWorkoutVideo] = useState<Video>(DEFAULT_VIDEO)
    const [isPlaying, setIsPlaying] = useState(false)

    const { userInfo, handleOpenVideo } = props

    const [vimeoData, setVimeoData] = useState<any>()

    const info = useVideoProgress({ videos: recoveryVideos as Video[], userInfo, autoReload: true, realoadTriggeer: currentVideoId })

    const { favorites, toggleFavorite, isLoading, getFavorites, getIsFavorited } = useFavorites(userInfo?.account_id as number, undefined, 'others', true)


    const getVideoDuration = async (videoId: string) => {

        const response = await fetch(
            `https://vimeo.com/api/oembed.json?url=https://player.vimeo.com/video/${videoId}`
        );
        const data = await response.json();
        if (data) {
            const duration = Math.round(data?.duration / 60)
            setVimeoData(`${duration} ${duration <= 1 ? 'minute' : 'minutes'}`)
        }
    }

    const onLike = async (videoId: string) => {
        const isLiked = favorites && favorites.find((x: any) => x.id === videoId) ? true : false;
        await toggleFavorite(userInfo?.account_id as number, Number(videoId), !isLiked)
    }

    const getVideoDetails = (vidId?: string) => {
        const video: typeof recoverVideos[0] | undefined = recoverVideos.find(x => x.type === "pre-assessment" && x.vimeo_id === vidId)

        return { id: video?.id, videoId: video?.vimeo_id, title: video?.title }
    }

    const handleSetCurrentVideoId = (videoId: any) => {
        setIsPlaying(false)
        setCurrentVideoId(videoId)
        handleOpenVideo(assignVideo(videoId), "pre-assessment")
        // setCurrentWorkoutVideo(assignVideo(videoId))
    }

    const assignVideo = (videoId: string) => {
        const video: Video = {
            id: videoId,
            url: '/#/programs/runsmart-recover?videoId=' + videoId,
            // image: '\\img\\recover.jpg',
            image: `https://vumbnail.com/${videoId}.jpg`,
            category: 'Recover',
            headingTitle: getVideoDetails(videoId).title as string,
            headingSubtitle: '',
            detailsLine1: '',
            detailsLine2: '',
            headlines: '' || [],
            descriptions: [] || []
        }

        return video
    }

    const handleOnPlay = async () => {

        // localStorage.setItem('playHistory', JSON.stringify(playHistory))
        // localStorage.setItem('previousActivityStatus', 'unparsed')

        setIsPlaying(true)

    }

    const handleGenerateVideos = () => {
        const vids: Video[] = []
        recoverVideos.map((video) => {
            vids.push(assignVideo(video.vimeo_id))
        })
        setCurrentVideoId(vids[0].id)
        setRecoveryVideos(vids)
    }

    useEffect(() => {
        handleGenerateVideos()
    }, [])

    return (
        <div>
            <Row className=''>
                <Col lg={12} sm={12} className='mt-4 mb-5 md-mb-0'>
                    <div className="text-center mb-5 px-5">
                        <h3>Rest is on tap for today.</h3>
                        <p className='text-nm' >
                            Watch these videos to understand the recovery process better.
                        </p>
                    </div>
                    {
                        recoverVideos.filter(x => x.type === "pre-assessment").map((video) => (
                            <div onClick={() => handleSetCurrentVideoId(video.vimeo_id)} key={video.id} className="justify-content-center phone-video-list mb-2 mt-n2">
                                <MainCard
                                    videoId={video.vimeo_id}
                                    key={video.vimeo_id}
                                    cardType="video"
                                    // type="videoLgStretch"
                                    hasCheck={true}
                                    progress={info?.find(x => x.videoId == Number(video.vimeo_id))?.progress}
                                    isComplete={info && info.length >= 0 && info?.find(x => x?.videoId == Number(video.vimeo_id))?.progress as number >= 80}
                                    logoImage={`https://vumbnail.com/${video.vimeo_id}.jpg`}
                                    bgImage={`https://vumbnail.com/${video.vimeo_id}.jpg`}
                                    // logoImage={DEFAULT_VIDEO.image}
                                    // bgImage={DEFAULT_VIDEO.image}
                                    detailTitle={''}
                                    detailSubtitle={video.title}
                                    // link={getVideoLink(video.vimeo_id)}
                                    hasLikeButton={true}
                                    isProgram={false}
                                    onLike={() => onLike(video.vimeo_id)}
                                    // isSelected={video.vimeo_id == currentWorkoutVideo?.id}
                                    favorites={favorites}
                                />
                            </div>
                        ))
                    }
                </Col>
            </Row >
        </div >
    )
}

export default RecoveryVideos
