/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { FC, useEffect, useState } from 'react'
import { Container, Spinner } from 'react-bootstrap'
import AppMenus from '../../components/layout/AppMenus'
import InstabotHide from '../../components/styles/InstabotHide'
import WithAuth from '../../components/WithAuth'
import RSButton from '../../components/buttons/RSButton'
import useQueryParams from '../../hooks/useQueryParams'
import questions from './questions.json'
import { IOnboardingSurveyQuestion } from './types'
import AccountSubmitButtons from '../../components/buttons/AuthSubmitButtons'
import { navReq } from '../../modules/apiConsume'
import useStore from '../../store/useStore'
import { IComponentStore } from '../../store/createComponentStore'
import useSurveyActions from './useSurveyActions'
import { AuthPageProps } from '../../modules/types'
import { ISurveyDetails } from '../../api/v2/onboarding-survey/types'
import Indicator from './Indicator'
import Answer from './Answer'
import { updateAccountInfo } from '../../modules/accountActions'
import suggestedPrograms from '../../pages/onboarding/suggested-program.json'
import useSuggestedPrograms from './useSuggestedPrograms'
import secureLocalStorage from 'react-secure-storage'
import useDeviceDetect from '../../hooks/useDeviceDetect'



const QuestionPage: FC<AuthPageProps> = ({ userInfo }) => {

    const { setIsLoading, setLoadingMsg } = useStore((state: IComponentStore) => state)
    const { getSuggestedPrograms, constructSurveyAnswers } = useSuggestedPrograms(userInfo?.account_id as number, false)
    //survey Hook
    const { getSurveyDetails, userSurveyDetails, createSurveyDetails, updateSurveyDetails, isLoading: isLoadingSurvey, setUserSurveyDetails, pushDetailsToZapier } = useSurveyActions()
    const { mobileType } = useDeviceDetect()
    const { seq, offer, requestKey } = useQueryParams()
    const [question, setQuestion] = useState<IOnboardingSurveyQuestion | undefined>()
    const [answer, setAnswer] = useState<number>(0)
    const [hasChangeMulti, setHasChangeMulti] = useState(false)
    const [isNativeMobile, setIsNativeMobile] = useState(false)
    const [answerMulti, setAnswerMulti] = useState<number[]>([])

    const [joinReason, setJoinReason] = useState<string | undefined>()

    const SURVEY_TYPE = localStorage.getItem('surveyType')
    const localStorageRequestKey = localStorage.getItem('deviceRequestKey') || undefined
    const isAppleSignup = secureLocalStorage.getItem("isAppleSignup") || false
    const isGoogleSignup = secureLocalStorage.getItem("isGoogleSignup") || false


    const handleContinue = async () => {
        // await getSurveyDetails(userInfo?.account_id as number)
        let q = questions;

        if (SURVEY_TYPE === "profile_update")
            q = q.filter(x => x.id !== 5)

        if (Number(seq) == q.length) {
            await updateUserSurveyDetails()
            await pushDetailsToZapier(Number(userInfo?.account_id), isAppleSignup ? 'Apple' : isGoogleSignup ? 'Google' : (isNativeMobile && mobileType === "Ios") ? 'Ios' : (isNativeMobile && mobileType === "Android") ? 'Android' : 'Web')
            setLoadingMsg(`Please wait while we customize your training plan.`)
            setIsLoading(true)
            await getSuggestedPrograms(constructSurveyAnswers(userSurveyDetails as ISurveyDetails), 200, true)
            SURVEY_TYPE === "profile_update" ?
                await updateProfile()
                : setTimeout(() => {
                    setLoadingMsg(undefined)
                    setIsLoading(false)
                    navReq(`/#/choose-plan${offer ? '?offer=' + offer : ''}`)
                }, 2000)
        }
        else {
            await updateUserSurveyDetails()
            navReq(`/#/onboarding-survey/questions?seq=${(Number(seq) + 1)}${offer ? '&offer=' + offer : ''}`)
        }
    }

    const updateProfile = async () => {
        if (userInfo) {
            const result = await updateAccountInfo({
                firstName: userInfo?.first_name as string,
                lastName: userInfo?.last_name as string,
                gender: userInfo?.gender as string,
                dob: userInfo?.dob?.split('T')[0] as string,
                // favorite: userInfo?.favorite as string,
                joinReason: joinReason,
            }, "profile_update")
            if (result?.data?.id) {
                setIsLoading(false)
                navReq('/#/end')
            }
        }

    }

    const getJoinReason = () => {
        const suggested = suggestedPrograms.find(x => x.answer == Number(userSurveyDetails?.running_goal))
        return suggested?.join_reason
    }

    const handleAnswerClick = (key: number) => {
        if (question?.multiple) {

            if (seq === "4" && key == 6) {
                setHasChangeMulti(true)
                setAnswerMulti([6])
            }
            else if (seq === "4" && key != 6) {

                if (answerMulti.includes(key)) {
                    setHasChangeMulti(true)
                    setAnswerMulti((prev) => prev.filter(x => x != key && x != 6))
                }
                else
                    setAnswerMulti((prev) => [...prev.filter(x => x != 6), key])
            }
            else {
                if (answerMulti.includes(key)) {
                    setHasChangeMulti(true)
                    setAnswerMulti((prev) => prev.filter(x => x != key))
                }
                else
                    setAnswerMulti((prev) => [...prev, key])
            }
        }
        else
            setAnswer(key)
    }

    const createInitialUserSurveyDetails = async () => {
        const InitData: ISurveyDetails = {
            user_id: userInfo?.account_id,
            describe_running: "",
            weekly_average: "",
            running_goal: "",
            struggle_areas: "",
            add_pt: 0
        }
        await createSurveyDetails(InitData)
        userInfo?.account_id ? await getSurveyDetails(userInfo?.account_id) : undefined
    }

    const initDetails = async () => {
        let details: ISurveyDetails | undefined = undefined
        if (userInfo?.account_id)
            details = await getSurveyDetails(userInfo?.account_id)

        if (!details && userInfo?.account_id)
            await createInitialUserSurveyDetails()

    }

    const updateUserSurveyDetails = async () => {
        if (Number(seq) == 1 && userSurveyDetails)
            await updateSurveyDetails({ ...userSurveyDetails, describe_running: String(answer) })
        else if (Number(seq) == 2 && userSurveyDetails)
            await updateSurveyDetails({ ...userSurveyDetails, weekly_average: String(answer) })
        else if (Number(seq) == 3 && userSurveyDetails)
            await updateSurveyDetails({ ...userSurveyDetails, running_goal: String(answer) })
        else if (Number(seq) == 4 && userSurveyDetails)
            await updateSurveyDetails({ ...userSurveyDetails, struggle_areas: String(answerMulti) })
        else if (Number(seq) == 5 && userSurveyDetails)
            await updateSurveyDetails({ ...userSurveyDetails, add_pt: answer })
    }

    const handleCheckSelected = (key: number) => {
        return question && !question.multiple && answer === key ? true : question && question.multiple && answerMulti.includes(key) ? true : false
    }

    useEffect(() => {
        setIsNativeMobile((requestKey || localStorage.getItem("deviceRequestKey")) ? true : false)
    }, [localStorage.getItem("deviceRequestKey")])

    useEffect(() => {
        if (userSurveyDetails)
            setJoinReason(getJoinReason())
        else
            setJoinReason("race")
    }, [userSurveyDetails])

    useEffect(() => {
        setHasChangeMulti(false)
        setAnswer(0)
        setAnswerMulti([])
        setQuestion(questions.find(x => x.seq == Number(seq)))

        // if (userInfo?.account_id)
        //     getSurveyDetails(userInfo?.account_id)
    }, [seq])

    useEffect(() => {
        if (Number(seq) == 1 && userSurveyDetails)
            setAnswer(Number(userSurveyDetails.describe_running))
        else if (Number(seq) == 2 && userSurveyDetails)
            setAnswer(Number(userSurveyDetails.weekly_average))
        else if (Number(seq) == 3 && userSurveyDetails)
            setAnswer(Number(userSurveyDetails.running_goal))
        else if (Number(seq) == 4 && userSurveyDetails)
            setAnswer(Number(userSurveyDetails.struggle_areas.split(",")))
        else if (Number(seq) == 5 && userSurveyDetails)
            setAnswer(Number(userSurveyDetails.add_pt))

    }, [seq, userSurveyDetails])

    useEffect(() => {
        setHasChangeMulti(false)

        // if (userInfo?.account_id)
        //     getSurveyDetails(userInfo?.account_id)

    }, [userInfo])

    useEffect(() => {
        setHasChangeMulti(false)
        initDetails()
    }, [seq, userInfo])

    useEffect(() => {
        if (userSurveyDetails && userSurveyDetails?.struggle_areas && Number(seq) == 4) {
            const numberArray = userSurveyDetails?.struggle_areas.split(",").map(Number);
            setAnswerMulti(numberArray)
        }
    }, [userSurveyDetails])

    return (
        <>
            {/* Styles */}
            < InstabotHide />
            {/* Page */}
            < AppMenus hideOptions hideBackButton >
                <Container fluid="md" className={'survey-form-container account-form'}>
                    <div className='content-questions'>
                        <div className='indicators'>
                            {questions.map(x => (
                                <Indicator navigate={() => navReq('/#/onboarding-survey/questions?seq=' + x.seq)} key={x.id} active={x.seq <= Number(seq) ? true : false} />
                            ))}
                        </div>
                        <h1 className='question'>{question?.question}</h1>
                        {question?.multiple ? (<p className='info'>Select all that apply.</p>) : undefined}
                        <div className={`${question?.multiple ? 'answers-multiple mb-3' : 'answers mb-3'}`}>
                            {question?.answers.map((x, key) => (
                                <Answer isLoading={isLoadingSurvey} option={x} isMultiple={question.multiple} selected={handleCheckSelected(key + 1)} setAnswer={() => handleAnswerClick(key + 1)} key={key + 1} />
                            ))}
                        </div>
												<RSButton sx={{width: '220px'}} onClick={handleContinue} disabled={answer == 0 && answerMulti.length == 0 ? true : isLoadingSurvey ? true : false}>
													{isLoadingSurvey ? <Spinner size="sm" animation='border' /> : "Continue"}
												</RSButton>
                    </div>
                </Container>
            </AppMenus>
        </>
    )
}


export default WithAuth(QuestionPage, true)
