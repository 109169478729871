import { useState, FC } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'

import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Input from '@mui/material/InputBase';

import RSHeaderCont from './RSHeaderCont'
import RSSideMenu from './RSSideMenu'
import RSTheme from '../styles/RSTheme'
import RSIcon from '../icons/RSIcon'
import RSAnnouncement from '../custom-popover/RSAnnouncement'
import GoogleCastButton from '../buttons/GoogleCastButton'
import { HOME_LOGO } from '../../modules/miscImages'
import Loader from '../animation/Loader'
import { LoaderProps } from '../../modules/types'
import { signOut } from '../../modules/accountActions'
import useIsMobileScreen from '../../hooks/useIsMobileScreen'
import useStore from '../../store/useStore'
import { IComponentStore } from '../../store/createComponentStore'
import { ICurrentUser } from '../../store/createAccountStore'

export type RSNavContProps = {
	planInactive?: any,
	children?: any,
	plain?: boolean,
	hideHeader?: boolean,
	hideFooter?: boolean,
	sx?: object,
	contSX?: object,
	onClick?: any,
	loaderProps?: LoaderProps
	showLogOut?: boolean,
	disableHome?: boolean,
	addHeader?: number
}

export type MenuItemProps = {
	name?: string,
	link?: string,
	subLinks?: Array<string>,
	logo: Function
}


const RSNavCont: FC<RSNavContProps> = ({ planInactive, children, plain, hideHeader, contSX = {}, sx = {}, loaderProps = {}, onClick, hideFooter, showLogOut, disableHome, addHeader = 0 }) => {
	const [open, setOpen] = useState(false)
	const location = useLocation()
	const navigate = useNavigate()
	const isMobile = useIsMobileScreen();
	const { isMobile: showCast, showAnnouncementUpdates, setShowAnnouncementUpdates } = useStore((state: IComponentStore) => state)
	const { currentUser } = useStore((state: ICurrentUser) => state)
	console.log(currentUser)
	const menuItems = [
		{
			name: 'Home',
			link: '/',
			logo: (isActive?: boolean, bottom?: boolean) => <RSIcon type={`house${isActive ? '-active' : ''}`} className={bottom ? 'footer-icons' : 'header-icons'} />
		},
		{
			name: 'Explore',
			link: '/explore',
			logo: (isActive?: boolean, bottom?: boolean) => <RSIcon type={`menu${isActive ? '-active' : ''}`} className={bottom ? 'footer-icons' : 'header-icons'} />
		},
		{
			name: 'Profile',
			link: '/profile',
			logo: (isActive?: boolean, bottom?: boolean) => <RSIcon type={`profile${isActive ? '-active' : ''}`} className={bottom ? 'footer-icons' : 'header-icons'} />
		}
	]

	const header = hideHeader ? 0 : 1
	const footer = hideFooter ? 0 : 1
	const constStyles = {
		width: '100%',
		height: '100%',
		minHeight: window.innerHeight - ((footer + header + addHeader) * 60),
		overflowX: 'hidden',
		...contSX
	}

	const loaderPropsDefault: LoaderProps = {
		isBehindHeader: true,
		...loaderProps
	}

	const renderFooter = () => {
		return isMobile && !hideFooter ? <RSHeaderCont bottom barSX={{ px: '10px' }} sx={{ zIndex: 1002 }}>
			<Grid container xs={12} sx={{ height: '100%' }} justifyContent='space-between' alignItems='center'>
				{menuItems.map((item: MenuItemProps, i: number) => {
					const linkList = item.subLinks ? [...item.subLinks, item.link] : [item.link]
					const isActive = item.link ?
						(i ? linkList?.find((ll: string | undefined) => ll && location?.pathname?.includes(ll))
							: location?.pathname === item.link)
						: false
					return <Grid container xs={12 / menuItems.length} className='pointer'
						key={'menu-' + i}
						onClick={onClick ? onClick : () => item.link && navigate(item.link, { replace: true })}
						sx={{ position: 'relative', display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', height: '100%' }}
					>
						<Box sx={{ position: 'absolute', top: '0', width: '100%', height: '3px', backgroundColor: isActive ? '#9fcd2b' : 'white' }} />
						<Box sx={{ height: '37px', width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>{item.logo(isActive, true)}</Box>
						<Box sx={{ fontSize: '12px', fontFamily: 'Poppins-Medium' }}>{item.name}</Box>
					</Grid>
				})}
			</Grid>
		</RSHeaderCont> : <></>
	}

	const renderHeader = () => {
		return !hideHeader ? <RSHeaderCont sx={{ zIndex: 1002 }}>
			<Grid container xs={12} sx={{ height: '100%' }} justifyContent='space-between' alignItems='center'>
				<Box className={disableHome ? '' : 'pointer'} sx={{ display: 'flex' }}>
					<img onClick={() => !disableHome && navigate('/', { replace: true })} className='header-logo' src={HOME_LOGO} alt="RunSmart Home Logo" />
					{!isMobile && false && <Box sx={{ borderRadius: '5px', backgroundColor: 'rgba(159,205,43,0.2)', ml: '30px', width: '250px', display: 'flex', justifyContent: 'flex-start', alignItems: 'center' }}>
						<i style={{ padding: '0px 10px' }} className="fa-regular fa-magnifying-glass"></i>
						<Input placeholder="Search.." />
					</Box>}
				</Box>
				<Box sx={{ height: '100%', display: 'flex' }}>
					{
						!plain && !planInactive && <>
							{
								!isMobile && menuItems.map((item: MenuItemProps, i: number) => {
									const linkList = item.subLinks ? [...item.subLinks, item.link] : [item.link]
									const isActive = item.link ?
										(i ? linkList?.find((ll: string | undefined) => ll && location?.pathname?.includes(ll))
											: location?.pathname === item.link)
										: false
									return <Box className='pointer'
										key={'menu-' + i}
										onClick={onClick ? onClick : () => item.link && navigate(item.link, { replace: true })}
										sx={{ position: 'relative', display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', px: '5px' }}
									>
										<Box>{item.logo(isActive)}</Box>
										{item.name && <Box sx={{ fontSize: '0.8em' }}>{item.name}</Box>}
										{item.name && <Box sx={{ position: 'absolute', bottom: '0', width: '100%', height: '3px', backgroundColor: isActive ? '#9fcd2b' : 'white' }} />}
									</Box>
								})
							}
							{showCast && isMobile && <Grid container sx={{ px: '20px', mt: '-3px' }} justifyContent='center' alignItems='center'>
								<GoogleCastButton vimeoId={location.pathname.split('/')[3]} />
							</Grid>}
							{/* <Grid container className='pointer' onClick={() => setOpen(true)} sx={{ pl: isMobile ? 0 : '20px' }} justifyContent='center' alignItems='center'>
								<i style={{ fontSize: '22px' }} className="fa-light fa-bars"></i>
							</Grid> */}
						</>
					}
					{showLogOut && <Box className='pointer' onClick={signOut}
						sx={{ position: 'relative', display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', px: '5px' }}
					>
						<Box><i style={{ fontSize: '22px' }} className="fa-regular fa-arrow-right-from-bracket"></i></Box>
						{!isMobile && <Box sx={{ fontSize: '0.8em' }}>LogOut</Box>}
					</Box>}
				</Box>
			</Grid>
		</RSHeaderCont> : <></>
	}

	return <RSTheme>
		<Box sx={{ width: '100%', display: 'flex', flexDirection: 'column', ...sx }} justifyContent='flex-start' alignItems='center'>
			{loaderPropsDefault.active && <Box sx={{ position: 'absolute', height: '100%', top: 0, width: '100%' }}>
				<Loader {...loaderPropsDefault} />
			</Box>}
			{renderHeader()}
			<Box className='default-mw' sx={constStyles}>
				{children}
			</Box>
			{renderFooter()}
			<RSSideMenu open={open} onClose={() => setOpen(false)} />
			<RSAnnouncement showAnnouncementUpdates={showAnnouncementUpdates} setShowAnnouncementUpdates={setShowAnnouncementUpdates} />
		</Box>
	</RSTheme>
}

export default RSNavCont;
