import React from 'react'
import { Button, Form, Modal } from 'react-bootstrap'

const EditLocationModal = (props: any) => {

    const {
        showEditLocations,
        handleCloseEditLocations,
        locationInput,
        setLocationInput,
        isLoading,
        handleSubmitLocation,
        // currentLocation
    } = props

    return (
        <Modal
            className="common-modal build-plan edit-locations-modal"
            show={showEditLocations}
            onHide={handleCloseEditLocations}
            backdrop="static"
            keyboard={false}

        >
            <Modal.Header closeButton>
                <h2 className="traning-popup--title mb-3">Edit Locations</h2>
            </Modal.Header>
            <Modal.Body className="mt-0 pt-0 mx-5 px-md-5 px-3 text-center">
                <p className="mt-0 mb-0 font-weight-400">
                    By entering your location, we will track current weather
                    conditions and help you pace accordingly. We will allow you to
                    change this at a later date.
                </p>
                <Form>
                    <Form.Group className="mt-4 position-relative">
                        <Form.Control
                            defaultValue={locationInput}
                            onChange={(e) => setLocationInput(e.target.value)}
                            type="text"
                            placeholder='Enter "Zip, Country Code" or City Name'
                        />
                        <div className="edit-loations-pin">
                            <img
                                className="pointer"
                                src="./images/icons/edit-black.png"
                                alt="Edit"
                            ></img>
                        </div>
                    </Form.Group>
                </Form>
                <div className="center-align">
                    <Button
                        disabled={isLoading}
                        variant="primary"
                        onClick={handleSubmitLocation}
                        className="rounded-pill color-primary-bg save-btn mt-4 center-align text-capitalize letter-space btn-dark"
                    >
                        {isLoading ? "Saving Location..." : "Save"}
                    </Button>
                </div>
            </Modal.Body>
        </Modal>
    )
}

export default EditLocationModal