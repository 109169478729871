import { AllRSCategories } from '../modules/types'
import programInfo from '../modules/videos/program-info.json'
import allVideos, { Video } from '../modules/videos/allVideos'
import allCategories from '../modules/static-images/allCategories'
import { AuthPageProps } from '../modules/types'
import { getProgressData } from './useVideoProgress'
import { useState, useEffect } from 'react'
import {
	baseThree,
	baseSix,
	mechanix,
	yogaIntro,
	yoga,
	primeIntro,
	prime,
	midfoot,
	recover
} from '../modules/videos/videoOrders'
import { IVideoStore } from '../store/createVideoStore'
import useStore from '../store/useStore'
// import { IComponentStore } from '../store/createComponentStore'

// Video Orders
const videoOrders = {
	Base3: baseThree,
	Base6: baseSix,
	Mechanix: mechanix,
	Yoga: yoga,
	Prime: prime,
	Midfoot: midfoot,
	Recover: recover
}

// Mechanix Phases
const mechanixPhases = [0, 10, 17, 26]

// Default Progress
export const DEFAULT_PROGRESS = { isComplete: false, progress: 0 }

/**
 * useProgramPage Hook
 */

const useProgramPage = ({
	programType,
	currentPhase,
	videoId,
	userInfo
}: {
	programType: keyof AllRSCategories['programs']
	currentPhase?: number
	videoId?: number | string
	userInfo?: AuthPageProps['userInfo']
}) => {
	const { videoProgressData } = useStore((state: IVideoStore) => state)
	// const { isLoading: isLoadingData } = useStore((state: IComponentStore) => state)
	// Get Video Info
	const getVideoInfo = () => {
		const currentProgram = allCategories.programs[programType]
		const introVideoIds =
			programType == 'Yoga'
				? yogaIntro
				: programType == 'Prime'
					? primeIntro
					: []
		const isMechanix = programType == 'Mechanix'
		const mechanixIndex =
			isMechanix && videoId ? videoOrders.Mechanix.indexOf(Number(videoId)) : 0
		const mechanixPhase = isMechanix
			? currentPhase != null && !videoId
				? currentPhase
				: mechanixIndex < mechanixPhases[1]
					? 0
					: mechanixIndex >= mechanixPhases[1] &&
						mechanixIndex < mechanixPhases[2]
						? 1
						: mechanixIndex >= mechanixPhases[2] &&
							mechanixIndex < mechanixPhases[3]
							? 2
							: 3
			: 0
		const workoutVideoIds = isMechanix
			? videoOrders.Mechanix.slice(
				mechanixPhases[mechanixPhase],
				mechanixPhases[mechanixPhase + 1]
			)
			: videoOrders[programType]
		const workoutVideos: Video[] = []
		const introVideos: Video[] = []
		const currentProgramInfo = (programInfo as any)[programType]
		const currentWorkoutVideoId = videoId ? String(videoId) : workoutVideoIds[0]
		const currentWorkoutVideoIndex = workoutVideoIds.indexOf(
			Number(currentWorkoutVideoId)
		)
		const nextWorkoutVideoIndex =
			currentWorkoutVideoIndex < workoutVideoIds.length - 1
				? currentWorkoutVideoIndex + 1
				: 0
		const nextWorkoutVideoId = workoutVideoIds[nextWorkoutVideoIndex]
		const currentIntroVideoId = introVideoIds[0]

		// Get Text
		const title = currentProgramInfo?.about
			? currentProgramInfo.about.primary_headline
			: ''
		const aboutHeadlines = currentProgramInfo?.about
			? [currentProgramInfo.about.primary_description]
			: []
		const aboutDescriptions: string[] = []
		const introBodyText = currentProgramInfo?.info?.primary_description || ''

		// Populate Headlines/Descriptions
		if (currentProgramInfo) {
			for (const key in currentProgramInfo.about) {
				if (!key.startsWith('primary')) {
					if (key.includes('headline')) {
						aboutHeadlines.push(currentProgramInfo.about[key])
					} else if (key.includes('description')) {
						aboutDescriptions.push(currentProgramInfo.about[key])
					}
				}
			}
		}

		// Get Videos
		introVideoIds.map((id) => introVideos.push(allVideos[id]))
		workoutVideoIds.map((id) => workoutVideos.push(allVideos[id]))
		return {
			featureImage: currentProgram.feature,
			currentIntroVideoId,
			currentWorkoutVideoId,
			nextWorkoutVideoId,
			currentIntroVideo: currentIntroVideoId
				? allVideos[currentIntroVideoId]
				: undefined,
			currentWorkoutVideo: allVideos[currentWorkoutVideoId],
			currentWorkoutVideoProgressInfo: { ...DEFAULT_PROGRESS },
			nextWorkoutVideo: allVideos[nextWorkoutVideoId],
			nextWorkoutVideoProgressInfo: { ...DEFAULT_PROGRESS },
			introVideos,
			workoutVideos,
			aboutHeadlines,
			aboutDescriptions,
			mechanixPhase,
			title,
			introBodyText,
			introSubtitleText: 'How-To Videos',
		}
	}

	// State
	const [info, setInfo] = useState(getVideoInfo())
	// const [data, setData] = useState(undefined as any)

	// Get Progress Info
	const getProgressInfo = async (newInfo: ReturnType<typeof getVideoInfo>, videoProgressData: any) => {
		const { newInfo: progressInfo } =
			await getProgressData(
				[newInfo.currentWorkoutVideo, newInfo.nextWorkoutVideo],
				userInfo,
				videoProgressData,
				true
			)
		// setData(progressData)
		// setVideoProgressData(videoProgressData)
		setInfo({
			...newInfo,
			currentWorkoutVideoProgressInfo: progressInfo?.[0] || {
				...DEFAULT_PROGRESS,
			},
			nextWorkoutVideoProgressInfo: progressInfo?.[1] || {
				...DEFAULT_PROGRESS,
			},
		})
	}

	// Change Effect
	useEffect(() => {
		const newInfo = getVideoInfo()
		if (videoProgressData)
			getProgressInfo(newInfo, videoProgressData)
		else
			getProgressInfo(newInfo, [])
	}, [userInfo, programType, videoId, currentPhase])


	// Return
	return info
}

export default useProgramPage
