// WebSocketProvider.js
import React, { createContext, FC } from 'react';
import useWebSocket from 'react-use-websocket';
import { SendJsonMessage } from 'react-use-websocket/dist/lib/types';
import { WS_URL } from './modules/cliEnvValues';

interface IValue {
    sendJsonMessage?: SendJsonMessage,
    lastJsonMessage: any
}
const WebSocketContext = createContext<IValue>({ sendJsonMessage: undefined, lastJsonMessage: '' });

const WebSocketProvider: FC<{ children: any }> = ({ children }) => {
    const { sendJsonMessage, lastJsonMessage } = useWebSocket(WS_URL,
        {
            shouldReconnect: () => {
                return true;
            },
            reconnectAttempts: 3,
            reconnectInterval: 10000,
        }, true);
    return (
        <WebSocketContext.Provider value={{ sendJsonMessage, lastJsonMessage }}>
            {children}
        </WebSocketContext.Provider>
    );
};

export { WebSocketProvider, WebSocketContext };