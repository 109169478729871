/* eslint-disable @typescript-eslint/no-unused-vars */
import { FC, useEffect, useState } from 'react'
import useSWR from 'swr'
import moment from 'moment'

import Grid from '@mui/material/Grid'
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import ArrowForward from '@mui/icons-material/ArrowForwardIos'
import Avatar from '@mui/material/Avatar';

import ProfileImage from './ProfileImage'
import HeightWeight from './HeightWeight'
import Nutrition from './Nutrition'
import MeasurementSystem from './MeasurementSystem'
import Gender from './Gender'
import DOB from './DOB'
import LongRunDay from './LongRunDay'
import RunningStyle from './RunningStyle'
import PhysicalActivity from './PhysicalActivity'
import AverageRun from './AverageRun'
import { AuthPageProps } from '../../modules/types'
import { getHeight, getWeight, capCase } from '../../modules/miscUtils'
import { UpdateAccountProfile, getAllAccountInfo, getSubscriptionInfo, signOut } from '../../modules/accountActions'
import useIsMobileScreen from '../../hooks/useIsMobileScreen'
import WithAuth from '../../components/WithAuth'
import RSNavCont from '../../components/layout/RSNavCont'
import RSDrawer from '../../components/layout/RSDrawer'
import RSHeaderCont from '../../components/layout/RSHeaderCont'
import { AccountProfile } from '../../api/v2/account/Account.types'
import IntegrationsMainPage from '../integrations/IntegrationsMainPage'
import { getReq } from '../../modules/apiConsume'
import NameProfileImage from './NameProfileImage'

import textHelperUtils from '../../utils/textHelperUtils'
import ChangePassword from '../../components/page-specific/account/ChangePassword'
import useAlert from '../../hooks/useAlert'
import Subscription from '../../components/page-specific/account/Subscription'
import { navReq } from '../../modules/apiConsume'
import { Elements, RecurlyProvider } from '@recurly/react-recurly'
import { RECURLY_PUBLIC_KEY } from '../../modules/cliEnvValues'
import Billing from '../../components/page-specific/account/Billing'
import ImageLogo from '../../components/logos/ImageLogo'
import Email from './Email'
import RSButton from '../../components/buttons/RSButton'
import RSChurrosButton from '../../components/buttons/RSChurrosButton'
import Location from './Location'
import secureLocalStorage from 'react-secure-storage'
import YourTrainingJourney from '../training-plan/YourTrainingJourney'
import RSCardCont from '../../components/cards/RSCardCont'
import { MISC_LOGOS } from '../../modules/miscImages'
import AccountDeletion from './AccountDeletion'
import { getCurrentAmount, getCurrentPlanCode } from '../../modules/subscriptionUtils'
import useQueryParams from '../../hooks/useQueryParams'
import useStore from '../../store/useStore'
import { ICurrentUser } from '../../store/createAccountStore'
import useRNBridge from '../../hooks/useRNBridge'
import ShareInvite from './ShareInvite'

type InfoListProps = {
	label: string,
	data?: any,
	logo: any,
	component?: any,
	hidden?: boolean
	disabled?: boolean
}

type GroupListProps = {
	title: string,
	infoList: Array<InfoListProps>
}


const Profile: FC<AuthPageProps> = ({ userInfo, hasLoaded }) => {
	const isMobile = useIsMobileScreen()
	const [showContent, setShowContent] = useState<Array<number>>([])
	const [mainLoading, setMainLoading] = useState(false)
	const [refreshImageKey, setRefreshImageKey] = useState(new Date().getTime());
	const [userGarminDetails, setUserGarminDetails] = useState<any>()
	const [planType, setPlanType] = useState<string>('')
	const [paymentType, setPaymentType] = useState<string>('')

	const { subscription, billingInfo, userCoords } = useStore((state: ICurrentUser) => state)

	const { data: accountProfile, isLoading, mutate } = useSWR(hasLoaded && userInfo?.account_id ? `/account-profile?account_id=${userInfo?.account_id}` : null, { refreshInterval: 0 })

	const accountProfileData = accountProfile?.data.result[0] as AccountProfile || undefined
	const { physical_activity, running_activity, running_style, dob, gender, run_day, measurement_system, weight_lbs, height_cm, name, user_id, profile_image_path, dietary_preferences, location } = accountProfileData || {}
	const isMetric = measurement_system === 'metric' ? true : false

	const { feet = 0, inch = 0, cm = 0 } = height_cm ? getHeight(true, { feet: 0, inch: 0, cm: height_cm }) : {}
	const { kg = 0, lbs = 0 } = weight_lbs ? getWeight(false, { kg: 0, lbs: weight_lbs }) : {}

	const isCoupon = userInfo?.paymentType && userInfo?.paymentType.includes('coupon') ? true : false

	const nameSP = name ? name : (userInfo?.first_name || "") + " " + (userInfo?.last_name || "") || ""

	const { changeLocation, changeUnits, oauth_token, oauth_verifier, timeline } = useQueryParams()

	const { capitalizeEachWord } = textHelperUtils()

	const onCancelSubscription = () => navReq('/#/cancel/step-1')

	const closeDrawer = () => setShowContent([])

	const { sendDataToReactNative } = useRNBridge()

	const isNativeApp = localStorage.getItem('deviceRequestKey') ? true : false

	const appleHealthPermission = JSON.parse(localStorage.getItem('healthkit-permission') || '{}')

	const [enableDevMode, setEnableDevMode] = useState(true)

	 console.log(accountProfileData)
	// console.log(userInfo)

	const handleUpdateProfile = async (dataOBJ: object) => {
		if ((user_id || userInfo?.account_id) && dataOBJ && Object.keys(dataOBJ)?.length) {
			setMainLoading(true)
			await UpdateAccountProfile({ user_id: user_id || userInfo?.account_id, ...dataOBJ }).then(() => {
				mutate?.()
				closeDrawer()
			})
			setMainLoading(false)
		}
	}

	const handleGetNativeLocation = () => {
		const dataToSend = {
			requestType: 'geolocation-enable',
			payload: true,
			timestamp: new Date().getTime(),
		}
		sendDataToReactNative(dataToSend);
	}

	const handleGetZipcode = async (coords: GeolocationCoordinates) => {
		const { latitude, longitude } = coords;
		const zipcodes = await getReq(`/v2/findNearbyPostalCodes?latitude=${latitude}&longitude=${longitude}`)
		if (zipcodes?.data?.postalCodes?.length > 0) {
			const address = zipcodes.data.postalCodes[0]
			await handleUpdateProfile({ location: address.placeName + ', ' + address.countryCode })
		}
	}

	const getGarminDetails = async () => {
		const response = await getReq('/v2/garmin/user')
		const data = response.data.result[0]
		// console.log(data)
		setUserGarminDetails(data)
	}

	const getData = async () => {
		const activeDetails = subscription?.active || {}
		const planCode = getCurrentPlanCode(activeDetails)
		const rate = getCurrentAmount(activeDetails)
		setPlanType('$' + rate + '/' + (planCode.includes('month') ? 'month' : planCode.includes('annual') ? 'year' : ''))
		setPaymentType(capitalizeEachWord((billingInfo?.paymentType ? billingInfo?.paymentType : userInfo?.paymentType ? userInfo.paymentType : 'None' || '')?.replace(/_/g, ' ')))
	}


	const {
		onAlertOpen,
		setAlertMessage,
		setAlertVariant,
	} = useAlert()

	const allProps = { data: accountProfileData || {}, handleUpdateProfile }

	const groupList: Array<GroupListProps> = [
		{
			title: 'Personal',
			infoList: [
				{
					label: '',
					data: { accountProfileData },
					logo: '',
					component: <NameProfileImage userInfo={userInfo} isMobile={isMobile} {...allProps} reload={() => {
						mutate()
						setRefreshImageKey(new Date().getTime())
						closeDrawer && closeDrawer()
					}} />,
					hidden: true
				},
				{
					label: '',
					data: '',
					logo: '',
					component: <YourTrainingJourney userInfo={userInfo} onCloseModal={closeDrawer} />,
					hidden: true
				},
				{
					label: 'Gender',
					data: gender || 'None',
					logo: <i className="fa-duotone fa-person-half-dress duo-tone" />,
					component: <Gender {...allProps} />
				}, {
					label: 'Date of Birth',
					data: dob ? moment(dob).format('MMM DD, YYYY') : 'None',
					logo: <i className="fa-duotone fa-cake-candles duo-tone" />,
					component: <DOB {...allProps} />
				}, {
					label: 'Location',
					data: location || 'None',
					logo: <i className={`fa-duotone fa-location-dot${location ? '' : '-slash'} duo-tone`} />,
					component: <Location {...allProps} getLocation={isNativeApp ? handleGetNativeLocation : undefined} />,
				}, {
					label: 'Height & Weight',
					data: isMetric ? `${kg || 0}kg | ${cm || 0} cm` : `${lbs || 0}lbs | ${feet || 0}'${inch || 0}"`,
					logo: <i className="fa-duotone fa-weight-scale duo-tone" />,
					component: <HeightWeight {...allProps} />
				}, {
					label: 'Running Style',
					data: running_style || 'None',
					logo: <i className="fa-duotone fa-person-running duo-tone" />,
					component: <RunningStyle {...allProps} />
				}, {
					label: 'Physical Activity',
					data: physical_activity || 'None',
					logo: <i className="fa-duotone fa-person-running duo-tone" />,
					component: <PhysicalActivity {...allProps} />
				}, {
					label: 'Average run per week',
					data: running_activity?.replace(isMetric ? 'mi' : 'km', isMetric ? 'km' : 'mi') || 'None',
					logo: <i className="fa-duotone fa-person-running duo-tone" />,
					component: <AverageRun {...allProps} />
				}
			]
		}, {
			title: 'Preferences',
			infoList: [
				{
					label: 'Nutrition Preferences',
					data: capCase(dietary_preferences) || 'None',
					logo: <i className="fa-duotone fa-salad duo-tone" />,
					component: <Nutrition {...allProps} />
				}, {
					label: 'Preferred Long Run Days',
					data: run_day || 'None',
					logo: <i className="fa-duotone fa-person-running-fast duo-tone" />,
					component: <LongRunDay {...allProps} />
				}, {
					label: 'Measurement System',
					data: capCase(measurement_system) || 'None',
					logo: <i className="fa-duotone fa-light fa-ruler duo-tone" />,
					component: <MeasurementSystem {...allProps} />
				}
			]
		}, {
			title: 'Account',
			infoList: [
				{
					label: 'Connected Watch',
					data: userGarminDetails && userGarminDetails['status'] == 1 ? 'Garmin' : appleHealthPermission?.status === "granted" ? 'Apple' : 'None',
					logo: <i className={`fa-duotone fa-watch duo-tone ${userGarminDetails && userGarminDetails['status'] == 1 || appleHealthPermission?.status === "granted" ? '' : 'duo-tone-red'}`} />,
					component: <IntegrationsMainPage reload={async () => await getGarminDetails()} userInfo={userInfo} />,
				}, {
					// hidden: secureLocalStorage.getItem("isAppleSignup") ? true : false,
					label: secureLocalStorage.getItem("isAppleSignup") || secureLocalStorage.getItem("isGoogleSignup") ? "Sign In" : 'Update Email',
					data: secureLocalStorage.getItem("isAppleSignup") ? "Sign in with Apple" : secureLocalStorage.getItem("isGoogleSignup") ? "Sign in with Google" : accountProfileData?.email || userInfo?.email || 'None',
					logo: <i className="fa-duotone fa-at duo-tone" />,
					disabled: secureLocalStorage.getItem("isAppleSignup") || secureLocalStorage.getItem("isGoogleSignup") ? true : false,
					component: <Email
						onAlertOpen={onAlertOpen}
						setAlertMessage={setAlertMessage}
						setAlertVariant={setAlertVariant}
						reload={() => {
							mutate()
							closeDrawer && closeDrawer()
						}}
						{...allProps}
						userInfo={userInfo}
					/>
				}, {
					label: 'Update Password',
					logo: <i className="fa-duotone fa-lock-keyhole duo-tone" />,
					component: <ChangePassword
						onAlertOpen={onAlertOpen}
						setAlertMessage={setAlertMessage}
						setAlertVariant={setAlertVariant}
					/>
				}, {
					label: 'Delete Account',
					logo: <i className="fa-duotone fa-trash-can duo-tone" />,
					component: <AccountDeletion />,
					hidden: true
				}
			]
		}, {
			title: 'Billing',
			infoList: [
				{
					hidden: isCoupon,
					label: 'Manage Subscription',
					data: planType,
					logo: <ImageLogo type="subscription" height={25} />,
					component: <Subscription
						isRenew={false}
						isExpiring={userInfo?.is_expiring}
						isCoupon={isCoupon}
						billingIsValid={userInfo?.is_valid}
						onSubmit={undefined}
						onCancel={onCancelSubscription}
						onAlertOpen={onAlertOpen}
						setAlertMessage={setAlertMessage}
						setAlertVariant={setAlertVariant}
					/>
				}, {
					hidden: localStorage.getItem('deviceRequestKey') ? true : false,
					label: 'Manage Billing',
					data: paymentType,
					logo: <i className="fa-duotone fa-credit-card duo-tone" />,
					component: <RecurlyProvider publicKey={RECURLY_PUBLIC_KEY}>
						<Elements>
							<Billing
								userInfo={userInfo}
								afterBillingProcess={closeDrawer}
								isUpdate={false}
								onAlertOpen={onAlertOpen}
								setAlertMessage={setAlertMessage}
								setAlertVariant={setAlertVariant}
								isAccount={true}
								hideDue
							/>
						</Elements>
					</RecurlyProvider>
				}
			]
		}]



	const renderDrawer = () => {
		let drawer = ''
		if (showContent.length == 2)
			drawer = groupList[showContent[0] - 1]?.infoList?.[showContent[1] - 1]?.component
		return drawer
	}

	const renderTrainingHistory = () => {
		const symbolElement = <img src={MISC_LOGOS.training} style={{ scale: '0.7', width: '100%', height: '100%', marginLeft: '-15px', marginTop: '5px' }} />
		return <RSChurrosButton
				 symbolElement={symbolElement}
				 title='Training Timeline'
				 dipColor='#9fcd2b'
				 onClick={() => setShowContent([1, 2])}
				 isLoading={hasLoaded && !isLoading ? false : true}
				 arrow
				 sx={{maxWidth: '100% !important'}}
		 />
//		return (
//			<RSCardCont onClick={() => { setShowContent([1, 2]) }} isLoading={hasLoaded && !isLoading ? false : true} sx={{ height: '80px', p: 0, overflow: 'hidden', width: isMobile ? '95%' : '98%', mx: '10px', mb: "15px" }}>
//				<Box sx={{ height: '80px', width: '100%', display: 'flex', justifyContent: 'flex-start', alignItems: 'center' }}>
//					<Grid container xs={3} sx={{ backgroundColor: `#9fcd2b`, position: 'relative', height: '80px', zIndex: 1, display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
//						{/* <Box sx={{ position: 'absolute', height: '100%', width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}> */}
//						<img src={MISC_LOGOS.training} style={{ scale: '0.8', width: '100%', height: '100%', marginLeft: '5px', marginTop: '5px' }} />
//						{/* <i style={{ fontSize: '52px', color: '#fff' }} className="fa-thin fa-calendar-week" /> */}
//						{/* </Box> */}
//					</Grid>
//					<Grid container xs={9} sx={{ height: '80px', position: 'relative', overflow: 'hidden' }}>
//						<Box sx={{ background: `#9fcd2b`, width: '33px', height: '115px', position: 'absolute', transform: 'rotate(16deg)', left: -22, top: -12 }} />
//						<Grid container xs={12} sx={{ pl: '30px', pr: '24px', flexDirection: 'column', height: '80px' }} justifyContent='center' alignItems='flex-start'>
//							<Box sx={{ fontSize: '16px', fontFamily: 'Poppins', lineHeight: '1.2em', textAlign: 'left' }}>Training Timeline</Box>
//						</Grid>
//					</Grid>
//					<ArrowForward fontSize="medium" sx={{ stroke: 'white', strokeWidth: '1.3px', mx: '13px' }} />
//				</Box>
//			</RSCardCont>
//		)
	}

	const renderShop = () => {
		return <RSButton secondary card addBorder>
			<Box sx={{px: 1, display: 'flex'}}>
				<i className="fa-duotone fa-solid fa-store duo-tone" style={{fontSize: '22px' }}/>
			</Box>
			<Box sx={{pr: 1}}>
				Shop
			</Box>
		</RSButton>
	}

	useEffect(() => {
		if (userInfo?.account_id)
			getGarminDetails()

	}, [userInfo])

	useEffect(() => {
		if (billingInfo && subscription)
			getData()
	}, [billingInfo, subscription])

	useEffect(() => {
		if (timeline) {
			setShowContent([1, 2])
			window.location.replace('/#/profile')
		}
	}, [timeline])

	useEffect(() => {
		if (changeLocation && accountProfileData) {
			setShowContent([1, 5])
			window.location.replace('/#/profile')
		}
	}, [changeLocation, accountProfileData])

	useEffect(() => {
		if (changeUnits && accountProfileData) {
			setShowContent([2, 3])
			window.location.replace('/#/profile')
		}
	}, [changeUnits, accountProfileData])

	useEffect(() => {
		if (oauth_token && oauth_verifier) {
			setShowContent([3, 1])
		}
	}, [oauth_token, oauth_verifier])

	useEffect(() => {
		if (userCoords) {
			handleGetZipcode(userCoords)
		}
	}, [userCoords])

	useEffect(() => {
		if (isNativeApp) {
			handleGetNativeLocation()
		}
	}, [isNativeApp])

	return (
		<RSNavCont loaderProps={{ active: !hasLoaded || isLoading || mainLoading, isBehindHeader: showContent ? false : true }} hideHeader={isMobile}>


			<RSHeaderCont sx={{ mb: '20px' }}>
				<Grid container sx={{ fontSize: '16px', fontFamily: 'Poppins-Bold' }} justifyContent='center' alignItems='center'>
					Profile
				</Grid>
			</RSHeaderCont>

			<Box sx={{ m: 1.6 }}>
				<Grid container xs={12} sx={{ px: 2 }} justifyContent="center" alignItems="center" >
					<Grid className='pointer' sx={{ pr: '10px'}}>
						<ProfileImage openForm={() => setShowContent([1, 1])} refreshImageKey={refreshImageKey} firstName={accountProfileData?.first_name || userInfo?.first_name || ""} lastName={accountProfileData?.last_name || userInfo?.last_name || ""} src={profile_image_path} />
					</Grid>

					<Grid sx={{maxWidth: 'calc(100% - 135px)', height: 'auto', my: 'auto' }}>
						<Grid container xs={12} onClick={() => setShowContent([1, 1])}>
							<Box sx={{width: 'auto', pr: '25px', position: 'relative', fontFamily: 'Poppins', fontSize: '20px', lineHeight: '1em', textAlign: 'left'}}>
								{nameSP?.trim() ? nameSP : 'First Name, Last Name'}
								<Box sx={{position: 'absolute', right : 0, top: 0, fontSize: '20px', width: '21px', display: 'flex'}}>
									<i className='fa-thin fa-edit' />
								</Box>
							</Box>
						</Grid>
						<Grid xs={12} className='ellipsis-1l' sx={{fontFamily: 'Poppins-Light', fontSize: '14px'}}>
							{accountProfileData?.email || userInfo?.email || 'None'}
						</Grid>
					</Grid>
				</Grid>

				{/* @@@ Share Button */}

				<Grid container xs={12} sx={{ px: '10px', pt: 3, pb: 2}} justifyContent='space-between'>
					{enableDevMode && <Grid container sx={{maxWidth: 'calc(50% - 5px)'}}>
						<ShareInvite />
					</Grid>}
					<Grid container sx={{maxWidth: 'calc(50% - 5px)'}} justifyContent='flex-end'>
						{renderShop()}
					</Grid>
				</Grid>
				<Grid container xs={12} sx={{ px: '10px'}}>
					<Box sx={{ fontFamily: 'Poppins-Medium', fontSize: '20px', pb: '5px' }}>Running Plans</Box>
				</Grid>
				<Grid container xs={12} sx={{ px: '10px', pt: 0, pb: '10px'}}>
					{renderTrainingHistory()}
				</Grid>
				{
					groupList.map((groupData, gI: number) => {
						const { title, infoList } = groupData
						return <Grid container xs={12} sx={{ p: '10px' }} justifyContent="flex-start" alignItems="center" key={gI + '-group'}>
							<Box sx={{ fontFamily: 'Poppins-Medium', fontSize: '20px', pb: '5px' }}>{title}</Box>
							<Box sx={{ mx: 'auto', backgroundColor: 'white', color: 'gray', width: '100%', borderRadius: '10px' }}>
								{infoList.map((listData, lI) => {
									const { label, logo, data, component, hidden, disabled } = listData
									return hidden ? '' : <Grid container key={lI + '-side-menu'} xs={12} sx={{ height: '60px', borderBottom: lI === infoList.length - 1 ? '' : '1px solid #8080803b' }} justifyContent="space-between" alignContent="center" alignItems="center">
										<Button disabled={disabled} onClick={() => component && setShowContent([gI + 1, lI + 1])} sx={{ p: '10px', width: '100%', color: '#010101', justifyContent: 'space-between', textTransform: 'none', height: '100%' }}>
											<Grid container sx={{ width: 'calc(100% - 22px)' }} justifyContent='flex-start' alignItems='center'>
												<Box sx={{ width: '40px', display: 'flex', justifyContent: 'center', alignItems: 'center', fontSize: '30px', mr: '5px' }}>{logo}</Box>
												<Box sx={{ width: 'calc(100% - 45px)' }}>
													<Box sx={{ fontSize: '16px', textAlign: 'left', lineHeight: '1.2em', fontFamily: 'Poppins' }} >{label}</Box>
													{data ? <Box className='ellipsis-1l' sx={{ width: '100%', fontSize: '14px', textAlign: 'left', lineHeight: '1.2em', fontFamily: 'Poppins-Light', fontStyle: 'italic', height: '17px' }} >{data}</Box> : ''}
												</Box>
											</Grid>
											<Box sx={{ color: 'black', position: 'relative', display: 'flex', justifyContent: 'center', alignItems: 'center', width: '22px' }}>
												<ArrowForward fontSize="medium" sx={{ stroke: 'white', strokeWidth: '1.3px' }} />
											</Box>
										</Button>
									</Grid>
								})}
							</Box>
						</Grid>
					})
				}
				<RSDrawer bottom fitContent={showContent[0] == 1 && showContent[1] == 2 && isMobile ? false : true} popUpWidth={showContent[0] == 1 && showContent[1] == 2 && !isMobile ? '700px' : '400px'} popUpHeight={showContent[0] == 1 && showContent[1] == 2 && !isMobile ? '90%' : 'auto'} open={showContent.length ? true : false} onClose={closeDrawer}>
					<Box sx={{ py: showContent[0] == 1 && showContent[1] == 2 ? 0 : 3, px: 2 }}>
						{renderDrawer()}
					</Box>
				</RSDrawer>

				<Grid container xs={12} justifyContent="center" alignItems="center" sx={{ mt: 2, mb: 3 }}>
					<RSButton secondary customized sx={{
						display: 'flex',
						justifyContent: 'center',
						alignItems: 'center',
						p: '15px 15px 15px 0px',
						mt: '10px', maxWidth: '200px',
						fontWeight: 'lighter'
					}}
						onClick={signOut}
					>
						Logout
					</RSButton>
				</Grid>
				<Grid container xs={12} justifyContent="center" alignItems="center" sx={{ mt: 2, mb: 3 }}>
					<RSButton tertiary customized sx={{
						display: 'flex',
						justifyContent: 'center',
						alignItems: 'center',
						p: '15px 15px 15px 0px',
						mt: '10px', maxWidth: '200px',
						fontWeight: 'lighter'
					}}
						onClick={() => setShowContent([3, 4])}
					>
						Delete Account
					</RSButton>
				</Grid>
				<Grid container xs={12} justifyContent="center" alignItems="center" sx={{ mt: 2, mb: 3 }}>
					<RSButton tertiary customized sx={{
						display: 'flex',
						color: '#EEE',
						justifyContent: 'center',
						alignItems: 'center',
						p: '15px 15px 15px 0px',
						mt: '10px', maxWidth: '200px',
						fontWeight: 'lighter'
					}}
						onClick={() => setEnableDevMode(!enableDevMode)}
					>
						dev
					</RSButton>
				</Grid>
			</Box>
		</RSNavCont >
	)
}

export default WithAuth(Profile);
