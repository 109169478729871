import { StoreSlice } from "./types"


export interface IGoogleCastStore {
    activeVideo?: string,
    setActiveVideo: (activeVideo: string) => void,
    selectedVideo?: string,
    setSelectedVideo: (selectedVideo: string) => void,
    castStatus?: string,
    setCastStatus: (castStatus: string) => void,
}

const createGoogleCastStore: StoreSlice<IGoogleCastStore> = (set) => ({
    activeVideo: undefined,
    setActiveVideo: (activeVideo: string) => set(() => ({ activeVideo: activeVideo })),
    selectedVideo: undefined,
    setSelectedVideo: (selectedVideo: string) => set(() => ({ selectedVideo: selectedVideo })),
    castStatus: undefined,
    setCastStatus: (castStatus: string) => set(() => ({ castStatus: castStatus })),
})

export default createGoogleCastStore
