/* eslint-disable @typescript-eslint/no-unused-vars */
import WithAuth from '../../components/WithAuth'
import AppMenus, { BodyComponent } from '../../components/layout/AppMenus'
import { FC, useEffect, useState } from 'react'
import { Container, Row, Col, Button, Placeholder } from 'react-bootstrap'
import { GetAllUserPdf, GetOldUserPDF, GetUserInfo, GetUserPdfData } from '../../modules/trainingPlanActions'
import { Document, Page } from 'react-pdf/dist/umd/entry.webpack';
import { PDFViewer } from '@react-pdf/renderer'
import axios from 'axios'
import { ICurrentUser } from '../../store/createAccountStore'
import useStore from '../../store/useStore'
import { useNavigate } from 'react-router-dom'
import PdfViewer from '../PdfViewer'
// import { Document } from 'react-pdf';

/**
 * Programs Component
 */

const DownloadPlan: FC = () => {
	const { currentUser } = useStore((state: ICurrentUser) => state)
	const [userInfo, setUserInfo] = useState<any>(null)
	const navigate = useNavigate()

	const requestKey = localStorage.getItem('deviceRequestKey')

	const [file, setFile] = useState<any>()
	const [isLoading, setIsLoading] = useState(false)
	const [usersPdfs, setUserPdfs] = useState<Array<any>>([])
	const [userPDFData, setUserPDFData] = useState<any>()
	const [showPdf, setShowPdf] = useState(false)

	const Loader = () => {
		return <div className="download-loader">
			<h6>Download Your Training Plan</h6>
			<p>Hang tight while we generate your training log...</p>
			<img src='/images/icons/logo-gif.gif' />
		</div>
	}

	const isPDFExist = () => {
		//null
	}
	const setHideMainModal = () => {
		//null
	}
	const handleCloseModal = () => setShowPdf(false);
	const handleOpenModal = () => setShowPdf(true);

	const handleDownload = async (userId: number, fileId: number) => {
		await GetOldUserPDF(userId, fileId).then((res) => {
			if (res?.error && res?.error?.message !== "File Not Found!" || res?.status && res?.status === "ok")
				window.open(res?.data?.data?.url)
			else
				getPdfData(userId, fileId)
		})
	}

	const getPdfData = async (userId: number, fileId: number) => {
		await GetUserPdfData(userId, fileId).then((res) => {
			setUserPDFData(res?.data?.data)
			setIsLoading(false)
			handleOpenModal()
		})
	}

	const getUserPdfs = async (abort: AbortSignal) => {
		await GetAllUserPdf(userInfo?.uid, abort).then((res) => {
			setUserPdfs(res?.data?.result)
			setIsLoading(false)
		})
	}

	useEffect(() => {
		const abortController = new AbortController();

		if (userInfo)
			getUserPdfs(abortController.signal)
		return () => {
			abortController.abort();
		}
	}, [userInfo])

	useEffect(() => {
		const abortController = new AbortController();

		setIsLoading(true)
		if (currentUser?.vdot_id) {
			GetUserInfo(currentUser?.account_id as number, abortController.signal).then(res => {
				setUserInfo(res?.data?.result[0])
			})
		}
		return () => {
			abortController.abort();
		}
	}, [currentUser])

	// Render
	return (
		<AppMenus title='DOWNLOAD TRAINING PLANS' hideTitleDesktop isMobileTitle>
			<BodyComponent>
				<div className="download-plan-list">
					<Container>
						{/* <Document file={file}
							options={{ workerSrc: "/pdf.worker.js" }}
							loading={<Loader />}
							onLoadSuccess={(pdf: any) => {
								// const blob = new window.Blob([file as Blob], { type: 'application/pdf' })
								// const url = window.URL.createObjectURL(blob)
								// window.open(url)
							}}
							renderMode="canvas">
							<Page pageNumber={1} width={window.innerWidth} />
						</Document> */}
						<div className="text-center">
							{/* <h1 className="font-weight-bold font-80 text-uppercase"> */}
							{/* <div className="left-icon download-left-icon">
								<Button variant="light" className="back-btn p-0">
									<img
										src="./images/icons/left-icon.svg"
										alt="Back"
										width={'28px'}
									/>
								</Button>

							</div> */}
							<h1 className="title--text font-md-16 mt-md-0 font-weight-500">
								DOWNLOAD PLANS
							</h1>
							<h4 className="font-weight-500 download-plan--text font-weight-bold letter-space mt-2 mt-md-4 pt-2">
								View and download your <br /> training plans below.
							</h4>

							{requestKey ? (
								<p className='message-text' style={{ color: '#ff0011' }}>Download plan feature not available in-app. Please use your browser.</p>
							) : undefined}
						</div>
						<div className="mt-md-4 pt-3 pt-md-5 mx-xl-5 px-xl-5">
							<Row className="g-4">
								{isLoading && (
									<>
										<Col md={4} className='text-center'>
											<Placeholder className='text-center' as="span" animation="glow">
												<Placeholder style={{ borderRadius: 15, width: '45%', height: 20, marginBottom: 10, float: 'left' }} xs={12} />
												<Placeholder style={{ borderRadius: 15, width: '70%', height: 20, marginBottom: 10, float: 'left' }} xs={12} />
												<Placeholder style={{ borderRadius: 15, width: '70%', height: 20, marginBottom: 10, float: 'left' }} xs={12} />
												<Placeholder style={{ borderRadius: 15, width: '80%', height: 20, marginBottom: 10, float: 'left' }} xs={12} />
												<Placeholder style={{ borderRadius: 15, width: '90%', height: 20, marginBottom: 10, float: 'left' }} xs={12} />

												<Placeholder style={{ borderRadius: 50, width: '60%', height: 50, float: 'left' }} xs={12} />

												<Placeholder style={{ borderRadius: 50, width: '20%', height: 50, float: 'left', marginLeft: '10px' }} xs={12} />

											</Placeholder>
										</Col>

										<Col md={4} className='text-center'>
											<Placeholder className='text-center' as="span" animation="glow">
												<Placeholder style={{ borderRadius: 15, width: '45%', height: 20, marginBottom: 10, float: 'left' }} xs={12} />
												<Placeholder style={{ borderRadius: 15, width: '70%', height: 20, marginBottom: 10, float: 'left' }} xs={12} />
												<Placeholder style={{ borderRadius: 15, width: '70%', height: 20, marginBottom: 10, float: 'left' }} xs={12} />
												<Placeholder style={{ borderRadius: 15, width: '80%', height: 20, marginBottom: 10, float: 'left' }} xs={12} />
												<Placeholder style={{ borderRadius: 15, width: '90%', height: 20, marginBottom: 10, float: 'left' }} xs={12} />

												<Placeholder style={{ borderRadius: 50, width: '60%', height: 50, float: 'left' }} xs={12} />

												<Placeholder style={{ borderRadius: 50, width: '20%', height: 50, float: 'left', marginLeft: '10px' }} xs={12} />

											</Placeholder>
										</Col>

										<Col md={4} className='text-center'>
											<Placeholder className='text-center' as="span" animation="glow">
												<Placeholder style={{ borderRadius: 15, width: '45%', height: 20, marginBottom: 10, float: 'left' }} xs={12} />
												<Placeholder style={{ borderRadius: 15, width: '70%', height: 20, marginBottom: 10, float: 'left' }} xs={12} />
												<Placeholder style={{ borderRadius: 15, width: '70%', height: 20, marginBottom: 10, float: 'left' }} xs={12} />
												<Placeholder style={{ borderRadius: 15, width: '80%', height: 20, marginBottom: 10, float: 'left' }} xs={12} />
												<Placeholder style={{ borderRadius: 15, width: '90%', height: 20, marginBottom: 10, float: 'left' }} xs={12} />

												<Placeholder style={{ borderRadius: 50, width: '60%', height: 50, float: 'left' }} xs={12} />

												<Placeholder style={{ borderRadius: 50, width: '20%', height: 50, float: 'left', marginLeft: '10px' }} xs={12} />

											</Placeholder>
										</Col>
									</>
								)}

								{
									!isLoading && usersPdfs ? (
										usersPdfs?.map((pdf, idx) => (
											<Col key={idx} sm={12} md={6} lg={4} >
												<div className="bg-white radius-15 p-4 p-md-5">
													<ul className="list-unstyled m-0">
														<li><b>{pdf.race_name}</b></li>
														<li>
															Finish Time:<span> {pdf.finish_time}</span>
														</li>
														<li>
															Goal Time: <span> {pdf.goal_time}</span>
														</li>
														<li>
															Race Date: <span>{new Date(pdf.race_date.split('T')[0]).toLocaleDateString()} </span>
														</li>
														<li>
															Race Distance:<span> {pdf.race_distance}</span>
														</li>
													</ul>
													<div className="download-btn d-flex align-items-center mt-3 flex-row flex-md-column flex-lg-row">
														<Button
															disabled={requestKey ? true : false}
															onClick={() => handleDownload(pdf.user_id, pdf.id)}
															size="lg"
															variant="dark"
															className="rounded-pill color-primary-bg next-btn mt-2 text-uppercase"
														>
															DOWNLOAD PLAN
														</Button>
														{/* <div className="download-icon ms-4 pointer mt-3 mt-lg-0">
															<img
																src="./images/icons/download-plan.svg"
																alt="Download Plan"
															/>
														</div> */}
													</div>
												</div>
											</Col>
										))
									) : null
								}
								{
									!isLoading && usersPdfs?.length == 0 ? (
										<Col md={12} className='text-center'>
											<div className="bg-white radius-15 p-4 p-md-5">
												<ul className="list-unstyled m-0">
													<li>
														There are no plans available for download.
													</li>
												</ul>
												<div className="download-btn d-flex align-items-center mt-3 flex-row flex-md-column flex-lg-row">
													<Button
														onClick={() => navigate('/training-plan/build/step-1', { replace: true })}
														size="lg"
														variant="dark"
														className="rounded-pill mx-auto color-primary-bg next-btn mt-2 text-uppercase"
													>
														CREATE A PLAN
													</Button>
												</div>
											</div>
										</Col>
									) : null
								}
							</Row>
						</div>
					</Container>

					<PdfViewer
						isPDFExist={isPDFExist}
						showPdf={showPdf}
						handleClose={handleCloseModal}
						userInfo={userPDFData?.userInfo}
						trainingData={userPDFData?.trainingData}
						pdfData={userPDFData?.pdfData}
						currentUser={userPDFData?.urrentUser}
						userRestComments={userPDFData?.userRestComments}
						vdotAdjustments={userPDFData?.vdotAdjustments}
						customPaces={userPDFData?.customPaces}
						setHideMainModal={setHideMainModal}
					/>
				</div>
			</BodyComponent>
		</AppMenus>
	)
}

export default WithAuth(DownloadPlan)
