import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Modal from 'react-bootstrap/Modal'
// import Button from 'react-bootstrap/Button'
import { FC } from 'react'
import AccountSubmitButtons from '../buttons/AuthSubmitButtons'

// Props
export type MessageCardProps = {
	title: string
	text: string
	isShown: boolean
	buttonText?: string
	setIsShown: (x: boolean) => any
	onConfirm: () => any
	resetModalData: () => any
	disableButton?: boolean
	secondaryButtonText?: string,
	secondaryAction?: () => any
}

/**
 * MessageCard Component
 */

const MessageCard: FC<MessageCardProps> = ({
	title,
	text,
	buttonText,
	isShown = false,
	setIsShown,
	onConfirm,
	resetModalData,
	disableButton,
	secondaryButtonText,
	secondaryAction
}) => {
	// Properties
	const buttonMessage = buttonText ? buttonText : 'Confirm Change'

	// On Close
	const onClose = () => {
		resetModalData()
		setIsShown(false)
	}

	// On Submit
	const onSubmit = async () => {
		await onConfirm()
		// onClose()
	}

	// Render
	return (
		<Modal className="confirm-change-plan" centered show={isShown} onHide={onClose}>
			<Modal.Header closeButton>
				{/* Title */}
				<Row className="my-3 text-center">
					<Col>
						<h3 className="position-relative">{title}</h3>
					</Col>
				</Row>
			</Modal.Header>
			<Modal.Body>
				{/* Content */}
				<Row className="mb-4 text-center">
					<Col>
						<p>{text}</p>
					</Col>
				</Row>

				{/* Ok Button */}
				<Row className="mb-0 text-center">
					<Col>
						{/* <Button
							className="rounded-pill"
							variant="dark"
							onClick={onSubmit}
							style={{ width: '10rem' }}
						>
							{buttonMessage}
						</Button> */}
						{!disableButton ? (
							<>
								{secondaryButtonText ? (
									<div style={{marginBottom: '10px'}}>
									<AccountSubmitButtons
										primaryText={secondaryButtonText}
										primaryDisabled={disableButton}
										primaryAction={secondaryAction as any}
										isAccount
									/>
									</div>
								) : undefined}
								<div className="mt-n4">
									<AccountSubmitButtons
										primaryText={buttonMessage}
										primaryDisabled={disableButton}
										primaryAction={onSubmit}
										// secondaryText={'Cancel'}
										// secondaryAction={onClose}
										isAccount
									/>
								</div>
							</>
						) : undefined}

					</Col>
				</Row>


			</Modal.Body>
		</Modal >
	)
}

export default MessageCard
