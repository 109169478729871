import allVideos, { Video } from '../modules/videos/allVideos'
import { DEFAULT_PROGRESS } from './useProgramPage'
import { getProgressData } from './useVideoProgress'
import { AuthPageProps } from '../modules/types'
import {
	foamRolling,
	stretching,
	strengthExercises,
	formDrills,
	runSmart101,
	allExercises,
} from '../modules/videos/videoOrders'
import { useState, useEffect } from 'react'

// Exercise Types
export const exerciseTypes = {
	'Foam Rolling': foamRolling,
	Stretching: stretching,
	Strength: strengthExercises,
	'Run Form Drills': formDrills,
	'RunSmart 101': runSmart101,
	'All Exercises': allExercises,
}

/**
 * useExerciseType Hook
 */

const useExerciseType = ({
	exerciseType,
	videoId,
	userInfo,
}: {
	exerciseType: keyof typeof exerciseTypes
	videoId?: number | string
	userInfo?: AuthPageProps['userInfo']
}) => {
	// Get Info
	const getInfo = () => {
		let currentVideo: Video | undefined = undefined
		const videos: Video[] = []
		const order = exerciseTypes[exerciseType]

		// Filter Videos
		for (const id of order) {
			const videoInfo = allVideos[String(id)]
			if (videoInfo) {
				videos.push(videoInfo)
				if (videoInfo.id == String(videoId)) {
					currentVideo = videoInfo
				}
			}
		}

		if (!currentVideo) currentVideo = videos[0]

		// Return
		return { videos, currentVideo, progressInfo: DEFAULT_PROGRESS }
	}

	// State
	const [info, setInfo] = useState(getInfo())
	const [data, setData] = useState(undefined as any)

	// Get Progress Info
	const getProgressInfo = async (newInfo: ReturnType<typeof getInfo>) => {
		const { newData: progressData, newInfo: progressInfo } =
			await getProgressData([newInfo.currentVideo], userInfo, data)
		setData(progressData)
		setInfo({
			...newInfo,
			progressInfo: progressInfo?.[0] || { ...DEFAULT_PROGRESS },
		})
	}

	// Change Effect
	useEffect(() => {
		const newInfo = getInfo()
		getProgressInfo(newInfo)
	}, [userInfo, exerciseType, videoId])

	return info
}

export default useExerciseType
