import MainCard from '../../cards/MainCard'
import { HomeDetailCardProps } from '../../cards/HomeDetailCard'
import ScrollRow from '../../layout/ScrollRow'
import useScrollRow from '../../../hooks/useScrollRow'
import { FC } from 'react'
import useStore from '../../../store/useStore'
import { ITrainingStore } from '../../../store/createTrainingStore'

// Card Type
export type Card = Partial<HomeDetailCardProps> & {
	title?: string
	text: string
	altText?: string
	bgImage: string
	logoType?: string
	logoImage?: string
	link?: string
	alt?: string,
	hasInfo?: boolean
	otherInfo?: string
	directLink?: string
	id?: number
	isLoading?: boolean,
	logo?: string,
 	typeIndex?: number | undefined
	planType?: string
}

// Card Row Props
export type CardRowProps = {
	cards: Card[]
}

/**
 * InfoRow Component
 */

const InfoRow: FC<CardRowProps> = ({ cards }) => {
	const previousActivityStatus = localStorage.getItem('previousActivityStatus') || undefined

	const { isScrolling, setIsScrolling } = useScrollRow()
	const { currentRecover } = useStore((state: ITrainingStore) => state)
	return (
		<ScrollRow isHome breakMobile setIsScrolling={setIsScrolling}>
			{cards.length == 0 ? (
				<>
					{[1, 2].map((value) => (
						<div key={value} className="card bg-transparent border-0 mt-n3 mx-n3 mr-3" aria-hidden="true">
							<div className="card-body">
								<h5 className="card-title placeholder-glow">
									<span className="placeholder placeholder-home-training"></span>
								</h5>
							</div>
						</div>
					))}
				</>
			) : (
				<>
					{cards.map((card, index) => (
						<MainCard
							key={index}
							text={card.text}
							title={card.title}
							bgImage={card.bgImage}
							logoType={card.logoType as any}
							showProgressBar={card.showProgressBar}
							showDaysUntilRace={card.showDaysUntilRace}
							progressPercent={card.progressPercent}
							daysUntilRace={card.daysUntilRace}
							trainingType={card.trainingType}
							scheduleText={card.scheduleText}
							isScrolling={isScrolling}
							link={card.logoType == "recover" && (currentRecover || previousActivityStatus) ? card.directLink : card.id ? card.link + '?planId=' + card.id : card.link}
							hasInfo={card.hasInfo}
							otherInfo={card.otherInfo}
						/>
					))}
				</>)}
		</ScrollRow>
	)
}

export default InfoRow
