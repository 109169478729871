import { FC, useState, useEffect } from 'react'

import Grid from '@mui/material/Grid'
import Box from '@mui/material/Box'

import RSButton from '../../components/buttons/RSButton'
import { AccountProfile } from '../../api/v2/account/Account.types'

export type MeasurementSystemProps = {
	data?: AccountProfile | undefined,
	handleUpdateProfile: any,
}

const MeasurementSystem: FC<MeasurementSystemProps> = ({ data = {}, handleUpdateProfile }) => {
	const { measurement_system } = data
	const [metric, setMetric] = useState<any>(measurement_system || '');

	const metricList = [{
		title: 'Imperial',
		desc: 'mi/lbs'
	}, {
		title: 'Metric',
		desc: 'km/kg'
	}]

	const handleSave = async () => {
		handleUpdateProfile?.({ measurement_system: metric }, 'CHANGE_UNITS')
	}

	useEffect(() => {
		setMetric(measurement_system)
	}, [measurement_system])

	return (
		<Box>
			<Box sx={{ fontSize: '16px', fontFamily: 'Poppins-Medium', pb: 1 }}>Measurement System</Box>
			<Grid container xs={12} justifyContent='space-between'>
				{metricList.map((activity: any, ai: number) => {
					const val = activity.title.toLowerCase()
					const isActive = metric === val
					return <Grid item container xs={5.6} key={'activity-' + ai} sx={{ minHeight: '60px', width: '100px' }} justifyContent='center' alignItems='center'>
						<RSButton card secondary={!isActive} sx={{ height: '100%', flexDirection: 'column' }} onClick={() => setMetric(val)}>
							<Box sx={{ fontFamily: 'Poppins-Medium !important', fontSize: '14px !important', textAlign: 'center', lineHeight: '1em', pb: '5px' }}>{activity.title}</Box>
							{activity.desc && <Box sx={{ fontFamily: 'Poppins-light !important', fontSize: '10px !important', lineHeight: '1.2em', textAlign: 'center' }}>{activity.desc}</Box>}
						</RSButton>
					</Grid>
				})}
			</Grid>
			<RSButton onClick={handleSave} disabled={!metric || metric === measurement_system} sx={{ mt: 3 }}>Save</RSButton>
		</Box>
	)
}

export default MeasurementSystem;
