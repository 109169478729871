// Program Covers
export const programCovers = {
	Base3: '/img-new/homepage/cover_images/no_logo/Base3_cover_image.jpg',
	Base6: '/img-new/homepage/cover_images/no_logo/base6_cover_image.jpg',
	Mechanix: '/img-new/homepage/cover_images/no_logo/mechanix_cover_image.jpg',
	Prime: '/img-new/homepage/cover_images/no_logo/prime_cover_image.jpg',
	Yoga: '/img-new/homepage/cover_images/no_logo/Yoga_cover_image.jpg',
	Midfoot: '/img-new/homepage/cover_images/no_logo/midfoot_cover_image.jpg',
	Recover: ''
}

// Program Features
export const programFeatures = {
	Base3:
		'/img-new/programs/All Programs Page/featured_images/desktop/Base3_featured-image_desktop.jpg',
	Base6:
		'/img-new/programs/All Programs Page/featured_images/desktop/Base6_featured-image_desktop.jpg',
	Mechanix:
		'/img-new/programs/All Programs Page/featured_images/desktop/Mechanix_featured-image_desktop.jpg',
	Prime:
		'/img-new/programs/All Programs Page/featured_images/desktop/Prime_featured-image_desktop.jpg',
	Yoga: '/img-new/programs/All Programs Page/featured_images/desktop/Yoga_featured-image_desktop.jpg',
	Midfoot: '',
	Recover: ''
}

// Program Logos
export const programLogos = {
	Base3: '/img-new/programs/All Programs Page/logos/Base3_logo_green-black.png',
	Base6: '/img-new/programs/All Programs Page/logos/Base6_logo_green-black.png',
	Mechanix: '/img-new/programs/All Programs Page/logos/Mechanix_logo_black.png',
	Prime:
		'/img-new/programs/All Programs Page/logos/runsmart-prime_logo-black.png',
	Yoga: '/img-new/programs/All Programs Page/logos/Yoga_logo_black.png',
	Midfoot: '',
	Recover: ''

}

// Alt Program Logos
export const altProgramLogos = {
	Base3: '/img-new/homepage/Logos/Base3_Logo_green_reverse.png',
	Base6: '/img-new/homepage/Logos/Base6_Logo_green_reverse.png',
	Mechanix: '/img-new/homepage/Logos/Mechanix_logo_reverse.png',
	Prime: '/img-new/homepage/Logos/runsmart_prime_logo_reverse.png',
	Yoga: '/img-new/homepage/Logos/yoga-logo-DARK BACKGROUND.png',
	Midfoot: '',
	Recover: ''
}
