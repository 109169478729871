import { FC, useEffect } from "react";
import { IComponentStore } from "../../store/createComponentStore";
import useStore from "../../store/useStore";

type SessionInactiveProps = {
	refresh: boolean
}

const SessionInactive: FC<SessionInactiveProps> = ({refresh}) => {

    const { setIsLoading } = useStore((state: IComponentStore) => state)

    useEffect(() => {
        setIsLoading(true)
    }, [] )
    

    return <>
        {!refresh ? <div style={{width: '100%', height: '100vh', padding: '10px', display: 'flex', flexDirection: 'column',alignContent: 'center', alignItems:'center', justifyContent:'center', textAlign: 'center'}}>
            <h1>User has been away <br/> for a while</h1>
            <h4>For security purposes, Kindly login again.</h4>
        </div> : <h1>Session Inactive</h1>}
    </>
}


export default SessionInactive;