import React, { useEffect } from 'react'
import { Container } from 'react-bootstrap'
import AppMenus from '../../components/layout/AppMenus'
import InstabotHide from '../../components/styles/InstabotHide'
import WithAuth from '../../components/WithAuth'
import RSButton from '../../components/buttons/RSButton'
import useQueryParams from '../../hooks/useQueryParams'
import { navReq } from '../../modules/apiConsume'

const StartSurvey = () => {
    const { type, offer } = useQueryParams()

    useEffect(() => {
        if (type)
            localStorage.setItem('surveyType', type)
        else
            localStorage.setItem('surveyType', 'new_survey')

    }, [type])

    return (
        <div>
            {/* Styles */}
            <InstabotHide />
            {/* Page */}
            <AppMenus hideOptions hideBackButton>
                <Container fluid="md" className={'survey-form-container bg-main'}>
                    {type === "profile_update" ? (
                        <div className="content center-content p-3">
                            <h1 className='headline'>Update Your Profile</h1>
                            <p className='subhead'>Start the survey to reflect your current training and running. <br />Your previous answers will be highlighted.</p>
                            <RSButton sx={{my: 1}}  onClick={() => navReq('/#/onboarding-survey/questions?seq=1')}>Start Survey</RSButton>
                            <RSButton sx={{my: 1}} secondary onClick={() => navReq('/#/')}>Go Back</RSButton>
                        </div>
                    ) : (
                        <div className="content center-content">
                            <h1 className='headline'>Let’s get you up <br />and running.</h1>
                            <p className='subhead'>To customize your training, <br /> complete this short survey.</p>
                            <RSButton onClick={() => navReq(`/#/onboarding-survey/questions?seq=1${offer ? '&offer=' + offer : ''}`)}>Get Started </RSButton>
							<RSButton secondary onClick={() => navReq(`/#/choose-plan?skipSurvey=1${offer ? '&offer=' + offer : ''}`)}>Skip</RSButton>
                        </div>
                    )}

                </Container>
            </AppMenus>
        </div>
    )
}

export default WithAuth(StartSurvey, true)
