import { useEffect, useState } from 'react'

const useDeviceDetect = () => {
    const ISMOBILE = localStorage.getItem('isMobile') === "true" ? true : false

    const [isMobile, setMobile] = useState(ISMOBILE);
    const [mobileType, setMobileType] = useState("Windows")

    const userAgent = typeof window.navigator === "undefined" ? "" : navigator.userAgent;

    useEffect(() => {
        const mobile = Boolean(
            userAgent.match(
                /Android|BlackBerry|iPhone|iPad|iPod|Opera Mini|IEMobile|WPDesktop/i
            )
        );

        localStorage.setItem('isMobile', String(mobile))
        setMobile(ISMOBILE ? true : mobile);

        if (userAgent.includes('Android'))
            setMobileType('Android')
        else if (userAgent.includes('iPhone') || userAgent.includes('iPad') || userAgent.includes('iPod'))
            setMobileType('Ios')
        else
            setMobileType('Windows')
    }, []);

    return { isMobile, mobileType };
}

export default useDeviceDetect