import { FC, useState, useRef } from 'react'
import Slider from 'react-slick'

import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import RSTheme from '../styles/RSTheme'
import RSPopUpModal from '../layout/RSPopUpModal'
import RSButton from '../buttons/RSButton'
import RSIcon from '../icons/RSIcon'

export type RSRSAnnouncementProps = {
	showAnnouncementUpdates: boolean,
	setShowAnnouncementUpdates: any
}


const RSAnnouncement: FC<RSRSAnnouncementProps> = ({showAnnouncementUpdates, setShowAnnouncementUpdates}) => {


	const data = [{
		title: 'Share Feature',
		description: 'Share the app with your friends and family and get points for every referral',
		image: <Box sx={{height: '150px', width: '150px', backgroundColor: '#9fcd2ba6', borderRadius: '50%', position: 'relative'}}>
			<RSIcon type='logo' height='220px' width='220px' sx={{fill: 'white', position: 'absolute', top: 'calc(50% + 8px)', left: 'calc(50% - 15px)', transform: 'translate(-50%,-50%)'}}/>
		</Box>
	}, {
		title: 'Share Feature',
		description: 'Share the app with your friends and family and get points for every referral',
		image: <Box sx={{height: '150px', width: '150px', backgroundColor: '#9fcd2ba6', borderRadius: '50%', position: 'relative'}}>
			<RSIcon type='logo' height='220px' width='220px' sx={{fill: 'white', position: 'absolute', top: 'calc(50% + 8px)', left: 'calc(50% - 15px)', transform: 'translate(-50%,-50%)'}}/>
		</Box>
	}, {
		title: 'Share Feature',
		description: 'Share the app with your friends and family and get points for every referral',
		image: <Box sx={{height: '150px', width: '150px', backgroundColor: '#9fcd2ba6', borderRadius: '50%', position: 'relative'}}>
			<RSIcon type='logo' height='220px' width='220px' sx={{fill: 'white', position: 'absolute', top: 'calc(50% + 8px)', left: 'calc(50% - 15px)', transform: 'translate(-50%,-50%)'}}/>
		</Box>
	}]

	const [slideIndex, setSlideIndex] = useState(0);
	const sliderRef = useRef<any>();

	const lastSlide = slideIndex === data.length - 1

	const settings = {
		dots: data?.length > 1 && !lastSlide ? true : false,
		infinite: false,
		speed: 500,
		slidesToShow: 1,
		slidesToScroll: 1,
		arrows: false,
		beforeChange: (current: any, next: any) => setSlideIndex(next),
		initialSlide: 0
	};

	const handleClose = () => {
		setShowAnnouncementUpdates(false)
	}

	return <RSTheme>
		<RSPopUpModal popUpHeight='90%' popUpWidth='90%' popUpMaxHeight='500px' popUpMaxWidth='300px' open={showAnnouncementUpdates} onClose={handleClose}>
			<Box className='announcement-body' sx={{height: '100%', width: '100%'}}>
				<Grid container xs={12} sx={{height: '100%', position: 'relative', py: 2}}>
					<RSButton onClick={handleClose} tertiary sx={{zIndex: 1, position: 'absolute', top: 0, right: 0, width: '65px', height: '50px'}}>
						Skip
					</RSButton>

					<Slider {...settings} ref={sliderRef}>
						{data.map((d: any, di: number) => {
							return <Grid container xs={12} key={`announcement-slider-${di}`} sx={{pt: '50px'}}>
								<Grid container xs={12} sx={{height: 'auto'}} justifyContent='center'>
									{d.image}
								</Grid>
								<Grid container xs={12} sx={{fontFamily: 'Poppins-Bold', fontSize: '20px', pt: '50px'}} justifyContent='center'>
									{d.title}
								</Grid>
								<Grid container xs={12} sx={{height: '100px', fontFamily: 'Poppins', fontSize: '14px', pt: 1, px: 3, lineHeight: '1.2em'}} justifyContent='center'>
									{d.description}
								</Grid>
							</Grid>
						})}
					</Slider>
					<Grid  container xs={12} sx={{height: '82px', zIndex: 1, bottom: 0, left: 0, position: 'absolute', backgroundColor: 'off-white', p: 2}}>
						<RSButton onClick={()=>sliderRef?.current?.slickPrev?.()} secondary customized sx={{display: slideIndex ? 'flex' : 'none', position: 'absolute', left: 16, height: '50px', width: '50px', borderRadius: '25px', minWidth: 0 }}>
							<i className="fa-regular fa-chevron-left" />
						</RSButton>
						<RSButton onClick={()=>lastSlide ? handleClose() : sliderRef?.current?.slickNext?.()} customized sx={{position: 'absolute', right: 16, height: '50px', width: 'auto', borderRadius: '25px', minWidth: 0}}>
							<Box className='transition-all-quarter ellipsis-1l' sx={{width: lastSlide ? '100px' : '50px', overflow: 'hidden', height: '28px'}}>
								{lastSlide ? `Let's go` : <i className="fa-regular fa-chevron-right" />}
							</Box>
						</RSButton>
					</Grid>
				</Grid>
			</Box>
		</RSPopUpModal>
	</RSTheme>
}

export default RSAnnouncement;
