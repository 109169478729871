/* eslint-disable @typescript-eslint/no-unused-vars */
import moment from 'moment';

export interface AIUserDetails {
  planWeeks: number
  dob: any
  gender: string
  currentWeeklyMileage:
  number
  unit: string
  lastLongestRun: number
  maxRunPerWeek: number
  buildNumber?: number
  raceType?: string
  runDaysPerWeek?: number
}

export interface Stage2Details {
  preferredLongRun: string
  availableRunDays: Array<string>
  runDaysPerWeek: number
  raceType?: string
  dob?: any
  gender?: string
  stepResults?: any
  initLongRuns?: any
  currentStep?: number
  planWeeks?: number
  crossTraining?: Array<string>
  level?: any
  latestRevision?: number
}

const ChatCompletionPromptsSingle = () => {

  // CHECKS USER IF READY FOR TRAINING FOR THE RACE
  // PROVIDES EXPLANATION IF NOT READY

  const getUnselectedDays = (selectedDaysArray: any) => {
    console.log('selectedDaysArray', selectedDaysArray)
    const allDays = ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"];
    const unselectedDays = allDays.filter(day => !selectedDaysArray.includes(day));
    return unselectedDays
  }

  const buildUserRecommendation = (data?: AIUserDetails) => {
    const { planWeeks, dob, gender, currentWeeklyMileage, unit, lastLongestRun, runDaysPerWeek, raceType } = data || {}
    const age = dob ? ((moment().diff(moment(dob), 'years')) + '-year-old') : ''

    let race = raceType
    if (raceType?.toLowerCase().includes('half') || raceType?.toLowerCase().includes('full')) race += ' marathon'


    return data ?
      `You are an expert running coach. Based on the following inputs, determine if I have adequate training to enter a training plan for the upcoming race.
      For your suggestion, take into account that I am a ${age} ${gender}.

      Guidelines:
      If the runner has adequate training, but the race is soon, allow the runner to enter the training plan.

      Inputs:
      - Length of plan: ${planWeeks} weeks
      - Plan type: ${race}
      - Average weekly mileage over the last 4 weeks: ${currentWeeklyMileage} ${unit || 'miles'}
      - Longest run completed in the last 2 weeks: ${lastLongestRun} ${unit || 'miles'}
      - Number of running days per week: ${runDaysPerWeek}
      
      Results:
      - Tell me if I'm ready to enter a Training Plan: Yes/No
      - Short Explanation only if No: [Short Explanation (35-50 words) of why I may not be ready to enter a training plan for the race]
      Output:
      - Provide the output in the following JSON format
      - Example output: { "ready": "no", "reason": "You do not have a strong base yet and only have a few days to train. Therefore, most like you are not ready to enter a training plan. It is not recommended because etc..." }` : ''

  }

  const buildUserDetails = (data?: AIUserDetails) => {
    const { planWeeks, dob, gender, currentWeeklyMileage, unit, lastLongestRun, maxRunPerWeek, raceType } = data || {}

    const age = dob ? moment().diff(moment(dob), 'years') : 36
    return data ? `Build a  ${planWeeks}-week training plan/routine to prepare for a ${raceType} marathon race while considering the specifics I mention about my current training and goals. Only provide weekly totals and no details about the weeks.

Details:
- Age: ${age}
- Gender: ${gender}
- Current weekly mileage:  ${currentWeeklyMileage} ${unit}
- Longest run in the last two weeks: ${lastLongestRun} ${unit}
- Goal: Emphasize performance while preventing injuries
- Maximum weekly mileage: ${maxRunPerWeek} ${unit}.

Rules:
- Start the plan at ${currentWeeklyMileage} ${unit} per week.
- DO NOT exceed ${maxRunPerWeek} ${unit} for any weekly mileage of the plan.
- Do not exceed to the specified length of the training plan.
- Progress runs following 10% - 15% rule to avoid overtraining and injury.
- Periodize the weekly running plan to peak weekly mileage at weeks ${planWeeks ? planWeeks - 3 : 0} and ${planWeeks ? planWeeks - 2 : 0}
- Periodize the long runs to peak at weeks ${planWeeks ? planWeeks - 3 : 0} and ${planWeeks ? planWeeks - 2 : 0}` : ''
  }

  // const outputJsonFormat = {
  //   plan: [{
  //     Week: 1,
  //     Long_run: 0,
  //     Weekly_mileage: 0,
  //     Taper: false,
  //     Recovery: false,
  //   }]
  // }

  // const outputJsonFormat = {
  //   plan: [{
  //     w: 1,
  //     lr: 0,
  //     m: 0,
  //     t: 0,
  //     r: 0,
  //   }]
  // }

  const outputJsonFormat = {
    plan: [{
      w: [1, 2, 3, 4, 5, 6, 7],
      m: [0, 0, 0, 0, 0, 0, 0],
      l: [0, 0, 0, 0, 0, 0, 0],
      t: [0, 0, 0, 0, 0, 0, 0],
      r: [0, 0, 0, 0, 0, 0, 0],
    }]
  }

  const defaultSytemPrompt = `
    You are a running coach who helps runners train for marathons. Utilize the step-by-step process below to create running plans based on the specifics provided. Build a table that reflects each week of the training program.

    Take the information provided about the runner and understand their running history and persona to create a plan that emphasizes performance but also injury prevention.

    Build Out a Weekly Mileage Plan According to Runner Specifications

    - output legend: w = weeks , m = weekly mileage, l = long run, t = taper (0 - false, 1-true), r = recovery (0 - false, 1-true)
    - Return results in json format. Sample JSON format to follow is ${JSON.stringify(outputJsonFormat)}.
    `

  const defaultSytemPrompt2 = `
    You are a running coach who helps runners train for half marathons.Utilize the step - by - step process below to create running plans based on the specifics provided.Build a table that reflects each day of the training program.

    Build Out a daily running Plan According to Runner Specifications

        - Return results in json format.Sample JSON format to follow is plan: [{
            week: 1, weekly_mileage: 20, is_taper: false, is_recovery: false}]`

  const inputPrompts = [
    `
    Update the existing plan by adding a long row column to determine the best long run distance for each week. Only use whole numbers for long runs.

    The table should never increase its number of rows.
    Start by identifying the weeks that have a drop in overall total running distance. These weeks are recovery weeks long runs” should not progress to longer distances during recovery weeks.

    When building out the long run column:
    Rule 1:
    Start long runs at 6 miles per week.

    Rule 2:
    “Long runs” should progress steadily throughout the plan. The values should start short and get longer throughout the plan (except for “recovery weeks” and “taper weeks”)

    Rule 3:
    The “Long run” length should not exceed 30% of the total weekly distance unless the result is less than 10 miles.
    For example, if a runner is running 40 miles in a week, their long run should not exceed 12 miles.
    For example, if a runner is running 25 miles in a week, their long run should not exceed 7.5 miles.

    Rule 4:
    “Long runs” should not progress and get longer in the final two weeks of the training plan
    `
  ]

  // "plan": [
  //     {
  //       "week": 1,
  //       "weekly_mileage": 0,
  //       "sunday": 0
  //       "monday": 0,
  //       "tuesday": 0,
  //       "wednesday": 0,
  //       "thursday": 0,
  //       "friday": 0,
  //       "saturday": 0,
  //     }
  //   ]

  // { 
  //   "plan": [ 
  //     {
  //       "week": 1,
  //       "weekly_mileage": 0,
  //       "long_run": 0,
  //       "days": [
  //         {
  //           "day_name": "sunday",
  //           "run_distance": 0,
  //           "run_type": "Base"
  //         },
  //         {
  //           "day_name": "monday",
  //           "run_distance": 0,
  //           "run_type": "Base"
  //         },
  //         {
  //           "day_name": "tuesday",
  //           "run_distance": 0,
  //           "run_type": "Base"
  //         },
  //         {
  //           "day_name": "wednesday",
  //           "run_distance": 0,
  //           "run_type": "Base"
  //         },
  //         {
  //           "day_name": "thursday",
  //           "run_distance": 0,
  //           "run_type": "Base"
  //         },
  //         {
  //           "day_name": "friday",
  //           "run_distance": 0,
  //           "run_type": "Base"
  //         },
  //         {
  //           "day_name": "saturday",
  //           "run_distance": 0,
  //           "run_type": "Base"
  //         }
  //       ]
  //     }
  //   ]
  // }

  // 1. Runner can run ${runDaysPerWeek} days per week.
  // 2. The runner can run on the following days: ${[availableRunDays, preferredLongRun].join(', ')}
  // 3. The sum of all runs for each week should equal the "weekly_mileage" from the original plan.
  // 4. No individual run should be less than 3 miles.
  // 5. Minimize consecutive days of running when able.
  // 6. Long runs must remain on ${preferredLongRun} and should not be altered.

  //   Steps to build the running plan:
  // 1. Place all long run days from the original plan on ${preferredLongRun} without changes.
  // 2. Divide the remaining weekly mileage across the ${(runDaysPerWeek || 3) - 1} other running days. Use these days as options: ${[availableRunDays, preferredLongRun].join(', ')}
  // 3. Ensure that the total mileage for each week matches the "weekly_mileage" specified in the original plan.
  // 4. Ensure that the runner is scheduled to run only ${runDaysPerWeek} days per week and no run is less than 3 miles.

  // Before output, ensure:
  // 1. Long runs are placed on ${preferredLongRun} and remain unchanged.
  // 2. All 1 or 2 mile runs are removed
  // 3. The runner is scheduled to run only ${runDaysPerWeek} days per week.
  // 4. The total mileage each week matches the weekly mileage from the original plan.

  //   Instructions:
  // You are a running coach building a ${raceType}-marathon plan for a ${age}-year-old ${gender}. 
  // Start by evaluating the weekly plan provided, noting of the weekly mileage, long runs, recovery weeks, and taper weeks.

  // Your job is to convert the weekly plan into a daily running plan without any 1 or 2 mile runs.

  // Guidelines:
  // 1. Runner can run ${runDaysPerWeek} days per week with long run on ${preferredLongRun}.
  // 2. The runner can run on the following days: ${[availableRunDays, preferredLongRun].join(', ')}
  // 3. The sum of all runs for each week should equal the "weekly_mileage" from the original plan.
  // 4. Minimize consecutive days of running when able.

  // ADMIN DASHBOARD
  const buildStage2InputPrompts = (data?: Stage2Details) => {
    const { raceType, gender, dob, preferredLongRun, availableRunDays, initLongRuns, stepResults, currentStep, planWeeks, runDaysPerWeek } = data || {}
    const age = dob ? moment().diff(moment(dob), 'years') : 1
    const longRuns: string[] = []
    // console.log(availableRunDays?.length)
    if (initLongRuns.length) {
      initLongRuns.map((e: any) => {
        longRuns.push(`w ${e.w} ${e.lr}`)
      })
    }

    return data ? [`Weekly Plan: \n${stepResults.length ? JSON.stringify(stepResults.find((x: any) => x?.step === currentStep)?.data) : undefined}\n
As a running coach, create a ${raceType}-marathon plan for a ${age}-year-old ${gender}.

Convert the weekly plan into a daily schedule using these strict, non-negotiable rules:
1. The plan must include exactly ${runDaysPerWeek} running days selected from the following options:  ${[availableRunDays, preferredLongRun].join(', ')}. No exceptions.
2. Weekly mileage must match the original plan precisely.
3. Long run distance on ${preferredLongRun} should never be changed.
4. Every single run must be 3 miles or more - no exceptions.

Process:
1. Keep  ${preferredLongRun} long runs as is.
2. Distribute remaining mileage across the other ${runDaysPerWeek ? runDaysPerWeek - 1 : 0} running days following these principles:
   a. Allocate more miles to mid-week runs, even if it results in significantly longer individual runs.
   b. Make runs as long as necessary to ensure no run is less than 3 miles and to maintain exactly ${runDaysPerWeek} running days.
   c. Space out runs across the week to avoid consecutive running days when possible.
3. Adjust distances (except ${preferredLongRun}) to ensure weekly totals match the original plan.

Final check:
-  ${preferredLongRun}s are for long runs and remain unchanged.
- The plan has no more than ${runDaysPerWeek} running days per week selected from the specified options. Update if needed.
- Every single run is 3 miles or more - double-check this.
- Weekly mileage matches the original plan exactly.
- Mileage distribution follows a logical progression through the week.
- Check to ensure there's a MAX of ${runDaysPerWeek} runs per week. Update if needed
${availableRunDays?.length && getUnselectedDays([...availableRunDays, preferredLongRun]).length > 0 ? `- Check to ensure no runs are scheduled for ` + getUnselectedDays([...availableRunDays, preferredLongRun]).join(', ') : ''}.

Important: Do not add a ${runDaysPerWeek ? runDaysPerWeek + 1 : 6}th running day under any circumstances. If necessary, significantly increase the length of existing runs to meet weekly mileage goals while maintaining exactly ${runDaysPerWeek} running days.


Output the plan:
Output Format
Provide the output in the following JSON format:
{
"plan": [{ w: 1, m: 0, 1: 0, 2: 0, 3: 0, 4: 0, 5: 0, 6: 0, 7: 0}] 
}

Output legend: w=week, m=weekly mileage, 1=sunday, 2=monday, 3=tuesday, 4=wednesday, 5=thursday, 6=friday, 7=saturday:{number of miles per day}
`     ,
    `You are a running coach building a ${raceType}-marathon plan for a ${age}-year old ${gender}. You will receive a daily running plan that includes distances on specific days, long runs, recovery, and taper weeks. Your goal is to update the plan with run types, optimizing for performance and injury prevention.

Types of runs you can assign:
- Base Runs: Focus on building aerobic endurance. They form the foundation of the plan.
- Progression Runs: Progress in pace throughout the run. They build speed without too much stress.
- Easy Runs: Great for recovery. Can be done on their own or after hard workouts or long runs. Limit to one per week.
- Tempo Runs: Improve lactate threshold and build speed. Begin within 8 weeks of race day.
- Interval Workouts: Build speed and VO2 max. Begin within 4 weeks of race day.

Guidelines for assigning run types:
1. Assign Base Runs throughout the plan
2. Assign Progression Runs. Limit one per week
3. Assign Easy Runs. Limit to two per week.
4. Begin Tempo and interval Runs at week 9. Limit two per week.
5. Ensure there is a mix of run types each week to optimize training.
6. In the final week, change all runs to “race pace” runs for tapering.
7. Long runs can be base or progression runs

Steps:
1. Assign long runs as Base Runs or Progression Runs.
2. For weeks 1-8, focus on Base Runs, Progression Runs, and Easy Runs.
3. From week 9 onward, introduce Tempo Runs and Interval Workouts as described.
4. In week ${planWeeks}, change all runs to “race pace” runs for tapering.

Final check:
- Ensure progression runs aren't assigned to every long run
- Ensure harder workouts are not on consecutive days
- If there are two harder workouts in a given week, try to assign an easy run

Output format: JSON.
Output legend: [1=sunday, 2=monday, 3=tuesday, 4=wednesday, 5=thursday, 6=friday, 7=saturday] : [0 - Rest, 1 - Base Run, 2 - Progression Run, 3 - Easy, 4 - Tempo, 5 - Interval Run, 6 - Race Pace]
  Provide the output in the following JSON format:
  {
    "plan": [{ 1: 0, 2: 0, 3: 0, 4: 0, 5: 0, 6: 0, 7: 0}] 
  }
\n

Here is your training plan.\n${stepResults.length ? JSON.stringify(stepResults.find((x: any) => x?.step === currentStep)?.data) : undefined}\n`,
    `You are a running coach tasked with adding cross-training activities to a ${raceType}-marathon plan for a ${age}-year old ${gender}. You will receive a daily running plan that includes distances and run types for specific days, including long runs, recovery, and taper weeks. Your goal is to update the plan with cross-training activities, optimizing for performance and injury prevention.

Types of cross-training you can assign:
- Strength Training: Bias towards shorter weekday runs or non-run days. Maximum of two times per week and at least 2 days before long runs or hard runs (tempo or intervals).
- Yoga: Same guidelines as strength training. If there's one strength training day, there should be only one yoga day. If there are two strength training days, there will be 0 yoga days. If there are two yoga days, there will be 0 strength training days.
- Stretching: Short 10-15 minute sessions, ideal for the same day or the day after longer runs or workouts.

Rules for assigning cross-training activities:
1. Assign Strength Training and Yoga to shorter weekday runs or non-run days.
2. Ensure Strength Training and Yoga are scheduled a maximum of two times per week and at least 2 days before long runs or hard runs (tempo or intervals).
3. If there's one strength training day, there should be only one yoga day.
4. If there are two strength training days, there will be 0 yoga days.
5. If there are two yoga days, there will be 0 strength training days.
6. Assign Stretching sessions on the same day or the day after longer runs or workouts.
7. In the final week of the plan, assign stretching only.

Steps:
1. Review the running plan to identify shorter weekday runs or non-run days for potential Strength Training and Yoga assignments.
2. Ensure Strength Training and Yoga are placed a minimum of 2 days before long runs or hard runs.
3. Ensure no more than two cross-training activities (Strength Training or Yoga) are scheduled per week.
4. Assign Stretching sessions to appropriate days based on longer runs or workouts.

Output format: JSON.
Output legend: [1=sunday, 2=monday, 3=tuesday, 4=wednesday, 5=thursday, 6=friday, 7=saturday] : [0 - None, 1 - Strength Training, 2 - Yoga, 3 - Stretching]
Provide the output in the following JSON format:
{
  "plan": [{ 1: 0, 2: 0, 3: 0, 4: 0, 5: 0, 6: 0, 7: 0}] 
}
\n

Daily Running plan:\n${stepResults.length ? JSON.stringify(stepResults.find((x: any) => x?.step === currentStep)?.data) : undefined}\n
`] : []

  }


  // RACE BUILD PLAN - EXPLORE PAGE
  const buildStage2InputPromptsForRunner = (data?: Stage2Details) => {
    const { raceType, gender, dob, preferredLongRun, availableRunDays, stepResults, currentStep, planWeeks, runDaysPerWeek, crossTraining, level, latestRevision } = data || {}
    const age = dob ? ((dob ? moment().diff(moment(dob), 'years') : 36) + '-year-old') : ''

    const trainingLevel = level || 3
    const yoga = crossTraining?.includes('Yoga') || false
    const strength = crossTraining?.includes('Strength') || false
    const stretch = true || crossTraining?.includes('Stretch')
    // console.log('yoga', yoga)
    // console.log('strength', strength)

    // const previousResults = stepResults.find((x: any) => x?.step == currentStep)?.data
    const allCurrentStepResults = stepResults.filter((x: any) => x?.step == currentStep && x?.revision === latestRevision)
    // allCurrentStepResults && console.log('>>> all step results of latest revision', latestRevision, allCurrentStepResults)
    const previousResults = (allCurrentStepResults && allCurrentStepResults.length > 0) ? allCurrentStepResults[allCurrentStepResults.length - 1]?.data : ''
    // console.log('>>> latest revision results', previousResults)

    return data ? [`Weekly Plan: \n${stepResults.length ? JSON.stringify(previousResults) : undefined}\n
    As a running coach, create a ${raceType}-marathon plan for a ${age}-year-old ${gender}.

Convert the weekly plan into a daily schedule using these strict, non-negotiable rules:
1. The plan must include exactly ${runDaysPerWeek} running days selected from the following options:  ${[availableRunDays, preferredLongRun].join(', ')}. No exceptions.
2. Weekly mileage must match the original plan precisely.
3. Long run distance on ${preferredLongRun} should never be changed.
4. Every single run must be 3 miles or more - no exceptions.

Process:
1. Keep  ${preferredLongRun} long runs as is.
2. Distribute remaining mileage across the other ${runDaysPerWeek ? runDaysPerWeek - 1 : 0} running days following these principles:
   a. Allocate more miles to mid-week runs, even if it results in significantly longer individual runs.
   b. Make runs as long as necessary to ensure no run is less than 3 miles and to maintain exactly ${runDaysPerWeek} running days.
   c. Space out runs across the week to avoid consecutive running days when possible.
3. Adjust distances (except ${preferredLongRun}) to ensure weekly totals match the original plan.

Final check:
-  ${preferredLongRun}s are for long runs and remain unchanged.
- The plan has no more than ${runDaysPerWeek} running days per week selected from the specified options. Update if needed.
- Every single run is 3 miles or more - double-check this.
- Weekly mileage matches the original plan exactly.
- Mileage distribution follows a logical progression through the week.
- Check to ensure there's a MAX of ${runDaysPerWeek} runs per week. Update if needed
${availableRunDays?.length && getUnselectedDays([...availableRunDays, preferredLongRun]).length > 0 ? `- Check to ensure no runs are scheduled for ` + getUnselectedDays([...availableRunDays, preferredLongRun]).join(', ') : ''}.

Important: Do not add a ${runDaysPerWeek ? runDaysPerWeek + 1 : 6}th running day under any circumstances. If necessary, significantly increase the length of existing runs to meet weekly mileage goals while maintaining exactly ${runDaysPerWeek} running days.


Output the plan:
Output Format
Provide the output in the following JSON format:
{
"plan": [{ w: 1, m: 0, 1: 0, 2: 0, 3: 0, 4: 0, 5: 0, 6: 0, 7: 0}] 
}

Output legend: w=week, m=weekly mileage, 1=sunday, 2=monday, 3=tuesday, 4=wednesday, 5=thursday, 6=friday, 7=saturday:{number of miles per day}`
      ,

    `You are a running coach building a ${raceType}-marathon plan for a ${age} ${gender}. You will receive a daily running plan that includes distances on specific days, long runs, recovery, and taper weeks. Your goal is to update the plan with run types, optimizing for performance and injury prevention.
  
  Types of runs you can assign:
  - Base Runs: Focus on building aerobic endurance. They form the foundation of the plan.
  - Progression Runs: Progress in pace throughout the run. They build speed without too much stress.
  - Easy Runs: Great for recovery. Can be done on their own or after hard workouts or long runs. Limit to one per week.
  ${trainingLevel >= 2 && `- Tempo Runs: Improve lactate threshold and build speed. Begin within 8 weeks of race day.`}
  ${trainingLevel >= 3 && `- Interval Workouts: Build speed and VO2 max. Begin within 4 weeks of race day.`}

  Guidelines for assigning run types:
  1. Assign Base Runs throughout the plan
  2. Assign Progression Runs. Limit one per week
  3. Assign Easy Runs. Limit to two per week.
  4. Begin ${trainingLevel >= 2 && `Tempo`} ${trainingLevel >= 3 && ` and Interval Runs`} at week 9. Limit two per week.
  5. Ensure there is a mix of run types each week to optimize training.
  6. In the final week, change all runs to “race pace” runs for tapering.
  7. Long runs can be base or progression runs
  
  Steps:
  1. Assign long runs as Base Runs or Progression Runs.
  2. For weeks 1-8, focus on Base Runs, Progression Runs, and Easy Runs.
  ${trainingLevel >= 2 && `3. From week 9 onward, introduce Tempo Runs as described.`}
  ${trainingLevel >= 3 && `4. Also, from week 9 onward, introduce Interval Workouts as described.`}
  5. In week ${planWeeks}, change all runs to “race pace” runs for tapering.
  
  Final check:
  - Ensure progression runs aren't assigned to every long run
  - Ensure harder workouts are not on consecutive days
  - If there are two harder workouts in a given week, try to assign an easy run

  Output format: JSON. 
  Output legend:  [1=sunday, 2=monday, 3=tuesday, 4=wednesday, 5=thursday, 6=friday, 7=saturday] : [0 - Rest, 1 - Base Run, 2 - Progression Run, 3 - Easy, 4 - Tempo, 5 - Interval Run, 6 - Race Pace]
  Provide the output in the following JSON format:
  {
    "plan": [{ 1: 0, 2: 0, 3: 0, 4: 0, 5: 0, 6: 0, 7: 0}] 
  }
  \n

Here is your training plan.\n${stepResults.length ? JSON.stringify(previousResults) : undefined}
 
  `,
    `You are a running coach tasked with adding cross-training activities to a ${raceType}-marathon plan for a ${age} ${gender}. You will receive a daily running plan that includes distances and run types for specific days, including long runs, recovery, and taper weeks. Your goal is to update the plan with cross-training activities, optimizing for performance and injury prevention.

Types of cross-training you can assign:
${strength ? `- Strength Training: Bias towards shorter weekday runs or non-run days. Maximum of two times per week and at least 2 days before long runs or hard runs (tempo or intervals).` : ''}
${yoga ? `- Yoga: Same guidelines as strength training. If there's one strength training day, there should be only one yoga day. If there are two strength training days, there will be 0 yoga days. If there are two yoga days, there will be 0 strength training days.` : ''}
${stretch && `- Stretching: Short 10-15 minute sessions, ideal for the same day or the day after longer runs or workouts.`}

Rules for assigning cross-training activities:
1. Assign ${strength ? `, Strength Training` : ''}${yoga ? `, Yoga` : ''} to shorter weekday runs or non-run days.
2. Ensure ${strength ? `, Strength Training` : ''}${yoga ? `, Yoga` : ''} are scheduled a maximum of two times per week and at least 2 days before long runs or hard runs (tempo or intervals).
${strength && yoga ? `3. If there's one strength training day, there should be only one yoga day.
4. If there are two strength training days, there will be 0 yoga days.
5. If there are two yoga days, there will be 0 strength training days.` : ''}
${stretch ? `6. Assign Stretching sessions on the same day or the day after longer runs or workouts.
7. In the final week of the plan, assign stretching only.` : ''}

Steps:
1. Review the running plan to identify shorter weekday runs or non-run days for potential ${strength ? `, Strength Training` : ''}${yoga ? `, Yoga` : ''} assignments.
2. Ensure ${strength ? `, Strength Training` : ''}${yoga ? `, Yoga` : ''} are placed a minimum of 2 days before long runs or hard runs.
3. Ensure no more than two cross-training activities (${strength ? `, Strength Training` : ''}${yoga ? `, Yoga` : ''}) are scheduled per week.
${stretch ? `4. Assign Stretching sessions to appropriate days based on longer runs or workouts.` : ''}

Output format: JSON
Output legend:  [1=sunday, 2=monday, 3=tuesday, 4=wednesday, 5=thursday, 6=friday, 7=saturday] : [0 - None, 1 - Strength Training, 2 - Yoga, 3 - Stretching]
Provide the output in the following JSON format:
{
  "plan": [{ 1: 0, 2: 0, 3: 0, 4: 0, 5: 0, 6: 0, 7: 0}] 
}
  \n

Daily Running plan.\n${stepResults.length ? JSON.stringify(previousResults) : undefined}\n`] : []

  }


  // PROVIDES SUGGESTED PEAK/MAX WEEKLY MILEAGE
  const buildPromptSuggestedWeeklyMileage = (weeklyPlanData: any) => {

    const { dob, gender, planWeeks, lastLongestRun, currentWeeklyMileage, raceType, runDaysPerWeek, unit } = weeklyPlanData || {}

    const age = dob ? (moment().diff(moment(dob), 'years') + '-year-old') : '36-year-old'
    let race = raceType
    if (raceType?.toLowerCase().includes('half') || raceType?.toLowerCase().includes('full')) race += ' marathon'

    return `You are a running coach determining the average and highest weekly mileage for a runner based on the following information.

    Guidelines:
    1. Be sure to take age, gender, and number of running days into account.
    2. Be conservative in your recommendation (15% rule)
    3. Account for recovery weeks and taper
    
    Inputs:
    - Age: ${age}
    - Gender: ${gender}
    - Length of plan: ${planWeeks} weeks
    - Plan type: ${race || ''}
    - Average weekly mileage over the last 4 weeks: ${currentWeeklyMileage} ${unit || 'miles'}
    - Longest run completed in the last 2 weeks: ${lastLongestRun} ${unit || 'miles'}
    - Number of running days per week: ${runDaysPerWeek || 4}

    Rules for weekly distances:
    - For input of 3 running days: Do not exceed 25 mi (40km)
    - For input of 4 running days: Do not exceed 35 mi (56km)
    - For input of 5 running days: Do not exceed 45 mi (72km)
    - For input of 6 running days: Do not exceed 60 mi (97km)
    - For input of 7 running days: Do not exceed 75 mi (113km)

    Output:
    - Recommended Average Weekly Mileage: [provide number range]
    - Recommended Peak Weekly Mileage: [provide number range]

    Output format: JSON
    Example: { average: '18-22', peak: '26-28' }`
  }


  // PROVIDES SUGGESTED GOAL FINISH TIME
  const buildPromptSuggestedGoalTime = (weeklyPlanData: any) => {

    const { dob, gender, planWeeks, raceType, raceDistance, runPace } = weeklyPlanData || {}

    const age = (dob ? moment().diff(moment(dob), 'years') : 33) + '-year-old'

    const prompt = `I am a ${age} ${gender || ''} training for a ${raceType || ''} marathon of ${raceDistance || 26} miles, ${planWeeks || 13}-weeks from now.
      Help me come up with a finish goal time.
      Suggest me the best finish goal time so that I could run like I am in a ${runPace}
      
      Your output should be in this time format: { goalFinishTime: { hours: 0, minutes: 0, seconds: 0 } }.
      Example Output: { goalFinishTime: { hours: 2, minutes: 33, seconds: 26 } }
      `
    // console.log('Suggested Goal Time Prompt: ', prompt)

    return prompt

  }

  return { defaultSytemPrompt, inputPrompts, buildUserDetails, buildStage2InputPrompts, buildStage2InputPromptsForRunner, defaultSytemPrompt2, buildPromptSuggestedGoalTime, buildPromptSuggestedWeeklyMileage, buildUserRecommendation }
}

export default ChatCompletionPromptsSingle


