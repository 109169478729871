import { postReq } from "../modules/apiConsume"

const useMailer = () => {
    const sendMail = async (type: string, mailTo: string, file: string) => {
        const response = await postReq('/v2/send-mail', { type, mailTo, file })
        return response
    }

    return { sendMail }
}

export default useMailer