import GenericForm, { GenericFormProps } from './GenericForm'
import { InputInfo } from './SignInForm'
import { BasicInputProps } from '../input/BasicInput'
import useFormInfo, { FormInfo } from '../../hooks/useFormInfo'
import {
	isRequired,
	validPassword,
	validConfirmPassword,
} from '../../modules/validation'
import { FC, useState, useEffect } from 'react'

// Props
export type ChangePassFormProps = {
	setIsValid: (x: boolean) => any
	setInfo?: (x: FormInfo) => any
}

// Input Props
export const inputProps: GenericFormProps['inputs'] = [
	{
		id: 'cur-pass-input',
		formKey: 'currentPassword',
		placeholder: 'Current Password',
		label: 'Current Password',
		isPassword: true,
		validations: [isRequired, validPassword],
	},
	{
		id: 'new-pass-input',
		formKey: 'newPassword',
		placeholder: 'New Password',
		label: 'New Password',
		isPassword: true,
		validations: [isRequired, validPassword],
	},
]

// Default Form Info
export const defaultFormInfo: FormInfo = {
	currentPassword: { value: '', isValid: false } as InputInfo,
	newPassword: { value: '', isValid: false } as InputInfo,
	confirmPassword: { value: '', isValid: false } as InputInfo,
}

// Change Pass Form Info
export type ChangePassFormInfo = typeof defaultFormInfo

/**
 * ChangePassForm Component
 */

const ChangePassForm: FC<ChangePassFormProps> = ({ setInfo, setIsValid }) => {
	// Update Input Props
	const updateInputProps = (info: FormInfo, setProps?: (x: any) => any) => {
		// Properties
		const confirmInput: BasicInputProps = {
			id: 'confirm-pass-input',
			formKey: 'confirmPassword',
			placeholder: 'Confirm Password',
			label: 'Confirm Password',
			isPassword: true,
			validations: [
				isRequired,
				validPassword,
				validConfirmPassword.bind(null, info.newPassword.value),
			],
		}
		const newInputProps = [...inputProps, confirmInput]
		if (setProps) setProps(newInputProps)
		return newInputProps
	}

	// State
	const [curInputProps, setCurInputProps] = useState(
		updateInputProps(defaultFormInfo)
	)

	// Hooks
	const { formInfo } = useFormInfo({
		inputProps: curInputProps,
		defaultFormInfo,
		setInfo,
		setIsValid,
	})

	// Mount Effect
	useEffect(() => {
		updateInputProps(formInfo, setCurInputProps)
	}, [formInfo])

	// Render
	return <GenericForm isHorizontal inputs={curInputProps} />
}

export default ChangePassForm
