// Webinar Covers
export const webinarCovers = {
	'60 Second Core':
		'/img-new/homepage/cover_images/no_logo/60-second-core-cover-image.jpg',
	'Fast Over 40':
		'/img-new/homepage/cover_images/no_logo/fast_over_40_cover_image.jpg',
	'Foam Rolling':
		'/img-new/homepage/cover_images/no_logo/foam_rolling_secrets_cover_image.jpg',
	'Peak on Race Day':
		'/img-new/homepage/cover_images/no_logo/Peak_on_Race_Day_cover_image .jpg',
	'Run Through Fatigue':
		'/img-new/homepage/cover_images/no_logo/run_through_ratigue_cover_image.jpg',
	'RunSmart Protocol':
		'/img-new/homepage/cover_images/no_logo/RunSmart_Protocol_cover_image.jpg',
}

// Webinar Logos
export const webinarLogos = {
	'60 Second Core': '/img-new/homepage/Logos/60-second-core-logo_reverse.png',
	'Fast Over 40': '/img-new/homepage/Logos/fast-over-40-logo.png',
	'Foam Rolling': '/img-new/homepage/Logos/foam-rolling-secrets-logo.png',
	'Peak on Race Day': '/img-new/homepage/Logos/peak-on-race-day-logo.png',
	'Run Through Fatigue': '/img-new/homepage/Logos/run-through-fatigue-logo.png',
	'RunSmart Protocol':
		'/img-new/homepage/Logos/runsmart-protocol-logo-green.png',
}

// Webinar Downloads
export const webinarDownloads = {
	'60 Second Core':
		'/img-new/webinars/download_images/60-second-core-download-image.png',
	'Fast Over 40':
		'/img-new/webinars/download_images/fast-over-40-download-image.png',
	'Foam Rolling': '/img-new/webinars/download_images/foam-rolling-secrets.png',
	'Peak on Race Day': '/img-new/webinars/download_images/peak-race-day.jpg',
	'Run Through Fatigue':
		'/img-new/webinars/download_images/run-through-fatigue.jpg',
	'RunSmart Protocol':
		'/img-new/webinars/download_images/RunSmart Protocol opt-in-24.jpg',
}
