import AccountSubmitButtons from '../../buttons/AuthSubmitButtons'
// import useUserInfo from '../../../hooks/useUserInfo'
import { getPendingDelete, getSubscriptionInfo, signOut, updateAccountInfo, CreateOnBoarding, UpdateOnBoarding } from '../../../modules/accountActions'
import { FC, useEffect, useState } from 'react'
import AccountForm, { accountFormInfo, FormInfo } from '../../forms/AccountForm'
import useStore from '../../../store/useStore'
import { ICurrentUser } from '../../../store/createAccountStore'
import secureLocalStorage from 'react-secure-storage'
import {  Row } from 'react-bootstrap'
import { navReq, postReq } from '../../../modules/apiConsume'
import WorkspacePremiumOutlinedIcon from '@mui/icons-material/WorkspacePremiumOutlined';
// import DeleteForeverOutlined from '@mui/icons-material/DeleteForeverOutlined';
import HttpsOutlinedIcon from '@mui/icons-material/HttpsOutlined';
import Grid from '@mui/material/Grid';
import useDeviceDetect from '../../../hooks/useDeviceDetect'
import GenericModal from '../../misc/GenericModal'
import RSButton from "../../../components/buttons/RSButton"

// Profile Props
export type ProfileProps = {
	onChangePassword: () => any
	onAlertOpen: () => any
	setAlertMessage: (x: string) => any
	setAlertVariant: (x: string) => any,
	side?: boolean
}

/**
 * Profile Component
 */
const CONFIRM_BUTTONS = ['DELETE ACCOUNT', 'CANCEL', 'DELETE IMMEDIATELY', 'AFTER SUBSCRIPTION ENDS', 'GO HERE TO CANCEL', 'OK']

const Profile: FC<ProfileProps> = ({
	onChangePassword,
	onAlertOpen,
	setAlertMessage,
	setAlertVariant,
	side
}) => {
	// State
	const [info, setInfo] = useState(accountFormInfo as FormInfo)
	const [isValid, setIsValid] = useState(false)
	const [showConfirmDelete, setShowConfirmDelete] = useState(false)
	const [hasPendingDelete, setHasPendingDelete] = useState(false)
	const [showSuccessDelete, setShowSuccessDelete] = useState(false)
	const [subscriptionInfo, setSubscriptionInfo] = useState<any>()
	const [isLoading, setIsLoading] = useState(false)
	const [showSelectDeleteMethod, setShowSelectDeleteMethod] = useState(false)
	const [showCancelFirst, setShowCancelFirst] = useState(false)
	const isAppleSignup = secureLocalStorage.getItem("isAppleSignup") || false
	const isGoogleSignup = secureLocalStorage.getItem("isGoogleSignup") || false
	const localStorageRequestKey = localStorage.getItem('deviceRequestKey')
	const { mobileType } = useDeviceDetect()


	// Hooks
	// const { username, email, firstName, lastName, dob, gender, favorite } =
	// 	useUserInfo()

	// Get Current User
	const { currentUser, setCurrentUser } = useStore(
		(state: ICurrentUser) => state
	)

	const handleDeleteAccountImmediately = async () => {
		if (!subscriptionInfo?.subscription?.canceled && subscriptionInfo?.isActive) {
			setShowSelectDeleteMethod(false)
			setShowCancelFirst(true)
		}
		else {
			setShowCancelFirst(false)
			await postReq('/delete-account', { delete_type: 0 }).then(() => {
				setShowSelectDeleteMethod(false)
				signOut()
			})
		}

	}

	const handleDeleteAccountEndSub = async () => {

		if (!subscriptionInfo?.subscription?.canceled && subscriptionInfo?.isActive) {
			setShowSelectDeleteMethod(false)
			setShowCancelFirst(true)
		}

		else {
			setShowCancelFirst(false)
			await postReq('/delete-account', { delete_type: 1 }).then(() => {
				setShowSuccessDelete(true)
				setShowSelectDeleteMethod(false)
				// navReq('/')
			})
		}

	}

	const getSubscription = async () => {
		setIsLoading(true)
		const subscription = await getSubscriptionInfo()
		const hasPendingDeleteAccount = await getPendingDelete()
		// console.log(subscription)
		// alert(subscription.subscription.subscription_type)
		setHasPendingDelete(hasPendingDeleteAccount)
		setSubscriptionInfo(subscription)
		setIsLoading(false)
	}
	// On Submit
	const onSubmit = async () => {

		const result = await updateAccountInfo({
			oldEmail: currentUser?.email,
			email: info.email.value,
			username: info.user.value,
			firstName: info.firstName.value,
			lastName: info.lastName.value,
			gender: info.gender.value,
			dob: info.dob.value,
			// favorite: info.favorite.value,
			distanceUnit: info.distanceUnit.value,
			dayStart: info.dayStart.value
		}, "profile_update")


		const oldOnBoardingData = currentUser?.onBoardingData?.id ? currentUser.onBoardingData : {}
		const onBoardingData = {
			...oldOnBoardingData,
			id: currentUser?.account_id,
			firstName: info.firstName.value,
			lastName: info.lastName.value,
			birthDate: info.dob.value.split('T')?.[0]?.replaceAll('-', '/'),
			gender: info.gender.value === 'O' ? 'nb' : info.gender.value?.toLowerCase(),
			isKm: info.distanceUnit.value === 'km' ? 1 : 0,
			isLongRunSat: info.dayStart.value === 'monday' ? 0 : 1
		}

		if (currentUser?.account_id) {
			const body = { user_id: currentUser.account_id, user_progress: JSON.stringify(onBoardingData) }
			if (currentUser?.onBoardingData?.id)
				await UpdateOnBoarding(body)
			else
				await CreateOnBoarding(body)
		}

		if (result?.status == 'ok') {
			setCurrentUser({
				...currentUser,
				username: info.user.value,
				first_name: info.firstName.value,
				last_name: info.lastName.value,
				email: info.email.value,
				gender: info.gender.value,
				dob: info.dob.value.split('T')[0],
				// favorite: info.favorite.value,
				distance_unit: info.distanceUnit.value,
				day_start: info.dayStart.value,
				onBoardingData
			})

			setAlertVariant('success')
			setAlertMessage('Updated profile successfully')
		} else {
			setAlertVariant('danger')
			setAlertMessage('Could not update profile')
		}
		onAlertOpen()
	}

	useEffect(() => {
		if (localStorageRequestKey && mobileType === 'Ios') {
			getSubscription()
		}
	}, [localStorageRequestKey, mobileType])

	// Render
	return (
		<>
			<GenericModal
				type='delete-account'
				show={showConfirmDelete}
				title="Account Deletion"
				text={'Please note, deleting your account data will result in the removal of your account and data. This includes the ability to access any content or videos. It may also result in the inability to re-create your account.'}
				setShow={setShowConfirmDelete}
				keyboard={false}
				backdrop="static"
				button1Action={() => {
					setShowConfirmDelete(false)
					setShowSuccessDelete(false)
					setShowSelectDeleteMethod(true)
				}}
				button1Text={CONFIRM_BUTTONS[0]}
				button2Action={() => {
					setShowSuccessDelete(false)
					setShowConfirmDelete(false)
				}}
				button2Text={CONFIRM_BUTTONS[1]}
			/>

			<GenericModal
				type='delete-account'
				show={showCancelFirst}
				title=""
				text={'You have an active subscription. Please cancel your subscription first, then return to your profile to finish deletion.'}
				setShow={setShowCancelFirst}
				keyboard={false}
				backdrop="static"
				button1Action={() => navReq('/#/cancel/step-1')}
				button1Text={CONFIRM_BUTTONS[4]}
				button2Action={() => setShowCancelFirst(false)}
				button2Text={CONFIRM_BUTTONS[1]}
			/>

			<GenericModal
				type='delete-account'
				show={showSuccessDelete}
				title=""
				text={`Your request to delete your account has been ${hasPendingDelete ? 'queued' : ' received'}. Your account will be permanently deleted on ${new Date(subscriptionInfo?.subscription?.end_date).toLocaleDateString()}`}
				setShow={setShowSuccessDelete}
				keyboard={false}
				backdrop="static"
				button1Action={() => {
					setShowSuccessDelete(false)
					!hasPendingDelete ? navReq('/') : undefined
					// navReq('/')
				}}
				button2Action={hasPendingDelete ? () => {
					setShowSelectDeleteMethod(true)
				} : undefined}
				button2Text={hasPendingDelete ? 'DELETE IMMEDIATELY' : undefined}
				button1Text={CONFIRM_BUTTONS[5]}
			/>

			{/* <GenericModal
				type='delete-account'
				show={showCancelFirst}
				title=""
				text={'You have an active subscription. Please cancel your subscription before deleting your account.'}
				setShow={setShowCancelFirst}
				keyboard={false}
				backdrop="static"
				button1Action={() => navReq('/#/cancel/step-1')}
				button1Text={CONFIRM_BUTTONS[4]}
				button2Action={() => setShowCancelFirst(false)}
				button2Text={CONFIRM_BUTTONS[1]}
			/> */}

			<GenericModal
				type='delete-account'
				show={showSelectDeleteMethod}
				title="Account Deletion Confirmation"
				text={'This action cannot be undone. You have the option to delete your account immediately or at the end of your subscription period.'}
				setShow={setShowSelectDeleteMethod}
				keyboard={false}
				button1Action={async () => await handleDeleteAccountImmediately()}
				button1Text={CONFIRM_BUTTONS[2]}
				button2Action={hasPendingDelete ? undefined : async () => await handleDeleteAccountEndSub()}
				button2Text={hasPendingDelete ? undefined : CONFIRM_BUTTONS[3]}
				button3Action={() => {
					setShowSelectDeleteMethod(false)
					setShowSuccessDelete(false)
				}}
				button3Text={CONFIRM_BUTTONS[1]}
			/>
			{!side && <h3 className='mt-3 mb-4' style={{ letterSpacing: 0 }}><b>Profile</b></h3>}
			{isAppleSignup ? (
				<h5 className='mt-n3'>(Log with Apple ID)</h5>
			) : undefined}
			{isGoogleSignup ? (
				<h5 className='mt-n3'>(Log with Google ID)</h5>
			) : undefined}
			<AccountForm
				setInfo={setInfo}
				setIsValid={setIsValid}
				username={`${currentUser?.username || ""}`}
				email={currentUser?.email}
				firstName={currentUser?.first_name}
				lastName={currentUser?.last_name}
				gender={currentUser?.gender}
				dob={
					currentUser?.dob
						? currentUser.dob.split('T')[0]
						: ('' as string | undefined)
				}
				// favorite={currentUser?.favorite}
				type='profile'
				defaultUsername={currentUser?.username}
				distanceUnit={currentUser?.distance_unit || ''}
				dayStart={currentUser?.day_start || ''}
			/>
			<div className='mt-4'>
				<>
					{/* <AccountSubmitButtons
							primaryText="Save"
							// extraText="Change Password"
							// extraAction={onChangePassword}
							primaryAction={onSubmit}
							primaryDisabled={!isValid}
							isSecondary
							extraOnly={true}
						/> */}

					<div className='account-buttons-container'>
						<AccountSubmitButtons
							primaryText="Save"
							primaryAction={onSubmit}
							primaryDisabled={!isValid}
							isAccount
						/>


						{/* <AccountSubmitButtons
								primaryText="Update Profile"
								primaryAction={() => navReq('/#/start?type=profile_update')}
								isAccount
							/> */}
					</div>

					<Grid container>
						<Grid container xs={6} justifyContent='center' alignItems='center'>
							<button onClick={() => navReq('/#/start?type=profile_update')} type="button" className="btn btn-outline-secondary btn-outlined"><WorkspacePremiumOutlinedIcon />
								Update Goals & Preferences</button>
						</Grid>
						<Grid container xs={6} justifyContent='center' alignItems='center'>
							<button onClick={onChangePassword} type="button" className="btn btn-outline-secondary btn-outlined"><HttpsOutlinedIcon />Change Password</button>
						</Grid>
					</Grid>
					{localStorageRequestKey && mobileType === "Android" || localStorageRequestKey && mobileType === 'Ios' ? (
						<Row className='mx-0 w-100'>
							<RSButton secondary disabled={isLoading} onClick={() => hasPendingDelete ? setShowSuccessDelete(true) : setShowConfirmDelete(true)} className={`${mobileType === "Android" ? 'w-75' : 'w-50'} btn btn-outlined mx-auto mt-4 text-secondary`}>
								<span className='w-100 text-center'>
									{/* <DeleteForeverOutlined className='text-danger' /> */}
									{mobileType === "Android" ? 'Request Account Deletion' : 'Delete Account'}
								</span>
							</RSButton>

						</Row>
					) : undefined}
				</>
			</div>
		</>
	)
}

export default Profile
