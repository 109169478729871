import Arrow from '../logos/Arrow'
import Card from 'react-bootstrap/Card'
import MultiLineText from '../text/MultiLineText'
import classNames from 'classnames'
import colors from '../../modules/colors.json'
import { titleCase } from '../../modules/miscUtils'
import { CircularProgressbar, buildStyles } from 'react-circular-progressbar'
import BodyComponent, { BodyComponentProps } from './BodyComponent'
import ImageLogo, { ImageLogoProps } from '../logos/ImageLogo'
import { FC } from 'react'

// Props
export type HomeDetailCardProps = BodyComponentProps & {
	title?: string
	text: string
	logoType?: ImageLogoProps['type']
	logoImage?: string
	showProgressBar?: boolean
	showDaysUntilRace?: boolean
	progressPercent?: number
	daysUntilRace?: number
	trainingType?: string
	scheduleText?: string
	hasInfo?: boolean
	otherInfo?: string,
	weeks?: number,
	pace?: string,
	paceDetails?: boolean,
	currentWeek?: number
}

/**
 * HomeDetailCard Component
 */

const HomeDetailCard: FC<HomeDetailCardProps> = ({
	title,
	text,
	bgImage,
	logoImage,
	logoType,
	showProgressBar = false,
	showDaysUntilRace = true,
	progressPercent = 0,
	daysUntilRace = 0,
	trainingType = '',
	scheduleText = '',
	hasInfo,
	otherInfo
}) => {
	// Properties
	const trainingTypeFormatted = !trainingType
		? ''
		: trainingType[trainingType.length - 1] == 'k'
			? trainingType.toUpperCase()
			: titleCase(trainingType)
	const trainingText = trainingType ? `${trainingTypeFormatted} ${trainingType.toLowerCase().includes('recovery') ? '' : scheduleText.includes('results') ? ' training ' : ' training: '}` : ''
	const progressText = showProgressBar ? `${progressPercent}% Complete` : ''
	const daysUntilRaceText = trainingType.toLowerCase().includes('recovery') ? `${daysUntilRace} days remaining` : showDaysUntilRace
		? `${daysUntilRace} days until race day`
		: ''
	const cardClass = classNames(
		'home-detail-card',
		bgImage ? 'card-clear' : undefined
	)
	// Render
	return (
		<Card text="dark" className={cardClass} bg="white">
			<BodyComponent bgImage={bgImage}>
				<Card.Text as="div">
					<div className="flex-content-center justify-content-center home-detail-card-body">
						{/* Logo */}
						<div className="justify-content-center mx-n4 text-center">
							{showProgressBar ? (
								<div
									className='ms-4 ms-md-3'
									style={{ height: '80px', width: '80px' }}
								>
									<CircularProgressbar
										value={progressPercent}
										text={`${progressPercent}%`}
										styles={buildStyles({
											pathColor: colors.primary,
											textColor: colors.primary,
										})}
									/>
								</div>
							) : (
								<div className={`${logoType === "training" ? 'ms-2 ms-md-2' : ''}`}>
									<ImageLogo hasBorderRadius hasInfo={hasInfo} type={logoType} image={logoImage} height={logoType == 'training' ? 90 : 90} />
								</div>
							)}
						</div>

						{/* Text */}
						<div className="home-detail-card-text no-bottom-margin ms-5 me-2">
							{showProgressBar ? (
								<div className='mx-md-0 mx-1'>
									{/* Schedule */}
									{scheduleText?.includes('results') ? undefined : (<h6>Schedule for today:</h6>)}


									{/* Completion Percent */}
									{scheduleText?.includes('results') ? (
										<>
											<h6 className='p-bold'>{scheduleText}</h6>
											<h6 className='mt-2'>{trainingText} for</h6>
											<h6>{otherInfo}</h6>
										</>
									) : (
										<>
											<h6>
												<div className='p-bold'>{scheduleText}</div>
											</h6>
											<h6>{trainingText}</h6>
											<h6>
												<div className='p-bold' style={{color: '#999'}}>{progressText}</div>
											</h6>
											{/* Days until Race */}
											{showDaysUntilRace && (
												<h6 className="fst-italic">{daysUntilRaceText}</h6>
											)}
										</>
									)}



								</div>
							) : (
								<div className={`${logoType === "training" ? 'ms-1 ms-md-2' : ''}`}>{
									logoType !== "training" ? (
										<h5 className="heading-meta-data text-white fw-regular">
											NEW!
										</h5>
									) : undefined
								}
									{(title ? true : false) && <h4>{title}</h4>}
									<h6 className="is-h7">
										<MultiLineText text={text} />
									</h6>
									{(otherInfo ? true : false) && <p><u>{otherInfo}</u></p>}
								</div>
							)}
						</div>
						{/* Arrow Button */}
						<Arrow />
					</div>
				</Card.Text>
			</BodyComponent>
		</Card>
	)
}

export default HomeDetailCard
