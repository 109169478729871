// Home Links
export const homeLinks = ['/', '/home']

// Program Links
export const programLinks = {
	Base3: '/#/programs/base3',
	Base6: '/#/programs/base6',
	Mechanix: '/#/programs/mechanix',
	Prime: '/#/programs/Prime',
	Yoga: '/#/programs/Yoga',
	Midfoot: '/#/programs/midfoot-project',
	Recover: '/#/programs/runsmart-recover'
}

// Webinar Links
export const webinarLinks = {
	'60 Second Core': '/#/webinars/60-second-core',
	'RunSmart Protocol': '/#/explore?p_id=13',
	'Fast Over 40': '/#/webinars/fast-over-40',
	'Peak on Race Day': '/#/webinars/peak-on-race-day',
	'Run Through Fatigue': '/#/webinars/run-through-fatigue',
	'Foam Rolling': '/#/webinars/foam-rolling',
}

// Exercise Links
export const exerciseLinks = {
	'All Exercises': '/#/exercises/all-exercises',
	'Foam Rolling': '/#/exercises/foam-rolling',
	Stretching: '/#/exercises/stretching',
	Strength: '/#/exercises/strength',
	'Run Form Drills': '/#/exercises/run-form-drills',
	'RunSmart 101': '/#/exercises/runsmart-101',
}

// Guide Links
export const guideLinks = {
	Training: '/#/explore',
	Strength: '/#/explore?p_id=6',
}

// Training Links
export const trainingLinks = {
	view: '/#/training-plan/view',
	build: '/#/training-plan/build/step-1',
	start: '/#/training-plan/start',
	download: '/#/training-plan/download',
}

// Training Frame Routes
export const trainingFrameRoutes = {
	view: 'view',
	build: 'build',
	start: 'get-started',
	download: 'download-plans',
}

// Link ID
export const linkID = {
	programLinks: {
  	Base3: 7,
		Base6: 8,
		Yoga: 9,
		Prime: 10,
		Mechanix: 15,
		Midfoot: 16,
		Recover: 19
  },
  webinarLinks: {
  	'Fast Over 40': 2,
  	'Run Through Fatigue': 3,
  	'60 Second Core': 12,
  	'RunSmart Protocol': 13,
  	'Foam Rolling': 14,
  },
  guideLinks: {
  	Training: 4,
  	Strength: 6,
  },
  resetLinks: {
  	Reset: 1
	}
}
