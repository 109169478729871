import { useEffect, useState } from "react"
import { ISurveyDetails } from "../../api/v2/onboarding-survey/types"
import { postReq, putReq } from "../../modules/apiConsume"
import { Video } from "../../modules/videos/allVideos"
import { GetOnBoarding } from '../../modules/accountActions'
import suggestedPrograms from './suggested-program.json'
import suggestedExercisesList from './suggested-exercises.json'
import { Exercise } from "../exercises/types"
import allExercises from '../../modules/videos/allNewExercises.json';
import lodash from "lodash"
import { ISurveyStore } from "../../store/createSurveyStore"
import useStore from "../../store/useStore"

const DEFAULT_VALUES = {
    "id": 0,
    "user_id": 0,
    "describe_running": "",
    "weekly_average": "",
    "running_goal": "",
    "struggle_areas": "",
    "add_pt": 0,
    "date_created": "0000-00-00T00:00:00.000Z",
    "last_modified": "0000-00-00T00:00:00.000Z"
}

const useSurveyActions = (userId?: number) => {

    const [isLoading, setIsLoading] = useState(true)

    // const [userSurveyDetails, setUserSurveyDetails] = useState<ISurveyDetails | undefined>()
    const { userSurveyDetails, setUserSurveyDetails } = useStore((state: ISurveyStore) => state)
    const [suggestedProgram, setSuggestedProgram] = useState<typeof suggestedPrograms[0]>()
    const [suggestedExercises, setSuggestedExercises] = useState<Video[]>([])

    const parseVideoList = (exercises: Exercise[]) => {
        const videos: Video[] = []
        exercises?.forEach((e) => {
            const data: Video = {
                id: String(e?.video_id),
                url: '/#/exercises/' + e?.video_id,
                // image: e.featured_img,
                image: `https://vumbnail.com/${e?.video_id}.jpg`,
                category: e?.exercise_type,
                headingTitle: e?.exercise_title,
                headingSubtitle: e?.primary_region,
                detailsLine1: e?.tips_description,
                detailsLine2: '',
                headlines: [],
                descriptions: [e?.tips_description],
            }
            videos.push(data)
        })

        return videos;
    }

    const getSurveyDetails = async (userid: number) => {
        setIsLoading(true)

        if (!userSurveyDetails?.add_pt) {
            let surveyDetails = DEFAULT_VALUES
            const abortController = new AbortController();
            const result = await GetOnBoarding(userid, abortController.signal)
            const res = result?.data?.result?.[0]
            const userProgress = res?.user_progress;
            if (userProgress) {
                const onboardingData = JSON.parse(userProgress)
                const newSurveyDetails = { ...surveyDetails, user_id: userid, date_created: res?.date_created, last_modified: res?.last_modified, add_pt: 1 }
                const goalsAnswer = onboardingData?.goals[0]?.title?.answer?.toString()
                const problemAreas = onboardingData?.problemAreas
                const runningActivityIndex = onboardingData?.runningActivity?.index

                if (goalsAnswer)
                    newSurveyDetails.running_goal = goalsAnswer

                if (problemAreas?.length)
                    newSurveyDetails.struggle_areas = problemAreas.map((x: any) => (x.index + 1).toString()).filter((f: any) => f).join(',')


                if (runningActivityIndex)
                    newSurveyDetails.weekly_average = runningActivityIndex > 4 ? '4' : runningActivityIndex.toString()

                surveyDetails = newSurveyDetails
            }

            setUserSurveyDetails(surveyDetails)
            setIsLoading(false)
            return surveyDetails
        }
        else {
            setIsLoading(false)
            return userSurveyDetails;
        }
    }

    const createSurveyDetails = async (details: ISurveyDetails) => {
        setIsLoading(true)
        const result = await postReq(`/v2/onboarding-survey`, details)
        setUserSurveyDetails(result?.data?.result[0])
        setIsLoading(false)
    }

    const updateSurveyDetails = async (details: ISurveyDetails) => {
        setIsLoading(true)
        const result = await putReq(`/v2/onboarding-survey`, details)
        setUserSurveyDetails(result?.data?.result[0])
        setIsLoading(false)
    }

    const pushDetailsToZapier = async (userId: number, signup_method: string) => {
        setIsLoading(true)
        const result = await postReq(`/v2/onboarding-survey/zapier?user_id=${userId}&signup_method=${signup_method}`, {})
        setIsLoading(false)
        if (result?.status === "ok")
            return true
        else
            return false
    }

    const getSuggestedExercises = () => {
        let ansArray = userSurveyDetails?.struggle_areas.split(",").map(Number)
        const exercises: Exercise[] = [];
        if (ansArray?.includes(1) && ansArray?.includes(2))
            ansArray = ansArray.filter(x => x != 2)
        const ansLength = ansArray?.length
        if (ansLength) {
            let n = ansLength > 4 ? ansLength : 4
            ansArray?.forEach((answer: any, i: number) => {
                const l = Math.floor(n / (ansLength - i))
                n = n - l
                const vId = suggestedExercisesList.find(x => x.answer == answer)?.exercises as number[]
                vId?.slice(0, l).forEach((id) => {
                    exercises.push(allExercises.find(x => x.video_id == id) as unknown as Exercise)
                })
            })
        }

        return parseVideoList(lodash.uniq(exercises))

    }

    // useEffect(() => {
    //     if (userId)
    //         getSurveyDetails(userId)
    // }, [userId])

    useEffect(() => {
        if (userId && userSurveyDetails?.add_pt) {
            setSuggestedProgram(suggestedPrograms.find(x => x.answer == Number(userSurveyDetails?.running_goal)))
            setSuggestedExercises(getSuggestedExercises())
        }
    }, [userSurveyDetails?.add_pt, userId])

    return { getSurveyDetails, isLoading, userSurveyDetails, createSurveyDetails, updateSurveyDetails, setUserSurveyDetails, suggestedProgram, suggestedExercises, pushDetailsToZapier }
}

export default useSurveyActions
