import { ProgressInfo } from "../hooks/useVideoProgress"
import { StoreSlice, VideoWatchData } from "./types"

export interface IVideoStore {
    videoWatchData: VideoWatchData[],
    setVideoWatchData: (videoWatchData: VideoWatchData[]) => void,
    videoProgressData: any | undefined,
    setVideoProgressData: (videoProgress: any) => void,
    videoInfo: ProgressInfo[],
    setVideoInfo: (videoInfo: any) => void,
    homeVideos: any | undefined,
    setHomeVideos: (homeVideos: any) => void
}

const createVideoStore: StoreSlice<IVideoStore> = (set) => ({
    videoWatchData: [],
    setVideoWatchData: (videoWatchData: VideoWatchData[]) => set(() => ({ videoWatchData: videoWatchData })),
    videoProgressData: undefined as any,
    setVideoProgressData: (videoProgressData: any) => set(() => ({ videoProgressData: videoProgressData })),
    videoInfo: [],
    setVideoInfo: (videoInfo: ProgressInfo[]) => set(() => ({ videoInfo: videoInfo })),
    homeVideos: [],
    setHomeVideos: (homeVideos: any) => set(() => ({ homeVideos: homeVideos })),
})

export default createVideoStore