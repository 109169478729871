/* eslint-disable @typescript-eslint/no-unused-vars */
import { useEffect, useState } from "react"
import exerciseActions from "../../modules/exerciseActions"
import { Exercise, WatchData } from "../exercises/types"
import ALL_EXERCISES from '../../modules/videos/allNewExercises.json'
import featuredPrograms from '../../modules/programs/featured-programs.json'
import allVideos, { Video } from "../../modules/videos/allVideos"
import workoutsActions from "../../modules/workoutsActions"
import useProgramFavorites from "./useProgramFavorites"
import recoverVideos from "../recover/RecoverVideos.json"

const useFavorites = (userId: number, videoId?: string, type?: string, requestData?: boolean) => {
    const { getExerciseFavorites, likeExerciseVideo } = exerciseActions()
    const { getWorkoutsFavorites, likeWorkoutVideo } = workoutsActions()
    const [favorites, setFavorites] = useState<Video[]>([])
    const [markAsFavorite, setMarkAsFavorite] = useState(false)
    const [isLoading, setIsLoading] = useState(true)
    const [newFavorites, setNewFavorites] = useState<any>(null)
    const [newLoading, setNewLoading] = useState(true)

    const { programFavorites, getUserProgramFavorites } = useProgramFavorites(userId, type)

    const handleSetFavorites = (data: any, type: string, getAll?: boolean) => {
        const exercisesVideos: Video[] = []
        if (data) {
            data.forEach((value: any) => {
                if (type === "exercises") {
                    const e = ALL_EXERCISES.find((x) => x.exercise_id == value.exercise_id)
                    // const v = allVideos[e?.video_id as number]
                    const data: Video = {
                        id: String(e?.video_id),
                        url: '/#/exercises/' + e?.video_id,
                        // image: e.featured_img,
                        image: `https://vumbnail.com/${e?.video_id}.jpg`,
                        category: e?.exercise_type as string,
                        headingTitle: e?.exercise_title as string,
                        headingSubtitle: e?.primary_region as string,
                        detailsLine1: e?.tips_description as string,
                        detailsLine2: '',
                        headlines: [],
                        descriptions: [e?.tips_description as string],
                        date_created: value?.last_watched || value?.date_created,
                        type,
                        duration: value?.video_length ? value?.video_length : 0
                    }
                    exercisesVideos.push(data)
                }
                else if (type === "programs") {
                    const fpURL = featuredPrograms.find((x: any) => x.Program_ID === value?.program_id)?.Url
                    const data: Video = {
                        id: String(value?.program_id),
                        url: fpURL ? fpURL : value?.Program_Url,
                        // image: e.featured_img,
                        image: value.Featured_img,
                        category: "Program",
                        headingTitle: value?.Featured_Title,
                        headingSubtitle: "",
                        detailsLine1: "",
                        detailsLine2: '',
                        headlines: [],
                        descriptions: [],
                        date_created: value?.last_watched || value?.date_created,
                        type
                    }
                    exercisesVideos.push(data)
                }
                else if (type === "others") {
                    const val = recoverVideos.find(x => x.vimeo_id === String(value.workout_video_id))
                    if (val) {
                        const data: Video = {
                            id: String(val?.vimeo_id),
                            url: `/#/programs/runsmart-recover?videoId=${val?.vimeo_id}&redirect=favorites`,
                            // image: e.featured_img,
                            image: `https://vumbnail.com/${val?.vimeo_id}.jpg`,
                            category: "Recover",
                            headingTitle: val?.title as string,
                            headingSubtitle: "",
                            detailsLine1: "",
                            detailsLine2: '',
                            headlines: [],
                            descriptions: [],
                            date_created: value?.last_watched || value?.date_created,
                            type
                        }
                        exercisesVideos.push(data)
                    }
                }
                else if (type === "workouts") {
                    const v = allVideos[value.workout_video_id]
                    exercisesVideos.push(v)
                }
            })

            const list = type === "guides" ? exercisesVideos.filter(x => x.category === "Training Guide" || x.category === "Strength Guide")
                : type === "workouts" ? exercisesVideos?.filter(x => x && x.category !== "Training Guide" && x.category !== "Strength Guide") : exercisesVideos

            if (getAll) {
                return list
            } else {
                setNewFavorites(list)
                setFavorites(list)
            }
        }
    }

    const getFavorites = async () => {
        setIsLoading(true)
        setNewLoading(true)
        setNewFavorites(null)
        if (type === "exercises")
            await getExerciseFavorites({ user_id: userId, liked: 1 }).then((data) => {
                setIsLoading(false)
                handleSetFavorites(data, "exercises")
            })

        else if (type === "workouts")
            await getWorkoutsFavorites({ user_id: userId, liked: 1 }).then((data) => {
                setIsLoading(false)
                handleSetFavorites(data, "workouts")
            })
        else if (type === "others")
            await getWorkoutsFavorites({ user_id: userId, liked: 1 }).then((data) => {
                setIsLoading(false)
                handleSetFavorites(data, "others")
            })
        else if (type === "programs") {
            await getUserProgramFavorites().then((data) => {
                setIsLoading(false)
                handleSetFavorites(data.filter(x => x.favorited == 1), "programs")
            })
        } else if (type === 'all') {
            const favoritesVideos: Array<any> = []

            await getWorkoutsFavorites({ user_id: userId, liked: 1 }).then((data) => {
                const d = handleSetFavorites(data, "workouts", true)
                d?.length && favoritesVideos.push(...d)
            })

            await getExerciseFavorites({ user_id: userId, liked: 1 }).then((data) => {
                const d = handleSetFavorites(data, "exercises", true)
                d?.length && favoritesVideos.push(...d)
            })
            // await getWorkoutsFavorites({ user_id: userId, liked: 1 }).then((data) => {
            // 		const d = handleSetFavorites(data, "others", true)
            // 		d?.length && exercisesVideos.push(...d)
            // })
            // await getUserProgramFavorites().then((data) => {
            //     const d = handleSetFavorites(data.filter(x => x.favorited == 1), "programs", true)
            //     d?.length && favoritesVideos.push(...d)
            // })
            setIsLoading(false)
            setNewFavorites(favoritesVideos)
            setFavorites(favoritesVideos)
        }

    }

    const toggleFavorite = async (userId: number, itemId: number, isChecked: boolean) => {
        if (type === "exercises") {
            const exerciseId = ALL_EXERCISES.find(x => x.video_id === itemId)?.exercise_id
            const data = {
                user_id: `${userId}`,
                exercise_id: `${exerciseId}`,
                liked: !isChecked ? 0 : 1
            }
            const result: any = await likeExerciseVideo(data)
            if (result?.data?.messages === "success") {
                setMarkAsFavorite(!markAsFavorite)
            }
        }

        else if (type === "workouts" || type === "guides" || type === "others") {
            const data = {
                user_id: `${userId}`,
                workout_video_id: `${itemId}`,
                liked: !isChecked ? 0 : 1
            }
            const result: any = await likeWorkoutVideo(data)
            if (result?.data?.messages === "success") {
                setMarkAsFavorite(!markAsFavorite)
            }
        }
    }

    const getIsFavorited = async (itemId: number) => {
        let data: any;

        if (type === "exercises")
            data = favorites.find(x => x.id === String(itemId))
        else if (type === "workouts" || type === "others")
            data = favorites.find(x => x.id === String(itemId))

        // else if (type === "workouts")
        //     data =

        if (data)
            return true
        else
            return false
    }

    useEffect(() => {
        newFavorites !== null && setNewLoading(false)
    }, [newFavorites])

    useEffect(() => {
        if (requestData && userId) {
            getFavorites()
        }
    }, [userId, markAsFavorite, videoId, type, requestData])

    return { favorites, toggleFavorite, getIsFavorited, isLoading, getFavorites, newLoading }

}

export default useFavorites
