/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { FC, useState, useEffect, useRef } from 'react';
import Slider from 'react-slick'
import moment from 'moment'

import { Box } from '@mui/material'

export type WeekCalendarProps = {
	initialSlide: number,
	weekHeight: number,
	calendarOpen: boolean,
	list?: any
	weekList?: any,
	calendarDefault: number
	key?: any
}

const WeekCalendar: FC<WeekCalendarProps> = ({ initialSlide = 0, weekList, weekHeight, list, calendarOpen, calendarDefault, key }) => {
	let mouseDown = false
	const [isScrolling, setIsScrolling] = useState(false)
	const sliderRef = useRef(null)

	const handleMouseDown = () => {
		mouseDown = true
	}

	const handleMouseUp = (e: any) => {
		mouseDown = false
		const scrollTop = e?.currentTarget?.scrollTop || 0
		const scrollTo = Math.round(scrollTop / 80)
		if (scrollTop % 80) {
			e.currentTarget.scrollTo({ top: scrollTo * 80, behavior: "smooth" })
		}
		isScrolling && setIsScrolling(false)
	}

	const handleHighlight = (sTop: number) => {
		const el = document.getElementsByClassName('dark-box-cal') as HTMLCollectionOf<HTMLElement>

		if (el.length) {
			let onlyOne = false
			for (let i = 0; i < el.length; i++) {
				const item = el[i]
				const clientHeight = item.clientHeight
				const offsetTop = item.offsetTop
				const divPos = clientHeight + offsetTop
				const scrollHeight = sTop + calendarDefault
				let topSpace = offsetTop - sTop
				topSpace = topSpace < 0 ? topSpace : 0
				let botSpace = scrollHeight - divPos
				botSpace = botSpace < 0 ? botSpace : 0
				const remainingSpace = clientHeight + topSpace + botSpace
				if (item.firstChild) {
					const itemBox = item.firstChild as HTMLElement
					if (itemBox && itemBox?.style) {
						if ((clientHeight / 2) <= remainingSpace && !onlyOne) {
							onlyOne = true
							itemBox.style.backgroundColor = 'rgba(0,0,0,0.5)'
						} else
							itemBox.style.backgroundColor = 'rgba(0,0,0,0.3)'
					}
				}
			}
		}
	}

	const handleOnScroll = (e: any) => {
		handleHighlight(e.currentTarget.scrollTop || 0)
		if (mouseDown) {
			!isScrolling && setIsScrolling(true)
		}
	}

	useEffect(() => {
		const el = document.getElementById(`renderWeekCalendar-${initialSlide}`)
		const el2 = document.getElementById(`renderWeekCalendar-cont`)
		if (calendarOpen) {

			if (el?.style)
				el.style.transform = 'translateY(0px)'
			el2?.scrollTo({ top: 80 * initialSlide, behavior: "auto" })
		} else {
			if (el?.style)
				el.style.transform = `translateY(-${initialSlide * 80}px)`
			el2?.scrollTo({ top: 0, behavior: "auto" })
		}
	}, [calendarOpen])

	const renderMonths = () => {
		const newArr: Array<any> = []
		let month = ''
		list?.forEach((item: any, index: number) => {
			if (index) {
				if (index + 1 < list.length) {
					const monthData = moment(item?.replaceAll('-', '/')).format('MMMM')
					if (monthData !== month) {
						month = monthData
						newArr.push({ month, item: item?.replaceAll('-', '/'), index: index })
					}
				}
			} else {
				month = moment(item?.replaceAll('-', '/')).format('MMMM')
				newArr.push({ month, item: item?.replaceAll('-', '/'), index: index })
			}
		})

		const wg = (document.getElementsByClassName('item-calendar')?.[0]?.clientWidth || 0)
		const hh = weekHeight || 80
		const monthDiv = newArr.map((item: any, index: number) => {
			const mon = moment(item.item).diff(moment(`${moment(item?.item).format('YYYY/MM')}/01`), 'd')
			const nextMon = index !== newArr.length - 1 ? moment(newArr[index + 1]?.item).diff(moment(`${moment(newArr[index + 1]?.item).format('YYYY/MM')}/01`), 'd') : 0

			const weekDiff = mon > 0 && index ? 1 : 0
			const ht = newArr[index + 1]?.index || list.length
			const h = hh * ((ht - item.index) + weekDiff)
			const top = (item?.index - weekDiff) * hh

			const topX = mon && index ? `${((7-mon)*wg)+9}px` : '0%'
			const topY = mon && index ? `${hh}px` : '0%'
			const topPath = `0% ${topY}, ${topX} ${topY}, ${topX} 0%`

			const botX = nextMon ? `${((7-nextMon)*wg)+9}px` : '100%'
			const botY = nextMon ? `calc(100% - ${hh-0.5}px)` : '100%'
			const botPath = `100% ${botY}, ${botX} ${botY}, ${botX} 100%, 0% 100%`

			return <Box className='dark-box-cal' key={`monthDiv-${index}`} sx={{ height: h, transform: 'translateY(-2.5px)', width: '100%', position: 'absolute', textAlign: 'center', justifyContent: 'center', alignItems: 'center', display: 'flex', top: top, left: 0, zIndex: 1 }}>
				<Box className='transition-all' sx={{ position: 'absolute', top: 0, left: 0, width: '100%', height: '100%', clipPath: `polygon(${topPath}, 100% 0%, ${botPath})` }} />
				<Box sx={{ fontFamily: 'Poppins-Medium', fontSize: '30px', color: 'white', zIndex: 1 }}>{item.month}</Box>
			</Box>
		})
		return <>{monthDiv}</>
	}

	return <Box key={key} ref={sliderRef} id='renderWeekCalendar-cont' className={`transition-all-quarter disable-scrollbars no-overscroll`} onScroll={handleOnScroll} onMouseDown={handleMouseDown} onMouseUp={handleMouseUp} onTouchStart={handleMouseDown} onTouchEnd={handleMouseUp} sx={{ backgroundColor: 'white', overflowY: 'hidden', maxHeight: `${calendarDefault}px`, height: `${weekHeight}px` }} >
		<Box className='transition-n' id={`renderWeekCalendar-${initialSlide}`} sx={{ transform: `translateY(-${initialSlide * 80}px)`, position: 'relative' }}>
			{weekList}
			{calendarOpen && isScrolling && renderMonths()}
		</Box>
	</Box>
}

export default WeekCalendar
