import { useState, useEffect } from 'react'
import useSWR from 'swr';
import { isIOS } from 'react-device-detect'

import Grid from '@mui/material/Grid'
import Box from '@mui/material/Box'
import Input from '@mui/material/InputBase';
import { Portal } from '@mui/base/Portal';
import RSButton from '../components/buttons/RSButton'
import useIsMobileScreen from './useIsMobileScreen'

export type RSSearchProps = {
	programs?: boolean
	inverted?: boolean
}

const useRSSearch = ({inverted, programs}: RSSearchProps) => {
	const [showSearchbar, setShowSearchBar] = useState(false)
	const [inputHasFocus, setInputHasFocus] = useState(false)
	const [searchValue, setSearchValue] = useState('')
	const [result, setResult] = useState<Array<any> | undefined>()
	const [value, setValue] = useState('')
	const [translate, setTranslate] = useState(0)
	const url = programs ? '/programs' : '/exercises'
	const { data: searchData, isLoading } = useSWR(searchValue ? `${url}/search?title=${searchValue}` : undefined)
	const innerWidth = window.innerWidth

	const isMobile = useIsMobileScreen()

	const handleReset = () => {
		setValue('')
		setSearchValue('')
		setResult(undefined)
	}

	const handleSearch = (preventSearch?: any) => {
		showSearchbar && value && !preventSearch && setSearchValue(value)
		setShowSearchBar(!showSearchbar)
	}

	const handleEnter = (e: any) => {
	 	if(e.keyCode == 13){
	 		if (value === searchValue) {
	 			setResult(result ? [...result] : undefined)
	 			setShowSearchBar(!showSearchbar)
	 		} else handleSearch()

			document?.getElementById("search-button-rs")?.focus();
			e.preventDefault()
		}
	}

	const resizeHandler = () => {
		const { height } = window.visualViewport
		setTranslate( window.innerHeight - height - 80 )
	}


	useEffect(() => {
		window.visualViewport?.addEventListener("resize", resizeHandler);
		return () => {
			window.visualViewport?.addEventListener("resize", resizeHandler);
		}
	},[])

	useEffect(() => {
		const results = searchData?.data?.result
		setResult(results ? results : undefined)
	},[searchData])

	useEffect(() => {
		document.activeElement?.id !== 'search-button-rs' && !inputHasFocus && setShowSearchBar(false)
	}, [inputHasFocus])

	const renderSearchUI = () => (
		<Portal>
		<Grid className='default-mw' container
			sx={{width: '100%', height: 0, position: 'fixed', bottom: isMobile ? '140px' : '80px', px: 2, zIndex: 2, transform: `translateY(-${isIOS&&inputHasFocus?translate: 0}px)`, transition: 'transform 0.5s ease', left: innerWidth > 900 ? (innerWidth-900)/2 : 0}}
			justifyContent='right'
		>
			<Box sx={{display: 'flex', borderRadius: '50px', height: '60px', width: showSearchbar ? '100%' : '60px', minWidth: '60px', maxWidth: '400px', transition: 'width 0.5s ease', justifyContent: 'space-between', alignItems: 'center', backgroundColor: '#bfd38c', overflow: 'hidden', flexDirection: inverted ? 'row' : 'row-reverse'}}>
				<RSButton id='search-button-rs' onClick={()=>handleSearch(!inverted)} customized sx={{ borderRadius: '0px', height: '60px', width: '60px', minWidth: '60px', justifyContent: 'center'}}>
					<i className={`fa-regular ${showSearchbar && !inverted ? 'fa-x':'fa-magnifying-glass'}`} style={{fontSize: '20px'}}></i>
				</RSButton>
				{ showSearchbar &&
					<Input autoFocus placeholder="I'm searching for.."
						onBlur={()=>setInputHasFocus(false)}
						onFocus={()=>setInputHasFocus(true)}
						onKeyDown={handleEnter}
						onChange={(e) => setValue(e.target.value)}
						value={value}
						sx={{  width: 'calc(100% - 70px)', pl: inverted ? '0px' : '25px', borderRadius: '5px', height: '70%', backgroundColor: 'transparent', fontSize: '18px'}}/
					>
				}
			</Box>
		</Grid>
		</Portal>
	)

	return { searchUI: renderSearchUI(), searching: isLoading, result, searchValue, resetSearch: handleReset }
}

export default useRSSearch;
