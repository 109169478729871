/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { FC, useEffect, useState, useRef } from 'react'
import { useLocation } from 'react-router-dom'
import { Card, Col, Row } from 'react-bootstrap'
import { PrimaryButton } from '../../components/buttons/AuthSubmitButtons'
import AppMenus, { BodyComponent } from '../../components/layout/AppMenus'
import RelatedVideosRow from '../../components/page-specific/video/RelatedVideosRow'
import WithAuth from '../../components/WithAuth'
import exerciseActions from '../../modules/exerciseActions'
import { AuthPageProps } from '../../modules/types'
import { Video } from '../../modules/videos/allVideos'
import { IExerciseStore } from '../../store/createExerciseStore'
import useStore from '../../store/useStore'
import { Exercise } from './types'
import FilterIcon from '@mui/icons-material/FilterAltOutlined'
import SortIcon from '@mui/icons-material/SortOutlined'
import CloseIcon from '@mui/icons-material/CloseOutlined'
import { IconButton } from '../../components/buttons/IconButton'
import useScrollRow from '../../hooks/useScrollRow'
import ScrollRow from '../../components/layout/ScrollRow'
import Menu from '@mui/material/Menu'
import MenuItem from '@mui/material/MenuItem'
import Fade from '@mui/material/Fade'
import Box from '@mui/material/Box';
import {
	Checkbox,
	Divider,
	FormControlLabel,
	List,
	ListItem,
} from '@mui/material'
import BackButton from '../../components/buttons/BackButton'
import PageTitle from '../../components/text/PageTitle'
import Motion from '../../components/animation/Motion'
import MainCard from '../../components/cards/MainCard'
import Loader from '../../components/animation/Loader'
import RSNavCont from '../../components/layout/RSNavCont'
import useViewAll from './useViewAll'
import allExercises from '../../modules/videos/allNewExercises.json'
import useFavorites from '../favorites/useFavorites'
import {
	slideInRight,
	slideOutRight,
	slideInLeft,
	slideOutLeft,
	fadeIn,
} from 'react-animations'
import styled, { keyframes } from 'styled-components'
import useIsMobileScreen from '../../hooks/useIsMobileScreen'
import useVideoProgress from '../../hooks/useVideoProgress'

// Exercises Props
export type ExerciseTypeProps = AuthPageProps

const AslideInLeft = keyframes`${slideInLeft}`
const AslideInRight = keyframes`${slideInRight}`

const AslideOutLeft = keyframes`${slideOutLeft}`
const AslideOutRight = keyframes`${slideOutRight}`

const FadeIn = keyframes`${fadeIn}`

const DivFadeIn = styled.div`
	animation: 0.2s ${FadeIn};
`
const DivInLeft = styled.div`
	animation: 0.2s ${AslideInLeft};
`
const DivInRight = styled.div`
	animation: 0.3s ${AslideInRight};
`

const DivOutLeft = styled.div`
	animation: 0.3s ${AslideOutLeft};
`
const DivOutRight = styled.div`
	animation: 0.3s ${AslideOutRight};
`

const defaultOptions = ['most popular']
const exerciseTypeOptions = [
	'balance',
	'mobility',
	'strength',
	'stretch',
	'drill',
]
const regionOptions = [
	'back',
	'core',
	'foot and ankle',
	'hip',
	'knee',
	'trunk',
	'run form',
]
const sortOptions = ['easy to hard', 'hard to easy']

const ExercisesMain: FC<ExerciseTypeProps> = ({ userInfo }) => {
	const { isScrolling, setIsScrolling } = useScrollRow()
	const location = useLocation()

	const mobileScreen = useIsMobileScreen()
	const slideDirection = useRef({slide: localStorage.getItem('page') === 'pw'? 'right' : ''})

	const {
		getAllExercises,
		getTrendingExercises,
		getPopularExercises,
		getLastWatchedData,
	} = exerciseActions()
	const {
		// setAllExercises,
		// allExercises,
		trendingExercises,
		popularExercises,
		userWatchData,
		setUserWatchData,
		setAllFilteredVideos,
		allFilteredVideos,
		setActiveFilter,
		activeFilter,
		setSelectedFilter,
		selectedFilter,
		isLoadingExercises: isLoading,
	} = useStore((state: IExerciseStore) => state)
	const [allVideos, setAllVideos] = useState<Array<Video>>([])
	const [lastWatchedVideos, setLastWatchedVideos] = useState<Array<Video>>([])
	const [trendingVideos, setTrendingVideos] = useState<Array<Video>>([])
	const [popularVideos, setPopularVideos] = useState<Array<Video>>([])
	const [filteredVideos, setFilteredVideos] = useState<Array<Video>>([])
	// const [selectedFilter, setSelectedFilter] = useState<Array<string>>(['all'])
	// const [activeFilter, setActiveFilter] = useState<Array<string>>(['all'])

	const [showFilter, setShowFilter] = useState(false)
	const [isLoadingLastWatched, setIsLoadingLastWatched] = useState(true)
	const [isTrendingLoading, setIsTrendingLoading] = useState(true)

	const [showAll, setShowAll] = useState(false)

	const { resultVideos } = useViewAll(filteredVideos as Video[], showAll)

	const [anchorElFilter, setAnchorElFilter] = React.useState<
		HTMLElement | undefined
	>()
	const [anchorElSort, setAnchorElSort] = React.useState<
		HTMLElement | undefined
	>()

	const openFilter = Boolean(anchorElFilter)
	const openSort = Boolean(anchorElSort)

	const { favorites, toggleFavorite, getIsFavorited } = useFavorites(
		userInfo?.account_id as number,
		undefined,
		'exercises',
		true
	)

	const handleClickOpenFilter = (event: React.MouseEvent<HTMLElement>) => {
		setAnchorElFilter(event.currentTarget)
	}
	const handleClose = () => {
		setAnchorElFilter(undefined)
	}

	const handleClickOpenSort = (event: React.MouseEvent<HTMLElement>) => {
		setAnchorElSort(event.currentTarget)
	}
	const handleCloseSort = () => {
		setAnchorElSort(undefined)
	}

	// TO-DO: On Like
	const onLike = async (videoId: string) => {
		const isLiked =
			favorites && favorites.find((x: any) => x.id === videoId) ? true : false
		await toggleFavorite(
			userInfo?.account_id as number,
			Number(videoId),
			!isLiked
		)
	}

	const getUserLastWatchedData = async () => {
		const lastWatchParams = {
			user_id: userInfo?.account_id,
			limit: 8,
		}
		await getLastWatchedData(lastWatchParams)
		setIsLoadingLastWatched(false)
	}

	const initializeExercisesData = async (signal: AbortSignal) => {
		await getAllExercises()

		const trendingParams = {
			'v.video_type': 'exercise',
			limit: 8,
		}

		if (allFilteredVideos?.length == 0) {
			await getTrendingExercises(trendingParams, signal, true)
			setIsTrendingLoading(false)
		}

		getTrendingExercises(trendingParams, signal, false).then(
			(res: Exercise[]) => {
				const videos = parseVideoList(res as Exercise[])
				setTrendingVideos(videos)
				setIsTrendingLoading(false)
			}
		)

		getPopularExercises(trendingParams, signal, false).then(
			(res: Exercise[]) => {
				const videos = parseVideoList(res as Exercise[])
				setPopularVideos(videos)
				setIsTrendingLoading(false)
			}
		)
	}

	const getTrendingExercisesData = async (
		params: any,
		updateStore: boolean
	) => {
		await getTrendingExercises(params, undefined, updateStore)
	}

	const getPopularExerciseData = async (params: any) => {
		await getPopularExercises(params)
	}

	const parseVideoList = (exercises: Exercise[]) => {
		const videos: Video[] = []
		exercises?.forEach((e) => {
			const data: Video = {
				id: String(e?.video_id),
				url: '/#/exercises/' + e?.video_id,
				// image: e.featured_img,
				image: `https://vumbnail.com/${e?.video_id}.jpg`,
				category: e?.exercise_type,
				headingTitle: e?.exercise_title,
				headingSubtitle: e?.primary_region,
				detailsLine1: e?.tips_description,
				detailsLine2: '',
				headlines: [],
				descriptions: [e?.tips_description],
			}
			videos.push(data)
		})

		return videos
	}

	const checkIsActive = (filter: string) => {
		const isActive = activeFilter.find((x) => x === filter)
		if (isActive) return true
		else return false
	}

	const handleSetActiveFilter = (filter: string) => {
		const isActive = activeFilter.find((x) => x === filter)
		if (filter === 'all') setActiveFilter([filter])
		else if (!isActive)
			setActiveFilter([...activeFilter.filter((x) => x !== 'all'), filter])
		else setActiveFilter(activeFilter.filter((x) => x !== filter))
	}

	const handleSetSelectedFilter = (filter: string) => {
		const isSelected = selectedFilter.find((x) => x === filter)

		if (!isSelected) {
			if (filter === 'easy to hard') {
				setSelectedFilter([
					...selectedFilter.filter((x) => x !== 'hard to easy'),
					filter,
				])
				setActiveFilter([
					...activeFilter.filter((x) => x !== 'hard to easy'),
					filter,
				])
			} else if (filter === 'hard to easy') {
				setSelectedFilter([
					...selectedFilter.filter((x) => x !== 'easy to hard'),
					filter,
				])
				setActiveFilter([
					...activeFilter.filter((x) => x !== 'easy to hard'),
					filter,
				])
			} else setSelectedFilter([...selectedFilter, filter])

			setActiveFilter([...activeFilter.filter((x) => x !== 'all'), filter])
		} else {
			setSelectedFilter(selectedFilter.filter((x) => x !== filter))
			if (checkIsActive(filter))
				setActiveFilter(activeFilter.filter((x) => x !== filter))
		}
	}

	const checkIsSelected = (filter: string) => {
		const isSelected = selectedFilter.find((x) => x === filter)
		if (isSelected) return true
		else return false
	}

	const handleRemoveFilter = (filter: string) => {
		// if (filter !== "all") {
		setSelectedFilter(selectedFilter.filter((x) => x !== filter))
		if (checkIsActive(filter))
			setActiveFilter(activeFilter.filter((x) => x !== filter))
		// }
	}

	const buildExerciseTypeParams = (activeFilter: string[]) => {
		const activeTypeParams: string[] = []
		exerciseTypeOptions.forEach((value, index) => {
			const val = activeFilter.find((x) => x === value)
			if (val) activeTypeParams.push(`'${val}'`)
		})
		const value =
			activeTypeParams.length == 1
				? activeTypeParams[0].replace(/['‘’"“”]/g, '')
				: activeTypeParams
		return value
	}

	const buildExerciseRegionsParams = (activeFilter: string[]) => {
		const activeTypeParams: string[] = []
		regionOptions.forEach((value, index) => {
			const val = activeFilter.find((x) => x === value)
			if (val) activeTypeParams.push(`'${val}'`)
		})
		const value =
			activeTypeParams.length == 1
				? activeTypeParams[0].replace(/['‘’"“”]/g, '')
				: activeTypeParams
		return value
	}

	useEffect(() => {
		const hasMostPopular = activeFilter.find((x) => x === 'most popular')
		const params = buildExerciseTypeParams(activeFilter)
		const paramsRegion = buildExerciseRegionsParams(activeFilter)
		const requestParams = {
			'v.video_type': 'exercise',
			// limit: 8
		}
		if (selectedFilter.length > 1) setShowFilter(true)
		else setShowFilter(false)

		if (params.length != 0)
			Object.assign(requestParams, { 'e.exercise_type': params })

		if (paramsRegion.length != 0) {
			Object.assign(requestParams, { 'e.primary_region': paramsRegion })
			Object.assign(requestParams, { 'e.secondary_region': paramsRegion })
		}

		setShowAll(false)
		getTrendingExercisesData(requestParams, true)

		// if (activeFilter.length == 0) {
		//     const trendingParams = {
		//         'v.video_type': 'exercise',
		//         limit: 8,
		//     }
		//     setActiveFilter(['all'])
		//     getTrendingExercisesData(trendingParams)
		// }

		// if (hasMostPopular)
		//     getPopularExerciseData(requestParams)
		// else
	}, [activeFilter])

	useEffect(() => {
		const lastWatchData: Exercise[] = []
		if (userWatchData && userWatchData?.length > 0) {
			userWatchData?.forEach((value) => {
				lastWatchData.push(
					allExercises
						?.filter((x) => x.video_type === 'exercise')
						?.find(
							(x) => x.exercise_id == value?.exercise_id
						) as unknown as Exercise
				)
			})

			const videos = parseVideoList(lastWatchData as Exercise[])
			setLastWatchedVideos(videos)
		}
	}, [userWatchData])

	useEffect(() => {
		if (selectedFilter.length == 0) setSelectedFilter(['all'])
	}, [selectedFilter])

	useEffect(() => {
		const videos = parseVideoList(allExercises as unknown as Exercise[])
		setAllVideos(videos)
	}, [allExercises])

	useEffect(() => {
		const hasEasyToHardSort = activeFilter?.find((x) => x === 'easy to hard')
		const hasHardToEasy = activeFilter?.find((x) => x === 'hard to easy')

		hasEasyToHardSort
			? popularExercises?.sort(
					(a, b) => a.difficulty_score - b.difficulty_score
			  )
			: hasHardToEasy
			? popularExercises?.sort(
					(b, a) => a.difficulty_score - b.difficulty_score
			  )
			: popularExercises

		const videos = parseVideoList(popularExercises as Exercise[])
		setFilteredVideos(videos)
		setAllFilteredVideos(videos)
		setPopularVideos(videos)
	}, [popularExercises])

	useEffect(() => {
		const hasEasyToHardSort = activeFilter?.find((x) => x === 'easy to hard')
		const hasHardToEasy = activeFilter?.find((x) => x === 'hard to easy')

		hasEasyToHardSort
			? trendingExercises?.sort(
					(a, b) => a.difficulty_score - b.difficulty_score
			  )
			: hasHardToEasy
			? trendingExercises?.sort(
					(b, a) => a.difficulty_score - b.difficulty_score
			  )
			: trendingExercises

		const videos = parseVideoList(trendingExercises as Exercise[])
		setFilteredVideos(videos)
		setAllFilteredVideos(videos)
		if (trendingVideos.length == 0) {
			const videos = parseVideoList(trendingExercises as Exercise[])
			setTrendingVideos(videos)
		}
	}, [trendingExercises])

	// useEffect(() => {
	//     if (trendingVideos.length == 0) {
	//         const videos = parseVideoList(trendingExercises as Exercise[])
	//         setTrendingVideos(videos)
	//     }
	// }, [trendingExercises])

	useEffect(() => {
		if (userInfo?.account_id) {
			getUserLastWatchedData()
		}
	}, [userInfo])

	useEffect(() => {
		if (activeFilter.length > 0 && activeFilter[0] !== 'all') {
			setShowFilter(true)
		}
	}, [userInfo])

	// useEffect(() => {
	//     console.log(filteredVideos)
	// }, [filteredVideos])

	useEffect(() => {
		const abortController = new AbortController()
		initializeExercisesData(abortController.signal)
		return () => {
			setUserWatchData(undefined)
			abortController.abort()
		}
	}, [])

	const PageContainer = () => {
		return (
			<Box sx={{pt: 3}}>
				<h1 className="d-none d-md-block">Exercises</h1>

				<div className="filter-button-container">
					<span
						className="button-container"
						id="filter-button"
						aria-controls={openFilter ? 'fade-menu' : undefined}
						aria-haspopup="true"
						aria-expanded={openFilter ? 'true' : undefined}
						onClick={!isLoading ? handleClickOpenFilter : undefined}
					>
						<IconButton
							primaryText={'Filter'}
							primaryAction={() => {
								null
							}}
							icon={<FilterIcon />}
							primaryDisabled={isLoading}
						/>
					</span>

					<Menu
						id="filter-menu"
						MenuListProps={{
							'aria-labelledby': 'filter-button',
						}}
						anchorEl={anchorElFilter}
						open={openFilter}
						onClose={handleClose}
						TransitionComponent={Fade}
						// onPointerMove={handleClose}
						onTouchMove={handleClose}
					>
						{/* <MenuItem className="py-0">
															{defaultOptions.map((value, index) => (
																	<FormControlLabel key={index} control={<Checkbox checked={checkIsSelected(value)} disabled={isLoading} onClick={() => handleSetSelectedFilter(value)} />} label={value.toUpperCase()} />
															))}
													</MenuItem> */}
						<h5 className="fc-secondary mt-2">TYPE</h5>
						<List>
							{exerciseTypeOptions.map((value, index) => (
								<ListItem className="py-0 mb-n3" key={index}>
									<FormControlLabel
										control={
											<Checkbox
												checked={checkIsSelected(value)}
												disabled={isLoading}
												onClick={() => handleSetSelectedFilter(value)}
											/>
										}
										label={value.toUpperCase()}
									/>
								</ListItem>
							))}
						</List>
						<h5 className="fc-secondary mt-2">REGIONS</h5>
						<List>
							{regionOptions.map((value, index) => (
								<ListItem className="py-0 mb-n3" key={index}>
									<FormControlLabel
										control={
											<Checkbox
												className="filter-cb"
												checked={checkIsSelected(value)}
												disabled={isLoading}
												onClick={() => handleSetSelectedFilter(value)}
											/>
										}
										label={value.toUpperCase()}
									/>
								</ListItem>
							))}
						</List>
					</Menu>

					<span
						className="button-container"
						id="sort-button"
						aria-controls={openSort ? 'fade-menu' : undefined}
						aria-haspopup="true"
						aria-expanded={openSort ? 'true' : undefined}
						onClick={!isLoading ? handleClickOpenSort : undefined}
					>
						<IconButton
							primaryText={'Sort'}
							primaryAction={() => {
								null
							}}
							icon={<SortIcon />}
						/>
					</span>

					<Menu
						id="sort-menu"
						MenuListProps={{
							'aria-labelledby': 'sort-button',
						}}
						anchorEl={anchorElSort}
						open={openSort}
						onClose={handleCloseSort}
						TransitionComponent={Fade}
						onTouchMove={handleClose}
					>
						<List>
							{sortOptions.map((value, index) => (
								<ListItem className="py-0 mb-n3" key={index}>
									<FormControlLabel
										control={
											<Checkbox
												checked={checkIsSelected(value)}
												disabled={isLoading || selectedFilter.length == 1}
												onClick={() => handleSetSelectedFilter(value)}
											/>
										}
										label={value.toUpperCase()}
									/>
								</ListItem>
							))}
						</List>
					</Menu>
				</div>

				{showFilter ? (
					<>
						<div className="filtered-row">
							<ScrollRow setIsScrolling={setIsScrolling}>
								{selectedFilter.map((value, index) => (
									<IconButton
										key={index}
										primaryText={value.toUpperCase()}
										primaryAction={() => handleSetActiveFilter(value)}
										secondaryAction={() => handleRemoveFilter(value)}
										icon={<CloseIcon />}
										isOption
										isSelected={checkIsActive(value)}
									/>
								))}
							</ScrollRow>
							<div className="mt-n2 d-none d-sm-none d-md-block">
								<RelatedVideosRow
									title={''}
									introSubtitleText={''}
									videos={filteredVideos}
									userInfo={userInfo}
									rowType="exercises"
									hasLikeButtons={true}
									onLike={onLike}
									favorites={favorites}
								/>
							</div>

							{resultVideos?.map((video) => (
								<Row
									className="mt-n2 justify-content-center d-sm-none d-md-none"
									key={video.id}
								>
									<Col className="justify-content-center phone-video-list">
										<MainCard
											videoId={video.id}
											cardType="video"
											type="videoLgStretch"
											hasCheck={false}
											progress={0}
											progressPercent={0}
											logoImage={video.image}
											detailTitle={video.headingTitle}
											detailSubtitle={video.category.toUpperCase()}
											link={video.url}
											onLike={() => onLike(video.id)}
											hasLikeButton={true}
											favorites={favorites}
											isProgram={true}
										/>
									</Col>
								</Row>
							))}

							{!showAll && (filteredVideos?.length as number) > 5 ? (
								<Row className="text-end d-sm-block d-md-none">
									<a
										onClick={() => setShowAll(true)}
										target={'blank'}
										className="text-secondary pointer mt-1 mx-n3"
									>
										<u>View All</u>
									</a>{' '}
								</Row>
							) : undefined}
						</div>
					</>
				) : undefined}

				<div className="mt-4">
					{lastWatchedVideos?.length > 0 ? (
						<ScrollRow setIsScrolling={setIsScrolling}>
							<IconButton
								primaryText={'LAST WATCHED'}
								primaryAction={() => null}
								secondaryAction={() => null}
								icon={<CloseIcon color="disabled" />}
								isOption
							/>
						</ScrollRow>
					) : undefined}

					<div className="mt-n4 mx-3">
						{isLoadingLastWatched ? (
							<ScrollRow setIsScrolling={setIsScrolling}>
								{[1, 2, 3, 4].map((value) => (
									<div
										key={value}
										className="card bg-transparent border-0 mt-n3 mx-n3 mr-3"
										aria-hidden="true"
									>
										<div className="card-body">
											<h5 className="card-title placeholder-glow">
												<span className="placeholder placeholder-video"></span>
											</h5>
											<p className="card-text placeholder-glow">
												<span className="placeholder col-6"></span>
											</p>
										</div>
									</div>
								))}
							</ScrollRow>
						) : undefined}

						{lastWatchedVideos?.length > 0 && !isLoadingLastWatched ? (
							<RelatedVideosRow
								title={''}
								introSubtitleText={''}
								videos={lastWatchedVideos}
								userInfo={userInfo}
								rowType="exercises"
								hasLikeButtons={true}
								onLike={onLike}
								favorites={favorites}
							/>
						) : undefined}
					</div>
				</div>

				<div className="mt-4">
					<ScrollRow setIsScrolling={setIsScrolling}>
						<IconButton
							primaryText={'TRENDING'}
							primaryAction={() => null}
							secondaryAction={() => null}
							icon={<CloseIcon color="disabled" />}
							isOption
						/>
					</ScrollRow>
					<div className="mt-n4 mx-3">
						{trendingVideos?.length > 0 && !isTrendingLoading ? (
							<RelatedVideosRow
								title={''}
								introSubtitleText={''}
								videos={trendingVideos}
								userInfo={userInfo}
								rowType="exercises"
								hasLikeButtons={true}
								onLike={onLike}
								favorites={favorites}
							/>
						) : undefined}
						{isTrendingLoading ? (
							<ScrollRow setIsScrolling={setIsScrolling}>
								{[1, 2, 3, 4].map((value) => (
									<div
										key={value}
										className="card bg-transparent border-0 mt-n3 mx-n3 mr-3"
										aria-hidden="true"
									>
										<div className="card-body">
											<h5 className="card-title placeholder-glow">
												<span className="placeholder placeholder-video"></span>
											</h5>
											<p className="card-text placeholder-glow">
												<span className="placeholder col-6"></span>
											</p>
										</div>
									</div>
								))}
							</ScrollRow>
						) : undefined}
					</div>
				</div>

				<div className="mt-4">
					<ScrollRow setIsScrolling={setIsScrolling}>
						<IconButton
							primaryText={'MOST FAVORITED'}
							primaryAction={() => null}
							secondaryAction={() => null}
							icon={<CloseIcon color="disabled" />}
							isOption
						/>
					</ScrollRow>
					<div className="mt-n4 mx-3">
						{popularVideos?.length > 0 && !isTrendingLoading ? (
							<RelatedVideosRow
								title={''}
								introSubtitleText={''}
								videos={popularVideos}
								userInfo={userInfo}
								rowType="exercises"
								hasLikeButtons={true}
								onLike={onLike}
								favorites={favorites}
							/>
						) : undefined}
						{isTrendingLoading ? (
							<ScrollRow setIsScrolling={setIsScrolling}>
								{[1, 2, 3, 4].map((value) => (
									<div
										key={value}
										className="card bg-transparent border-0 mt-n3 mx-n3 mr-3"
										aria-hidden="true"
									>
										<div className="card-body">
											<h5 className="card-title placeholder-glow">
												<span className="placeholder placeholder-video"></span>
											</h5>
											<p className="card-text placeholder-glow">
												<span className="placeholder col-6"></span>
											</p>
										</div>
									</div>
								))}
							</ScrollRow>
						) : undefined}
					</div>
				</div>

				{/* <div className='mt-4'>
                    <ScrollRow setIsScrolling={setIsScrolling}>
                        <IconButton
                            primaryText={'MOST FAVORITED'}
                            primaryAction={() => null}
                            secondaryAction={() => null}
                            icon={<CloseIcon />}
                            isOption

                        />
                    </ScrollRow>
                    <div className='mt-n4'>
                        <RelatedVideosRow
                            title={''}
                            introSubtitleText={''}
                            videos={popularVideos}
                            userInfo={userInfo}
                            rowType='exercises'
                        />
                    </div>
                </div> */}
			</Box>
		)
	}

	return (
		<RSNavCont>
			<Motion
				isVisible={true}
				isMobile={mobileScreen}
				direction={slideDirection.current}
				clear={true}
			>
				<>
					<Loader active={isTrendingLoading || isLoading || isLoadingLastWatched} isBehindHeader/>
					<PageContainer />
				</>
			</Motion>
		</RSNavCont>
	)
}

export default WithAuth(ExercisesMain)
