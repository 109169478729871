import { FC } from 'react'
import { MISC_LOGOS } from '../../modules/miscImages'

// Props
export type ImageLogoProps = {
	type?: keyof typeof MISC_LOGOS
	image?: string
	size?: string | number
	width?: string | number
	height?: string | number
	hasBorderRadius?: boolean
	hasInfo?: boolean
	hasCustomWidth?: boolean
}

/**
 * ImageLogo Component
 */

const ImageLogo: FC<ImageLogoProps> = ({
	type,
	image,
	size,
	width,
	height,
	hasBorderRadius,
	hasInfo,
	hasCustomWidth
}) => {
	// Image Size
	const imgWidth = width || size || undefined
	const imgHeight = height || size || undefined
	const src = image || MISC_LOGOS[type as keyof typeof MISC_LOGOS] || undefined

	// Render
	return (
		<>
			{
				hasInfo ? (
					<div>
						{/* <h5 className="heading-meta-data text-white fw-regular">
							NEW!
						</h5> */}
						<img style={{ borderRadius: `${hasBorderRadius ? '5px' : '0'}`, marginRight: `${hasBorderRadius ? '5px' : '0'} `, width: 'auto' }} src={src} height={imgHeight} />
					</div >
				) : hasCustomWidth ? (
					<img style={{ width: imgWidth, height: imgHeight }} src={src} width={imgWidth} height={imgHeight} />

				) : <img src={src} width={imgWidth} height={imgHeight} />
			}
		</>
	)
}

export default ImageLogo
