/* eslint-disable @typescript-eslint/no-unused-vars */
import MultiLineText from '../text/MultiLineText'
import Card from 'react-bootstrap/Card'
import Button from 'react-bootstrap/Button'
import classNames from 'classnames'
import lineClamp from '../../modules/lineClamp'
import { FC, useState, useEffect, useRef } from 'react'

// Props
export type TextCardProps = {
	id?: string
	title?: string
	text?: string
	noBackground?: boolean
	noBorder?: boolean
	isCentered?: boolean
	size?: 'md' | 'lg' | 'xl' | 'fit'
	className?: string,
	autoHeight?: boolean,
	smallTitle?: boolean
}

// Button Options
const BUTTON_OPTIONS = ['Read More', 'Close']

// Max Height
const MAX_HEIGHT = 402.09

/**
 * TextCard Component
 */

const TextCard: FC<TextCardProps> = ({
	id,
	title,
	text,
	noBackground,
	isCentered,
	children,
	size,
	className,
	autoHeight,
	smallTitle,
	noBorder
}) => {
	// State
	const [curKey, setCurKey] = useState(0)
	const [isClamped, setIsClamped] = useState(false)
	const [isExpanded, setIsExpanded] = useState(true)
	const [buttonText, setButtonText] = useState(BUTTON_OPTIONS[0])

	// Properties
	const hasMultiLineText = text ? true : false
	const cardSize = size || 'md'
	const sizeSuffix = cardSize == 'md' ? '' : `-${cardSize}`
	const cardClassName = classNames(
		`responsive-text-card${sizeSuffix}`,
		noBackground ? 'bg-light border-0' : noBorder ? 'border-0 h-100' : undefined,
		isClamped || isExpanded || autoHeight ? 'card-no-aspect' : undefined
	)
	const selector = className ? `.${className}.line-clamp` : '.line-clamp'
	const textClassName = classNames(
		'line-clamp',
		className,
		isCentered ? 'vertical-center-container' : undefined
	)

	// Refs
	const curKeyRef = useRef(0)
	curKeyRef.current = curKey

	// On Toggle Expanded
	const onToggleExpanded = () => {
		const newExpandedState = !isExpanded
		const textIndex = newExpandedState ? 1 : 0
		const newText = BUTTON_OPTIONS[textIndex]
		setIsExpanded(newExpandedState)
		setButtonText(newText)
		incrementKey()
	}

	// Clamp Lines
	const clampLines = () => {
		// Expand Classes
		const curElement = document.querySelector(selector)
		if (isExpanded) curElement?.classList.add('is-expanded')
		else curElement?.classList.remove('is-expanded')

		// Line Clamping
		if (!isExpanded && curElement?.textContent && curElement?.scrollHeight) {
			const hasEllipses = curElement.textContent.endsWith('…')
			const newIsClamped: boolean = hasEllipses
				? true
				: lineClamp(curElement, 12)
			if (newIsClamped) curElement?.classList.add('is-clamped')
			else curElement?.classList.remove('is-clamped')
			if (newIsClamped != isClamped) setIsClamped(newIsClamped)
		}
	}

	// Increment Key
	const incrementKey = () => {
		setCurKey(curKeyRef.current + 1)
	}

	// Expanded Change Effect
	useEffect(() => {
		if (autoHeight) {
			setIsExpanded(true)
		}
	}, [autoHeight])

	// Expanded Change Effect
	useEffect(() => {
		clampLines()
	}, [curKey])

	// Text Change Effect
	useEffect(() => {
		incrementKey()
	}, [text, children])

	// Render
	return (
		<Card
			id={id}
			className={cardClassName}
			style={isExpanded || autoHeight ? { height: '100%' } : { maxHeight: MAX_HEIGHT }}
		>
			<Card.Body className={className}>
				{(title ? true : false) && <Card.Title className={`${smallTitle ? 'mb-n2' : undefined}`} as={`${smallTitle ? 'h4' : 'h3'}`}>{title}</Card.Title>}
				<Card.Text as="div" key={curKey} className={textClassName}>
					<div>
						{/* Normal Content */}
						{hasMultiLineText ? (
							<MultiLineText text={text as string} />
						) : (
							children
						)}
					</div>
				</Card.Text>

				{/* Expand Button */}
				{/* {(isClamped || isExpanded && !autoHeight) && (
					<Button
						variant="dark"
						className="toggle-card-button rounded-pill color-primary-bg"
						onClick={onToggleExpanded}
					>
						{buttonText}
					</Button>
				)} */}
			</Card.Body>
		</Card>
	)
}

export default TextCard
