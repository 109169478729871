/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { FC, useEffect, useState } from 'react'
import { Col, Modal, Row } from 'react-bootstrap'
import { useLocation } from 'react-router-dom'
import WithAuth from '../../components/WithAuth'
import useQueryParams from '../../hooks/useQueryParams'
import { navReq } from '../../modules/apiConsume'
import { AuthPageProps } from '../../modules/types'
import { ICurrentUser } from '../../store/createAccountStore'
import { IComponentStore } from '../../store/createComponentStore'
import useStore from '../../store/useStore'
import useSurveyActions from './useSurveyActions'

interface AnnouncementPopupProps {
    showPopup: boolean,
    // closePopup: () => any
}

const SurveyPopup: FC<AnnouncementPopupProps> = ({ showPopup }) => {

    const { currentUser } = useStore((state: ICurrentUser) => state)
    const { offer } = useQueryParams()
    const url = useLocation()
    const [show, setShow] = useState(false)
    const [surveyType, setSurveyType] = useState<string | undefined>()

    const { userSurveyDetails, isLoading: isLoadingSurvey } = useSurveyActions(currentUser?.account_id)

    const closePopup = () => {
        setShow(false)
        localStorage.setItem("surveyType", "skipped")
        setSurveyType("skipped")
    }

    const handleStartSurvey = () => {
        localStorage.setItem("surveyType", "profile_update")
        setSurveyType("profile_update")
        navReq(`/#/onboarding-survey/questions?seq=1${offer ? '&offer=' + offer : ''}`)
    }

    useEffect(() => {
        const SURVEY_TYPE = localStorage.getItem("surveyType") || undefined
        if (url.pathname !== "/start")
            setShow(currentUser?.account_id && SURVEY_TYPE && SURVEY_TYPE === "exist_user_survey" ? true : false)
    }, [surveyType])

    useEffect(() => {
        const surveyType = localStorage.getItem("surveyType") || undefined
        if (!isLoadingSurvey && currentUser?.account_id && !userSurveyDetails)
            if (!surveyType) {
                localStorage.setItem('surveyType', 'exist_user_survey')
                setSurveyType("exist_user_survey")
            }
            else if (surveyType && surveyType !== "skipped" && surveyType !== "profile_update" && surveyType !== "new_account") {
                localStorage.setItem('surveyType', 'exist_user_survey')
                setSurveyType("exist_user_survey")
            }
    }, [isLoadingSurvey])


    return (
        <Modal
            size="lg"
            className="announcement-modal"
            show={show}
            // show={showPopup}
            onHide={closePopup}
            backdrop='static'
            keyboard={false}
            centered
        >
            <Modal.Header closeButton={true}>

            </Modal.Header>
            <Modal.Body>
                <Row style={{ minHeight: '400px' }}>
                    <Col xs={12} sm={12} md={12}>
                        <div className={'survey-form-container'}>
                            <div className="content center-content p-4">
                                <h1 className='headline'>Update Your Profile</h1>
                                <p className='subhead'>To receive recommendations tailored to your running goals, complete this short survey.</p>
                                <button className='start-btn' onClick={handleStartSurvey}>Start Survey</button>
                                <a className='skip-link pointer' onClick={closePopup}>Skip</a>
                            </div>
                        </div>
                    </Col>
                </Row>
            </Modal.Body>
        </Modal >
    )
}

export default WithAuth(SurveyPopup, true)