import React, { FC } from 'react'
import { Card, Col, Row } from 'react-bootstrap'
import Arrow from '../../components/logos/Arrow'
import { navReq } from '../../modules/apiConsume'
// import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';

interface NextStepProps {
    title: string,
    url: string
}
const NextSteps: FC<NextStepProps> = ({ title, url }) => {
    return (
        <Card onClick={() => navReq(url)} className='program-head-link-steps mx-2 mt-3 mt-lg-2 border-0 p-4 pb-1 p-lg-5 pb-lg-3 pt-lg-4 pointer'>
            <Row className='program-head-link-steps'>
                <Col xs={10}>
                    <p className='pt-md-1'><b>{title}</b></p>
                </Col>
                <Col className='arrow-forward-icon-container mt-md-0 ' xs={2}>
                    {/* <ArrowForwardIosIcon className='mt-n2 mt-lg-0' fontSize='large' htmlColor='#9fcd2b' /> */}
                    <Arrow />
                </Col>
            </Row>
        </Card>
    )
}

export default NextSteps