/* eslint-disable @typescript-eslint/no-unused-vars */

import Button from 'react-bootstrap/Button'
import { FC, useState } from 'react'
// import ApplePayButton from '../buttons/ApplePayButton'
import GooglePayButton from '../buttons/GooglePayButton'
import { GenericFormProps } from '../forms/GenericForm'
import { isRequired } from '../../modules/validation'
import Drawer from '@mui/material/Drawer'
import Modal from '@mui/material/Modal'
import Box from '@mui/material/Box'
import Grid from '@mui/material/Grid'

import { ArrowBackIos } from '@mui/icons-material'
import useIsMobileScreen from '../../hooks/useIsMobileScreen'

export const inputProps: GenericFormProps['inputs'] = [
	{
		id: 'first-input',
		placeholder: 'First Name',
		label: 'First Name',
		formKey: 'firstName',
		recurlyData: 'first_name',
		validations: [isRequired],
	},
	{
		id: 'last-input',
		placeholder: 'Last Name',
		label: 'Last Name',
		formKey: 'lastName',
		recurlyData: 'last_name',
		validations: [isRequired],
	},
	{
		id: 'zipcode-input',
		placeholder: 'Postal Code',
		label: 'Postal Code',
		formKey: 'zipCode',
		recurlyData: 'postal_code',
		validations: [isRequired],
	},
	{
		id: 'cc-input',
		placeholder: 'Credit Card',
		label: 'Credit Card',
		formKey: 'creditCard',
		isCreditCard: true,
	},
]

// Props
export type PaymentModalProps = {
	show: boolean
	title: string
	text: string
	subtext?: string
	planCode: string
	button1Text?: string
	button1Action?: () => any
	button2Text?: string
	button2Action?: () => any
	button3Text?: string
	button3Action?: () => any
	centered?: boolean,
	backdrop?: "static" | boolean,
	keyboard?: boolean
	setEmail?: (email: string) => any
	email?: string
	type?: string
	inputBilling?: any
	buttonSubmit?: any
	totalDue?: string
	planType?: string
	invalidInfo?: boolean
}

/**
 * PaymentModal Component
 */

const PaymentModal: FC<PaymentModalProps> = ({
	show,
	title,
	text,
	planCode,
	button1Text,
	inputBilling,
	buttonSubmit,
	planType,
	totalDue,
	invalidInfo,
}) => {

	const [showInput, setShowInput] = useState(false)

	const isMobile = useIsMobileScreen() || localStorage.getItem('deviceRequestKey')

	// Handle Close
	const handleClose = () => setShowInput(false)

	// get current date
	const getDate = () => {

		const currentDate = new Date();

		const day = currentDate.getDate();
		const month = currentDate.getMonth() + 1; // Month is zero-based, so we add 1
		const year = currentDate.getFullYear();

		return `${month.toString().padStart(2, '0')}-${day.toString().padStart(2, '0')}-${year}`
	}
	const dateToday = getDate();



	const PaymentOptions = () => {

		const formPadding = isMobile ? '2px' : '50px'
		const textPadding = isMobile ? '36px' : '66px'

		return (

			<Box className='bg-main' sx={{ py: formPadding, display: 'flex', justifyContent: 'flex-start', flexDirection: 'column', position: 'absolute', borderRadius: '5px', pt: '10px', top: '35%', left: '50%', width: '80%', maxWidth: '400px', backgroundColor: 'white', transform: 'translate(-50%, -50%)' }}>

				<Grid container sx={{ pb: '20px', pt: formPadding }}>
					<h3 className='text-center w-100 mb-12'>{title}</h3>
				</Grid>

				<Grid container xs={12} sx={{ px: textPadding }}>
					<p>{text}</p>

					<p>Total Due: ${totalDue ? totalDue : ''}</p>
				</Grid>

				<Grid container xs={12} sx={{ px: '20px' }} justifyContent='center'>

					<Box sx={{ display: 'flex', fontFamily: 'Poppins-Light', fontSize: '16px' }}>
						<h4 className="choices-label text-center">Choose a Payment Method</h4>
					</Box>

					<Box sx={{ fontFamily: 'Poppins-medium', fontSize: '16px' }}>
						<Button
							className="squared-pill--pay"
							variant="dark"
							onClick={() => setShowInput(true)}
						>
							{button1Text}
						</Button>
					</Box>


					{/* <Box sx={{ fontFamily: 'Poppins-medium', fontSize: '16px' }}>
						<GooglePayButton planCode={planCode} tranxType="update" />
					</Box> */}

					{/* <Box sx={{ fontFamily: 'Poppins-medium', fontSize: '16px' }}>
						<ApplePayButton planCode={planCode} tranxType="update" />
					</Box> */}
				</Grid>

			</Box>


		)

	}


	const CreditCardForm = () => {

		const formPadding = isMobile ? '2px' : '50px'

		return (<>


			{isMobile && (
				<div className={`back-arrow--mobile`} onClick={handleClose}>
					<ArrowBackIos />
				</div>
			)}


			<Box className={`bg-main`} sx={{ p: formPadding, display: 'flex', justifyContent: 'flex-start', flexDirection: 'column', position: 'absolute', borderRadius: '5px', pt: '10px', top: '40%', left: '50%', width: '90%', maxWidth: '500px', backgroundColor: 'white', transform: 'translate(-50%, -50%)' }}>

				{!isMobile && (
					<div className={`back-arrow--modal`} onClick={handleClose}>
						<ArrowBackIos />
					</div>
				)}

				<Grid container sx={{ pb: '40px', pt: formPadding }}>
					<h3 className='text-center w-100 mb-12'>Pay with Credit Card</h3>
				</Grid>

				<Grid container sx={{ px: '8px' }}>
					{inputBilling(true)}
				</Grid>

				<Grid container sx={{ px: '8px' }} justifyContent='center'>
					{buttonSubmit('Re-Subscribe')}
				</Grid>

				<Grid container xs={12} sx={{ px: '20px' }} justifyContent='center'>

					<Box sx={{ display: 'flex', fontFamily: 'Poppins-Light', fontSize: '16px', color: 'red' }}>
						{invalidInfo ? (<p>
							Invalid Credit Card information.
						</p>) : undefined}
					</Box>

					<Box sx={{ display: 'flex', fontFamily: 'Poppins-Light', fontSize: '16px' }}>
						<div className="chosen-plan-info mt-4">
							<p>
								Your account will be charged {planType?.toLowerCase()} starting <br /> {dateToday}. Upgrade or cancel your plan in your account settings anytime.
							</p>
						</div>
					</Box>

				</Grid>

			</Box>

		</>)
	}

	// Render
	return (
		<>
			{isMobile ? (<>

				<Drawer
					className='bg-main'
					anchor='right'
					open={show}
					onClose={() => { handleClose }}
					sx={{
						'& *': {
							fontFamily: 'Poppins'
						},
						'& .MuiDrawer-paperAnchorRight': {
							width: '100%',
							border: 'none !important',
							backgroundColor: 'inherit',
							margin: 'auto'
						}
					}}>

					{PaymentOptions()}
				</Drawer>

				<Drawer
					className='bg-main'
					anchor='right'
					open={show && showInput}
					onClose={() => { handleClose }}
					sx={{
						'& *': {
							fontFamily: 'Poppins'
						},
						'& .MuiDrawer-paperAnchorRight': {
							width: '100%',
							border: 'none !important',
							backgroundColor: 'inherit',
							margin: 'auto'
						}
					}}>


					{CreditCardForm()}
				</Drawer>


			</>)
				:

				(<>

					<Modal
						open={show}
						onClose={() => { handleClose }}
					>
						{PaymentOptions()}
					</Modal>


					<Modal
						open={show && showInput}
						onClose={() => { handleClose }}
					>

						{CreditCardForm()}
					</Modal>


				</>)}

		</>
	)
}

export default PaymentModal
