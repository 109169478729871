/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useEffect, useState } from 'react'
import { Button, Modal } from 'react-bootstrap';
import { AdjustVdotParams, ICreateTrainingPlanParams, ITimeObject, UserVdotAdjustRecord } from '../../api/v2/types';
import useTrainingPlanUtils from '../../hooks/useTrainingPlanUtils';
import { SaveVdotAdjustments } from '../../modules/trainingPlanActions';
import { IComponentStore } from '../../store/createComponentStore';
import { ITrainingPlanStore } from '../../store/createTrainingPlanStore';
import useStore from '../../store/useStore';

const AdjustRaceGoalModal = (props: any) => {
    const { showAdjustGoal, handleCloseAdjustGoal, userInfo, selectedWeek, selectedDay, getUserVdotChanges } = props
    const { userVdotAdjustments } = useStore((state: ITrainingPlanStore) => state)
    const { setToast } = useStore((state: IComponentStore) => state)
    const { ConvertFullTo5k, ConvertFullToHalf, ConvertM2H2, ConvertFullto10k } = useTrainingPlanUtils()

    const [isNoSetTime, setIsNoSetTime] = useState(false);
    const [hasErrorSaving, setHasErrorSaving] = useState(false);
    const [isSaving, setIsSaving] = useState(false);
    const [isSuccessSave, setIsSuccessSave] = useState(false);

    const [timeType, setTimeType] = useState<string>("M");
    const [selectedTime, setSelectedTime] = useState<string>("0:0:0");
    const [mTime, setMTime] = useState<ITimeObject>({ hr: 0, min: 0, sec: 0 });
    const [targetTime, setTargetTime] = useState("")

    const handleTimeTypeChange = (timeType: string) => {
        timeType === "FivekTime" && setMTime({ ...mTime, hr: 0 })
        timeType === "FivekTime" ? setTimeType("5k") : timeType === "halfMarathonTime" ? setTimeType("hm") : setTimeType("M")
    }

    const handleBuildPlanSubmit = async (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
        const vdotData: ICreateTrainingPlanParams = {
            userId: userInfo?.uid as number,
            marathon: userInfo?.type as number,
            weeks: userInfo?.weeks as number,
            mileage: userInfo?.distance,
            plan: timeType,
            time: mTime,
            date: userInfo?.race_date,
            km: userInfo?.km === "Y" ? true : false,
            agg: userInfo?.aggressiveness,
            monday: userInfo?.monday_start,
            vdot_adj: false,
            training_level: userInfo?.training_level
        }

        const adjustmentData: UserVdotAdjustRecord = {
            uid: userInfo?.uid,
            week: selectedWeek.key,
            day: selectedDay,
            vdot: 0,
            targetm: 0
        }

        setIsSaving(true)
        const timeTotal = mTime.hr + mTime.min + mTime.sec

        if (timeTotal > 0 && !isSaving && userInfo?.uid) {
            const current = userVdotAdjustments?.find((x: UserVdotAdjustRecord) => x.uid == userInfo?.uid && x.day == selectedDay && x.week == selectedWeek.key)

            const data: AdjustVdotParams = {
                params: vdotData,
                adjustments: adjustmentData,
                type: current ? "update" : "insert"
            }

            SaveVdotAdjustments(data).then((res) => {
                if (res.status === "ok") {
                    setIsSaving(false)
                    handleCloseAdjustGoal()
                    getUserVdotChanges()
                    setIsSuccessSave(true)
                }
                else {
                    setIsSaving(false)
                    setIsSuccessSave(false)
                    setHasErrorSaving(true)
                }
            })
        }
        else {
            setIsNoSetTime(true)
            setIsSaving(false)
        }
    }

    useEffect(() => {
        setToast({
            show: isNoSetTime,
            title: "Validation Error",
            message: "Target time is required.",
            variant: "danger",
            onClose: () => setIsNoSetTime(false),
            duration: 3000
        })
    }, [isNoSetTime])

    useEffect(() => {
        setToast({
            show: hasErrorSaving,
            title: "Error Message",
            message: "An error occurred while saving the training plan adjustments, please try again.",
            variant: "danger",
            onClose: () => setHasErrorSaving(false),
            duration: 5000
        })
    }, [hasErrorSaving])

    useEffect(() => {
        // setSelectedRaceDate(new Date())
        setToast({
            show: isSuccessSave,
            title: "Adjust Training Plan",
            message: "Training plan has been adjusted!",
            variant: "success",
            onClose: () => {
                setIsSuccessSave(false)
            },
            duration: 3000,
        })
    }, [isSuccessSave])

    useEffect(() => {
        setSelectedTime(`${mTime.hr}:${mTime.min}:${mTime.sec}`)
        setIsNoSetTime(false)
    }, [mTime])

    useEffect(() => {
        const abortController = new AbortController();

        setTimeType('M')
        setMTime({ hr: 0, min: 0, sec: 0 })
        if (showAdjustGoal)
            getUserVdotChanges()

        return () => {
            abortController.abort();
        }
    }, [showAdjustGoal]);

    useEffect(() => {

        let targetTime;

        if (userVdotAdjustments?.length > 0)
            targetTime = userVdotAdjustments[userVdotAdjustments?.length - 1]?.targetm

        else
            targetTime = userInfo?.targetm;

        const type = userInfo?.type;

        switch (type) {
            case 0:
                setTargetTime(ConvertFullToHalf(targetTime));
                break;
            case 1:
                setTargetTime(ConvertM2H2(targetTime));
                break;
            case 2:
                setTargetTime(ConvertFullTo5k(targetTime));
                break;
            case 3:
                setTargetTime(ConvertFullto10k(targetTime));
                break;
            case 4:
                setTargetTime(ConvertM2H2(targetTime));
                break;
            default:
                break;
        }
    }, [userVdotAdjustments])

    return (
        <Modal
            className="common-modal build-plan adjust-race-modal build-plane-stepthree"
            show={showAdjustGoal}
            onHide={handleCloseAdjustGoal}
        >
            <Modal.Header closeButton>
                <h2 className="traning-popup--title mb-0 mb-md-3">
                    Adjust Race Goal
                </h2>
            </Modal.Header>
            <Modal.Body className="pt-0 mt-2 mx-5 px-2 px-md-5 text-center build-plan-wrap">
                <p className="mt-0 mb-0 font-weight-400 mb-3">
                    Adjust plan based on one of the following:
                </p>
                <h5 className="text-uppercase font-weight-semibold letter-space">
                    CURRENT GOAL: {targetTime.toUpperCase()}
                </h5>
                <div className="f-box d-flex justify-content-center mt-2">
                    <div className="r-box timeType">
                        <input
                            type="radio"
                            id="desiredFinishTime"
                            name="radio-group"
                            defaultChecked
                            onChange={(e) => handleTimeTypeChange(e.target.id)}
                        ></input>
                        <label htmlFor="desiredFinishTime">
                            <span className="font-md-12 plan--text">
                                {userInfo?.type == 4 ? "Marathon Time" : "Desired Finish Time"}
                            </span>
                        </label>
                    </div>
                    {userInfo?.type == 4 && (
                        <div className="r-box timeType">
                            <input
                                type="radio"
                                id="halfMarathonTime"
                                name="radio-group"
                                onChange={(e) => handleTimeTypeChange(e.target.id)}
                            ></input>
                            <label htmlFor="halfMarathonTime">
                                <span className="font-md-12 plan--text">
                                    Half Marathon Time
                                </span>
                            </label>
                        </div>
                    )}
                    {userInfo?.type.toString() === "0" || userInfo?.type.toString() === "1" || userInfo?.type.toString() === "4" ? (
                        <div className="r-box timeType">
                            <input
                                type="radio"
                                id="FivekTime"
                                name="radio-group"
                                onChange={(e) => handleTimeTypeChange(e.target.id)}
                            ></input>
                            <label htmlFor="FivekTime">
                                <span className="font-md-12 plan--text">5K Time</span>
                            </label>
                        </div>
                    ) : null}
                </div>
                <div className="input-box">
                    <div className="d-flex justify-content-center mt-3">
                        {

                            userInfo?.type.toString() === "0" && timeType !== "5k" || userInfo?.type.toString() === "1" && timeType !== "5k" || userInfo?.type.toString() === "4" && timeType !== "5k" ? (
                                <input type="number" min={0} onChange={(e) => setMTime({ ...mTime, hr: +e.target.value })} placeholder="HH"></input>
                            ) : null

                        }
                        <input type="number" min={0} onChange={(e) => setMTime({ ...mTime, min: +e.target.value })} placeholder="MM"></input>
                        <input type="number" min={0} onChange={(e) => setMTime({ ...mTime, sec: +e.target.value })} placeholder="SS"></input>
                    </div>
                </div>
                <div className="center-align">
                    <Button
                        disabled={isSaving}
                        variant="primary"
                        onClick={handleBuildPlanSubmit}
                        className="rounded-pill color-primary-bg save-btn mt-4 center-align text-capitalize letter-space btn-dark"
                    >
                        {isSaving ? "Saving adjustments..." : "Save"}
                    </Button>
                </div>
            </Modal.Body>
        </Modal>

    )
}

export default AdjustRaceGoalModal