import Container from 'react-bootstrap/Container'
import classNames from 'classnames'
import { FC, useEffect, useRef } from 'react'

// Props
export type HorizontalScrollProps = {
	breakMobile?: boolean
	noPadding?: boolean
	isHome?: boolean
	setIsScrolling?: (x: boolean) => any,
	contClassName?: string,
	sx?: object
}

// Prevent Default
const preventDefault = (e?: any) => {
	try {
		e?.preventDefault()
	} catch (err) {
		// Do Nothing
	}
}

/**
 * HorizontalScroll Component
 */

const HorizontalScroll: FC<HorizontalScrollProps> = ({
	children,
	breakMobile,
	noPadding,
	isHome,
	setIsScrolling,
	contClassName,
	sx = {}
}) => {
	// Element Ref
	const sliderRef = useRef(null)

	// Properties
	const containerClassName = classNames(
		'scroll-container',
		noPadding ? 'ps-0 pe-0' : undefined,
		contClassName ? contClassName : undefined
	)
	const elementContainerClassName = classNames(
		'scroll-element-container',
		breakMobile ? 'mobile-break' : undefined,
		isHome ? 'home-center-row' : false
	)
	let clickListeners: any[] = []
	let isDown = false
	let scrollLeft = 0
	let startX = 0

	// Get Links
	const getLinks = (slider: HTMLDivElement) => [
		...(slider.querySelectorAll('a') as any),
		...(slider.querySelectorAll('div') as any),
		...(slider.querySelectorAll('span') as any),
	]

	// Prevent Link Click
	const preventLinkClick = (
		slider: HTMLDivElement,
		links: HTMLAnchorElement[],
		e?: any
	) => {
		preventDefault(e)
		for (const link of links) {
			clickListeners.push(link.addEventListener('click', preventDefault, true))
		}
	}

	// Should Prevent Clicks
	const shouldPreventClicks = (isScrolling: boolean) => {
		if (setIsScrolling) setIsScrolling(isScrolling)
	}

	// Mount Effect
	useEffect(() => {
		const slider: HTMLDivElement | null = sliderRef.current as any

		if (slider) {
			const links = getLinks(slider)

			// Mouse Down
			slider.addEventListener('mousedown', (e: any) => {
				isDown = true
				slider.classList.add('rso-scroll-active')
				startX = e.pageX - slider.offsetLeft
				scrollLeft = slider.scrollLeft
			})

			// Mouse Leave
			slider.addEventListener('mouseleave', () => {
				isDown = false
				shouldPreventClicks(false)
				slider.classList.remove('rso-scroll-active')
			})

			// Mouse Up
			slider.addEventListener('mouseup', () => {
				isDown = false
				slider.classList.remove('rso-scroll-active')
				setTimeout(() => {
					if (clickListeners.length) {
						for (const link of links) {
							link.removeEventListener('click', preventDefault, true)
						}
						clickListeners = []
						shouldPreventClicks(false)
					}
				}, 250)
			})

			// Mouse Move
			slider.addEventListener('mousemove', (e: any) => {
				if (!isDown) return
				shouldPreventClicks(true)
				preventLinkClick(slider, links, e)
				const x = e.pageX - slider.offsetLeft
				const walk = x - startX
				slider.scrollLeft = scrollLeft - walk
			})
		}
	}, [])

	// Render
	return (
		<div ref={sliderRef} className="rso-scroll-row" style={sx}>
			<Container className={containerClassName} fluid="lg">
				<div className={elementContainerClassName}>{children}</div>
			</Container>
		</div>
	)
}

export default HorizontalScroll
