import { ISurveyDetails } from "../api/v2/onboarding-survey/types"
import { ISuggestedProgram } from "../api/v2/suggested-programs/types"
import { StoreSlice } from "./types"

export interface ISurveyStore {
    userSurveyDetails: ISurveyDetails | undefined,
    setUserSurveyDetails: (surveyDetails: ISurveyDetails) => void,
    suggestedPrograms: any | undefined
    setSuggestedPrograms: (suggestedPrograms: any) => void
    currentProgram: ISuggestedProgram | undefined
    setCurrentProgram: (currentProgram: ISuggestedProgram) => void
}

const createSurveyStore: StoreSlice<ISurveyStore> = (set) => ({
    userSurveyDetails: undefined,
    setUserSurveyDetails: (userSurveyDetails: ISurveyDetails) => set(() => ({ userSurveyDetails: userSurveyDetails })),
    suggestedPrograms: undefined,
    setSuggestedPrograms: (suggestedPrograms: any) => set(() => ({ suggestedPrograms: suggestedPrograms })),
    currentProgram: undefined,
    setCurrentProgram: (currentProgram: ISuggestedProgram) => set(() => ({ currentProgram: currentProgram })),
})

export default createSurveyStore