import Form from 'react-bootstrap/Form'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import BasicInput, { BasicInputProps } from '../input/BasicInput'
import { FC, Fragment } from 'react'

// GenericForm Props
export type GenericFormProps = {
	inputs: BasicInputProps[]
	isHorizontal?: boolean
	id?: string
}

/**
 * GenericForm Component
 */

const GenericForm: FC<GenericFormProps> = ({ id, inputs, isHorizontal }) => {
	// Render
	return (
		<Form id={id}>
			{inputs.map((inputProps, index) => (
				<Fragment key={index}>
					{(!inputProps.skipNewRow ? true : false) && (
						<Row className="mb-4">
							<Col>
								<BasicInput isHorizontal={isHorizontal} {...inputProps}>
									{(inputs[index + 1]?.skipNewRow ? true : false) && (
										<BasicInput
											isHorizontal={isHorizontal}
											{...inputs[index + 1]}
										/>
									)}
								</BasicInput>
							</Col>
						</Row>
					)}
				</Fragment>
			))}
		</Form>
	)
}

export default GenericForm
