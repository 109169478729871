/* eslint-disable @typescript-eslint/no-unused-vars */
import { date, strtotime } from 'locutus/php/datetime'
import useBuildTrainingPlan from '../../../pages/training-plan/hooks/useBuildTrainingPlan'
import useTrainingPlanUtils from '../../../hooks/useTrainingPlanUtils'
import { count } from 'locutus/php/array'
import { values } from 'lodash'
import moment from 'moment'
import { DAYS_OF_WEEK, DAYS_OF_WEEK_ALT } from '../build/DaysOptions'
import { useState } from 'react'
import { GetAllUserNotes, GetMarathonValues } from '../../../modules/trainingPlanActions'
import { MarathonValRecord, UserNotesRecord, UserOverrideRecord } from '../../../api/v2/types'


const useCalendarBase = (userOverrides: UserOverrideRecord[] | null) => {
    const { getThisDate, getCurrentWeek, DaysInMonth, GetWeek, GetWeek2, convertDateToUTC, getWeekDays } = useTrainingPlanUtils()
    const { getRaceDayDetails, getRaceDistance, getRaceDistancePace } = useBuildTrainingPlan()
    const [loadingValues, setLoadingValues] = useState(true)
    // const [allUserNotes, setAllUserNotes] = useState<Array<UserNotesRecord> | undefined>(undefined)

    // const [selectedWeek, setSelectedWeek] = useState<{ week: string, key: number }>({ week: "", key: 1 })

    const [marathonDailyValues, setMarathonDailyValues] = useState<Array<any>>([])


    const convertTime = (value: any) => {
        const minsToSubtract = 121;
        return strtotime('+' + minsToSubtract + ' minutes', strtotime(value))
    }

    const buildMarathonPaces = (values: Array<any>) => {
        const paces: Array<any> = []
        values.forEach(value => {
            paces.push({ mar_id: value.paceMarId, paceId: value.paceId, pace: value.pace, notes: value.notes, orderid: value.paceOrderId })
        })

        const uniqueValues = paces.filter((a, i) => paces.findIndex((s) => a.paceId === s.paceId) === i)
        return uniqueValues;
    }

    const buildWorkouts = (values: Array<any>) => {
        const workouts: Array<any> = []
        values.forEach(value => {
            workouts.push({ mid: value.workoutMarId, workoutId: value.woId, title: value.title, description: value.description, link: value.link, orderid: value.wOrderid })
        })

        const uniqueValues = workouts.filter((a, i) => workouts.findIndex((s) => a.workoutId === s.workoutId) === i)
        return uniqueValues;
    }

    const getWeeklyActivities = async (week: number, signal: AbortSignal, userInfo: any, selectedWeek: { week: string, key: number }, allUserNotes: Array<UserNotesRecord>) => {

        const weekDate: any = []

        DAYS_OF_WEEK.map((value, key) => {
            weekDate.push(moment(selectedWeek.week).add(key, 'days').format('YYYY-MM-D'))
        })

        const { type, weeks, distance, monday_start } = userInfo
        const details = getRaceDayDetails(userInfo)
        const saturdayRaceMondayStart = details.raceDay == 5 && details.saturdayRace;
        const saturdayRaceSundayStart = details.raceDay == 6 && !details.saturdayRace;
        let userNote;

        const checkRaceWeek = () => {
            //sunday start - monday race
            if (details.raceDay == 1 && (+weeks + 1) == selectedWeek.key && type != 4)
                return true
            //sunday start - sunday race
            else if (details.raceDay == 0 && (+weeks + 1) == selectedWeek.key && type != 4)
                return true
            //sunday start - saturday race
            else if (saturdayRaceSundayStart && weeks == selectedWeek.key && type != 4)
                return true
            else if (saturdayRaceMondayStart && weeks == selectedWeek.key && type != 4) {
                return true;
            }
            else
                return false;
        }
        const isRaceWeek = checkRaceWeek();

        const values: Array<any> = []
        let marathons: Array<any> = []
        let paces: Array<any> = []
        let workouts: Array<any> = []

        const days = monday_start ? DAYS_OF_WEEK_ALT : DAYS_OF_WEEK;

        const params: MarathonValRecord = {
            marathon: type,
            type: weeks,
            training_goal: distance,
            week: week,
            distance: distance,
        }

        await GetMarathonValues(params, signal).then(async (res) => {
            if (res && res.data.messages === "success") {
                marathons = res.data.result.marathon;
                paces = res.data.result.marathonPaces;
                workouts = res.data.result.workouts;
            }
        })

        if (!isRaceWeek)
            for (const [index, d] of Object.entries(days)) {
                const indexVal = +(index as unknown as number) + 1
                // const marathon = res.data.result.marathon.find((x: any) => x.day == indexVal)
                let marathon = marathons?.filter((x: any) => x.day == indexVal);

                if (allUserNotes && allUserNotes.length > 0 && marathon)
                    userNote = allUserNotes?.find(x => x?.marid == marathon[0]?.id)
                const currentOveride = userOverrides?.filter((x) => (+x.day + 1) == (+index + 1) && x.week == selectedWeek.key)
                if (currentOveride && currentOveride?.length != 0) {
                    marathon = marathons?.filter((x: any) => x.id == currentOveride[0]?.mid && x.week == currentOveride[0]?.week);
                }
                else {
                    marathon = marathons?.filter((x: any) => x.day == indexVal);
                }

                if (marathon) {
                    const data = {
                        monthData: {
                            week: selectedWeek.key,
                            day: +index + 1,
                            dateVal: weekDate[index],
                            monthDigits: []
                        },
                        activitiesValue: {
                            marathon: {
                                id: marathon[0]?.id,
                                marathon: marathon[0]?.marathon,
                                type: marathon[0]?.type,
                                training_goal: marathon[0]?.training_goal,
                                week: marathon[0]?.week,
                                day: marathon[0]?.day,
                                distance: marathon[0]?.distance,
                                race_day: marathon[0]?.race_day,
                            },
                            marathonPaces: buildMarathonPaces(marathon),
                            workouts: buildWorkouts(marathon),
                        }

                        // overrides: buildOverrides(marathon)
                    }
                    values.push({ ...data })
                }
                else
                    values.push([])
            }
        else if (isRaceWeek)
            for (const [index, d] of Object.entries(days)) {

                const indexVal = +(index as unknown as number) + 2 - (saturdayRaceMondayStart ? 1 : 0)
                // const marathon = res.data.result.marathon.find((x: any) => x.day == indexVal)
                let marathon = marathons?.filter((x: any) => x.day == indexVal);
                if (allUserNotes && allUserNotes.length > 0 && marathon)
                    userNote = allUserNotes?.find(x => x.marid == marathon[0]?.id)

                const currentOveride = userOverrides?.filter((x) => (+x.day + 1) == (+index + 1) && x.week == selectedWeek.key)
                if (currentOveride && currentOveride?.length != 0) {
                    marathon = marathons?.filter((x: any) => x.id == currentOveride[0]?.mid && x.week == currentOveride[0]?.week);
                }
                else {
                    marathon = marathons?.filter((x: any) => x.day == indexVal);
                }
                if (marathon && +index + 1 != 7) {
                    const data = {
                        monthData: {
                            week: selectedWeek.key,
                            day: +index + 1,
                            dateVal: weekDate[index],
                            monthDigits: []
                        },
                        activitiesValue: {
                            marathon: {
                                id: marathon[0]?.id,
                                marathon: marathon[0]?.marathon,
                                type: marathon[0]?.type,
                                training_goal: marathon[0]?.training_goal,
                                week: marathon[0]?.week,
                                day: marathon[0]?.day - (saturdayRaceMondayStart ? 0 : 1),
                                distance: marathon[0]?.distance,
                                race_day: marathon[0]?.race_day,
                            },
                            marathonPaces: buildMarathonPaces(marathon),
                            workouts: buildWorkouts(marathon)
                        }
                        // overrides: buildOverrides(marathon)
                    }
                    if (saturdayRaceMondayStart && data.activitiesValue.marathon.day == 6) {
                        //
                    } else
                        values.push({ ...data })
                }
                else {
                    values.push({
                        monthData: {
                            week: selectedWeek.key,
                            day: +index + 1,
                            dateVal: weekDate[(details.raceDay == 1 ? 2 : details.raceDay == 0 ? 1 : saturdayRaceMondayStart ? 6 : 7) - 1],
                            monthDigits: []
                        },
                        activitiesValue: {
                            marathon: {
                                id: 5000 + userInfo?.uid,
                                marathon: userInfo?.type,
                                type: userInfo?.weeks,
                                training_goal: userInfo?.distance,
                                week: selectedWeek.key,
                                day: details.raceDay == 1 ? 2 : details.raceDay == 0 ? 1 : saturdayRaceMondayStart ? 6 : 7,
                                distance: getRaceDistance(userInfo?.type as number),
                                race_day: 1,
                            },
                            marathonPaces: [{ mar_id: 5000 + userInfo?.uid, paceId: 9999 + userInfo?.uid, pace: 'Race Day', notes: `${getRaceDistancePace(userInfo)} ${userInfo?.km === "Y" ? "kms" : "miles"}`, orderid: userInfo?.type }],
                            workouts: []
                        }
                    })
                }
            }

        // console.log(values)
        return values
        // setMarathonDailyValues((prev) => [...values, ...prev])
    }

    const buildCalendarMonth = async (monthKey: number, userInfo: any, aC: any | undefined) => {
        setLoadingValues(true)
        const abortController = aC ? aC : new AbortController();
        const weekDays = getWeekDays(new Date(), userInfo.start, userInfo.race_date, userInfo.monday_start ? DAYS_OF_WEEK_ALT : DAYS_OF_WEEK);
        const allUserNotes = await getAllUserNotes(abortController.signal, userInfo)
        const weekValues: any = []

        for (const key in weekDays) {
            // console.log(key)
            const values = await getWeeklyActivities(+key + 1, abortController.signal, userInfo, { week: weekDays[key], key: +key + 1 }, allUserNotes)
            // const results = values.filter(x => Number(x.monthData.dateVal.split('-')[1]) == monthKey + 1)
            weekValues.push(...values)
        }
        setLoadingValues(false)
        return weekValues
    }

    const getAllUserNotes = async (abort?: AbortSignal, userInfo?: any) => {
        const allUserNotes = await GetAllUserNotes(userInfo?.uid, abort);
        if (allUserNotes?.data?.messages === "success" && allUserNotes?.data?.result.length > 0)
            return allUserNotes?.data.result
        else
            return []
    }


    return { buildCalendarMonth, marathonDailyValues, loadingValues, setLoadingValues }
}

export default useCalendarBase
