import Card from 'react-bootstrap/Card'
import PlayButton from '../buttons/PlayButton'
import Heart from '../logos/Heart'
import VideoDetailText from '../text/VideoDetailText'
import ImageLogo, { ImageLogoProps } from '../logos/ImageLogo'
import { FC } from 'react'

// Props
export type VideoDetailCardProps = {
	text: string
	title?: string
	logoType?: ImageLogoProps['type']
	logoImage?: string
	hasCheck?: boolean
	hasLikeButton?: boolean
	videoId?: string
	favorites?: any
	hasPlayButton?: boolean
	onPlay?: () => any
	onLike?: (x: boolean) => any,
	className?: string
	isComplete?: boolean
	isProgram?: boolean
	duration?: number
	isSelected?: boolean
}

/**
 * VideoDetailCard Component
 */

const VideoDetailCard: FC<VideoDetailCardProps> = ({
	text,
	title,
	logoImage,
	logoType,
	hasCheck,
	onPlay,
	onLike,
	hasLikeButton,
	videoId,
	favorites,
	hasPlayButton,
	className,
	isComplete,
	isProgram,
	duration,
	isSelected
}) => {
	const isLiked = favorites && favorites.find((x: any) => x.id === videoId) ? true : false;
	// Render
	// console.log(className)
	return (
		<Card className={`home-detail-card ${isSelected ? 'selected-card' : 'bg-white'}`}>
			<Card.Body>
				<Card.Text as="div">
					<div className="flex-content-center justify-content-start">
						{/* Logo */}
						<div className='card-image-container'>
							<ImageLogo type={logoType} image={logoImage} />
							{onLike && hasLikeButton && (
								<Heart isFromMetadata onLike={onLike} isLiked={isLiked} className={className ? className : "video-detail-card-icon"} />
							)}
						</div>

						{/* Text */}
						<div className="text-black video-detail-card-text no-bottom-margin me-3">
							<div className={isProgram ? 'ms-n2' : 'ms-3'}>
								<VideoDetailText
									detailTitle={title}
									detailSubtitle={text}
									hasCheck={hasCheck}
									isComplete={isComplete}
									isProgram={isProgram}
								/>
								{duration ? (
									<p className={`${!isProgram ? 'video-duration-nm' : 'video-duration'}`}>{duration}min</p>
								) : undefined}
							</div>
						</div>
						{
							hasPlayButton ? (
								/* Play Button */
								<div className="me-3">
									<PlayButton onPlay={onPlay} />
								</div>
							) : undefined
						}
					</div>
				</Card.Text>
			</Card.Body>
		</Card>
	)
}

export default VideoDetailCard
