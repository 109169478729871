/* eslint-disable @typescript-eslint/no-unused-vars */
import ArrowForward from '@mui/icons-material/ArrowForwardIos'
import ArrowBack from '@mui/icons-material/ArrowBackIosRounded'
import React, { FC, useEffect, useState, useRef } from 'react'
import { Card, Col, Row } from 'react-bootstrap'
import MainCard from '../../components/cards/MainCard'
import AppMenus, { BodyComponent } from '../../components/layout/AppMenus'
import useQueryParams from '../../hooks/useQueryParams'
// import GenericModal from '../../components/misc/GenericModal'
import { backNav, navReq } from '../../modules/apiConsume'
import { PrimaryButton } from '../../components/buttons/AuthSubmitButtons'
import { useLocation } from 'react-router-dom'
import {
	slideInRight,
	slideOutRight,
	slideInLeft,
	slideOutLeft,
} from 'react-animations'
import styled, { keyframes } from 'styled-components'
import useIsMobileScreen from '../../hooks/useIsMobileScreen'
import ProgramMobileView from '../programs/v2/ProgramMobileView'
import useFavorites from '../favorites/useFavorites'
import { AuthPageProps } from '../../modules/types'
import WithAuth from '../../components/WithAuth'
import { Video } from '../../modules/videos/allVideos'
import SaveAltIcon from '@mui/icons-material/SaveAlt'
import AudioPlayerComponent from './AudioPlayer'
import MidfootData from './midfoot.db.json'
import useMidfoot from './useMidfoot'
import moment from 'moment'
import midfootActions from './midfootActions'
import { Exercise } from '../exercises/types'
import { Midfoot } from '../../api/v2/midfoot/midfoot.types'
import GenericModal from '../../components/misc/GenericModal'
import allNewExercises from '../../modules/videos/allNewExercises.json'
import FileSaver from 'file-saver'
import { DownloadModal } from '../guides/Guide'
import useAlert from '../../hooks/useAlert'
import GenericAlert from '../../components/misc/GenericAlert'
import Motion from '../../components/animation/Motion'

const AslideInLeft = keyframes`${slideInLeft}`
const AslideInRight = keyframes`${slideInRight}`

const AslideOutLeft = keyframes`${slideOutLeft}`
const AslideOutRight = keyframes`${slideOutRight}`

const DivInLeft = styled.div`
	animation: 0.3s ${AslideInLeft};
`
const DivInRight = styled.div`
	animation: 0.3s ${AslideInRight};
`

const DivOutLeft = styled.div`
	animation: 0.3s ${AslideOutLeft};
`
const DivOutRight = styled.div`
	animation: 0.3s ${AslideOutRight};
`

interface ICurrentVideos {
	day: string
	title: string
	link: string
}

const SELECTOR_VALUES = [
	'Introduction',
	'Week 1',
	'Week 2',
	'Week 3',
	'Week 4',
	'Week 5',
]
const PILL_OPTIONS = ['Training', 'Files']
const PILL_OPTIONS_DEFAULT = ['Introduction', 'Training', 'Files']
const DUMMY = [1, 2, 3]
const CONFIRM_BUTTONS = ['Yes, continue', 'No, cancel']

const TEMP_DATA = [
	{
		week: 1,
		introText:
			' The first week is geared towards preparing your anatomy for transitioning off your heel and onto the midfoot.This is important, as a midfoot landing allows the body to absorb the forces or running better. <br/><br/> Using the musculature of the foot, ankle, knee, and hip in contrast to your bones can make all the difference when you’re looking to stay on the road and out of the doc’s office. <br/><br/> <b>WEEK 1 GOALS</b> <br/><br/> Prepare anatomy for mid-foot transition to avoid excessive soreness. <br/><br/> Prep calfs to sustain midfoot for long haul <br/><br/> Strengthen foot muscles and improve balance <br/><br/> Stretch foot/ankle to relieve soreness associated with change <br/><br/> Start 15-30 sec. mid-foot progression to begin learning new landing.',
	},
]

const defaultWorkoutVideo: Video = {
	id: String(689944784),
	url: '/#/programs/midfoot-program?videoId=' + 689944784,
	image: '\\img\\midfoot_project.jpg',
	category: 'Midfoot',
	headingTitle: 'Introduction',
	headingSubtitle: 'Midfoot Project',
	detailsLine1: '',
	detailsLine2: '',
	headlines: '' || [],
	descriptions:
		[
			'Midfoot running can provide benefits from both a speed and injury standpoint — and set you up for a career of consistent, fast & injury-free running. <br/><br/>Join us for 5 Weeks of step-by-step video training that teaches you everything you need to fix old habits.',
		] || [],
}

const MidfootProject: FC<AuthPageProps> = ({ userInfo, hasLoaded }) => {
	const { loading, getActiveMidfoot, createMidfootData, restartMidfoot } =
		midfootActions()

	const PROGRESS = 0 as number
	const [showPlayer, setShowPlayer] = useState(false)
	const [isPlaying, setIsPlaying] = useState(false)
	const [todayVideo, setTodayVideo] = useState<ICurrentVideos | undefined>()
	const [currentSelectorValue, setCurrentSelectorValue] = useState<number>(0)
	const [showProgramView, setShowProgramView] = useState(false)
	const [isFavorite, setIsFavorite] = useState(false)
	const [hasActiveTraining, setHasActiveTraining] = useState(false)
	const [isLoadingMark, setIsLoadingMark] = useState(false)
	const [selectedPill, setSelectedPill] = useState(0)
	const [selector, setSelector] = useState(SELECTOR_VALUES)
	const [currentWeek, setCurrentWeek] = useState(0)
	const [activeMidfoot, setActiveMidfoot] = useState<any | undefined>()
	const [currentWeekData, setCurrentWeekData] = useState<any | undefined>()
	const [currentExercises, setCurrentExercises] = useState<
		Exercise[] | undefined
	>()
	const [currentWorkoutVideo, setCurrentWorkoutVideo] =
		useState<Video>(defaultWorkoutVideo)
	const [modalOpen, setModalOpen] = useState(false)
	const [isCompleted, setIsCompleted] = useState(false)
	const [currentVideo, setCurrentVideo] = useState('INTRODUCTION')
	const [weekVideos, setWeekVideos] = useState<Video[] | undefined>()
	const [downloadType, setDownloadType] = useState('pdf')

	const { favorites, toggleFavorite, isLoading, getFavorites, getIsFavorited } =
		useFavorites(userInfo?.account_id as number, undefined, 'exercises', true)
	const { getCurrentWeek } = useMidfoot()
	const isMobile = useIsMobileScreen()
	const url = useLocation()
	const { videoId, type, mediaFile } = useQueryParams()
	const slideDirection = useRef({slide: localStorage.getItem('page') === 'ep'? '' : 'left'})

	const handleOnPlay = async () => {
		setIsPlaying(true)
	}

	const {
		showAlert,
		onAlertOpen,
		onAlertClose,
		alertTitle,
		alertMessage,
		setAlertMessage,
		alertVariant,
	} = useAlert()

	const getFile = () => {
		if (downloadType === 'pdf')
			return `/files/Midfoot_Project_Week_${currentSelectorValue}.pdf`
		else return `/files/Midfoot_Week${currentSelectorValue}.mp3`
	}

	const getGuideType = () => {
		if (downloadType === 'pdf') return 'Midfoot Project'
		else return 'Midfoot Project Audio Run'
	}

	const parseExerciseVideo = (e: any) => {
		const data: Video = {
			id: String(e?.video_id),
			url: '/#/exercises/' + e?.video_id,
			// image: e.featured_img,
			image: `https://vumbnail.com/${e?.video_id}.jpg`,
			category: e?.exercise_type,
			headingTitle: e?.exercise_title,
			headingSubtitle: e?.primary_region,
			detailsLine1: e?.tips_description,
			detailsLine2: '',
			headlines: [],
			descriptions: [e?.tips_description],
		}

		return data
	}

	const onLike = async (videoId: string) => {
		const isLiked =
			favorites && favorites.find((x: any) => x.id === videoId) ? true : false
		await toggleFavorite(
			userInfo?.account_id as number,
			Number(videoId),
			!isLiked
		)
	}

	const handleMarkAsFavorite = async (isChecked: boolean) => {
		if (currentWorkoutVideo) {
			setIsLoadingMark(true)
			await toggleFavorite(
				userInfo?.account_id as number,
				Number(currentWorkoutVideo.id),
				isChecked
			)
			await getFavorites()
			setIsLoadingMark(false)
		}
	}
	const handleCloseView = () => {
		setShowProgramView(false)
		// navReq('/#/programs/midfoot-project')
		backNav()
	}

	const handleDownloadFile = (type: string) => {
		setDownloadType(type)
		setModalOpen(true)
	}

	const handleContinueButton = async () => {
		if (activeMidfoot && userInfo?.account_id) {
			await restartMidfoot(userInfo?.account_id, activeMidfoot.id)
			await loadMidfootData()
			setHasActiveTraining(false)
			setCurrentSelectorValue(1)
		}
	}

	const handleOnchangeSelector = (type: string) => {
		if (activeMidfoot) {
			if (type === 'forward') {
				if (currentSelectorValue + 1 == SELECTOR_VALUES.length)
					setCurrentSelectorValue(0)
				else setCurrentSelectorValue((prev) => prev + 1)
			} else {
				if (currentSelectorValue == 0)
					setCurrentSelectorValue(SELECTOR_VALUES.length - 1)
				else setCurrentSelectorValue((prev) => prev - 1)
			}
		}
	}

	const handleStartTraining = async () => {
		if (activeMidfoot) {
			setHasActiveTraining(true)
		} else {
			await createMidfootData(userInfo?.account_id)
			await loadMidfootData()
			setCurrentSelectorValue(1)
		}
	}

	const getVideoLink = (link: string) => {
		if (link && link?.includes('exercises')) {
			return link
		} else {
			return `/#/programs/midfoot-project?type=${
				isMobile ? 'mobile' : 'full'
			}&videoId=${link}`
		}
	}

	const isNotFirstWeek = (index: number) => {
		const bool = currentSelectorValue >= 2 ? true : false
		if (bool) return index + 1
		else return index
	}

	const loadMidfootData = async () => {
		const data = (await getActiveMidfoot(userInfo?.account_id)) as Midfoot[]
		setActiveMidfoot(data[0] ? data[0] : undefined)
	}

	const getVideoId = (link: string) => {
		if (link && link.includes('exercises')) {
			const id = link.split('/')
			return id[3]
		} else {
			return link
		}
	}

	const handleCancelButton = () => {
		setHasActiveTraining(false)
	}

	const handleDownload = async (url: string) => {
		FileSaver.saveAs(url, currentWeekData?.training_audio)
	}

	useEffect(() => {
		// if (videoId === "783135875")
		//     setCurrentVideo("Week 1")
		// else
		//     setCurrentVideo("INTRODUCTION")

		type === 'mobile' && videoId
			? setShowProgramView(true)
			: setShowProgramView(false)
		setIsPlaying(false)
	}, [videoId, type])

	useEffect(() => {
		setIsPlaying(false)
	}, [url])

	useEffect(() => {
		if (hasLoaded && userInfo?.account_id)
			getActiveMidfoot(userInfo?.account_id).then((res) => {
				setActiveMidfoot(res[0])
			})
	}, [hasLoaded, userInfo])

	useEffect(() => {
		let dateTrainingStart
		if (activeMidfoot) {
			const dateStarted = activeMidfoot.date_started.split('T')[0]
			const dateNowString = moment(new Date()).toISOString().split('T')[0]
			// if (dateStarted === dateNowString) {
			//     setCurrentWeek(getCurrentWeek(moment(activeMidfoot.date_started).subtract(2, 'days').toDate()));
			// }
			// else
			const curWeek = getCurrentWeek(
				moment(activeMidfoot.date_started).toDate()
			)
			setCurrentWeek(curWeek == 0 ? curWeek + 1 : curWeek > 5 ? 5 : curWeek)

			// console.log(dateStarted, moment(activeMidfoot.date_started).subtract(0, 'days').toDate())
		}
	}, [activeMidfoot])

	useEffect(() => {
		if (currentWeek == 0) setSelector(['Introduction'])
		else {
			setSelector(SELECTOR_VALUES)
			setCurrentSelectorValue(currentWeek)
		}
	}, [currentWeek])

	useEffect(() => {
		// console.log(selector)
		setSelectedPill(!isMobile ? 1 : 0)
		setCurrentWeekData(MidfootData[currentSelectorValue - 1])
	}, [currentSelectorValue])

	useEffect(() => {
		!isMobile
			? window.location.assign(
					getVideoLink(
						currentWeekData ? currentWeekData?.training_video : '808234618'
					)
			  )
			: undefined

		currentWeekData
			? setCurrentWorkoutVideo({
					id: String(currentWeekData?.training_video),
					url:
						'/#/programs/midfoot-program?videoId=' +
						currentWeekData?.training_video,
					image: `https://vumbnail.com/${currentWeekData?.training_video}.jpg`,
					category: 'Midfoot',
					headingTitle: `Week ${currentSelectorValue}`,
					headingSubtitle: 'Midfoot Project',
					detailsLine1: '',
					detailsLine2: '',
					headlines: '' || [],
					descriptions: [currentWeekData?.description] || [],
			  })
			: setCurrentWorkoutVideo({
					id: String(808234618),
					url: '/#/programs/midfoot-program?videoId=' + 808234618,
					image: `\\img\\midfoot_project.jpg`,
					category: 'Midfoot',
					headingTitle: `Introduction`,
					headingSubtitle: 'Midfoot Project',
					detailsLine1: '',
					detailsLine2: '',
					headlines: '' || [],
					descriptions:
						[
							'<p> <span>Are you struggling with overstriding or other run form issues that slow you down or cause injuries?&nbsp;</span> </p> <p>  </p> <p> <span>The Midfoot Project, led by physical therapist Steve Gonser, is designed to help runners like you! This five-week bootcamp targets overstriding and helps improve overall run form, ensuring a smoother and safer running experience.</span> </p> <p>  </p> <p> <span>The Midfoot Project is perfect for runners who overstride and heelstrike but also for runners looking to make minor tweaks or improvements in their form.</span> </p> <p>  </p> <p> <span>Throughout five weeks, participants will receive comprehensive training to change their run form and support it with targeted exercises.&nbsp;</span> </p> <p>  </p> <p> <span><b>The program includes the following:</b></span> </p> <ul> <li> <span>Weekly drills.</span> </li> <li> <span>Exercises.</span> </li> <li> <span>A printable PDF guide.</span> </li> <li> <span>Weekly audio runs that allow you to bring Steve&apos;s expert guidance with you on the go.</span> </li> </ul> <p> <span>With the Midfoot Project, you&apos;ll dive deep into overhauling your run form to eliminate overstriding, improve speed, and prevent injuries.</span> </p> <p>  </p> <p> <span>If you&apos;re ready to elevate your running performance and protect your body, don&apos;t wait - join the Midfoot Project today!</span> </p>',
						] || [],
			  })
	}, [currentWeekData, currentSelectorValue])

	useEffect(() => {
			slideDirection.current.slide = '';
			localStorage.setItem('page', 'pw')
	}, [])

	return (
		<>
			<GenericModal
				show={hasActiveTraining}
				title="Are you sure?"
				text={'You want to restart your midfoot project training?'}
				setShow={setHasActiveTraining}
				keyboard={false}
				backdrop="static"
				button1Action={handleContinueButton}
				button1Text={loading ? 'Loading...' : CONFIRM_BUTTONS[0]}
				button2Action={handleCancelButton}
				button2Text={CONFIRM_BUTTONS[1]}
			/>

			{/* Alert */}
			<GenericAlert
				show={showAlert}
				title={alertTitle}
				message={alertMessage}
				variant={alertVariant}
				onClose={onAlertClose}
			/>

			{/* Download Modal */}
			<DownloadModal
				title={'Midfoot Project Week ' + currentSelectorValue}
				show={modalOpen}
				setShow={setModalOpen}
				userInfo={userInfo}
				getGuideType={getGuideType}
				onAlertOpen={onAlertOpen}
				setAlertMessage={setAlertMessage}
				getFile={getFile}
			/>

			<Motion
				isVisible={showProgramView && isMobile}
				isMobile={true}
				direction={{slide:"up"}}
			>
				<ProgramMobileView
					onLike={onLike}
					programType={'Midfoot'}
					title={currentWorkoutVideo.headingTitle}
					currentVideo={currentWorkoutVideo}
					closeView={handleCloseView}
					handleMarkAsFavorite={handleMarkAsFavorite}
					handleOnPlay={handleOnPlay}
					isPlaying={isPlaying}
					isFavorite={isFavorite}
					isLoadingMark={isLoadingMark}
				/>
			</Motion>

			<Motion
				isVisible={!(showProgramView && isMobile)}
				isMobile={isMobile}
				direction={slideDirection.current}
			>
				<AppMenus hasContainer>
						<BodyComponent>
							<div className="">
								<Row className="d-none d-md-block mx-md-n4 mb-n0">
									<Col md={1}>
										<span
											className="has-pointer back-btn w-auto pt-1"
											onClick={backNav}
										>
											<ArrowBack />
										</span>
									</Col>
									<Col md={3}>
										<h3
											style={{ cursor: 'pointer', marginTop: '5px' }}
											onClick={backNav}
											className="mx-5"
										>
											Midfoot Project
										</h3>
									</Col>
								</Row>
								<Row className="">
									<Col className={`d-none d-md-block`} md={8} lg={9} sm={12}>
										<Row className="get-started-training-plan justify-content-center mb-md-2 mb-3">
											<Col className="px-0 px-sm-3">
												<div className="video-wrapper w-100 blue-bg">
													<MainCard
														playButtonIsCentered
														type="responsiveCardLg"
														progress={0}
														bgImage={
															videoId && currentSelectorValue > 0
																? `https://vumbnail.com/${videoId}.jpg`
																: `\\img\\midfoot_project.jpg`
														}
														vimeoId={
															videoId && currentSelectorValue > 0
																? videoId
																: 808234618
														}
														onPlay={handleOnPlay}
														hasCheck
														isPlaying={isPlaying}
													/>
													{!isPlaying && !isPlaying ? (
														<div className="banner-info">
															<h3 className="text-white fw-normal text-capitalize">
																{todayVideo &&
																!todayVideo.link.includes('exercises')
																	? todayVideo.title
																	: videoId && currentSelectorValue > 0
																	? 'Week ' + currentSelectorValue
																	: 'Introduction'}
															</h3>
														</div>
													) : null}

													{!isPlaying && !isPlaying ? (
														<div className="tick-mark">
															<img
																src={`${
																	PROGRESS == 100
																		? './images/icons/checkmark-done.svg'
																		: './images/icons/checkmark.svg'
																}`}
																alt="Like"
															/>
														</div>
													) : undefined}
												</div>
											</Col>
										</Row>
									</Col>

									<Col
										className={`${showPlayer ? 'mt-0' : 'mt-0'} `}
										md={4}
										lg={3}
										sm={12}
									>
										<Row className="mb-2 mb-md-4 active-training-container">
											<Col>
												<div className="reset-day-selector mb-1 mb-md-n2">
													<span>
														<ArrowBack
															onClick={() =>
																handleOnchangeSelector('backward')
															}
														/>
													</span>
													<div>
														<h4></h4>
														<h5 className="mt-n0">
															{selector[currentSelectorValue]}
														</h5>
													</div>
													<span
														onClick={() => handleOnchangeSelector('forward')}
													>
														<ArrowForward />
													</span>
												</div>
											</Col>
											{currentSelectorValue == 0 ? (
												<div className="text-center px-5 mt-3">
													<p className="mb-md-n1 d-md-block d-none">
														Watch the video to the left to learn about the
														program.
													</p>
													<p className="mb-md-n1 d-md-none d-block">
														Watch the video below to learn about the program.
													</p>
													<PrimaryButton
														primaryText={
															activeMidfoot
																? 'Re-start Training'
																: 'Start Training'
														}
														primaryAction={handleStartTraining}
														primaryDisabled={isLoading}
													/>
												</div>
											) : (
												<>
													<div className="pill-selector-container mt-md-4 mt-3">
														{(!isMobile
															? PILL_OPTIONS_DEFAULT
															: PILL_OPTIONS
														).map((value, index) => (
															<button
																onClick={() => setSelectedPill(index)}
																key={index}
																className={`squared-pill ${
																	selectedPill == index ? 'active-pill' : ''
																}`}
															>
																{value}
															</button>
														))}
													</div>
													<div className="mt-3">
														{!isMobile && selectedPill == 0 ? (
															<Card className="border-0 p-4 w-100">
																<p
																	dangerouslySetInnerHTML={{
																		__html: `${currentWeekData?.description}`,
																	}}
																/>
															</Card>
														) : (isMobile && selectedPill == 0) ||
															(!isMobile && selectedPill == 1) ? (
															//TRAINING
															<div>
																<div className="justify-content-center phone-video-list mb-n2">
																	<MainCard
																		videoId={currentWeekData?.training_video}
																		key={currentWeekData?.training_video}
																		cardType="video"
																		// type="videoLgStretch"
																		hasCheck={true}
																		progress={0}
																		logoImage={`https://vumbnail.com/${currentWeekData?.training_video}.jpg`}
																		bgImage={`https://vumbnail.com/${currentWeekData?.training_video}.jpg`}
																		detailTitle={'Midfoot Project'}
																		detailSubtitle={
																			'Week ' + currentSelectorValue
																		}
																		link={getVideoLink(
																			currentWeekData?.training_video
																		)}
																		isProgram={true}
																	/>
																</div>

																<AudioPlayerComponent
																	file={currentWeekData?.training_audio}
																	title={`Week ${currentSelectorValue} Guided Run`}
																/>

																<div className="mt-n3">
																	<p className="mb-1 mt-5">
																		<b>Daily Exercises</b>
																	</p>
																	{currentWeekData?.exercises
																		?.filter((x: any) => x.is_daily == 1)
																		.map((value: any, index: number) =>
																			allNewExercises
																				.filter(
																					(x) =>
																						x.exercise_id == value.id &&
																						x.video_type === 'intro'
																				)
																				.map((x, i) => (
																					<div
																						key={i}
																						className="justify-content-center phone-video-list mb-n2"
																					>
																						<MainCard
																							videoId={
																								parseExerciseVideo(x).id
																							}
																							cardType="video"
																							// type="videoLgStretch"
																							hasCheck={false}
																							progress={0}
																							logoImage={
																								parseExerciseVideo(x).image
																							}
																							bgImage={
																								parseExerciseVideo(x).image
																							}
																							detailTitle={
																								parseExerciseVideo(x)
																									.headingTitle
																							}
																							detailSubtitle={''}
																							link={parseExerciseVideo(x).url}
																							hasLikeButton={true}
																							onLike={() =>
																								onLike(
																									parseExerciseVideo(x).id
																								)
																							}
																							favorites={favorites}
																						/>
																					</div>
																				))
																		)}
																</div>

																<div className="mt-n3">
																	<p className="mb-1 mt-5">
																		<b>Do 2-3x/week</b>
																	</p>
																	<p>
																		Complete the following exercises or a
																		RunSmart strength session.
																	</p>
																	{currentWeekData?.exercises
																		?.filter((x: any) => x.is_daily != 1)
																		.map((value: any, index: number) =>
																			allNewExercises
																				.filter(
																					(x) =>
																						x.exercise_id == value.id &&
																						x.video_type === 'intro'
																				)
																				.map((x, i) => (
																					<div
																						key={i}
																						className="justify-content-center phone-video-list mb-n2"
																					>
																						<MainCard
																							videoId={
																								parseExerciseVideo(x).id
																							}
																							cardType="video"
																							// type="videoLgStretch"
																							hasCheck={false}
																							progress={0}
																							logoImage={
																								parseExerciseVideo(x).image
																							}
																							bgImage={
																								parseExerciseVideo(x).image
																							}
																							detailTitle={
																								parseExerciseVideo(x)
																									.headingTitle
																							}
																							detailSubtitle={''}
																							link={parseExerciseVideo(x).url}
																							hasLikeButton={true}
																							onLike={() =>
																								onLike(
																									parseExerciseVideo(x).id
																								)
																							}
																							favorites={favorites}
																						/>
																					</div>
																				))
																		)}
																</div>
															</div>
														) : (
															//FILES
															<div className="mt-3">
																<button
																	onClick={() => handleDownloadFile('pdf')}
																	type="button"
																	className="text-start w-100 p-3 mb-1 mx-auto btn btn-outline-secondary"
																>
																	<SaveAltIcon className="me-2 mt-n1" />
																	Download Guide
																</button>
																<button
																	onClick={() => handleDownloadFile('audio')}
																	type="button"
																	className="text-start p-3 w-100 mb-1 mx-auto btn btn-outline-secondary"
																>
																	<SaveAltIcon className="me-2 mt-n1" />
																	Download Guided Audio
																</button>
															</div>
														)}
													</div>
												</>
											)}
											{currentSelectorValue == 0 ? (
												<Col lg={12} sm={12} className="mt-md-2">
													{/* {currentVideos.map((video) => ( */}
													<div className="justify-content-center phone-video-list mb-2 mt-n2">
														<MainCard
															videoId={'808234618'}
															key={808234618}
															cardType="video"
															// type="videoLgStretch"
															hasCheck={true}
															progress={0}
															logoImage={`\\img\\midfoot_project.jpg`}
															bgImage={`\\img\\midfoot_project.jpg`}
															detailTitle={'Introduction'}
															detailSubtitle={'Midfoot Project'}
															link={getVideoLink('808234618')}
															isProgram={true}
														/>
													</div>

													<Card className="d-none d-md-block border-0 p-4 w-100">
														<p
															dangerouslySetInnerHTML={{
																__html: `${currentWorkoutVideo?.descriptions[0]}`,
															}}
														/>
													</Card>
												</Col>
											) : undefined}
										</Row>
									</Col>
								</Row>
							</div>
						</BodyComponent>
				</AppMenus>
			</Motion>
		</>
	)
}
export default WithAuth(MidfootProject)
