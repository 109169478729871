import React from 'react'
import { Col, Modal, Row } from 'react-bootstrap'
import { HOME_LOGO } from '../../modules/miscImages'

const SyncDeviceDismissModal = (props: any) => {
    const { showModal, closeModal, confirm } = props
    return (
        <Modal
            size="xl"
            className="announcement-modal"
            show={showModal}
            // show={false}
            onHide={closeModal}
            // backdrop='static'
            // keyboard={false}
            centered
        >
            <Modal.Header closeVariant='white' closeButton={true}>

            </Modal.Header>
            <Modal.Body>
                <Row>
                    <Col className='text-center' xs={12} sm={12} md={12}>
                        <img
                            style={{ width: '144px' }}
                            src={HOME_LOGO}
                            // width={LOGO_SIZE.width}
                            // height={LOGO_SIZE.height}
                            className="mt-n3 mb-3"
                            alt="RunSmart Home Logo"
                        />
                        <h3 className='font-15'>We are continuing to add integrations <br />to the RunSmart toolbox.</h3>

                        {/* <div className='details-container'>
                            <h3 className='header-text'>{title}</h3>
                            <h4 className='sub-text'>{subtitle}</h4>
                            <h5 className='start-date'>{startText}</h5>
                            <h6 className='details-text' dangerouslySetInnerHTML={{
                                __html: `${description}`
                            }} />
                            <a onClick={handleClickLink} className="link mt-3">Learn More</a>
                            {/* <a onClick={handleClickLink} className="link mt-3">Close</a> */}
                        {/* </div>  */}
                        <div className='text-center mt-3'>
                            <h5 className='text-gray'>
                                If you wish to sync a new device later, head<br /> to the integrations tab in the Account menu.
                            </h5>
                        </div>
                        <div className='text-center mt-3'>
                            <p onClick={confirm} className='text-gray'><u>Continue</u></p>
                        </div>
                    </Col>
                </Row>
            </Modal.Body>
        </Modal>
    )
}

export default SyncDeviceDismissModal