import { useState } from 'react'

/**
 * useScrollRow Component
 */

const useScrollRow = () => {
	const [isScrolling, setIsScrolling] = useState(false)
	return { isScrolling, setIsScrolling }
}

export default useScrollRow
