import { UserOverrideRecord, UsersRestCommentsRecord, UserVdotAdjustRecord } from "../api/v2/types"
import { IRaceType } from "../pages/training-plan/build/RaceType"
import { StoreSlice } from "./types"

export interface ITrainingPlanStore {
    selectedRace: IRaceType | undefined,
    setSelectedRace: (selectedRace: IRaceType) => void
    selectedRaceDate: string | null,
    setSelectedRaceDate: (date: string | null) => void,
    agreedCreateNewPlan: boolean,
    setAgreedCreateNewPlan: (bool: boolean) => void,
    currentPath: string | undefined,
    setCurrentPath: (path: string) => void,
    hasActivePlan: boolean,
    setHasActivePlan: (bool: boolean) => void,
    currentLocation: string
    setCurrentLocation: (currentLocation: string) => void,
    currentDayActivity: string
    setCurrentDayActivity: (currentDayActivity: string) => void,
    showWeatherFormModal: boolean,
    setShowWeatherFormModal: (showWeatherFormModal: boolean) => void,
    showSideBar: boolean,
    setShowSideBar: (showSideBar: boolean) => void,
    userRestComments: Array<UsersRestCommentsRecord> | null
    setUserRestComments: (userRestComments: Array<UsersRestCommentsRecord> | null) => void,
    userOverrides: Array<UserOverrideRecord> | null
    setUserOverrides: (userOverrides: Array<UserOverrideRecord> | null) => void,
    userVdotAdjustments: Array<UserVdotAdjustRecord>
    setUserVdotAdjustments: (userVdotAdjustments: Array<UserVdotAdjustRecord>) => void,
    weatherData: any,
    setWeatherData: (weatherData: any) => void,
    isOnboardingSelected: boolean,
    setIsOnboardingSelected: (isOnboardingSelected: boolean) => void
}

const createTrainingPlanStore: StoreSlice<ITrainingPlanStore> = (set) => ({
    selectedRace: undefined,
    setSelectedRace: (selectedRace: IRaceType) => set(() => ({ selectedRace: selectedRace })),
    selectedRaceDate: null,
    setSelectedRaceDate: (selectedRaceDate: string | null) => set(() => ({ selectedRaceDate: selectedRaceDate })),
    agreedCreateNewPlan: false,
    setAgreedCreateNewPlan: (bool: boolean) => set(() => ({ agreedCreateNewPlan: bool })),
    currentPath: '',
    setCurrentPath: (path: string) => set(() => ({ currentPath: path })),
    hasActivePlan: false,
    setHasActivePlan: (bool: boolean) => set(() => ({ hasActivePlan: bool })),
    currentLocation: "",
    setCurrentLocation: (currentLocation: string) => set(() => ({ currentLocation: currentLocation })),
    currentDayActivity: "",
    setCurrentDayActivity: (currentDayActivity: string) => set(() => ({ currentDayActivity: currentDayActivity })),
    showWeatherFormModal: false,
    setShowWeatherFormModal: (showWeatherFormModal: boolean) => set(() => ({ showWeatherFormModal: showWeatherFormModal })),
    showSideBar: false,
    setShowSideBar: (showSideBar: boolean) => set(() => ({ showSideBar: showSideBar })),
    userRestComments: null,
    setUserRestComments: (userRestComments: Array<UsersRestCommentsRecord> | null) => set(() => ({ userRestComments: userRestComments })),
    userOverrides: null,
    setUserOverrides: (userOverrides: Array<UserOverrideRecord> | null) => set(() => ({ userOverrides: userOverrides })),
    userVdotAdjustments: [],
    setUserVdotAdjustments: (userVdotAdjustments: Array<UserVdotAdjustRecord>) => set(() => ({ userVdotAdjustments: userVdotAdjustments })),
    weatherData: undefined,
    setWeatherData: (weatherData: any) => set(() => ({ weatherData: weatherData })),
    isOnboardingSelected: false,
    setIsOnboardingSelected: (isOnboardingSelected: boolean) => set(() => ({ isOnboardingSelected: isOnboardingSelected }))
})

export default createTrainingPlanStore