/* eslint-disable @typescript-eslint/no-unused-vars */
import { FC, useEffect, useState } from 'react'

import Grid from '@mui/material/Grid'
import Box from '@mui/material/Box'

import RSDrawer from '../../components/layout/RSDrawer'
import RSFlagButton from '../../components/buttons/RSFlagButton';
import RaceEventDetails from './RaceEventDetails';
import { UserInfoProps } from '../../modules/types'
import { AccountProfile } from '../../api/v2/account/Account.types'
import raceSelection from '../training-plan/build/RaceSelection.json'


export type SearchCardsProps = {
	accountProfile: AccountProfile,
	userInfo: any,
	data?: any,
	isFeatured?: boolean,
	sx?: object
	disabledRaces?: any,
	hasLoader?: boolean
}

const SearchCards: FC<SearchCardsProps> = ({ hasLoader, userInfo, accountProfile, data = {}, isFeatured = false, sx = {}, disabledRaces }) => {
	const { name, logo, date, place, type, color } = data
	const [open, setOpen] = useState(false)
	const [openBuild, setBuild] = useState(false)
	const [eventDetails, setEventDetails] = useState<any>()
	const [isStepOpen, setIsStepOpen] = useState<any>(false)
	// const img = logo || '/images/icons/runsmart-guy.svg'
	const img = logo || '/img-new/race-events/maraton_placeholder.png'

	useEffect(() => {
		if (!open) setEventDetails(data)
	}, [data])

	useEffect(() => {
		setIsStepOpen(false)
	}, [open])

	return <>
		<RSFlagButton
			symbol={img}
			title={name}
			date={date}
			place={place}
			onClick={() => setOpen(true)}
			sx={{ mb: 1, backgroundColor: `${color ? color : '#fff'} !important`, ...sx }}
			isFeatured={isFeatured}
			hasLoader={hasLoader}
		>
			<Grid container xs={12} >
				{type?.map((type: any, typeI: number) => (
					<Box key={typeI + 'type'}
						sx={{ height: '30px', width: '30px', mt: '5px', mr: '10px', borderRadius: '50%', fontSize: '11px !important', fontFamily: 'Poppins !important', lineHeight: '1em !important', backgroundColor: isFeatured ? 'white' : '#FCC26A', color: '#010101', alignContent: 'center', justifyContent: 'center', textAlign: 'center' }}
					>
						{raceSelection?.find((rs: any) => rs.title.toLowerCase() === type.toLowerCase())?.cardValue}
					</Box>
				))}
			</Grid>
		</RSFlagButton>

		<RSDrawer bottom maxHeight={isStepOpen ? '0' : '90%'} open={open} onClose={() => setOpen(false)}>

			<RaceEventDetails disabledRaces={disabledRaces} userInfo={userInfo} accountProfile={accountProfile} details={eventDetails} stepOpen={setIsStepOpen} />

		</RSDrawer>

	</>
}

export default SearchCards;
