/* eslint-disable @typescript-eslint/no-unused-vars */
import InstabotHide from '../../components/styles/InstabotHide'
import WithAuth from '../../components/WithAuth'
import AppMenus from '../../components/layout/AppMenus'
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Billing from '../../components/page-specific/account/Billing'
import AccountSubmitButtons from '../../components/buttons/AuthSubmitButtons'
import ChooseYourPlanForm from '../../components/forms/ChooseYourPlanForm'
import useQueryParams from '../../hooks/useQueryParams'
import GenericAlert from '../../components/misc/GenericAlert'
import useAlert from '../../hooks/useAlert'
// import Badge from 'react-bootstrap/Badge'
import { DEFAULT_COSTS } from '../../modules/values'
import { AuthPageProps } from '../../modules/types'
import { HAS_SALE, RECURLY_PUBLIC_KEY } from '../../modules/cliEnvValues'
import { getPlanCosts, createSubscription } from '../../modules/accountActions'
import { Elements, RecurlyProvider } from '@recurly/react-recurly'
import { FC, useState, useEffect } from 'react'
import { navReq } from '../../modules/apiConsume'
import ReactPixel from 'react-facebook-pixel';
import ApplePayButton from '../../components/buttons/ApplePayButton'
import secureLocalStorage from 'react-secure-storage'
import useStore from '../../store/useStore'
import { IComponentStore } from '../../store/createComponentStore'
import GooglePayButton from '../../components/buttons/GooglePayButton'
import useSurveyActions from '../onboarding/useSurveyActions'
// import ApplePayButton from '../../components/buttons/ApplePayButton'


// const HEADER_1 = `
// When your free trial ends, your account will be
// charged for the selected plan.You can change
// or cancel anytime.
`

// Header Tagline
// const HEADER_TAGLINE = `
// You can change or cancel your plan at any time.
// `

// Header Billing
// const HEADER_BILLING = `
// You're two steps away from fast & injury-free running.
// You may cancel anytime during your free trial to avoid charges.
// `

const HEADER_BILLING = `Sign up requires payment information.
No charge is applied during free trials. We’ll
remind you before any charges. Cancel
anytime, no commitment.`


// Default Header
// const DEFAULT_HEADER = `Choose a plan below to get started.`

/**
 * ChoosePlan Component
 */

const ChoosePlan: FC<AuthPageProps> = ({ userInfo }) => {
	// Properties
	const [monthly, setMonthly] = useState(true)
	const [showBilling, setShowBilling] = useState(false)
	const [title, setTitle] = useState('Choose Your Plan')
	const [costs, setCosts] = useState({ ...DEFAULT_COSTS })
	// const [planCode, setPlanCode] = useState<string>("")
	// const [isApplePaySupport, setIsApplePaySupport] = useState<boolean>(false)

	const isAppleSignup = secureLocalStorage.getItem("isAppleSignup") || false
	const { setIsLoading, setLoadingMsg } = useStore((state: IComponentStore) => state)

	// const loginType = localStorage.getItem("rs_login_type") || "email";

	// Hooks
	const {
		showAlert,
		onAlertOpen,
		onAlertClose,
		alertTitle,
		alertMessage,
		setAlertMessage,
		alertVariant,
		setAlertVariant,
	} = useAlert()

	// Params
	const { offer, skip_info, development, skipSurvey } = useQueryParams()
	const hasOffer = Number(offer) == 2 ? true : false
	const hasSale = Number(offer) == 2 || HAS_SALE ? true : false
	const skipInfo = skip_info ? true : false
	// const { development } = useQueryParams()

	// const makeid = (length: number) => {
	// 	var result = '';
	// 	var characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
	// 	var charactersLength = characters.length;
	// 	for (var i = 0; i < length; i++) {
	// 		result += characters.charAt(Math.floor(Math.random() * charactersLength));
	// 	}
	// 	return result;
	// }

	// Set Plan Costs
	const setPlanCosts = async () => {
		if (HAS_SALE) {
			setLoadingMsg('Please wait')
			setIsLoading(true)
		}
		const newCosts = await getPlanCosts({
			hasOffer,
			isRenew: hasSale,
			isYearlyRenewOnly: hasSale,
			hasSale,
		})

		if (HAS_SALE) {
			setLoadingMsg(undefined)
			setIsLoading(false)
		}
		setCosts(newCosts)
	}

	// On Choose Plan Submit
	const onChoosePlanSubmit = () => {
		// ReactPixel.trackCustom('InitiateCheckout', { k: new Date().getTime() });
		setShowBilling(true)
		setTitle('Billing')
	}

	// After Billing Change
	const afterBillingChange = async () => {
		// ReactPixel.trackCustom('AddPaymentInfo', { k: new Date().getTime() });
		const planCode = monthly ? costs.monthlyCode : costs.annualCode
		await createSubscription(planCode)
	}

	// After Billing Process
	const afterBillingProcess = () => {
		// ReactPixel.trackCustom('AddPaymentInfo', { k: new Date().getTime() });
		if (!skipInfo) navReq('/#/user-info')
	}

	// Mount Effect
	useEffect(() => {
		setPlanCosts()
	}, [])

	// useEffect(() => {
	// 	// const processZap = async () => await pushDetailsToZapier(Number(userInfo?.account_id))

	// 	// if (userInfo?.account_id && skipSurvey && skipSurvey === "1")
	// 	// 	processZap()
	// 	alert("test")
	// }, [])

	useEffect(() => {
		if (title === "Choose Your Plan") {
			ReactPixel.trackCustom('Lead', { k: new Date().getTime() });
		}
	}, [title])


	useEffect(() => {
		setPlanCosts()
	}, [offer])


	// Render
	return (
		<>

			{/* Styles */}
			<InstabotHide />

			{/* Alerts */}
			<GenericAlert
				show={showAlert}
				title={alertTitle}
				message={alertMessage}
				variant={alertVariant}
				onClose={onAlertClose}
				mustClose
			/>

			{/* Page */}
			<AppMenus hideOptions hideBackButton showLogout>

				<Container fluid="md" className="px-form-5 signup-container">
					<div className="user-info-page--wrapper container-center">
						{/* <Row className="mb-3 user-step--title  d-block text-cetner d-md-none">
							<Col className="text-center">
								<Badge
									as="h5"
									style={{ fontSize: '1rem' }}
									bg="black"
									className="rounded-pill--signup bg-black"
								>
									{showBilling ? 'Step 3 of 4' : 'Step 2 of 4'}
								</Badge>
							</Col>
						</Row> */}

						{/* Title */}
						<Row className="mb-2 user-login--title">
							<Col>
								<h3>{title}</h3>
							</Col>
						</Row>

						{/* Switch Page Link */}
						{/* <Row className="mb-3 user-step--title">
							<Col>
								<Badge
									as="h5"
									style={{ fontSize: '1rem' }}
									bg="dark"
									className="rounded-pill--signup bg-black"
								>
									{showBilling ? 'Step 3 of 4' : 'Step 2 of 4'}
								</Badge>
							</Col>
						</Row> */}

						{/* Signup Header */}
						<Row className="m-auto">
							<Col className="m-auto">
								<p className="choose-plan--text">
									{showBilling ? (
										<>
											{HEADER_BILLING}
											{/* {development === "1" ? ( */}
											<RecurlyProvider publicKey={RECURLY_PUBLIC_KEY}>
												<Elements>
													<ApplePayButton
														planCode={monthly ? costs.monthlyCode : costs.annualCode}
														tranxType="create-signup"
														afterBillingChange={afterBillingChange}
														afterBillingProcess={afterBillingProcess}
														handleContinue={() => onChoosePlanSubmit()}
														isAppleSignup={isAppleSignup as boolean}
													/>
													{development === "1" ? (
														<GooglePayButton
															planCode={monthly ? costs.monthlyCode : costs.annualCode}
															tranxType="create-signup"
															afterBillingChange={afterBillingChange}
															afterBillingProcess={afterBillingProcess}
														/>)
														: undefined
													}
												</Elements>
											</RecurlyProvider>
											{/* ) : undefined} */}

										</>
									) : (
										<>
											{/* {HEADER_1} */}
											{/* <br />
											<br />
											<strong>{HEADER_TAGLINE}</strong> */}
										</>
									)}
								</p>
							</Col>
						</Row>
					</div>
					<div className="account-form m-auto">
						{/* Choose Plan Form */}
						{!showBilling ? (
							<>
								<ChooseYourPlanForm
									hasSale={hasSale}
									setMonthly={setMonthly}
									monthCost={costs.monthly}
									yearCost={costs.annual}
								/>

								{!isAppleSignup ? (
									<>
										<AccountSubmitButtons
											primaryText="Continue"
											primaryAction={onChoosePlanSubmit}
											addClassName="choose-plan-btn"
										/>
										{development === "1" ? (
											<>
												<h5 className='text-center mb-4'>OR</h5>
												<RecurlyProvider publicKey={RECURLY_PUBLIC_KEY}>
													<Elements>
														<GooglePayButton planCode={monthly ? costs.monthlyCode : costs.annualCode} tranxType="create-signup" />
													</Elements>
												</RecurlyProvider>
											</>
										) : undefined}
									</>
								) :
									(
										<RecurlyProvider publicKey={RECURLY_PUBLIC_KEY}>
											<Elements>
												<ApplePayButton
													planCode={monthly ? costs.monthlyCode : costs.annualCode}
													tranxType="create"
													afterBillingChange={afterBillingChange}
													afterBillingProcess={afterBillingProcess}
													handleContinue={() => onChoosePlanSubmit()}
													isAppleSignup={isAppleSignup as boolean}
												/>
											</Elements>
										</RecurlyProvider>
									)}

								{/* {planCode ? (
									 : undefined} */}
							</>
						) : (
							<RecurlyProvider publicKey={RECURLY_PUBLIC_KEY}>
								<Elements>
									<Billing
										userInfo={userInfo}
										hideDetails
										onAlertOpen={onAlertOpen}
										setAlertMessage={setAlertMessage}
										setAlertVariant={setAlertVariant}
										afterBillingChange={afterBillingChange}
										afterBillingProcess={afterBillingProcess}
										isAccount={false}
									/>
								</Elements>
							</RecurlyProvider>
						)}
					</div>
				</Container>
			</AppMenus>
		</>
	)
}

export default WithAuth(ChoosePlan, true)
