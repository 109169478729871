import { useState, useEffect, FC } from 'react'

import Grid from '@mui/material/Grid'
import Box from '@mui/material/Box'
import IconButton from '@mui/material/IconButton';
// import ArrowForward from '@mui/icons-material/ArrowForwardIos';

import RSFlexCard from '../../components/cards/RSFlexCard'
import RSCardCont from '../../components/cards/RSCardCont'

import { getActivePlan } from '../../modules/programActions';
import { AuthPageProps } from '../../modules/types';
import { ActiveProgram } from '../../api/v2/programs/programs.types';
import ActivePlanUpdatePanel from '../../components/misc/ActivePlanUpdatePanel';
import { useNavigate } from 'react-router-dom'

const EMPTY_PLAN: ActiveProgram = {
	program_name: 'Explore Plans',
	id: 0,
	progress_percent: 0,
	user_id: 0,
	program_id: 0,
	logo: '',
	bg_color: '#1a1a12',
	next_video: {
		video_id: 0,
		video_title: 'Find the perfect plan to supplement your running.'
	}
}

const AdditionalPlans: FC<AuthPageProps> = ({ userInfo, hasLoaded }) => {

	const [plansList, setPlansList] = useState<Array<ActiveProgram>>([])
	// const [suggestVideos, setSuggestVideos] = useState<boolean>(true)
	const [showUpdatePlanPanel, setShowUpdatePlanPanel] = useState<boolean>(false)
	const [selectedActivePlanDetails, setSelectedActivePlanDetails] = useState<ActiveProgram>({})
	const navigate = useNavigate()
	const [isLoading, setIsLoading] = useState<boolean>(false)
	
	const getActivePlanDetails = async (accountId: number) => {
		setIsLoading(true)
		const activePlansData = await getActivePlan(accountId)
		//exclude training plan from the list
		const activePlanList: ActiveProgram[] = activePlansData.data.result.filter((plan: ActiveProgram) => plan.program_id !== 5).filter((plan: ActiveProgram) => plan.program_id !== 19)
		setPlansList(activePlanList)
		setIsLoading(false)
		// setSuggestVideos(false)
	}

	useEffect(() => {
		if (userInfo?.account_id && !showUpdatePlanPanel) getActivePlanDetails(userInfo?.account_id)
	}, [userInfo?.account_id, showUpdatePlanPanel])

	const renderEmptyPlans = () => {
		return [<RSCardCont onClick={() => { navigate(`/explore?page=1`) }} isLoading={hasLoaded && !isLoading ? false : true} key={EMPTY_PLAN.id + '-plan'} sx={{ maxWidth: '450px', p: 0, overflow: 'hidden', width: '100%' }}>
			<Grid container xs={3} sx={{ backgroundColor: `${EMPTY_PLAN?.bg_color}`, minHeight: '80px', position: 'relative', zIndex: 1 }} justifyContent='center' alignItems='center'>
				<Box sx={{ position: 'absolute', width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
					<i style={{ fontSize: '52px', color: '#fff' }} className="fa-thin fa-compass"></i>
				</Box>
			</Grid>
			<Grid container xs={9} sx={{ minHeight: '80px', position: 'relative' }}>
				<Box sx={{ background: `${EMPTY_PLAN?.bg_color}`, width: '33px', height: '115px', position: 'absolute', transform: 'rotate(16deg)', left: -22, top: -12 }} />
				<Grid container xs={12} sx={{ pl: '30px', pr: 1, flexDirection: 'column' }} justifyContent='center' alignItems='flex-start'>
					<Box sx={{ fontSize: '16px', fontFamily: 'Poppins-Medium', lineHeight: '1.2em', textAlign: 'left' }}>{EMPTY_PLAN?.program_name}</Box>
					<Box sx={{ fontSize: '13px', fontFamily: 'Poppins', lineHeight: '1.2em', textAlign: 'left' }}>{`${EMPTY_PLAN?.progress_percent == 0 ? '' : 'Up Next: '}`}{EMPTY_PLAN.next_video?.video_title}</Box>
				</Grid>
			</Grid>
		</RSCardCont>]
	}

	const renderAdditionalPlans = () => {
		return plansList?.map((plan, planIndex: number) =>
			<RSCardCont onClick={() => { navigate(`/explore?p_id=${plan?.program_id}&videoId=${plan?.next_video?.video_id}&tab=1${plan?.next_video?.video_title?.toLowerCase().includes('week') ? '&week=' + plan?.next_video?.video_order : ''}`) }} isLoading={hasLoaded && !isLoading ? false : true} key={planIndex + '-plan'} sx={{ maxWidth: '450px', height: '80px', p: 0, overflow: 'hidden', width: '100%' }}>
				<Box sx={{ height: '80px', width: '100%', display: 'flex', justifyContent: 'flex-start', alignItems: 'center' }}>
					<Grid container xs={3} sx={{ backgroundColor: `${plan?.bg_color}`, position: 'relative', height: '80px', zIndex: 1 }}>
						<Box sx={{ position: 'absolute', height: '100%', width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
							<img src={plan?.logo} style={{ scale: '0.8', margin: 'auto', marginRight: '-3px' }} />
						</Box>
					</Grid>
					<Grid container xs={9} sx={{ height: '80px', position: 'relative', overflow: 'hidden' }}>
						<Box sx={{ background: `${plan?.bg_color}`, width: '33px', height: '115px', position: 'absolute', transform: 'rotate(16deg)', left: -22, top: -12 }} />
						<Grid container xs={12} sx={{ pl: '30px', pr: '24px', flexDirection: 'column', height: '80px' }} justifyContent='center' alignItems='flex-start'>
							<Box sx={{ fontSize: '16px', fontFamily: 'Poppins-Medium', lineHeight: '1.2em', textAlign: 'left' }}>{plan?.program_name}</Box>
							<Box sx={{ fontSize: '13px', fontFamily: 'Poppins', lineHeight: '1.2em', textAlign: 'left' }}>{`${plan?.progress_percent == 0 || plan?.progress_percent == 100 ? '' : 'Up Next: '}`}{plan?.next_video?.video_title?.toLowerCase() === plan?.program_name?.toLowerCase() ? '' : plan?.next_video?.video_title || ''}</Box>
							{plan?.next_video?.video_length ? <Box sx={{ fontSize: '13px', fontFamily: 'Poppins', lineHeight: '1.2em', textAlign: 'left', fontStyle: 'italic' }}>{Math.round(plan.next_video.video_length / 60)} min</Box> : <></>}
						</Grid>
					</Grid>
					<IconButton onClick={(e) => {
						e.stopPropagation();
						setShowUpdatePlanPanel(true)
						setSelectedActivePlanDetails({
							program_name: plan.program_name,
							id: plan.id,
							progress_percent: plan.progress_percent,
							user_id: userInfo?.account_id,
							program_id: plan.program_id
						})
					}} sx={{ color: '#010101', position: 'absolute', top: 0, right: 0, fontSize: '16px', p: '10px' }}>
						<i className="fa-solid fa-ellipsis-vertical"></i>
					</IconButton>
					<Box className='home-percent-cont'>
						{plan.progress_percent}% Complete
					</Box>
				</Box>
			</RSCardCont>)
	}

	return <>
		<RSFlexCard videoList={plansList?.length ? renderAdditionalPlans() : renderEmptyPlans()} sx={{ py: 0 }} />

		<ActivePlanUpdatePanel
			show={showUpdatePlanPanel}
			setShow={setShowUpdatePlanPanel}
			programTitle={selectedActivePlanDetails.program_name}
			activePlanId={selectedActivePlanDetails.id}
			activePlanProgress={selectedActivePlanDetails.progress_percent}
			userId={userInfo?.account_id}
			programId={selectedActivePlanDetails.program_id}
		/>
	</>
}

export default AdditionalPlans;
