// import { Button } from 'react-bootstrap'
// import CustomPopOver from '../../../components/custom-popover'
import { Card } from 'react-bootstrap'
import { useNavigate } from 'react-router-dom'

const CrossTraining = (props: any) => {

    const navigate = useNavigate()
    const {
        dayData,
        getWorkoutVideoDetails,
        loadingValues,
    } = props

    const getFoamRollingLink = (link: string) => {
        const linkA = link.replace('foam-rolling', '').split('#')[1].split('/')
        const convertedLink = `/${linkA[1]}/${linkA[3]}`
        return convertedLink
    }

    return (
        <div className='mx-md-4 mx-n4 mt-4'>
            {
                !loadingValues && dayData?.workouts ?
                    dayData?.workouts?.length > 0 && dayData?.workouts[0].title !== "" && (
                        <div className="workout-video mt-3 mb-4 mb-md-0">
                            <h2 className="text-capitalize letter-space font-18 mb-1 cross-train--desc">
                                Cross Training Workout
                            </h2>
                            {
                                dayData?.workouts.filter((x: any) => x.mid == dayData.marathon.id && x.title === "Cross Train" || x.mid == dayData.marathon.id && x.link === "").filter((x: any) => x.title !== '').filter((x: any) => x.title.length > 4 || x.description.length > 4).map((workout: any, index: number) => (
                                    <Card key={workout.workoutId} className={`pt-2 px-3 pb-3 mb-2 ${index > 0 ? 'mt-n4 mt-md-0' : ''}`}>
                                        <div className="center-align">
                                            <div style={{ marginBottom: -20 }} className="workout-info flex-content-column flex-content-start ">
                                                {workout.title !== "Cross Train" && workout.title.length > 4 || workout.title.length > 4 ? (
                                                    <h6 className="text-overflow-el" style={{ marginBottom: 0 }}>{workout.title}</h6>) : undefined
                                                }
                                                {
                                                    workout.description !== "" ? (
                                                        <p className="small-text opacity-80" style={{ marginBottom: 0 }}>
                                                            <img
                                                                className="w-12 me-1 mt-n1"
                                                                src="./images/icons/tick-mark.png"
                                                                alt="tick-mark"
                                                            />
                                                            {workout.description}
                                                        </p>
                                                    ) : undefined
                                                }
                                                <p className="pointer font-13 opacity-90 font-weight-semibold color-primary"
                                                >
                                                    <span></span>
                                                </p>
                                            </div>
                                        </div>
                                    </Card>
                                ))
                            }
                            {
                                dayData?.workouts.filter((x: any) => x.mid == dayData.marathon.id && x.title !== "Cross Train" && x.link !== "").filter((x: any) => x.title !== '').map((workout: any) => (
                                    <Card onClick={() => navigate(`${workout?.link.includes('foam-rolling') ? getFoamRollingLink(workout?.link) : workout?.link.split("#")[1]}?type=mobile&from=training-plan`)} key={workout?.workoutId} className="pt-2 px-3 pb-3 mb-2">
                                        <div className="center-align">
                                            <div className="workout-img me-3 mt-2">
                                                <img
                                                    className='pointer'
                                                    style={{ width: '100%' }}
                                                    src={getWorkoutVideoDetails(workout?.link)?.img_title}
                                                />
                                                <div className="like-option" onClick={() => {
                                                    // workout.link.split("#")[1].includes('foam-rolling') ?
                                                    //     navigate(`/exercises/${workout.link.split("#")[1].split('/')[3]}`)
                                                    //     : 
                                                    navigate(`${workout?.link.split("#")[1]}?type=mobile&from=training-plan`)

                                                }}>
                                                    {/* <img src="./images/icons/heart-white.png" alt="Like" /> */}
                                                    {/* <img
                                                        className='pointer'
                                                        width={30}
                                                        src="./images/icons/play.png"
                                                        alt="Like"
                                                    /> */}
                                                </div>
                                            </div>

                                            <div style={{ marginBottom: -20 }} className="workout-info flex-content-column flex-content-start ">
                                                <h6 className="text-overflow-el" style={{ marginBottom: 0 }}>{workout?.title}</h6>
                                                <p className="font-12 opacity-50" style={{ marginBottom: 0 }}>
                                                    <img
                                                        className="w-12 me-1"
                                                        src="./images/icons/tick-mark.png"
                                                        alt="tick-mark"
                                                    />
                                                    {getWorkoutVideoDetails(workout?.link)?.main_headline}
                                                </p>
                                                {/* <div className="weather-alert">
                                                    <CustomPopOver
                                                        icon={
                                                            <p className="pointer font-12 opacity-90 font-weight-semibold color-primary"
                                                            >
                                                                View Details
                                                            </p>
                                                        }
                                                    >
                                                        <div className="popover-custom">
                                                            <h6 className="font-weight-semibold">
                                                                {workout?.title}
                                                            </h6>
                                                            <p className="font-12">
                                                                {workout?.description?.replace(/[^\w ]/, '')}
                                                            </p>
                                                            <Button
                                                                onClick={() => navigate(`${workout?.link.split("#")[1]}`)}
                                                                size="lg"
                                                                variant="dark"
                                                                className="rounded-pill color-primary-bg save-btn mt-4"
                                                            >
                                                                Get Started
                                                            </Button>
                                                        </div>
                                                    </CustomPopOver>
                                                </div> */}
                                            </div>
                                        </div>
                                    </Card>
                                ))}

                        </div>
                    ) : null
            }
        </div>
    )
}

export default CrossTraining