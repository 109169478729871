import { FC } from 'react'
import classNames from 'classnames'

// BillingUpdateMessage Props
export type BillingUpdateMessageProps = {
	hasStyledText?: boolean
	isBlack?: boolean
}

/**
 * BillingUpdateMessage Component
 */

const BillingUpdateMessage: FC<BillingUpdateMessageProps> = ({
	hasStyledText,
	isBlack = false,
}) => {
	// Properties
	const className = hasStyledText
		? classNames('text-center', isBlack ? undefined : 'text-danger')
		: undefined

	// Render
	return (
		<p className={className}>
			<strong>Your billing information requires updating.</strong>
			<br />
			If you believe you received this message in error, please go to
			RunSmartSupport.com and submit a ticket.
		</p>
	)
}

export default BillingUpdateMessage
