import { useRef, useEffect } from 'react'

/**
 * useSlideDirection Hook
 */

const useSlideDirectionHook = () => {
	const slideDirection = useRef({slide: 'left'})
	const emptySlideDirection = () => {
		slideDirection.current.slide = ""
	}

	useEffect(() => {
    localStorage.setItem('page', 'pw')
    return emptySlideDirection()
	}, [])

	return slideDirection
}

export default useSlideDirectionHook
