/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useEffect, useState } from 'react'
import useVideoProgress from '../../hooks/useVideoProgress'
import { Video } from '../../modules/videos/allVideos'
import useFavorites from '../../pages/favorites/useFavorites'
import ProgramMobileView from '../../pages/programs/v2/ProgramMobileView'
import Assessment from '../../pages/recover/Assessment'
import recoverVideos from '../../pages/recover/RecoverVideos.json'
import assessmentsDetails from '../../pages/recover/AssessmentsDetails.json'
import { Button } from 'react-bootstrap'
import useStore from '../../store/useStore'
import { ITrainingStore } from '../../store/createTrainingStore'
import { getReq, postReq, putReq } from '../../modules/apiConsume'
import { IComponentStore } from '../../store/createComponentStore'
import GenericModal from '../misc/GenericModal'
import moment from 'moment'
import MobilityVideos from './MobilityVideos'

interface IAssessment {
    assessmentId: number,
    areaId: number
}

export interface Recover {
    id?: number
    user_id: number
    mobility_assessment: string
    strength_assessment: string
    status: number
    date_started?: Date
    last_modified?: Date
}

const RecoveryAssessments = (props: any) => {
    const [currentProgress, setCurrentProgress] = useState(1)
    const [selectedAssessment, setSelectedAssessment] = useState<IAssessment[]>([])

    const { setIsLoading, setLoadingMsg } = useStore((state: IComponentStore) => state)

    const [isLoadingMark, setIsLoadingMark] = useState(false)
    const [showAssessment, setShowAssessment] = useState(false)

    const [currentVideoId, setCurrentVideoId] = useState("")
    const [recoveryVideos, setRecoveryVideos] = useState<Video[]>([])
    const [isPlaying, setIsPlaying] = useState(false)
    const [isFavorite, setIsFavorite] = useState(false)
    const [showConfirmRecoverModal, setShowConfirmRecoverModal] = useState(false)

    const [currentWorkoutVideo, setCurrentWorkoutVideo] = useState<Video>()

    const { currentRecover, setCurrentRecover } = useStore((state: ITrainingStore) => state)

    const [assessmentValues, setAssessmentValues] = useState<any>()

    const { userInfo, handleUpdateRecovery, currentData, closeAssessment } = props

    const [vimeoData, setVimeoData] = useState<any>()

    const info = useVideoProgress({ videos: recoveryVideos as Video[], userInfo, autoReload: true, realoadTriggeer: currentVideoId })

    const recoveryAssessment = localStorage.getItem('recoveryAssessment')

    const { favorites, toggleFavorite, isLoading, getFavorites, getIsFavorited } = useFavorites(userInfo?.account_id as number, undefined, 'others', true)

    const getVideoDetails = (vidId?: string) => {
        const video = recoverVideos.find(x => x.type === "assessment" && x.vimeo_id === vidId)
        return { id: video?.id, videoId: video?.vimeo_id, title: video?.title }
    }

    const onLike = async (videoId: string) => {
        const isLiked = favorites && favorites.find((x: any) => x.id === videoId) ? true : false;
        await toggleFavorite(userInfo?.account_id as number, Number(videoId), !isLiked)
    }

    const handleSelectAssessmentArea = (assessmentId: number, areaId: number) => {

        const current = selectedAssessment.find(x => x.assessmentId == assessmentId && x.areaId == areaId)

        if (current)
            setSelectedAssessment([...selectedAssessment.filter(x => x.areaId != current.areaId)])

        else
            setSelectedAssessment([...selectedAssessment, { assessmentId: assessmentId, areaId: areaId }])
    }

    const getCurrentRecover = async () => {
        const recover = await getReq('/v2/recover/user?user_id=' + userInfo?.account_id)
        setCurrentRecover(recover.data.result[0])
    }

    const getAssessmentTypes = () => {
        const mainAssessment: any = [];

        if (currentRecover) {
            const mobilityAssessments = JSON.parse(currentRecover.mobility_assessment as string)
            const strengthAssessments = JSON.parse(currentRecover.strength_assessment as string)

            mobilityAssessments.forEach((e: any) => {
                const assessment = assessmentsDetails.find(x => x.id == e.assessmentId)
                mainAssessment.push({ id: assessment?.id, title: assessment?.assessment, type: assessment?.type })
            });

            strengthAssessments.forEach((e: any) => {
                const assessment = assessmentsDetails.find(x => x.id == e.assessmentId)
                mainAssessment.push({ id: assessment?.id, title: assessment?.assessment, type: assessment?.type })
            });
        }

        const uniqueValues = mainAssessment.filter((a: any, i: any) => mainAssessment.findIndex((s: any) => a.title === s.title) === i)
        setAssessmentValues(uniqueValues)
    }

    const handleSubmitAssessment = async () => {
        const data: Recover = {
            user_id: userInfo?.account_id as number,
            mobility_assessment: JSON.stringify(selectedAssessment.filter(x => x.assessmentId <= 4)),
            strength_assessment: JSON.stringify(selectedAssessment.filter(x => x.assessmentId >= 5)),
            status: 1,
        }
        closeAssessment?.()
        setLoadingMsg(`Please wait while we customize your training.`)
        setIsLoading(true)

        if (currentRecover)
            await putReq('/v2/recover', { ...currentRecover, ...data }).then(async () => {
                await getCurrentRecover()
                setSelectedAssessment([])
                setIsLoading(false)
                setShowConfirmRecoverModal(true)
                setShowAssessment(false)
                setLoadingMsg(undefined)
                localStorage.setItem('assessmentStatus', 'active')
                localStorage.setItem('selectedAssessment', '[]')
                localStorage.setItem('previousActivityStatus', 'parsed')
                localStorage.setItem('recoveryAssessment', '1')
            })
        else
            await postReq('/v2/recover', data).then(async () => {
                await getCurrentRecover()
                setSelectedAssessment([])
                setShowAssessment(false)
                setIsLoading(false)
                setLoadingMsg(undefined)
                setShowConfirmRecoverModal(true)
                localStorage.setItem('assessmentStatus', 'active')
                localStorage.setItem('selectedAssessment', '[]')
                localStorage.setItem('previousActivityStatus', 'parsed')
                localStorage.setItem('recoveryAssessment', '1')
            })
    }

    const handleButtonClick = async () => {
        if (recoveryAssessment === "1" && !showAssessment && !closeAssessment) {
            setShowAssessment(true)
        }
        else
            if (currentProgress == 0) {
                localStorage.setItem('assessmentStatus', 'started')
                localStorage.setItem('previousActivityStatus', 'parsed')
                localStorage.setItem('recoverStatus', 'unrecovered')
                setCurrentProgress(1)
            }
            else if (currentProgress > 0) {
                if (currentProgress < assessmentsDetails.length) {
                    localStorage.setItem('selectedAssessment', JSON.stringify(selectedAssessment))
                    setCurrentProgress((prev) => prev + 1)
                }
                else if (currentProgress == 7) {
                    handleSubmitAssessment()
                }
            }
    }

    const assignVideo = (videoId: string) => {
        const video: Video = {
            id: videoId,
            url: '/#/programs/runsmart-recover?videoId=' + videoId,
            // image: '\\img\\recover.jpg',
            image: `https://vumbnail.com/${videoId}.jpg`,
            category: 'Recover',
            headingTitle: '',
            headingSubtitle: getVideoDetails().title as string,
            detailsLine1: '',
            detailsLine2: '',
            headlines: '' || [],
            descriptions: [] || []
        }

        return video
    }

    const applyPreviousActivity = () => {

        let playHistory = localStorage.getItem('playHistory') as any

        const isAssessmentStarted = localStorage.getItem('assessmentStatus') === "started" ? true : false
        const isAssessmentActive = localStorage.getItem('assessmentStatus') === "active" ? true : false

        const currentProgress = Number(localStorage.getItem('currentProgress')) || undefined

        let selectedAssessment = localStorage.getItem('selectedAssessment') as any

        if (selectedAssessment)
            selectedAssessment = JSON.parse(selectedAssessment)
        else
            selectedAssessment = []

        if (!playHistory || playHistory === '') {
            playHistory = null;
        }
        else
            playHistory = JSON.parse(playHistory as string)

        if (playHistory && (!isAssessmentStarted && !isAssessmentActive)) {
            setCurrentWorkoutVideo(assignVideo(playHistory.videoId))
            localStorage.setItem('previousActivityStatus', 'parsed')
        }
        else if (isAssessmentStarted) {
            setSelectedAssessment(selectedAssessment)
            setCurrentProgress(currentProgress as number)
            // CONFIRMATION.map((x, i) => {
            //     handleConfirmation(i)
            // })

            // setShowAssessment(true)

            localStorage.setItem('previousActivityStatus', 'parsed')
        }
    }

    const handleMarkAsFavorite = async (isChecked: boolean) => {
        if (currentWorkoutVideo) {
            setIsLoadingMark(true)
            await toggleFavorite(userInfo?.account_id as number, Number(currentWorkoutVideo.id), isChecked)
            await getFavorites()
            setIsLoadingMark(false)
        }
    }


    const getVideoDuration = async (videoId: string) => {

        const response = await fetch(
            `https://vimeo.com/api/oembed.json?url=https://player.vimeo.com/video/${videoId}`
        );
        const data = await response.json();
        if (data) {
            const duration = Math.round(data?.duration / 60)
            setVimeoData(`${duration} ${duration <= 1 ? 'minute' : 'minutes'}`)
        }
    }

    const handleOnPlay = async () => {
        setIsPlaying(true)
    }

    const updateAssessmentVideos = async () => {
        if (assessmentValues) {
            const mobility = assessmentValues.filter((x: any) => x.type === "mobility")
            const mobilityVideos: any = []
            const mobilityPieces: any = []

            for (const m of mobility) {
                const mob = assessmentsDetails.find((x: any) => x.id == m.id)
                if (mob)
                    mob.pieces.forEach((x: any) => {
                        mobilityPieces.push(x)
                    })
            }

            for (const mb of mobilityPieces) {
                mobilityVideos.push(mb.vimeo_id)
            }

            const strength = assessmentValues.filter((x: any) => x.type === "strength")
            const strengthVideos: any = []
            const strengthPieces: any = []

            for (const s of strength) {
                const str = assessmentsDetails.find((x: any) => x.id == s.id)
                if (str)
                    str.pieces.forEach((x: any) => {
                        strengthPieces.push(x)
                    })
            }

            for (const sp of strengthPieces) {
                strengthVideos.push(sp.vimeo_id)
            }
            await handleUpdateRecovery(mobilityVideos, strengthVideos)
        }
    }

    useEffect(() => {
        setIsPlaying(false)
        if (currentProgress > 0) {
            localStorage.setItem('currentProgress', String(currentProgress))
            const recoverVideo = recoverVideos.find(x => x.id == (currentProgress + 6))

            const video: Video = {
                id: recoverVideo?.vimeo_id as string,
                url: '/#/programs/runsmart-recover?videoId=' + recoverVideo?.vimeo_id,
                image: `https://vumbnail.com/${recoverVideo?.vimeo_id}.jpg`,
                category: 'Recover',
                headingTitle: 'Assessments',
                headingSubtitle: getVideoDetails(recoverVideo?.vimeo_id).title as string,
                detailsLine1: assessmentsDetails[currentProgress - 1]?.type,
                detailsLine2: '',
                headlines: '' || [],
                descriptions: [] || []
            }
            setCurrentWorkoutVideo(video)
        }
    }, [currentProgress])

    useEffect(() => {
        const isFavorite = favorites.find(x => x.id === currentWorkoutVideo?.id)
        if (isFavorite)
            setIsFavorite(true)
        else
            setIsFavorite(false)

    }, [favorites, currentWorkoutVideo])

    useEffect(() => {
        if (currentWorkoutVideo?.id)
            getVideoDuration(currentWorkoutVideo.id)
    }, [currentWorkoutVideo])

    useEffect(() => {
        if (userInfo) {
            applyPreviousActivity()
            getCurrentRecover()
        }
    }, [userInfo]);

    useEffect(() => {
        if (currentRecover) {
            getAssessmentTypes()
        }
    }, [currentRecover]);

    useEffect(() => {
        if (assessmentValues && recoveryAssessment == "1") {
            updateAssessmentVideos()
        }
    }, [assessmentValues, recoveryAssessment]);


    return (
        <div className='w-100 recovery-assessment'>
            <GenericModal
                type='recover'
                setShow={setShowConfirmRecoverModal}
                show={showConfirmRecoverModal}
                title='Assessment Complete'
                text={`Your assessment was completed ${moment(currentRecover?.date_started).format('MM/D/Y')}. Your recovery plan will focus on your ${assessmentValues?.map((val: any) => val.title + "")}. `}
                subtext={"You may repeat the assessment anytime."}
                button1Text="OK"
                button1Action={() => {
                    setCurrentProgress(1)
                    setShowConfirmRecoverModal(false)
                }
                }
            />
            {
                recoveryAssessment === "1" && !showAssessment && currentData?.raceResults?.recoveryPlan?.mobility_video_ids && !closeAssessment ? (
                    <>
                        <MobilityVideos videoIds={currentData?.raceResults?.recoveryPlan?.mobility_video_ids} userInfo={userInfo} />
                        <br />
                        <br />
                    </>
                ) : (
                    <>
                        <div className="text-center w-100 mt-2 mb-4 px-5">
                            <h3>Assessment Time!</h3>
                            <p className='text-nm' >
                                Complete the Physical Therapist-designed assessment below.
                            </p>
                        </div>
                        <div className='mx-2'>
                            <ProgramMobileView
                                onLike={onLike}
                                programType={'Recover'}
                                title={currentWorkoutVideo?.headingSubtitle || ''}
                                currentVideo={currentWorkoutVideo as Video}
                                handleMarkAsFavorite={handleMarkAsFavorite}
                                handleOnPlay={handleOnPlay}
                                isPlaying={isPlaying}
                                isFavorite={isFavorite}
                                isLoadingMark={isLoadingMark}
                                subTitle={currentWorkoutVideo?.detailsLine1 as string}
                                currentWorkoutVideoProgressInfo={info?.find(x => x.videoId == Number(currentWorkoutVideo?.id))}
                                duration={vimeoData}
                            />
                            <Assessment navigate={setCurrentProgress} assesment={selectedAssessment} currentProgress={currentProgress} selectArea={handleSelectAssessmentArea} />
                        </div>
                    </>
                )
            }

            <Button className={`default-mw mx-0 bottom-button-sm fxd-bg ${currentProgress == 7 ? 'submit-button' : ''}`} variant='none' onClick={handleButtonClick}>
                <span className='text-white'>{recoveryAssessment === "1" && !showAssessment && currentData?.raceResults?.recoveryPlan?.mobility_video_ids && !closeAssessment ? "Re-take Assessments" : currentProgress == 7 ? 'Submit Assessment' : 'Continue'}</span>
            </Button>

        </div>
    )
}

export default RecoveryAssessments
