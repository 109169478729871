import classNames from 'classnames'
import { PLAY_ICON } from '../../modules/miscImages'
import { FC } from 'react'

// Props
export type PlayButtonProps = {
	isHome?: boolean
	isCentered?: boolean
	isLower?: boolean
	onPlay?: () => any
}

/**
 * PlayButton Component
 */

const PlayButton: FC<PlayButtonProps> = ({
	isHome,
	isCentered,
	isLower,
	onPlay,
}) => {
	// Properties
	const curClassName = classNames(
		'play-btn',
		isHome ? 'home-play-btn' : undefined
	)
	const btnClassName = classNames(
		'btn-circle has-pointer',
		isCentered ? 'icon-btn-circle-lg' : 'icon-btn-circle-md',
		isLower && !isCentered ? 'card-play-lower' : undefined
	)

	// Render
	return (
		<>
			{isCentered ? (
				<div className={curClassName}>
					<img className={btnClassName} onClick={onPlay} src={PLAY_ICON} />
				</div>
			) : (
				<img className={btnClassName} onClick={onPlay} src={PLAY_ICON} />
			)}
		</>
	)
}

export default PlayButton
