import { FC, useState, useEffect } from 'react'

import Grid from '@mui/material/Grid'
import Box from '@mui/material/Box'

import RSInput from '../../components/input/RSInput'
import RSButton from '../../components/buttons/RSButton'
import { AccountProfile } from '../../api/v2/account/Account.types'

export type LocationProps = {
	data: AccountProfile,
	handleUpdateProfile: Function,
	getLocation?: any,
	description?: string
}

const Location: FC<LocationProps> = ({ data, handleUpdateProfile, getLocation, description }) => {
	const { location } = data
	const [locationValue, setLocation] = useState<any>(location || '');

	const handleSave = async () => {
		handleUpdateProfile({ location: locationValue }, 'CHANGE_LOCATION')
	}

	useEffect(() => {
		setLocation(location)
	}, [location])

	return (
		<Box>
			<Grid container xs={12} sx={{ fontFamily: `Poppins-${description ? 'light' : 'Medium'} !important`, fontSize: '16px', lineHeight: '1.4em' }}>
				{description || 'Where are you located?'}
			</Grid>
			<Grid container xs={12} sx={{ position: 'relative' }}>
				<RSInput sx={{ width: getLocation ? 'calc(100% - 50px)' : '100%' }} value={locationValue} setValue={setLocation} title={'City or Zip Code'} />
				{getLocation ? <RSButton secondary customized onClick={() => getLocation?.()} sx={{ height: 'auto', width: '45px', minWidth: '0px', justifyContent: 'center', alignItems: 'center', ml: '5px', mt: '10px' }}>
					<i className='fa-light fa-location-arrow' style={{ fontSize: '22px' }} />
				</RSButton> : <></>}
			</Grid>
			<RSButton onClick={handleSave} disabled={location == locationValue} sx={{ mt: 3 }}>Save</RSButton>
		</Box>
	)
}

export default Location;
