import { useState, FC } from 'react'
import { useNavigate } from 'react-router-dom';

import { Elements, RecurlyProvider } from '@recurly/react-recurly'
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import ArrowForward from '@mui/icons-material/ArrowForwardIos';
import AppSettings from '@mui/icons-material/AppSettingsAltOutlined';

import RSDrawer from './RSDrawer'
import RSTheme from '../styles/RSTheme'
import RSButton from '../buttons/RSButton'
import ImageLogo from '../logos/ImageLogo'
import useAlert from '../../hooks/useAlert'
import useStore from '../../store/useStore'
import { ICurrentUser } from '../../store/createAccountStore'
import { RECURLY_PUBLIC_KEY } from '../../modules/cliEnvValues'
import { signOut } from '../../modules/accountActions'
import Profile from '../page-specific/account/Profile'
import ChangePassword from '../page-specific/account/ChangePassword'
import Billing from '../page-specific/account/Billing'
import Subscription from '../page-specific/account/Subscription'
import IntegrationsMainPage from '../../pages/integrations/IntegrationsMainPage'

export type RSSideMenuProps = {
	open: boolean,
	onClose: Function,
}

//const HeaderWithTitle

const RSSideMenu: FC<RSSideMenuProps> = ({ open, onClose }) => {
	const [showProfile, setShowProfile] = useState(false)
	const [showBilling, setShowBilling] = useState(false)
	const [showSubscription, setShowSubscription] = useState(false)
	const [showIntegrations, setShowIntegrations] = useState(false)
	const [showChangePW, setShowChangePW] = useState(false)

	const { onAlertOpen, setAlertMessage, setAlertVariant } = useAlert()

	const { currentUser } = useStore((state: ICurrentUser) => state)

	const requestKey = localStorage.getItem('deviceRequestKey') || undefined

	const navigate = useNavigate()
	let infoList = [{
		label: 'Profile',
		logo: <ImageLogo type="profile" height={20} />,
		onClick: setShowProfile
	}, {
		label: 'Billing',
		logo: <ImageLogo type="billing" height={20} />,
		onClick: setShowBilling
	}, {
		label: 'Subscription',
		logo: <ImageLogo type="subscription" height={20} />,
		onClick: setShowSubscription
	}, {
		label: 'Integrations',
		logo: <AppSettings sx={{ fontSize: '28px !important' }} />,
		onClick: setShowIntegrations
	}]

	if (requestKey) {
		infoList = infoList.filter((item) => item.label !== 'Billing')
	}



	const renderProfile = () => {
		return <RSDrawer headerTitle='Profile' open={showProfile} onClose={() => setShowProfile(false)}>
			<Box sx={{ pt: 4, px: 2 }}>
				<Profile
					onChangePassword={() => setShowChangePW(true)}
					onAlertOpen={onAlertOpen}
					setAlertMessage={setAlertMessage}
					setAlertVariant={setAlertVariant}
					side
				/>
			</Box>
		</RSDrawer>
	}

	const renderChangePW = () => {
		return <RSDrawer headerTitle='Change Password' open={showChangePW} onClose={() => setShowChangePW(false)}>
			<Box sx={{ pt: 4, px: 2 }}>
				<ChangePassword
					onCancel={() => setShowChangePW(false)}
					onAlertOpen={onAlertOpen}
					setAlertMessage={setAlertMessage}
					setAlertVariant={setAlertVariant}
					side
				/>
			</Box>
		</RSDrawer>
	}

	const renderBilling = () => {
		return <RSDrawer headerTitle='Billing' open={showBilling} onClose={() => setShowBilling(false)}>
			<Box sx={{ pt: 1, px: 2 }}>
				<RecurlyProvider publicKey={RECURLY_PUBLIC_KEY}>
					<Elements>
						<Billing
							userInfo={currentUser}
							afterBillingProcess={() => setShowBilling(false)}
							isUpdate={false}
							onAlertOpen={onAlertOpen}
							setAlertMessage={setAlertMessage}
							setAlertVariant={setAlertVariant}
							isAccount={true}
							side
						/>
					</Elements>
				</RecurlyProvider>
			</Box>
		</RSDrawer>
	}
	const renderSubscription = () => {
		return <RSDrawer headerTitle='Subscription' open={showSubscription} onClose={() => setShowSubscription(false)}>
			<Box sx={{ pt: 4, px: 2 }}>
				<Subscription
					isRenew={false}
					isExpiring={currentUser?.is_expiring}
					billingIsValid={currentUser?.is_valid}
					onSubmit={() => setShowSubscription(false)}
					onCancel={() => navigate('/cancel/step-1', { replace: true })}
					onAlertOpen={onAlertOpen}
					setAlertMessage={setAlertMessage}
					setAlertVariant={setAlertVariant}
					side
				/>
			</Box>
		</RSDrawer>
	}

	const renderIntegrations = () => {
		return <RSDrawer headerTitle='Integrations' open={showIntegrations} onClose={() => setShowIntegrations(false)}>
			<Box sx={{ pt: 4, px: 4 }}>
				<IntegrationsMainPage userInfo={currentUser} side />
			</Box>
		</RSDrawer>
	}

	const renderMain = () => <RSDrawer headerTitle='Account Settings' open={open} onClose={onClose}>
		<Box sx={{ width: '100%', px: 2, pt: 4 }}>
			<Grid container xs={12} justifyContent="flex-start" alignItems="center" >
				<Box sx={{ mx: 'auto', backgroundColor: 'white', color: 'gray', width: '100%', borderRadius: '10px' }}>
					{infoList.map((listData, i) => {
						const { label, logo, onClick } = listData
						return <Grid container key={i + '-side-menu'} xs={12} sx={{ height: '60px', borderBottom: i === infoList.length - 1 ? '' : '1px solid #8080803b' }} justifyContent="space-between" alignContent="center" alignItems="center">
							<Button onClick={() => onClick?.(true)} sx={{ p: '10px', width: '100%', color: '#010101', justifyContent: 'space-between', textTransform: 'none', height: '100%' }}>
								<Grid container justifyContent='flex-start' alignItems='center'>
									<Box sx={{ width: '40px' }}>{logo}</Box>
									<Box sx={{ fontSize: '16px', textAlign: 'left' }} >{label}</Box>
								</Grid>
								<Box sx={{ color: 'black', position: 'relative', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
									<ArrowForward fontSize="medium" sx={{ stroke: 'white', strokeWidth: '1.3px' }} />
								</Box>
							</Button>
						</Grid>
					})}
				</Box>
			</Grid>
			<Grid container xs={12} sx={{ pt: 2 }} justifyContent="center" alignItems="center">
				<RSButton sx={{
					display: 'flex',
					justifyContent: 'space-between',
					alignItems: 'center',
					p: '15px 10px 15px 20px',
					mt: '10px', maxWidth: '400px'
				}}
					onClick={() => navigate('/training-plan/build/step-1', { replace: true })}
				>
					Build New Plan
					<ArrowForward fontSize="large" sx={{ stroke: '#9fcd2b', strokeWidth: '1.3px', fontSize: '20px' }} />
				</RSButton>
			</Grid>
			<Grid container xs={12} justifyContent="center" alignItems="center">
				<RSButton secondary customized sx={{
					display: 'flex',
					justifyContent: 'space-between',
					alignItems: 'center',
					p: '15px 15px 15px 20px',
					mt: '10px', maxWidth: '400px'
				}}
					onClick={signOut}
				>
					Logout
					<i style={{ fontSize: '20px' }} className="fa-regular fa-arrow-right-from-bracket"></i>
				</RSButton>
			</Grid>
		</Box>
	</RSDrawer>

	return <RSTheme>
		{renderMain()}
		{renderProfile()}
		{renderChangePW()}
		{renderBilling()}
		{renderSubscription()}
		{renderIntegrations()}
	</RSTheme>
}

export default RSSideMenu;
