/* eslint-disable @typescript-eslint/no-unused-vars */
import { pdf } from '@react-pdf/renderer'
import axios from 'axios'
import moment from 'moment'
import React, { useEffect, useState } from 'react'
import { Button, Form, Modal } from 'react-bootstrap'
import { ITimeObjectString, MarathonValRecord, UserPdfRecord } from '../../../api/v2/types'
import useTrainingPlanUtils from '../../../hooks/useTrainingPlanUtils'
import { CreatePdf, GetAllMarathonValues, GetAllUserNotes, GetUserRestComments, SaveUserPDF, UploadPDF } from '../../../modules/trainingPlanActions'
import { ICurrentUser } from '../../../store/createAccountStore'
import { IComponentStore } from '../../../store/createComponentStore'
import { ITrainingPlanStore } from '../../../store/createTrainingPlanStore'
import useStore from '../../../store/useStore'
import PdfViewer from '../../PdfViewer'
import useTrainingPlanData from '../hooks/useTrainingPlanData'

const DownloadPlanModal = (props: any) => {
    const { ConvertFullTo5k, ConvertFullToHalf, ConvertM2H2, ConvertFullto10k, ConvertM2Mt } = useTrainingPlanUtils()
    const { getWholePlan } = useTrainingPlanData()
    const { setToast } = useStore((state: IComponentStore) => state)
    const { currentUser } = useStore((state: ICurrentUser) => state)
    const { setUserVdotAdjustments, userVdotAdjustments, userOverrides } = useStore((state: ITrainingPlanStore) => state)
    const [hasErrorSaving, setHasErrorSaving] = useState(false);
    const [isSaving, setIsSaving] = useState(false);
    const [isSuccessSave, setIsSuccessSave] = useState(false);
    const [pdfData, setPdfData] = useState<UserPdfRecord>()
    const [isLoading, setIsLoading] = useState<boolean>(false)
    const { show, handleClose, userInfo, getRaceDistance, userCustomPaces, isPDFExist } = props
    const [fTime, setFTime] = useState<ITimeObjectString>({ hr: undefined, min: undefined, sec: undefined });
    const [finishTime, setFinishTime] = useState("")
    const [targetTime, setTargetTime] = useState("")
    const [trainingData, setTrainingData] = useState<Array<any>>([])
    const [userRestComments, setUserRestComments] = useState<Array<any>>([])
    const [hideMainModal, setHideMainModal] = useState(false)


    const [showPdf, setShowPdf] = useState(false)
    const [abort, setAbort] = useState<AbortController | undefined>()
    const handleCloseModal = () => setShowPdf(false);
    const handleOpenModal = () => setShowPdf(true);

    const handleSubmit = async () => {
        if (pdfData?.race_name !== "") {
            setIsSaving(true)
            // console.log(userInfo)
            SaveUserPDF(pdfData as UserPdfRecord).then(async (res) => {
                if (res.status === "ok") {
                    const insertId = res.data.result.insertId
                    const params: MarathonValRecord = {
                        marathon: userInfo?.type,
                        type: userInfo?.weeks,
                        training_goal: userInfo?.distance,
                        distance: userInfo?.distance,
                        week: 0                        // day: +(index as unknown as number) + 1
                    }


                    GetAllMarathonValues(params).then(async (res) => {
                        const result = res.data.result.marathon

                        const { data: userNotes } = await GetAllUserNotes(userInfo?.uid, abort?.signal)
                        const { data: comments } = await GetUserRestComments(userInfo?.uid, abort?.signal)
                        setUserRestComments(comments.result)
                        const userData = currentUser;
                        const trainingData = await getWholePlan(userInfo, result, userNotes.result, comments.result) as Array<any>
                        await CreatePdf(userInfo, trainingData, insertId, pdfData, currentUser, userRestComments, userVdotAdjustments, userCustomPaces)
                        setTrainingData(trainingData)
                        setHideMainModal(true)
                        handleOpenModal()
                        // handleClose()
                        setIsSaving(false)
                        setIsSuccessSave(true)
                        setPdfData({ ...pdfData, race_name: "", finish_time: "", goal_time: "" })
                        setFTime({ hr: 0, min: 0, sec: 0 })
                    })
                }
                else {
                    setIsSaving(false)
                    setIsSuccessSave(false)
                    setHasErrorSaving(true)
                }

            })
        }
    }


    useEffect(() => {
        setShowPdf(false)
        if (show && userInfo) {
            const targetTimeVal = userInfo?.targetm;

            const type = userInfo?.type;

            switch (type) {
                case 0:
                    setTargetTime(ConvertFullToHalf(targetTimeVal));
                    break;
                case 1:
                    setTargetTime(ConvertM2H2(targetTimeVal));
                    break;
                case 2:
                    setTargetTime(ConvertFullTo5k(targetTimeVal));
                    break;
                case 3:
                    setTargetTime(ConvertFullto10k(targetTimeVal));
                    break;
                case 4:
                    setTargetTime(ConvertM2H2(targetTimeVal));
                    break;
                default:
                    break;
            }

            setPdfData({ ...pdfData, user_id: userInfo?.uid, goal_time: targetTime, start: new Date(userInfo?.start).toISOString().split('T')[0], race_date: new Date(userInfo?.race_date).toISOString().split('T')[0], race_distance: userInfo?.type != 4 ? getRaceDistanceValue(getRaceDistance(userInfo?.type)) : "Maintenance" })
        }

    }, [show])

    const getRaceDistanceValue = (distance: number) => {
        if (userInfo?.km === 'Y')
            return (ConvertM2Mt(distance) / 1000)?.toFixed(1)?.toString()?.replace(/(\.0+|0+)$/, '') + ' Kms'
        else if (userInfo?.km === 'N')
            return distance + ' Miles'
    }

    useEffect(() => {
        setPdfData({ ...pdfData, goal_time: targetTime })
    }, [targetTime])

    useEffect(() => {
        setFinishTime(`${fTime.hr ? fTime.hr : 0}h ${fTime.min ? fTime.min : 0}m ${fTime.sec ? fTime.sec : 0}s`)
    }, [fTime])

    useEffect(() => {
        setPdfData({ ...pdfData, finish_time: finishTime })
    }, [finishTime])

    // useEffect(() => {
    //     setToast({
    //         show: hasErrorSaving,
    //         title: "Error Message",
    //         message: "An error occurred while saving the training plan pdf data, please try again.",
    //         variant: "danger",
    //         onClose: () => setHasErrorSaving(false),
    //         duration: 5000
    //     })
    // }, [hasErrorSaving])

    // useEffect(() => {
    //     // setSelectedRaceDate(new Date())
    //     setToast({
    //         show: isSuccessSave,
    //         title: "Download Training Plan",
    //         message: "Training plan pdf data has been saved.",
    //         variant: "success",
    //         onClose: () => {
    //             setIsSuccessSave(false)
    //         },
    //         duration: 3000,
    //     })
    // }, [isSuccessSave])

    useEffect(() => {
        const abortController = new AbortController();
        setAbort(abortController)

        return () => {
            abort?.abort();
        }
    }, [])
    return (
        <Modal
            className={`common-modal download-form build-plan edit-locations-modal ${hideMainModal ? 'd-none' : ""}`}
            show={show}
            onHide={handleClose}
            backdrop="static"
            keyboard={false}
        >
            <Modal.Header closeButton>
                <h5 className="h3 mb-2">Download Your <br /> Training Plan</h5>
            </Modal.Header>
            <Modal.Body className="mt-0 pt-0 mx-5 px-md-5 px-3 text-center">
                {/* <p className="mt-0 mb-0 font-weight-400">
                    By entering your location, we will track current weather
                    conditions and help you pace accordingly. We will allow you to
                    change this at a later date.
                </p> */}
                <Form>
                    <Form.Group className="mt-4 position-relative">
                        <Form.Label className='download-label text-uppercase'>
                            Race Name
                        </Form.Label>
                        <Form.Control
                            value={pdfData?.race_name}
                            defaultValue={pdfData?.race_name}
                            onChange={(e) => setPdfData({ ...pdfData, race_name: e.target.value })}
                            type="text"
                            placeholder="What race did you train for?"
                            className='download-field'
                        />

                        <Form.Label className='download-label text-uppercase'>
                            Finish Time (HH:MM:SS)
                        </Form.Label>
                        <div className='small-field-container'>
                            <Form.Control
                                min={0}
                                max={24}
                                maxLength={2}
                                value={fTime?.hr}
                                defaultValue={fTime?.hr}
                                onChange={(e) => setFTime({ ...fTime, hr: +e.target.value })}
                                type="number"
                                placeholder="HH"
                                className='download-field small-field'
                            />
                            <Form.Control
                                min={0}
                                max={60}
                                maxLength={2}
                                value={fTime?.min}
                                defaultValue={fTime?.min}
                                onChange={(e) => setFTime({ ...fTime, min: +e.target.value })}
                                type="number"
                                placeholder="MM"
                                className='download-field small-field'
                            />
                            <Form.Control
                                min={0}
                                max={60}
                                maxLength={2}
                                value={fTime?.sec}
                                defaultValue={fTime?.sec}
                                onChange={(e) => setFTime({ ...fTime, sec: +e.target.value })}
                                type="number"
                                placeholder="SS"
                                className='download-field small-field'
                            />
                        </div>


                        <Form.Label className='download-label text-uppercase'>
                            Goal Time
                        </Form.Label>

                        <Form.Control
                            disabled
                            defaultValue={targetTime.toUpperCase()}
                            // onChange={(e) => setPdfData({ ...pdfData, race_name: e.target.value })}
                            type="text"
                            placeholder="Goal Time"
                            className='download-field'
                        />

                        <Form.Label className='download-label text-uppercase'>
                            Plan Started
                        </Form.Label>
                        <Form.Control
                            disabled
                            defaultValue={new Date(userInfo?.start).toLocaleDateString()}
                            // onChange={(e) => setPdfData({ ...pdfData, race_name: e.target.value })}
                            type="text"
                            placeholder="Plan Start"
                            className='download-field'
                        />
                        <Form.Label className='download-label text-uppercase'>
                            Race Date
                        </Form.Label>
                        <Form.Control
                            disabled
                            defaultValue={moment.utc(userInfo?.race_date).format("YYYY-MM-DD")}
                            // onChange={(e) => setPdfData({ ...pdfData, race_name: e.target.value })}
                            type="text"
                            placeholder="Race Date"
                            className='download-field'
                        />

                    </Form.Group>
                </Form>
                <div className="center-align">
                    <Button
                        disabled={isSaving}
                        variant="primary"
                        onClick={handleSubmit}
                        className="rounded-pill color-primary-bg save-btn mt-4 center-align text-capitalize letter-space btn-dark"
                    >
                        {isSaving ? "Saving..." : "Download"}
                    </Button>
                </div>
            </Modal.Body>

            <PdfViewer
                isPDFExist={isPDFExist}
                showPdf={showPdf}
                handleClose={handleClose}
                userInfo={userInfo}
                trainingData={trainingData}
                pdfData={pdfData}
                currentUser={currentUser}
                userRestComments={userRestComments}
                vdotAdjustments={userVdotAdjustments}
                customPaces={userCustomPaces}
                setHideMainModal={setHideMainModal}
            />
        </Modal>
    )
}

export default DownloadPlanModal