import { SimpleSubscription } from '../api/routes/accountRoutes'

// Get Current Amount
export const getCurrentAmount = (
	subscription: Partial<SimpleSubscription> = {}
) =>
	subscription?.change_amount != null
		? subscription.change_amount
		: subscription?.amount != null
		? subscription.amount
		: 0

// Get Current ID
export const getCurrentId = (
	subscription: Partial<SimpleSubscription> = {},
	useChange = true
) =>
	(useChange
		? subscription?.change_id || subscription?.id
		: subscription?.id) || ''

// Get Current Plan Code
export const getCurrentPlanCode = (
	subscription: Partial<SimpleSubscription> = {},
	useChange = true
) =>
	(useChange
		? subscription?.change_plan_code || subscription?.plan_code
		: subscription?.plan_code) || ''

// Get Plan Args
export const getPlanArgs = (planCode: string) => {
	const hasSale = planCode.includes('flash')
	const hasOffer = planCode.includes('discount') || hasSale
	const isRenew = planCode.includes('renew') || hasSale
	return { hasOffer, isRenew, hasSale }
}
