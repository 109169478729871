
const useRNBridge = () => {

    const sendDataToReactNative = (data: any) => {
        if (window.ReactNativeWebView) {
            window.ReactNativeWebView.postMessage(JSON.stringify(data))
        }
    };

    return { sendDataToReactNative }
}

export default useRNBridge