import { useState, useEffect } from 'react'
import { getAccountInfo } from '../modules/accountActions'

/**
 * useUserInfo Hook
 */

const useUserInfo = () => {
	// State
	const [accountId, setAccountId] = useState(0)
	const [username, setUsername] = useState('')
	const [email, setEmail] = useState('')
	const [firstName, setFirstName] = useState('')
	const [lastName, setLastName] = useState('')
	const [dob, setDob] = useState('')
	const [gender, setGender] = useState('M')
	const [favorite, setFavorite] = useState('5K')
	const [joinReason, setJoinReason] = useState('strength')

	// Get User Info
	const getUserInfo = async () => {
		const info = await getAccountInfo()
		setAccountId(info.account_id || 0)
		setUsername(info.username || '')
		setEmail(info.email || '')
		setFirstName(info.first_name || '')
		setLastName(info.last_name || '')
		setDob(info.dob ? info.dob.split('T')[0] : '')
		setGender(info.gender || 'M')
		setFavorite(info.favorite || '5K')
		setJoinReason(info.join_reason || 'strength')
	}

	// Mount Effect
	useEffect(() => {
		getUserInfo()
	}, [])

	// Return
	return {
		accountId,
		username,
		email,
		firstName,
		lastName,
		dob,
		gender,
		favorite,
		joinReason,
	}
}

export default useUserInfo
