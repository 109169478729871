/* eslint-disable @typescript-eslint/no-empty-function */
/* eslint-disable @typescript-eslint/no-unused-vars */

import { useState, useEffect, isValidElement } from 'react'
import { motion, AnimatePresence } from "framer-motion";

import Grid from '@mui/material/Grid';
import AudioPlayerComponent from '../../midfoot-project/AudioPlayer'
import midfootActions from '../../midfoot-project/midfootActions'
import allNewExercises from '../../../modules/videos/allNewExercises.json'
import RSVideoCard from '../../../components/cards/RSVideoCard'
import kfsExercises from './KneedForSpeedExercises.json'

const variants = {
    enter: (direction: number) => {
        return {
            opacity: 0,
            x: direction > 0 ? "100%" : "-100%"
        };
    },
    center: {
        opacity: 1,
        x: 0
    },
    exit: (direction: number) => {
        return {
            opacity: 0,
            x: direction > 0 ? "-100%" : "100%"
        };
    }
};

const ProgramTraining = (props: any) => {

    const { phase, initPhase, prevActivePhase, renderPhaseSelect, handlePlayVideo, setIsLoading, onboarding, programDetails } = props
    const [weekVideos, setWeekVideos] = useState<any>({})
    const [weekVideosWithDuration, setWeekVideosWithDuration] = useState<any>({})

    const generateProgramTrainingData = () => {
        const data: any = []
        const audioRuns = programDetails?.additional_content?.filter((x: any) => x.type === "audio")

        programDetails?.videos?.forEach((video: any) => {
            const x = {
                week: video?.video_order as string,
                title: video?.phase,
                exercises: [],
                training_video: video?.video_id,
                training_audio: audioRuns[video?.video_order - 1]?.file_path?.replace('/files/', ''),
                duration: video?.video_length,
                video_thumbnail: video?.video_thumbnail
            }
            data.push(x)
        })

        return data
    }

    const getVideoDuration = async (hasDuration?: boolean) => {
        const data = generateProgramTrainingData()
        const newWeekVideos: any = {}
        for (const nv of data) {
            const daily = []
            const times = []
            const e = kfsExercises.find(x => x.week === nv.week)
            if (e?.exercises.length)
                for (const v of e.exercises) {
                    const ane = allNewExercises.find((ae: any) => ae.exercise_id == v && ae.video_type === 'intro')
                    let duration = undefined
                    if (hasDuration) {
                        const response = await fetch(
                            `https://vimeo.com/api/oembed.json?url=https://player.vimeo.com/video/${ane?.video_id}`
                        );
                        const data = await response.json();
                        duration = data?.duration ? data.duration : 0
                    }

                    const vDetails = { ...ane, duration }
                    daily.push(vDetails)
                }
            newWeekVideos[nv.week] = { data: nv, daily }
        }
        hasDuration ? setWeekVideosWithDuration(newWeekVideos) : setWeekVideos(newWeekVideos)
        setIsLoading?.(false)
    }

    const handlePlay = async (id?: any, week?: any) => {
        // if (activeMidfoot)
        //     await updateMidfootData(userInfo?.account_id, activeMidfoot.id, Number(week))
        // else
        //     await createMidfootData(userInfo?.account_id)
        // await loadMidfootData()
        id && handlePlayVideo(id, 'Week ' + week)
    }

    useEffect(() => {
        if (!Object.keys(weekVideosWithDuration).length) {
            getVideoDuration(true)
            getVideoDuration()
        }
    }, [])

    const renderVideos = (videoList: Array<any>, week: number) => {
        const list = videoList.map((vd: any) => {
            const { video_id: id, headingTitle, exercise_title, duration, video_thumbnail } = vd

            return isValidElement(vd) ? vd : {
                headingTitle: headingTitle || exercise_title,
                duration,
                id,
                src: video_thumbnail,
                onClick: () => handlePlay(id, week),
            }
        })
        return <Grid container sx={{ px: 3 }}>
            <RSVideoCard thmb_scale={1.3} videoList={list} sx={{ pt: 0 }} onboarding={onboarding} />
        </Grid>
    }

    const renderTraining = () => {
        const videos = weekVideosWithDuration?.[phase + 1] ? weekVideosWithDuration[phase + 1] : weekVideos?.[phase + 1]
        return videos ? <>
            {renderPhaseSelect?.()}
            <AnimatePresence mode='wait' initial={false}>
                <motion.div
                    key={phase}
                    custom={initPhase - prevActivePhase?.current}
                    variants={variants}
                    initial="enter"
                    animate="center"
                    exit='exit'
                    transition={{ duration: 0.2 }}
                    style={{ paddingBottom: '20px' }}
                    className='midfoot-program-container'
                >
                    {renderVideos([{
                        headingTitle: `${videos?.data?.title}`,
                        video_id: videos?.data?.training_video,
                        duration: videos?.data?.duration,
                        video_thumbnail: videos?.data?.video_thumbnail
                    }, <Grid container key={`${videos?.data?.week}-audio`} sx={{ pointerEvents: !onboarding ? 'auto' : 'none' }}>
                        <AudioPlayerComponent
                            file={videos?.data?.training_audio}
                            title={`Week ${videos?.data?.week} Guided Run`}
                            handlePlay={() => handlePlay(undefined, videos?.data?.week)}
                        />
                    </Grid>],
                        videos?.data?.week)
                    }

                    {videos?.daily?.length && <><Grid container sx={{ px: 3, pt: 2, fontFamily: 'Poppins-Medium', fontSize: '16px' }}>
                        Exercises
                    </Grid>
                        {renderVideos(videos.daily, videos?.data?.week)}
                    </>}

                    {videos?.times?.length && <><Grid container sx={{ px: 3, pt: 3, fontFamily: 'Poppins-Medium', fontSize: '16px' }}>
                        Do 2-3x/week
                    </Grid>
                        {renderVideos(videos.times, videos?.data?.week)}
                    </>}
                </motion.div>
            </AnimatePresence>
        </>
            :
            <></>
    }

    return renderTraining()

}

export default ProgramTraining;
