/* eslint-disable @typescript-eslint/no-unused-vars */
import classNames from 'classnames'
import { FC, useState } from 'react'

interface PlanSelectorProps {
    selected?: boolean;
    months: string,
    rate: string,
    discount: string,
    total: number
    banner?: string,
    onSelected?: () => void,
    newDesign?: boolean,
    isAndroidNative?: boolean
}

const PlanSelector: FC<PlanSelectorProps> = ({ selected, months, rate, discount, total, banner, onSelected, newDesign, isAndroidNative }) => {

    return (
        <>

            <div onClick={onSelected} className={classNames('selector', newDesign ? 'selectorNew' : '', selected ? (newDesign ? 'newDesign' : 'selected') : '')}>
                {banner ? (
                    <div className='popular'>
                        <b><p>{banner}</p></b>
                    </div>
                ) : undefined}

                <span className={`upper-text ${isAndroidNative ? 'mt-3' : 'mt-1'}`}>
                    <p>{isAndroidNative ? '' : rate}</p>
                    <p>{isAndroidNative ? '' : discount}</p>
                </span>

                <span className='lower-text mt-n3'>
                    <p>{months}</p>
                    <p>${total} USD</p>
                </span>
            </div>
        </>
    )
}

export default PlanSelector
