/* eslint-disable @typescript-eslint/no-unused-vars */
import { useEffect, useState } from "react";
import { isIOS, isAndroid } from 'react-device-detect'

import { LOCAL_MACHINE } from '../modules/cliEnvValues'

const useIsMobileScreen = () => {
  const [isScreenMobile, setIsScreenMobile] = useState(window.innerWidth < 901 || isIOS || isAndroid ? true : false);
  const handleWindowSizeChange = () => {
    const requestKey = localStorage.getItem('deviceRequestKey') || undefined
    //&& (LOCAL_MACHINE ? true: (requestKey && (isIOS || isAndroid)))
    const checker = window.innerWidth < 901 || isIOS || isAndroid ? true : false
    setIsScreenMobile(checker)
  }

  useEffect(() => {
    handleWindowSizeChange()
    window.addEventListener('resize', handleWindowSizeChange);
    return () => {
      window.removeEventListener('resize', handleWindowSizeChange);
    }
  }, []);

  return isScreenMobile;
}

export default useIsMobileScreen
