import Container from 'react-bootstrap/Container'
import classNames from 'classnames'
import { FC } from 'react'

// Props
export type HomeSubtitleProps = {
	center?: boolean
	isHome?: boolean
	subText?: string
}

/**
 * HomeSubtitle Component
 */

const HomeSubtitle: FC<HomeSubtitleProps> = ({
	children,
	subText,
	center,
	isHome,
}) => {
	const className = classNames(
		'mb-2',
		center ? 'text-center' : undefined,
		isHome ? 'home-center-text' : undefined
	)

	// Render
	return (
		<Container fluid="lg" className={className}>
			<h4>{children}</h4>
			{subText && (
				<span>
					<h6>{subText}</h6>
				</span>
			)}
		</Container>
	)
}

export default HomeSubtitle
