import { useEffect, useState } from 'react'

const useScrollEnd = (element?: Element, reduced?: number) => {
    const [isScrollEnd, setIsScrollEnd] = useState(false)


    useEffect(() => {
    		const el = element || window;
        const onscroll = () => {
            const scrolledTo = element ? (element?.scrollTop + element?.clientHeight) : (window.scrollY + window.innerHeight) + 200;
            const isReachBottom = (element?.scrollHeight ? element.scrollHeight-(reduced || 0) : document.body.scrollHeight) <= scrolledTo;
        		if (isReachBottom)
                setIsScrollEnd(true);
            else
                setIsScrollEnd(false);
        };
				el.removeEventListener("scroll", onscroll);
        el.addEventListener("scroll", onscroll);
        return () => {
            el.removeEventListener("scroll", onscroll);
        };
    }, [element]);

    return { isScrollEnd }
}

export default useScrollEnd
