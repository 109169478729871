// Home Logo
export const HOME_LOGO =
	'/img-new/homepage/Logos/RunSmart - Horizontal - Normal.png'

// Misc Logso
export const MISC_LOGOS = {
	kettleBell: '/icons/kettle-bell-icon.svg',
	kettleBellActive: '/icons/kettle-bell-icon-active.svg',
	// training: '/images/desktop/training-icon-green.png',
	training: '/images/icons/runsmart-guy.svg',
	trainingActive: '/icons/training-icon-active.svg',
	trainingBlack: '/icons/training-icon.svg',
	facebook: '/img-new/homepage/icons/facebook-icon-08.svg',
	heart: '/img/Icon-favorites-heart.svg',
	heartActive: '/img/Icon-favorites-heart-active.svg',
	star: '/img/Icon-programs-star.svg',
	starActive: '/img/Icon-programs-star-active.svg',
	reset: '/images/icons/runsmart-reset.jpg',
	midfoot: '/img/midfoot_project_full.jpg',
	recover: '/img/recover.jpg',
	home: '/images/icons/home.svg',
	homeNew: '/icons/home-icon.svg',
	homeNewActive: '/icons/home-icon-active.svg',
	profile: 'icons/Icon-account.svg',
	billing: 'icons/billing-icon.svg',
	subscription: 'icons/subscription-icon.svg',
	viewPlan: 'icons/view-plan-icon.svg',
	buildPlan: 'icons/build-a-new-plan-icon.svg',
	downloadPlan: 'icons/download-plan-icon.svg',
	getStarted: 'icons/get-started-icon.svg',
	getHelp: 'icons/get-help-icon.svg',
	

}

// Play Icon
export const PLAY_ICON = '/images/icons/play.png'

// Gonser Avi
export const GONSER_AVI = '/img-new/misc/gonser-message.png'
