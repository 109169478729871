/* eslint-disable @typescript-eslint/no-unused-vars */

import { FC, useContext, useEffect, useState } from 'react'
import { FaChromecast } from 'react-icons/fa6'
import { RouletteSpinner } from 'react-spinner-overlay'
import usePlayCastVideo from '../../hooks/usePlayCastVideo'
// import useWebSocket from 'react-use-websocket'
import useQueryParams from '../../hooks/useQueryParams'
import useRNBridge from '../../hooks/useRNBridge'
// import { WS_URL } from '../../modules/cliEnvValues'
import useStoreWSMessages from '../../hooks/useStoreWSMessages'
import { getReq } from '../../modules/apiConsume'
import { IGoogleCastStore } from '../../store/createGoogleCastStore'
import { IVimeoPlayerStore } from '../../store/createVimeoPlayerStore'
import useStore from '../../store/useStore'
import { WebSocketContext } from '../../WebsocketProvider'
// GoogleCastButton Props
export type GoogleCastButtonProps = {
	vimeoId?: string
	onClick?: () => any
	className?: string
	isCustomPlayer?: boolean
	onCustomClick?: () => any
	currentProgress?: number
	autoCast?: boolean
}

/**
 * GoogleCastButton Component
 */

const GoogleCastButton: FC<GoogleCastButtonProps> = ({ vimeoId, onClick, className = "googlecast-button", isCustomPlayer, currentProgress, autoCast }) => {
	const { requestKey } = useQueryParams()
	const localStorageRequestKey = localStorage.getItem('deviceRequestKey') || requestKey || undefined
	const { setSelectedVideo, setActiveVideo, activeVideo, setCastStatus, castStatus, selectedVideo } = useStore((state: IGoogleCastStore) => state)
	const { storeWebSocketMessages } = useStoreWSMessages()
	const { lastJsonMessage } = useContext(WebSocketContext);
	const { sendDataToReactNative } = useRNBridge()

	const [currentMessage, setCurrentMessage] = useState<any>(null)

	const { setCurrentAction } = useStore((state: IVimeoPlayerStore) => state)

	const { playVideo } = usePlayCastVideo(localStorageRequestKey)

	useEffect(() => {
		if (vimeoId)
			setSelectedVideo(vimeoId)
	}, [vimeoId])

	useEffect(() => {
		if (autoCast && (castStatus == 'active' || castStatus == 'playing')) {
			if (selectedVideo != activeVideo || (!selectedVideo && vimeoId)) {
				// sendDataToReactNative({ requestType: 'google-cast-toggle-dialog', requestKey: requestKey || localStorageRequestKey, payload: 'initial-load-cast', timestamp: new Date().getTime() })
				getReq(`/v2/exercises/raw-link?video_id=${vimeoId}`)
					.then((res) => {
						const media = res.data.result;
						// sendJsonMessage && sendJsonMessage({ requestType: 'google-cast-toggle-dialog', requestKey: requestKey || localStorageRequestKey, payload: media, timestamp: new Date().getTime() })

						sendDataToReactNative({ requestType: 'google-cast-toggle-dialog', requestKey: requestKey || localStorageRequestKey, payload: media, timestamp: new Date().getTime() })

						// setCastStatus('playing')
						setActiveVideo(String(vimeoId))

					})
					.catch(err => {
						// alert(err)
						console.log("ERR: VIDEO LINK", err);
					})
			}
		}
	}, [castStatus, vimeoId, autoCast])

	// functions
	onClick = () => {
		const dataToSend = {
			requestType: 'google-cast-toggle-dialog',
			requestKey: requestKey || localStorageRequestKey,
			payload: 'initial-load-cast',
			timestamp: new Date().getTime(),
		}

		sendDataToReactNative(dataToSend);
	}

	if (!localStorageRequestKey) {
		return null
	}

	// Render
	return (
		<>
			{
				isCustomPlayer ? (
					<div>
						<FaChromecast onTouchStart={onClick} size={31} color={`${castStatus == 'active' || castStatus == 'playing' ? '#9fcd2b' : '#fff'}`} />
					</div>
				) :
					<div onClick={onClick} className={className}>
						{castStatus == 'active' || castStatus == 'playing' ? (
							className.includes('player') ?
								<FaChromecast onTouchStart={onClick} size={24} color={`#9fcd2b`} />
								:
								<img src={`${className.includes('player') ? '/images/icons/chromecast-icon.png' : '/images/icons/google-cast-logo-active.png'} `} />
						) : castStatus == 'loading' ? (
							<RouletteSpinner color={`${className.includes('player') ? '#FFF' : '#333'}`} size={18} />
						) : (
							<img src={`${className.includes('player') ? '/images/icons/chromecast-icon.png' : '/images/icons/google-cast-logo.png'} `} />
						)}
					</div>
			}
		</>
	)
}

export default GoogleCastButton
