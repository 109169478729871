/* eslint-disable @typescript-eslint/no-unused-vars */

import { CloseOutlined } from "@mui/icons-material";
import { FC } from "react"
import { Card, Col, Row, Spinner } from "react-bootstrap";
import { useLocation } from "react-router-dom";
import GoogleCastButton from "../../../components/buttons/GoogleCastButton";
import MainCard from "../../../components/cards/MainCard";
import RSCloseBtn from '../../../components/buttons/RSCloseBtn'
import { Video } from "../../../modules/videos/allVideos";
import useIsMobileScreen from '../../../hooks/useIsMobileScreen'


const ProgramMobileView: FC<{
    currentVideo: Video,
    programType: string,
    title: string,
    isPlaying: boolean,
    currentWorkoutVideoProgressInfo?: any,
    isFavorite: boolean
    isLoadingMark: boolean,
    subTitle?: string,
    duration?: string,
    closeView?: () => void,
    handleOnPlay: () => void,
    onLike: (videoId: string) => void,
    handleMarkAsFavorite: (checked: boolean) => void,
    isFromTP?: boolean
    // isAssessment?: boolean
}> = ({ currentVideo, programType, handleMarkAsFavorite, title, closeView, isPlaying, currentWorkoutVideoProgressInfo, isFavorite, onLike, isLoadingMark, handleOnPlay, subTitle, duration, isFromTP
    // isAssessment
}) => {
        const url = useLocation()
				const isMobile = useIsMobileScreen();
        const localStorageRequestKey = localStorage.getItem('deviceRequestKey')

        return (
            <div>
                {closeView ? (
                    <div onClick={closeView} className={`pointer program-view-close ${isFromTP && !isMobile ? 'tp-close' : ''} ${programType === 'Recover' ? 'mt-n4' : ''}`}>
                        <RSCloseBtn size={25}/>
                    </div>
                ) : undefined}

                <Row className="get-started-training-plan mb-md-2 mb-3">
                    <Col sm={12} className="px-0 px-sm-3">
                        <div className="video-wrapper px-0 w-100 blue-bg">
                            {!isPlaying ? (
                                programType === "Midfoot" || programType === "Recover" ? undefined : (
                                    <h5 className="heading-meta-data text-white fw-regular">
                                        {programType === "Assessment" ? "Recovery" : currentVideo?.headingTitle}
                                    </h5>
                                )
                            ) : null}
                            <MainCard
                                hasPlayButton
                                playButtonIsCentered
                                type="responsiveCardLg"
                                progress={currentWorkoutVideoProgressInfo?.progress}
                                progressPercent={currentWorkoutVideoProgressInfo?.progress}
                                isComplete={currentWorkoutVideoProgressInfo?.isComplete}
                                bgImage={currentVideo?.image}
                                vimeoId={currentVideo?.id}
                                onLike={() => onLike(currentVideo?.id as string)}
                                onPlay={handleOnPlay}
                                hasCheck={currentWorkoutVideoProgressInfo?.isComplete}
                            />
                            {!isPlaying && !isLoadingMark ? (
                                <div className="fav-icon heart-image mx-3">
                                    <input type="checkbox" checked={isFavorite} onChange={e => handleMarkAsFavorite(e.target.checked)} id="heart" hidden />
                                    <label htmlFor="heart">
                                        <div className="animation">
                                            <span className="dot-1"></span>
                                            <span className="dot-2"></span>
                                            <span className="dot-3"></span>
                                            <span className="dot-4"></span>
                                            <span className="dot-5"></span>
                                            <span className="dot-6"></span>
                                            <span className="dot-7"></span>
                                            <span className="dot-8"></span>
                                        </div>
                                        <img
                                            className="fill pointer"
                                            src="./images/icons/heart-red.svg"
                                            alt="Like"
                                        />
                                        <img
                                            className="without-fill pointer"
                                            src="./images/icons/heart-white-big.png"
                                            alt="Like"
                                        />
                                    </label>
                                    {localStorageRequestKey ? (
                                        <GoogleCastButton className='googlecast-button-player' vimeoId={url.pathname.split('/')[3]} />
                                    ) : undefined
                                    }
                                </div>
                            ) :
                                !isPlaying && isLoadingMark ? (
                                    <div className="fav-icon heart-image mb-n2 mx-3">
                                        <Spinner animation='border' variant='danger' />
                                    </div>) : undefined
                            }

                            {!isPlaying ? (
                                <div className="banner-info">
                                    {programType === "Midfoot" || programType === "Recover" ? (
                                        <>
                                            {
                                                programType === "Recover" && subTitle ? (
                                                    <span className="video-pill">{subTitle}</span>
                                                ) : undefined
                                            }
                                            <h3 className="text-white mt-1 mb-0 fw-normal">
                                                {programType === 'Midfoot' ? 'Midfoot Project' : programType === 'Recover' ? title : ''}
                                            </h3>
                                            <h6 className="text-white mt-1 mb-0">
                                                {duration}
                                            </h6>
                                            {programType !== "Recover" ? (
                                                <h4 className="text-white fw-normal mt-n1 mb-n1">
                                                    {title}
                                                </h4>
                                            ) : undefined}

                                        </>
                                    )
                                        : programType === "Assessment" ? (
                                            <h3 className="text-white fw-normal text-capitalize">
                                                {currentVideo?.headingTitle}
                                            </h3>
                                        )
                                            : (
                                                <h3 className="text-white fw-normal text-capitalize">
                                                    {programType == "Mechanix" ? "RunSmart Mechanix" : title}
                                                </h3>
                                            )}
                                </div>
                            ) : null}

                            {!isPlaying ? (
                                <div className="tick-mark mx-3">
                                    <img
                                        style={{ opacity: `${currentWorkoutVideoProgressInfo?.progress >= 80 ? 1 : 0.3}` }}
                                        src={`${currentWorkoutVideoProgressInfo?.progress >= 80 ? './images/icons/video-header-check-mark_watched.svg' : './images/icons/video-header-check-mark_unwatched.svg'}`}
                                        alt="Like"
                                    />
                                </div>
                            ) : undefined}
                        </div>
                        <div>
                            {programType === "Recover" || programType === "Assessment" ? undefined : (
                                <Card className="border-0 p-4 m-3" >

                                    {programType !== "Midfoot" ? (
                                        <p>{`${currentVideo?.descriptions}`}</p>
                                    ) :
                                        <p dangerouslySetInnerHTML={{
                                            __html: `${currentVideo?.descriptions[0]}`
                                        }} />
                                    }
                                </Card>
                            )}
                        </div>
                    </Col>
                </Row>
            </div >
        );
    }

export default ProgramMobileView
