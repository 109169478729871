import MainCard from '../../cards/MainCard'
import ScrollRow from '../../layout/ScrollRow'
import HomeSubtitle from '../../text/HomeSubtitle'
import useScrollRow from '../../../hooks/useScrollRow'
// import useVideoProgress from '../../../hooks/useVideoProgress'
import { AuthPageProps } from '../../../modules/types'
import { getVideoTitleAndSubtitle } from '../../../modules/videoUtils'
import { Video } from '../../../modules/videos/allVideos'
import { FC } from 'react'
import { Replay } from '@mui/icons-material'
import { ProgressInfo } from '../../../hooks/useVideoProgress'

// RelatedVideosRow Props
export type RelatedVideosRowProps = {
	title: string
	introSubtitleText: string
	videos: Video[]
	userInfo: AuthPageProps['userInfo']
	rowType?: string,
	hasLikeButtons?: boolean
	favorites?: any
	forDelete?: any
	contentType?: string,
	onLike?: (videoId: string) => any
	onCancel?: (prev: any) => any,
	progressInfo?: ProgressInfo[]
}

/**
 * RelatedVideosRow Component
 */

const RelatedVideosRow: FC<RelatedVideosRowProps> = ({
	title,
	introSubtitleText,
	videos,
	// userInfo,
	rowType,
	hasLikeButtons,
	favorites,
	forDelete,
	contentType,
	onLike,
	onCancel,
	progressInfo
}) => {
	// State
	const { isScrolling, setIsScrolling } = useScrollRow()
	// const info = useVideoProgress({ videos, userInfo })

	// TO-DO: On Like
	// const onLike = () => 0

	return (
		<>
			<HomeSubtitle subText={introSubtitleText}>{title}</HomeSubtitle>
			<ScrollRow setIsScrolling={setIsScrolling}>
				{videos.map((value, index) => {
					const { title, subtitle } = getVideoTitleAndSubtitle(value)
					const videoInfo = progressInfo ? progressInfo[index] : undefined;
					// const isComplete = videoInfo?.isComplete || false
					const progress = videoInfo?.progress || 0
					return (
						<div key={index}>
							{
								forDelete && forDelete.includes(value.id) ? (
									<>
										<div className='card undo-card-large'>
											<p>You have removed this <br /> item from favorites </p>
											<div onClick={() => onCancel ? onCancel((prev: any) => prev.filter((x: any) => x !== value.id)) : undefined}>
												<Replay fontSize='large' htmlColor='#742F4E' />
											</div>
										</div>
									</>
								) : (
									<MainCard
										videoId={value.id}
										key={index}
										hasCheck={true}
										progress={progress}
										isInline
										type="videoLg"
										bgImage={contentType === "programs" ? value.image.replace('.jpg', '_400px.jpg') : value.image}
										detailTitle={title}
										detailSubtitle={rowType && rowType === "exercises" ? value?.category?.toUpperCase() : subtitle}
										link={value.url}
										onLike={() => onLike ? onLike(value.id) : undefined}
										isScrolling={isScrolling}
										hasLikeButton={hasLikeButtons}
										favorites={favorites}
										isProgram={false}
										hasPlayButton={true}
									/>
								)
							}

						</div>
					)
				})}
			</ScrollRow>
		</>
	)
}

export default RelatedVideosRow
