/* eslint-disable @typescript-eslint/no-unused-vars */
import Box from '@mui/material/Box';
import InstabotHide from '../../components/styles/InstabotHide'
import AppMenus from '../../components/layout/AppMenus'
import RSButton from '../../components/buttons/RSButton'
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import GenericAlert from '../../components/misc/GenericAlert'
import useAlert from '../../hooks/useAlert'
import AccountSubmitButtons, { PrimaryButton } from '../../components/buttons/AuthSubmitButtons'
import useQueryParams from '../../hooks/useQueryParams'
import useAppStorage from '../../hooks/useAppStorage'
import useLocalStorage from '../../hooks/useLocalStorage'
// import Badge from 'react-bootstrap/Badge'
import {
	APPLE_CLIENT_ID, APPLE_REDIRECT_URI, AUTH_COOKIE,
	GOOGLE_CLIENT_AUTH,
	HAS_SALE,
	IMPACT_TRIAL_ID,
	WS_URL
} from '../../modules/cliEnvValues'
// import { aboutLinks } from '../../modules/values'
import SignInForm, {
	SignInFormInfo,
	defaultFormInfo,
} from '../../components/forms/SignInForm'
import { navReq, postReq } from '../../modules/apiConsume'
import { createImpactClickId, logIn, signUp, updateAccountInfo } from '../../modules/accountActions'
import { FC, useState, useEffect, useCallback, useContext } from 'react'
import { useLocation } from 'react-router-dom'
import useStore from '../../store/useStore'
import { ICurrentUser } from '../../store/createAccountStore'
import classNames from 'classnames'
import AppleIcon from '@mui/icons-material/Apple';
import GoogleIcon from '@mui/icons-material/Google';
// import AppleLogin from 'react-apple-login';
import { useGoogleLogin } from '@react-oauth/google';
import axios from 'axios'
import { IAppleTokenDetails, IGoogleUser } from '../../store/types'
import { appleAuthHelpers } from 'react-apple-signin-auth';
import jwt_decode from "jwt-decode";
import startCase from 'lodash.startcase'
import ReactPixel from 'react-facebook-pixel';
import secureLocalStorage from "react-secure-storage";
import { Spinner } from 'react-bootstrap'
import useScript from '../../hooks/useScript'
import { sha1 } from 'locutus/php/strings'
import useDeviceDetect from '../../hooks/useDeviceDetect'
import useWebSocket, { ReadyState } from 'react-use-websocket';
import {
	GoogleReCaptchaProvider,
	useGoogleReCaptcha
} from 'react-google-recaptcha-v3';
import { WebSocketContext } from '../../WebsocketProvider'
import useStoreWSMessages from '../../hooks/useStoreWSMessages'
import useRNBridge from '../../hooks/useRNBridge';
import { IComponentStore } from '../../store/createComponentStore';

// Props
export type SignInProps = {
	isSignUp?: boolean
}

// URL With Offer
export const urlWithOffer = (url: string, offerType?: number) =>
	offerType ? `${url}?offer=${offerType}` : url

// Sign In Header
// const SIGNIN_HEADER = `
// Get everything you need for fast & injury-free running - all in one place!
// `

/**
 * SignIn Component
 */

const SignIn: FC<SignInProps> = ({ isSignUp }) => {
	const { setHasOffer, currentUser } = useStore((state: ICurrentUser) => state)
	const { lastRNBridgeMessage, setLastRNBridgeMessage } = useStore((state: IComponentStore) => state)

	const { executeRecaptcha } = useGoogleReCaptcha();

	// const WS_URL = 'ws://dev.runsmartonline.com';
	const url = useLocation();
	// useScript('impact.js', url)
	useScript('identify.js', url)
	useScript('conversion.js', url)

	// Properties
	const title = isSignUp ? 'Join RunSmart' : 'Log In'

	// Params
	const { offer, irclickid, requestKey, newVersion } = useQueryParams()

	//impact click id
	const CLICK_ID = localStorage.getItem("irclickid") || irclickid || undefined
	const DEVICEKEY = localStorage.getItem("deviceRequestKey") || undefined
	const NEW_VERSION = newVersion || localStorage.getItem('appVersion') || undefined


	const link = 'signup' + url.search
	const linkHref = isSignUp
		? undefined
		: `/#/${link}${HAS_SALE ? '?offer=2' : ''}`
	const linkName = isSignUp ? undefined : 'Sign Up'
	const linkDetails = isSignUp
		? undefined
		: "Don't have an account?"
	const [info, setInfo] = useState<SignInFormInfo>(defaultFormInfo)
	const [isValid, setIsValid] = useState(false)
	const [isLoading, setIsLoading] = useState(false)
	const [redirect, setRedirect] = useState('')

	const offerType = Number(offer) || undefined

	const { mobileType } = useDeviceDetect()

	const [messageData, setMessageData] = useState<{ requestKey: string, payload: any, requestType: string, timestamp: number } | undefined>();
	// Hooks
	useAppStorage()
	const { storedValue: authCookie } = useLocalStorage({ key: AUTH_COOKIE })
	const {
		showAlert,
		onAlertOpen,
		onAlertClose,
		alertTitle,
		alertMessage,
		setAlertMessage,
		alertVariant,
		setAlertVariant,
	} = useAlert()

	const { sendJsonMessage, lastJsonMessage } = useContext(WebSocketContext);
	const { removeMessageByType } = useStoreWSMessages()
	const { sendDataToReactNative } = useRNBridge()

	// const { sendJsonMessage, lastMessage, readyState } = useWebSocket(WS_URL,
	// 	{
	// 		shouldReconnect: (closeEvent) => {
	// 			return true;
	// 		},
	// 		reconnectAttempts: 30,
	// 		reconnectInterval: 30000,
	// 	}, requestKey || DEVICEKEY ? true : false);

	// On Submit
	const onSubmit = async (type?: string, googleUser?: IGoogleUser, appleUser?: IAppleTokenDetails, autoSignUp?: boolean, userDetails?: any) => {
		const honeypotField = info?.emailConfirm?.value

		const isVerified = await handleReCaptchaVerify();

		if (!honeypotField && isVerified || honeypotField == '' && isVerified) {
			// if (!honeypotField || honeypotField == '') {
			setIsLoading(true)
			// Sign Up
			if (isSignUp) {
				const response = await signUp({
					username: info.email.value,
					email: info.email.value,
					password: info.password.value,
				})
				// Login and get token
				if (response?.data?.account_id != null) {

					const response = await logIn({
						username: info.email.value,
						password: info.password.value,
					})
					// Go to User Info Page
					if (response?.data?.account_id != null) {
						const data = {
							username: info.email.value,
							password: info.password.value,
						}

						await initImpactConversion(sha1(response?.data?.account_id), response?.data?.account_id, sha1(info.email.value))

						// setTimeout(() => {
						setIsLoading(false)
						localStorage.setItem("surveyType", "new_account")
						secureLocalStorage.setItem("workerKeys", data)
						secureLocalStorage.setItem("isAppleSignup", false)
						secureLocalStorage.setItem("isGoogleSignup", false)
						setRedirect(`/#/start?type=new_account${offer ? '&offer=' + offer : ''}`)
						// }, 2000)
					}
				}

				// User or email exists
				else if (response?.error?.message?.includes('exist')) {
					setIsLoading(false)
					setAlertVariant('danger')
					setAlertMessage(response.error.message)
					onAlertOpen()
				}

				// Uncaught error
				else {
					setIsLoading(false)
					setAlertVariant('danger')
					setAlertMessage('Signup failed')
					onAlertOpen()
				}
			}


			// Login
			else {
				const data = {
					username: googleUser && type === "google" ? googleUser.email : appleUser && type === "apple" ? appleUser.email : info.user.value,
					password: googleUser && type === "google" ? googleUser.sub : appleUser && type === "apple" ? appleUser.sub : info.password.value,
				}

				const response = await logIn(data)
				if (response?.data?.account_id != null) {
					secureLocalStorage.setItem("workerKeys", data)
					secureLocalStorage.setItem("isAppleSignup", type === "apple" ? true : false)
					secureLocalStorage.setItem("isGoogleSignup", type === "google" ? true : false)
					if (url.search === "?offer=2" || url.search === "?offer=1") {
						setIsLoading(false)
						setHasOffer({
							offerParam: url.search,
							hasOffer: true
						})
						// localStorage.setItem("rs_login_type", type as string)
						setRedirect('/')
					}
					else {
						setIsLoading(false)
						// localStorage.setItem("rs_login_type", type ? type : "email")
						setRedirect('/')
					}
				} else {
					if (autoSignUp && appleUser && userDetails) {
						const signUpResponse = await signUp({
							username: appleUser.email,
							email: appleUser.email,
							password: appleUser.sub,
						})

						if (signUpResponse?.data?.account_id != null) {
							const response = await logIn({
								username: appleUser.email,
								password: appleUser.sub,
							})
							// Go to User Info Page
							if (response?.data?.account_id != null) {
								const data = {
									username: appleUser.email,
									password: appleUser.sub,
								}

								const userData = {
									firstName: userDetails.givenName,
									lastName: userDetails.familyName,
									gender: '',
									dob: '',
									favorite: '',
									joinReason: 'strength',
								}

								await updateAccountInfo(userData, "profile_update")

								// setTimeout(() => {
								setIsLoading(false)
								localStorage.setItem("surveyType", "new_account")
								secureLocalStorage.setItem("workerKeys", data)
								secureLocalStorage.setItem("isAppleSignup", true)
								secureLocalStorage.setItem("isGoogleSignup", false)
								setRedirect(`/#/start?type=new_account${offer ? '&offer=' + offer : ''}`)
								// }, 2000)
							}

						}

						// User or email exists
						else if (signUpResponse?.error?.message?.includes('exist')) {
							setIsLoading(false)
							setAlertVariant('danger')
							setAlertMessage(signUpResponse.error.message)
							onAlertOpen()
						}

						// Uncaught error
						else {
							setIsLoading(false)
							setAlertVariant('danger')
							setAlertMessage('Signup failed')
							onAlertOpen()
						}
					}
					else {
						setIsLoading(false)
						setAlertVariant('danger')
						setAlertMessage(response?.error?.message ? response?.error?.message : 'Invalid username or password')
						onAlertOpen()
					}
				}
			}
		}
		// }
	}

	const initImpactConversion = async (orderId: string, account_id: number, email: string) => {
		if (CLICK_ID) {
			// window?.callConversion?.(IMPACT_TRIAL_ID, orderId, String(account_id), sha1(email))
			await createImpactClickId(account_id as number, CLICK_ID, orderId, 'created')
		}
	}

	// Create an event handler so you can call the verification on button click event or form submit
	const handleReCaptchaVerify = async () => {
		setIsLoading(true)
		if (!executeRecaptcha) {
			console.log('Execute recaptcha not yet available');
			return false;
		}

		const token = await executeRecaptcha();
		const response = await postReq('/verify-recaptcha-token', { token: token })
		setIsLoading(false)
		if (response.data.verified)
			return true
		else
			return false
		// Do whatever you want with the token
	};

	// You can use useEffect to trigger the verification as soon as the component being loaded
	// useEffect(() => {
	// 	handleReCaptchaVerify();
	// }, [handleReCaptchaVerify]);

	// useEffect(() => {
	// 	localStorage.setItem("surveyType", "new_account")
	// 	setRedirect(urlWithOffer(`/#/start?type=new_account`, offerType))
	// }, [])

	// useEffect(() => {
	// 	if (development)
	// 		setTimeout(() => {
	// 			setRedirect(urlWithOffer(`/#/choose-plan${development ? '?development=1' : undefined}`, offerType))
	// 		}, 3000)
	// }, [development])

	const signInApple = async () => await appleAuthHelpers.signIn({
		authOptions: {
			clientId: APPLE_CLIENT_ID,
			scope: 'email name',
			redirectURI: APPLE_REDIRECT_URI,
			state: 'state',
			nonce: 'nonce',
			usePopup: true
		},
		onSuccess: (response: any) => {
			const tokenDetails: IAppleTokenDetails = jwt_decode(response.authorization.id_token)
			onSubmit("apple", undefined, tokenDetails)
		},
		onError: (error: any) => {
			if (error.error !== "popup_closed_by_user") {
				setAlertVariant('danger')
				setAlertMessage(startCase(error.error))
				onAlertOpen()
			}
		},
	});

	const handleGoogleLogin = () => {
		const localStorageRequestKey = localStorage.getItem('deviceRequestKey')
		if (requestKey || localStorageRequestKey) {
			// sendJsonMessage && sendJsonMessage({ requestType: 'google-signin-webapp', requestKey: requestKey || localStorageRequestKey, payload: {}, timestamp: new Date().getTime() })
			sendDataToReactNative({ requestType: 'google-signin-webapp', requestKey: requestKey || localStorageRequestKey, payload: {}, timestamp: new Date().getTime() })
		}
		else
			return googleLogin()
	}

	const handleAppleLogin = () => {
		const localStorageRequestKey = localStorage.getItem('deviceRequestKey')
		if ((requestKey || localStorageRequestKey)) {
			// sendJsonMessage && sendJsonMessage({ requestType: 'apple-signin-webapp', requestKey: requestKey || localStorageRequestKey, payload: {}, timestamp: new Date().getTime() })
			sendDataToReactNative({ requestType: 'apple-signin-webapp', requestKey: requestKey || localStorageRequestKey, payload: {}, timestamp: new Date().getTime() })
		}
		else
			return signInApple()
	}


	const googleLogin = useGoogleLogin({
		onSuccess: async (tokenResponse) => {
			const userInfo = await axios.get(GOOGLE_CLIENT_AUTH, { headers: { Authorization: `Bearer ${tokenResponse.access_token}` } });

			if (userInfo) {
				onSubmit("google", userInfo?.data)
				// setGoogleUser(userInfo?.data as IGoogleUser)
			}
			else {
				setAlertVariant('danger')
				setAlertMessage('Signup failed')
				onAlertOpen()
			}
		},
		onError: () => {
			setAlertVariant('danger')
			setAlertMessage('Signup failed')
			onAlertOpen()
		},
	});

	// Redirect/Cookie Effect
	useEffect(() => {
		if (authCookie && redirect) navReq(redirect)
	}, [authCookie, redirect])

	// useEffect(() => {
	// 	// secureLocalStorage.clear();
	// 	// setRedirect(`/#/start?type=new_account${offer ? '&offer=' + offer : ''}`)

	// 	ReactPixel.trackCustom('ViewContent', { k: new Date().getTime() });
	// }, [])

	useEffect(() => {
		if (irclickid)
			localStorage.setItem("clickid", irclickid)
	}, [irclickid])

	useEffect(() => {
		if (CLICK_ID) {
			window?.callIdentify?.(String(currentUser?.account_id), sha1(currentUser?.email))
		}
	}, [currentUser, url])

	useEffect(() => {
		if (requestKey)
			localStorage.setItem("deviceRequestKey", requestKey)
	}, [requestKey])

	// useEffect(() => {
	// 	if (lastJsonMessage) {
	// 		const message = lastJsonMessage
	// 		setMessageData(message)
	// 	}
	// }, [lastJsonMessage]);

	useEffect(() => {
		const localStorageRequestKey = localStorage.getItem('deviceRequestKey')
		if (lastRNBridgeMessage) {
			const messageData = JSON.parse(lastRNBridgeMessage)
			if (messageData?.requestKey === localStorageRequestKey) {
				setMessageData(messageData)
				setLastRNBridgeMessage(undefined)
			}
		}
	}, [lastRNBridgeMessage]);

	useEffect(() => {
		const localStorageRequestKey = localStorage.getItem('deviceRequestKey')
		// alert(JSON.stringify(messageData))
		if ((messageData?.requestKey === requestKey || messageData?.requestKey === localStorageRequestKey) && messageData?.requestType === 'google-signin-mobileapp' && messageData.payload) {
			// removeMessageByType('google-signin-mobileapp')
			onSubmit("google", messageData.payload)
		}

		if ((messageData?.requestKey === requestKey || messageData?.requestKey === localStorageRequestKey) && messageData?.requestType === 'apple-success-signin' && messageData.payload) {
			const tokenDetails: IAppleTokenDetails = jwt_decode(messageData?.payload?.identityToken)
			const fullName = messageData?.payload?.fullName

			// sendJsonMessage && sendJsonMessage({ requestType: 'success-signin', requestKey: requestKey || localStorageRequestKey, payload: {}, timestamp: new Date().getTime() })

			onSubmit("apple", undefined, tokenDetails, (fullName?.familyName || fullName?.givenName) ? true : false, fullName)
		}
	}, [messageData]);

	const renderBTN = (icon: any, text: string, action: any) => {
		return <RSButton sx={{ mb: '10px' }} onClick={action}>
			{icon}
			<Box sx={{ pl: '10px' }}>{text}</Box>
		</RSButton>
	}

	// Render
	return (
		<>

			{/* Styles */}
			<InstabotHide />

			{/* Alert */}
			<GenericAlert
				show={showAlert}
				title={alertTitle}
				message={alertMessage}
				variant={alertVariant}
				onClose={onAlertClose}
			/>

			{/* Page */}
			<AppMenus hideOptions hideBackButton>
				<Container fluid="md" className={classNames("px-form-5", isSignUp ? 'signup-container' : 'signup-container-sm')}>
					<div className="user-info-page--wrapper container-center">
						{/* Title */}
						{/* {isSignUp && (
							<Row className="mb-3 user-step--title d-block text-cetner d-md-none">
								<Col className="text-center">
									<Badge
										as="h5"
										style={{ fontSize: '1' }}
										bg="black"
										className="rounded-pill--signup bg-black font-14"
									>
										Step 1 of 4
									</Badge>
								</Col>
							</Row>
						)} */}
						<Row className="mb-2 user-login--title">
							<Col>
								<h3>{title}</h3>
							</Col>
						</Row>
						{isSignUp && (
							<>
								{/* Switch Page Link */}
								{/* <Row className="mb-3 user-step--title">
									<Col className="">
										<Badge
											as="h5"
											style={{ fontSize: '1rem' }}
											bg="black"
											className="rounded-pill--signup bg-black"
										>
											Step 1 of 4
										</Badge>
									</Col>
								</Row> */}

								{/* Signup Header */}
								<Row className="mb-3 text-center m-auto user--text">
									<Col className="text-center m-auto d-flex justify-content-center">
										{/* <p style={{ maxWidth: '500px' }}>{SIGNIN_HEADER}</p> */}
									</Col>
								</Row>
							</>
						)}
					</div>

					{/* Form */}
					<div className="account-form m-auto">
						{/* Form */}

						<SignInForm
							isSignUp={isSignUp}
							setInfo={setInfo}
							setIsValid={setIsValid}
						/>

						{/* Terms of Service */}
						{/* {isSignUp && (
							<p className="mb-4 text-center">
								{`By clicking "Continue" you agree to our `}
								<a href={aboutLinks.terms} style={{ color: 'black' }}>
									<strong>Terms of Service</strong>
								</a>
								{', '}
								<a href={aboutLinks.privacy} style={{ color: 'black' }}>
									<strong>Privacy Policy</strong>
								</a>
								{', '}
								<a href={aboutLinks.waiver} style={{ color: 'black' }}>
									<strong>Waiver of Liability</strong>
								</a>
								, and to receive our email updates
							</p>
						)} */}

						{isSignUp && (
							<div className='mt-1 mb-3 text-center footer-info'>
								<p>
									By Clicking Continue, you agree to our{' '}
									<a href={DEVICEKEY ? undefined : 'https://runsmartonline.com/terms-of-use/'} target={DEVICEKEY ? '' : 'blank'} onClick={() => {
										DEVICEKEY ? navReq('/#/terms-of-use') : undefined
									}} className='pointer'>
										<u>Terms of Service</u>
									</a>
									,{' '}
									<a href={DEVICEKEY ? undefined : 'https://runsmartonline.com/privacy-policy/'} target={DEVICEKEY ? '' : 'blank'} onClick={() => {
										DEVICEKEY ? navReq('/#/privacy-policy') : undefined
									}} className='pointer'>
										<u>Privacy Policy, </u>
									</a>{' '}

									<a href={DEVICEKEY ? undefined : 'https://runsmartonline.com/waiver-of-liability/'} target={DEVICEKEY ? '' : 'blank'} onClick={() => {
										DEVICEKEY ? navReq('/#/waiver-of-liability') : undefined
									}} className='pointer'>
										<u>Waiver of Liability, </u>
									</a>{' '}
									and receive our email updates.
								</p>
							</div>
						)}
						{/* Submit Buttons */}
						<Box sx={{ width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
							<RSButton
								disabled={!isValid || !executeRecaptcha || isLoading}
								sx={{ width: '220px', mb: '10px' }} onClick={onSubmit}
							>
								<Box sx={{ pl: '10px' }}>{isLoading ? <Spinner size="sm" animation='border' /> : "Continue"}</Box>
							</RSButton>
						</Box>

						{/* Switch Page Link */}
						{!isSignUp && (
							<Row className="mb-1 footer-info">
								<Col className="text-center">
									<span className="text-black me-2">{linkDetails}</span>
									<span>
										<a href={linkHref}><u>{linkName}</u></a>
									</span>
								</Col>
							</Row>
						)}

						{/* Forgot Password Link */}
						{!isSignUp && (
							<Row className="mb-3 footer-info">
								<Col className="text-center">
									<span className="text-black me-2">
										Forgot your password?
									</span>
									<span>
										<a href="/#/forgot-password"><u>Reset Password</u></a>
									</span>
								</Col>
							</Row>
						)}
						{!isSignUp ? (
							<Row xs={12}>
								<div className='other-login'>
									<Box sx={{ width: '220px' }}>
										{mobileType !== "Android" && renderBTN(<AppleIcon />, 'Log in with Apple', handleAppleLogin)}
										{renderBTN(<GoogleIcon />, 'Log in with Google', (mobileType === "Android" || mobileType === "Ios") ? handleGoogleLogin : googleLogin)}
									</Box>
								</div>
							</Row>) : undefined}
					</div>

				</Container>
			</AppMenus>
		</>
	)
}

export default SignIn