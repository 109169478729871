import Card from 'react-bootstrap/Card'
import MultiLineText from '../text/MultiLineText'
import Button from 'react-bootstrap/Button'
import classNames from 'classnames'
import { FC } from 'react'

// Props
export type SplitCardProps = {
	bgImage: string
	logoImage: string
	text: string
	title?: string
	buttonText?: string
	buttonLink?: string
	buttonDisabled?: boolean
	isColored?: boolean
	isSmall?: boolean
	className?: string
	buttonAction?: () => any
}

/**
 * SplitCard Component
 */

const SplitCard: FC<SplitCardProps> = ({
	bgImage,
	logoImage,
	text,
	title,
	children,
	buttonText,
	buttonLink,
	buttonDisabled,
	isColored,
	isSmall,
	className,
	buttonAction,
}) => {
	// Has Button
	const hasButton = buttonText ? true : false
	const buttonClass = classNames(
		'rounded-pill mb-4',
		isColored ? 'color-primary-bg' : undefined
	)
	const textClass = classNames(
		'text-black',
		hasButton ? 'text-center' : undefined,
		className
	)

	// Render
	return (
		<Card bg="white" style={{ maxWidth: '100%' }}>
			<div className="flex-content-center justify-content-center text-center">
				<Card.Img variant="top" src={bgImage} />
				<img src={logoImage} className="card-logo-center" />
			</div>
			<Card.Body>
				{(title ? true : false) && <Card.Title as="h3">{title}</Card.Title>}
				<Card.Text as="div" className={textClass}>
					<div>
						{/* Normal Content */}
						{text ? <MultiLineText text={text as string} /> : children}
					</div>
					<MultiLineText text={text} />
					{hasButton && (
						<Button
							size={isSmall ? undefined : 'lg'}
							variant="dark"
							className={buttonClass}
							disabled={buttonDisabled}
							style={{
								maxWidth: '18rem',
								height: '50px',
								color: `${buttonDisabled ? '#555555' : '#010101'} !important`,
								border: `1px solid ${buttonDisabled ? 'transparent' : '#9fcd2b'} !important`,
								backgroundColor: `${buttonDisabled ? '#cbcbcb' : '#9fcd2b'} !important`,
								textTransform: 'none',
								borderRadius: '5px !important',
								fontFamily: 'Poppins-Medium !important',
								fontSize: '16px',
								lineHeight: '1em'
							}}
							as={buttonLink ? 'a' : undefined}
							href={buttonLink || undefined}
							onClick={buttonAction || undefined}
						>
							{buttonText}
						</Button>
					)}
					<div></div>
				</Card.Text>
			</Card.Body>
		</Card>
	)
}

export default SplitCard
