import newExercises from './allNewExercises.json'

// Midfoot Project
export const midfoot = []


// Base Three
export const baseThree = [
	257359653, 257359366, 257359512, 261055910, 261056321, 261056470,
]

// Base Six
export const baseSix = [
	229654477, 103857986, 103857989, 103857991, 103260370, 103260368, 103260367,
	103260365, 103260364, 103253434, 103253433, 103253432,
]

// Mechanix
export const mechanix = [
	121058239, 123773838, 121058241, 120877069, 121058243, 120646624, 120877070,
	120646626, 120646625, 120759482, 120759483, 120759484, 121058244, 121058246,
	122204067, 121167672, 121167673, 121167676, 121167677, 121187529, 121187530,
	121187532, 121187535, 121197694, 123472453, 123472454, 122809260, 122809259,
	123472303, 122160151, 122204070, 122204069, 122204068,
]

// Yoga Intro
export const yogaIntro = [
	548325612, 185147350, 185147349, 185147348, 185147347, 185147346
]

// Yoga
export const yoga = [
	546874547, 546875992, 548220973, 548220342, 548219448, 548219884,
	103196882, 103196884, 103196886, 103196888, 103196889, 103199526, 103199527,
	103199528, 103199529, 103199530, 103220324, 103220326
]

// Prime Into
export const primeIntro = [139803482, 139804523, 139808580, 139808126]

// Prime
export const prime = [
	138350084, 138350085, 138769297, 139358144, 138769298, 139358150, 140526325,
	140231576, 140233863, 140236673, 140239409, 140557567,
]

// Webinars
export const webinars = {
	'60 Second Core': 117060386,
	'Injury Management': 174394906,
	'Peak on Race Day': 194264416,
	'Fast Over 40': 144814602,
	'Run Through Fatigue': 180568195,
	'RunSmart Protocol': 229655635,
	'Foam Rolling': 229476430,
}

// Training Guide
export const trainingGuide = [
	277829059, 277829026, 761151027, 277829008, 277829103, 277829193, 277830119,
	277828835, 277829271, 277829367,
]

// Strength Guide
export const strengthGuide = [
	764376077, 415767600, 424368802, 423592233, 423592412, 423592173, 423592343, 423592277,
	423592463, 424287036, 424286995,
]

// Foam Rolling
export const foamRolling = [
	175456899, 175456915, 175456903, 175456908, 175456921, 175456902,
]

// Stretching
export const stretching = [
	174395695, 174395841, 173778834, 174395813, 175475553, 174395766, 174395881,
	174395752, 173778710, 174395712, 116117592,
]

// Strength Exercises
export const strengthExercises = [
	173779430, 173779408, 173779333, 173779258, 173779392, 173779277, 173779326,
	175475750, 173779417, 173779214, 173779308,
]

// Form Drills
export const formDrills = [
	116118548, 122886049, 122910859, 123153187, 174949625, 174949675, 174949663,
	174949665,
]

// RunSmart 101
export const runSmart101 = [122886050, 175475684, 123152653]

// All Exercises
export const allExercises = [
	175456899, 116118548, 277829059, 174395695, 415767600, 174395841, 424368802,
	175456915, 122886049, 423592233, 175456903, 277829026, 173778834, 122910859,
	175456908, 174395813, 761151027, 173779430, 123153187, 423592412, 175456921,
	423592173, 174949625, 277830337, 277829008, 175475553, 174949675, 174395766,
	423592343, 277829103, 175456902, 277829193, 174949663, 423592277, 174395881,
	277830119, 174395752, 423592463, 174949665, 424287036, 277828835, 173778710,
	173779408, 174395712, 122886050, 424286995, 277829271, 175475684, 116117592,
	173779333, 277829367, 173779258, 123152653, 173779392, 173779277, 173779326,
	175475750, 173779417, 173779214, 173779308,
]

const allNewExercises = () => {
	const urls: Array<number> = []
	newExercises.forEach((value) => urls.push(value.video_id))
	return urls;

}

//Recover
export const recover = allNewExercises()

// All Videos
export const allVideos = [
	...baseThree,
	...baseSix,
	...mechanix,
	...yogaIntro,
	...yoga,
	...primeIntro,
	...prime,
	...Object.values(webinars),
	...trainingGuide,
	...strengthGuide,
	...foamRolling,
	...stretching,
	...strengthExercises,
	...formDrills,
	...runSmart101,
	...allNewExercises()
]

// Page Urls
export const pageUrls = {
	'programs/base3': baseThree,
	'programs/base6': baseSix,
	'programs/mechanix': mechanix,
	'programs/yoga': [...yoga, ...yogaIntro],
	'programs/prime': [...prime, ...primeIntro],
	webinars,
	'guides/training': trainingGuide,
	'guides/strength': strengthGuide,
	'exercises/foam-rolling': foamRolling,
	'exercises/stretching': stretching,
	'exercises/strength': strengthExercises,
	'exercises/run-form-drills': formDrills,
	'exercises/runsmart-101': runSmart101,
	'exercises': allNewExercises()
}
