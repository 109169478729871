import { FormInfo } from '../../forms/AccountForm'
import { updatePassword } from '../../../modules/accountActions'
import ChangePassForm, {
	defaultFormInfo as changePassFormInfo,
} from '../../forms/ChangePassForm'
import RSButton from '../../../components/buttons/RSButton'
import { FC, useState } from 'react'
import Box from '@mui/material/Box'
import useStore from '../../../store/useStore'
import { IComponentStore } from '../../../store/createComponentStore'

// Change Password Props
export type ChangePasswordProps = {
	onCancel?: () => any
	onAlertOpen: () => any
	setAlertMessage: (x: string) => any
	setAlertVariant: (x: string) => any,
	side?: boolean
}

/**
 * Change Password Component
 */

const ChangePassword: FC<ChangePasswordProps> = ({
	onAlertOpen,
	setAlertMessage,
	setAlertVariant
}) => {
	// State
	const [info, setInfo] = useState(changePassFormInfo as FormInfo)
	const [isValid, setIsValid] = useState(false)
	const [responseMessage, setResponseMessage] = useState(0)

	const { setIsLoading, setIsSuccess } = useStore((state: IComponentStore) => state)
	
	// On Submit
	const onSubmit = async () => {
		setIsLoading(true)
		const response = await updatePassword(
			info.currentPassword.value,
			info.newPassword.value
		)
		if (response?.status == 'ok') {
			setAlertVariant('success')
			setAlertMessage('Password was reset successfully')
			setResponseMessage(1)
			setIsLoading(false)
			setIsSuccess(true)

			setTimeout(() => {
				setIsSuccess(false)
			}, 1500)
		} else {
			setAlertVariant('danger')
			setAlertMessage('Could not reset password')
			setResponseMessage(-1)
			setIsLoading(false)
		}

		onAlertOpen()
	}

	// Render
	return (
		<>
			<Box sx={{ fontSize: '16px', fontFamily: 'Poppins-Medium', mb: 4}}>Update Password</Box>
			<ChangePassForm setInfo={setInfo} setIsValid={setIsValid} />
			<Box sx={{ color: responseMessage == 1 ? '' : 'red',fontSize: '14px', fontFamily: 'Poppins-Light', height: '80px'}} justifyContent={'center'} alignItems={'center'}>
				{responseMessage == 1 ? '* Password was reset successfully' : responseMessage == -1 ?'* Could not reset password' : ''}</Box>
			<RSButton sx={{mb: 4}} onClick={onSubmit} disabled={!isValid} >Save Password</RSButton>
		</>
	)
}

export default ChangePassword
