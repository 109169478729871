import { useState, useEffect } from 'react'

/**
 * useAlert Hook
 */

const useAlertHook = () => {
	// State
	const [show, setShow] = useState(false)
	const [title, setTitle] = useState<string | undefined>(undefined)
	const [message, setMessage] = useState<string | JSX.Element>('Success')
	const [variant, setVariant] = useState('success')

	// On Open
	const onOpen = () => setShow(true)

	// On Close
	const onClose = () => setShow(false)

	// Variant Change Effect
	useEffect(() => {
		if (variant == 'success' && (!title || title == 'Failure')) {
			setTitle('Success')
		} else if (variant == 'danger' && (!title || title == 'Success')) {
			setTitle('Failure')
		}
	}, [variant, title])

	return {
		showAlert: show,
		onAlertOpen: onOpen,
		onAlertClose: onClose,
		alertTitle: title,
		setAlertTitle: setTitle,
		alertMessage: message,
		setAlertMessage: setMessage,
		alertVariant: variant,
		setAlertVariant: setVariant,
	}
}

export default useAlertHook
