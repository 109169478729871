import { useState } from 'react'

const useStoreWSMessages = () => {
    const [webSocketMessages, setWebSocketMessages] = useState<any[]>([])

    const storeWebSocketMessages = (message: any) => {
        setWebSocketMessages((prev) => [...prev.filter(x => x?.requestType !== message?.requestType), message])
    }

    const removeMessageByType = (messageType: string) => {
        setWebSocketMessages((prev) => [...prev.filter(x => x?.requestType !== messageType)])
    }

    return { webSocketMessages, storeWebSocketMessages, removeMessageByType }
}

export default useStoreWSMessages