import moment from 'moment'
import React, { useEffect, useState } from 'react'
import { Container, Placeholder } from 'react-bootstrap'
import useScrollDirection from '../../../hooks/useScrollDirection'
import { GetCurrentUserPdf } from '../../../modules/trainingPlanActions'
import DownloadPlan from './DownloadPlan'

const Header = (props: any) => {
  const { selectedRace, currentWeek, userInfo, getRaceDistance, userCustomPaces } = props

  const scrollDirection = useScrollDirection();

  const [hasExistingPdf, setHasExistingPdf] = useState(false)
  const [isLoading, setIsLoading] = useState(true)
  const [daysBeforeStart, setDaysBeforeStart] = useState(0)

  const isPDFExist = async (abort: AbortSignal) => {
    const isPlanFinish = new Date(userInfo?.race_date).getTime() <= new Date().getTime() ? true : false
    await GetCurrentUserPdf(userInfo?.uid, userInfo?.start.split('T')[0], userInfo?.race_date.split('T')[0], abort).then(res => {
      setIsLoading(false)
      if (res?.data?.result.length > 0 && isPlanFinish)
        setHasExistingPdf(true)
      else if (res?.data?.result.length > 0 && !isPlanFinish)
        setHasExistingPdf(true)
      else if (res?.data?.result.length == 0 && !isPlanFinish)
        setHasExistingPdf(true)
      else
        setHasExistingPdf(false)
    })
  }

  useEffect(() => {
    const abortController = new AbortController();

    if (userInfo) {
      setDaysBeforeStart(moment(userInfo?.start).diff(moment(new Date()), 'days') | 0)
      isPDFExist(abortController.signal)
    }
    return () => {
      abortController.abort();
    }
  }, [userInfo])

  return (
    <Container>
      {
        currentWeek?.key < 0 ? (
          <h4 className={`plan-start-info ${scrollDirection === "down" ? 'no-top' : ''} ${daysBeforeStart < 0 ? 'd-none' : ''} font-16 font-md-16 font-weight-semibold`}>
            {/* Your Plan Begins in {currentWeek?.key * (-1) + (userInfo?.monday_start ? 1 : 0)} {(currentWeek?.key * (-1) + (userInfo?.monday_start ? 1 : 0)) > 1 ? 'Weeks' : 'Week'} */}

            {`Your Plan Begins in ${daysBeforeStart} ${daysBeforeStart > 1 ? 'days' : 'day'}`}
          </h4>
        ) : null
      }
      <div className="training-header">
        {selectedRace ? (
          <h1 className="traning-page--title font-weight-bold text-uppercase text-center font-md-15">
            {selectedRace?.title} {selectedRace?.value != 4 && selectedRace?.subTitle} TRAINING PLAN
          </h1>
        ) : (
          <h1 className="traning-page--title font-weight-bold text-uppercase text-center font-md-15">
            <Placeholder style={{ width: '100%', textAlign: 'center' }} as="span" animation="glow">
              <Placeholder style={{ borderRadius: 2 }} xs={10} sm={8} md={5} lg={5} />
            </Placeholder>
          </h1>
        )}

        {
          !isLoading ? (
            !hasExistingPdf ? (
              <DownloadPlan
                userCustomPaces={userCustomPaces}
                getRaceDistance={getRaceDistance}
                userInfo={userInfo}
                isPDFExist={isPDFExist} />
            ) : null
          ) : null
        }
        <h4 className="select-week-title font-12 text-capitalize mt-3">Select a Week</h4>
      </div>
    </Container>
  )
}

export default Header