import {FC, useState, useEffect} from 'react'

import Grid from '@mui/material/Grid'
import Box from '@mui/material/Box'
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Select, { SelectChangeEvent } from '@mui/material/Select';

import RSButton from '../../components/buttons/RSButton'
import { AccountProfile } from '../../api/v2/account/Account.types'

export type GenderProps = {
	data: AccountProfile,
	handleUpdateProfile: Function,
}

const Gender: FC<GenderProps> = ({data, handleUpdateProfile}) => {
	const {gender} = data
	const [dataGender, setDataGender] = useState<any>(gender || ''  );

	const handleSave = async () => {
		handleUpdateProfile({ gender: dataGender })
	}

	useEffect(()=>{
		setDataGender(gender)
	},[gender])

	return (
		<Box>
			<Box sx={{fontSize: '16px', fontFamily: 'Poppins-Medium'}}>Gender</Box>
			<Grid container xs={12} justifyContent='space-between'>
				<FormControl sx={{ mt: '10px', width: '100%' }} size="small">
					<InputLabel id="demo-select-small-label">Select</InputLabel>
					<Select
						sx={{ height: '38px', backgroundColor: 'white' }}
						labelId="demo-select-small-label"
						id="demo-select-small"
						value={dataGender}
						label="Gender"
						onChange={(e: SelectChangeEvent) => setDataGender(e.target.value as string)}
					>
						<MenuItem value={'Male'}>Male</MenuItem>
						<MenuItem value={'Female'}>Female</MenuItem>
						<MenuItem value={'Non-Binary'}>Non-Binary</MenuItem>
					</Select>
				</FormControl>
			</Grid>
			<RSButton onClick={handleSave} disabled={!dataGender || dataGender === gender } sx={{mt: 3}}>Save</RSButton>
		</Box>
	)
}

export default Gender;
