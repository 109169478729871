import { FC } from 'react'

/**
 * Arrow Component
 */

const Arrow: FC = () => {
	// Render
	return (
		<svg className="arrow-icon" viewBox="301 467 33.516 72.924">
			<path d="M 301.0000305175781 467.0001220703125 L 334.5160827636719 503.8843383789062 L 301.0000305175781 539.9243774414062"></path>
		</svg>
	)
}

export default Arrow
