export const DAYS_OF_WEEK = ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"];
export const DAYS_OF_WEEK_ABBV = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];
export const DAYS_OF_WEEK_ABBV_SHORT = ["Su", "M", "Tu", "W", "Th", "F", "Sa"];
export const DAYS_OF_WEEK_ALT = ["Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday", "Sunday"];
export const DAYS_OF_WEEK_ALT_ABBV = ["Mon", "Tue", "Wed", "Thu", "Fri", "Sat", "Sun"];
export const DAYS_OF_WEEK_ALT_ABBV_SHORT = ["M", "Tu", "W", "Th", "F", "Sa", "Su"];

export const DAYS_ALT = [
    {
        id: 0,
        day: "Monday"
    },
    {
        id: 1,
        day: "Tuesday"
    },
    {
        id: 2,
        day: "Wednesday"
    },
    {
        id: 3,
        day: "Thursday"
    },
    {
        id: 4,
        day: "Friday"
    },
    {
        id: 5,
        day: "Saturday"
    },
    {
        id: 6,
        day: "Sunday"
    }
]

export const DAYS = [
    {
        id: 0,
        day: "Sunday"
    },
    {
        id: 1,
        day: "Monday"
    },

    {
        id: 2,
        day: "Tuesday"
    },
    {
        id: 3,
        day: "Wednesday"
    },
    {
        id: 4,
        day: "Thursday"
    },
    {
        id: 5,
        day: "Friday"
    },
    {
        id: 6,
        day: "Saturday"
    }
]

export const sortDays = (days: string) => {
    const daysArray = days?.split(',');
    daysArray?.sort((a: string, b: string) => {
        return DAYS_OF_WEEK?.indexOf(a) - DAYS_OF_WEEK?.indexOf(b);
    });
    return daysArray?.join(',');
}
