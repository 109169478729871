import moment, { DurationInputArg1, DurationInputArg2 } from 'moment'
import React, { useState } from 'react'
import { Modal, Spinner } from 'react-bootstrap'
import { CouponRedemption } from 'recurly'
import { redeemCoupon } from '../../../modules/accountActions'
import RSButton from "../../../components/buttons/RSButton"

const CouponModal = (props: any) => {
    const { showModal, handleCloseModal, afterBillingProcess } = props
    const [code, setCode] = useState('')
    const [redeemStatus, setRedeemStatus] = useState('')
    const [isLoading, setIsLoading] = useState(false)
    const [redeemCouponResponse, setRedeemCouponResponse] = useState<CouponRedemption>()

    const handleSubmitCode = async () => {
        if (code || code !== '') {
            setIsLoading(true)
            const result = await redeemCoupon(code, props.signupMethod, props.email)
            if (result?.data?.status === "success") {
                setRedeemStatus("success")
                setRedeemCouponResponse(result?.data?.coupon)
                setIsLoading(false)
            }
            else {
                setRedeemStatus("failed")
                setIsLoading(false)
            }
        }
    }

    return (
        <Modal
            className="common-modal"
            show={showModal}
            onHide={handleCloseModal}
            centered
        >
            <Modal.Header closeButton />
            <Modal.Body className='coupon-modal'>
                {redeemStatus === '' ? (
                    <div className='coupon-form'>
                        <p>
                            Enter Your Race Code
                        </p>
                        <input value={code} onChange={(e) => setCode(e.target.value.trim())} style={{ textTransform: 'uppercase' }} type="text" />
                        <RSButton onClick={handleSubmitCode}> {!isLoading ? "Submit" : (<Spinner size='sm' animation='border' />)}</RSButton>
                    </div>
                ) : redeemStatus === "failed" ? (
                    <div className='coupon-form'>
                        <h3>
                            Error!
                        </h3>
                        <p>
                            That code <b>{`"${code.toUpperCase()}"`}</b> is either invalid or expired. Please try again or use the X to return to the signup screen.
                        </p>
                        <RSButton onClick={() => setRedeemStatus("")}>Retry</RSButton>
                    </div>
                ) : redeemStatus === "success" && redeemCouponResponse ? (
                    <div className='coupon-form'>
                        <h3>
                            Success!
                        </h3>
                        <p>
                            You successfully used a code for the <b>{redeemCouponResponse?.coupon?.name}</b>. You have free access to RunSmart until {redeemCouponResponse?.coupon?.redeemBy ? moment(String(redeemCouponResponse?.coupon?.redeemBy).split('T')[0]).format('MMMM D, Y') : moment(new Date()).add(redeemCouponResponse?.coupon?.discount?.trial?.length as unknown as DurationInputArg1, redeemCouponResponse?.coupon?.discount?.trial?.unit as DurationInputArg2).format('MMMM D, Y')}.
                        </p>
                        <RSButton onClick={afterBillingProcess}>Continue</RSButton>
                    </div>
                ) : undefined
                }

            </Modal.Body>
        </Modal>
    )
}

export default CouponModal
