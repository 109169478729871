import { FC, isValidElement } from 'react'

import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';

import RSFlexCard from './RSFlexCard'
import RSCardCont from './RSCardCont'
import Heart from '../logos/Heart'

export type RSVideoCardProps = {
	videoList: Array<any>,
	sx?: object,
	getVideoProgress?: Function,
	onboarding?: boolean,
	thmb_scale?: number
}

export type VideoProps = {
	id?: string | number,
	duration?: number,
	src?: string,
	headingTitle?: string,
	onClick?: Function,
	liked?: boolean,
	onLike?: Function,
	type?: any
}


const RSVideoCard: FC<RSVideoCardProps> = ({ videoList, sx = {}, getVideoProgress, onboarding, thmb_scale }) => {
	const renderBody = () => {

		return videoList?.map((v: VideoProps) => {
			const id = v?.id ? v.id : ''
			const isLiked = v?.liked
			const progress = getVideoProgress ? getVideoProgress(v.id) : 0
			const duration = v?.duration ? Math.round(v?.duration / 60) : ''
			const videoSrc = v?.src ? v?.src : (id ? `https://vumbnail.com/${id}.jpg` : '')
			return isValidElement(v) ? v : <>
				{id && v?.onLike && !onboarding && <Box sx={{ position: 'absolute', top: 20, right: 8, zIndex: 1 }}>
					<Heart onLike={() => v?.onLike?.()} isLiked={isLiked} isNotAbsolute={true} isFromMetadata={true} />
				</Box>}

				{onboarding && <Box sx={{ position: 'absolute', top: 20, right: 8, zIndex: 1 }}>
					<i className="fa-regular fa-lock-keyhole" style={{ fontSize: '20px' }}></i>
				</Box>}

				<RSCardCont onClick={() => v?.onClick?.()} sx={{ pointerEvents: !onboarding ? 'auto' : 'none' }}>
					<Grid container xs={5.5} sx={{ position: 'relative', overflow: 'hidden', height: '100px', boxShadow: '0px 0px 3px #80808024' }} justifyContent='center' alignItems='center'>
						{videoSrc ? <img style={{ scale: `${thmb_scale ? thmb_scale : '2'}`, margin: 'auto' }} src={videoSrc} alt="" />
							: <Box className='loading-days toRight' />}

						{progress ? <Box sx={{ width: '100%', height: '5px', position: 'absolute', bottom: 0, backgroundColor: 'transparent', textTransform: 'capitalize !important' }}>
							<Box sx={{ width: `${progress}%`, height: '100%', backgroundColor: '#2b9fcd' }} />
						</Box> : ''}
					</Grid>
					<Grid container xs={6.5} sx={{ height: '100%' }}>
						<Grid container xs={12} sx={{ px: 2 }} justifyContent='space-between' alignItems='space-between'>
							<Grid container sx={{ flexDirection: 'column' }} justifyContent='flex-start' alignItems='flex-start'>
								<Box sx={{ fontFamily: 'Poppins-Bold !important', fontSize: '16px', textAlign: 'left', lineHeight: '1.2em', mt: '2px' }}>
									{v?.headingTitle}
								</Box>
								{duration ? <Box sx={{ pb: 2, fontFamily: 'Poppins-Light !important', fontSize: '16px' }}>
									{duration}min
								</Box> : (duration !== 0 ? <Box sx={{ position: 'relative', width: '80px', height: '20px' }}>
									<Box className='loading-days toRight' sx={{ border: 'none !important' }} />
								</Box> : '')
								}
							</Grid>
						</Grid>
					</Grid>
				</RSCardCont>
			</>
		})
	}

	return videoList?.length ? <RSFlexCard videoList={renderBody()} sx={sx} /> : <></>
}

export default RSVideoCard;
