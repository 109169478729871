import React, { useState, useRef, useEffect } from 'react'
import Overlay from 'react-bootstrap/Overlay'
import Popover from 'react-bootstrap/Popover'
import { FC } from 'react'
// import { OverlayTrigger } from 'react-bootstrap'

export type CustomPopOverProps = {
	icon: any
	children: any,
	autoClose?: boolean
}

const CustomPopOver: FC<CustomPopOverProps> = ({ icon, children, autoClose }) => {
	const [show, setShow] = useState(false)
	const [target, setTarget] = useState(null)
	const ref = useRef(null)
	const Overlayref = useRef<HTMLElement>(null)

	const handleClick = (event: any) => {
		setShow(!show)
		setTarget(event.target)
	}

	useEffect(() => {
		/**
		 * Alert if clicked on outside of element
		 */
		function handleClickOutside(event: any) {
			if (Overlayref.current && !Overlayref.current.contains(event.target)) {
				setShow(false)
			}
		}
		// Bind the event listener
		document.addEventListener('mousedown', handleClickOutside)
		return () => {
			// Unbind the event listener on clean up
			document.removeEventListener('mousedown', handleClickOutside)
		}
	}, [Overlayref])

	useEffect(() => {
		autoClose ? setShow(false) : null;
	}, [autoClose])

	const popoverClickRootClose = (
		<Overlay
			show={show}
			target={target}
			placement="bottom"
			container={ref}
			containerPadding={20}
			ref={Overlayref}
		>
			<Popover id="popover-contained">
				<Popover.Body>
					{children}
					<div onClick={handleClick} className="popover-close">
						<img src="./images/icons/popover-close.png" alt="Close" />
					</div>
				</Popover.Body>
			</Popover>
		</Overlay>
	)

	return (
		// <OverlayTrigger overlay={popoverClickRootClose} trigger="click" rootClose>
		<div ref={ref}>
			<div onClick={handleClick} style={{ lineHeight: 1 }}>
				{icon}
			</div>
			{popoverClickRootClose}
		</div>
		// </OverlayTrigger>
	)
}
export default CustomPopOver
