// Is Required
export const isRequired = (value: string, field = 'Field') =>
	value.length ? '' : `${field} is required`

// Valid Email
export const validEmail = (value: string, field = 'Email') =>
	/^\S+@\S+\.\S+$/.test(value) ? '' : `${field} is not valid`

// Valid Pass
const validPass = (length: number, value: string, field = 'Password') =>
	value.length >= length
		? ''
		: `${field} must be at least ${length} characters long`

// Valid Password
export const validPassword = validPass.bind(null, 4)

// Valid Signup Password
export const validSignupPassword = validPass.bind(null, 8)

// Valid Confirm Password
export const validConfirmPassword = (confirmValue: string, value: string) =>
	value == confirmValue ? '' : 'Passwords must match'

// Valid Confirm Email
export const validConfirmEmail = (confirmValue: string) =>
	confirmValue === "" || !confirmValue || confirmValue == null ? '' : 'Invalid Confirm Email'
