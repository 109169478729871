
import { getReq } from '../modules/apiConsume'

const useHereLocation = () => {

    const getSuggestedLocation = async (keyword: string) => {
        const token = getReq(`/v2/location/get-suggested?keyword=${keyword}`)
        return token
    }

    return { getSuggestedLocation }
}

export default useHereLocation