const InstabotHide = () => (
	<style>
		{`
			.roko-instabot-widget-button {
				display: none !important;
			}
			`}
	</style>
)
export default InstabotHide
