import { ActivityDetailsStorage, Summary } from "../api/v2/garmin-connect/garmin-connect.types"
import { StoreSlice } from "./types"

export interface GarminDailyDetail {
    date: string,
    activities: Summary[]
}


export interface IGarminStore {
    garminDailyDetails: GarminDailyDetail[] | [],
    setGarminDailyDetails: (garminDailyDetails: GarminDailyDetail[] | []) => void,
    currentDayActivities: Summary[] | []
    setCurrentDayActivities: (currentDayActivities: Summary[]) => void,
    currentWeekActivities: ActivityDetailsStorage[] | [],
    setCurrentWeekActivities: (currentWeekActivities: ActivityDetailsStorage[]) => void,
}

const createGarminStore: StoreSlice<IGarminStore> = (set) => ({
    garminDailyDetails: [],
    setGarminDailyDetails: (garminDailyDetails: GarminDailyDetail[] | []) => set(() => ({ garminDailyDetails: garminDailyDetails })),
    currentDayActivities: [],
    setCurrentDayActivities: (currentDayActivities: Summary[]) => set(() => ({ currentDayActivities: currentDayActivities })),
    currentWeekActivities: [],
    setCurrentWeekActivities: (currentWeekActivities: ActivityDetailsStorage[]) => set(() => ({ currentWeekActivities: currentWeekActivities })),
})

export default createGarminStore
