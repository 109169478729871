import Nav from 'react-bootstrap/Nav'
import classNames from 'classnames'
import { FC, useState, useEffect } from 'react'

// Props
export type SelectPillProps = {
	index?: number
	options: string[]
	mobileHiddenOptions?: string[]
	onChange: (value: string, index: number) => any,
	isAccount?: boolean
	disabled?: boolean
	isProgram?: boolean
	color?: string
}

/**
 * SelectPill Component
 */

const SelectPill: FC<SelectPillProps> = ({
	index = 0,
	options,
	// mobileHiddenOptions,
	onChange,
	isAccount,
	disabled,
	isProgram,
	color
}) => {
	// State
	const [curIndex, setCurIndex] = useState(index)
	const [curKey, setCurKey] = useState(0)

	// On Nav Change
	const onNavChange = (value: string, index: number) => {
		setCurIndex(index)
		onChange(value, index)
	}

	// Index Change Effect
	useEffect(() => {
		setCurIndex(index)
		setCurKey(curKey + 1)
	}, [index])

	// Render
	return (
		<div key={curKey} className={classNames("d-flex px-3 justify-content-center", isAccount ? 'account-nav-main' : undefined)}>
			<Nav
				variant="pills"
				className={classNames(isAccount ? 'account-nav rounded-pill' : isProgram ? 'program-nav squared-pill' : undefined, "rs-nav bg-dark")}
				defaultActiveKey={isAccount && curIndex == 3 ? 0 : curIndex}
			>
				{options.map((value, optIndex) => {
					const navItemClass = classNames(
						isProgram ? 'squared-pill' : 'rounded-pill',
						'small-text',
						isAccount ? 'select-pill' : undefined,
					)
					const className = classNames(
						isProgram ? 'squared-pill' : 'rounded-pill',
						curIndex == optIndex ? 'color-primary-bg' : undefined
					)
					return (
						<Nav.Item key={optIndex} className={navItemClass}>
							<Nav.Link
								disabled={disabled}
								className={className}
								style={{ backgroundColor: color && curIndex == optIndex ? color : undefined }}
								eventKey={String(optIndex)}
								onClick={onNavChange.bind(null, value, optIndex)}
							>
								{value}
							</Nav.Link>
						</Nav.Item>
					)
				})}
			</Nav>
		</div >
	)
}

export default SelectPill
