import { useEffect } from 'react'
import useQueryParams from '../hooks/useQueryParams'
import { navReq } from '../modules/apiConsume'
import { IVimeoPlayerStore } from '../store/createVimeoPlayerStore'
import useStore from '../store/useStore'
import VideoPlayer from './VideoPlayer'

const VideoPlayerPage = () => {

    const { setIsPlaying, setShowVimeoPlayer, setVimeoId, setIsFullScreen } = useStore((state: IVimeoPlayerStore) => state)
    const { videoId, programId, tab, progress } = useQueryParams()

    const handlePlay = (videoId: string) => {
        setVimeoId(videoId)
        setShowVimeoPlayer(true)
        setIsFullScreen(true)
        setIsPlaying(true)
    }

    const handleBackToProgramPage = () => {
        navReq(`/#/programs?prev_program_id=${programId}&tab=${tab}`)
    }

    useEffect(() => {
        if (videoId && programId && tab)
            handlePlay(videoId)
    }, [videoId, programId, tab])

    return (
        <>
            <VideoPlayer backToProgramPage={handleBackToProgramPage} progress={progress} />
        </>
    )
}

export default VideoPlayerPage