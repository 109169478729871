/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { FC, useEffect, useState } from 'react'
import { Button, Col, Container, Form, Row, Spinner, Table } from 'react-bootstrap'
import BackButton from '../../components/buttons/BackButton'
import SelectPill from '../../components/input/SelectPill'
import AppMenus, { BodyComponent } from '../../components/layout/AppMenus'
import ScrollRow from '../../components/layout/ScrollRow'
import PageTitle from '../../components/text/PageTitle'
import useScrollRow from '../../hooks/useScrollRow'
import DatePicker from "react-datepicker";
import useSale, { ISale } from '../../hooks/useSale'
import moment from 'moment'
import WithAuth from '../../components/WithAuth'
import { AuthPageProps } from '../../modules/types'
import { navReq } from '../../modules/apiConsume'
import useAdminPage from './useAdminPage'
import { BrowserUpdated, DownloadDoneTwoTone } from '@mui/icons-material'
import useMidfoot from '../midfoot-project/useMidfoot'
import ExcelExporter from '../../components/misc/ExcelExporter'
import AdminUI from './AdminUI'
import Embeddings from './Embeddings'
import ChatCompletion from './ChatCompletion'
import ChatCompletionStep from './ChatCompletionStep'
import ChatCompletionSingle from './ChatCompletionSingle'
import TrainingPlanManager from './TrainingPlanManager'

const PILL_OPTIONS = ["OpenAI Embeddings", "OpenAI Chat - Single",
    "Training Plans",
    // "OpenAI Chat - Steps",
    "Manage Sale",
    // "Midfoot Project",
    'Styles']
const ALLOWED_USER = [1, 64, 16123]
const AdminPage: FC<AuthPageProps> = ({ userInfo }) => {
    const { isScrolling, setIsScrolling } = useScrollRow()

    const { allSale: activeSale, isLoadingSale, updateSale } = useSale()

    const [pillKey, setPillKey] = useState(0)
    const [dayIntervalValue, setDayIntervalValue] = useState(0)
    const [selectedPill, setSelectedPill] = useState('OpenAI Embeddings')
    const [midfootUsers, setMidfootUsers] = useState<any[] | undefined>()
    const [endDate, setEndDate] = useState(new Date());
    const [saleDetails, setSaleDetails] = useState<ISale | undefined>(activeSale)

    // const { updateDaysInterval, surveyReport = {}, IsLoading } = useAdminPage(selectedPill)

    const { getActiveUsers, isLoading: loadingMidfootUsers } = useMidfoot()

    const onPillChange = (value: any) => {
        setSelectedPill(value)
        // setPillKey(value === "Introduction" ? 0 : 1)
    }

    const handleUpdateSale = (type: string) => {
        if (type === "headline" && saleDetails?.headline !== activeSale?.headline)
            updateSale(saleDetails as ISale)

        else if (type === "subtext" && saleDetails?.subtext !== activeSale?.subtext)
            updateSale(saleDetails as ISale)

        else if (type === "banner" && saleDetails?.banner !== activeSale?.banner)
            updateSale(saleDetails as ISale)
    }

    useEffect(() => {
        setSaleDetails(activeSale)
    }, [activeSale])

    useEffect(() => {
        if (!isLoadingSale && saleDetails?.end !== activeSale?.end)
            updateSale(saleDetails as ISale)
    }, [saleDetails?.end])

    useEffect(() => {
        updateSale(saleDetails as ISale)
    }, [saleDetails?.active])

    useEffect(() => {
        if (userInfo?.account_id && !ALLOWED_USER.includes(userInfo?.account_id as number)) {
            navReq('/#/')
        }
    }, [userInfo])

    useEffect(() => {
        if (selectedPill === "Midfoot Project")
            getActiveUsers().then(res => {
                setMidfootUsers(res)
            })
    }, [selectedPill])

    return (
        <AppMenus hasContainer>
            <BodyComponent>
                {/* Back Button */}
                {/* <BackButton /> */}

                {/* Title */}
                {/* <Row className="d-block d-md-none">
                    <Col>
                        <PageTitle text={'Administrator'} isMobileTitle />
                    </Col>
                </Row> */}
                <Row className='text-center'>
                    <ScrollRow setIsScrolling={setIsScrolling}>
                        <SelectPill
                            index={pillKey}
                            key={pillKey}
                            options={PILL_OPTIONS}
                            onChange={onPillChange}
                        />
                    </ScrollRow>
                </Row>

                <Container>
                    {selectedPill === "Manage Sale" ? (
                        <Form>
                            <Form.Group>
                                <div className='half-md-card card mx-auto p-3 mt-0'>
                                    {/* <h4>Midfoot Project:<a href='/#/programs/midfoot-project'>Click here...</a></h4> */}
                                    {/* <br /> */}
                                    <Form.Label className='download-label'>
                                        Toggle Sale
                                    </Form.Label>
                                    <div className='d-flex-row-center'>
                                        <h5 className='mt-3'>OFF</h5>
                                        <Form.Check
                                            type="switch"
                                            id="custom-switch"
                                            checked={saleDetails?.active == 1 ? true : false}
                                            onChange={(e) => setSaleDetails({ ...saleDetails as ISale, active: e.target.checked ? 1 : 0 })}
                                            disabled={isLoadingSale}
                                        />
                                        <h5 className='mt-3'>ON</h5>
                                    </div>

                                    <Form.Label className='mt-3'>
                                        Headline
                                    </Form.Label>
                                    <Form.Control
                                        value={saleDetails?.headline}
                                        type="text"
                                        placeholder="Type headline here"
                                        onChange={(e) => setSaleDetails({ ...saleDetails as ISale, headline: e.target.value })}
                                        onBlur={() => handleUpdateSale("headline")}
                                        disabled={isLoadingSale}
                                    />

                                    <Form.Label className='mt-3'>
                                        Subtext
                                    </Form.Label>
                                    <Form.Control
                                        value={saleDetails?.subtext}
                                        type="text"
                                        as="textarea"
                                        rows={3}
                                        placeholder="Type subtext here"
                                        onChange={(e) => setSaleDetails({ ...saleDetails as ISale, subtext: e.target.value })}
                                        onBlur={() => handleUpdateSale("subtext")}
                                        disabled={isLoadingSale}
                                    />

                                    <Form.Label className='mt-3'>
                                        Banner
                                    </Form.Label>
                                    <Form.Control
                                        value={saleDetails?.banner}
                                        type="text"
                                        placeholder="Type banner here"
                                        onChange={(e) => setSaleDetails({ ...saleDetails as ISale, banner: e.target.value })}
                                        onBlur={() => handleUpdateSale("banner")}
                                        disabled={isLoadingSale}
                                    />

                                    <Form.Label className='mt-3'>
                                        Sale Ends
                                    </Form.Label>
                                    <DatePicker
                                        className='p-1 small-date-picker'
                                        selected={moment(saleDetails?.end).toDate()}
                                        onChange={(date: Date) => setSaleDetails({ ...saleDetails as ISale, end: date })}
                                        disabled={isLoadingSale}
                                    />


                                    {isLoadingSale ? (
                                        <div className='center-content'>
                                            <Spinner animation='border' />
                                        </div>
                                    ) : undefined}
                                </div>
                            </Form.Group>
                        </Form>
                    ) : selectedPill === "Onboarding Survey" ? (
                        <div>
                            {/* <Form>
                                <Form.Group>
                                    <div className='half-md-card card mx-auto p-3 mt-0'>
                                        <h4>New Accounts: <b>{surveyReport.totalUsers}</b></h4>
                                        <h4>New Subscriptions: <b>{surveyReport.allSubscriptions}</b></h4>
                                        <h4>Percentage: <b>{(surveyReport.allSubscriptions / surveyReport.totalUsers * 100).toPrecision(3)}%</b></h4>
                                        <hr />

                                        <h4>Started Survey: <b>{surveyReport.completedSurvey}</b></h4>
                                        <h4>Percentage: <b>{surveyReport.percentage}</b></h4>
                                        <p><b>{surveyReport.allSubsWithCompletedSurvey}</b> out of <b>{surveyReport.completedSurvey}</b> users who started the survey started a plan, with a total percentage of <b>{(surveyReport.allSubsWithCompletedSurvey / surveyReport.completedSurvey * 100).toPrecision(3)}%</b>.</p>
                                        <hr />
                                        <h4>Skipped Survey: <b>{surveyReport.notCompleted}</b></h4>
                                        <h4>Percentage: <b>{(surveyReport.notCompleted / surveyReport.totalUsers * 100).toPrecision(3)}%</b></h4>
                                        <p><b>{surveyReport.allSubsNoSurvey}</b> out of <b>{surveyReport.notCompleted}</b> users who did not complete the survey started a plan, with a total percentage of <b>{(surveyReport.allSubsNoSurvey / surveyReport.notCompleted * 100).toPrecision(3)}%</b>.</p>
                                        <hr />
                                        <h4>Incomplete surveys: <b>{surveyReport.userWithEmptySurvey + surveyReport.usersStoppedAtQ2 + surveyReport.usersStoppedAtQ3 + surveyReport.usersStoppedAtQ4}</b></h4>
                                        <h4>Percentage <b>{(surveyReport.userWithEmptySurvey + surveyReport.usersStoppedAtQ2 + surveyReport.usersStoppedAtQ3 + surveyReport.usersStoppedAtQ4 / surveyReport.completedSurvey * 100).toPrecision(3)}%</b></h4>
                                        <h4>Empty Surveys: <b>{surveyReport.userWithEmptySurvey}</b></h4>
                                        <h4>Users who stopped at:</h4>
                                        <h4>Q2 (Weekly Average): <b>{surveyReport.usersStoppedAtQ2
                                        }</b></h4>
                                        <h4>Q3 (Running Goal): <b>{surveyReport.usersStoppedAtQ3
                                        }</b></h4>
                                        <h4>Q4 (Struggle Areas): <b>{surveyReport.usersStoppedAtQ4
                                        }</b></h4>
                                        <hr />
                                        <Form.Label className='mt-n1'>
                                            <h5>Change Suggested Programs Days Interval</h5>
                                        </Form.Label>
                                        <Form.Control
                                            value={dayIntervalValue}
                                            type="number"
                                            placeholder="Type Days Interval here..."
                                            disabled={isLoadingSale}
                                            onChange={(e) => setDayIntervalValue(e.target.value as unknown as number)}
                                        />
                                        <Button disabled={IsLoading} type='button' className='bg-primary border-0 mt-3' onClick={() => updateDaysInterval(dayIntervalValue)}>Save</Button>
                                        {IsLoading ? (
                                            <div className='center-content text-center'>
                                                <h4>Loading, please wait...</h4>
                                                <Spinner animation='border' />
                                            </div>
                                        ) : undefined}
                                    </div>
                                </Form.Group>
                            </Form> */}
                        </div>
                    ) :
                        selectedPill === "Midfoot Project" ? (
                            <div style={{ maxHeight: '100vh' }} className='md-card card mx-auto p-3 mt-0'>
                                <Row>
                                    <Col>
                                        Total active users: {midfootUsers?.length}
                                    </Col>
                                    <Col>
                                        {midfootUsers && midfootUsers.length > 0 ? (<ExcelExporter apiData={midfootUsers} filename={`midfoot-active-users_${moment(new Date()).toISOString()}`} />) : undefined}
                                    </Col>
                                </Row>
                                <Table responsive striped bordered hover size="sm">
                                    <thead>
                                        <tr>
                                            <html>#</html>
                                            <th>User ID</th>
                                            <th>First Name</th>
                                            <th>Last Name</th>
                                            <th>Email</th>
                                            <th>Date Started</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {midfootUsers?.map((data, index) => (
                                            <tr key={index}>
                                                <td>{index + 1}</td>
                                                <td>{data.user_id}</td>
                                                <td style={{ minWidth: '160px' }}>{data.first_name}</td>
                                                <td style={{ minWidth: '160px' }}>{data.last_name}</td>
                                                <td style={{ minWidth: '200px' }}>{data.email}</td>
                                                <td style={{ minWidth: '130px' }}>{moment(data.date_started.split('T')[0]).format('MMMM D, YYYY')}</td>
                                            </tr>
                                        ))}

                                    </tbody>
                                </Table>
                            </div>
                        ) :
                            selectedPill === "Styles" ?
                                <AdminUI />
                                : selectedPill === "OpenAI Embeddings" ? <Embeddings />
                                    : selectedPill === "OpenAI Chat - Single" ? <ChatCompletionSingle userInfo={userInfo} /> : selectedPill === "OpenAI Chat - Steps" ? <ChatCompletionStep userInfo={userInfo} /> : selectedPill === "Training Plans" ? <TrainingPlanManager /> : undefined
                    }
                </Container>
            </BodyComponent>
        </AppMenus>
    )
}

export default WithAuth(AdminPage)
