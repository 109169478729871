import { date, strtotime } from 'locutus/php/datetime'
import React, { useEffect, useState } from 'react'
import { Col, Row } from 'react-bootstrap'
import useTrainingPlanUtils from '../../../hooks/useTrainingPlanUtils'
import useBuildTrainingPlan from '../hooks/useBuildTrainingPlan'

const GoalRaces = (props: any) => {

    const { getRaceGoalsWeek, getRaceGoalsValues, distanceMarkerGoals } = useBuildTrainingPlan()
    const { DaysBetween } = useTrainingPlanUtils()
    const { userInfo } = props;

    const [cRaceData, setCRaceData] = useState<any>()
    const [bRaceData, setBRaceData] = useState<any>()
    const [ARaceData, setARaceData] = useState<any>()

    const getGoalRaces = async (abort: AbortSignal) => {
        const { type, weeks, distance, monday_start, start, km, race_date } = userInfo
        const isKm = km === 'Y' ? true : false;
        if (type == 0 || type == 1) {
            const goalWeeks = getRaceGoalsWeek(type, weeks, distance)
            await getRaceGoalsValues(goalWeeks.c_week as number, distance, type, weeks, start, isKm, monday_start, abort, race_date).then(res => setCRaceData(res))

            await getRaceGoalsValues(goalWeeks.b_week as number, distance, type, weeks, start, isKm, monday_start, abort, race_date).then(res => setBRaceData(res))
        }

        let aDistance;
        const aDate = date("m/d/Y", strtotime(race_date))
        let aWeeks;

        switch (type) {
            case 0:
                aDistance = distanceMarkerGoals("13.1", isKm);
                break;
            case 1:
                aDistance = distanceMarkerGoals("26.2", isKm);
                break;
            case 2:
                aDistance = distanceMarkerGoals("3.1", isKm);
                break;
            case 3:
                aDistance = distanceMarkerGoals("6.2", isKm);
                break;
            default:
                break;
        }

        aWeeks = Math.ceil(DaysBetween(strtotime(race_date)) / 7);
        if (aWeeks >= 0) {
            aWeeks = 0;
        } else {
            aWeeks = -aWeeks;
        }
        setARaceData({ aDistance, aDate, aWeeks })
    }

    useEffect(() => {
        const abortController = new AbortController();

        if (userInfo)
            getGoalRaces(abortController.signal)

        return () => {
            abortController.abort();
        }
    }, [userInfo])

    return (
        <>
            <div className={`build-plan ${props.mainClass}`} >
                <h4 className="text-uppercase font-weight-semibold my-4">
                    GOAL RACES
                </h4>
                <Row>
                    <Col xl={4} md={6} xs={4}>
                        <div className="bg-white radius-5">
                            <div className="card-header bg-primary">
                                <h6 className="font-15 text-uppercase m-0 text-center font-weight-semibold">
                                    C RACE
                                </h6>
                            </div>
                            <ul className="list-unstyled d-flex justify-content-between px-3">
                                <li className="mx-2 text-center">
                                    <span className="font-12 text-uppercase">Date</span>
                                    <br />
                                    <span className="font-12 text-uppercase">{cRaceData?.goalDate}</span>
                                </li>
                                <li className="mx-2 text-center">
                                    <span className="font-12 text-uppercase">
                                        DISTANCE
                                    </span>
                                    <br />
                                    <span className="font-12 text-uppercase">{cRaceData?.goalDistance}</span>
                                </li>
                                <li className="mx-2 text-center">
                                    <span className="font-12 text-uppercase">
                                        UNTIL
                                    </span>
                                    <br />
                                    <span className="font-12 text-uppercase">{cRaceData && cRaceData?.goalWeeks !== "" ? cRaceData?.goalWeeks + ' weeks' : ''}</span>
                                </li>
                            </ul>
                        </div>
                    </Col>
                    <Col xl={4} md={6} xs={4}>
                        <div className="bg-white radius-5">
                            <div className="card-header bg-primary">
                                <h6 className="font-15 text-uppercase m-0 text-center font-weight-semibold">
                                    B RACE
                                </h6>
                            </div>
                            <ul className="list-unstyled d-flex justify-content-between px-3">
                                <li className="mx-2 text-center">
                                    <span className="font-12 text-uppercase">Date</span>
                                    <br />
                                    <span className="font-12 text-uppercase">{bRaceData?.goalDate}</span>
                                </li>
                                <li className="mx-2 text-center">
                                    <span className="font-12 text-uppercase">
                                        DISTANCE
                                    </span>
                                    <br />
                                    <span className="font-12 text-uppercase">{bRaceData?.goalDistance}</span>
                                </li>
                                <li className="mx-2 text-center">
                                    <span className="font-12 text-uppercase">
                                        UNTIL
                                    </span>
                                    <br />
                                    <span className="font-12 text-uppercase">{bRaceData && bRaceData?.goalWeeks !== "" ? bRaceData?.goalWeeks + ' weeks' : ''}</span>
                                </li>
                            </ul>
                        </div>
                    </Col>
                    <Col xl={4} md={6} xs={4}>
                        <div className="bg-white radius-5">
                            <div className="card-header bg-primary">
                                <h6 className="font-15 text-uppercase m-0 text-center font-weight-semibold">
                                    A RACE
                                </h6>
                            </div>
                            <ul className="list-unstyled d-flex justify-content-between px-3">
                                <li className="mx-2 text-center">
                                    <span className="font-12 text-uppercase">Date</span>
                                    <br />
                                    <span className="font-12 text-uppercase">{ARaceData?.aDate}</span>
                                </li>
                                <li className="mx-2 text-center">
                                    <span className="font-12 text-uppercase">
                                        DISTANCE
                                    </span>
                                    <br />
                                    <span className="font-12 text-uppercase">{ARaceData?.aDistance}</span>
                                </li>
                                <li className="mx-2 text-center">
                                    <span className="font-12 text-uppercase">
                                        UNTIL
                                    </span>
                                    <br />
                                    <span className="font-12 text-uppercase">{ARaceData?.aWeeks ? ARaceData?.aWeeks + ' weeks' : ''}</span>
                                </li>
                            </ul>
                        </div>
                    </Col>
                </Row>
            </div >
        </>
    )
}

export default GoalRaces