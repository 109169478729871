/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useEffect, useState } from 'react'
import { Col, Row } from 'react-bootstrap'
import useVideoProgress from '../../hooks/useVideoProgress'
import { Video } from '../../modules/videos/allVideos'
import useFavorites from '../../pages/favorites/useFavorites'
import ProgramMobileView from '../../pages/programs/v2/ProgramMobileView'
import recoverVideos from '../../pages/recover/DailyRecoveryVideos.json'
import MainCard from '../cards/MainCard'

const DEFAULT_VIDEO: Video = {
    id: String(825035143),
    url: '/#/programs/runsmart-recover?videoId=' + 825035143,
    image: '\\img\\recover.jpg',
    category: 'Recover',
    headingTitle: '',
    headingSubtitle: 'Recovery Basics',
    detailsLine1: '',
    detailsLine2: '',
    headlines: '' || [],
    descriptions: [] || []
}

const MobilityVideos = (props: any) => {
    const [currentVideoId, setCurrentVideoId] = useState("")
    const [recoveryVideos, setRecoveryVideos] = useState<Video[]>([])
    const [currentWorkoutVideo, setCurrentWorkoutVideo] = useState<Video>()
    const [isPlaying, setIsPlaying] = useState(false)
    const [isFavorite, setIsFavorite] = useState(false)
    const { userInfo, videoIds } = props
    const [isLoadingMark, setIsLoadingMark] = useState(false)

    const [vimeoData, setVimeoData] = useState<any>()

    const info = useVideoProgress({ videos: recoveryVideos as Video[], userInfo, autoReload: true, realoadTriggeer: currentVideoId })

    const { favorites, toggleFavorite, isLoading, getFavorites, getIsFavorited } = useFavorites(userInfo?.account_id as number, undefined, 'others', true)

    const getVideoDuration = async (videoId: string) => {

        const response = await fetch(
            `https://vimeo.com/api/oembed.json?url=https://player.vimeo.com/video/${videoId}`
        );
        const data = await response.json();
        if (data) {
            const duration = Math.round(data?.duration / 60)
            setVimeoData(`${duration} ${duration <= 1 ? 'minute' : 'minutes'}`)
        }
    }

    const onLike = async (videoId: string) => {
        const isLiked = favorites && favorites.find((x: any) => x.id === videoId) ? true : false;
        await toggleFavorite(userInfo?.account_id as number, Number(videoId), !isLiked)
    }

    const getVideoDetails = (vidId?: string) => {
        const mobilities = recoverVideos.find(x => x.type === "mobility")
        if (mobilities) {
            const video = mobilities.videos.find(x => x.vimeo_id === vidId as string)

            return { id: video?.id, videoId: video?.vimeo_id, title: video?.corrective }
        }
        else {
            return undefined
        }

    }

    const handleSetCurrentVideoId = (videoId: any) => {
        setCurrentWorkoutVideo(assignVideo(videoId))
    }

    const assignVideo = (videoId: string) => {
        const video: Video = {
            id: videoId,
            url: '/#/programs/runsmart-recover?videoId=' + videoId,
            // image: '\\img\\recover.jpg',
            image: `https://vumbnail.com/${videoId}.jpg`,
            category: 'Recover',
            headingTitle: getVideoDetails(videoId)?.title as string,
            headingSubtitle: '',
            detailsLine1: '',
            detailsLine2: '',
            headlines: '' || [],
            descriptions: [] || []
        }

        return video
    }

    const handleOnPlay = async () => {

        // localStorage.setItem('playHistory', JSON.stringify(playHistory))
        // localStorage.setItem('previousActivityStatus', 'unparsed')

        setIsPlaying(true)

    }

    const handleMarkAsFavorite = async (isChecked: boolean) => {
        if (currentWorkoutVideo) {
            setIsLoadingMark(true)
            await toggleFavorite(userInfo?.account_id as number, Number(currentWorkoutVideo.id), isChecked)
            await getFavorites()
            setIsLoadingMark(false)
        }
    }
    useEffect(() => {
        setIsPlaying(false)
    }, [currentWorkoutVideo])

    useEffect(() => {
        if (videoIds) {
            const vids = videoIds.split(',')
            const videos: Video[] = []
            vids.forEach((id: any) => {
                videos.push(assignVideo(id))
            });

            setRecoveryVideos(videos)
            setCurrentWorkoutVideo(videos[0])
        }
    }, [videoIds])

    return (
        <div>
            <Row className='mb-5'>
                <Col>
                    <Row className="get-started-training-plan justify-content-center mb-md-2 mb-3">
                        <ProgramMobileView
                            onLike={onLike}
                            programType={'Recover'}
                            title={currentWorkoutVideo?.headingTitle || ''}
                            currentVideo={currentWorkoutVideo as Video}
                            handleMarkAsFavorite={handleMarkAsFavorite}
                            handleOnPlay={handleOnPlay}
                            isPlaying={isPlaying}
                            isFavorite={isFavorite}
                            isLoadingMark={isLoadingMark}
                            subTitle={currentWorkoutVideo?.detailsLine1 as string}
                            currentWorkoutVideoProgressInfo={info?.find(x => x.videoId == Number(currentWorkoutVideo?.id))}
                            duration={vimeoData}
                        />
                    </Row>
                </Col>
                {
                    recoveryVideos.map((video) => (
                        <div onClick={() => handleSetCurrentVideoId(video.id)} key={video.id} className="justify-content-center phone-video-list mb-2 mt-n2">
                            <MainCard
                                videoId={video.id}
                                key={video.id}
                                cardType="video"
                                // type="videoLgStretch"
                                hasCheck={true}
                                progress={info?.find(x => x.videoId == Number(video.id))?.progress}
                                isComplete={info && info.length >= 0 && info?.find(x => x?.videoId == Number(video.id))?.progress as number >= 80}
                                logoImage={`https://vumbnail.com/${video.id}.jpg`}
                                bgImage={`https://vumbnail.com/${video.id}.jpg`}
                                // logoImage={DEFAULT_VIDEO.image}
                                // bgImage={DEFAULT_VIDEO.image}
                                detailTitle={''}
                                detailSubtitle={video.headingTitle}
                                // link={getVideoLink(video.vimeo_id)}
                                hasLikeButton={true}
                                isProgram={false}
                                onLike={() => onLike(video.id)}
                                isSelected={video.id == currentWorkoutVideo?.id}
                                favorites={favorites}
                            />
                        </div>
                    ))
                }
            </Row>
        </div>
    )
}

export default MobilityVideos