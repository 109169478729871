import WithAuth from '../../components/WithAuth'
import AppMenus from '../../components/layout/AppMenus'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import MainCard from '../../components/cards/MainCard'
import useJoinVideo from '../../hooks/useJoinVideo'
import { GET_STARTED_IMG } from '../../modules/values'
import { AuthPageProps } from '../../modules/types'
import { FC, useState } from 'react'
import { Container, Button, Accordion } from 'react-bootstrap'
import { useNavigate } from 'react-router-dom'
// import BackArrow from '../../../public/images/icons/left-icon.svg'
/**
 * JoinVideo Component
 */

const GetStartedTrainingPlan: FC<AuthPageProps> = ({ userInfo }) => {
	// Hooks
	const { video } = useJoinVideo({ userInfo, isGetStarted: true })
	const [isPlaying, setIsPlaying] = useState(false)

	// TO-DO: On Like
	const onLike = () => 0

	const navigate = useNavigate()

	// Render
	return (
		<AppMenus hideTitleDesktop>
			<div className="get-started-training-plan mt-n4">

				<Row className="justify-content-center mb-md-5 mb-3">
					<Col className="px-0 px-sm-3">
						<div className="video-wrapper blue-bg">
							<MainCard
								hasPlayButton
								playButtonIsCentered
								type="responsiveCardLg"
								bgImage={GET_STARTED_IMG}
								vimeoId={video?.id}
								onLike={onLike}
								onPlay={() => setIsPlaying(true)}
							/>
							{/* <div className="banner-top-icon fav-icon">
								<input type="checkbox" id="heart" hidden />
								<label htmlFor="heart">
									<div className="animation">
										<span className="dot-1"></span>
										<span className="dot-2"></span>
										<span className="dot-3"></span>
										<span className="dot-4"></span>
										<span className="dot-5"></span>
										<span className="dot-6"></span>
										<span className="dot-7"></span>
										<span className="dot-8"></span>
									</div>
									<img
										className="fill pointer"
										src="./images/icons/heart-green-big.png"
										alt="Like"
									/>
									<img
										className="without-fill pointer"
										src="./images/icons/heart-white-big-unfill.png"
										alt="Like"
									/>
								</label>
							</div> */}
							{!isPlaying ? (
								<div className="banner-info">
									<h3 className="text-white text-capitalize">Get Started</h3>
									<h4 className="text-white letter-space">
										GET STARTED ON YOUR TRAINING PLAN
									</h4>
								</div>
							) : null}
						</div>
					</Col>
				</Row>

				{/* Title Desktop */}
				<Row className="text-center mb-xl-5 mb-2">
					<Col>
						<div className="d-none d-md-block">
							{/* <svg width="700pt" height="550pt" version="1.1" viewBox="0 0 700 550" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
								<g>
									<path d="m423.36 77.895-202.16 202.11 202.16 202.11 55.441-55.441-146.72-146.66 146.72-146.66z" />
								</g>
							</svg> */}
							{/* <h2 className="font-80 d-none d-md-block">GET STARTED</h2> */}
							<h1 className="page-title">GET STARTED</h1>
						</div>
					</Col>
					<div className="text-center px-4">
						<Button
							// as="a"
							onClick={() => navigate('/training-plan/view', { replace: true })}
							size="lg"
							variant="dark"
							className="rounded-pill ls-0 color-primary-bg next-btn mt-2 d-md-none"
						>
							Take Me To My Plan
						</Button>
					</div>
				</Row>
				<div className="regular-container">
					<Container className="px-sm-5">
						<Row>
							<Col lg={6}>
								<div className="bg-white p-4 p-md-5 radius-15 mt-4">
									<div className="py-sm-2">
										<p className="font-18 font-md-15 traning-plan--text">
											Your customized training plan awaits. Before you jump into
											the mix, take a look at the video above and learn how to
											navigate the dashboard.
										</p>
										<p className="font-18 font-md-15 traning-plan--text">
											Each run will be planned for you, from distance, to pace,
											to speed. You can edit as you go and export the training
											plan after race day.
										</p>
										<div className="text-center">
											<Button
												// as="a"
												onClick={() => navigate('/training-plan/view', { replace: true })}
												size="lg"
												variant="dark"
												className="rounded-pill color-primary-bg next-btn mt-2 d-none d-md-block mx-md-auto"
											>
												Take Me To My Plan
											</Button>

										</div>
									</div>
								</div>
							</Col>
							<Col lg={6}>
								<h4 className="font-weight-bold mt-5 mt-lg-0">FAQ</h4>
								<Accordion defaultActiveKey="0">
									<Accordion.Item eventKey="0">
										<Accordion.Header>
											Can I download my training plan to my computer or device?
											<div className="man-run-icon">
												<img src="./images/icons/runsmart-guy.svg" alt="Man" />
											</div>
										</Accordion.Header>
										<Accordion.Body className="traning-plan--text">
											Your training plan will not be available for download
											until after race day. Once your chosen race day has
											passed, you will be able to download in a .pdf file of
											your plan, including all heart rate data and stored notes.
										</Accordion.Body>
									</Accordion.Item>
									<Accordion.Item eventKey="1">
										<Accordion.Header>
											Can I make more than one training plan for my account?
											<div className="man-run-icon">
												<img src="./images/icons/runsmart-guy.svg" alt="Man" />
											</div>
										</Accordion.Header>
										<Accordion.Body className="traning-plan--text">
											No. Currently only one training plan is available per user
											account.
										</Accordion.Body>
									</Accordion.Item>
									<Accordion.Item eventKey="2">
										<Accordion.Header>
											Will I lose all my stored information if I create another
											training plan before finishing my current plan?
											<div className="man-run-icon">
												<img src="./images/icons/runsmart-guy.svg" alt="Man" />
											</div>
										</Accordion.Header>
										<Accordion.Body className="traning-plan--text">
											Yes. If you create a second plan prior to finishing your
											current training plan all data will be lost. Your training
											plan will be available for download after race day. We
											cannot recover your data once you build a new plan.
										</Accordion.Body>
									</Accordion.Item>
									<Accordion.Item eventKey="3">
										<Accordion.Header>
											Is there a charge for this service?
											<div className="man-run-icon">
												<img src="./images/icons/runsmart-guy.svg" alt="Man" />
											</div>
										</Accordion.Header>
										<Accordion.Body className="traning-plan--text">
											No. There are two levels of access to this training plan.
											Free accounts will have restricted access to all track
											workouts, some pacing data, and allmaintenance work
											(strength workouts, stretches, etc). Membership to
											RunSmart is $15/month and includes unrestricted access to
											your training plan, access to OnDemand strength workouts
											and run form training, plus much more.
										</Accordion.Body>
									</Accordion.Item>
									<Accordion.Item eventKey="4">
										<Accordion.Header>
											Can I change my plan from miles to kilometers?
											<div className="man-run-icon">
												<img src="./images/icons/runsmart-guy.svg" alt="Man" />
											</div>
										</Accordion.Header>
										<Accordion.Body className="traning-plan--text">
											YES! We have recently launched a kilometer option.
										</Accordion.Body>
									</Accordion.Item>
								</Accordion>
							</Col>
						</Row>
					</Container>
				</div>
			</div>
		</AppMenus>
	)
}

export default WithAuth(GetStartedTrainingPlan)
