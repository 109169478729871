import { FC } from "react"

const format = (seconds: number) => {
    const date = new Date(seconds * 1000)
    const hh = date.getUTCHours()
    const mm = date.getUTCMinutes()
    const ss = pad(date.getUTCSeconds().toString())
    if (hh) {
        return `${hh}:${pad(mm.toString())}:${ss}`
    }
    return `${mm}:${ss}`
}

const pad = (string: string) => {
    return ('0' + string).slice(-2)
}

const Duration: FC<{ cl: string, seconds: number }> = ({ cl, seconds }) => {
    return (
        <time dateTime={`P${Math.round(seconds)}S`} className={cl}>
            {format(seconds)}
        </time>
    )
}



export default Duration