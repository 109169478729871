import React from "react";
import { signOut } from "../../modules/accountActions";
import SessionInactive from "./SessionInactive";
import { navReq } from "../../modules/apiConsume";
import ReturnPage from "../../pages/ReturnPage";

class SessionCheck extends React.Component {

    state = {
        initial: true,
        hasError: false
    }

    static getDerivedStateFromError(error) {
        console.log(error)
        return { hasError: true }

    }

    componentDidCatch(error, info) {
        console.log(error, info)
    }

    invalidSession() {
        signOut()
        return <SessionInactive refresh={false} /> || this.props.fallback || <h1>User session expired.</h1>
    }

    render() {
        if (this.state.hasError) {
            if (this.props.main) {
                this.invalidSession()
                return <>
                    <ReturnPage />
                    <h1>Happy Running!</h1>
                </>
            }
            this.props.setInvalidScreen(true)
            if (this.state.initial) {
                if (this.props.return) {
                    this.invalidSession()
                    this.setState({ initial: false })
                    return <p>Invalid Session</p>
                }
                try {
                    navReq('/#/')
                    window.location.reload()
                    // window.location.assign('/#/')
                    return <SessionInactive refresh={true} />
                } catch (error) {
                    this.invalidSession()
                }
            }
            else {
                this.invalidSession()
            }
            return <div style={{ width: '100vw', justifyContent: 'center', alignItems: 'center', textAlign: 'center', marginTop: '2rem' }}>
                <h4>Welcome Back</h4>
            </div>
        }

        return this.props.children

    }

}

export default SessionCheck