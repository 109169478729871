import useLocalStorage from '../hooks/useLocalStorage'
import useQueryParams from '../hooks/useQueryParams'
import { useEffect } from 'react'
import { APP_STORAGE_KEY } from '../modules/values'

/**
 * useAppStorage Hook
 */

const useAppStorage = (): {
	isMobile?: boolean
	isIos?: boolean
	isAndroid?: boolean
} => {
	// Params
	const { app } = useQueryParams()
	const isMobileDevice = ['ios', 'android'].includes((app || '').toLowerCase())

	// Hooks
	const { storedValue, setValue } = useLocalStorage({ key: APP_STORAGE_KEY })

	// App Change Effect
	useEffect(() => {
		if (app) {
			setValue({
				isMobile: isMobileDevice,
				isIos: (app || '').toLowerCase() == 'ios',
				isAndroid: (app || '').toLowerCase() == 'android',
			})
		}
	}, [app])

	// Return
	return storedValue
}

export default useAppStorage
