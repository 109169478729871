import {
	programFeatures,
	programCovers,
	programLogos,
	altProgramLogos,
} from './programs'
import { webinarCovers, webinarLogos, webinarDownloads } from './webinars'
import { guideFeatures, guideDownloads } from './guides'
import {
	AllRSCategories,
	AllRSCategoriesPartial,
	RSCategoryImages,
} from '../types'

// Get All Categories
const getAllCategories = () => {
	const allCategories: AllRSCategoriesPartial = {
		programs: {},
		webinars: {},
		guides: {
			'5K 10K': {},
			'Half Marathon': {},
			Marathon: {},
			Training: {},
			Strength: {},
		},
	}
	const defaultCategory: RSCategoryImages = {
		cover: undefined,
		feature: undefined,
		mainLogo: undefined,
		secondaryLogo: undefined,
	}

	// Iterate Program Features
	for (const key in programFeatures) {
		const programKey = key as keyof AllRSCategories['programs']
		const image = programFeatures[programKey]
		allCategories.programs[programKey] = {
			...defaultCategory,
			feature: image,
		}
	}

	// Iterate Program Covers
	for (const key in programCovers) {
		const programKey = key as keyof AllRSCategories['programs']
		const image = programCovers[programKey]
			; (allCategories as AllRSCategories).programs[programKey].cover = image
	}

	// Iterate Program Logos
	for (const key in programLogos) {
		const programKey = key as keyof AllRSCategories['programs']
		const image = programLogos[programKey]
			; (allCategories as AllRSCategories).programs[programKey].mainLogo = image
	}

	// Iterate Alt Program Logos
	for (const key in altProgramLogos) {
		const programKey = key as keyof AllRSCategories['programs']
		const image = altProgramLogos[programKey]
			; (allCategories as AllRSCategories).programs[programKey].secondaryLogo =
				image
	}

	// Iterate Webinar Covers
	for (const key in webinarCovers) {
		const webinarKey = key as keyof AllRSCategories['webinars']
		const image = webinarCovers[webinarKey]
		allCategories.webinars[webinarKey] = {
			...defaultCategory,
			cover: image,
		}
	}

	// Iterate Webinar Logos
	for (const key in webinarLogos) {
		const webinarKey = key as keyof AllRSCategories['webinars']
		const image = webinarLogos[webinarKey]
			; (allCategories as AllRSCategories).webinars[webinarKey].mainLogo = image
	}

	// Iterate Webinar Downloads
	for (const key in webinarDownloads) {
		const webinarKey = key as keyof AllRSCategories['webinars']
		const image = webinarDownloads[webinarKey]
			; (allCategories as AllRSCategories).webinars[webinarKey].secondaryLogo =
				image
	}

	// Iterate Guide Features
	for (const key in guideFeatures) {
		const guideKey = key as keyof typeof guideFeatures
		const image = guideFeatures[guideKey]
		if (allCategories.guides?.[guideKey]) {
			; (allCategories as AllRSCategories).guides[guideKey].feature = image
		}
	}

	// Iterate Guide Downloads
	for (const key in guideDownloads) {
		const guideKey = key as keyof typeof guideDownloads
		const image = guideDownloads[guideKey]
		if (allCategories.guides?.[guideKey]) {
			; (allCategories as AllRSCategories).guides[guideKey].mainLogo = image
		}
	}

	return allCategories as AllRSCategories
}

// All Categories
const allCategories = getAllCategories()

// Export
export default allCategories
