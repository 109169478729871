import {FC, useState, useEffect} from 'react'

import Grid from '@mui/material/Grid'
import Box from '@mui/material/Box'

import RSButton from '../../components/buttons/RSButton'
import { AccountProfile } from '../../api/v2/account/Account.types'

export type AverageRunProps = {
	data: AccountProfile,
	handleUpdateProfile: Function,
}

const AverageRun: FC<AverageRunProps> = ({data, handleUpdateProfile}) => {
	const {running_activity, measurement_system} = data
	const [runningActivity, setRunningActivity] = useState<any>(running_activity || ''  );
	const isMetric = measurement_system === 'metric'
	const unit = isMetric ? 'km' : 'mi'

	const activities = [
		'No running',
		`Less than 10 ${unit}`,
		`10 to 20 ${unit}`,
		`21 to 30 ${unit}`,
		`31 to 40 ${unit}`,
		`41 to 50 ${unit}`,
		`More than 50 ${unit}`
	]

	const replaceMetric = (str: string | undefined) => {
		return str?.replace(isMetric? 'mi' : 'km', isMetric? 'km' : 'mi')
	}

	const handleSave = async () => {
		handleUpdateProfile({ running_activity: runningActivity })
	}

	useEffect(()=>{
		setRunningActivity(running_activity)
	},[running_activity])
	return (
		<Box>
			<Box sx={{fontSize: '16px', fontFamily: 'Poppins-Medium', pb: 1}}>In the last four weeks, I average...</Box>
			<Grid container xs={12} justifyContent='space-between'>
				{activities.map((activity: any, ai: number)=>{
					const isActive = activity === replaceMetric(runningActivity)
					return <Grid item container xs={5.6} key={'activity-'+ai} sx={{minHeight: '80px', width: '100px', mb: '15px'}} justifyContent='center' alignItems='center'>
						<RSButton card secondary={!isActive} sx={{height: '100%', flexDirection: 'column'}} onClick={()=>setRunningActivity(activity)}>
							<Box sx={{fontFamily: 'Poppins-Medium !important', fontSize: '14px !important', textAlign: 'center', lineHeight: '1em', pb: '5px'}}>{activity}</Box>
							{ai>0 && <Box sx={{fontFamily: 'Poppins-light !important', fontSize: '10px !important', lineHeight: '1.2em', textAlign: 'center'}}>per week</Box>}
						</RSButton>
					</Grid>
				})}
			</Grid>
			<RSButton onClick={handleSave} disabled={!runningActivity || runningActivity?.toLowerCase() === replaceMetric(running_activity)?.toLowerCase() } sx={{mt: 1}}>Save</RSButton>
		</Box>
	)
}

export default AverageRun;
