import { Video } from "../modules/videos/allVideos"
import { StoreSlice } from "./types"

export interface ITimeValue {
    duration: number,
    currentSec: number,
    percent: number
}

export interface IVimeoPlayerStore {
    isPlaying: boolean,
    setIsPlaying: (isPlaying: boolean) => void
    showVimeoPlayer: boolean,
    setShowVimeoPlayer: (showVimeoPlayer: boolean) => void
    vimeoId: string | undefined
    setVimeoId: (vimeoId: string | undefined) => void
    isFullScreen: boolean,
    setIsFullScreen: (isFullScreen: boolean) => void,
    currentAction: string | undefined,
    setCurrentAction: (currentAction: string | undefined) => void,
    currentTitle: string | undefined,
    setCurrentTitle: (currentTitle: string | undefined) => void,
    isShowControls: boolean,
    setIsShowControls: (isShowControls: boolean) => void,
    currentList: Video[],
    setCurrentList: (currentList: Video[]) => void,
    currentPercentage: number,
    setCurrentPercentage: (currentPercentage: number) => void,
    currentTimeValue: ITimeValue,
    setCurrentTimeValue: (currentTimeValue: ITimeValue) => void,
    currentTab: number,
    setCurrentTab: (currentTab: number) => void,
    currentProgramId: number,
    setCurrentProgramId: (currentProgramId: number) => void,
}

const createVimeoPlayerStore: StoreSlice<IVimeoPlayerStore> = (set) => ({
    isPlaying: false,
    setIsPlaying: (isPlaying: boolean) => set(() => ({ isPlaying: isPlaying })),
    showVimeoPlayer: false,
    setShowVimeoPlayer: (showVimeoPlayer: boolean) => set(() => ({ showVimeoPlayer: showVimeoPlayer })),
    vimeoId: undefined,
    setVimeoId: (vimeoId: string | undefined) => set(() => ({ vimeoId: vimeoId })),
    isFullScreen: false,
    setIsFullScreen: (isFullScreen: boolean) => set(() => ({ isFullScreen: isFullScreen })),
    currentAction: undefined,
    setCurrentAction: (currentAction: string | undefined) => set(() => ({ currentAction: currentAction })),
    currentTitle: undefined,
    setCurrentTitle: (currentTitle: string | undefined) => set(() => ({ currentTitle: currentTitle })),
    isShowControls: false,
    setIsShowControls: (isShowControls: boolean) => set(() => ({ isShowControls: isShowControls })),
    currentList: [],
    setCurrentList: (currentList: Video[]) => set(() => ({ currentList: currentList })),
    currentPercentage: 0,
    setCurrentPercentage: (currentPercentage: number) => set(() => ({ currentPercentage: currentPercentage })),
    currentTimeValue: { duration: 0, currentSec: 0, percent: 0 },
    setCurrentTimeValue: (currentTimeValue: ITimeValue) => set(() => ({ currentTimeValue: currentTimeValue })),
    currentTab: 0,
    setCurrentTab: (currentTab: number) => set(() => ({ currentTab: currentTab })),
    currentProgramId: 0,
    setCurrentProgramId: (currentProgramId: number) => set(() => ({ currentProgramId: currentProgramId })),
})

export default createVimeoPlayerStore