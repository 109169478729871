import allVideos, { Video } from '../modules/videos/allVideos'
import { DEFAULT_PROGRESS } from './useProgramPage'
import { getProgressData } from './useVideoProgress'
import { AuthPageProps } from '../modules/types'
import { strengthGuide, trainingGuide } from '../modules/videos/videoOrders'
import { useState, useEffect } from 'react'
import { guideHeadline } from '../modules/values'

/**
 * useGuidePage Hook
 */

const useGuidePage = ({
	guideType,
	videoId,
	userInfo,
}: {
	guideType: 'Training' | 'Strength' | undefined
	videoId?: number | string
	userInfo?: AuthPageProps['userInfo']
}) => {
	// Get Info
	const getInfo = () => {
		let currentVideo: Video | undefined = undefined
		const videos: Video[] = []
		const title = `${guideType} Guide`
		const order = guideType == 'Training' ? trainingGuide : guideType === "Strength" ? strengthGuide : undefined
		if (order)
			for (const id of order) {
				const videoInfo = allVideos[String(id)]
				if (videoInfo) {
					videos.push(videoInfo)
					if (videoInfo.id == String(videoId)) {
						currentVideo = videoInfo
					}
				}
			}

		if (!currentVideo) currentVideo = videos[0]

		// Return
		return {
			title,
			headline: guideHeadline,
			videos,
			currentVideo,
			progressInfo: DEFAULT_PROGRESS,
		}
	}

	// State
	const [info, setInfo] = useState(getInfo())
	const [data, setData] = useState(undefined as any)

	// Get Progress Info
	const getProgressInfo = async (newInfo: ReturnType<typeof getInfo>) => {
		const { newData: progressData, newInfo: progressInfo } =
			await getProgressData([newInfo.currentVideo], userInfo, data)
		setData(progressData)
		setInfo({
			...newInfo,
			progressInfo: progressInfo?.[0] || { ...DEFAULT_PROGRESS },
		})
	}

	// Change Effect
	useEffect(() => {
		const newInfo = getInfo()
		getProgressInfo(newInfo)
	}, [userInfo, guideType, videoId])

	return info
}

export default useGuidePage
