import MultiLineText from '../../text/MultiLineText'
import { FC } from 'react'

// Line Props
export type LineProps = { title: string; text: string, smallTitle?: boolean }

/**
 * Line Component
 */

const Line: FC<LineProps> = ({ title, text, smallTitle }) => (
	<>
		<div className={`${smallTitle ? 'mb-n2' : 'mb-0'}`}>
			{smallTitle ? (
				<h4>{title}</h4>
			) : <h3>{title}</h3>
			}
		</div>
		<div className={`${smallTitle ? 'mb-n2' : 'mb-4'}`}>
			<MultiLineText text={text} />
		</div>
	</>
)

export default Line
