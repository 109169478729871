/* eslint-disable react/no-unknown-property */
import useAppStorage from '../../hooks/useAppStorage'
import classNames from 'classnames'
import { FC, useRef, useState, useEffect } from 'react'

// ChromeCastButtons Props
export type ChromeCastButtonsProps = {
	vimeoId?: number | string
	showVimeoPlayer?: boolean
	onClick?: () => any
	isPlaying?: boolean
}

/**
 * ChromeCastButtons Component
 */

const ChromeCastButtons: FC<ChromeCastButtonsProps> = ({
	vimeoId,
	showVimeoPlayer,
	onClick,
	isPlaying
}) => {

	// console.log(isPlaying)
	// State
	const [isActive, setIsActive] = useState(false)
	const [isAvailable, setIsAvailable] = useState(false)
	const [isMobileCast, setIsMobileCast] = useState(false)

	// Hooks
	const { isMobile } = useAppStorage()

	// Properties
	const desktopClass = classNames(
		'cc-desktop cc-btn cc-link',
		isMobileCast ? 'd-none' : undefined,
		isPlaying ? 'cc-hide' : undefined,
		!isMobileCast && !showVimeoPlayer ? 'cc-tuck' : undefined
	)
	const mobileSpanClass = classNames(
		'cc-link d-md-none',
		isAvailable && isMobileCast ? undefined : isPlaying ? 'cc-hide' : 'cc-hide'
	)
	const mobileInactiveClass = classNames(
		'cc-btn cc-btn-inactive',
		isActive ? 'cc-hide' : undefined
	)
	const mobileActiveClass = classNames(
		'cc-btn cc-btn-active',
		isActive ? undefined : isPlaying ? 'cc-hide' : 'cc-hide'
	)

	// Refs
	const mobileRef = useRef(null)
	const desktopRef = useRef(null)

	// Desktop Click
	const onDesktopClick = () => {
		setIsActive(!isActive)
		window.chromeClickEvent(desktopRef.current, false)
		onClick && onClick()
	}

	// Mobile Click
	const onMobileClick = () => {
		setIsActive(!isActive)
		window.chromeClickEvent(mobileRef.current, true)
		onClick && onClick()
	}

	// Cast Is Available Effect
	useEffect(() => {
		if (window.castIsAvailable != isAvailable) {
			setIsAvailable(window.castIsAvailable)
		}
	}, [window.castIsAvailable])

	// Is Mobile Effect
	useEffect(() => {
		const newIsMobileCast = isMobile || window.castIsMobile || false
		if (newIsMobileCast != isMobileCast) setIsMobileCast(newIsMobileCast)
	}, [isMobile, window.castIsMobile])

	// Render
	return (
		<>
			<span
				className={mobileSpanClass}
				vimeo-id={vimeoId}
				onClick={onMobileClick}
				ref={mobileRef}
			>
				<img
					className={mobileInactiveClass}
					src="/images/icons/chromecast-icon.png"
				/>
				<img
					className={mobileActiveClass}
					src="/images/icons/chromecast-icon-active.png"
				/>
			</span>
			<button
				// eslint-disable-next-line
				// @ts-ignore
				class={desktopClass}
				vimeo-id={vimeoId}
				onClick={onDesktopClick}
				ref={desktopRef}
				is="google-cast-button"
			/>
		</>
	)
}

export default ChromeCastButtons
