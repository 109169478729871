import GenericForm, { GenericFormProps } from './GenericForm'
import { InputInfo } from './SignInForm'
import { isRequired, validEmail } from '../../modules/validation'
import { FC } from 'react'
import useFormInfo, { FormInfo } from '../../hooks/useFormInfo'

// Props
export type ForgotPassFormProps = {
	setIsValid: (x: boolean) => any
	setInfo: (x: FormInfo) => any
}

// Account Props
export const accountProps: GenericFormProps['inputs'] = [
	{
		id: 'email-input',
		placeholder: 'Email',
		label: '',
		formKey: 'email',
		validations: [isRequired, validEmail],
	},
]

// Account Form Info
export const accountFormInfo: FormInfo = {
	email: { value: '', isValid: false } as InputInfo,
}

// Account Form Info
export type ForgotPassFormInfo = typeof accountFormInfo

/**
 * ForgotPassForm Component
 */

const ForgotPassForm: FC<ForgotPassFormProps> = ({ setIsValid, setInfo }) => {
	// Hooks
	useFormInfo({
		inputProps: accountProps,
		defaultFormInfo: accountFormInfo,
		setInfo,
		setIsValid,
	})

	// Render
	return <GenericForm isHorizontal inputs={accountProps} />
}

export default ForgotPassForm
