/* eslint-disable @typescript-eslint/no-unused-vars */
import { ArrowBack } from '@mui/icons-material'
import { FC, Fragment, useEffect, useState, useRef } from 'react'
import { Card, Col, Row, Spinner } from 'react-bootstrap'
import AppMenus, { BodyComponent } from '../../../components/layout/AppMenus'
import WithAuth from '../../../components/WithAuth'
import { backNav, navReq } from '../../../modules/apiConsume'
import useProgramPage from '../../../hooks/useProgramPage'
import { AllRSCategories, AuthPageProps } from '../../../modules/types'
import { useLocation, useParams } from 'react-router-dom'
import SelectPill from '../../../components/input/SelectPill'
import MainCard from '../../../components/cards/MainCard'
import useFavorites from '../../favorites/useFavorites'
import { Video } from '../../../modules/videos/allVideos'
import Heart from '../../../components/logos/Heart'
import useVideoProgress from '../../../hooks/useVideoProgress'
import useQueryParams from '../../../hooks/useQueryParams'

import { slideInRight, slideOutRight, slideInLeft, slideOutLeft } from 'react-animations'
import styled, { keyframes } from 'styled-components';
import ProgramMobileView from './ProgramMobileView'
import useIsMobileScreen from '../../../hooks/useIsMobileScreen'
import ScrollRow from '../../../components/layout/ScrollRow'
import useScrollRow from '../../../hooks/useScrollRow'
import useSlideDirection from '../../../hooks/useSlideDirection'
import Motion from '../../../components/animation/Motion'


const AslideInLeft = keyframes`${slideInLeft}`;
const AslideInRight = keyframes`${slideInRight}`;

const AslideOutLeft = keyframes`${slideOutLeft}`;
const AslideOutRight = keyframes`${slideOutRight}`;

const DivInLeft = styled.div` animation: 0.3s ${AslideInLeft};`;
const DivInRight = styled.div` animation: 0.3s ${AslideInRight};`;

const DivOutLeft = styled.div` animation: 0.3s ${AslideOutLeft};`;
const DivOutRight = styled.div` animation: 0.3s ${AslideOutRight};`;

const ProgramWorkouts: FC<AuthPageProps & { programType: keyof AllRSCategories['programs'] }> = ({ programType, userInfo }) => {
	// Hooks
	const { videoId } = useParams()

	const { type, from } = useQueryParams()
	const url = useLocation()

	const [pageType, setPageType] = useState(0)
	const [overrideId, setOverrideId] = useState<number | undefined>(
		Number(videoId)
	)

	const { isScrolling, setIsScrolling } = useScrollRow()

	const {
		currentWorkoutVideoId,
		currentWorkoutVideoProgressInfo,
		currentIntroVideo,
		currentWorkoutVideo,
		introVideos,
		workoutVideos,
		title
	} = useProgramPage({ programType, videoId: programType == "Mechanix" ? overrideId : videoId, userInfo, currentPhase: pageType })

	const info = useVideoProgress({ videos: workoutVideos, userInfo })

	// console.log(info)
	const [isLoadingMark, setIsLoadingMark] = useState(false)
	const [isFavorite, setIsFavorite] = useState(false)
	const [pillKey, setPillKey] = useState(0)
	const [currentVideo, setCurrentVideo] = useState<Video | undefined>(undefined)
	const { favorites, toggleFavorite, getFavorites, getIsFavorited } = useFavorites(userInfo?.account_id as number, undefined, 'workouts', true)
	const [isPlaying, setIsPlaying] = useState(false)
	const PILL_OPTIONS_MECHANIX = ['Phase 1', 'Phase 2', 'Phase 3', 'Phase 4']
	const PILL_OPTIONS = programType === "Mechanix" ? PILL_OPTIONS_MECHANIX : ['Intro to ' + currentWorkoutVideo.category, 'Workouts']
	const [current, setCurrent] = useState("")

	const [showProgramView, setShowProgramView] = useState(false)

	const isMobile = useIsMobileScreen()

	const slideDirection = useSlideDirection();

	// Split into Pairs
	const pairs: Video[][] = []
	for (const index in workoutVideos) {
		if (Number(index) % 2 == 0) {
			pairs.push([])
		}
		pairs[pairs.length - 1].push(workoutVideos[index])
	}

	// TO-DO: On Like
	const onLike = async (videoId: string) => {
		const isLiked = favorites && favorites.find((x: any) => x.id === videoId) ? true : false;
		await toggleFavorite(userInfo?.account_id as number, Number(videoId), !isLiked)
	}

	const onPillChange = (e: any, index?: number) => {
		let key = pageType;

		if (programType !== "Mechanix") {
			setCurrent(e)
			setPillKey(e === 'Workouts' ? 1 : 0)
		}
		else {
			PILL_OPTIONS_MECHANIX.forEach((x, i) => {
				x === e ?
					key = i : undefined;
			});
			setCurrent(e)
			setPageType(key);
		}
	}

	const handleOnPlay = async () => {
		setIsPlaying(true)
	}

	const handleMarkAsFavorite = async (isChecked: boolean) => {
		if (currentWorkoutVideo) {
			setIsLoadingMark(true)
			await toggleFavorite(userInfo?.account_id as number, currentWorkoutVideoId as number, isChecked)
			await getFavorites()
			setIsLoadingMark(false)
		}
	}
	const handleCloseView = () => {
		if (from && from === "training-plan") {
			navReq('/#/training-plan/view')
		}
		else {
			backNav();
			// const nextURL = `/#/programs/${url.pathname.split('/')[2]}`
			// window.location.assign(nextURL)
		}
		setShowProgramView(false)
	}
	useEffect(() => {
		setIsPlaying(false)
		setCurrentVideo(currentWorkoutVideo)
	}, [currentWorkoutVideo])

	useEffect(() => {
		setOverrideId(Number(videoId))
	}, [videoId])

	useEffect(() => {
		if (type === "mobile" && videoId) {
			setShowProgramView(true)
		} else {
			setShowProgramView(false)
		}
		setIsPlaying(false)
	}, [videoId, type])

	useEffect(() => {
		if (currentWorkoutVideo) getIsFavorited(currentWorkoutVideo.id as unknown as number).then(res => setIsFavorite(res))
	}, [currentWorkoutVideo, favorites])

	useEffect(() => {
		if (current.includes('Intro') && introVideos.length > 0) {
			setCurrentVideo(introVideos[0])
		}
		else {
			setOverrideId(undefined)
			setCurrentVideo(workoutVideos[0])
		}
	}, [current])

	useEffect(() => {
		if (programType !== "Mechanix") {
			setCurrent(currentIntroVideo ? 'Intro to ' + title : 'Workouts')
			setPillKey(currentIntroVideo ? 0 : 1)
		}
		else {
			setPageType(0)
			setCurrent('Phase 1')
		}
	}, [])

	return (
		<>
			<Motion
				isVisible={showProgramView && isMobile}
				isMobile={isMobile}
				direction={{ slide: "up" }}
			>
				<ProgramMobileView
					onLike={onLike}
					programType={programType}
					title={title}
					currentVideo={currentWorkoutVideo}
					closeView={handleCloseView}
					handleMarkAsFavorite={handleMarkAsFavorite}
					handleOnPlay={handleOnPlay}
					isPlaying={isPlaying}
					isFavorite={isFavorite}
					isLoadingMark={isLoadingMark}
					currentWorkoutVideoProgressInfo={currentWorkoutVideoProgressInfo}
				/>
			</Motion>
			<Motion
				isVisible={!(showProgramView && isMobile)}
				isMobile={isMobile}
				direction={slideDirection.current}
			>
				<AppMenus hasContainer>
					<BodyComponent>
						{/* Desktop View */}
						<div className='d-none d-md-block program-workouts'>
							<Row className='d-none d-md-block'>
								<Col md={1}>

									<span className="has-pointer back-btn w-auto pt-1" onClick={() => {
										isPlaying ? backNav() : navReq('/#/programs')
										isPlaying ? setIsPlaying(false) : undefined
									}}>
										<ArrowBack />
									</span>
								</Col>
								<Col md={11}>
									<h3 style={{ cursor: 'pointer', marginTop: '5px' }} onClick={() => {
										isPlaying ? backNav() : navReq('/#/programs')
										isPlaying ? setIsPlaying(false) : undefined
									}} className='mx-5'>
										{/* {title + " " + currentVideo?.headingTitle || ''} */}
										{programType !== "Mechanix" ? (isPlaying ? title + " " + currentWorkoutVideo?.headingTitle : title) : 'RunSmart Mechanix'}
									</h3>
								</Col>
								<Row className="get-started-training-plan mb-md-2 mb-3">
									<Col xs={12} sm={12} md={12} lg={7} xl={8} xxl={8} className="px-0 px-sm-3 mt-2">
										<div className="video-wrapper w-100 blue-bg">
											{!isPlaying ? (
												<h5 className="heading-meta-data text-white fw-regular">
													{currentWorkoutVideo?.headingTitle}
												</h5>
											) : null}
											<MainCard
												hasPlayButton
												playButtonIsCentered
												type="responsiveCardLg"
												progress={currentWorkoutVideoProgressInfo.progress}
												progressPercent={currentWorkoutVideoProgressInfo.progress}
												bgImage={currentWorkoutVideo?.image}
												vimeoId={currentWorkoutVideo?.id}
												onLike={() => onLike(currentWorkoutVideo?.id as string)}
												onPlay={handleOnPlay}
												hasCheck
											/>
											{!isPlaying && !isLoadingMark ? (
												<div className="fav-icon heart-image">
													<input type="checkbox" checked={isFavorite} onChange={e => handleMarkAsFavorite(e.target.checked)} id="heart" hidden />
													<label htmlFor="heart">
														<div className="animation">
															<span className="dot-1"></span>
															<span className="dot-2"></span>
															<span className="dot-3"></span>
															<span className="dot-4"></span>
															<span className="dot-5"></span>
															<span className="dot-6"></span>
															<span className="dot-7"></span>
															<span className="dot-8"></span>
														</div>
														<img
															className="fill pointer"
															src="./images/icons/heart-red.svg"
															alt="Like"
														/>
														<img
															className="without-fill pointer"
															src="./images/icons/heart-white-big.png"
															alt="Like"
														/>
													</label>
												</div>
											) :
												!isPlaying && isLoadingMark ? (<div className="fav-icon heart-image mb-n2 mx-n1">
													<Spinner animation='border' variant='danger' />
												</div>) : undefined
											}

											{!isPlaying ? (
												<div className="banner-info">
													<h3 className="text-white fw-normal text-capitalize">
														{programType == "Mechanix" ? "RunSmart Mechanix" : title}
													</h3>
												</div>
											) : null}

											{!isPlaying ? (
												<div className="tick-mark">
													<img
														src={`${currentWorkoutVideoProgressInfo.progress >= 80 ? './images/icons/checkmark-done.svg' : './images/icons/checkmark.svg'}`}
														alt="Like"
													/>
												</div>
											) : undefined}

										</div>
									</Col>
									{!isPlaying ? (
										<Col xs={12} sm={12} md={12} lg={5} xl={4} xxl={4} className={`${!currentIntroVideo && programType !== "Mechanix" ? 'mt-n3' : 'mt-n5'}`}>
											{currentIntroVideo || programType === "Mechanix" ? (
												<>
													{/* <SelectPill
																										 index={programType === "Mechanix" ? pageType : pillKey}
																										 key={programType === "Mechanix" ? pageType : pillKey}
																										 options={PILL_OPTIONS}
																										 onChange={onPillChange}
																										 isProgram={true}
																								 /> */}
													<div className={`text-center ${PILL_OPTIONS.length >= 3 ? 'ms-0' : 'ms-3'}`}>
														<ScrollRow noPadding setIsScrolling={setIsScrolling} >
															<SelectPill
																index={programType === "Mechanix" ? pageType : pillKey}
																key={programType === "Mechanix" ? pageType : pillKey}
																options={PILL_OPTIONS}
																onChange={onPillChange}
																isProgram={true}
															/>
														</ScrollRow>
													</div>
													{
														current !== 'Workouts' && programType !== "Mechanix" ? (
															<p className='mt-3 text-center'>Start by watching the intro training, to learn the core elements of form and movement to make the most of {title}.</p>
														) : undefined
													}

												</>
											) : undefined}

											<div className={`${!currentIntroVideo ? 'mt-4' : 'mt-3'}`}>
												{current === "Workouts" || programType === "Mechanix" ? (
													<>
														{pairs.map((pair, weekIndex) =>
														(
															<Row className="justify-content-center mb-n2" key={`w-${weekIndex}`}>
																<p>{`Week ${weekIndex + 1}`}</p>
																<Col className="justify-content-center phone-video-list">
																	{pair.map((video, dayIndex) => {
																		const videoIndex = weekIndex * 2 + dayIndex
																		const { isComplete, progress } = info?.[videoIndex] || {}
																		// const { title, subtitle } = getVideoTitleAndSubtitle(video)
																		return (
																			<Fragment key={`d-${dayIndex}`}>
																				<div className='mt-n2'>
																					<MainCard
																						videoId={video.id}
																						cardType="video"
																						// type="video"
																						hasCheck={true}
																						progress={progress}
																						logoImage={video.image}
																						detailTitle={programType == "Mechanix" ? "RunSmart Mechanix" : title}
																						detailSubtitle={video.headingTitle}
																						link={video.url + '?type=full'}
																						onLike={() => onLike(video.id)}
																						hasLikeButton={true}
																						favorites={favorites}
																						hasPlayButton={false}
																						isComplete={progress >= 80}
																						isProgram={true}
																					/>
																				</div>
																			</Fragment>
																		)
																	})
																	}
																</Col>
															</Row>
														)
														)}
													</>
												) :

													introVideos.map((video, key) => (
														<div key={key}>
															<div className={`mt-n2 pointer card image-card`}>
																<img onClick={() => navReq(video.url + '?type=full' as string)} src={video.image} alt="" />
																<Heart className='suggested-like' onLike={() => onLike(video.id as string)}
																	isLiked={favorites.find(x => x.id === video.id) ? true : false}
																// isLiked={true}
																/>
															</div>
															<p className='program-title'>{video?.headingTitle}</p>
														</div>
													))
												}

											</div>

										</Col>
									) : (
										<Col sm={6} md={5} lg={4} xl={4}>
											<Card className='p-5 mt-2 border-0'>
												<p>{currentWorkoutVideo.descriptions}</p>
											</Card>
										</Col>
									)}
								</Row>
							</Row>
						</div>
						{/* End Desktop View */}

						{/* Mobile View */}
						<div className='mt-5 d-block d-md-none program-workouts'>
							<Row>
								<Col sm={12} className={`${!currentIntroVideo && programType !== "Mechanix" ? 'mt-n3' : 'mt-n5'}`}>
									{currentIntroVideo || programType === "Mechanix" ? (
										<>
											<div className={`text-center ${PILL_OPTIONS.length >= 3 ? 'ms-0' : 'ms-3'}`}>
												<ScrollRow noPadding setIsScrolling={setIsScrolling} >
													<SelectPill
														index={programType === "Mechanix" ? pageType : pillKey}
														key={programType === "Mechanix" ? pageType : pillKey}
														options={PILL_OPTIONS}
														onChange={onPillChange}
														isProgram={true}
													/>
												</ScrollRow>
											</div>
											{
												current !== 'Workouts' && programType !== "Mechanix" ? (
													<p className='mt-3 text-center'>Start by watching the intro training, to learn the core elements of form and movement to make the most of {title}.</p>
												) : undefined
											}

										</>
									) : undefined}
									<div className={`${!currentIntroVideo && programType !== "Mechanix" ? 'mt-n4' : 'mt-3'}`}>
										{current === "Workouts" || programType === "Mechanix" ? (
											<>
												{pairs.map((pair, weekIndex) =>
												(
													<Row className="justify-content-center mb-n2" key={`w-${weekIndex}`}>
														<p>{`Week ${weekIndex + 1}`}</p>
														<Col className="justify-content-center phone-video-list">
															{pair.map((video, dayIndex) => {
																const videoIndex = weekIndex * 2 + dayIndex
																const { isComplete, progress } = info?.[videoIndex] || {}
																// const { title, subtitle } = getVideoTitleAndSubtitle(video)
																return (
																	<Fragment key={`d-${dayIndex}`}>
																		<div className='mt-n2'>
																			<MainCard
																				videoId={video.id}
																				cardType="video"
																				// type="videoLgStretch"
																				hasCheck={true}
																				isComplete={isComplete}
																				progress={progress}
																				logoImage={video.image}
																				detailTitle={programType == "Mechanix" ? "RunSmart Mechanix" : title}
																				detailSubtitle={video.headingTitle}
																				link={video.url + '?type=mobile'}
																				onLike={() => onLike(video.id)}
																				hasLikeButton={true}
																				favorites={favorites}
																				hasPlayButton={false}
																				isProgram={true}
																			/>
																		</div>
																	</Fragment>
																)
															})
															}
														</Col>
													</Row>
												)
												)}
											</>
										) :
											introVideos.map((video, key) => (
												<div key={key}>
													<div className={`mt-n2 pointer card image-card`}>
														<img onClick={() => navReq(video.url + '?type=mobile' as string)} src={video.image} alt="" />
														<Heart className='suggested-like' onLike={() => onLike(video.id as string)}
															isLiked={favorites.find(x => x.id === video.id) ? true : false}
														/>
													</div>
													<p className='program-title'>{video?.headingTitle}</p>
												</div>
											))
										}
									</div>
								</Col>
							</Row>
						</div>
					</BodyComponent>
				</AppMenus>
			</Motion>
		</>
	)
}

export default WithAuth(ProgramWorkouts)
