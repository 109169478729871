/* eslint-disable @typescript-eslint/no-unused-vars */
import WithAuth from '../../components/WithAuth'
import RSNavCont from '../../components/layout/RSNavCont'
import AppMenus from '../../components/layout/AppMenus'
import Button from 'react-bootstrap/Button'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Download from '@mui/icons-material/SaveAlt'
import MainCard from '../../components/cards/MainCard'
import useGuidePage from '../../hooks/useGuidePage'
// import WorkoutVideos from '../../components/page-specific/video/WorkoutVideos'
import AboutCard from '../../components/page-specific/video/AboutCard'
import PageTitle from '../../components/text/PageTitle'
import useAlert from '../../hooks/useAlert'
import GenericModal from '../../components/misc/GenericModal'
import GenericAlert from '../../components/misc/GenericAlert'
import Motion from '../../components/animation/Motion'
// import useAppStorage from '../../hooks/useAppStorage'
import { PUBLIC_DIR } from '../../modules/cliEnvValues'
import { UserInfoProps } from '../../modules/types'
import { backNav, navReq } from '../../modules/apiConsume'
import { emailGuide } from '../../modules/accountActions'
import {
	DOWNLOAD_GUIDE_TEXT,
	guideDocuments,
	guideText,
	strengthGuideTexts,
} from '../../modules/values'
import { useParams } from 'react-router-dom'
import { FC, Fragment, useEffect, useState } from 'react'
import useIsMobileScreen from '../../hooks/useIsMobileScreen'
import useFavorites from '../favorites/useFavorites'
import useProgramFavorites from '../favorites/useProgramFavorites'
import featuredPrograms from '../../modules/programs/featured-programs.json'
import { Card, Spinner } from 'react-bootstrap'
import { ArrowBack } from '@mui/icons-material'
import { Video } from '../../modules/videos/allVideos'
import useVideoProgress from '../../hooks/useVideoProgress'
import {
	slideInRight,
	slideOutRight,
	slideInLeft,
	slideOutLeft,
} from 'react-animations'
import styled, { keyframes } from 'styled-components'
import useQueryParams from '../../hooks/useQueryParams'
import ProgramMobileView from '../programs/v2/ProgramMobileView'
import useMailer from '../../hooks/useMailer'
import useSlideDirection from '../../hooks/useSlideDirection'
import useRNBridge from '../../hooks/useRNBridge'

// Props
export type GuidesProps = UserInfoProps & {
	guideType: 'Training' | 'Strength'
}

// Training Buttons
const TRAINING_BUTTONS = ['5K/10K', 'Half-Marathon', 'Marathon']

// Download Guide Text

// Details
const Details: FC<
	Partial<ReturnType<typeof useGuidePage>> & { hideDownload?: boolean }
> = ({ title, headline, hideDownload }) => (
	<>
		{/* Title */}
		{!hideDownload && (
			<Row>
				<Col>
					<PageTitle text={title || ''} />
				</Col>
			</Row>
		)}
		{/* Headline */}
		<h4 className="text-center mb-0">{headline}</h4>

		{/* Download Below Text */}
		{!hideDownload && (
			<Row className="text-center justify-content-center mb-5">
				<Col>
					<span className="d-inline-block">
						<h3>Download Guide Below</h3>
					</span>
					<span className="d-inline-block">
						<Download className="icon-lg ms-3" />
					</span>
				</Col>
			</Row>
		)}
	</>
)

// Download Modal
export const DownloadModal: FC<{
	title: string
	show: boolean
	userInfo: UserInfoProps['userInfo']
	onboarding?: boolean
	setShow: (x: boolean) => any
	onAlertOpen: () => any
	setAlertMessage: (x: string) => any
	getFile: () => string
	getEmailId?: () => number
	getGuideType: () => string
}> = ({
	title,
	show,
	userInfo,
	setShow,
	onboarding,
	onAlertOpen,
	setAlertMessage,
	getFile,
	getEmailId,
	getGuideType,
}) => {
		//Is Mobile
		const isMobileScreen = useIsMobileScreen()

		//Set Email
		const [email, setEmail] = useState(userInfo?.email)

		//Mailer hook

		const { sendMail } = useMailer()

		// On Download Guide
		const onDownloadGuide = () => {
			const file = getFile()
			navReq(file)
			setShow(false)
		}

		//isMobile

		// On Email Guide
		const onEmailGuide = async () => {
			// const emailId = getEmailId()
			const guideType = getGuideType()
			const guideFile = `${PUBLIC_DIR}${getFile()}`
			// const toEmail = email
			// await emailGuide({
			// 	username: userInfo?.username || toEmail,
			// 	accountId: userInfo?.account_id || 0,
			// 	emailId,
			// 	toEmail,
			// 	guideFile,
			// 	guideType,
			// })
			await sendMail(
				guideFile.includes('mp3') ? guideType : guideType + ' Guide',
				email as string,
				guideFile
			)
			setAlertMessage(`Guide emailed to ${email as string}`)
			onAlertOpen()
			setShow(false)
		}

		// Hooks
		// const { isMobile } = useAppStorage()

		// Params
		const button1Text = isMobileScreen ? 'Email' : 'Download'
		const button1Action = isMobileScreen ? onEmailGuide : onDownloadGuide
		const button2Text = isMobileScreen ? undefined : 'Email'
		const button2Action = isMobileScreen ? undefined : onEmailGuide

		useEffect(() => {
			if (userInfo?.account_id) setEmail(userInfo?.email)
		}, [userInfo])
		// Render
		return (
			<GenericModal
				show={show}
				setShow={setShow}
				onboarding={onboarding}
				title={title}
				text={DOWNLOAD_GUIDE_TEXT}
				button1Text={button1Text}
				button1Action={button1Action}
				button2Text={button2Text}
				button2Action={button2Action}
				setEmail={setEmail}
				email={email}
				type="download"
			/>
		)
	}

/**
 * Guides Component
 */

const AslideInLeft = keyframes`${slideInLeft}`
const AslideInRight = keyframes`${slideInRight}`

const AslideOutLeft = keyframes`${slideOutLeft}`
const AslideOutRight = keyframes`${slideOutRight}`

const DivInLeft = styled.div`
	animation: 0.3s ${AslideInLeft};
`
const DivInRight = styled.div`
	animation: 0.3s ${AslideInRight};
`

const DivOutLeft = styled.div`
	animation: 0.3s ${AslideOutLeft};
`
const DivOutRight = styled.div`
	animation: 0.3s ${AslideOutRight};
`

const Guides: FC<GuidesProps> = ({ guideType, userInfo }) => {
	// Hooks
	const { sendDataToReactNative } = useRNBridge()
	const { videoId } = useParams()
	const { type } = useQueryParams()
	const { title, videos, currentVideo, progressInfo } = useGuidePage({
		guideType,
		videoId,
	})
	const [isFavorite, setIsFavorite] = useState(false)
	const [isLoadingMark, setIsLoadingMark] = useState(false)
	const { favorites, toggleFavorite } = useFavorites(
		userInfo?.account_id as number,
		undefined,
		'guides',
		true
	)
	const { toggleProgramFavorite, programFavorites } = useProgramFavorites(
		userInfo?.account_id as number,
		isFavorite
	)

	const isMobile = useIsMobileScreen()
	const slideDirection = useSlideDirection();

	const info = useVideoProgress({ videos: videos, userInfo })

	const [showProgramView, setShowProgramView] = useState(false)

	// Split into Pairs
	const pairs: Video[][] = []
	for (const index in videos) {
		if (Number(index) % 2 == 0) {
			pairs.push([])
		}
		pairs[pairs.length - 1].push(videos[index])
	}

	const {
		showAlert,
		onAlertOpen,
		onAlertClose,
		alertTitle,
		alertMessage,
		setAlertMessage,
		alertVariant,
	} = useAlert()

	// Properties
	const hasVideoId = videoId ? true : false

	// State
	const [modalOpen, setModalOpen] = useState(false)
	const [modalTitle, setModalTitle] = useState('')

	const [isPlaying, setIsPlaying] = useState(false)

	// Get File
	const getFile = () => {
		const key = modalTitle.replace('Guide', '').replace(/[-/]/g, ' ').trim()
		return guideDocuments[key as keyof typeof guideDocuments]
	}

	// TO-DO: On Like
	const onLike = async (videoId: string) => {
		const isLiked =
			favorites && favorites.find((x: any) => x.id === videoId) ? true : false
		await toggleFavorite(
			userInfo?.account_id as number,
			Number(videoId),
			!isLiked
		)
	}

	// Get Email ID
	const getEmailId = () =>
		modalTitle.toLowerCase().includes('strength') ? 78 : 76

	// Get Guide Type
	const getGuideType = () => {
		const lowerTitle = modalTitle.toLowerCase()
		return lowerTitle.includes('5k')
			? '5k/10k'
			: lowerTitle.includes('half')
				? 'Half Marathon'
				: lowerTitle.startsWith('marathon')
					? 'Marathon'
					: 'Strength'
	}

	// Download Click
	const onDownloadClick = (value: string) => {
		setModalTitle(`${value} Guide`)
		setModalOpen(true)
	}

	const handleCloseProgramView = () => {
		backNav()
		setIsPlaying(false)
		setShowProgramView(false)
	}

	const handleLikeProgram = async (checked: boolean) => {
		const program = getProgram()
		setIsLoadingMark(true)
		if (userInfo?.account_id && currentVideo) {
			const current = programFavorites.find(
				(x) => x.program_id == program?.Program_ID
			)
			await toggleProgramFavorite(
				program?.Program_ID as number,
				userInfo.account_id,
				current
			)
			setIsFavorite(!checked)
		}
		setIsLoadingMark(false)
	}

	const getProgram = () => {
		const program = featuredPrograms.find(
			(x) =>
				x.Program ===
				(guideType === 'Training' ? 'Training Guide' : 'Strength Guide')
		)
		return program
	}
	const handleOnPlay = async () => {
		setIsPlaying(true)
	}

	useEffect(() => {
		const program = getProgram()
		if (programFavorites) {
			const current = programFavorites?.find(
				(x) => x.program_id == program?.Program_ID
			)
			if (current) {
				if (current.favorited == 1) setIsFavorite(true)
				else setIsFavorite(false)
			}
		}
	}, [programFavorites])

	useEffect(() => {
		setIsPlaying(false)
	}, [currentVideo])

	useEffect(() => {
		setIsPlaying(false)
		videoId && type === 'mobile'
			? setShowProgramView(true)
			: setShowProgramView(false)
	}, [videoId, type])

	useEffect(() => {
		if (isMobile) {
			const dataToSend = {
				requestType: 'cast-show-bottom-menu',
				payload: showProgramView ? false : true,
				timestamp: new Date().getTime(),
			}
			sendDataToReactNative(dataToSend);
		}
	}, [showProgramView, isMobile])

	// Render
	return (
		<>
			{/* Alert */}
			<GenericAlert
				show={showAlert}
				title={alertTitle}
				message={alertMessage}
				variant={alertVariant}
				onClose={onAlertClose}
			/>
			<Motion
				isVisible={showProgramView && isMobile}
				isMobile={isMobile}
				direction={{ slide: "up" }}
			>
				<ProgramMobileView
					onLike={onLike}
					programType={guideType}
					title={title}
					currentVideo={
						guideType === 'Strength'
							? {
								...currentVideo,
								descriptions: strengthGuideTexts.descriptions,
							}
							: currentVideo
					}
					closeView={handleCloseProgramView}
					handleMarkAsFavorite={() => {
						null
					}}
					handleOnPlay={handleOnPlay}
					isPlaying={isPlaying}
					isFavorite={isFavorite}
					isLoadingMark={isLoadingMark}
					currentWorkoutVideoProgressInfo={progressInfo}
				/>
			</Motion>

			<Motion
				isVisible={!(showProgramView && isMobile)}
				isMobile={isMobile}
				direction={slideDirection.current}
			>
				<RSNavCont>
					<Row className="w-100 mt-4">
						<Col className="d-none d-md-block" sm={4} md={4} lg={6} xl={8}>
							<span
								className="has-pointer back-btn mt-0 pt-1"
								onClick={backNav}
							>
								<ArrowBack />
							</span>
							<h3
								style={{ cursor: 'pointer', marginTop: '5px' }}
								onClick={backNav}
								className="mx-5"
							>
								{guideType} Guide
							</h3>
						</Col>
						<Col
							className="d-none d-md-block text-center"
							sm={8}
							md={8}
							lg={6}
							xl={4}
						>
							<Row>
								<Col>
									{guideType == 'Training' ? (
										<div>
											<Download />
											{TRAINING_BUTTONS.map((value) => (
												<>
													<span className="d-none d-md-inline-block mx-1">
														<Button
															size="sm"
															variant="dark"
															className="rounded-pill program-nav color-primary-bg mx-3 fixed-sm-btn"
															onClick={onDownloadClick.bind(null, value)}
														>
															{value}
														</Button>
													</span>
													<span className="d-inline-block d-md-none">
														<Button
															variant="dark"
															className="rounded-pill color-primary-bg mx-1 fixed-sm-btn small-text"
															onClick={onDownloadClick.bind(null, value)}
														>
															{value}
														</Button>
													</span>
												</>
											))}
										</div>
									) : undefined}
								</Col>
							</Row>
						</Col>
					</Row>
					{/* Download Row */}
					{/* <Row className='mt-md-n5'>
					<Col className='d-none d-md-block' sm={12} md={12} lg={6} xl={6}></Col>
					<Col className='d-none d-md-block' sm={12} md={12} lg={6} xl={6}>

					</Col>
				</Row> */}
					{/* Video Card Row */}
					<Row className="get-started-training-plan mb-md-2 mb-3 mt-n4 mt-md-3 px-2">
						<Col sm={12}>
							<div className="video-wrapper mx-n3 mx-md-0 w-auto blue-bg d-none d-md-block">
								{!isPlaying ? (
									<h5 className="heading-meta-data text-white fw-regular">
										{currentVideo.category}
									</h5>
								) : null}
								<MainCard
									hasPlayButton
									playButtonIsCentered
									type="responsiveCardLg"
									bgImage={currentVideo.image}
									vimeoId={currentVideo.id}
									progress={progressInfo?.progress}
									onLike={() => onLike(currentVideo.id)}
									onPlay={() => setIsPlaying(true)}
								/>
								{!isPlaying && !isLoadingMark ? (
									<div className="fav-icon heart-image">
										<input
											type="checkbox"
											checked={isFavorite}
											onChange={(e) => handleLikeProgram(e.target.checked)}
											id="heart"
											hidden
										/>
										<label htmlFor="heart">
											<div className="animation">
												<span className="dot-1"></span>
												<span className="dot-2"></span>
												<span className="dot-3"></span>
												<span className="dot-4"></span>
												<span className="dot-5"></span>
												<span className="dot-6"></span>
												<span className="dot-7"></span>
												<span className="dot-8"></span>
											</div>
											<img
												className="fill pointer"
												src="./images/icons/heart-red.svg"
												alt="Like"
											/>
											<img
												className="without-fill pointer"
												src="./images/icons/heart-white-big.png"
												alt="Like"
											/>
										</label>
									</div>
								) : !isPlaying && isLoadingMark ? (
									<div className="fav-icon heart-image mb-n2 mx-n1">
										<Spinner animation="border" variant="danger" />
									</div>
								) : undefined}

								{!isPlaying ? (
									<div className="banner-info">
										<h3 className="text-white fw-normal text-capitalize">
											{/* {selectedVideoType === "Intro" ? (
																												<>
																														{exerciseData && exerciseData.length > 0 ? "Intro : " + exerciseData[0].exercise_title : ''}
																												</>
																										) : ( */}
											<>{currentVideo.headingTitle || ''}</>
											{/* )
																										} */}
										</h3>
										{/* <h4 className="text-white text-capitalize">
												{currentWorkoutVideo?.headingTitle}
											</h4> */}
									</div>
								) : null}

								{!isPlaying ? (
									<div className="tick-mark">
										<img
											src={`${progressInfo?.progress == 80
												? './images/icons/checkmark-done.svg'
												: './images/icons/checkmark.svg'
												}`}
											alt="Like"
										/>
									</div>
								) : undefined}
							</div>
						</Col>

						<Col sm={12}>
							{/* Workout Videos */}
							{/* <WorkoutVideos
							favorites={favorites}
							onLike={onLike}
							videos={videos}
							hideWeeks
							userInfo={userInfo}
						/> */}

							<>
								<div className="mt-md-n5 mt-4 text-center mb-3">
									{guideType === 'Strength' ? (
										<span className="ps-3 ps-md-0">
											<Download />
											<Button
												size="sm"
												variant="dark"
												className="rounded-pill color-primary-bg mx-2"
												onClick={onDownloadClick.bind(null, 'Strength')}
											>
												Strength Training Guide
											</Button>
										</span>
									) : (
										<div className="d-block d-md-none mb-n4">
											<Download />
											{TRAINING_BUTTONS.map((value) => (
												<>
													<span className="d-none d-md-inline-block mx-1">
														<Button
															size="sm"
															variant="dark"
															className="rounded-pill program-nav color-primary-bg mx-3 fixed-sm-btn"
															onClick={onDownloadClick.bind(null, value)}
														>
															{value}
														</Button>
													</span>
													<span className="d-inline-block d-md-none">
														<Button
															variant="dark"
															className="rounded-pill color-primary-bg mx-1 fixed-sm-btn small-text"
															onClick={onDownloadClick.bind(null, value)}
														>
															{value}
														</Button>
													</span>
												</>
											))}
										</div>
									)}
								</div>

								<div className={`${isPlaying ? 'd-none' : 'd-block'}`}>
									<Card
										className={`border-0 p-4 mb-3 ${guideType === 'Strength' ? 'mt-0' : 'mt-5'
											}`}
									>
										To get started, download the guide of your choice. Then,
										watch the videos below to get started.
									</Card>
									{pairs.map((pair, weekIndex) => (
										<Row
											className="justify-content-center mb-n0"
											key={`w-${weekIndex}`}
										>
											{/* <p>{`Week ${weekIndex + 1}`}</p> */}
											<Col className="justify-content-center phone-video-list">
												{pair.map((video, dayIndex) => {
													const videoIndex = weekIndex * 2 + dayIndex
													const { progress } = info?.[videoIndex] || {}
													// const { title, subtitle } = getVideoTitleAndSubtitle(video)
													return (
														<Fragment key={`d-${dayIndex}`}>
															<div className="mt-n2">
																<MainCard
																	videoId={video.id}
																	cardType="video"
																	type="videoLgStretch"
																	hasCheck={true}
																	progress={progress}
																	logoImage={video.image}
																	detailTitle={guideType + ' Guide'}
																	detailSubtitle={video.headingTitle}
																	link={
																		video.url +
																		`${isMobile ? '?type=mobile' : '?type=full'
																		}`
																	}
																	// onLike={() => onLike(video.id)}
																	// hasLikeButton={true}
																	favorites={favorites}
																	hasPlayButton={isMobile ? false : true}
																	isComplete={progress >= 80}
																	isProgram={true}
																/>
															</div>
														</Fragment>
													)
												})}
											</Col>
										</Row>
									))}
								</div>

								<div
									className={`${isPlaying ? 'd-block' : 'd-none'} mt-md-5`}
								>
									{/* Details Mobile */}
									<div className="d-block d-md-none">
										<Details title={title} hideDownload />
									</div>

									{/* About Card */}
									{hasVideoId &&
										currentVideo.headlines.length > 0 &&
										currentVideo.descriptions.length > 0 && (
											<Row className="mb-3">
												<Col>
													<AboutCard
														aboutHeadlines={currentVideo.headlines || []}
														aboutDescriptions={
															currentVideo.descriptions || []
														}
													/>
												</Col>
											</Row>
										)}

									{guideType === 'Strength' && (
										<Row className="mb-3">
											<Col>
												<AboutCard
													aboutHeadlines={strengthGuideTexts.headlines}
													aboutDescriptions={strengthGuideTexts.descriptions}
												/>
											</Col>
										</Row>
									)}
								</div>
							</>
						</Col>
					</Row>

					{/* Download Modal */}
					<DownloadModal
						title={modalTitle}
						show={modalOpen}
						setShow={setModalOpen}
						userInfo={userInfo}
						getEmailId={getEmailId}
						getGuideType={getGuideType}
						onAlertOpen={onAlertOpen}
						setAlertMessage={setAlertMessage}
						getFile={getFile}
					/>
				</RSNavCont>
			</Motion>
		</>
	)
}

export default WithAuth(Guides)
