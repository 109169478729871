import React from 'react';
import { Table } from 'react-bootstrap';

type JSONValue =
    | string
    | number
    | boolean
    | { [key: string]: JSONValue }
    | JSONValue[];

interface JSONTableProps {
    data: JSONValue;
    noHeader?: boolean;
}


const JSONTable: React.FC<JSONTableProps> = ({ data, noHeader }) => {
    if (typeof data === 'string' || typeof data === 'number' || typeof data === 'boolean') {
        return <span>{data.toString()}</span>;
    }

    if (Array.isArray(data)) {
        return (
            <Table responsive striped bordered hover size="sm">
                <thead>
                    <tr>
                        {data.length > 0 && <th>Index</th>}
                        <th>Value</th>
                    </tr>
                </thead>
                <tbody>
                    {data.map((value, index) => (
                        <tr key={index}>
                            <td>{index}</td>
                            <td><JSONTable data={value} /></td>
                        </tr>
                    ))}
                </tbody>
            </Table>
        );
    }

    if (typeof data === 'object' && data !== null) {
        return (
            <Table responsive striped bordered hover size="sm">
                {
                    noHeader ? null : (
                        <thead>
                            <tr>
                                {Object.keys(data).map((key) => (
                                    <th key={key}>{key.replaceAll('_', ' ')}</th>
                                ))}
                            </tr>
                        </thead>
                    )
                }
                <tbody>
                    <tr>
                        {Object.values(data).map((value, index) => (
                            <td key={index}><JSONTable data={value} /></td>
                        ))}
                    </tr>
                </tbody>
            </Table>
        );
    }

    return null;
};

export default JSONTable;