import { ArrowBack } from '@mui/icons-material'
import { backNav } from '../../modules/apiConsume'

const WaiverOfLiability = () => {
    return (
        <div className='misc'>
            <span className="has-pointer back-btn-misc" onClick={() => backNav()}>
                <ArrowBack />
            </span>
            <h1>Waiver of Liability</h1>
            <p>By participating in any and all activities RunSmart Online LLC or RunSmartOnline.com offers, I indicate that I have read and understand this Waiver of Liability and terms of use. I am aware that this is a waiver and a release of liability and I voluntarily agree to its terms.</p>

            <p>1. As the participant, I, on behalf of myself, recognize and acknowledge that there are risks of physical injury and I, on behalf of myself, agree to assume the full risk of any injuries (including death), damages or loss which I, on behalf of myself, may sustain as a result of participating in any and all activities arising out of, connected with or in any way associated with my use of RunSmart Online, LLC. I, on behalf of myself, acknowledge that participation and use of RunSmart Online, LLC activities is voluntary.</p>

            <p>2. I, on behalf of myself, do hereby fully release and discharge RunSmart Online, LLC and its employees and the sponsors and those whose facilities are being used for this program (collectively, the “Released Parties”) from any and all liability, claims and causes of action from injuries or illness (including death), damages or loss which I, on behalf of myself, may have or which may accrue to me on account of participation in all activities utilizing the facility. This is a complete and irrevocable release and waiver of liability. Specifically and without limitation, I, on behalf of myself, hereby release the Released Parties from any liability, claim, or cause of action arising out of the Released Parties’ negligence. I, on behalf of myself, covenant not to sue the Released Parties for any alleged liabilities, claims, or causes of action released hereunder</p>

            <p>3. In the event of any emergency or injury, I, on behalf of myself, authorize the Released Parties to secure from any licensed hospital, physician and/or medical personnel any treatment deemed necessary for my immediate care and agree that I, on behalf of myself, will be responsible for payment of any and all medical services rendered.</p>

            <p>4. I hereby acknowledge my responsibility in communicating any physical and psychological concerns that might conflict with participation in activity. I acknowledge that I am physically fit and mentally capable of performing the physical activity I choose to participate in.</p>

            <p>After having read this waiver and knowing these facts, and in consideration of acceptance of my participation and the RunSmart Online, LLC furnishing services to me, I agree, for myself and anyone entitled to act on my behalf, to HOLD HARMLESS, WAIVE AND RELEASE RunSmart Online, LLC, its owners, employees, organizers, representatives, and successors from any responsibility, liabilities, demands, or claims of any kind arising out of my participation in RunSmart Online, LLC exercises, programs and/or events.</p>
        </div>
    )
}

export default WaiverOfLiability