import { FC } from 'react'

import Button from '@mui/material/Button';

import RSTheme from '../styles/RSTheme'

export type RSJourneyCardProps = {
	children?: any,
	onClick?: Function,
	isPast?: boolean,
	isCurrentActive?: boolean
}


const RSJourneyCard: FC<RSJourneyCardProps> = ({children, onClick, isPast, isCurrentActive}) => {
	return <>
		<RSTheme>
			<Button variant='outlined' onClick={()=>onClick?.()} sx={{ backgroundColor: `${isCurrentActive ? '#bfd38c' : 'white'} !important`, border: `1px solid ${isPast ? 'white' : '#9FCD2B'} !important`, textAlign: 'left', width: '100%', textTransform: 'none', px: 2, borderRadius: '5px', minHeight: '80px' }}>
				{children}
			</Button>
		</RSTheme>
	</>
}

export default RSJourneyCard;
