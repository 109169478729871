/* eslint-disable @typescript-eslint/no-unused-vars */

import useVimeoPlayer from '../../hooks/useVimeoPlayer'
import useUserInfo from '../../hooks/useUserInfo'
import { FC, useRef, useEffect, useState } from 'react'
import { IVimeoPlayerStore } from '../../store/createVimeoPlayerStore'
import useStore from '../../store/useStore'
// import { FaVolumeXmark } from 'react-icons/fa6'
import { VolumeOff, VolumeUp } from '@mui/icons-material'
// import { RouletteSpinner as Loader } from 'react-spinner-overlay'
import { isIOS, isAndroid } from 'react-device-detect'
import { IComponentStore } from '../../store/createComponentStore'
import Player from '@vimeo/player'

// Props
export type VimeoPlayerProps = {
	id: string | number
	isPlaying?: boolean,
	isMuted?: boolean,
	currentProgress?: number
}

// Get Link
const getLink = (id: string | number, isPlaying = false, isMobile: boolean) =>
	// `https://player.vimeo.com/video/${id}${isPlaying ? '?autoplay=1' : ''}`
	`https://player.vimeo.com/video/${id}?autoplay=${isPlaying ? 1 : 0}${isMobile ? "&controls=0" : "&controls=1"}`

/**
 * VimeoPlayer Component
 */

const VimeoPlayer: FC<VimeoPlayerProps> = ({ id, isPlaying, isMuted, currentProgress }) => {
	const { isMobile } = useStore((state: IComponentStore) => state)

	const requestKey = localStorage.getItem('deviceRequestKey') || undefined

	const isMobileApp = isMobile || requestKey ? true : false
	// State
	const [src, setSrc] = useState(getLink(id, isPlaying, requestKey ? true : false))

	const [isMutedVid, setIsMutedVid] = useState(isMobileApp && !isMuted ? false : true)
	const [currentPlayer, setCurrentPlayer] = useState<Player | undefined>()

	// const [videoVisible, setVideoVisible] = useState(false)

	// Ref
	const iframeRef = useRef(null)

	// Hooks
	const { accountId } = useUserInfo()

	const { isFullScreen, currentAction, setIsShowControls, setCurrentAction, isShowControls, setShowVimeoPlayer, setIsFullScreen, setCurrentTimeValue } = useStore((state: IVimeoPlayerStore) => state)

	useEffect(() => {
		if (iframeRef.current && accountId)
			useVimeoPlayer({
				player: new Player(iframeRef.current),
				userId: accountId,
				isMuted: isMobile && (isIOS || isAndroid) ? isMutedVid : isMuted,
				// action: currentAction ? currentAction : undefined,
				// setAction: setCurrentAction,
				// showControls: setIsShowControls,
				// setIsFullScreen: setIsFullScreen,
				// setShowPlayer: setShowVimeoPlayer,
				isMobileApp: isMobile ? true : false,
				// setVideoVisible: setVideoVisible,
				isFullScreen: isFullScreen,
				setCurrentTimeValue: setCurrentTimeValue,
				currentProgress: currentProgress
			})
	}, [iframeRef.current, accountId, isMutedVid])

	// Is Playing Effect
	useEffect(() => {
		if (isPlaying && id) {
			setSrc(getLink(id, isPlaying, requestKey ? true : false))
		}
	}, [isPlaying, id, requestKey])

	useEffect(() => {
		return () => {
			currentPlayer?.destroy().then((res) => {
				console.log(res)
			}).catch(err => console.log(err))
			setCurrentPlayer(undefined)
			setCurrentAction(undefined)
			setSrc('')
		}
	}, [])

	// Render
	return (
		<div style={{ boxSizing: 'unset' }}>
			<iframe
				ref={iframeRef}
				className={`iframe-player ${isFullScreen ? 'fullscreen-content' : ''}`}
				src={src}
				frameBorder="0"
				allowFullScreen={false}
				allow="autoplay"
				data-ready
			/>
			{isMobileApp ? (
				<div className="overlay"
					// onClick={() => {
					// 	if (!isMobileApp) {
					// 		isMobileApp && !isFullScreen ? setIsMutedVid(!isMutedVid) : undefined
					// 		isMobileApp && isFullScreen && !isShowControls ? setIsShowControls(true) : undefined
					// 		isMobileApp && isFullScreen && isShowControls ? setIsShowControls(false) : undefined
					// 	}
					// }}
					onTouchStart={() => {
						isMobileApp ? setIsMutedVid(!isMutedVid) : undefined
						isMobileApp && isFullScreen && !isShowControls ? setIsShowControls(true) : undefined
						isMobileApp && isFullScreen && isShowControls ? setIsShowControls(false) : undefined
					}}
				>
					{/* {
						!videoVisible && isFullScreen ? (
							<div className='loader-container'>
								<Loader color='#FFF' size={50} />ƒ
							</div>) : undefined
					} */}
					{isMutedVid && !isFullScreen ? (
						<VolumeOff className='scaled-up' style={{ position: 'absolute', bottom: 10, left: 10 }} htmlColor='#fff' />
					) : !isMutedVid && !isFullScreen ? (
						<VolumeUp className='scaled-up' style={{ position: 'absolute', bottom: 10, left: 10 }} htmlColor='#fff' />
					) : undefined}
				</div>
			) : undefined}
		</div>
	)
}

export default VimeoPlayer

