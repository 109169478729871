import allCategories from '../modules/static-images/allCategories'
/**
 * useWebinarsPage Hook
 */

const useWebinarsPage = () => {
	return { webinars: allCategories.webinars }
}

export default useWebinarsPage
