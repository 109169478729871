import { postReq, getReq, deleteReq } from './apiConsume'


export const getActivePlan = async (user_id: number, program_id?: number) =>
    await getReq(`/v2/active-program?user_id=${user_id}&program_id=${program_id}`)


export const addActivePlan = async (args: { user_id: number, program_id: number }) =>
    await postReq(`/v2/active-program`, args)

export const saveSuggestedPrograms = async (userId: number, programs: Array<any>) => {
    let suggested_programs: Array<number> = []
    programs.length > 0 ?
        programs.forEach(e => {
            suggested_programs.push(e)
        }) : suggested_programs = [];

    try {
        await deleteReq('/v2/suggested-programs?user_id=' + userId)
        const result = await postReq('/v2/suggested-programs/' + userId, { suggested_programs })
        if (result?.status == 'ok') return true
    } catch (err) {
        //Do Nothing
    }
    return false
}

export const getSuggestedProgramsByUserId = async (userId: number) => {
    try {
        const request = await getReq('/v2/suggested-programs?user_id=' + userId)
        return request?.data?.result
    } catch (err) {
        return []
    }
}

// Use addActivePlan for reseting since logic there is when we add with the same existing plan, 
// it changes the status column to 0 instead and adds a new row with that plan
// export const resetPlanProgress = async (args: { program_id: number }) => {}


export const removeActivePlan = async (active_program_id: number) =>
    await deleteReq(`/v2/active-program/${active_program_id}`)


export const updateBillingInfo = async (id: string) =>
    await postReq('/update-billing-info', {
        id: id != null ? String(id) : '',
    })