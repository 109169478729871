import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Button from 'react-bootstrap/Button'
import MultiLineText from '../text/MultiLineText'
import Card from 'react-bootstrap/Card'
import { FC } from 'react'

// Props
export type DetailedContentCardProps = {
	title: string
	text: string
	buttonText: string
	link?: string
}

/**
 * DetailedContentCard Component
 */

const DetailedContentCard: FC<DetailedContentCardProps> = ({
	title,
	text,
	link,
	buttonText,
}) => {
	// Render
	return (
		<Card className="responsive-card-xl">
			<Card.Body className="vertical-center-container justify-content-center">
				<Card.Text as="div">
					{/* Title Row */}
					<Row className="justify-content-center text-center mb-4">
						<Col>
							<h3>{title}</h3>
						</Col>
					</Row>

					{/* Text Row */}
					<Row className="justify-content-center text-center mb-4">
						<Col>
							<MultiLineText text={text} />
						</Col>
					</Row>

					{/* Button Row */}
					<Row className="justify-content-center text-center">
						<Col>
							<Button
								variant="dark"
								className="rounded-pill color-primary-bg"
								as="a"
								href={link}
							>
								{buttonText}
							</Button>
						</Col>
					</Row>
				</Card.Text>
			</Card.Body>
		</Card>
	)
}

export default DetailedContentCard
