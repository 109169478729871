/* eslint-disable @typescript-eslint/no-unused-vars */

import { ArrowBackIos, ArrowForwardIos } from '@mui/icons-material'
import React, { FC, useState } from 'react'
import { navReq } from '../../modules/apiConsume'
import Indicator from '../onboarding/Indicator'
import Answer from './Answer'
import assessmentsDetails from './AssessmentsDetails.json'

interface Assessment {
    assessmentId: number,
    areaId: number
}

const Assessment: FC<{
    currentProgress: number,
    assesment: Assessment[],
    selectArea: (assessmentId: number, areaId: number) => any,
    navigate: any
}> = ({ currentProgress, selectArea, assesment, navigate }) => {

    // const [currentProgress, setCurrentProgress] = useState(1)
    const isSelected = (assessmentId: number, areaId: number) => {
        const selected = assesment?.find(x => x.assessmentId == assessmentId && x.areaId == areaId)
        if (selected)
            return true
        else
            return false
    }

    return (
        <>
            <div className='scroll-view text-center px-md-2 mt-4'>
                <div className='content-questions'>
                    <div className='indicators-small'>
                        {assessmentsDetails.map(x => (
                            <Indicator navigate={() => navigate(x.id)} key={x.id} active={x.id <= Number(currentProgress) ? true : false} />
                        ))}
                    </div>
                </div>
                {/* <p style={{ fontSize: '1.2rem' }}>Do you struggle with any of these areas?</p> */}
                <div className='mb-4'>
                    <p style={{ fontSize: '1.1rem' }} className='mb-0 mt-n2'><b>Use the video above to identify imbalances.</b></p>
                    <p className='text-nm' >Select areas below that do not pass the assessment.</p>
                </div>
                {assessmentsDetails[currentProgress - 1]?.pieces.map((part) => (
                    <Answer key={part.id} option={part.part} selected={isSelected(assessmentsDetails[currentProgress - 1].id, part.id)} setAnswer={() => selectArea(assessmentsDetails[currentProgress - 1].id, part.id)} />
                ))}
            </div>

        </>
    )
}

export default Assessment