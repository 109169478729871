import { useState, useEffect, FC } from 'react'
import { useNavigate } from 'react-router-dom'

import Grid from '@mui/material/Grid'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography';
import Favorite from '@mui/icons-material/Favorite'

import { UserInfoProps } from '../../modules/types'
import RSCardCont from '../../components/cards/RSCardCont'
import ScrollRow from '../../components/layout/ScrollRow'
import useFavorites from '../favorites/useFavorites'
import useSWR from 'swr'
import { Program, ProgramVideo } from '../../api/v2/programs/programs.types'
import { Video } from '../../modules/videos/allVideos';
import RSDrawer from '../../components/layout/RSDrawer';
import ExercisePage from '../exercises/ExercisePage';

export type FavoritesHomeProps = UserInfoProps & {
	hasLoaded: boolean | undefined
}

const FavoritesHome: FC<FavoritesHomeProps> = ({ userInfo, hasLoaded }) => {

	const [isScrolling, setIsScrolling] = useState(false)
	const [isLoading, setIsLoading] = useState(true)
	const [favoriteList, setFavoriteList] = useState<Array<Video> | undefined>()
	const navigate = useNavigate()
	const { favorites } = useFavorites(userInfo?.account_id as number, undefined, 'all', true)

	const { data: programVideos } = useSWR(hasLoaded ? `/program-videos` : undefined)
	const { data: programs } = useSWR(hasLoaded ? `/programs` : undefined)
	const [showExerciseVideo, setShowExerciseVideo] = useState(false)
	const [exerciseVideoId, setExerciseVideoId] = useState<number>()

	const renderExercisePlayer = () => {
		return (
			<RSDrawer
				bottom
				noClose
				open={showExerciseVideo}
				onClose={() => setShowExerciseVideo(false)}
			>
				<Box>
					<ExercisePage closePlayer={() => {
						setShowExerciseVideo(false)
					}} isFromTP={true} userInfo={userInfo} videoIdProps={String(exerciseVideoId)} />
				</Box>
			</RSDrawer>
		)
	}

	const getVideoDurations = async (vids: Array<Video>, programVideos?: ProgramVideo[], programs?: Program[]) => {
		setIsLoading(true)
		const finalData: Array<Video> = []
		for (const v of vids) {
			if (v.id && programVideos?.length) {
				let duration = '';
				let durationN = 0
				let program_has_intro = false;
				let program_id = undefined
				if (v.duration)
					durationN = v?.duration ? Math.round(v?.duration / 60) : 0
				else {
					const video = programVideos?.find((x) => x.video_id == Number(v.id))
					const program = programs?.find((x) => x.program_id == video?.program_id)
					program_id = program?.program_id;
					program_has_intro = program?.program_tabs?.toLowerCase().includes('intro') ? true : false
					durationN = video?.video_length ? Math.round(video?.video_length / 60) : 0
				}
				duration = durationN > 0 ? `${durationN} Minute${durationN > 1 ? 's' : ''}` : ''
				finalData.push({ ...v, duration, program_has_intro, program_id })
			} else
				finalData.push(v)
		}
		setIsLoading(false)
		setFavoriteList(finalData)
	}


	const handleNavigateToVideo = (url: string, video_id?: number, program_id?: number, intro?: number, program_has_intro?: boolean) => {
		if (!program_id && url && url.includes('exercise')) {
			setShowExerciseVideo(true)
			setExerciseVideoId(video_id)
		}
		else if (!program_id && url)
			navigate(url.replace('/#', ''), { replace: true })
		else if (program_id && video_id) {
			navigate(`/explore?videoId=${video_id}&p_id=${program_id}${program_has_intro ? (intro ? '&tab=1' : '&tab=2') : '&tab=1'}`, { replace: true })
		}

	}

	useEffect(() => {
		const progVids = programVideos?.data?.result as ProgramVideo[]
		const progs = programs?.data?.result as Program[]
		progVids?.length && getVideoDurations(favorites, progVids, progs)
	}, [favorites, programVideos, programs])

	const renderFavorites = () => {
		const favList: Video[] = favoriteList?.length ? favoriteList : []
		return favoriteList?.length === 0 ? <Grid container xs={12} sx={{ px: 2, mt: 0, mb: 1, mx: 2, bgcolor: '#FFF', height: '100px', borderRadius: '5px' }} justifyContent='flex-start' alignItems='center'>
			<Typography sx={{ fontFamily: 'Poppins', fontSize: '16px', fontStyle: 'italic', textAlign: 'center' }}>No faves yet! Tap the
				<Favorite htmlColor='#fff' sx={{ fontSize: '28px', ml: '5px', mr: '4px', stroke: 'gray', cursor: 'pointer' }} />
				icon on any video to add them.
			</Typography>
		</Grid> : <ScrollRow setIsScrolling={setIsScrolling}>
			{favList?.map((plan, planIndex: number) =>
				<Box key={planIndex + '-plan-favorites'} sx={{ pr: '5px' }} >
					<RSCardCont isLoading={hasLoaded && !isLoading ? false : true} disabled={isScrolling || isLoading} sx={{ height: '120px', width: '200px', borderRadius: '15px', p: 0 }}
						onClick={() => handleNavigateToVideo(plan?.url, Number(plan?.id), plan?.program_id, plan?.intro_status, plan?.program_has_intro)}
					>
						<img src={plan.image} style={{ scale: '1.1', margin: 'auto' }} />
						<Box sx={{ position: 'absolute', p: '5px 10px', bottom: 0, left: 0, background: 'conic-gradient(#ffffff6b, #ffffffdb, white, #ffffff, #ffffff6b)', borderRadius: '0px 15px' }}>
							<Box sx={{ fontFamily: 'Poppins-Medium', fontSize: '12px !important', lineHeight: '1.2em', width: 'fit-content' }}>{plan.headingTitle}</Box>
							<Box sx={{ fontFamily: 'Poppins-Medium', fontSize: '12px !important', lineHeight: '1.2em', width: 'fit-content' }}>{plan.duration || ''}</Box>
						</Box>
					</RSCardCont>
				</Box>
			)}
		</ScrollRow>
	}

	return <Box id='favorites-home' sx={{ pt: 3 }}>
		<Grid container xs={12} sx={{ fontFamily: 'Poppins-Bold', fontSize: '20px', pl: 2 }}>
			Favorites
		</Grid>
		<Grid container xs={12} sx={{ pt: 1 }}>
			{renderFavorites()}
			{renderExercisePlayer()}
		</Grid>
	</Box>
}

export default FavoritesHome;
