import Box from "@mui/material/Box"
import RSButton from "../../components/buttons/RSButton"
import Grid from "@mui/material/Grid"
import RSFlexCard from "../../components/cards/RSFlexCard"

import { useNavigate } from 'react-router-dom'
import useSWR from "swr"
import { FC, useEffect, useState } from "react"
import { getActivePlan, saveSuggestedPrograms } from "../../modules/programActions"
import { ActiveProgram, Program } from "../../api/v2/programs/programs.types"
import useQueryParams from "../../hooks/useQueryParams"
import useIsMobileScreen from '../../hooks/useIsMobileScreen'
import ProgramMetadata from "./v2/ProgramMetadata"
import { AuthPageProps } from "../../modules/types"
import WithAuth from "../../components/WithAuth"
import suggestedExercisesList from '../../pages/onboarding/suggested-exercises.json'
import lodash from "lodash"
import useFavorites from "../favorites/useFavorites"
import useStore from "../../store/useStore"
import { ITrainingPlanStore } from "../../store/createTrainingPlanStore"

export type SuggestedProgramsProps = {
    done?: any,
    bodyData?: any,
    setBodyData?: any,
    nextAvailability?: any
    setAllowSkip?: any
}

const SuggestedPrograms: FC<SuggestedProgramsProps & AuthPageProps> = ({ bodyData, nextAvailability, userInfo, setAllowSkip }) => {
    const isMobile = useIsMobileScreen();
    const navigate = useNavigate()
    const { obDisplay } = useQueryParams()
    const { setIsOnboardingSelected } = useStore((state: ITrainingPlanStore) => state)

    const [textQuery, setTextQuery] = useState<string>()
    const [suggestedProgramsList, setSuggestedProgramsList] = useState<any[]>([])
    const [selectedPrograms, setSelectedPrograms] = useState<any>([])
    const [obDisplayPrograms, setobDisplayPrograms] = useState<number>(parseInt(obDisplay) || 5)
    const [currentProgram, setCurrentProgram] = useState<Program>()

    const [isSavingSuggested, setIsSavingSuggested] = useState(false)

    const { data: programs, isLoading, error } = useSWR(textQuery ? `/program-embeddings/search?searchQuery=${textQuery}` : undefined, { revalidateOnFocus: false, revalidateOnReconnect: false })


    const twoPerRow = window.innerWidth > 799 ? 2 : 1
    const containerHeight = (Math.round(obDisplayPrograms / twoPerRow)) * 96

    const { toggleFavorite: toggleFavoriteExercises } = useFavorites(userInfo?.account_id as number, undefined, "exercises", true)

    const processSaveSuggestedPrograms = async (userInfo: any, programs: Array<number>) => {
        if (programs && programs?.length > 0 && userInfo?.account_id) {
            await saveSuggestedPrograms(userInfo?.account_id, programs)
        }
    }

    useEffect(() => {
        constructTextQuery()
        checkActivePrograms(bodyData?.id)

        if (userInfo && userInfo?.account_id)
            getSuggestetExercises()

    }, [bodyData, userInfo])

    useEffect(() => {
        if (selectedPrograms && selectedPrograms.length > 0) {
            nextAvailability(true)
            // setAllowSkip && setAllowSkip(false)
        }
        else {
            nextAvailability(false)
            setAllowSkip && setAllowSkip(true)
        }
    }, [selectedPrograms])

    useEffect(() => {
        return () => {
            setAllowSkip && setAllowSkip(false)
        }
    }, [])

    useEffect(() => {
        if (selectedPrograms && selectedPrograms.includes(5)) setIsOnboardingSelected(true)
    }, [selectedPrograms])

    useEffect(() => {
        const selectedProgs: any = []
        const notSelectedPrograms: any = []
        if (programs?.data?.result && programs?.data?.result.length > 0 && !isLoading && !error) {
            programs?.data?.result?.forEach((prog: any) => {
                if (selectedPrograms.includes(prog.program.program_id))
                    selectedProgs.push(prog)
                else
                    notSelectedPrograms.push(prog)
            })
            const newProgramsList = [...selectedProgs.sort((a: any, b: any) => b.similarity - a.similarity), ...notSelectedPrograms.sort((a: any, b: any) => b.similarity - a.similarity)]

            setSuggestedProgramsList(newProgramsList)
        }
    }, [selectedPrograms, isLoading, programs, error])


    useEffect(() => {
        if (programs?.data?.result && programs?.data?.result.length > 0 && !isLoading && !error) {
            setIsSavingSuggested(true)
            const programsToSave = programs?.data?.result.map((x: any) => x.program.program_id)
            processSaveSuggestedPrograms(userInfo, programsToSave).then(() => {
                setIsSavingSuggested(false)
            }).catch(() => {
                setIsSavingSuggested(false)
            })
        }
    }, [programs, userInfo, isLoading])


    useEffect(() => {
        if (obDisplay) setobDisplayPrograms(parseInt(obDisplay))
    }, [obDisplayPrograms])

    const checkActivePrograms = async (accountId?: number) => {
        if (accountId) {
            const activeProgramsData = await getActivePlan(accountId)
            const activePlanList: ActiveProgram[] = activeProgramsData.data.result
            const activeProgramIds = []

            for (const plan of activePlanList) {
                activeProgramIds.push(plan.program_id)
            }

            setSelectedPrograms(activeProgramIds)
        }
    }

    const getSuggestetExercises = async () => {
        let exercisesIds: number[] = []
        const ansArray = bodyData?.problemAreas.map((problem: any) => problem.index + 1)
        const ansLength = ansArray?.length
        if (ansLength) {
            ansArray.forEach((answer: number) => {
                suggestedExercisesList?.find((x: any) => x.answer == answer)?.exercises.forEach((id: number) => {
                    exercisesIds.push(id)
                })
            })
        }
        exercisesIds = lodash.uniq(exercisesIds)

        for (const id of exercisesIds) {
            await toggleFavoriteExercises(userInfo?.account_id as number, Number(id), true)
        }
    }

    const constructTextQuery = () => {

        if (bodyData == undefined || bodyData == '') return

        let textQuery = ''

        if (bodyData?.goals?.length) {
            bodyData.goals.map((goal: any) => {
                textQuery += 'My goal is to ' + goal.title.details + '. '
            })
        }

        if (bodyData?.runningActivity) {
            textQuery += 'I usually run ' + bodyData.runningActivity.titleKM + '. '
        }

        if (bodyData?.physicalActivity) {
            textQuery += 'I have ' + bodyData.physicalActivity.title + '. '
        }

        if (bodyData?.problemAreas?.length) {
            bodyData.problemAreas.map((problem: any) => {
                textQuery += 'I have a problem in my ' + problem.title + '. '
            })
        }

        if (bodyData?.runningStyle) {
            textQuery += 'My running style is ' + bodyData.runningStyle.title + '. '
        }

        if (bodyData?.dietaryPreferences) {
            textQuery += 'I prefer my diet to be ' + bodyData.dietaryPreferences + '. '
        }

        setTextQuery(textQuery)

    }

    const renderSuggestedProgramsList = () => {
        return suggestedProgramsList?.slice(0, isMobile ? suggestedProgramsList.length : obDisplayPrograms)?.filter(plan => plan?.program?.metadata).map((plan, planIndex: number) => {
            let highlightProgram = false
            if (selectedPrograms.includes(plan.program.program_id)) highlightProgram = true;
            return <>
                <RSButton card customized disabled={isLoading || isSavingSuggested} secondary={!highlightProgram} checked={highlightProgram} onClick={() => {
                    setCurrentProgram(undefined)
                    setCurrentProgram(plan.program)
                }} key={planIndex + '-plan'} sx={{ maxWidth: '450px', height: '80px', p: 0, overflow: 'hidden', width: '100%', ml: '-5px' }}>
                    <Box sx={{ height: '100%', width: '100%', display: 'flex', justifyContent: 'flex-start', alignItems: 'center', minHeight: '80px' }}>
                        <img src={plan?.program.banner ? plan?.program.banner : plan?.program.image} style={{ position: 'absolute', margin: 'auto', backgroundPosition: 'center center', objectFit: 'cover', backgroundSize: 'cover', height: '100%' }} />
                        <Grid container xs={9} sx={{ height: '100%', width: '100%', position: 'relative', overflow: 'hidden' }}>
                            <Grid container xs={12} sx={{ pl: '30px', pr: '24px', flexDirection: 'column' }} justifyContent='center' alignItems='flex-start'>
                                <Box sx={{ fontSize: '16px', fontFamily: 'Poppins-Medium', lineHeight: '1.2em', textAlign: 'left', justifyContent: 'center', color: 'white' }}>
                                    {plan?.program.logo ? <img src={plan?.program.logo} style={{ height: '60px', width: 'auto' }} /> : (plan?.program.program_title || '')}
                                </Box>
                            </Grid>
                        </Grid>
                    </Box>
                </RSButton>
            </>
        })
    }


    return (
        <>
            <h3>Welcome to RunSmart, {bodyData?.firstName ? bodyData?.firstName + '!' : ''}</h3>

            <p>Based on your running history and goals, here&apos;s what we recommend.<br /> <b>Add at least one program to get started.</b></p>

            <Grid container xs={12} sx={{ mr: '-9px' }} >
                <RSFlexCard className='transition-height' videoList={renderSuggestedProgramsList()} sx={{ pt: 0, pb: '5px', my: 0, height: isMobile ? `${containerHeight}px` : '100%', overflow: 'hidden' }} />
            </Grid>

            <Grid container xs={12} sx={{ pb: 3 }}>
                {suggestedProgramsList?.length !== obDisplayPrograms && <button style={{ fontSize: '1rem', margin: '0.5rem 0', padding: '0.2rem', border: 'none', backgroundColor: 'transparent' }} onClick={() => {
                    const moreObDP = obDisplayPrograms + 3
                    const newObDisplay = moreObDP > suggestedProgramsList?.length ? suggestedProgramsList.length : moreObDP
                    setobDisplayPrograms(newObDisplay)
                    navigate('?obDisplay=' + (newObDisplay))
                }}>View More {`>`}</button>}
            </Grid>

            <ProgramMetadata onboarding={currentProgram?.program_id || !userInfo?.plan_code} currentProgram={currentProgram} userInfo={userInfo} programID={currentProgram?.program_id} isRedirect={true} setIsRedirect={() => null} currentTab={0} setCurrentTab={() => null} setProgramID={() => {
                setCurrentProgram(undefined)
                checkActivePrograms(userInfo?.account_id)
            }} />
        </>
    )
}

export default WithAuth(SuggestedPrograms)
