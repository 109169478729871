import React, { FC } from 'react'

const AnswerSmall: FC<{ selected: boolean, option: string, setAnswer?: () => any }> = ({ selected = false, setAnswer, option, }) => {
    return (
        <div className='recover-div'>
            <div onClick={setAnswer} className={`${selected ? 'answer-small selected-small' : 'answer-small'}`} >
                <p>{option}</p>
            </div>
        </div>
    )
}

export default AnswerSmall