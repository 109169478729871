/* eslint-disable @typescript-eslint/no-unused-vars */
import { Avatar, Badge, Box, styled } from '@mui/material'
import { FC } from 'react'
import { API_URL } from '../../modules/cliEnvValues'

interface UploadImageProps {
    src?: string
    refreshImageKey?: number
    openForm?: Function
    firstName?: string,
    lastName?: string
}

const StyledBadge = styled(Badge)(({ theme }) => ({
    '& .MuiBadge-badge': {
        backgroundColor: '#44b700',
        color: '#44b700',
        boxShadow: `0 0 0 2px ${theme.palette.background.paper}`,
        '&::after': {
            position: 'absolute',
            top: 0,
            left: 0,
            width: '100%',
            height: '100%',
            borderRadius: '50%',
            animation: 'ripple 1.2s infinite ease-in-out',
            border: '1px solid currentColor',
            content: '""',
        },
    },
    '@keyframes ripple': {
        '0%': {
            transform: 'scale(.8)',
            opacity: 1,
        },
        '100%': {
            transform: 'scale(2.4)',
            opacity: 0,
        },
    },
}));

const ProfileImage: FC<UploadImageProps> = ({ src, refreshImageKey, openForm, firstName, lastName }) => {

    const getNameAbbreviation = (firstName?: string, lastName?: string) => {
        if (!firstName || !lastName)
            return ''
        return firstName.charAt(0).toUpperCase() + lastName.charAt(0).toUpperCase()
    }

    return (
        <>
            {src ? (
                <Avatar onClick={() => openForm && openForm()} sx={{ height: '125px', width: '125px', mx: 'auto', bgColor: '#FFFFFF' }} alt={firstName + " " + lastName} src={API_URL + src + "?key=" + refreshImageKey} />
            ) : (
                <Avatar onClick={() => openForm && openForm()} sx={{ height: '125px', width: '125px', borderRadius: '50%', mx: 'auto', bgcolor: '#9fcd2b', fontSize: "2rem" }}>{getNameAbbreviation(firstName, lastName)}</Avatar>
            )}
        </>
    )
}

export default ProfileImage