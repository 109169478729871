/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { FC, useEffect, useState, useRef } from 'react'
import { Col, Row } from 'react-bootstrap'
import { useNavigate } from 'react-router-dom'
import BackButton from '../../components/buttons/BackButton'
import MainCard from '../../components/cards/MainCard'
import TextCard from '../../components/cards/TextCard'
import AppMenus, { BodyComponent } from '../../components/layout/AppMenus'
import Line from '../../components/page-specific/video/Line'
import PageTitle from '../../components/text/PageTitle'
import ArrowBack from '@mui/icons-material/ArrowBackIos'
import ArrowForward from '@mui/icons-material/ArrowForwardIos'
import { backNav } from '../../modules/apiConsume'
import useQueryParams from '../../hooks/useQueryParams'
import { PrimaryButton } from '../../components/buttons/AuthSubmitButtons'
import WithAuth from '../../components/WithAuth'
import { AuthPageProps } from '../../modules/types'
import resetActions from './resetActions'
import { Reset } from '../../api/v2/reset/reset.types'
import SelectPill from '../../components/input/SelectPill'
import GenericModal from '../../components/misc/GenericModal'
import moment from 'moment'
import dailyVideos from './reset-daily-videos.json'
import dailyInfos from './info.json'
import carryVideoInfo from './video-carry-info.json'
import useFavorites from '../favorites/useFavorites'
import useStore from '../../store/useStore'
import { IComponentStore } from '../../store/createComponentStore'
import { guideDocuments } from '../../modules/values'
import { DownloadModal } from '../guides/Guide'
import useAlert from '../../hooks/useAlert'
import lodash from 'lodash'
import { Video } from '../../modules/videos/allVideos'
import GenericAlert from '../../components/misc/GenericAlert'
import Motion from '../../components/animation/Motion'

import { slideInRight, slideOutRight, slideInLeft, slideOutLeft } from 'react-animations'
import styled, { keyframes } from 'styled-components';
import useIsMobileScreen from '../../hooks/useIsMobileScreen'

const CONFIRM_BUTTONS = ['Yes, continue', 'No, cancel']
interface ICurrentVideos {
	day: string,
	title: string,
	link: string
}

const AslideInLeft = keyframes`${slideInLeft}`;
const AslideInRight = keyframes`${slideInRight}`;

const AslideOutLeft = keyframes`${slideOutLeft}`;
const AslideOutRight = keyframes`${slideOutRight}`;

const DivInLeft = styled.div` animation: 0.3s ${AslideInLeft};`;
const DivInRight = styled.div` animation: 0.3s ${AslideInRight};`;

const DivOutLeft = styled.div` animation: 0.3s ${AslideOutLeft};`;
const DivOutRight = styled.div` animation: 0.3s ${AslideOutRight};`;

const ResetInfoPage: FC<AuthPageProps> = ({ userInfo }) => {

	const { setResetCurrentDay } = useStore((state: IComponentStore) => state)
	const { getActiveReset, loading, createResetData, restartReset, getDailyReset, createResetDaily, updateResetDaily, getDaysCompleted } = resetActions()

	const PROGRESS = 0 as number;

	const mobileScreen = useIsMobileScreen()

	const { play, start, videoId } = useQueryParams()
	const [isPlaying, setIsPlaying] = useState(false)
	const [showPlayer, setShowPlayer] = useState(false)
	const [isCompleted, setIsCompleted] = useState(false)
	const [hasActiveTraining, setHasActiveTraining] = useState(false)
	const [resetData, setResetData] = useState<Reset | undefined>()
	const PILL_OPTIONS = ['Introduction', 'Training']
	const [selectedPill, setSelectedPill] = useState('Introduction')
	const [pillKey, setPillKey] = useState(0)
	const [currentDay, setCurrentDay] = useState(0)
	const [currentDate, setCurrentDate] = useState<any>(moment(new Date, "YYYY-MM-DD"))
	const [currentVideos, setCurrentVideos] = useState<ICurrentVideos[]>([])
	const [dailyReset, setDailyReset] = useState<Array<any>>([])
	const { favorites, toggleFavorite } = useFavorites(userInfo?.account_id as number, selectedPill, "exercises", true)
	// email state
	const [modalOpen, setModalOpen] = useState(false)
	const [modalTitle, setModalTitle] = useState('')
	const [todayVideo, setTodayVideo] = useState<ICurrentVideos | undefined>()
	const [completedPercent, setCompletedPercent] = useState(0)
	const navigate = useNavigate()

	const slideDirection = useRef({ slide: localStorage.getItem('page') === 'ep' ? '' : 'left' })

	const {
		showAlert,
		onAlertOpen,
		onAlertClose,
		alertTitle,
		alertMessage,
		setAlertMessage,
		alertVariant,
	} = useAlert()

	// Get File
	const getFile = () => {
		const key = modalTitle.replace('Guide', '').replace(/[-/]/g, ' ').trim()
		return guideDocuments[key as keyof typeof guideDocuments]
	}

	// Get Email ID
	const getEmailId = () =>
		modalTitle.toLowerCase().includes('strength') ? 78 : 76

	const handleOnPlay = async () => {
		setIsPlaying(true)
	}

	// Download Click
	const onDownloadClick = () => {
		setModalTitle(`RunSmart Reset Guide`)
		setModalOpen(true)
	}

	// Get Guide Type
	const getGuideType = () => {
		const lowerTitle = modalTitle.toLowerCase()
		return lowerTitle.includes('5k')
			? '5k/10k'
			: lowerTitle.includes('half')
				? 'Half Marathon'
				: lowerTitle.startsWith('marathon')
					? 'Marathon'
					: 'RunSmart Reset'
	}

	const getCompletedPercent = async () => {
		const daysCompleted = await getDaysCompleted(userInfo?.account_id)
		setCompletedPercent(Math.round((daysCompleted / 30) * 100))
	}

	const getCarryVideos = () => {
		const allVideos: any = [];
		const carriedFavorites: Video[] = []
		const currentVideos: ICurrentVideos[] = []
		const days = carryVideoInfo.find(x => x.day == currentDay)
		days?.lookUpDays.forEach(day => {
			const videos = dailyVideos.filter(x => x.day === String(day))
			videos.forEach(video => {
				allVideos.push(video?.link.split('/')[3])
			})
		});

		const uniqVideos = lodash.uniq(allVideos) as string[];

		uniqVideos.forEach((videoId: string) => {
			const fav = favorites.find(x => x.id === videoId)
			if (fav)
				carriedFavorites.push(fav as Video)
		})

		carriedFavorites.forEach(fav => {
			const data: ICurrentVideos = {
				day: String(currentDay),
				title: fav.headingTitle,
				link: `/#/exercises/` + fav.id
			}

			currentVideos.push(data)
		})

		setCurrentVideos((prev) => [...prev, ...currentVideos])
	}

	const getDayInfo = (day: number) => {
		const coolDownInfo = dailyInfos.coolDown.days.find(x => x == day)
		const warmUpInfo = dailyInfos.warmUp.days.find(x => x == day)
		if (warmUpInfo)
			return dailyInfos.warmUp.text
		else if (coolDownInfo)
			return dailyInfos.coolDown.text
		else
			return "This is what's on tap for today."
	}

	useEffect(() => {
		setShowPlayer(play === "1" ? true : false)
	}, [play])

	useEffect(() => {
		setShowPlayer(selectedPill === "Introduction" && play === "1" || selectedPill === "Training" && videoId ? true : false)
		// setCurrentDate(moment(new Date, "YYYY-MM-DD"))
	}, [selectedPill])

	const loadResetData = async () => {
		const data = await getActiveReset(userInfo?.account_id) as Reset[]
		setResetData(data[0] ? data[0] : undefined)
	}

	useEffect(() => {
		!resetData && userInfo?.account_id ? loadResetData() : undefined
	}, [userInfo])

	const onPillChange = (value: any) => {
		setIsPlaying(false)
		setSelectedPill(value)
		setPillKey(value === "Introduction" ? 0 : 1)
	}

	const handleStartTraining = async () => {
		if (resetData) {
			setHasActiveTraining(true)
		}
		else {
			await createResetData(userInfo?.account_id)
			await loadResetData()
			setSelectedPill("Training")
			setPillKey(1)
		}
	}

	const handleMarkAsCompleted = async () => {
		const day = dailyReset.find(x => x.day === currentDay)
		if (!day)
			await createResetDaily(resetData?.id as number, currentDate, currentDay)
		else {
			if (day.completed == 1) {
				await updateResetDaily(resetData?.id as number, 0, currentDay)
			}
			else {
				await updateResetDaily(resetData?.id as number, 1, currentDay)
			}
		}
		await getDailyResetData()
		// await loadResetData()
	}


	// TO-DO: On Like
	const onLike = async (videoId: string) => {
		const isLiked = favorites && favorites.find((x: any) => x.id === videoId) ? true : false;
		await toggleFavorite(userInfo?.account_id as number, Number(videoId), !isLiked)
	}

	const handleContinueButton = async () => {
		if (resetData) {
			setCurrentDate(moment(new Date(), "YYYY-MM-DD"))
			setCurrentDay(1)
			await restartReset(userInfo?.account_id, resetData.id)
			await loadResetData()
			setSelectedPill("Training")
			setHasActiveTraining(false)
			setPillKey(1)
		}
	}

	const handleCancelButton = () => {
		setHasActiveTraining(false)
	}

	const handleClickForward = () => {
		if (resetData && currentDay < 30) {
			const newDate = moment(currentDate, "YYYY-MM-DD").add(1, 'day')
			setCurrentDate(newDate)
			setCurrentDay(getCurrentDay(resetData?.date_started, newDate))
		}
		else if (resetData && currentDay == 30) {
			const newDate = moment(currentDate, "YYYY-MM-DD").subtract(29, 'day')
			setCurrentDate(newDate)
			setCurrentDay(getCurrentDay(resetData?.date_started, newDate))
		}
	}

	const handleClickBackward = () => {
		if (resetData && currentDay > 1 && currentDay <= 30) {
			const newDate = moment(currentDate, "YYYY-MM-DD").subtract(1, 'day')
			setCurrentDate(newDate)
			setCurrentDay(getCurrentDay(resetData?.date_started, newDate))
		}
		else if (resetData && currentDay == 1) {
			const newDate = moment(currentDate, "YYYY-MM-DD").add(29, 'day')
			setCurrentDate(newDate)
			setCurrentDay(getCurrentDay(resetData?.date_started, newDate))
		}
	}

	const getDailyResetData = async () => {
		const dailyReset = await getDailyReset(resetData?.id as number)
		setDailyReset(dailyReset)
	}

	const setDayVideo = (day: number) => {
		setIsPlaying(false)
		const videos = dailyVideos.filter((x) => x.day === String(day))
		setTodayVideo(videos[0])
	}

	const setNonExerciseVideo = () => {
		const selectedVideo = dailyVideos.find((x) => x.link === String(videoId))
		setTodayVideo(selectedVideo)
	}

	const getVideoLink = (link: string) => {
		if (link && link.includes('exercises')) {
			return link;
		}
		else {
			return '/#/runsmart-reset/info?play=1&start=1&videoId=' + link;
		}
	}

	const getVideoId = (link: string) => {
		if (link && link.includes('exercises')) {
			const id = link.split("/")
			return id[3];
		}
		else {
			return link;
		}
	}

	const getCurrentDay = (startDate: Date, currentDate: Date | any) => {
		// console.log(startDate, currentDate)
		const days = currentDate.diff(startDate, 'days')
		const start = moment(startDate, "YYYY-MM-DD");
		let current = moment(currentDate, "YYYY-MM-DD");
		const diff = moment.duration(current.diff(start)).asDays() + 1;
		let day = Math.floor(diff)

		if (days >= 30) {
			current = start.add(29, 'days')
			day = 30
		}

		return day
	}

	useEffect(() => {
		if (resetData) {
			getDailyResetData()
			setCurrentDay(getCurrentDay(resetData?.date_started, moment(new Date())))
			// setCurrentDate(moment(new Date, "YYYY-MM-DD"))
			setSelectedPill("Training")
			setPillKey(1)
		}
	}, [resetData])

	useEffect(() => {
		setCurrentVideos(dailyVideos.filter(x => x.day === String(currentDay)))
		getCarryVideos()
	}, [currentDay])

	useEffect(() => {
		if (selectedPill === "Training")
			setDayVideo(currentDay)
		else
			setTodayVideo(undefined)
	}, [selectedPill])

	useEffect(() => {
		const days = currentDate.diff(resetData?.date_started, 'days')

		const start = moment(resetData?.date_started, "YYYY-MM-DD");

		setResetCurrentDay(currentDay)
		setCurrentDate(days >= 30 ? start.add(29, 'days') : currentDate)
		setDayVideo(currentDay)

		const day = dailyReset.find(x => x.day === currentDay)

		if (day?.completed == 1)
			setIsCompleted(true)
		else
			setIsCompleted(false)

	}, [dailyReset, currentDay, resetData])

	useEffect(() => {
		navigate('/runsmart-reset/info?start=1', { replace: true })
	}, [currentDay, resetData])

	useEffect(() => {
		if (userInfo?.account_id)
			getCompletedPercent()
	}, [dailyReset])

	useEffect(() => {
		if (videoId) {
			setNonExerciseVideo()
			setIsPlaying(false)
		}
		else {
			setTodayVideo(undefined)
			setIsPlaying(false)
		}

	}, [videoId])

	useEffect(() => {
		slideDirection.current.slide = '';
		localStorage.setItem('page', 'pw')
	}, [])

	const PageContainer = () => {
		return (
			<>
				<BodyComponent>
					{/* Alert */}
					<GenericAlert
						show={showAlert}
						title={alertTitle}
						message={alertMessage}
						variant={alertVariant}
						onClose={onAlertClose}
					/>


					<GenericModal
						show={hasActiveTraining}
						title="Are you sure?"
						text={"You want to restart your reset training?"}
						setShow={setHasActiveTraining}
						keyboard={false}
						backdrop="static"
						button1Action={handleContinueButton}
						button1Text={loading ? 'Loading...' : CONFIRM_BUTTONS[0]}
						button2Action={handleCancelButton}
						button2Text={CONFIRM_BUTTONS[1]}
					/>

					<Row className='d-none d-md-block'>
						<Col md={1}>
							<span className="has-pointer back-btn w-auto pt-1" onClick={backNav}>
								<ArrowBack />
							</span>
						</Col>
						<Col md={3}>
							<h3 style={{ cursor: 'pointer', marginTop: '5px' }} onClick={backNav} className='mx-5'>RunSmart Reset</h3>
						</Col>
					</Row>
					<Row className='mt-md-4'>
						<Col className={`${showPlayer ? 'd-block mb-n3 mt-n4' : 'd-none d-md-block mt-n3'}`} lg={9} sm={12}>
							<Row className="get-started-training-plan justify-content-center mb-md-2 mb-3">
								<Col className="px-0 px-sm-3">
									<div className="video-wrapper w-100 blue-bg">
										<MainCard
											playButtonIsCentered
											type="responsiveCardLg"
											progress={0}
											bgImage={todayVideo && !todayVideo.link.includes("exercises") ? `https://vumbnail.com/${getVideoId(todayVideo.link)}.jpg` : '/images/icons/reset-cover.jpeg'}
											vimeoId={todayVideo && !todayVideo.link.includes("exercises") ? getVideoId(todayVideo.link) : !todayVideo && play !== "1" ? undefined : 783135874}
											onPlay={handleOnPlay}
											hasCheck
											isPlaying={isPlaying}
										/>
										{!isPlaying || showPlayer && !isPlaying ? (
											<div className="banner-info">
												<h3 className="text-white fw-normal text-capitalize">
													{todayVideo && !todayVideo.link.includes("exercises") ? todayVideo.title : 'Introduction'}
												</h3>
												{/* <h4 className="text-white text-capitalize">
													{currentWorkoutVideo?.headingTitle}
												</h4> */}
											</div>
										) : null}

										{!isPlaying || showPlayer && !isPlaying ? (
											<div className="tick-mark">
												<img
													src={`${PROGRESS == 100 ? './images/icons/checkmark-done.svg' : './images/icons/checkmark.svg'}`}
													alt="Like"
												/>
											</div>
										) : undefined}

									</div>
								</Col>
							</Row>
						</Col>
						<Col className='d-block d-md-none mb-2' lg={9} sm={12}>
							<Row className={`mb-2 mb-md-4 active-training-container`}>
								<Col>
									{/* ${videoId ? 'd-none' : ''} */}
									{!videoId ? (
										<SelectPill
											index={pillKey}
											key={pillKey}
											options={PILL_OPTIONS}
											onChange={onPillChange}
											disabled={!resetData && start !== "1"}
										/>
									) : undefined}

									{resetData && selectedPill === "Training" ? (
										<div className='mx-0 mb-n3'>
											{!videoId ? (<div className='reset-day-selector mt-4'>
												<span>
													<ArrowBack onClick={handleClickBackward} />
												</span>
												<div>
													<h4>DAY {currentDay}</h4>
													<h5 className='mt-n2'>{moment(currentDate).format("MMMM D, YYYY")}</h5>
												</div>
												<span onClick={handleClickForward}>
													<ArrowForward />
												</span>
											</div>) : undefined}

											<h6 className='text-center px-3 mt-4 mb-4' dangerouslySetInnerHTML={{
												__html: getDayInfo(currentDay)
											}} />
											<div className="check-complete mt-n3 mb-0">
												<input
													// disabled={!loading}
													type="checkbox"
													id="dayCheck"
													hidden
													checked={isCompleted}
													disabled={loading}
													onChange={handleMarkAsCompleted}
												/>
												<label htmlFor="dayCheck">
													<div className="uncheck-icon">
														<svg
															xmlns="http://www.w3.org/2000/svg"
															viewBox="0 0 448 512"
														>
															<path d="M438.6 105.4C451.1 117.9 451.1 138.1 438.6 150.6L182.6 406.6C170.1 419.1 149.9 419.1 137.4 406.6L9.372 278.6C-3.124 266.1-3.124 245.9 9.372 233.4C21.87 220.9 42.13 220.9 54.63 233.4L159.1 338.7L393.4 105.4C405.9 92.88 426.1 92.88 438.6 105.4H438.6z" />
														</svg>
													</div>
													<div className="checked-icon">
														<svg
															xmlns="http://www.w3.org/2000/svg"
															viewBox="0 0 448 512"
														>
															<path d="M438.6 105.4C451.1 117.9 451.1 138.1 438.6 150.6L182.6 406.6C170.1 419.1 149.9 419.1 137.4 406.6L9.372 278.6C-3.124 266.1-3.124 245.9 9.372 233.4C21.87 220.9 42.13 220.9 54.63 233.4L159.1 338.7L393.4 105.4C405.9 92.88 426.1 92.88 438.6 105.4H438.6z" />
														</svg>
													</div>
													<span className="check-complete mx-2 mt-3">
														{/* {isLoadingWoComplete ? 'Saving...' : 'Complete'} */}
														{loading ? 'LOADING...' : 'MARK AS COMPLETE'}
													</span>
												</label>
											</div>
										</div>
									) : undefined}
								</Col>
							</Row>
							{selectedPill === "Introduction" && resetData && !videoId ? (
								<Row className='d-block d-md-none'>
									<div className='mt-0 reset-completed'>
										<p>{completedPercent}% Completed</p>
									</div>
								</Row>
							) : undefined}

							{selectedPill === "Introduction" && play !== "1" ? (
								<Row className="justify-content-center mb-n2">
									<Col className="justify-content-center phone-video-list">
										<MainCard
											videoId={"783135874"}
											cardType="video"
											type="responsiveCardLg"
											hasCheck={false}
											progress={0}
											vimeoId={783135874}
											logoImage={'/images/icons/runsmart-reset.jpg'}
											detailTitle={'RunSmart Reset'}
											detailSubtitle={"Introduction"}
											hasLikeButton={false}
											hasPlayButton={false}
											onLike={() => null}
											link={`/#/runsmart-reset/info?play=1${start ? '&start=' + start + '&videoId=783135874' : ''}`}
											isProgram={true}
										// onPlay={handleOnPlayFull}
										// isPlaying={isPlayingFull}
										// isFullScreen={isPlayingFull}
										/>

									</Col>
								</Row>
							) : undefined}
						</Col>

						<Col lg={3} sm={12}>
							<Row className="d-none d-md-block mb-2 mb-md-4 active-training-container">
								<Col>
									<SelectPill
										index={pillKey}
										key={pillKey}
										options={PILL_OPTIONS}
										onChange={onPillChange}
										disabled={!resetData && start !== "1"}
									/>
									{resetData && selectedPill === "Training" ? (
										<div className='mx-3 mb-n3 mt-0'>
											<div className='reset-day-selector mt-4'>
												<span>
													<ArrowBack onClick={handleClickBackward} />
												</span>
												<div>
													<h4>DAY {currentDay}</h4>
													<h5 className='mt-n2'>{moment(currentDate).format("MMMM D, YYYY")}</h5>
												</div>
												<span onClick={handleClickForward}>
													<ArrowForward />
												</span>
											</div>

											<h6 className='text-center mt-4 mb-4' dangerouslySetInnerHTML={{
												__html: getDayInfo(currentDay)
											}} />

											<div className="check-complete mb-n5">
												<input
													// disabled={!loading}
													type="checkbox"
													id="dayCheck"
													hidden
													disabled={loading}
													checked={isCompleted}
													onChange={handleMarkAsCompleted}
												/>
												<label htmlFor="dayCheck">
													<div className="uncheck-icon">
														<svg
															xmlns="http://www.w3.org/2000/svg"
															viewBox="0 0 448 512"
														>
															<path d="M438.6 105.4C451.1 117.9 451.1 138.1 438.6 150.6L182.6 406.6C170.1 419.1 149.9 419.1 137.4 406.6L9.372 278.6C-3.124 266.1-3.124 245.9 9.372 233.4C21.87 220.9 42.13 220.9 54.63 233.4L159.1 338.7L393.4 105.4C405.9 92.88 426.1 92.88 438.6 105.4H438.6z" />
														</svg>
													</div>
													<div className="checked-icon">
														<svg
															xmlns="http://www.w3.org/2000/svg"
															viewBox="0 0 448 512"
														>
															<path d="M438.6 105.4C451.1 117.9 451.1 138.1 438.6 150.6L182.6 406.6C170.1 419.1 149.9 419.1 137.4 406.6L9.372 278.6C-3.124 266.1-3.124 245.9 9.372 233.4C21.87 220.9 42.13 220.9 54.63 233.4L159.1 338.7L393.4 105.4C405.9 92.88 426.1 92.88 438.6 105.4H438.6z" />
														</svg>
													</div>
													<span className="check-complete mx-2">
														{/* {isLoadingWoComplete ? 'Saving...' : 'Complete'} */}
														{loading ? 'LOADING...' : 'MARK AS COMPLETE'}
													</span>
												</label>
											</div>
										</div>
									) : undefined}
								</Col>
							</Row>

							<Row>
								{
									!resetData && selectedPill === "Training" ?
										(
											<div className='no-training-container text-center px-5'>
												<h3>Training not yet started</h3>
												<p>To start your RunSmart Reset Training, simply click the button below.</p>
												<PrimaryButton
													primaryText={resetData ? "Re-start Training" : "Start Training"}
													primaryAction={() => handleStartTraining()}
													primaryDisabled={loading}
												/>
											</div>
										) : undefined
								}
							</Row>
							<Row className='d-none d-md-block'>
								{selectedPill === "Introduction" && resetData ? (
									<div className='mt-0 reset-completed'>
										<p>{completedPercent}% Completed</p>
									</div>
								) : (<span className='blank-span'></span>)}
							</Row>

							{
								selectedPill === "Introduction" ? (
									<TextCard
										title='30 Day Challenge'
										size={'md'}
										isCentered={false}
										autoHeight={true}
										className='p-0 mb-n2'
										smallTitle
										noBorder
									>
										<br />
										{start && start === "1" ? (
											<div className='mb-4'>
												<PrimaryButton
													primaryText={resetData ? "Re-start Training" : "Start Training"}
													primaryAction={() => handleStartTraining()}
													primaryDisabled={loading}
												/>
												<br />
												<a onClick={onDownloadClick} style={{ cursor: 'pointer', color: '#555' }}>Download Guide
													<img
														src="./images/icons/download-plan.svg"
														alt="Download Guide"
														style={{ width: '18px', marginLeft: '10px', marginBottom: '5px', opacity: 0.7 }}
													/>
												</a>
											</div>
										) : (<Line smallTitle title={'COMING JANUARY 1st'} text={``} />)}

										<Line smallTitle title={''} text={`Build consistency, strength, and mobility for a fantastic year of running with the RunSmart Reset 30 Day Challenge.`} />
										<br />

										<Line smallTitle title={''} text={`Learn how to build a running plan, whether you’re a seasoned runner or just getting started.`} />
										<br />

										<Line smallTitle title={''} text={`Participate in specially curated daily and weekly rotational exercises designed to strengthen your core.Use the next 30 days to reset your routine and run your best all year long and beyond.`} />
									</TextCard>
								) : undefined
							}
							{
								selectedPill === "Training" ? (
									<Col lg={12} sm={12} className='mt-md-2'>
										{currentVideos.map((video) => (
											<div key={video.link} className="justify-content-center phone-video-list mb-n2">
												<MainCard
													videoId={getVideoId(video.link)}
													key={getVideoId(video.link)}
													cardType="video"
													// type="videoLgStretch"
													hasCheck={false}
													progress={0}
													logoImage={`https://vumbnail.com/${getVideoId(video?.link)}.jpg`}
													bgImage={`https://vumbnail.com/${getVideoId(video?.link)}.jpg`}
													detailTitle={video.title}
													// detailSubtitle={current === "Exercises" ? video?.category?.toUpperCase() : video?.headingTitle?.toUpperCase()}
													link={getVideoLink(video.link)}
													onLike={() => onLike(getVideoId(video.link))}
													hasLikeButton={video.link.includes("exercises") ? true : false}
													favorites={favorites}
												/>
											</div>

										))}
									</Col>
								) : undefined
							}

						</Col>
					</Row>
					{/* Download Modal */}
					<DownloadModal
						title={modalTitle}
						show={modalOpen}
						setShow={setModalOpen}
						userInfo={userInfo}
						getEmailId={getEmailId}
						getGuideType={getGuideType}
						onAlertOpen={onAlertOpen}
						setAlertMessage={setAlertMessage}
						getFile={getFile}
					/>
				</BodyComponent>
			</>
		)
	}
	return (
		<Motion
			isVisible={true}
			isMobile={mobileScreen}
			direction={slideDirection.current}
		>
			<AppMenus hasContainer>
				<PageContainer />
			</AppMenus>
		</Motion>
	)
}

export default WithAuth(ResetInfoPage)
