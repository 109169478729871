/* eslint-disable @typescript-eslint/no-unused-vars */
import Col from 'react-bootstrap/Col'
import Row from 'react-bootstrap/Row'
import Form from 'react-bootstrap/Form'
import InputGroup from 'react-bootstrap/InputGroup'
import Button from 'react-bootstrap/Button'
import Visibility from '@mui/icons-material/Visibility'
import VisibilityOff from '@mui/icons-material/VisibilityOff'
import { allPages } from '../../modules/searchInfo'
import { Typeahead } from 'react-bootstrap-typeahead'
import { CardElement } from '@recurly/react-recurly'
import React, { FC, useState, useEffect } from 'react'
import classNames from 'classnames'
import { navReq } from '../../modules/apiConsume'
import { useLocation } from 'react-router-dom'
import { SearchOutlined } from '@mui/icons-material'
import { FloatingLabel } from 'react-bootstrap'

const ALL_PAGES = allPages.filter((x) =>
	!x?.url?.includes("exercises/stretching") &&
	!x?.url?.includes("exercises/run-form-drill") &&
	!x?.url?.includes("exercises/strength") &&
	!x?.url?.includes("exercises/foam-rolling") &&
	!x?.url?.includes("exercises/runsmart-101")
)

// Props
export type BasicInputProps = {
	id: string
	formKey?: string
	placeholder: string
	text?: string
	label?: string
	className?: string
	isSearchBar?: boolean
	required?: boolean
	isPassword?: boolean
	isCreditCard?: boolean
	isDate?: boolean
	options?: any | any[]
	validations?: ((value: string, field?: string) => string)[]
	recurlyData?: string
	isHorizontal?: boolean
	skipNewRow?: boolean
	setIsValid?: (x: boolean) => any
	setText?: (x: string) => any
	disabled?: boolean
	floatingLabel?: boolean
}

// Recurly Card
const RecurlyCard: FC<{ onChange: (e: any) => any }> = ({ onChange }) => (
	<CardElement
		id="recurly-cc"
		className="form-control form-control-lg billing-input"
		style={{ height: '24px', fontSize: '19px' } as any}
		onChange={onChange}
	/>
)

// Field Wrapper
const FieldWrapper: FC<{
	isColumn?: boolean
	columnSize?: number
	extraChildren?: React.ReactNode
}> = ({ isColumn, children, extraChildren, columnSize = 3 }) => (
	<>
		{isColumn ? (
			<>
				<Col
					className={classNames(
						'vertical-center-container',
						isColumn ? 'text-end' : undefined
					)}
					xs={columnSize}
				>
					{extraChildren ? (
						<Row>
							<Col>{children}</Col>
							<Col>{extraChildren}</Col>
						</Row>
					) : (
						<>{children}</>
					)}
				</Col>
			</>
		) : (
			<>{children}</>
		)}
	</>
)

/**
 * BasicInput Component
 */

const BasicInput: FC<BasicInputProps> = ({
	id,
	text,
	placeholder,
	label,
	className,
	isSearchBar,
	required,
	isPassword,
	isDate,
	options,
	validations,
	recurlyData,
	isCreditCard,
	isHorizontal,
	children,
	setIsValid,
	setText,
	disabled,
	floatingLabel
}) => {
	// Properties
	const md = isSearchBar ? 5 : undefined
	const xs = isSearchBar ? 12 : undefined
	const formClassName = classNames(
		isHorizontal ? 'vertical-center-container' : undefined,
		className
	)

	const formType = isPassword
		? 'password'
		: isDate
			? 'date'
			: options
				? 'select'
				: isCreditCard
					? 'credit-card'
					: 'text'

	// State
	const [currentValue, setCurrentValue] = useState(text || '')
	const [currentFocused, setCurrentFocused] = useState('')
	const [message, setMessage] = useState('')
	const [hasChange, setHasChange] = useState(false)
	const [showPassword, setShowPassword] = useState(false)
	const [curFormType, setCurFormType] = useState(formType)

	const url = useLocation();

	// Set Warning Message
	const setWarningMessage = (value: string) => {
		let newMessage = ''
		if (validations) {
			for (const validation of validations) {
				newMessage = validation(value, label)
				if (newMessage) break
			}
			if (setIsValid) setIsValid(newMessage ? false : true)
		}
		setMessage(newMessage)
		return newMessage
	}

	// On Change
	const onChange = (event: any) => {
		if (isSearchBar) {
			const url = event?.[0]?.url || ''
			if (url) navReq(url)
		} else {
			if (event.valid != null) {
				if (setIsValid) setIsValid(event.valid ? true : false)
			} else {
				const value = String(event?.target?.value) || ''
				if (!hasChange) setHasChange(true)
				setCurrentValue(value)
				setWarningMessage(value)
				if (setText) setText(value)
			}
		}
	}

	// Toggle Show Password
	const onToggleShowPassword = () => {
		const newShowPassword = !showPassword
		const newFormType = newShowPassword ? 'text' : 'password'
		setShowPassword(newShowPassword)
		setCurFormType(newFormType)
	}

	// On Search Filter
	const onSearchFilter = (option: any, state: any) => {
		if (state?.seelcted?.length) return true
		const textCombo = `${option.title} ${option.subtitle || ''}`
			.trim()
			.toLowerCase()
		return textCombo.indexOf(state?.text?.toLowerCase()) > -1
	}

	// Search Label
	const searchLabel = (option: any) =>
		option.subtitle ? `${option.title}: ${option.subtitle}` : option.title

	// Text Effect
	useEffect(() => {
		const newValue = text || ''
		setCurrentValue(newValue)
		setWarningMessage(newValue)
	}, [text])

	// Render
	return (
		<>
			{
				floatingLabel ? (
					isCreditCard ? (
						<RecurlyCard onChange={onChange} />
					) : (
						<FloatingLabel label={currentValue !== '' ? label : (!currentValue || currentValue === "") && currentFocused === placeholder ? label : currentValue === "" && (curFormType !== 'text') ? label : ''}>
							{curFormType !== 'select' && !isCreditCard ? (
								<Form.Control
									size="lg"
									value={currentValue}
									required={required}
									placeholder={currentFocused === placeholder ? '' : placeholder}
									type={curFormType}
									isInvalid={hasChange && message ? true : false}
									onChange={onChange}
									data-recurly={recurlyData || undefined}
									disabled={disabled}
									onClick={() => setCurrentFocused(placeholder)}
									onBlur={() => setCurrentFocused('')}
								/>
							) : (
								<Form.Select
									size="lg"
									value={currentValue}
									required={required}
									placeholder={placeholder}
									isInvalid={hasChange && message ? true : false}
									onChange={onChange}
									data-recurly={recurlyData || undefined}
									onClick={() => setCurrentFocused(placeholder)}
									onBlur={() => setCurrentFocused('')}
								>
									{Array.isArray(options)
										? options.map((value, index) => (
											<option key={index}>{value}</option>
										))
										: Object.keys(options).map((key, index) => (
											<option key={index} value={options[key]}>
												{key}
											</option>
										))}
								</Form.Select>
							)}
						</FloatingLabel>
					)

				) :
					isCreditCard ? (
						<>
							{isHorizontal ? (
								<Row className="vertical-center-container">
									<Col className="vertical-center-container" xs={3}>
										{label && (
											<Form.Label className="ms-auto text-end form-label" as="h6">
												{label}
											</Form.Label>
										)}
									</Col>
									<Col xs={9}>
										<RecurlyCard onChange={onChange} />
									</Col>
								</Row>
							) : (
								<Col>
									{label && <Form.Label as="h6">{label}</Form.Label>}
									<RecurlyCard onChange={onChange} />
								</Col>
							)}
						</>
					) : (
						<Form.Group
							className={formClassName}
							as={isHorizontal ? Row : Col}
							md={md}
							xs={xs}
							id={id}
						>
							{label && !floatingLabel && formType && (
								<FieldWrapper columnSize={3} isColumn={isHorizontal}>
									<Form.Label
										as="h6"
										className={isHorizontal && placeholder === "Confirm Email" ? 'ms-auto form-label email-confirm-label' : placeholder === "Confirm Email" ? 'email-confirm-label' : 'form-label'}
									>
										{label}
									</Form.Label>
								</FieldWrapper>
							)}
							<FieldWrapper
								columnSize={label ? 9 : 12}
								isColumn={isHorizontal}
								extraChildren={children}
							>
								<InputGroup>
									{isSearchBar ? (
										<>
											<Typeahead
												size="lg"
												style={{ width: '100%' }}
												id="rs-search-bar"
												labelKey={searchLabel}
												filterBy={onSearchFilter}
												options={ALL_PAGES}
												placeholder={placeholder}
												onChange={onChange}
												className='search-text'
											/>
											<SearchOutlined fontSize='large' className='search-icon d-md-none' />
										</>
									) : !isSearchBar && curFormType != 'select' ? (
										<Form.Control
											id={placeholder === "Confirm Email" ? 'email-confirm-input' : undefined}
											size="lg"
											value={currentValue}
											required={required}
											placeholder={placeholder}
											type={curFormType}
											isInvalid={hasChange && message ? true : false}
											onChange={onChange}
											data-recurly={recurlyData || undefined}
											disabled={disabled}
											className={isDate && url.pathname !== "/account" ? 'input-date' : placeholder === "Confirm Email" ? 'email-confirm' : 'form-input'}
										/>
									) : (
										<Form.Select
											size="lg"
											value={currentValue}
											required={required}
											placeholder={placeholder}
											isInvalid={hasChange && message ? true : false}
											onChange={onChange}
											data-recurly={recurlyData || undefined}
										>
											{Array.isArray(options)
												? options.map((value, index) => (
													<option key={index}>{value}</option>
												))
												: Object.keys(options).map((key, index) => (
													<option key={index} value={options[key]}>
														{key}
													</option>
												))}
										</Form.Select>
									)}
									{isPassword && (
										<Button
											variant="outline-secondary"
											onClick={onToggleShowPassword}
										>
											{showPassword ? <VisibilityOff /> : <Visibility />}
										</Button>
									)}
									<Form.Control.Feedback type="invalid">
										{message}
									</Form.Control.Feedback>
								</InputGroup>
							</FieldWrapper>
						</Form.Group>
					)
			}
		</>
	)
}

export default BasicInput
