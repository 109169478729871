/* eslint-disable @typescript-eslint/no-unused-vars */
import { Modal } from 'react-bootstrap';
import { BlobProvider } from '@react-pdf/renderer';
import { Document } from 'react-pdf/dist/umd/entry.webpack';
import TrainingPlanPDF from '../components/pdf/TrainingPlanPDF';
const PdfViewer = (props: any) => {
    const { setHideMainModal, showPdf, isPDFExist, handleClose, userInfo, trainingData, pdfData, currentUser, userRestComments, vdotAdjustments, customPaces } = props

    const trainingPlanPDF = <TrainingPlanPDF userInfo={userInfo} trainingData={trainingData} currentUser={currentUser} pdfData={pdfData} vdotAdjustments={vdotAdjustments} customPaces={customPaces} userRestComments={userRestComments} />

    const Loader = () => {
        return <div className="download-loader">
            <h6>Download Your Training Plan</h6>
            <p>Hang tight while we generate your training log...</p>
            <img src='/images/icons/logo-gif.gif' />
        </div>
    }
    return (
        <Modal
            size='sm'
            className="pdf-viewer"
            show={showPdf}
            onHide={handleClose}
            backdrop="static"
            keyboard={false}
        >
            <Modal.Body>
                <BlobProvider document={trainingPlanPDF}>
                    {({ blob, url, loading }) => {
                        return loading ? <Loader /> : (
                            <Document file={url}
                                loading={<Loader />}
                                onLoadSuccess={(pdf) => {
                                    window.open(url as string)
                                    handleClose()
                                    isPDFExist()
                                    setHideMainModal(false)
                                }}
                                renderMode="canvas">
                            </Document>
                        );
                    }}
                </BlobProvider>
            </Modal.Body>
        </Modal>
    )
}

export default PdfViewer