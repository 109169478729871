import { FC, useState, useEffect, useRef } from 'react';

import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';

import RSButton from '../../components/buttons/RSButton'
import useIsMobileScreen from '../../hooks/useIsMobileScreen'

export type StretchingPlanProps = {
    addDetails: any
    allStageDetails: any,
    handleIsStepComplete?: any,
    setStretchingMain: any
	setUpdatedAddonDetails?: any
}

const StretchingPlan: FC<StretchingPlanProps> = ({ addDetails, allStageDetails, handleIsStepComplete, setStretchingMain, setUpdatedAddonDetails }) => {
		const stageNumber = '4'
		const isMobile = useIsMobileScreen()
		//const initialMount = useRef(true)
		const { stretchingPlan: sp } = allStageDetails?.[stageNumber] || {}
		const [stretching, setStretching] = useState<Array<string>>(sp || [])
		const firstRef = useRef<any>()
		const wHeight = isMobile ? window.innerHeight : 603
		const wWidth = isMobile ? window.innerWidth : 525

		const activities = ['Low back', 'Hip Flexors', 'Quads', 'Hamstrings', 'Calves']

		const parts = [{
			top: 37,
			right: 13,
		}, {
			top: 46,
			right: 19.6,
		}, {
			top: 55,
			right: 25,
		}, {
			top: 62,
			right: 19,
		}, {
			top: 78,
			right: 30,
		}]

    useEffect(() => {
		// console.log('stretching', stretching)
//		if(initialMount.current) {
//			initialMount.current = false
//			return
//		}
		if (allStageDetails?.[stageNumber]?.stretchingPlan != stretching) {
			// console.log('[updated stretchingPlan]')
			setUpdatedAddonDetails(true)
		}
    	setStretchingMain(stretching)
    	addDetails(stageNumber , { stretchingPlan: stretching })
    	handleIsStepComplete(true, stageNumber)

    }, [stretching])

    return <Box>
			<Grid container xs={12} sx={{ pb: 1, px: 3, fontFamily: 'Poppins-Medium', fontSize: '20px', lineHeight: '1em' }} alignItems='flex-end'>
				Add Stretching to your plan
			</Grid>
			<Grid container xs={12} sx={{ px: 3, fontFamily: 'Poppins', fontSize: '14px', lineHeight: '1em', pb: 3}} alignItems='flex-end'>
				Any areas you would like to focus on?
			</Grid>
			<Grid container xs={12} sx={{position: 'relative', px: isMobile ? 0 : 3}}>
				{isMobile && <Grid container xs={12} sx={{position: 'absolute', height: '100%'}} justifyContent='flex-end' alignItems='flex-start'>
					<Box sx={{position: 'relative'}}>
						<img src='/img-new/race-events/Runner-Areas-Mobile.jpg' alt='stretching' style={{width: 'auto', height: wHeight - 280}}/>
						{parts.map((p: any, pi: number) => {
							const isActive = stretching.includes(activities[pi])
							const thickness = 3
							const circleSize = (wHeight - 280)*0.045
							const imgWidth = (wHeight - 280)*0.6340110905730129
							const extraSpace = 144-(wWidth - imgWidth)
							const initArrowHeight = ((wHeight - 280)*(p.top*0.01))+(circleSize/2)-(61*pi)-22.5+(thickness)
							const isN = initArrowHeight < 0
							let arrowHeight = (initArrowHeight*(isN ? -1 : 1))+(isN ? thickness*2 : 0)
							arrowHeight = arrowHeight < thickness ? thickness : arrowHeight
							const arrowWidth = ((imgWidth)*((100-p.right)*0.01))-circleSize-extraSpace
							if (!pi)
								firstRef.current = arrowWidth/2
							const w = (firstRef?.current || arrowWidth/2 )
							const widthPosition = w-((w/parts.length)*pi)
							return <>
								<Box key={pi+'parts'} sx={{height: circleSize, width: circleSize, borderRadius: '50%', backgroundColor: `${isActive ? '#FCC26A' :' rgba(0,0,0,0.3)'}`, position: 'absolute', top: `${p.top}%`, right: `${p.right}%`, border: isActive ? '1px solid #FF9A00':'none'}} />
								<Box sx={{height: `${arrowHeight}px`, width: `${arrowWidth}px`,position: 'absolute', top: `calc(${p.top}% - ${(isN ? thickness : arrowHeight)-(circleSize/2)-(thickness/2)}px)`, right: `calc(${p.right}% + ${circleSize}px)`, transform: `rotateX(${isN ? '180deg' : '0deg'})`}}>
									<Box sx={{width: '100%',position: 'absolute', height: (arrowHeight/2)-(thickness), boxSizing: 'content-box'}}>
										<Box sx={{width: widthPosition, height: '100%', borderTop: `${thickness}px solid rgba(0,0,0,0.1)`, borderRight: `${thickness}px solid rgba(0,0,0,0.1)`, borderRadius: `0px ${widthPosition*0.5}px 0px 0px`, boxSizing: 'content-box'}}/>
									</Box>
									<Box sx={{width: `calc(100% - ${arrowHeight === thickness ? thickness : 0}px)`, position: 'absolute', bottom: 0, ml: arrowHeight === thickness ? `${thickness+1}px` : 0, height: (arrowHeight === thickness ? arrowHeight : arrowHeight/2), display: 'flex', justifyContent: 'flex-end', boxSizing: 'content-box'}}>
										<Box sx={{width: arrowWidth-widthPosition, height: '100%', borderBottom: `${thickness}px solid rgba(0,0,0,0.1)`, borderLeft: `${thickness}px solid rgba(0,0,0,0.1)`, borderRadius: `0px 0px 0px ${(arrowWidth-widthPosition)*0.5}px`}}/>
									</Box>
								</Box>
							</>
						})}
					</Box>
				</Grid>}
				{activities.map((activity: any, ai: number)=>{
					const isActive = stretching.includes(activity)
					return <Grid item container xs={isMobile ? 12 : 4} key={'activity-'+ai} sx={{px: isMobile ? 3 : 1, height: isMobile ? '45px' : '60px', mb: 2}} justifyContent='flex-start' alignItems='center'>
						<RSButton card customized style2 secondary={isActive} sx={{height: '100%', flexDirection: 'column', width: isMobile ? '120px' : '150px'}}
							onClick={() => setStretching(isActive ? stretching.filter((s: string)=> activity!==s) : [...stretching, activity])}>
							<Box sx={{fontFamily: 'Poppins !important', fontSize: '14px !important', textAlign: 'center', lineHeight: '1em', pb: '5px'}}>{activity}</Box>
						</RSButton>
					</Grid>
				})}
			</Grid>
    </Box>
}

export default StretchingPlan;
