/* eslint-disable @typescript-eslint/no-unused-vars */

import { Box, Grid, InputLabel, MenuItem, Select } from '@mui/material'
import { Form, Table } from 'react-bootstrap'
import React, { useEffect, useState } from 'react'
import useSWR from 'swr'
import moment from 'moment'
import RSButton from '../../components/buttons/RSButton'
import RaceSelection from '../../pages/training-plan/build/RaceSelection.json'
import JSONTable from './JSONTable'
import { DAYS_OF_WEEK, sortDays } from '../training-plan/build/DaysOptions'
import { User } from 'recurly'
import RSDrawer from '../../components/layout/RSDrawer'
import useIsMobileScreen from '../../hooks/useIsMobileScreen'
import GPTTrainingPlan from '../../pages/training-plan/GPTTrainingPlan'
import useTrainingPlanUtils from '../../hooks/useTrainingPlanUtils'
import numberHelperUtil from '../../utils/numberHelperUtil'
import ScrollRow from '../../components/layout/ScrollRow'
import SelectPill from '../../components/input/SelectPill'
import useScrollRow from '../../hooks/useScrollRow'
import PromptVersion from './PromptVersion'
import PromptVersionForm from './PromptVersionForm'
import useGPTPrompts from './useGPTPrompts'
import { OpenAIPrompt } from '../../api/v2/open-ai/OpenAIPrompt.types'
import BuildPlanAvailability from './BuildPlanAvailability'

const TrainingPlanManager = () => {
    const { isScrolling, setIsScrolling } = useScrollRow()

    const MENUS = ['Plan History', 'Build Availability', 'Version']
    const STATUS_FILTER = ['Declined', 'Active', 'All']
    const limit = 10;
    const activeColor = '#e0e0e0'

    const [status, setStatus] = useState(0)
    const [page, setPage] = useState(0)
    const [showOutput, setShowOutput] = useState(false)
    const [trainingPlans, setTrainingPlans] = useState<any>([])
    const [selectedMenu, setSelectedMenu] = useState(MENUS[0])
    const [selectedPlan, setSelectedPlan] = useState<any>(null)
    const [isEndOfResults, setIsEndOfResults] = useState(false)
    const [searchUserText, setSearchUserText] = useState('')
    const [searchTextVal, setSearchTextVal] = useState('')
    const [openViewPlan, setOpenViewPlan] = useState(false)
    const [tabOptions, setTabOptions] = useState([])
    const [selectedPill, setSelectedPill] = useState('Plan Preview')
    const { ConvertM2H2, ConvertM2H, ConvertFullToHalf } = useTrainingPlanUtils()
    const { convertNumberToWord } = numberHelperUtil()
    const { data, isLoading } = useSWR(`${searchUserText ? `/gpt-training-plan-info/search?user=${searchUserText}` : `/gpt-training-plan-info/status?value=${status}&page=${page}&limit=${limit}`}`, { revalidateOnFocus: false })
    const [selectedPrompt, setSelectedPrompt] = useState<OpenAIPrompt>()
    const [lastVersion, setLastVersion] = useState(0)
    const { prompts, updatePromptVersion, createPromptVersion, isSavingOrUpdating, mutatePrompts, checkDupplicatePrompt, deletePromptVersion, activatePromptVersion, restorePromptVersion } = useGPTPrompts({})

    const [pillKey, setPillKey] = useState(0)


    const handleChangeSelectStatus = (event: any) => {
        setPage(0)
        setTrainingPlans([])
        setStatus(event.target.value)
    }

    const handleSubmitPrompt = async (data: OpenAIPrompt, type: string) => {
        if (type === 'update') {
            await updatePromptVersion(data)
            return true
        }
        else {
            const isDupp = checkDupplicatePrompt(prompts?.data?.result as OpenAIPrompt[], data?.race_type as string, data?.step as string, data?.version as number)
            if (isDupp) {
                alert('Prompt with the same version and step already exists')
                return false
            }
            else {
                await createPromptVersion(data)
                return true
            }
        }
    }


    const handleSearch = () => {
        if (!searchTextVal) return

        // setPage(0)
        // setTrainingPlans([])
        setSearchUserText(searchTextVal)
    }

    const onPillChange = (value: any) => {
        setSelectedPill(value)
        // setPillKey(value === "Introduction" ? 0 : 1)
    }

    const getSuggestedDistance = (range: string) => {
        const diff: number = Number(range?.split("-")[1] || 0) - Number(range?.split("-")[0] || 0)
        const suggestedDistance = diff / 2
        return Math.ceil(Number(range?.split("-")[1] || 0) - suggestedDistance)
    }

    const getGoalTime = (goalTime: number, type: string) => {
        if (goalTime)
            if (type === "half" || type === "full") {
                // return ConvertM2H2((goalTime / 2))
                return ConvertM2H2(goalTime)
            }
            else {
                return ConvertM2H(goalTime)
            }
    }

    const renderVersionForm = () => {
        return <PromptVersionForm lastVersion={lastVersion} isLoading={isSavingOrUpdating} submit={(data, type) => handleSubmitPrompt(data, type)} data={selectedPrompt} setSelectedPrompt={setSelectedPrompt} />
    }

    const getReruns = (plan: any, stepNumber: string) => {
        const stepReruns = plan?.steps?.find((obj: any) => obj.step?.toUpperCase()?.includes(stepNumber))?.step.match(/\[(.*?)\]/)
        return stepReruns ? (stepReruns[1] > 0 ? stepReruns[1] : '') : ''
    }

    const getRerunsTotal = (data: any) => Number(getReruns(data, '2') > 0 ? getReruns(data, '2') : 0) + Number(getReruns(data, '3') > 0 ? getReruns(data, '3') : 0)


    useEffect(() => {
        if (searchTextVal === "") {
            setPage(0)
            setTrainingPlans([])
            setSearchUserText('')
        }
    }, [searchTextVal])


    useEffect(() => {
        if (data && !searchUserText) {
            setTrainingPlans([...trainingPlans, ...data.data.result])
            setIsEndOfResults(data?.data?.result?.length < limit)
        }
        else if (data && searchUserText) {
            setTrainingPlans(data.data.result)
        }
    }, [data, page, status, searchUserText])

    useEffect(() => {
        console.log('selectedPlan', selectedPlan)
        const tabs: any = []
        tabs.push('Plan Preview')

        if (selectedPlan?.steps?.length > 0) {
            const steps = selectedPlan?.steps.length > 5 ? selectedPlan?.steps.slice(0, 5) : selectedPlan?.steps
            steps.map((x: any, i: number) => {
                tabs.push('Step ' + convertNumberToWord(i + 1))
            })
        }

        setTabOptions(tabs)
    }, [selectedPlan])

    // console.log(JSON.parse(selectedPlan?.training[0]?.daily_runs))

    return <>
        <Grid container spacing={1}>
            <Grid item xs={4}>
                <div className='half-md-card w-100 card mx-auto p-3 mt-0'>
                    {MENUS.map((menu, index) => (
                        <div onClick={() => setSelectedMenu(menu)} style={{ cursor: 'pointer', textDecoration: `${menu === selectedMenu ? 'underline' : ''}` }} key={index}>
                            <h5>{menu}</h5>
                        </div>
                    ))}
                </div>
                {
                    selectedMenu === 'Plan History' ? (
                        <>
                            <div className='mt-2'>
                                <Table responsive striped bordered hover size="sm">
                                    <thead>
                                        <tr>
                                            <th colSpan={2}>User Details</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td>Name</td>
                                            <td><b>{selectedPlan?.profile?.name}</b></td>
                                        </tr>
                                        <tr>
                                            <td>Email</td>
                                            <td><b>{selectedPlan?.account?.email}</b></td>
                                        </tr>
                                        <tr>
                                            <td>Age</td>
                                            <td><b>{selectedPlan?.profile?.dob ? moment().diff(selectedPlan?.profile?.dob, 'years') : ''}</b></td>
                                        </tr>
                                        <tr>
                                            <td>VDot</td>
                                            <td><b>{selectedPlan?.vdot}</b></td>
                                        </tr>
                                    </tbody>
                                </Table>
                            </div>

                            <div className='mt-2'>
                                <Table responsive striped bordered hover size="sm">
                                    <thead>
                                        <tr>
                                            <th colSpan={2}>Race Details</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td style={{ width: '100px' }}>Race</td>
                                            <td><b>{RaceSelection?.find(x => x?.title.toLowerCase() === selectedPlan?.race_type?.toLowerCase())?.subtitle}</b></td>
                                        </tr>
                                        <tr>
                                            <td>Name</td>
                                            <td><b>{selectedPlan?.event_name}</b></td>
                                        </tr>
                                        <tr>
                                            <td>Race Date</td>
                                            <td><b>{moment(selectedPlan?.race_date).format('MMMM DD YYYY')}</b></td>
                                        </tr>
                                        <tr>
                                            <td>Goal Time</td>
                                            <td colSpan={3} ><b>{getGoalTime(selectedPlan?.target_time, selectedPlan?.race_type.toLowerCase())}</b></td>
                                        </tr>
                                    </tbody>
                                </Table>
                            </div>

                            <div className='mt-2'>
                                <Table responsive striped bordered hover size="sm">
                                    <thead>
                                        <tr>
                                            <th colSpan={2}>Runner Preferences</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td style={{ width: '50%' }}>Level</td>
                                            <td><b>{selectedPlan?.training_level}</b></td>
                                        </tr>
                                        <tr>
                                            <td style={{ width: '50%' }}>Unit</td>
                                            <td><b>{selectedPlan?.profile?.distance_unit?.toUpperCase() || ''}</b></td>
                                        </tr>
                                        <tr>
                                            <td>Avg (Last 4 Weeks)</td>
                                            <td><b>{selectedPlan?.average_distance}</b></td>
                                        </tr>
                                        <tr>
                                            <td>Long Run (2 weeks)</td>
                                            <td><b>{selectedPlan?.longest_run}</b></td>
                                        </tr>
                                        <tr>
                                            <td>Longrun Day</td>
                                            <td><b>{selectedPlan?.longrun_day}</b></td>
                                        </tr>
                                        <tr>
                                            <td>Run (days)</td>
                                            <td><b>{selectedPlan?.max_run_days}</b></td>
                                        </tr>
                                        <tr>
                                            <td>Run Days</td>
                                            <td><b>{sortDays(selectedPlan?.run_days)?.split(',')?.map((e: string, i: number) => (<span key={i}>{e.slice(0, 3)?.toUpperCase()}{i !== sortDays(selectedPlan?.run_days)?.split(',').length - 1 ? ', ' : ''}</span>))}</b></td>
                                        </tr>
                                        <tr>
                                            <td>Stretch Areas</td>
                                            <td><b>{selectedPlan?.stretching_areas.split(',').map((e: string) => (<span key={e}>{e}<br /></span>))}</b></td>
                                        </tr>
                                        <tr>
                                            <td>Training</td>
                                            <td><b>{selectedPlan?.cross_training}</b></td>
                                        </tr>
                                    </tbody>
                                </Table>
                            </div>

                            <div className='mt-2'>
                                <Table responsive striped bordered hover size="sm">
                                    <thead>
                                        <tr>
                                            <th colSpan={4}>Recommendations</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td style={{ width: '50%' }}>Suggested Average Range</td>
                                            <td><b>{selectedPlan?.average_range}</b></td>
                                        </tr>
                                        <tr>
                                            <td>Weekly Avg</td>
                                            <td><b>{getSuggestedDistance(selectedPlan?.average_range)}</b></td>
                                        </tr>
                                        <tr>
                                            <td>Suggested Max Range</td>
                                            <td><b>{selectedPlan?.max_range}</b></td>
                                        </tr>
                                        <tr>
                                            <td>Peak Weekly</td>
                                            <td><b>{getSuggestedDistance(selectedPlan?.max_range)}</b></td>
                                        </tr>
                                        <tr>
                                            <td>Final Saved Range</td>
                                            <td><b>{selectedPlan?.max_distance}</b></td>
                                        </tr>

                                    </tbody>
                                </Table>
                            </div>

                            <div className='mt-2'>
                                <Table responsive striped bordered hover size="sm">
                                    <thead>
                                        <tr>
                                            <th colSpan={4}>Plan Health</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td style={{ width: '50%' }}>Weekly Mileage</td>
                                            <td><b>{getReruns(selectedPlan, '2') > 2 ? <i className='fa-solid fa-poo' style={{ fontSize: '25px' }} /> : getReruns(selectedPlan, '2')}</b></td>
                                        </tr>
                                        <tr>
                                            <td style={{ width: '50%' }}>Daily Runs</td>
                                            <td><b>{getReruns(selectedPlan, '3') > 2 ? <i className='fa-solid fa-poo' style={{ fontSize: '25px' }} /> : getReruns(selectedPlan, '3')}</b></td>
                                        </tr>

                                    </tbody>
                                </Table>
                            </div>

                        </>
                    ) : selectedMenu === 'Build Availability' ? (
                        <></>
                    ) : selectedMenu === 'Version' ?
                        (<PromptVersion setLastVersion={setLastVersion} selectedPrompt={selectedPrompt} isLoading={isSavingOrUpdating} restorePromptVersion={restorePromptVersion} setSelectedPrompt={setSelectedPrompt} data={prompts?.data?.result || []} deletePromptVersion={deletePromptVersion} activatePromptVersion={activatePromptVersion} />)
                        : undefined
                }

            </Grid>
            <Grid item xs={8}>
                {
                    selectedMenu === 'Plan History' && !showOutput ? (
                        <>
                            <div className='half-md-card w-100 card mx-auto p-3 mt-0'>
                                <h5><b>Training Plans</b></h5>
                                <Grid container mb={1}>
                                    <Grid sm={2}>
                                        <Select style={{ width: '130px' }} value={status} onChange={(e) => handleChangeSelectStatus(e)} >
                                            {STATUS_FILTER?.map((status, index) => (<MenuItem key={index} value={index}>{status}</MenuItem>))}
                                        </Select>
                                    </Grid>
                                </Grid>
                                <Table responsive striped bordered hover size="sm">
                                    <thead>
                                        <tr>
                                            <th>#</th>
                                            {/* <th>ID</th> */}
                                            <th>Date</th>
                                            <th>User</th>
                                            <th>Status</th>
                                            <th>RTs</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {trainingPlans?.map((data: any, index: number) => (
                                            <tr onClick={() => {
                                                setSelectedPlan(data)
                                                setShowOutput(true)
                                            }} style={{ cursor: 'pointer' }} key={index}>
                                                <td style={{ backgroundColor: `${selectedPlan?.plan_id == data.plan_id ? activeColor : ''}` }}>{index + 1}</td>
                                                {/* <td>{data?.plan_id}</td> */}
                                                <td style={{ backgroundColor: `${selectedPlan?.plan_id == data.plan_id ? activeColor : ''}` }}>{moment(data?.date_created).format('MM/DD/YYYY')}</td>
                                                <td style={{ backgroundColor: `${selectedPlan?.plan_id == data.plan_id ? activeColor : ''}` }}>{data?.account?.email}</td>
                                                <td style={{ color: `${data.status ? 'green' : 'red'}`, backgroundColor: `${selectedPlan?.plan_id == data.plan_id ? activeColor : ''}` }}><b>{data?.status ? 'Active' : 'Declined'}</b></td>
                                                <td style={{ justifyContent: 'center', color: `${getRerunsTotal(data) > 1 ? '#FF5733' : '#0096FF'}` }}>{getRerunsTotal(data) == 0 ? <i className="fa-solid fa-face-smile" /> : <b>{getRerunsTotal(data)}</b>}</td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </Table>
                            </div>
                            {
                                !isEndOfResults ? (
                                    <div style={{ textAlign: 'center' }}>
                                        <RSButton className='mt-2 w-25' onClick={() => setPage(page + 1)}> Load More</RSButton>
                                    </div>
                                ) : undefined
                            }

                        </>
                    ) : selectedMenu === 'Version' ? renderVersionForm() : undefined
                }
                {
                    selectedMenu === 'Plan History' && showOutput ? (
                        <>
                            <div className='half-md-card w-100 card mx-auto p-3 mt-0'>
                                <Grid container>
                                    <Grid item xs={6}>
                                        <div onClick={() => {
                                            setSelectedPill('Plan Preview')
                                            setShowOutput(false)
                                        }} style={{ cursor: 'pointer' }}>
                                            <h5>{'< Go Back'}</h5>
                                        </div>
                                    </Grid>
                                    <Grid item xs={6}>
                                        <div onClick={() => setOpenViewPlan(true)} style={{ cursor: 'pointer', textAlign: 'right' }}>
                                            <h5>{'View Calendar'}</h5>
                                        </div>
                                    </Grid>
                                </Grid>
                                <Box sx={{ mt: 2 }}>
                                    <ScrollRow setIsScrolling={setIsScrolling}>
                                        <SelectPill
                                            index={pillKey}
                                            key={pillKey}
                                            options={tabOptions}
                                            onChange={onPillChange}
                                        />
                                    </ScrollRow>
                                </Box>

                                {selectedPill === 'Plan Preview' ? (
                                    <>
                                        <Table responsive striped bordered hover size="sm">
                                            <thead>
                                                <tr>
                                                    <th>Week</th>
                                                    <th>Weekly Mileage</th>
                                                    {DAYS_OF_WEEK?.map((day, index) => (
                                                        <th key={index}>{day}</th>
                                                    ))}
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {
                                                    selectedPlan?.training?.map((training: any, index: number) => (
                                                        <tr key={index}>
                                                            <td>{training?.week}</td>
                                                            <td>{training?.weekly_mileage}</td>
                                                            {
                                                                JSON.parse(training?.daily_runs)?.map((run: any, index: number) => (
                                                                    <td key={index}>
                                                                        <tr>
                                                                            <td>{run?.run_distance} -</td>
                                                                            <td>{run?.run_type.replace('Run', '')}</td>
                                                                        </tr>
                                                                        <tr><td colSpan={2}>{run?.cross_training?.replace('Training', '')}</td></tr>
                                                                    </td>
                                                                ))
                                                            }
                                                        </tr>
                                                    ))
                                                }
                                            </tbody>
                                        </Table>
                                    </>
                                ) : selectedPill === "Step one" ? (
                                    <>
                                        <Grid container>
                                            {selectedPlan?.steps.length > 0 && (
                                                <Grid item xs={12}>
                                                    <h5>{'Long Runs'}</h5>
                                                    <Table responsive striped bordered hover size="sm">
                                                        <thead>
                                                            <tr>
                                                                {JSON.parse(selectedPlan?.steps[0]?.data || {})[0]?.longRuns?.map((x: any, i: number) => (
                                                                    <th key={i}>w {x.w}</th>
                                                                ))}
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            <tr>
                                                                {JSON.parse(selectedPlan?.steps[0]?.data || {})[0]?.longRuns?.map((x: any, i: number) => (
                                                                    <th key={i}>{x.lr}</th>
                                                                ))}
                                                            </tr>
                                                        </tbody>
                                                    </Table>
                                                </Grid>
                                            )}
                                        </Grid>

                                        {JSON.parse(selectedPlan?.steps[0]?.data || {})[0]?.latestResults.length > 0 && (
                                            <Grid container>
                                                <Grid xs={12}>
                                                    <Table responsive striped bordered hover size="sm">
                                                        <thead>
                                                            <tr>
                                                                {Object.keys(JSON.parse(selectedPlan?.steps[0]?.data || {})[0]?.latestResults[0])?.splice(0, 3).map((x: any, i: number) => (
                                                                    <td key={x}>{x.replaceAll('_', ' ')}</td>
                                                                ))}
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            {
                                                                JSON.parse(selectedPlan?.steps[0]?.data || {})[0]?.latestResults?.map((x: any, i: number) => (
                                                                    <tr key={i}>
                                                                        <td>{x.Week}</td>
                                                                        <td>{x.Long_run}</td>
                                                                        <td>{x.Weekly_mileage}</td>
                                                                    </tr>
                                                                ))
                                                            }
                                                        </tbody>
                                                    </Table>
                                                </Grid>
                                            </Grid>
                                        )}
                                    </>
                                ) : selectedPill === "Step two" ? (
                                    <>
                                        {JSON.parse(selectedPlan?.steps[1]?.data || {})?.length > 0 && (
                                            <Grid container>
                                                <Grid xs={12}>
                                                    <Table responsive striped bordered hover size="sm">
                                                        <thead>
                                                            <tr>
                                                                {Object.keys(JSON.parse(selectedPlan?.steps[1]?.data || {})[0])?.map((x: any, i: number) => (
                                                                    <td key={i}>{x.replaceAll('_', ' ')}</td>
                                                                ))}
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            {
                                                                JSON.parse(selectedPlan?.steps[1]?.data || {}).map((x: any, i: number) => (
                                                                    <tr key={i}>
                                                                        <td>{x.Week}</td>
                                                                        <td>{x.Long_run}</td>
                                                                        <td>{x.Weekly_mileage}</td>
                                                                        <td>{x.Taper ? 'true' : ''}</td>
                                                                        <td>{x.Recovery ? 'true' : ''}</td>
                                                                    </tr>
                                                                ))
                                                            }
                                                        </tbody>
                                                    </Table>
                                                </Grid>
                                            </Grid>
                                        )}
                                    </>
                                ) : selectedPill === "Step three" ? (
                                    <>
                                        <Grid container>
                                            <Grid xs={12}>
                                                <Table responsive striped bordered hover size="sm">
                                                    <thead>
                                                        <tr>
                                                            {Object.keys(JSON.parse(selectedPlan?.steps[2]?.data || {})[0])?.map((x: any, i: number) => (
                                                                <td key={i}>{x.replaceAll('_', ' ')}</td>
                                                            ))}
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {
                                                            JSON.parse(selectedPlan?.steps[2]?.data || {}).map((x: any, i: number) => (
                                                                <tr key={i}>
                                                                    {Object.keys(JSON.parse(selectedPlan?.steps[2]?.data || {})[0])?.map((e: any, o: number) => (
                                                                        <td key={o}>{x[`${e}`]}</td>
                                                                    ))}
                                                                </tr>
                                                            ))
                                                        }
                                                    </tbody>
                                                </Table>
                                            </Grid>
                                        </Grid>
                                    </>
                                ) : selectedPill === "Step four" ? (
                                    <>
                                        <Table responsive striped bordered hover size="sm">
                                            <thead>
                                                <tr>

                                                    <th>Week</th>
                                                    <th>Weekly Mileage</th>
                                                    {JSON.parse(selectedPlan?.steps[selectedPlan?.steps.length > 5 ? 4 : 3]?.data || {})?.length > 0 && JSON.parse(selectedPlan?.steps[selectedPlan?.steps.length > 5 ? 4 : 3]?.data || {})[0].days.map((day: any, dI: number) => <>
                                                        <th>{day.day_name || day.day}</th>
                                                        {/* <th>Type</th> */}
                                                    </>)}
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {
                                                    JSON.parse(selectedPlan?.steps[selectedPlan?.steps.length > 5 ? 4 : 3]?.data || {})?.map((week: any, wI: number) =>
                                                        <tr key={wI}>
                                                            <td>{week.week}</td>
                                                            <td>{week?.weekly_mileage || week?.mileage}</td>
                                                            {week.days.map((day: any, dI: number) => <>
                                                                <td>{day.run_distance || day.distance} {(day.run_distance || day.distance) != 0 && (day.run_type || day.type) ? '-' : ''} {(day.run_distance || day.distance) != 0 && (day.run_type || day.type).replace('Run', '')}</td>
                                                            </>)}
                                                        </tr>
                                                    )
                                                }
                                            </tbody>
                                        </Table>
                                    </>
                                ) : selectedPill === "Step five" ? (
                                    <>
                                        {/* {console.log(JSON.parse(selectedPlan?.steps[4]?.data || {}))}
                                        {console.log(Object.keys(JSON.parse(selectedPlan?.steps[4]?.data || {})[0]))} */}
                                        <Table responsive striped bordered hover size="sm">
                                            <thead>
                                                <tr>
                                                    <th>Week</th>
                                                    <th>Weekly Mileage</th>
                                                    {JSON.parse(selectedPlan?.steps[selectedPlan?.steps.length > 5 ? 5 : 4]?.data || {})?.length > 0 && JSON.parse(selectedPlan?.steps[selectedPlan?.steps.length > 5 ? 5 : 4]?.data || {})[0].days.map((day: any, dI: number) => <>
                                                        <th>{day?.day_name || day?.day}</th>
                                                        {/* <th>Type</th> */}
                                                    </>)}
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {
                                                    JSON.parse(selectedPlan?.steps[selectedPlan?.steps.length > 5 ? 5 : 4]?.data || {})?.map((week: any, wI: number) =>
                                                        <tr key={wI}>
                                                            <td>{week?.week}</td>
                                                            <td>{week?.weekly_mileage || week?.mileage}</td>
                                                            {week.days.map((day: any, dI: number) => <>
                                                                <td>{day.run_distance || day.distance} {(day.run_distance || day.distance) != 0 && (day.run_type || day.type) ? '-' : ''} {(day.run_distance || day.distance) != 0 && (day.run_type || day.type).replace('Run', '')}
                                                                    {day.cross_training ? <><br /><span>{day.cross_training}</span></> : ''}
                                                                </td>
                                                            </>)}
                                                        </tr>
                                                    )
                                                }
                                            </tbody>
                                        </Table>
                                    </>
                                ) : undefined}
                            </div>
                        </>
                    ) :
                        selectedMenu === 'Build Availability' ? (
                            <BuildPlanAvailability />
                        )
                            : undefined
                }

                <RSDrawer bottom fitContent popUpWidth='100%' popUpHeight='90%' open={openViewPlan} onClose={() => {
                    setOpenViewPlan(false)
                }}>
                    <GPTTrainingPlan planIdLink={selectedPlan?.plan_id} onCloseModal={() => {
                        setOpenViewPlan(false)
                    }} />
                </RSDrawer>
            </Grid>
        </Grid>
    </>
}

export default TrainingPlanManager