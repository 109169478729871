/* eslint-disable @typescript-eslint/no-unused-vars */
import { FC, useState, useEffect } from 'react'
import { Form } from 'react-bootstrap'
import { roundNumber } from '../../modules/miscUtils'
import { DEFAULT_COSTS } from '../../modules/values'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import useStore from '../../store/useStore'
import { ICurrentUser } from '../../store/createAccountStore'
import { HAS_SALE } from '../../modules/cliEnvValues'
import PlanSelector from '../../pages/account/checkout/PlanSelector'
import useDeviceDetect from '../../hooks/useDeviceDetect'

export type ChooseYourPlanFormProps = {
	setMonthly: (x: boolean) => any
	hasSale?: boolean
	hasMonthlyPlan?: boolean
	hidePlanDetails?: boolean
	monthCost: number
	yearCost: number,
	isAccount?: boolean
}

// Get Next Date
const getNextDate = (hasTrial = true) => {
	const nextDate = new Date()
	if (hasTrial) nextDate.setDate(nextDate.getDate() + 14)
	return nextDate.toLocaleDateString()
}

// Get Yearly Expiration Date
const getYearlyExpirationDate = () => {
	const yearlyDate = new Date()
	yearlyDate.setFullYear(yearlyDate.getFullYear() + 1)
	yearlyDate.setDate(yearlyDate.getDate() + 14)
	return yearlyDate.toLocaleDateString()
}

// Get Monthly Expiration Date
const getMonthlyExpirationDate = () => {
	const monthlyDate = new Date()
	monthlyDate.setMonth(monthlyDate.getMonth() + 1)
	monthlyDate.setDate(monthlyDate.getDate() + 14)
	return monthlyDate.toLocaleDateString()
}

// Get Properties
export const getProperties = (
	monthCost: number,
	yearCost: number,
	isMonthly = true,
	hasSale = false
) => {
	const yearlyByMonthCost = roundNumber(yearCost / 12)
	const savingsPercent = Math.round(
		((monthCost - yearlyByMonthCost) / monthCost) * 100
	)
	const saleDate = getNextDate(false)
	const trialDate = getNextDate(true)
	const yearlyDate = !hasSale ? trialDate : saleDate
	const expirationDate = isMonthly
		? getMonthlyExpirationDate()
		: getYearlyExpirationDate()
	return {
		monthCost,
		yearCost,
		yearlyByMonthCost,
		savingsPercent,
		trialDate,
		yearlyDate,
		expirationDate,
	}
}

// Savings Badge
const SavingsBadge: FC<{ savingsPercent: number }> = ({ savingsPercent }) => (
	<span className="d-inline-block">
		<p
			className="ms-2 mb-0"
			style={{
				backgroundColor: 'black',
				borderRadius: 10,
				color: 'floralwhite',
				padding: '5px 10px 5px 10px',
				width: 'auto',
				textAlign: 'center',
			}}
		>
			{`${HAS_SALE ? 'BLACK FRIDAY - ' : ''}SAVE ${savingsPercent}%`}
		</p>
	</span>
)

// Plan Type Options
const PlanTypeOptions: FC<{
	hasSale?: boolean
	isMonthly: boolean
	onRadioChange: (opt: boolean, e: any) => any
	properties: ReturnType<typeof getProperties>
	hideDetails?: boolean,
	isAccount?: boolean
}> = ({ hasSale, isMonthly, hideDetails, properties, onRadioChange, isAccount }) => {
	// Properties
	const {
		monthCost,
		yearCost,
		yearlyByMonthCost,
		savingsPercent,
		trialDate,
		yearlyDate,
	} = properties

	// Text
	const monthDetailText = `After a 14-day free trial, on ${trialDate} a recurring charge of $${monthCost} monthly will automatically apply`
	const yearDetailText = hasSale
		? `Pay $${yearCost} today to get everything you need to run fast & injury-free. Your plan will renew automatically anually for $${yearCost}. You may cancel any time.`
		: `After a 14-day free trial, on ${yearlyDate} a recurring charge of $${yearCost} yearly will automatically apply`

	// Render
	return (
		<Form.Group controlId="planType">
			{/* Monthly Option */}
			<Form.Check type="radio" id="Monthly" label="Monthly" className="mb-3 c-checkbox--wrapper ps-0">
				<Form.Check.Input
					type="radio"
					checked={isMonthly}
					onChange={onRadioChange.bind(null, true)}
				/>
				<Row className="mb-1">
					<Col xs={2} className="pe-0">
						<Form.Check.Label className="fw-bold custom-checkbox">Monthly</Form.Check.Label>
					</Col>
				</Row>
				{isAccount ?
					<>
						{!hideDetails && <Form.Check.Label className="font-14 font-italic detail-text">{monthDetailText}</Form.Check.Label>}
						<Form.Check.Label className='mt-2 detail-text sm-normal-text'>{`$${monthCost} / month`}</Form.Check.Label>
					</> :
					<>
						{!hideDetails && <Form.Check.Label className="font-14 font-italic detail-text">{monthDetailText}</Form.Check.Label>}
						<Form.Check.Label className='month-annuall-bill mt-2 detail-text sm-normal-text'>{`$${monthCost} / month`}</Form.Check.Label>
					</>
				}
			</Form.Check>

			{/* <hr></hr> */}

			{/* Annual Option */}
			<Form.Check type="radio" id="Annually" label="Annually" className="c-checkbox--wrapper ps-0 mb-3">
				<Form.Check.Input
					type="radio"
					checked={!isMonthly}
					onChange={onRadioChange.bind(null, false)}
				/>
				<Row>
					<Col className="mb-1">
						<span className="d-inline-block">
							<Form.Check.Label className="fw-bold custom-checkbox">Annual</Form.Check.Label>
						</span>
						{!hideDetails && <SavingsBadge savingsPercent={savingsPercent} />}
					</Col>
				</Row>
				{hideDetails && <Form.Check.Label />}
				{!hideDetails ? (
					<Form.Check.Label className="font-14 font-italic detail-text">{yearDetailText}</Form.Check.Label>
				) : (
					<>
						<span className="d-inline-block detail-text">
							<Form.Check.Label>{`$${yearCost} / year`}</Form.Check.Label>
						</span>
						<SavingsBadge savingsPercent={savingsPercent} />
					</>
				)}
				<div className="month-annuall-bill mt-2 detail-text sm-normal-text">
					<Form.Check.Label>{`$${yearlyByMonthCost} / month billed annually`}</Form.Check.Label>
				</div>
			</Form.Check>
		</Form.Group>
	)
}

/**
 * ChooseYourPlanForm Component
 */

const ChooseYourPlanForm: FC<ChooseYourPlanFormProps> = ({
	setMonthly,
	hasSale,
	monthCost,
	yearCost,
	hidePlanDetails,
	hasMonthlyPlan = true,
	isAccount
}) => {
	// State

	const { setIsMonthlySelected, setPlanProperties } = useStore((state: ICurrentUser) => state)
	const { mobileType } = useDeviceDetect()
	
	const requestKey = localStorage.getItem('deviceRequestKey')
	const isAndroidNative = mobileType.toLowerCase() === 'android' && requestKey ? true : false

	const [isMonthly, setIsMonthly] = useState(hasMonthlyPlan)
	const [properties, setProperties] = useState(
		getProperties(
			monthCost != null ? monthCost : DEFAULT_COSTS.monthly,
			yearCost != null ? yearCost : DEFAULT_COSTS.annual,
			hasMonthlyPlan,
			hasSale
		)
	)

	// Update Monthly
	const updateMonthly = (newMonthly: boolean) => {
		setIsMonthly(newMonthly)
		setMonthly(newMonthly)
	}

	// On Radio Change
	const onRadioChange = (isMonthOption: boolean, e: any) =>
		updateMonthly(isMonthOption ? e.target.checked : !e.target.checked)

	// Set Plan Costs
	const setPlanCosts = (month: number, year: number) => {
		const newProperties = getProperties(month, year, isMonthly, hasSale)
		setProperties(newProperties)
		setPlanProperties(newProperties)
	}

	// Month/Year Cost Change Effect
	useEffect(() => {
		setIsMonthlySelected(isMonthly)
		setPlanCosts(monthCost, yearCost)
	}, [monthCost, yearCost, isMonthly, hasSale])

	// Has Monthly Plan Change Effect
	useEffect(() => {
		updateMonthly(hasMonthlyPlan)
	}, [hasMonthlyPlan])

	// Render
	return (
		<>
			<Row className="checkout mt-0 flex-column flex-content-center">
				{/* Form */}
				<Form>
					{/* {!hidePlanDetails && <hr />} */}

					{/* Plan Type Options */}
					{/* <PlanTypeOptions
						hasSale={hasSale}
						isMonthly={isMonthly}
						onRadioChange={onRadioChange}
						hideDetails={hidePlanDetails}
						properties={properties}
						isAccount={isAccount}
					/> */}
					<PlanSelector
						banner={''}
						selected={!isMonthly}
						months="12 Months"
						rate={`$${properties?.yearlyByMonthCost || ""}/month`}
						discount={`${properties?.savingsPercent || 0}% OFF MONTHLY PLAN`}
						total={properties?.yearCost || 0}
						onSelected={() => updateMonthly(false)}
						isAndroidNative={isAndroidNative}
					/>
					<PlanSelector
						selected={isMonthly}
						months="1 Month"
						rate="$15/month"
						discount=""
						total={properties?.monthCost || 0}
						onSelected={() => updateMonthly(true)}
						isAndroidNative={isAndroidNative}
					// onSelected={!loadingGooglePay ? handleSwitchSelected : undefined}
					/>
					{/* <hr /> */}
				</Form>
			</Row>
		</>
	)
}

export default ChooseYourPlanForm
