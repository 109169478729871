import classNames from "classnames"
import { FC, ReactElement, useEffect, useState } from "react"
import { Button } from "react-bootstrap"

// Props
export type ButtonProps = {
    primaryText: string | ReactElement
    secondaryText?: string
    primaryAction: () => any
    secondaryAction?: () => any
    extraText?: string
    extraAction?: () => any
    primaryDisabled?: boolean
    secondaryDisabled?: boolean,
    isSelected?: boolean,
    icon?: ReactElement
    isPay?: boolean,
    addClassName?: string,
    isOption?: boolean,
}

// Primary Button Component
export const IconButton: FC<ButtonProps> = ({
    primaryText,
    primaryAction,
    secondaryAction,
    primaryDisabled,
    isOption,
    isSelected,
    icon
}) => {
    // State
    const [isDisabled, setIsDisabled] = useState(primaryDisabled)

    // On Action
    const onAction = async () => {
        if (primaryAction) {
            setIsDisabled(true)
            await primaryAction()
            setIsDisabled(false)
        }
    }

    // Disabled Change Effect
    useEffect(() => {
        setIsDisabled(primaryDisabled)
    }, [primaryDisabled])

    // Render
    return (
        <span className={classNames("mb-3 d-inline-block button-container")}>
            <Button
                disabled={isDisabled}
                variant={isOption && isSelected ? 'dark' : isOption && !isSelected ? 'white' : 'dark'}
                className={classNames("btn-style", 'rounded-pill', `${isOption && !isSelected ? 'btn-white-bg' : ''}`)}
            >
                {icon ? (
                    <span className="">
                        <span onClick={onAction}>
                            {primaryText}
                        </span>
                        {' '}
                        <span onClick={secondaryAction}>
                            {icon}
                        </span>
                    </span>
                ) : primaryText}

            </Button>
        </span>
    )
}