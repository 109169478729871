/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useEffect, useState } from 'react'
import { Button, Form, Placeholder, Spinner } from 'react-bootstrap'
import { UserNotesRecord, UsersRestCommentsRecord } from '../../../api/v2/types'
import { CreateUserNotes, CreateUserRestComments, UpdateUserNotes, UpdateUserRestComments } from '../../../modules/trainingPlanActions'
import { IComponentStore } from '../../../store/createComponentStore'
import { ITrainingPlanStore } from '../../../store/createTrainingPlanStore'
import useStore from '../../../store/useStore'

const NotesBox = (props: any) => {
    const { userInfo, userNotes, dayData, getUserNotes, selectedDay, selectedWeek, getUserRestComments, loadingValues, activities, userGarminDetails } = props
    const [isLoading, setIsLoading] = useState(false)
    const [hasErrorSaving, setHasErrorSaving] = useState(false)
    const [isSuccessSave, setIsSuccessSave] = useState(false)
    const { setToast } = useStore((state: IComponentStore) => state)
    const [currentUserNote, setCurrentUserNote] = useState<UserNotesRecord | undefined>(undefined)
    const [comments, setComments] = useState("")
    const { currentDayActivity, userRestComments } = useStore((state: ITrainingPlanStore) => state)
    const [currentUserRestComments, setCurrentUserRestComments] = useState<UsersRestCommentsRecord | undefined>(undefined);

    const handleSaveUserNotes = async () => {
        if (comments !== "" || comments)
            if (currentDayActivity !== "REST") {
                if (currentUserNote && comments !== currentUserNote?.comments) {
                    setIsLoading(true)
                    const update = await UpdateUserNotes(buildData("update") as UserNotesRecord)
                    if (update?.data?.messages === "success") {
                        setIsLoading(false)
                        setIsSuccessSave(true)
                        getUserNotes(dayData)
                    }
                    else {
                        setIsLoading(false)
                        setIsSuccessSave(false)
                    }
                }

                else if (!currentUserNote && comments !== "") {
                    setIsLoading(true)
                    const create = await CreateUserNotes(buildData("insert") as UserNotesRecord)
                    if (create?.data?.messages === "success") {
                        setIsLoading(false)
                        setIsSuccessSave(true)
                        getUserNotes(dayData)
                    }
                    else {
                        setIsLoading(false)
                        setIsSuccessSave(false)
                    }
                }
            }
            else if (currentDayActivity === "REST") {

                const dataComments: UsersRestCommentsRecord = {
                    uid: userInfo?.uid,
                    comments: comments,
                    day: selectedDay,
                    week: selectedWeek?.key
                }

                if (currentUserRestComments && currentUserRestComments?.comments !== comments) {
                    setIsLoading(true)
                    const update = await UpdateUserRestComments(dataComments)
                    if (update?.data?.messages === "success") {
                        setIsLoading(false)
                        setIsSuccessSave(true)
                        getUserRestComments()
                    }
                    else {
                        setIsLoading(false)
                        setIsSuccessSave(false)
                    }
                }

                else if (!currentUserRestComments && comments !== "") {
                    setIsLoading(true)
                    const insert = await CreateUserRestComments(dataComments)
                    if (insert?.data?.messages === "success") {
                        setIsLoading(false)
                        setIsSuccessSave(true)
                        getUserRestComments()
                    }
                    else {
                        setIsLoading(false)
                        setIsSuccessSave(false)
                    }
                }
            }

    }

    const buildData = (method: string) => {

        let data: UserNotesRecord;

        if (method === "insert") {
            data = {
                marid: dayData?.marathon.id,
                uid: userInfo?.uid,
                comments: comments,
                heart_rate: "",
                distance: null,
                pace: null,
                wo_complete: false
            }

            return data
        }
        else if (method === "update") {
            data = {
                ...currentUserNote as UserNotesRecord,
                comments: comments,
                pace: null
            }
            return data
        }
    }

    useEffect(() => {
        setToast({
            show: hasErrorSaving,
            title: "Error Message",
            message: "An error occurred while saving the data",
            variant: "danger",
            onClose: () => setHasErrorSaving(false),
            duration: 5000
        })
    }, [hasErrorSaving])

    useEffect(() => {
        // setSelectedRaceDate(new Date())
        setToast({
            show: isSuccessSave,
            title: "Save Notes",
            message: "Notes has been successfully saved",
            variant: "success",
            onClose: () => {
                setIsSuccessSave(false)
            },
            duration: 3000,
        })
    }, [isSuccessSave])

    useEffect(() => {
        const userNote = userNotes?.find((x: any) => x.marid == dayData?.marathon?.id && x.uid == userInfo?.uid)
        setCurrentUserNote(userNote)

        const userRestComment = userRestComments?.find((x: UsersRestCommentsRecord) => x.uid == userInfo?.uid && x.day == (selectedDay) && x.week == selectedWeek?.key)
        setCurrentUserRestComments(userRestComment)

        if (userNote && currentDayActivity !== "REST") {
            if (!userNotes?.comments && userNote?.comments === "")
                setComments("")
            else
                setComments(userNote?.comments)
        }
        else if (!userNote && currentDayActivity !== "REST") {
            setCurrentUserNote(undefined)
            setComments("")
        }
        else if (userRestComment && currentDayActivity === "REST") {
            setComments(userRestComment.comments as string)
        }
        else if (!userRestComment && currentDayActivity === "REST") {
            setComments("")
            setCurrentUserRestComments(undefined)
        }

    }, [dayData, currentDayActivity, userNotes, userRestComments])

    useEffect(() => {
        const comments: string[] = []
        if (userGarminDetails && activities && activities?.length > 0) {
            activities.map((x: any) => {
                if (x.activityDescription)
                    comments.push(x.activityDescription + ',')
            })
        }
        setComments(comments as unknown as string)
    }, [activities, dayData, currentDayActivity, userGarminDetails])

    return (
        <>
            {/* {loadingValues ? (
                <ul className={`list-unstyled mt-3 edit-options-list list-compress}`}>
                    <Placeholder className='text-center' as="li" animation="glow">
                        <Placeholder style={{ borderRadius: 15, width: '100%', height: 20, marginBottom: 10 }} xs={12} />
                        <Placeholder style={{ borderRadius: 5, width: '100%', height: 180, marginBottom: 10 }} xs={12} />
                    </Placeholder>
                </ul>
            ) : null} */}
            {!loadingValues ? (
                <div className="notes-box mx-n3 mx-md-0 mt-n4 mt-md-0">
                    {/* {currentDayActivity === "REST" && (<h1 className="font-12 font-weight-bold text-uppercase text-center font-md-15">REST DAY NOTES</h1>)} */}

                    <Form.Group className="mb-3">
                        <Form.Control
                            disabled={isLoading || selectedWeek?.key < 0}
                            onBlur={handleSaveUserNotes}
                            as="textarea"
                            placeholder="Type your notes here..."
                            rows={3}
                            value={comments}
                            onChange={(e) => setComments(e.target.value)}
                        />
                    </Form.Group>

                    <Button
                        disabled={isLoading && !isSuccessSave}
                        size="lg"
                        className="d-block mx-auto rounded-pill font-weight-medium color-black-bg save-btn mt-1"
                        variant="dark"
                        onClick={handleSaveUserNotes}
                    >
                        {isLoading && !isSuccessSave ? (<Spinner animation='border' size='sm' />) : 'Save Notes'}
                    </Button>
                    {/* <div className="text-center">
                <div className="">
                    <Button
                        disabled={isLoading}
                        onClick={() => handleSaveUserNotes("paceNotes")}
                        size="lg"
                        variant="dark"
                        className="traning-save-btn rounded-pill text-uppercase d-block color-primary-bg save-btn mt-3 mx-auto"
                    >
                        Save Notes
                    </Button>
                </div>
            </div> */}
                </div>
            ) : null
            }
        </>
    )
}

export default NotesBox