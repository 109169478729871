import axios from "axios"
import { useEffect, useState } from "react"
import { ISurveyDetails } from "../../api/v2/onboarding-survey/types"
import useSurveyActions from "./useSurveyActions"
import questions from '../../pages/onboarding/questions.json'
import { deleteReq, getReq, postReq, putReq } from "../../modules/apiConsume"
import { ISuggestedProgramData } from "../../api/v2/suggested-programs/types"
import strtotime from "locutus/php/datetime/strtotime"
import moment from "moment"
import featuredPrograms from '../../modules/programs/featured-programs.json'

const RS_ELASTICSEARCH_URL = "https://app.runsmartonline.com/platform/search/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"

const useSuggestedPrograms = (userId: number, isRequest?: boolean) => {
    const { userSurveyDetails, getSurveyDetails } = useSurveyActions(userId)

    const [isLoading, setIsLoading] = useState(false)

    const [suggestedPrograms, setSuggestedPrograms] = useState<Array<any> | undefined>()
    // const { suggestedPrograms, setSuggestedPrograms } = useStore((state: ISurveyStore) => state)


    const saveSuggestedPrograms = async (programs: Array<number>) => {
        let suggested_programs: Array<number> = []
        programs?.length > 0 ?
            programs.forEach(e => {
                suggested_programs.push(e)
            }) : suggested_programs = [];

        try {
            await deleteReq('/v2/suggested-programs?user_id=' + userId)
            const result = await postReq('/v2/suggested-programs/' + userId, { suggested_programs })
            if (result?.status == 'ok') return true
        } catch (err) {
            //Do Nothing
        }
        return false
    }

    const getWhatToShowProgram = async (joinReason?: string, userSurveyDetails?: ISurveyDetails) => {

        try {
            let programs = await getSavedSuggestedPrograms(userId) as Array<ISuggestedProgramData>;
            programs = programs?.filter((p: any) => p?.program_id !== 11)
            if (programs && (programs.length == 0 && !userSurveyDetails || programs.length == 0 && userSurveyDetails && !userSurveyDetails.running_goal)) {
                // const key = Math.floor(Math.random() * featuredPrograms.length);
                // return featuredPrograms[key == 16 || key == 17 ? 1 : key]
                await getSuggestedPrograms(joinReason || "", 200, true)
                programs = await getSavedSuggestedPrograms(userId) as Array<ISuggestedProgramData>;
            }

            const maxDate = programs && programs?.length > 0 ? moment(programs[programs?.length - 1 || 0]?.show_date || new Date()).add(2, 'days')?.toISOString() : undefined;
            const isMax = maxDate ? strtotime(maxDate) <= strtotime(new Date()) ? true : false : undefined;

            if (isMax) {
                await getSuggestedPrograms(constructSurveyAnswers(userSurveyDetails as ISurveyDetails), 200, true)
                programs = await getSavedSuggestedPrograms(userId) as Array<ISuggestedProgramData>;
            }

            if (!programs.find(x => x.program_id == 16)) {
                await getSurveyDetails(userId).then(res => getSuggestedPrograms(res ? constructSurveyAnswers(res) : joinReason, 200, true))
            }

            let pastAndCurrentPrograms = programs.filter(x => strtotime(x.show_date) <= strtotime(new Date()))

            if (pastAndCurrentPrograms && pastAndCurrentPrograms.length > 0)
                pastAndCurrentPrograms = pastAndCurrentPrograms.sort((a, b) => b.id as number - (a.id as number))
            else
                pastAndCurrentPrograms = []


            const current = featuredPrograms.find(x => x.Program_ID == pastAndCurrentPrograms[0]?.program_id) || undefined

            return current
        }
        catch (error) {
            console.log(error)
        }

    }

    const setProgramAsShown = async (user_id: number, program_id: number, is_shown: number) => {
        const response = await putReq('/v2/suggested-programs', { user_id, program_id, is_shown })
        if (userSurveyDetails)
            await getSuggestedPrograms(constructSurveyAnswers(userSurveyDetails))
        const result = response?.data?.result;
        return result
    }

    const getSavedSuggestedPrograms = async (user_id: number) => {
        setIsLoading(true)
        const response = await getReq('/v2/suggested-programs?user_id=' + user_id)
        const result = response?.data?.result;
        setIsLoading(false)
        return result
    }

    const constructSurveyAnswers = (details: ISurveyDetails) => {
        const areas: Array<any> = []
        const areasArray = details?.struggle_areas.split(",").map(Number)
        areasArray?.forEach((a: number) => {
            const area = questions[3].answers[a - 1]
            area ? areas.push(area) : undefined
        });

        let struggle = `struggle with `
        areas.forEach((area, index) => {
            struggle = struggle + (index == areas.length - 1 && areas.length > 1 ? "and " + area : area + ", ")
        });

        const describe_running = questions[0]?.answers[Number(details?.describe_running) - 1]?.replace('&', "and") || ""
        const weekly_average = 'running ' + questions[1]?.answers[Number(details?.weekly_average) - 1] || "" + ' a week'
        const running_goal = questions[2]?.answers[Number(details?.running_goal) - 1] || ""
        const result = `${describe_running}, ${weekly_average}, ${running_goal} ${areas.length > 0 && !areas?.includes('None') ? ', ' + struggle : ''}`
        return result
    }

    const getSuggestedPrograms = async (term?: string, limit?: number, isSaveToDb?: boolean) => {
        console.log('getSuggestedPrograms')
        // console.log(term, limit, isSaveToDb)
        setIsLoading(true)
        const request = await axios.get(`${RS_ELASTICSEARCH_URL}?term=${term || "training guide"}&limit=${limit || 200}`)
        const res = request?.data?.results?.filter((d: any) => d?.Program_ID !== 11)
        const rdList = res?.slice(0, 3)?.map((d: any) => featuredPrograms?.find((fpData: any) => fpData.Program_ID === d?.Program_ID))
        if (isSaveToDb)
            await saveSuggestedPrograms(request.data.results)
        setSuggestedPrograms(rdList)
        // setSuggestedPrograms(featuredPrograms.slice(0, 3))

        setIsLoading(false)
        return request.data.results.slice(0, 3)
        // return featuredPrograms.slice(0, 3)
    }


    useEffect(() => {
        if (userId && userSurveyDetails && isRequest) {
            getSuggestedPrograms(constructSurveyAnswers(userSurveyDetails))
        }
    }, [userId, userSurveyDetails, isRequest])

    return { isLoading, saveSuggestedPrograms, suggestedPrograms, getSavedSuggestedPrograms, setProgramAsShown, getWhatToShowProgram, getSuggestedPrograms, constructSurveyAnswers, userSurveyDetails }
}

export default useSuggestedPrograms
