/* eslint-disable @typescript-eslint/no-unused-vars */
// import OnDemandVideo from '@mui/icons-material/OndemandVideo'
// import InsertDriveFile from '@mui/icons-material/InsertDriveFile'
import AccountCircle from '@mui/icons-material/AccountCircle'
import AdminLogo from '@mui/icons-material/AdminPanelSettings'
import HelpOutline from '@mui/icons-material/HelpOutline'
import ImageLogo from '../logos/ImageLogo'
import Nav from 'react-bootstrap/Nav'
import NavDropdown from 'react-bootstrap/NavDropdown'
import Box from '@mui/material/Box'
import {
	Search,
	Close,
	Logout,
	Settings,
	ArrowForwardIos,
	LogoutOutlined,
	AppSettingsAltOutlined,
	AddCircle
} from '@mui/icons-material'
import classNames from 'classnames'
import { signOut } from '../../modules/accountActions'
import { FC, useState, Fragment, forwardRef, useEffect } from 'react'
import { IComponentStore } from '../../store/createComponentStore'
import useStore from '../../store/useStore'
import BasicInput from '../input/BasicInput'
import { HOME_LOGO } from '../../modules/miscImages'
import useIsMobileScreen from '../../hooks/useIsMobileScreen'
import { navReq } from '../../modules/apiConsume'
import { useLocation, useNavigate } from 'react-router-dom'
import Account from '../../pages/account/Account'
import useQueryParams from '../../hooks/useQueryParams'
import { ICurrentUser } from '../../store/createAccountStore'

const ADMIN_ACCOUNTS = [1, 16123]
// Search Button Props
export type SearchButtonProps = {
	className?: string
	hideSearch?: boolean
	searchIsActive: boolean
	onClick: () => any
}

// Single Menu Item
export type SingleMenuItem = {
	title: string
	url?: string
	mobileOnly?: boolean
	desktopOnly?: boolean
	isLogout?: boolean
	showMobileChildren?: boolean
	optionKey?: string
	logo?: (isActive?: boolean) => any
	action?: () => any
}

// Menu Item
export type MenuItem = SingleMenuItem & {
	links?: SingleMenuItem[],
	isJourney?: boolean
}

// Navbar ID
export const NAVBAR_ID = 'rs-navbar'

// Navbar Bottom ID
export const NAVBAR_BOTTOM_ID = 'rs-btm-navbar'

// Home URL
export const HOME_URL = '/#/'

// Logo Size
export const LOGO_SIZE = {
	height: 40,
	width: 144,
}

// Logout Option
const logoutOption: SingleMenuItem = {
	title: 'Logout',
	mobileOnly: true,
	isLogout: true,
	logo: () => <Logout />,
	action: signOut,
}

// Admin Option
const adminOption: SingleMenuItem = {
	title: 'Administrator',
	url: '/#/admin-dashboard',
	logo: () => <AdminLogo height={20} />,
}

// Help Option
const helpOption: SingleMenuItem = {
	title: 'Help',
	mobileOnly: true,
	logo: () => <HelpOutline />,
	action: () =>
		(document.querySelector('.roko-instabot-widget-button') as any)?.click(),
}

// Favorites Option
const favorites: SingleMenuItem = {
	title: 'Favorites',
	url: '/#/favorites',
	logo: (isActive = false) => (
		<i className={`fa-${isActive ? 'solid' : 'regular'} fa-heart top-fa-icons ${isActive ? 'fa-active' : ''}`}></i>
	)
}

// Exercise Option
const exerciseOption: SingleMenuItem = {
	title: 'Exercises',
	url: '/#/exercises',
	logo: (isActive = false) => (
		isActive ? <img src="./images/icons/dumbbell-active.svg" style={{ height: '26.39px', top: 0 }} /> : <i className={`fa-${isActive ? 'solid' : 'regular'} fa-dumbbell top-fa-icons ${isActive ? 'fa-active' : ''}`}></i>
	)
}

// Training Option
const trainingOption: MenuItem = {
	title: 'Training',
	url: '/#/training-plan-journey/view',
	logo: (isActive = false) => (
		<i className={`fa-${isActive ? 'solid' : 'regular'} fa-calendar-week top-fa-icons ${isActive ? 'fa-active' : ''}`}></i>
	)
}

const sideCategories = [
	{
		title: 'Account Settings',
		menuItem: [
			{
				title: 'Profile',
				url: '/#/my-account?page=0',
				logo: () => <ImageLogo type="profile" height={20} />,
			},
			{
				title: 'Billing',
				url: '/#/my-account?page=1',
				logo: () => <ImageLogo type="billing" height={20} />,
			},
			{
				title: 'Subscription',
				url: '/#/my-account?page=2',
				logo: () => <ImageLogo type="subscription" height={20} />,
			},
			{
				title: ' Integrations',
				url: '/#/integrations?page=4',
				logo: () => <AppSettingsAltOutlined fontSize='large' />,
			},
		] as MenuItem[],
	},
	{
		title: 'Training',
		menuItem: [
			{
				title: 'View Plan',
				url: '/#/training-plan/view',
				logo: () => <ImageLogo type="viewPlan" height={20} />,
			},
			{
				title: 'Build a New Plan',
				url: '/#/training-plan/build/step-1',
				logo: () => <ImageLogo type="buildPlan" height={20} />,
			},
			// {
			// 	title: 'Download Plan',
			// 	url: '/#/training-plan/download',
			// 	logo: () => <ImageLogo type="downloadPlan" height={20} />,
			// }
		] as MenuItem[]
	},
	// {
	// 	title: 'Support',
	// 	menuItem: [
	// 		{
	// 			title: 'Get Help',
	// 			logo: () => <ImageLogo type="getHelp" height={20} />,
	// 			mobileOnly: true,
	// 			action: () =>
	// 				(
	// 					document.querySelector('.roko-instabot-widget-button') as any
	// 				)?.click(),
	// 		},
	// 	] as MenuItem[],
	// },
	{
		title: '',
		menuItem: [adminOption] as MenuItem[],
	},
	{
		title: '',
		menuItem: [logoutOption] as MenuItem[],
	},
]

// Categories
const categories: MenuItem[] = [{
	title: 'Build Plan',
	url: '/#/training-plan/build/step-1',
	logo: (isActive = false) => (
		<i className={`fa-sharp fa-solid fa-circle-plus fa-house top-fa-icons ${isActive ? 'fa-active' : ''}`}></i>
	),
	isJourney: true
},
{
	title: 'Home',
	url: '/#/',
	logo: (isActive = false) => (
		<i className={`fa-${isActive ? 'solid' : 'regular'} fa-house top-fa-icons ${isActive ? 'fa-active' : ''}`}></i>
	)
},
	trainingOption,
{
	title: 'Programs',
	url: '/#/programs',
	logo: (isActive = false) => (
		<i className={`fa-${isActive ? 'solid' : 'regular'} fa-grid-2 top-fa-icons ${isActive ? 'fa-active' : ''}`}></i>
	)
},
	exerciseOption,
	favorites,
	// adminOption,
	logoutOption,
]

// Bottom Categories
export const bottomCategories: MenuItem[] = [
	{
		title: 'Training',
		url: trainingOption.url,
		logo: (isActive = false) => (
			<i className={`fa-${isActive ? 'solid' : 'regular'} fa-calendar-week btm-fa-icons ${isActive ? 'fa-active' : ''}`}></i>
		),
	},
	{
		title: 'Programs',
		url: '/#/programs',
		logo: (isActive = false) => (
			<i className={`fa-${isActive ? 'solid' : 'regular'} fa-grid-2 btm-fa-icons ${isActive ? 'fa-active' : ''}`}></i>
		),
	},
	{
		title: 'Exercises',
		url: '/#/exercises',
		logo: (isActive = false) => (
			isActive ? <img src="./images/icons/dumbbell-active.svg" /> : <i className={`fa-${isActive ? 'solid' : 'regular'} fa-dumbbell btm-fa-icons ${isActive ? 'fa-active' : ''}`}></i>
		),
	},
	{
		title: 'Favorites',
		url: '/#/favorites',
		logo: (isActive = false) => (
			<i className={`fa-${isActive ? 'solid' : 'regular'} fa-heart btm-fa-icons ${isActive ? 'fa-active' : ''}`}></i>
		),
	},
	{
		title: 'Home',
		url: '/#/',
		logo: (isActive = false) => (
			<i className={`fa-${isActive ? 'solid' : 'regular'} fa-house btm-fa-icons ${isActive ? 'fa-active' : ''}`}></i>
		),
	},
]

const getIsActive = (currentUrl: any, thisUrl: any) => {
	if (currentUrl && thisUrl) {
		if (currentUrl.pathname === '/')
			return thisUrl === '/'
		else if (
			currentUrl.pathname.includes('/training-plan') &&
			thisUrl === '/training-plan-journey/view'
		)
			return true
		else if (
			(currentUrl.pathname.includes('/programs') ||
				currentUrl.pathname.includes('/webinars') ||
				currentUrl.pathname.includes('/runsmart-reset') ||
				currentUrl.pathname.includes('/guides')) &&
			thisUrl === '/programs'
		)
			return true
		else
			return thisUrl !== '/' ? currentUrl.pathname?.includes(thisUrl) : false
	} else
		return false
}

// Nav Title
const NavTitle: FC<{
	title: string
	logo?: MenuItem['logo']
	isMenu?: boolean
	isLogout?: boolean
}> = ({ title, logo, isMenu, isLogout }) => (
	<>
		{isMenu ? (
			<div>
				<div className={`menu-items img-link ${isLogout ? 'mt-n5' : ''}`}>
					<span className="nav-menu-span d-inline-block ms-n3 me-1">
						{logo && logo()}
					</span>
					<span className={`d-inline text-capitalize`}>{title}</span>
					<ArrowForwardIos
						className="me-md-n4 me-n3 mt-1"
						htmlColor="#555"
						style={{ float: 'right' }}
					/>
				</div>
			</div>
		) : (
			<div
				className={`menu-items float-start vertical-center-container img-link flex-column-items`}
			>
				<span className="nav-menu-span position-relative" style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
					{logo && logo()}
				</span>
				<span
					className='d-inline text-capitalize mt-0'
				>
					{title}
				</span>
			</div>
		)}
	</>
)

// Menu Links Component
export const MenuLinks: FC<{ showLogout?: boolean; account_id?: number }> = ({
	showLogout = false,
	account_id,
}) => {
	const { isMobile } = useStore((state: IComponentStore) => state)
	const url = useLocation()
	const isJourney = url?.pathname?.includes('training-plan-journey')
	const newCategories = isJourney ? categories : categories?.filter((c: any) => !(c?.isJourney))
	const currentUrl = useLocation()

	// Render
	return (
		<Nav className="ms-auto">
			{(ADMIN_ACCOUNTS.includes(account_id as number)
				? newCategories
				: newCategories.filter((x) => x.title !== 'Administrator')
			).map((navItem, navIndex) => {
				const {
					title,
					url: navUrl,
					logo,
					action,
					links,
					mobileOnly,
					isLogout,
				} = navItem
				const isDropdown = links?.length ? true : false
				const navKey = `n-${navIndex}`
				const shouldShow =
					(!mobileOnly && !showLogout) || (showLogout && isLogout)
				const urlSplit = navUrl?.split('#')[1]
				const isActive = getIsActive(currentUrl, urlSplit)

				// Dropdown
				if (isDropdown) {
					return (
						<Fragment key={navIndex}>
							{shouldShow && (
								<NavDropdown
									key={navKey}
									title={<NavTitle title={title} logo={logo} />}
								>
									{(links || []).map((linkItem, linkIndex) => {
										const { title: linkTitle, url: linkUrl, action } = linkItem
										const linkKey = `n-i-${linkIndex}`
										return (
											<NavDropdown.Item
												key={linkKey}
												href={linkUrl}
												onClick={action}
											>
												{linkTitle}
											</NavDropdown.Item>
										)
									})}
								</NavDropdown>
							)}
						</Fragment>
					)
				}

				// Link
				else {
					return (
						<Fragment key={navIndex}>
							{shouldShow && (
								<Nav.Item key={navKey}>
									<Nav.Link
										href={navUrl}
										onClick={action}
										className="text-nowrap"
									>
										<NavTitle
											title={isMobile && isLogout ? '' : title}
											logo={() => logo?.(isActive)}
										/>
									</Nav.Link>
								</Nav.Item>
							)}
						</Fragment>
					)
				}
			})}
		</Nav>
	)
}

// Side Menu
export const SideMenu: FC<{
	ref?: any
	show: boolean
	setShow: (x: boolean) => any
	account_id?: number
}> = forwardRef(({ show, setShow, account_id }, ref) => {


	// State
	const [expandedOptions, setExpandedOptions] = useState<{
		[index: string]: boolean
	}>({})

	const localStorageRequestKey = localStorage.getItem('deviceRequestKey')

	const { oauth_token, oauth_verifier } = useQueryParams()
	const { currentUser } = useStore((state: ICurrentUser) => state)


	const url = useLocation()

	// On Option Expand
	const onOptionExpand = (optionKey: string) => {
		const currentState = expandedOptions[optionKey] || false
		setExpandedOptions({ ...expandedOptions, [optionKey]: !currentState })
	}
	const [page, setPage] = useState<number | undefined>(undefined)
	const [showAccountPage, setShowAccountPage] = useState(false)
	const isMobile = useIsMobileScreen()

	useEffect(() => {
		if (show && isMobile) {
			document.body.style.position = 'fixed'
		} else {
			document.body.style.position = ''
			document.body.style.top = ''
		}
	}, [show, isMobile])

	useEffect(() => {
		oauth_token && oauth_verifier ? setShowAccountPage(true) : undefined
		oauth_token && oauth_verifier ? setPage(4) : undefined
	}, [oauth_token, oauth_verifier])

	// Render
	return (
		<div>
			<nav
				ref={ref as any}
				className={show ? 'side-nav-menu active' : 'side-nav-menu'}
			>
				<ul className="side-nav-menu-items">
					<li className="mt-2 pt-1 side-nav-menu-toggle d-block d-lg-none">
						<Nav.Item
							className="side-nav-menu-close"
							onClick={() => {
								setShow(!show)
								if (url.pathname === '/my-account') navReq('/#/')
							}}
						>
							<NavTitle title="" logo={() => <Close />} />
						</Nav.Item>
					</li>
					<li>
						<BasicInput
							isSearchBar={true}
							id="search-input"
							placeholder="Search"
							className="float-end mb-5 d-block d-lg-none"
						/>
					</li>
					{sideCategories.map((menus, index) => (
						<div className="nav-item-container" key={index}>
							{menus.title === 'Support' ? <br /> : undefined}
							<h4 className="nav-item-title ms-n3">{menus.title}</h4>
							<li
								className={`${sideCategories[index + 1]?.title === 'Support'
									? 'mb-n3'
									: undefined
									}`}
								key={index}
							>
								{menus.menuItem.filter(x => currentUser?.paymentType == "coupon" ? x.title !== 'Billing' : x.title !== '').map((menu, i) =>
									(!ADMIN_ACCOUNTS.includes(account_id as number) &&
										menu.title === 'Administrator') ||
										(localStorageRequestKey &&
											menu.title === 'Billing') ? undefined : (
										<Nav.Item
											key={i}
											className={`side-nav-menu-item ${menu.title === 'Logout'
												? 'no-border mt-md-5 mt-n4 mb-5'
												: ''
												}`}
										>
											<Nav.Link
												href={
													menu.url?.includes('my-account') || menu.url?.includes('integrations') ? undefined : menu.url
												}
												onClick={() => {
													menu?.action ? menu?.action() : setShow(!show) || undefined
													menu.url?.includes('my-account') || menu.url?.includes('integrations') ? setShowAccountPage(true) : undefined
													menu.url?.includes('my-account') || menu.url?.includes('integrations')
														? setPage(
															menu?.url
																? Number(
																	menu?.url?.split('?')[1]?.split('=')[1]
																)
																: undefined
														)
														: undefined
												}}
												className="text-nowrap"
											>
												<NavTitle
													isLogout={menu.title === 'Logout'}
													title={menu.title}
													logo={menu.logo}
													isMenu={true}
												/>
											</Nav.Link>
										</Nav.Item>
									)
								)}
							</li>
						</div>
					))}
					<div className="bottom-logo">
						<img
							src={HOME_LOGO}
							style={{ width: '135px', height: '35px' }}
							alt="RunSmart Home Logo"
						/>
					</div>
				</ul>
			</nav>
			{url.pathname === '/renew' ||
				url.pathname === '/update-billing' ? undefined : (
				<Account
					page={page}
					setShow={setShowAccountPage}
					show={showAccountPage}
				/>
			)}
		</div>
	)
})

// Bottom Menu Links
export const BottomMenuLinks: FC = () => {
	const currentUrl = useLocation()
	const navigate = useNavigate()
	return (
		<Nav fill justify className="ms-auto">
			{bottomCategories.map((item, index) => {
				const { title, logo, action, url } = item
				const urlSplit = url?.split('#')[1]
				const isActive: any = getIsActive(currentUrl, urlSplit)

				const handleOnClick = (url: any) => {
					url && navigate(url?.replace('#/', ''), { replace: true })
					localStorage.setItem('page', '')
					action?.()
				}

				return (
					<Nav.Item key={index} className="text-center">
						<Nav.Link className="bottom-menu" onClick={()=>handleOnClick(url)}>
							<div
								className={`bottom-menu-logo  ${title === 'Training' ? 'bottom-menu-logo-traning' : ''
									} ${title === 'Home' || title === 'Favorites'
										? 'bottom-menu-logo-smaller'
										: ''
									} ${title === 'Exercises' ? 'bottom-menu-logo-exercise' : ''}`}
							>
								{logo && logo(isActive)}
							</div>
							<div
								className={`bottom-menu-text ${title === 'Training' ? 'bottom-menu-text-traning' : ''
									}`}
							>
								{title}
							</div>
						</Nav.Link>
					</Nav.Item>
				)
			})}
		</Nav>
	)
}

// Search Button
export const SearchButton: FC<SearchButtonProps> = ({
	className,
	hideSearch,
	searchIsActive,
	onClick,
}) => {
	// Properties
	const navClassName = classNames('search-icon', className)
	const iconClassName = hideSearch ? 'd-none' : undefined

	// Render
	return (
		<Nav className={navClassName}>
			<Nav.Link onClick={onClick}>
				<span style={{ display: 'block', height: '24px' }}>
					{searchIsActive ? (
						<Close className={iconClassName} />
					) : (
						<Search className={iconClassName} />
					)}
				</span>
			</Nav.Link>
		</Nav>
	)
}
