/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useEffect, useState } from 'react'
import { Button, Card, Modal, Row, Spinner } from 'react-bootstrap';
import { AdjustVdotParams, ICreateTrainingPlanParams, ITimeObject, UserVdotAdjustRecord } from '../../api/v2/types';
import useTrainingPlanUtils from '../../hooks/useTrainingPlanUtils';
import { SaveVdotAdjustments, UpdateTrainingPlan, UpdateTrainingPlanLevel } from '../../modules/trainingPlanActions';
import StepTwo from '../../pages/training-plan/build/StepTwo';
import { IComponentStore } from '../../store/createComponentStore';
import { ITrainingPlanStore } from '../../store/createTrainingPlanStore';
import useStore from '../../store/useStore';

import levelSelection from '../../pages/training-plan/build/LevelSeletion.json'
import { navReq } from '../../modules/apiConsume';

const LEVELS = [1, 2, 3]

const ChangeLevelModal = (props: any) => {
    const { showChangeLevel, handleCloseChangeLevel, userInfo, selectedWeek, selectedDay, getUserVdotChanges, setReload } = props
    const { userVdotAdjustments } = useStore((state: ITrainingPlanStore) => state)
    const { setToast } = useStore((state: IComponentStore) => state)

    const [isNoSetTime, setIsNoSetTime] = useState(false);
    const [hasErrorSaving, setHasErrorSaving] = useState(false);
    const [isSaving, setIsSaving] = useState(false);
    const [isSuccessSave, setIsSuccessSave] = useState(false);
    const [selectedLevel, setSelectedLevel] = useState(userInfo?.training_level == 0 ? 3 : userInfo?.training_level);

    const handleChangeLevel = (level: number) => {
        setSelectedLevel(level)
    }

    const handleCloseModal = () => {
        setSelectedLevel(userInfo?.training_level == 0 ? 3 : userInfo?.training_level)
        handleCloseChangeLevel()
    }
    const handleBuildPlanSubmit = async (level: number) => {

        setIsSaving(true)

        UpdateTrainingPlanLevel(userInfo?.uid, level, userInfo?.internal_id).then((res) => {
            if (res.status === "ok" && res.data.messages === "success") {
                setIsSaving(false)
                setIsSuccessSave(true)
                handleCloseChangeLevel()
                setReload(true)
            }
            else {
                setIsSaving(false)
                setIsSuccessSave(false)
                setHasErrorSaving(true)
            }
        })

    }

    useEffect(() => {
        setToast({
            show: hasErrorSaving,
            title: "Error Message",
            message: "An error occurred while saving the training plan adjustments, please try again.",
            variant: "danger",
            onClose: () => setHasErrorSaving(false),
            duration: 5000
        })
    }, [hasErrorSaving])

    useEffect(() => {
        // setSelectedRaceDate(new Date())
        setToast({
            show: isSuccessSave,
            title: "Change Training Plan Level",
            message: "Training level plan has been changed!",
            variant: "success",
            onClose: () => {
                setIsSuccessSave(false)
            },
            duration: 3000,
        })
    }, [isSuccessSave])


    return (
        <Modal
            size='lg'
            className="common-modal build-plan adjust-race-modal build-plane-stepthree"
            show={showChangeLevel}
            onHide={handleCloseModal}
        >
            <Modal.Header className='bg-light-gray' closeButton />
            <Modal.Body className='bg-light-gray'>
                <div className='mt-n3 mt-md-0'>
                    <div className="topbar-info text-center">
                        <h1 className='text-caps'>
                            <b>Change Plan Level</b>
                        </h1>
                        <div className='text-center'>
                            <p className='w-75 mx-auto text-center'>Your goal will determine the aggressiveness of your plan.</p>
                        </div>

                        {/* <div className='text-center'>
                            <h3 className='w-75 mx-auto text-center'>Current Level: {userInfo?.training_level == 0 ? 3 : userInfo?.training_level}</h3>
                        </div> */}
                    </div>
                    <div className="mt-4">
                        <Row className="g-md-3 g-2 justify-content-center race-selection-container">
                            <Spinner animation='border' className={`${isSaving ? 'd-block' : 'd-none'} mb-3`} />
                            {levelSelection.map((level) => (
                                <Card onClick={() => userInfo?.training_level != level.id && handleBuildPlanSubmit(level.id)} key={level.id} className={`no-border px-4 pt-3 race-selection mb-2 mb-md-1 ${userInfo?.training_level == level.id ? 'selected-card' : ''}`}>
                                    <p className='title me-4'>{level.id}</p>
                                    <div className='subtext-container'>
                                        <p className='subtext mt-1'>{level.title}</p>
                                        <div className='details-wrapped'>
                                            <p className='mt-n4'>{level.description}</p>
                                        </div>
                                        <h4>Type of Runs</h4>
                                        <div className='type-container'>
                                            {level.typeOfRuns.map((type) => (
                                                <p key={type} className='type-value'>{type}</p>
                                            ))}
                                        </div>
                                    </div>
                                </Card>
                            ))}
                        </Row>
                    </div>
                </div>
            </Modal.Body>
            {/* <Modal.Header closeButton>
                <h2 className="traning-popup--title mb-0 mb-md-3">
                    Change Plan Level
                </h2>
            </Modal.Header>
            <Modal.Body className="pt-0 mt-2 mx-5 px-2 px-md-5 text-center build-plan-wrap">
                <h5 className="font-weight-semibold letter-space mb-3">
                    Current Level: {userInfo?.training_level == 0 ? 3 : userInfo?.training_level}
                </h5>
                <div className="f-box d-flex justify-content-center mt-2">
                    {LEVELS.map((level) => (
                        <div key={level} className="r-box timeType">
                            <input
                                checked={selectedLevel == level}
                                type="radio"
                                id={`level${level}`}
                                name="radio-group"
                                onChange={() => handleTimeTypeChange(level)}
                            ></input>
                            <label htmlFor={`level${level}`}>
                                <span className="font-md-12 plan--text">
                                    Level {level}
                                </span>
                            </label>
                        </div>
                    ))}

                </div>

                <div className="center-align">
                    <Button
                        disabled={isSaving}
                        variant="primary"
                        onClick={handleBuildPlanSubmit}
                        className="rounded-pill color-primary-bg save-btn mt-4 center-align text-capitalize letter-space btn-dark"
                    >
                        {isSaving ? "Saving adjustments..." : "Save"}
                    </Button>
                </div>
            </Modal.Body> */}
        </Modal>

    )
}

export default ChangeLevelModal