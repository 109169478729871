/* eslint-disable @typescript-eslint/no-unused-vars */
import { useEffect, useState } from 'react'
import { Nav, Placeholder } from 'react-bootstrap'
import { UserOverrideRecord } from '../../../api/v2/types'
import { ITrainingPlanStore } from '../../../store/createTrainingPlanStore'
import useStore from '../../../store/useStore'

const DaysSelector = (props: any) => {

    const { setCurrentDayActivity, userOverrides, currentDayActivity } = useStore((state: ITrainingPlanStore) => state)
    const [currentOverrides, setCurrentOverrides] = useState<Array<UserOverrideRecord>>([])

    const {
        daysOfWeekAbbv,
        loadingValues,
        setSelectedDay,
        getDailyActivityDisplay,
        marathonDailyValues,
        userInfo,
        selectedRace,
        selectedDay,
        dayData,
        selectedWeek,
        allUserNotes
    } = props

    useEffect(() => {
        if (dayData) {
            const overrides = userOverrides?.filter((x: any) => x.week == selectedWeek.key) as Array<UserOverrideRecord>
            if (overrides && overrides.length > 0)
                setCurrentOverrides(overrides)
            else
                setCurrentOverrides([])
            setCurrentDayActivity(
                getDailyActivityDisplay(dayData, userInfo, dayData?.marathonPaces, selectedRace?.value as number, dayData?.marathon?.race_day, allUserNotes)?.toUpperCase()
            )
        }
    }, [dayData, userOverrides, selectedWeek])

    useEffect(() => {
        const container = document.querySelector(".flex-column") as any;
        if (container) {
            const middle = container?.children[selectedDay]
            container.scrollLeft = middle?.offsetLeft + middle?.offsetWidth / 2 - container?.offsetWidth / 2 + 35;
        }
    }, [selectedDay])

    // useEffect(() => { console.log(marathonDailyValues) }, [marathonDailyValues])

    return (
        <Nav variant="pills" className='flex-column'>
            {
                currentOverrides && currentOverrides.length == 0 ?
                    daysOfWeekAbbv.map((day: any, idx: number) => (
                        <Nav.Item key={idx}>
                            <Nav.Link active={selectedDay == idx} disabled={loadingValues} onClick={() => setSelectedDay(idx)} eventKey={idx}>{day.toString().toUpperCase()}</Nav.Link>
                            <span className="font-12 font-weight-normal">
                                {loadingValues ? (
                                    <Placeholder as="nav" animation="glow">
                                        <Placeholder style={{ borderRadius: 10, height: '100%', width: 90, marginTop: 12 }} xs={12} />
                                    </Placeholder>
                                )
                                    : getDailyActivityDisplay(
                                        marathonDailyValues.find((x: any) => x?.marathon?.day == idx + 1),
                                        userInfo,
                                        marathonDailyValues.find((x: any) => x?.marathon?.day == idx + 1)?.marathonPaces,
                                        selectedRace?.value as number,
                                        marathonDailyValues.find((x: any) => x?.marathon?.day == idx + 1)?.marathon?.race_day,
                                        allUserNotes
                                    )?.toUpperCase()
                                }
                            </span>
                        </Nav.Item>
                    )) : null
            }
            {
                currentOverrides && currentOverrides.length != 0 ?
                    currentOverrides.map((value: any, idx: number) => (
                        <Nav.Item key={idx}>
                            <Nav.Link active={selectedDay == idx} disabled={loadingValues} onClick={() => setSelectedDay(idx)} eventKey={idx}>
                                {daysOfWeekAbbv.find((x: any, id: number) => id == value.day).toUpperCase()}
                            </Nav.Link>
                            <span className="font-12 font-weight-normal">
                                {loadingValues ? (
                                    <Placeholder as="nav" animation="glow">
                                        <Placeholder style={{ borderRadius: 10, height: '100%', width: 90, marginTop: 12 }} xs={12} />
                                    </Placeholder>
                                )
                                    : getDailyActivityDisplay(
                                        marathonDailyValues.find((x: any) => x?.marathon?.id == value.mid),
                                        userInfo,
                                        marathonDailyValues.find((x: any) => x?.marathon?.id == value.mid)?.marathonPaces,
                                        selectedRace?.value as number,
                                        marathonDailyValues.find((x: any) => x?.marathon?.id == value.mid)?.marathon?.race_day,
                                        allUserNotes
                                    )?.toUpperCase()
                                }
                            </span>
                        </Nav.Item>
                    )) : null
            }
        </Nav>
    )
}

export default DaysSelector