import { useEffect, useState } from 'react'

export function useDebounce<T>(value: T, delay?: number, minValue?: number): T {

    const [debouncedValue, setDebouncedValue] = useState<T>(value)

    useEffect(() => {
        if (delay) {
            const timer = setTimeout(() => {
                setDebouncedValue(value)
            }, delay)

            if (minValue) {
                if (typeof value === "string" && value.length <= minValue) {
                    clearTimeout(timer)
                }
            }
            return () => {
                clearTimeout(timer)
            }
        }
        else {
            setDebouncedValue(value)
        }
    }, [value, delay, minValue])

    return delay ? debouncedValue : '' as unknown as T
}