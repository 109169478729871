import RSButton from '../../components/buttons/RSButton'
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';

const AdminUI = () => {

	const renderTypography = () => {
		return <Box sx={{width: '100%', pb: '20px'}}>
			<Grid container xs={12} sx={{pb: '10px'}}>
				<Typography variant='h6' sx={{textDecoration: 'underline'}}>Typography</Typography>
			</Grid>

			<Grid container xs={12} justifyContent='space-evenly' alignItems='center'>
				<Grid container xs={12} sx={{fontFamily: 'Poppins-Thin !important'}}>
					Poppins Thin
				</Grid>
				<Grid container xs={12} sx={{fontFamily: 'Poppins-ExtraLight !important'}}>
					Poppins Extra Light
				</Grid>
				<Grid container xs={12} sx={{fontFamily: 'Poppins-Light !important'}}>
					Poppins Light
				</Grid>
				<Grid container xs={12} sx={{fontFamily: 'Poppins !important'}}>
					Poppins Normal
				</Grid>
				<Grid container xs={12} sx={{fontFamily: 'Poppins-Medium !important'}}>
					Poppins Medium
				</Grid>
				<Grid container xs={12} sx={{fontFamily: 'Poppins-SemiBold !important'}}>
					Poppins Semi Bold
				</Grid>
				<Grid container xs={12} sx={{fontFamily: 'Poppins-Bold !important'}}>
					Poppins Bold
				</Grid>
				<Grid container xs={12} sx={{fontFamily: 'Poppins-ExtraBold !important'}}>
					Poppins Extra Bold
				</Grid>
			</Grid>


			<Grid container xs={12} justifyContent='space-evenly' alignItems='center'>
				<Grid container xs={12}><Typography variant='h1'>Heading 1</Typography></Grid>
				<Grid container xs={12}><Typography variant='h2'>Heading 2</Typography></Grid>
				<Grid container xs={12}><Typography variant='h3'>Heading 3</Typography></Grid>
				<Grid container xs={12}><Typography variant='h4'>Heading 4</Typography></Grid>
				<Grid container xs={12}><Typography variant='h5'>Heading 5</Typography></Grid>
				<Grid container xs={12}><Typography variant='h6'>Heading 6</Typography></Grid>
			</Grid>
		</Box>
	}

	const renderCard = () => {
		return <Box sx={{width: '100%', pb: '20px'}}>
			<Grid container xs={12} sx={{pb: '10px'}}>
				<Typography variant='h6' sx={{textDecoration: 'underline'}}>Cards</Typography>
			</Grid>
			<Grid container xs={12} justifyContent='space-evenly' alignItems='center'>
				<Grid container xs={3.5}>
					<RSButton card sx={{height: '60px'}}>On Boarding Selected Card</RSButton>
				</Grid>
				<Grid container xs={3.5}>
					<RSButton secondary card sx={{height: '60px'}}>On Boarding UnSelected Card</RSButton>
				</Grid>
			</Grid>
		</Box>
	}

	const renderButton = () => {
		return <Box sx={{width: '100%', pb: '20px'}}>
			<Grid container xs={12} sx={{pb: '10px'}}>
				<Typography variant='h6' sx={{textDecoration: 'underline'}}>Buttons</Typography>
			</Grid>
			<Grid container xs={12} justifyContent='space-between' alignItems='center'>
				<Grid container xs={3.5}>
					<RSButton>Primary</RSButton>
				</Grid>
				<Grid container xs={3.5}>
					<RSButton secondary>Secondary</RSButton>
				</Grid>
				<Grid container xs={3.5}>
					<RSButton disabled>Disabled</RSButton>
				</Grid>
			</Grid>
		</Box>
	}

	return <>
		{renderButton()}
		{renderCard()}
		{renderTypography()}
	</>

}

export default AdminUI;
