import WithAuth from '../../components/WithAuth'
import AppMenus from '../../components/layout/AppMenus'
import MainCard from '../../components/cards/MainCard'
import DetailedContentCard from '../../components/cards/DetailedContentCard'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import SelectPill from '../../components/input/SelectPill'
import PageTitle from '../../components/text/PageTitle'
import useGuidesPage from '../../hooks/useGuidesPage'
import { guideText } from '../../modules/values'
import { FC, useState } from 'react'

// Pill Options
const PILL_OPTIONS = ['Training', 'Strength']

// Training Button Text
const TRAINING_BTN_TEXT = 'I am Training for a Race'

// Strength Button Text
const STRENGTH_BTN_TEXT = 'Go to Strength Training Guide'

// Title
const TITLE = 'All Guides'

/**
 * Guides Component
 */

const Guides: FC = () => {
	// Hooks
	const { guides } = useGuidesPage()

	// State
	const [bgImage, setBgImage] = useState(guides.Training.feature)
	const [title, setTitle] = useState('Training Guide')
	const [text, setText] = useState(guideText.Training)
	const [buttonText, setButtonText] = useState(TRAINING_BTN_TEXT)
	const [href, setHref] = useState('/#/guides/training')

	// On Pill Change
	const onPillChange = (value: string) => {
		const info = guides[value as keyof typeof guides]
		const newText = guideText[value as keyof typeof guideText]
		setBgImage(info.feature)
		setTitle(`${value} Guide`)
		setText(newText)
		setButtonText(value == 'Training' ? TRAINING_BTN_TEXT : STRENGTH_BTN_TEXT)
		setHref(`/#/guides/${value.toLowerCase()}`)
	}

	// Render
	return (
		<AppMenus hideTitleDesktop>
			{/* Desktop Row */}
			<Row className='mt-n4'>
				<Col xs={12} md={6}>
					<MainCard type="responsiveCardFull" bgImage={bgImage} />
				</Col>
				<Col xs={12} md={6} className="d-none d-md-flex">
					<DetailedContentCard
						title={title}
						text={text}
						buttonText={buttonText}
						link={href}
					/>
				</Col>
			</Row>

			{/* Pill Select Row */}
			<Row className="mb-3">
				<Col>
					<SelectPill options={PILL_OPTIONS} onChange={onPillChange} />
				</Col>
			</Row>

			{/* Title */}
			<Row>
				<Col>
					<PageTitle className="page-title-sm" text={TITLE} />
				</Col>
			</Row>

			{/* Mobile Row */}
			<Row className="mb-3">
				<Col className="d-md-none">
					<DetailedContentCard
						title={title}
						text={text}
						link={href}
						buttonText={buttonText}
					/>
				</Col>
			</Row>
		</AppMenus>
	)
}

export default WithAuth(Guides)
