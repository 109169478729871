import * as FileSaver from "file-saver";
import * as XLSX from "sheetjs-style";
import { BrowserUpdated } from '@mui/icons-material'
import { FC } from "react";

const ExcelExporter: FC<{ apiData: any, filename: string }> = ({ apiData, filename }) => {
    const fileType =
        "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
    const fileExtension = ".xlsx";

    const exportToExcel = () => {
        const ws = XLSX.utils.json_to_sheet(apiData);
        const wb = { Sheets: { data: ws }, SheetNames: ["data"] };
        const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
        const data = new Blob([excelBuffer], { type: fileType });
        FileSaver.saveAs(data, filename + fileExtension);
    };

    return (
        <button onClick={() => exportToExcel()} type="button" className=" mb-1 float-end btn btn-outline-secondary"><BrowserUpdated />Export to Excel</button>
    )
}



export default ExcelExporter