/* eslint-disable @typescript-eslint/no-unused-vars */
import Navbar from 'react-bootstrap/Navbar'
import Container from 'react-bootstrap/Container'
// import useComponentVisible from '../../hooks/useComponentVisible'
import { FC, useEffect, useState } from 'react'
import { HOME_LOGO } from '../../modules/miscImages'
import {
	NAVBAR_ID,
	HOME_URL,
	MenuLinks,
	SideMenu,
	SearchButton,
} from './rsMenuHelpers'
import { Col, Row, Toast, ToastContainer } from 'react-bootstrap'
import useStore from '../../store/useStore'
import { IComponentStore } from '../../store/createComponentStore'
import { ICurrentUser } from '../../store/createAccountStore'
import BackButton from '../buttons/BackButton'
import PageTitle from '../text/PageTitle'
import { useLocation } from 'react-router-dom'
import { navReq } from '../../modules/apiConsume'
import useQueryParams from '../../hooks/useQueryParams'
import useScrollDirection from '../../hooks/useScrollDirection'
// import useIsMobileScreen from '../../hooks/useIsMobileScreen'
import useComponentVisible from '../../hooks/useComponentVisible'
import GoogleCastButton from '../buttons/GoogleCastButton'
import useDeviceDetect from '../../hooks/useDeviceDetect'
import { isMobile as isMobileDevice, isTablet, isIPad13, isIOS, isAndroid } from 'react-device-detect';
import useRNBridge from '../../hooks/useRNBridge'

// Props
export type RsMenuProps = {
	setShowSearch: (x: boolean) => any
	hideOptions?: boolean
	hideSearch?: boolean
	showLogout?: boolean
}

/**
 * RsMenu Component
 */

const RsMenu: FC<RsMenuProps> = ({
	setShowSearch,
	hideOptions,
	hideSearch,
	showLogout = false,
}) => {

	const { sendDataToReactNative } = useRNBridge()

	const scrollDirection = useScrollDirection()
	//Get URL
	const url = useLocation()

	const { type } = useQueryParams()
	// const isMobile = useIsMobileScreen()

	const requestKey = localStorage.getItem('deviceRequestKey')

	// const isMobile = requestKey ? true : false

	const { mobileType } = useDeviceDetect()

	//Component Global State
	const { toast, showSideMenu, setShowSideMenu, isMobile } = useStore(
		(state: IComponentStore) => state
	)
	const { currentUser } = useStore((state: ICurrentUser) => state)
	// State
	const [searchIsActive, setSearchIsActive] = useState(false)
	// const [showSideMenu, setShowSideMenu] = useState(false)
	const [pageTitle, setPageTitle] = useState('')

	// Hooks
	const { ref, isComponentVisible, setIsComponentVisible } =
		useComponentVisible(showSideMenu)

	// Toggle Menu
	const toggleMenu = () => setShowSideMenu(!showSideMenu)

	const isSelectedProgramPage =
		(url.pathname.split('/')[1] === 'programs' ||
			url.pathname.split('/')[1] === 'runsmart-reset' ||
			url.pathname.split('/')[1] === 'guides' ||
			url.pathname.split('/')[1] === 'webinars') &&
		url.pathname.split('/')[2]
	const isSelcetedTrainingPlanPage =
		url.pathname.split('/')[1] === 'training-plan' &&
		url.pathname.split('/')[2] === 'build' &&
		url.pathname.split('/')[3] &&
		url.pathname.split('/')[3] !== 'step-1'

	const query = new URLSearchParams(url.search)

	// Toggle Search
	const toggleSearch = () => {
		if (!searchIsActive) {
			window.scrollTo(0, 0)
		}
		setShowSearch(!searchIsActive)
		setSearchIsActive(!searchIsActive)
	}

	const backToHomePage = () => {
		const isProgramPage = url.pathname.split('/')[1] === 'programs'
		const isTrainingPlanPage = url.pathname.split('/')[1] === 'training-plan'
		const title = url.pathname.split('/')[2]
		const videoId = url.pathname.split('/')[2]

		if (isProgramPage && !title && !videoId) return true
		else if (isTrainingPlanPage && title === 'view') return true
		else return false
	}

	const backToTrainingPage = () => {
		const isTrainingPlanPage = url.pathname.split('/')[1] === 'training-plan'
		const title = url.pathname.split('/')[2]
		return isTrainingPlanPage && title === 'view'
	}

	const goBackToProgramSplash = () => {
		const title = url.pathname.split('/')[2]

		if (title === 'runsmart-recover') return [true, 19]
		// if (title === 'programs') return [true, 19]
		else return [false, 0]
	}

	//Is Default to Back To Programs
	const goBackToProgramPage = () => {
		const isProgramPage = url.pathname.split('/')[1] === 'programs'
		const title = url.pathname.split('/')[2]
		const videoId = url.pathname.split('/')[2]
		if (isProgramPage && title && videoId && type === 'mobile') return true
		else return false
	}

	// Get Page Title
	const getPageTitle = () => {
		const programTitle = query.get('program_title')

		if (url.pathname === '/') {
			currentUser?.first_name
				? setPageTitle(`Welcome ${currentUser?.first_name}!`)
				: setPageTitle(`Welcome Back`)
		} else if (url.pathname.split('/')[1] === 'programs') {
			const title = url.pathname.split('/')[2]
			if (title)
				setPageTitle('RunSmart ' + url.pathname.split('/')[2].replace('-', ' '))
			else setPageTitle('Programs')
		} else if (
			url.pathname.split('/')[1] === 'onboarding-default' ||
			url.pathname.split('/')[1] === 'suggested-programs'
		) {
			currentUser?.first_name
				? setPageTitle(`Welcome ${currentUser?.first_name}!`)
				: setPageTitle(`Welcome`)
		} else {
			setPageTitle(url.pathname.split('/')[1].replace('-', ' '))
		}

		if (programTitle) {
			setPageTitle(programTitle)
		}
	}

	// Show Side Menu Effect
	useEffect(() => {
		if (isComponentVisible != showSideMenu) {
			setIsComponentVisible(showSideMenu)
		}

		if (requestKey) {
			const dataToSend = {
				requestType: 'cast-show-bottom-menu',
				payload: showSideMenu ? false : true,
				timestamp: new Date().getTime(),
			}

			sendDataToReactNative(dataToSend);
		}
	}, [showSideMenu])

	// Component Visible Effect
	useEffect(() => {
		if (isComponentVisible != showSideMenu) {
			setShowSideMenu(isComponentVisible)
		}
	}, [isComponentVisible])

	// Show Side Menu Effect
	useEffect(() => {
		getPageTitle()
	}, [url, currentUser])

	// Render
	return (
		<Navbar
			fixed="top"
			bg="light"
			expand="lg"
			className={
				// hideOptions ? 'navbar-hide-btn' :
				isMobile && scrollDirection == 'down'
					? 'header-menu hidden'
					: 'header-menu visible'
			}
		// style={{ height: isMobile ? '50px' : '' }}
		>
			<Container>
				{!isMobile || (isMobile && !isSelectedProgramPage && !isSelcetedTrainingPlanPage) ? (
					<Navbar.Brand
						href={HOME_URL}
						className="menu-brand"
					// style={{ left: '80px', top: '8px', height: '80px' }}
					>
						<img
							// style={{ width: '144px', height: '40px' }}
							src={HOME_LOGO}
							// width={LOGO_SIZE.width}
							// height={LOGO_SIZE.height}
							className="d-inline-block d-md-block align-top menu-brand-logo"
							alt="RunSmart Home Logo"
						/>
					</Navbar.Brand>
				) : null}

				<SideMenu
					ref={ref}
					account_id={currentUser?.account_id}
					show={showSideMenu}
					setShow={setShowSideMenu}
				/>

				{/* Main Links */}
				{!hideOptions ? (
					<>
						{/* Search Mobile */}
						{/* <SearchButton
							hideSearch={hideSearch}
							className="d-lg-none"
							searchIsActive={searchIsActive}
							onClick={toggleSearch}
						/> */}
						{/* {url.pathname !== "/" ? ( */}
						{isMobile && (isSelectedProgramPage || isSelcetedTrainingPlanPage) && (
							<>
								<div className="back-button-abs">
									<BackButton
										toProgramSplash={goBackToProgramSplash()}
										toHomePage={backToHomePage()}
										toTrainingPage={backToTrainingPage()}
									// toPrograms={goBackToProgramPage()}
									/>
								</div>
								<Row
									style={{ flex: 1 }}
									className="d-block d-md-none flex-1"
									onClick={() => navReq('/#/')}
								>
									<Col className="mt-2 mb-n3">
										<PageTitle text={pageTitle} isMobileTitle />
									</Col>
								</Row>
							</>
						)}

						{/* ) : (
							<div className='mb-5'></div>
						)} */}

						{/* Title */}
						{/* {url.pathname !== '/' ? (
							<Row
								// style={{ marginLeft: 25 }}
								className="d-block d-md-none"
								onClick={() => navReq('/#/')}
							>
								<Col className="mt-2 mb-n3">
									<PageTitle text={pageTitle} isMobileTitle />
								</Col>
							</Row>
						) : null} */}
						{/* {!isSelectedProgramPage && (
							<div className="mb-lg-0">
								<Navbar.Brand
									style={{ left: '70px', top: '4px' }}
									href={HOME_URL}
								>
									<img
										style={{ width: '120px', height: '32px' }}
										src={HOME_LOGO}
										className="d-inline-block d-block d-md-none align-top"
										alt="RunSmart Home Logo"
									/>
								</Navbar.Brand>
							</div>
						)} */}

						{/* } */}

						{/* Categories */}
						<Navbar.Collapse id={NAVBAR_ID} in={false}>
							<MenuLinks account_id={currentUser?.account_id} />
						</Navbar.Collapse>

						{/* CHROMECAST MOBILE */}
						{isMobile ? (
							<div
								style={{
									flexDirection: 'row',
									display: 'flex',
									alignItems: 'center',
								}}
							>
								<GoogleCastButton vimeoId={url.pathname.split('/')[3]} />
								{!isSelectedProgramPage && !isSelcetedTrainingPlanPage && (
									<Navbar.Toggle onClick={toggleMenu} />
								)}
							</div>
						) : (
							<div
								style={{
									flexDirection: 'row',
									display: 'flex',
									alignItems: 'center',
								}}
							>
								<Navbar.Toggle onClick={toggleMenu} />
							</div>
						)}

						{/* <GoogleCastButton vimeoId={url.pathname.split('/')[3]} /> */}

						{/* Search Desktop */}

						<SearchButton
							hideSearch={hideSearch}
							className="d-none d-lg-block"
							searchIsActive={searchIsActive}
							onClick={toggleSearch}
						/>

						<Navbar.Toggle onClick={toggleMenu} className="d-none d-lg-block" />
					</>
				) : (
					<>
						{/* <div
							className="back-button-abs mb-3"
							style={{ float: 'inline-start' }}
						>
							<BackButton
								toSplashPage={requestKey ? true : false}
								toHomePage={backToHomePage()}
								toPrograms={goBackToProgramPage()}
							/>
						</div> */}
						{/* <div className="mb-5 mb-lg-0">
							<Navbar.Brand className="text-center mt-2" href={HOME_URL}>
								<img
									src={HOME_LOGO}
									style={{ width: '135px', height: '35px', margin: '0 auto' }}
									className="d-inline-block d-block d-md-none align-top"
									alt="RunSmart Home Logo"
								/>
							</Navbar.Brand>
						</div> */}
						{/* Search Desktop */}
						<Navbar.Toggle
							style={{ visibility: 'hidden' }}
							onClick={toggleMenu}
						/>
					</>
				)}

				{/* Logout Link */}
				{showLogout && (
					<>
						<div></div>
						<Navbar.Text>
							<MenuLinks showLogout account_id={currentUser?.account_id} />
						</Navbar.Text>
					</>
				)}
			</Container>
			<ToastContainer position="top-center" style={{ marginTop: 5 }}>
				<Toast
					bg={toast?.variant}
					show={toast?.show}
					delay={toast?.duration}
					autohide
					onClose={toast?.onClose}
				>
					<Toast.Header style={{ background: '#F6F5F5' }}>
						<strong className="me-auto">{toast?.title}</strong>
						<small></small>
					</Toast.Header>
					<Toast.Body
						className={`${toast.variant === 'success' ? 'toast--success' : ''}`}
						style={{ color: '#FFF' }}
					>
						{toast?.message}
					</Toast.Body>
				</Toast>
			</ToastContainer>
		</Navbar>
	)
}

export default RsMenu
