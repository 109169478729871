import { useEffect, useState } from "react"
import { Video } from "../../modules/videos/allVideos"

const useViewAll = (videos: Video[], isFull: boolean) => {

    const [resultVideos, setResultVideos] = useState<Video[]>([])


    const handleFilterVideos = async () => {
        if (videos.length > 5 && !isFull)
            setResultVideos(videos.slice(0, 5))
        else
            setResultVideos(videos)
    }

    useEffect(() => {
        if (videos?.length > 0)
            handleFilterVideos()
    }, [videos, isFull])

    return { resultVideos }

}

export default useViewAll